// enum breadcrumbs text for each page
export enum BreadcrumbEnums {
    //user management
    userManagment = 'User Management',
    //My Inspection Hub Preferences
    preferences = 'My Inspection Hub Preferences',
    //location
    location = 'Locations',
    //main item
    mainItem = 'Items & Reports',
    //help and support
    helpAndSupport = 'Help & Support',
    //edit user
    editUser = 'Manage User',
    //new user registration
    userRegistration = 'New User Registration',
    // user permissions
    user = 'User Permissions',
    // item group screen
    itemGroup = 'Item Group',
    // unplanned maintenance screen
    unplannedMaintenance = 'Unplanned Maintenance'

}