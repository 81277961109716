<app-loading></app-loading>

<!-- DesktopView -->
<div *ngIf="itemDetailViewModel.isDesktop">
  <div class="display-input-dropdown-inline">
    <div class="set-width set-style-position" [style.background-color]="itemDetailViewModel.itemDetail | defectBgPipe">
      <span class="text-alignment" [style.color]="itemDetailViewModel.itemDetail | defectTextColorPipe">
        <span id="defect-code-element" class="defect-code-head">Defect code:</span>
        <span id="defect-code-element-data" class="defect-code-data"
          *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.reports && itemDetailViewModel.itemDetail.reports.results.length > 0">

          {{itemDetailViewModel.itemDetail?.reports?.results[0]?.defectCode?.code}}
        </span>
      </span>
    </div>
  </div>

  <div class="nx-margin-top-l nx-margin-bottom-2m border"></div>
  <nx-tab-group [(selectedIndex)]="currentIndex" mobileAccordion="false">
    <nx-tab label="Item details" id="item-details-tab">
      <ng-container *ngTemplateOutlet="itemDetails"></ng-container>
    </nx-tab>
    <nx-tab label="Inspection reports" id="inspection-reports-tab">
      <ng-container *ngTemplateOutlet="inspectionReports"></ng-container>
    </nx-tab>
    <nx-tab label="Notes" id="notes-tab">
      <ng-container *ngTemplateOutlet="notes"></ng-container>
    </nx-tab>
    <nx-tab label="User attachments" id="attachements-tab">
      <ng-container *ngTemplateOutlet="attachments"></ng-container>
    </nx-tab>
  </nx-tab-group>
</div>

<ng-template #itemDetails>
  <div nxRow class="nx-margin-y-s">
    <div nxCol="3" class="header-font">
      Item details for {{itemDetailViewModel.itemId}} </div>
    <div nxCol="3"> <nx-icon [ngStyle]="itemDetailViewModel.itemDetail == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
        name="export-and-share" size="s" (click)="itemDetailViewModel.itemDetail == 0 ? null : exportItemsToCSV()"
        nxTooltip="Export" class=" blueColor  cursor-pointer"></nx-icon></div>
  </div>
  <div class="item-details-container">
    <div>
      <p class="bold property-header-column-height" id="location-column">Location</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.location?.description else emptyIcon">
        {{itemDetailViewModel.itemDetail?.location?.description}}</p>
      <br>
      <p class="bold property-header-column-height" id="detailed-location-column">Location details</p>
      <div
        *ngIf="itemDetailViewModel?.itemDetail?.location?.subCodeDescription || itemDetailViewModel?.itemDetail?.location?.description else emptyIcon">
        <p class="column-detail-font property-column-height"
          *ngIf=" itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.location?.subCodeDescription">
          {{itemDetailViewModel.itemDetail?.location?.subCodeDescription}}</p>
        <p class="column-detail-font property-column-height"
          *ngIf="itemDetailViewModel?.itemDetail?.location?.subCodeDescription && itemDetailViewModel?.itemDetail?.location?.description">
          /</p>
        <p class="column-detail-font property-column-height"
          *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location  && itemDetailViewModel.itemDetail.location?.description">
          {{itemDetailViewModel.itemDetail?.location?.description}}</p>
      </div>
      <br>
      <p class="bold property-header-column-height" id="serialnumber-column">Serial number</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location  && itemDetailViewModel.itemDetail.serialNumber else emptyIcon">
        {{itemDetailViewModel.itemDetail?.serialNumber}}</p>
      <br>
      <p class="bold property-header-column-height" id="discipline-column">Discipline</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.discipline?.description else emptyIcon">
        {{itemDetailViewModel.itemDetail?.discipline?.description}}</p>
    </div>
    <div>
      <p class="bold property-header-column-height" id="client-plantNo-column">Client plant number</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.clientPlantNumber && itemDetailViewModel.itemDetail.clientPlantNumber else emptyIcon">
        {{itemDetailViewModel.itemDetail?.clientPlantNumber}}</p>
      <br>
      <p class="bold property-header-column-height" id="plant-description-column">Plant description</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.plantType && itemDetailViewModel.itemDetail.plantType?.description else emptyIcon">
        {{itemDetailViewModel.itemDetail?.plantType?.description}}</p>
      <br>
      <p class="bold property-header-column-height" id="manufacture-column">Manufacture</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.manufacturer else emptyIcon">
        {{itemDetailViewModel.itemDetail?.manufacturer}}</p>
      <br>
      <p class="bold property-header-column-height" id="manufactureDate-column">Date of manufacture</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.manufacturingYear else emptyIcon">
        {{itemDetailViewModel.itemDetail?.manufacturingYear}}</p>
    </div>
    <div>
      <p class="bold property-header-column-height" id="policy-number-column">Policy number</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail  && itemDetailViewModel.itemDetail.policyNumber else emptyIcon">
        {{itemDetailViewModel.itemDetail?.policyNumber}}</p>
      <br>
      <p class="bold property-header-column-height" id="AcePlant-column">ACE plant number</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.plantType && itemDetailViewModel.itemDetail.plantType.code else emptyIcon">
        {{itemDetailViewModel.itemDetail?.plantType?.code}}</p>
      <br>
      <p class="bold property-header-column-height" id="writtenScheme-column">Written scheme reference</p>

      <p class="icon-align column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.schemeReference  else wseEmptyIcon"
        (click)="itemsService.getItemReport(itemDetailViewModel.itemDetail?.schemeReference?.url)">
        <nx-icon name="pdf" size="s" class="blueColor">
        </nx-icon>&nbsp;
        <nx-link class="under-line">
          <a>{{itemDetailViewModel.itemDetail?.schemeReference?.fileName}}</a>
        </nx-link>
      </p>



      <br>
      <p class="bold property-header-column-height" id="status-column">Item status</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.status && itemDetailViewModel.itemDetail.status.description  else emptyIcon">
        {{itemDetailViewModel.itemDetail?.status?.description}}</p>
    </div>
    <div>
      <p class="bold property-header-column-height" id="lastInspected-column">Last inspected</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.inspectionDate  else emptyIcon">
        {{itemDetailViewModel.itemDetail?.inspections?.inspectionDate
        | date:'dd-MMM-YYYY'}}</p>
      <br>
      <p class="bold property-header-column-height" id="nextInspected-column">Next inspection type</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionType && itemDetailViewModel.itemDetail.inspections.nextInspectionType.description  else emptyIcon">
        {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionType?.description
        }}</p>
      <br>
      <p class="bold property-header-column-height" id="nextContratual-column">Next contractual date</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDate  else emptyIcon">
        {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDate
        | date:'dd-MMM-YYYY'}}</p>
      <br>
      <p class="bold property-header-column-height" id="nextStatutory-column">Next statutory date</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDateThorough  else emptyIcon">
        {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateThorough
        | date:'dd-MMM-YYYY'}} Thorough</p>
      <p class="column-detail-font property-column-height"
        *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDateWorking">
        {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateWorking
        | date:'dd-MMM-YYYY'}} Working</p>
      <br>
    </div>
  </div>
</ng-template>

<ng-template #notes>
  <div class="notes-container" *ngFor="let note of itemDetailViewModel.testDataForNotes">
    <div>
      <p class="bold">Added by:</p>
      <p class="bold">Role:</p>
      <p class="bold">Date added:</p>
    </div>
    <div>
      <p> {{note.name}} </p>
      <p>{{note.role}} </p>
      <p>{{note.dateAdded}} </p>
    </div>
    <div>
      <textarea cols="50" rows="4">{{note.notes}}</textarea>
    </div>
  </div>
</ng-template>

<ng-template #inspectionReports>
  <div class="wrapper nx-margin-top-s">
    <table nxTable nxSort>
      <caption></caption>
      <thead>
        <tr nxTableRow class="header-font">
          <th nxHeaderCell scope="col">Report ID</th>
          <th nxHeaderCell scope="col">Date report added</th>
          <th nxHeaderCell scope="col">Inspection date</th>
          <th nxHeaderCell scope="col" class="center">Defect</th>
          <th nxHeaderCell scope="col">Report attachments</th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr class="nx-margin-top-3xs">&nbsp;</tr>
          <tr nxTableRow selectable *ngFor="let data of itemDetailViewModel.itemDetail?.reports.results">
            <td nxTableCell>
              <span class="flex">
                <nx-icon name="pdf" size="s" class="standardBlueColor" (click)="itemsService.getItemReport(data.url)">
                </nx-icon>
                <span class="nx-margin-left-3xs">{{data.id}}</span>
              </span>
            </td>
            <td nxTableCell>{{data.previousInspectionDate | date:'dd-MMM-YYYY'}}</td>
            <td nxTableCell>{{data.inspectionDate | date:'dd-MMM-YYYY'}}</td>
            <td nxTableCell class="center ">
              <nx-badge [style.backgroundColor]="data.defectCodeBadgeColor"
                [style.color]="itemDetailViewModel.itemDetail | defectTextColorPipe" class="badge-padding">
                {{data?.defectCode?.code}}
              </nx-badge>
            </td>
            <td nxTableCell>
              <span class="flex " *ngIf="data?.attachments[0]?.url else wseEmptyIcon">
                <nx-icon name="pdf" size="s" class="blueColor"
                  (click)="itemsService.getItemReport(data?.attachments[0]?.url)">
                </nx-icon>
              </span>
            </td>
          </tr>

        </ng-container>

      </tbody>
    </table>
  </div>


</ng-template>

<ng-template #attachments>
  <table nxTable>
    <caption></caption>
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell></th>
        <th nxHeaderCell>File Name</th>
        <th nxHeaderCell>Type</th>
        <th nxHeaderCell>Date Modified</th>
      </tr>
    </thead>
    <tbody>
      <tr nxTableRow *ngFor="let data of itemDetailViewModel.testDataForAttachments">
        <td nxTableCell>
          <nx-icon name="pdf" size="s" class="blueColor nx-margin-top-s"></nx-icon>
        </td>
        <td nxTableCell>{{data?.fileName}}</td>
        <td nxTableCell>{{data?.type}}</td>
        <td nxTableCell>{{data?.dateModified}}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #emptyIcon>
  <p class="property-column-height"><i class="fas fa-minus" aria-hidden="true"></i></p>
</ng-template>

<ng-template #wseEmptyIcon>
  <div class="icon-padding" [ngClass]="{'icon-padding-mobile': !itemDetailViewModel.isDesktop}"><i class="fas fa-minus"
      aria-hidden="true"></i></div>
</ng-template>

<ng-template #attachmentEmptyIcon>
  <div class="attachment-icon-padding"><i class="fas fa-minus" aria-hidden="true"></i></div>
</ng-template>
<!-- MobileView -->
<div *ngIf="!itemDetailViewModel.isDesktop">
  <div class="nx-margin-x-xs">
    <div nxRow>
      <div nxCol="12,12">
        <div class="set-style-position-mobile nx-margin-top-s"
          [style.background-color]="itemDetailViewModel.itemDetail | defectBgPipe"
          [style.color]="itemDetailViewModel.itemDetail | defectTextColorPipe">
          <span class="nx-margin-left-xs">Defect code: &nbsp;</span>
          <span>{{itemDetailViewModel.itemDetail?.reports?.results[0]?.defectCode?.code}}</span>
        </div>
      </div>
    </div>
    <nx-accordion>
      <!-- Item detail accordian-->
      <nx-expansion-panel nxStyle="light">
        <nx-expansion-panel-header>
          <nx-expansion-panel-title nxStyle="light">
            Item Details
          </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <div nxLayout="grid">
          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Location</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.location?.description else emptyIcon">
              {{itemDetailViewModel.itemDetail.location?.description}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Location details</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel?.itemDetail?.location?.subCodeDescription || itemDetailViewModel?.itemDetail?.location?.description else emptyIcon">
              <div
                *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.location?.subCodeDescription">
                {{itemDetailViewModel.itemDetail.location?.subCodeDescription}}</div>
              <div
                *ngIf="itemDetailViewModel?.itemDetail?.location?.subCodeDescription && itemDetailViewModel?.itemDetail?.location?.description">
                /</div>
              <div
                *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location  && itemDetailViewModel.itemDetail.location?.description">
                {{itemDetailViewModel.itemDetail?.location?.description}}</div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Serial number</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail   && itemDetailViewModel.itemDetail.serialNumber else emptyIcon">
              {{itemDetailViewModel.itemDetail?.serialNumber}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Discipline</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.location && itemDetailViewModel.itemDetail.discipline?.description else emptyIcon">
              {{itemDetailViewModel.itemDetail?.discipline?.description}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Client plant number</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.clientPlantNumber else emptyIcon">
              {{itemDetailViewModel.itemDetail?.clientPlantNumber}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Plant description</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.plantType && itemDetailViewModel.itemDetail.plantType?.description else emptyIcon">
              {{itemDetailViewModel.itemDetail?.plantType?.description}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Manufacture</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.manufacturer else emptyIcon">
              {{itemDetailViewModel.itemDetail?.manufacturer}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Date of manufacture</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.manufacturingYear else emptyIcon">
              {{itemDetailViewModel.itemDetail?.manufacturingYear}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Policy number</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail  && itemDetailViewModel.itemDetail.policyNumber else emptyIcon">
              {{itemDetailViewModel.itemDetail?.policyNumber}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">ACE plant number</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.plantType && itemDetailViewModel.itemDetail.plantType.code else emptyIcon">
              {{itemDetailViewModel.itemDetail?.plantType?.code}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Item status</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.status && itemDetailViewModel.itemDetail.status.description  else emptyIcon">
              {{itemDetailViewModel.itemDetail.status?.description}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Last inspected</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.inspectionDate  else emptyIcon">
              {{itemDetailViewModel.itemDetail?.inspections?.inspectionDate
              | date:'dd-MMM-YYYY'}}</div>
          </div>



          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Next inspection type</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionType && itemDetailViewModel.itemDetail.inspections.nextInspectionType.description  else emptyIcon">
              {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionType?.description
              }}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Next contractual date</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDate  else emptyIcon">
              {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDate
              | date:'dd-MMM-YYYY'}}</div>
          </div>

          <div nxRow>
            <div nxCol="6" class="item-detail-formatting-mobile">Next statutory date</div>
            <div nxCol="6" class="item-detail-formatting-values-mobile"
              *ngIf="itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateThorough || itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateWorking else emptyIcon">
              <span
                *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDateThorough">
                {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateThorough
                | date:'dd-MMM-YYYY'}} Thorough</span>
              <span
                *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.inspections && itemDetailViewModel.itemDetail.inspections.nextInspectionDateWorking">
                {{itemDetailViewModel.itemDetail?.inspections?.nextInspectionDateWorking
                | date:'dd-MMM-YYYY'}} Working
              </span>
            </div>
          </div>

          <div nxRow class="nx-margin-top-3xs">
            <div nxCol="6" class="item-detail-formatting-mobile">Written scheme reference</div>
            <div nxCol="6"
              *ngIf="itemDetailViewModel.itemDetail && itemDetailViewModel.itemDetail.schemeReference  else wseEmptyIcon"
              (click)="itemsService.getItemReport(itemDetailViewModel.itemDetail?.schemeReference?.url)">
              <nx-link class="wse-link-mobile-vertical-alignment">
                <a
                  class="item-detail-formatting-values-mobile ">{{itemDetailViewModel.itemDetail?.schemeReference?.fileName}}</a>
              </nx-link>
              &nbsp;
              <nx-icon name="pdf" size="s" class="blueColor">
              </nx-icon>&nbsp;
            </div>
          </div>
          <div nxRow>
            <div nxCol="11">&nbsp;</div>
            <div nxCol="1"><nx-icon (click)="itemDetailViewModel.itemDetail == 0 ? null : exportItemsToCSV()"
                nxTooltip="Export" name="export-and-share" size="s" class=" blueColor  cursor-pointer"
                [ngStyle]="itemDetailViewModel.itemDetail == 0 && {cursor: 'not-allowed', opacity: '0.6'}"></nx-icon>
            </div>
          </div>
        </div>
      </nx-expansion-panel>
      <!--Inspection Reports-->
      <nx-expansion-panel nxStyle="light">
        <nx-expansion-panel-header>
          <nx-expansion-panel-title nxStyle="light">
            Inspection Reports
          </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <div class="wrapper nx-margin-top-3xs" nxLayout="grid"
          *ngFor="let data of itemDetailViewModel.itemDetail?.reports.results">
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Report ID</div>
            <div nxCol="6">
              {{ data.id }}
            </div>
          </div>
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Date report added</div>
            <div nxCol="6">
              {{ data.previousInspectionDate | date:'dd-MMM-YYYY' }}
            </div>
          </div>
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Inspection date</div>
            <div nxCol="6">
              {{ data.inspectionDate | date:'dd-MMM-YYYY' }}
            </div>
          </div>
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Defect</div>
            <div nxCol="6">
              <span *ngIf="data.defectCode && data.defectCode.code">
                <nx-badge class="badge-padding" [style.backgroundColor]="data.defectCodeBadgeColor"
                  [style.color]="itemDetailViewModel.itemDetail | defectTextColorPipe">
                  {{data.defectCode?.code}} </nx-badge>
              </span>
            </div>
          </div>
          <div nxRow class="nx-margin-top-s icon-heading-font-size">
            <div nxCol="3">
              &nbsp;
            </div>
            <div nxCol="3">
              Report
            </div>
            <div nxCol="6">
              Report attachments
            </div>
          </div>
          <div nxRow class="nx-margin-top-s">
            <div nxCol="3">
              &nbsp;
            </div>
            <div nxCol="3">
              <nx-icon name="pdf" size="s" class="blueColor report-icon-padding"
                (click)="itemsService.getItemReport(data.url)">
              </nx-icon>
            </div>

            <div nxCol="6">
              <div *ngIf="data?.attachments[0]?.url else attachmentEmptyIcon" class="attachment-icon-padding">
                <nx-icon name="pdf" size="s" class="blueColor"
                  (click)="itemsService.getItemReport(data?.attachments[0]?.url)">
                </nx-icon>
              </div>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <hr />
            </div>
          </div>
        </div>
      </nx-expansion-panel>

      <!--Attachment accordian-->
      <nx-expansion-panel nxStyle="light">
        <nx-expansion-panel-header>
          <nx-expansion-panel-title nxStyle="light">
            User attachments
          </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <div class="wrapper nx-margin-top-3xs" nxLayout="grid"
          *ngFor="let data of itemDetailViewModel.testDataForAttachments">
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">File name</div>
            <div nxCol="6">
              {{ data.fileName }}
            </div>
          </div>
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Type</div>
            <div nxCol="6">
              {{ data.type }}
            </div>
          </div>
          <div nxRow>
            <div class="formatHeading text-align-left" nxCol="6">Date modified</div>
            <div nxCol="4">
              {{ data?.dateModified }}
            </div>
            <div nxCol="2">
              <nx-icon name="pdf" size="s" class="standardBlueColor"> </nx-icon>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <hr />
            </div>
          </div>
        </div>
      </nx-expansion-panel>
    </nx-accordion>
  </div>
</div>