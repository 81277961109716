import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { RoleGuard } from 'src/app/services/auth-service/role.guard';
import { Role } from 'src/app/shared/enums/role';
import { ManagementReportComponent } from './management-report.component';
import { PlantNotAvailableComponent } from './plant-not-available/plant-not-available.component';

//Routing module for management report
const routes: Routes = [{
  //Route for management report
  path: '', component: ManagementReportComponent, canActivate: [AuthGuard, RoleGuard], data: {
    breadcrumb: '', expectedRoles: [Role.clientAdmin, Role.standardClientUser]
  }
},
// Route for Plant Not Available with Reasons
{
  path: 'plant', component: PlantNotAvailableComponent, canActivate: [AuthGuard], data: {
    breadcrumb: 'Plant Not Available with Reasons'
  }
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagementReportRoutingModule { }
