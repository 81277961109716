<!-- If device is desktop then show this menu  -->
<nav aria-label="Breadcrumb" class="breadcrumbAlignment">
    <breadcrumb #parent>
        <ol nxBreadcrumb *ngIf="router.url.indexOf('dashboard') !== 1">
            <ng-container *ngIf="isDesktop">
                <li *ngIf="router.url.indexOf('/permissions') === -1 ">
                    <a nxBreadcrumbItem href="" [routerLink]=" [homeLink]">Home</a>
                </li>
                <li *ngIf="router.url.indexOf('/permissions') > 1 " (click)="navigateToProfile()">
                    <a nxBreadcrumbItem class="standard-blue">{{firstname}} {{surname}}'s
                        profile</a>
                </li>

                <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                    <li *ngIf="!route.terminal">
                        <a nxBreadcrumbItem href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                    </li>
                    <li *ngIf="route.terminal">
                        <a class="dead-terminal" nxBreadcrumbItem>{{ route.displayName }}
                        </a>
                    </li>
                </ng-template>
            </ng-container>
        </ol>
    </breadcrumb>
</nav>

<!-- modal for cancel button -->
<ng-template #cancelChangesModal>

    <div>
        <h2 class="center text-alignment">Cancel changes</h2>
        <div class="nx-margin-top-m increase-font-size">
            By cancelling, all changes made to the user's permissions will not be saved and applied to their profile.
        </div>
        <div class="nx-margin-top-m increase-font-size">
            Are you sure you wish to cancel?
        </div>

        <div class="nx-margin-top-2m center">
            <button class="nx-margin-right-s" nxButton="secondary small" type="button"
                (click)="closeCancelSubmitModal()">
                No, don't cancel
            </button>
            <button class="nx-margin-right-s" nxButton="primary small" type="button" (click)="redirectToMyTeam()">
                Yes, cancel
            </button>
        </div>
    </div>

</ng-template>