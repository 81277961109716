import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { shareReplay, take } from "rxjs/operators";
import { PreferencesUpdateModel } from "src/app/schemas/users";
import { CommonService } from "src/app/services/common.service";
import { UserService } from "src/app/services/graphql/user.Service";
import { LayoutService } from "src/app/services/layout-service";
import { ErrorModel } from "src/app/shared/models/error.Model";
import { AppStates } from "src/app/statemanagement/app.state";
import { PreferencesViewModel } from "./preferences.viewModel";
import * as UserActions from 'src/app/statemanagement/actions/user.actions';
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbEnums } from "src/app/shared/enums/breadcrumbs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BannerDetail } from "src/app/shared/enums/banner";
import { CurrentUser } from "src/app/statemanagement/models/current-user.model";

@UntilDestroy()
@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

    // view model object
    preferencesViewModel: PreferencesViewModel = new PreferencesViewModel();
    // formGroup for input data/validation
    preferencesFormGroup: FormGroup;

    // preference component constructor
    constructor(private layoutService: LayoutService, private store: Store<AppStates>,
        private userService: UserService, private common: CommonService,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        //to set breadcrums on refresh
        this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.preferences);
        //load form
        this.loadForm()
        // find the current screen size desktop or not
        this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => { this.preferencesViewModel.isDesktop = result; });
        // show no banner and subBanner on prefernces page
        this.layoutService.setBannerVisibilty(BannerDetail.NoText, false)
        // fetch the details stored in store
        this.store.select("user").pipe(take(1)).subscribe((user: any) => {
            if (user) {
                this.preferencesViewModel.employeeModelObj = user
                this.preferencesFormGroup.patchValue(this.preferencesViewModel.employeeModelObj);
            }
        });

    }

    // save  user flags
    saveFlags() {
        // // interface object with user data
        const userFlags: PreferencesUpdateModel = {
            eReportingEnabled: this.preferencesFormGroup.value.eReporting,
            eNotificationEnabled: this.preferencesFormGroup.value.eNotification
        }

        // update to api with object
        this.userService.updatePreferences(userFlags).pipe(shareReplay(), untilDestroyed(this)).subscribe(res => {
            if (res) {

                if (res.data?.updateProfile) {
                    const user: CurrentUser = {
                        roles: res.data.updateProfile?.metadata?.role,
                        firstName: this.preferencesViewModel.employeeModelObj?.firstName,
                        lastName: this.preferencesViewModel.employeeModelObj?.lastName,
                        eNotification: res.data.updateProfile?.metadata?.eNotificationEnabled,
                        eReporting: res.data.updateProfile?.metadata?.eReportingEnabled,
                        itemGroups: res.data.updateProfile?.metadata?.userPermissionItemGroups
                    }

                    this.preferencesViewModel.employeeModelObj = user
                    //dispatching store new values for thes control
                    this.store.dispatch(new UserActions.SetUser(user))
                }
                // show message in case of failure
                else {
                    this.layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Unable to update the user preferences, please contact admin for support."));
                }
            }
        })
    }

    //manage ereporting and enotification control via form
    loadForm() {
        this.preferencesFormGroup = new FormBuilder().group({
            eNotification: [''],
            eReporting: ['']
        }, { updateOn: 'change' });
    }
}

