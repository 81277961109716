<!-- If device is desktop then show this menu  -->

<header nx-header *ngIf="headerViewModel.isDesktop">
    <nx-header-brand class="headerMargin">
        <nx-link>
            <a href="#">
                <figure nxFigure>
                    <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;" width="122px;">
                </figure>
            </a>
        </nx-link>
        <nx-header-app-title>Inspection Hub</nx-header-app-title>
    </nx-header-brand>

    <nx-header-actions class="headerMargin">
        <nx-icon class="nx-margin-right-xs standardBlueColor" name="product-search" size="s"
            (click)=" this.isSearchClicked = !this.isSearchClicked;">
        </nx-icon>
        <ng-container *ngIf="this.headerViewModel.hasClientAdminRole || this.headerViewModel.hasClientUserRole">
            <nx-icon
                [ngClass]="headerViewModel.itemGroupListComplete && headerViewModel.itemGroupListComplete.length > 1 ? 'pointer itemGroupIcon nx-margin-right-xs' : 'itemGroupIcon  nx-margin-right-xs'"
                name="filter" size="s"
                (click)="headerViewModel.itemGroupListComplete && headerViewModel.itemGroupListComplete.length > 1 && openModal()"></nx-icon>

            <p class="  nx-margin-top-s nx-margin-right-xs">
                Business Area:

            </p>

            <p *ngIf="headerViewModel.itemGroupListComplete && headerViewModel.itemGroupListComplete.length ===1"
                class=" nx-margin-right-xs">
                {{headerViewModel.itemGroupList[0]}}
            </p>
            <a [nxTooltip]="headerViewModel.listOfItemGroupNames"
                *ngIf="headerViewModel.itemGroupList && headerViewModel.itemGroupListComplete && headerViewModel.itemGroupList.length > 0 && headerViewModel.itemGroupListComplete.length > 1"
                class=" nx-margin-right-xs">
                {{headerViewModel.itemGroupList[0]}} {{headerViewModel.itemGroupList.length > 1? " +": ""}} </a>
        </ng-container>
        <ng-container *ngIf="this.headerViewModel.hasInternalAdminUserRole">
            <nx-icon nxTooltip="change organisation and Business Area" class="itemGroupIcon  nx-margin-right-xs"
                name="filter" size="s"></nx-icon>
            <p (click)="openOrganisationModel()"
                class=" pointer itemGroupIcon mobile-label-ItemGroup  nx-margin-top-s nx-margin-right-xs">
                <u> Organisation: </u>
            </p>
            <a *ngIf="headerViewModel.organisationObject && headerViewModel.organisationObject.length > 0"
                class="pointer itemGroupIcon nx-margin-right-xs" (click)="openOrganisationModel()">
                <u> {{headerViewModel.organisationObject[0].name}}</u> </a>
        </ng-container>

        <!-- myTeam and myAllianz applauncher options -->
        <div #myTeamAllianzDropdownContainer>
            <nx-icon name="applauncher" nxTooltip="Allianz Applications" size="s"
                class="blueColor nx-margin-right-s cog-wheel-icon"
                (click)="myTeamAllianzToggleDropdown($event)"></nx-icon>
            <nx-card *ngIf="headerViewModel.myTeamAllianzSubMenu" class='myTeamAllianz-sub-menu'>
                <a class="align-icon-title" (click)="navigateToMyAllianzHome()">
                    <img src="assets/eagle-icon.png" alt="" class="nx-margin-right-s eagle-icon-size image-width">
                    <p>myAllianz</p>
                </a>
                <a class="align-icon-title" (click)="navigateToMyTeamHome()">
                    <nx-icon name="product-people-collaboration" size="s"
                        class="blueColor nx-margin-right-s icon-bold"></nx-icon>
                    <p>myTeam</p>
                </a>
            </nx-card>
        </div>

        <!-- user management cog -->
        <div #userDropdownContainer
            *ngIf="headerViewModel.hasInternalAdminUserRole || headerViewModel.hasClientAdminRole">
            <nx-icon [name]="headerViewModel.cogWheelIcon" size="s" class="blueColor nx-margin-right-s cog-wheel-icon"
                [ngClass]="{'cog-wheel-bg-color': headerViewModel.userSubMenu}"
                (click)="toggleDropdown($event)"></nx-icon>
            <nx-card *ngIf="headerViewModel.userSubMenu" class='user-sub-menu'>
                <a class="align-icon-title" (click)="navigateToMyTeamRegisterNewUser()">
                    <nx-icon name="plus-circle-o" size="s" class="blueColor nx-margin-right-s"></nx-icon>
                    <p>Register new user</p>
                </a>
                <a class="align-icon-title" (click)="navigateToMyTeamManageUser()">
                    <nx-icon name="user-o" size="s" class="blueColor nx-margin-right-s"></nx-icon>
                    <p>User management</p>
                </a>
            </nx-card>
        </div>

        <!--User profile menu section-->
        <div #profileDropdownContainer>
            <div nxAvatar size="xsmall" class="color-1" (click)="profileToggleDropdown($event)">
                {{headerViewModel.userIntials}}
            </div>
            <nx-card *ngIf="headerViewModel.showSubMenu" class='profile-sub-menu'>
                <a class="align-icon-title" (click)="navigateToMyAllianzProfile()">
                    <nx-icon name="user-o" size="s" class="blueColor nx-margin-right-s icon-padding"></nx-icon>
                    <p>Profile</p>
                </a>
                <a class="align-icon-title" routerLink="./preferences"
                    (click)="this.headerViewModel.showSubMenu = !this.headerViewModel.showSubMenu">
                    <nx-icon name="helmet-construction" outline="true" size="s"
                        class="blueColor nx-margin-right-s"></nx-icon>
                    <p>My Inspection Hub Preferences</p>
                </a>
                <a class="align-icon-title" (click)="logout()" *ngIf="!this.headerViewModel.hasInternalAdminUserRole">
                    <nx-icon name="close" size="s" class="blueColor nx-margin-right-s icon-padding"></nx-icon>
                    <p>Logout</p>
                </a>
            </nx-card>
        </div>
    </nx-header-actions>
</header>


<!-- If device is mobile then show this menu  -->
<div>
    <header nx-header *ngIf="!headerViewModel.isDesktop">
        <nx-header-brand class="headerMargin">
            <nx-link>
                <a href="#">
                    <figure nxFigure>
                        <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;"
                            width="122px;">
                    </figure>
                </a>
            </nx-link>
        </nx-header-brand>
        <nav>
            <nx-icon class="nx-margin-top-xs standardBlueColor" name="product-search" size="s"
                (click)=" this.isSearchClicked = !this.isSearchClicked;">
            </nx-icon>
            <button nxPlainButton (click)="headerViewModel.isMenuVisible = !headerViewModel.isMenuVisible"
                aria-label="open menu" class="expand-btn nx-margin-left-xs">
                <nx-icon class="nx-margin-bottom-s" name="bars"
                    *ngIf="!headerViewModel.isMenuVisible; else showCross"></nx-icon>
            </button>
        </nav>
    </header>

</div>

<!--Global search row -->
<div nxLayout="grid" *ngIf="isSearchClicked" class="contentAlignment">
    <div *ngIf="!isDesktop" nxRow>
        <div nxCol="10">
            <nx-formfield>
                <input class="change-search-cursor" nxInput placeholder="To search start typing.." id="search"
                    name="search" />
            </nx-formfield>
        </div>
        <div nxCol="2" class="nx-margin-top-2m">
            <button type="reset" id="clear" nxButton="secondary small" type="button" (click)="clear()">X</button>
            &nbsp;
            <button id="search" nxButton="primary small" type="button">GO</button>
        </div>
    </div>
</div>
<!-- Template for showing cross icon in mobile menu  -->
<ng-template #showCross>
    <nx-icon class="nx-margin-bottom-s" name="close"></nx-icon>
</ng-template>
<!-- Menu -->

<div class="showMenu menuAlignment"
    [style.height]="headerViewModel.isMenuVisible ? this.headerViewModel.mobileMenuHeight : '0px'">
    <ul>
        <a routerLink="" (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Profile</a>
        <a routerLink="./preferences"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">My Inspection Hub
            Preferences</a>
        <a *ngIf="headerViewModel.hasInternalAdminUserRole || headerViewModel.hasClientAdminRole"
            (click)="navigateToMyTeamRegisterNewUser()"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Register new user</a>
        <a *ngIf="headerViewModel.hasInternalAdminUserRole || headerViewModel.hasClientAdminRole"
            (click)="navigateToMyTeamManageUser()"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">User management</a>
        <a (click)="navigateToMyAllianzHome()"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">myAllianz</a>
        <a (click)="navigateToMyTeamHome()"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">myTeam</a>
        <a routerLink="./dashboard"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Dashboard</a>
        <a routerLink="./locations"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Locations</a>
        <a routerLink="./items" (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Items
            & Reports</a>
        <a routerLink="./help-support"
            (click)="this.headerViewModel.isMenuVisible = !this.headerViewModel.isMenuVisible">Help & Support</a>
        <a (click)="logout()" *ngIf="!this.headerViewModel.hasInternalAdminUserRole">Log out</a>

    </ul>
</div>


<item-group-modal
    *ngIf="!headerViewModel.hasInternalAdminUserRole && headerViewModel.showPopup && headerViewModel.isDesktop"
    (setModalStatus)="getModalOpenStatus($event)" (setPopupCancel)="getModalCancelStatus($event)"
    [itemGroupList]="headerViewModel.itemGroupListComplete" [showPopup]="headerViewModel.showPopup"
    [itemGroupAssignedList]="headerViewModel.itemGroupAssignedList">
</item-group-modal>

<organization-modal *ngIf="headerViewModel.hasInternalAdminUserRole && headerViewModel.showOrganisationModel"
    (setModalStatus)="getOrganisationModalOpenStatus($event)"></organization-modal>