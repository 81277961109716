import { Injectable, ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorLogService } from "./errorService";
import { Router } from "@angular/router";

// global error handler class 
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private errorService: ErrorLogService, private router: Router) { }
  // global error handler
  // all types of errors will be catch here
  async handleError(error: any) {
   
    // passing the error into log service
    this.errorService.logError(error);
    // checking if error is of httpErrorResponse
    const httpErrorResponse = error as HttpErrorResponse;
    // if error property exists and has value
    if (httpErrorResponse.error instanceof Blob) {
      // getting text from error blob
      const responseError = await httpErrorResponse.error.text();
      // converting json string into an object
      const errorObject = JSON.parse(responseError);
      // checking if object exists
      if (errorObject) {
        // navigate to locations with error object
        location.href = `${this.router.parseUrl("/error").toString()}?rid=${errorObject.requestId}`;
      }
    }
  }
}