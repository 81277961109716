import { Component } from '@angular/core';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
// callback component required by auth0 integration
export class CallbackComponent {

  constructor() {
    // This is intentional
  }
}
