import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NxModalModule } from "@aposin/ng-aquila/modal";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { SharedModule } from "src/app/shared/shared.module";
import { PreferencesRoutingModule } from "./preferences-routing.module";
import { PreferencesComponent } from "./preferences.component";


@NgModule({
    declarations: [PreferencesComponent],
    imports: [SharedModule, FormsModule, NxSwitcherModule, PreferencesRoutingModule],
    exports: [
        NxModalModule
    ]
})
// Preferences page module class
export class PreferencesModule { }