import { Injectable } from '@angular/core';
import { Auth0Client, GetUserOptions } from '@auth0/auth0-spa-js';
import { from, Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import createAuth0Client from '@auth0/auth0-spa-js/dist/auth0-spa-js.development';
import { CommonService } from '../common.service';
import { CookieStorageService } from '../cookie-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  // setting up auth0 client id based on current environment
  _authSettings: { clientId: string, scope: string } = this.commonService.getAuth0Settings();
  _domain: string = environment.config.domain;

  // Create an observable of Auth0 instance of client
  auth0Client$ = (
    from(
      createAuth0Client({
        domain: this._domain,
        client_id: this._authSettings.clientId,
        redirect_uri: window.location.origin + '/callback',
        responseType: 'token id_token',
        scope: this._authSettings.scope,
        cacheLocation: 'localstorage'
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => (this.loggedIn = res))
  );
  getAccessToken$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently()))
  );
  getIdToken$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getIdTokenClaims())));
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = false;
  aTkn = null;

  constructor(private router: Router, private commonService: CommonService, private cookieService: CookieStorageService) {
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: GetUserOptions | undefined): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  login(redirectPath: string = '/') {
    this.cookieService.deleteCookie('logSess')
    this.cookieService.deleteCookie('countdown')
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: window.location.origin + '/callback',
        appState: { target: redirectPath, param: "true" },
      });
    });
  }

  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      let queryParam: string // query param added to handle the item group model work
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          queryParam = cbRes.appState && cbRes.appState.param ? cbRes.appState.param : '/';
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(() => {
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute], { queryParams: { firstLogin: queryParam } });
      });
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      this.cookieService.deleteCookie('logSess')
      this.cookieService.deleteCookie('countdown')
      // Call method to log out
      client.logout({
        client_id: this._authSettings.clientId,
        returnTo: window.location.origin + '/logged-out'
      });
    });
  }

  // gets user token and returns it in promise
  getUserToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken$.subscribe({
        next: (response: string) => {
          // when fetched successfully
          resolve(response);
        },
        error: err => {
          // when got any error
          reject(err);
          throw err;
        }
      });
    });
  }
}
