// This config for all non-local environments i.e. dev, quality, stable
export const environment = {
    production: true,
    name: 'default',
    google_map_api_key: 'AIzaSyCeLOKZELkAl9--_63i1e17KYxvQ1krjlQ',
    config: {
        name: 'stable',
        domain: 'uat-uk.apis.allianz.com/azuk/ds/uam/identity/oidc/v1',
        internal_domain: 'uat-uk.apis.allianz.com/azuk/ds/uam/identity/oidc/v1',
        client_id: 'u8fr5G7UIoA5oh6bdp1ODaAR5l2KlIpt',
        internal_client_id: 'J7fRZIcuAkfcxWxbtGUygoFhbywpeWg7',
    },
    qraphql: 'https://uat-uk.apis.allianz.com/azukc/es/ecp/hub/b2c/v1/graphql',
    BAE_HOST: 'https://uat-uk.apis.allianz.com/azukc/es/ecp/baewebevent/b2c/v1/graphql',
    reports: 'https://uat-uk.apis.allianz.com/azukc/es/ecp/doc/b2c/v1',
    myAllianzInternal: 'https://ops.int.my.allianz.co.uk',
    internalInstances: ['ops.eng.ci.inspectionhub.allianz.co.uk',
        'ops.eng.int.inspectionhub.allianz.co.uk',
        'ops.eng.inspectionhub.allianz.co.uk'],
    contactUsEmailAddress: 'inspectionhub-test@allianz.co.uk',
    myTeamManageUserPermissions: ['https://support.int.my.allianz.co.uk/user/manage', 'https://ops.support.int.my.allianz.co.uk/user/manage'],
    myTeamRegisterUser: ['https://support.int.my.allianz.co.uk/user/create', 'https://ops.support.int.my.allianz.co.uk/user/create'],
    myTeamRegisterUserRole: ['https://support.int.my.allianz.co.uk/user/add-role', 'https://ops.support.int.my.allianz.co.uk/user/add-role'],
    myTeamManageUser: ['https://support.int.my.allianz.co.uk/user/search', 'https://ops.support.int.my.allianz.co.uk/user/search'],
    myTeamHome: ['https://support.int.my.allianz.co.uk', 'https://ops.support.int.my.allianz.co.uk'],
    myAllianzHome: ['https://int.my.allianz.co.uk', 'https://ops.int.my.allianz.co.uk'],
    myAllianzProfile: ["https://int.my.allianz.co.uk/myprofile", "https://ops.int.my.allianz.co.uk/myprofile"],
    adobeUrl: 'https://assets.adobedtm.com/dd4adce84cad/070580fe0560/launch-87a2a943b8dd-staging.min.js',
    plannedMaintenanceFileEndPoint: "https://allianzdevstorage.blob.core.windows.net/non-production-container/maintenanceFile.json?sp=r&st=2023-05-04T11:12:58Z&se=2024-05-31T19:12:58Z&spr=https&sv=2022-11-02&sr=b&sig=3T1Tz5GIYHtTUlQ6mGMDM4ipx%2BOCPeEMOQiE0rA6Q88%3D",
    maintenanceAzureEndpoint: 'https://allianzdevstorage.blob.core.windows.net/non-production-container/message.json?sp=r&st=2023-03-06T11:48:23Z&se=2025-12-30T19:48:23Z&spr=https&sv=2021-06-08&sr=b&sig=mcbqPevN55rWEIhUszxxfaoBkAg2ABcfYvwF1%2BP74UM%3D',

}



