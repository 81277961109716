import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RolesFromApi } from 'src/app/shared/enums/role';
import { AppStates } from 'src/app/statemanagement/app.state';
import { AuthService } from '../../services/auth-service/auth0client.service';
import { CommonService } from 'src/app/services/common.service';
import { ErrorLogService } from 'src/app/services/errors/errorService';
import { AppRoutes } from 'src/app/shared/appRoutes';


@Component({
  selector: 'app-public',
  templateUrl: './public.component.html'
})
// component for all visitors without authentication
export class PublicComponent implements OnInit {
  //Responsive view true for desktop , false for mobile
  isDesktop: boolean;
  // Boolean field for showing menu state
  isMenuVisible = false;
  // Set mobile menue height based on additional links additions
  mobileMenuHeight: string = '46px';
  // check user is authenticated or not
  isAuthenticated: boolean = true;
  // contains the status of user role of internal admin
  hasInternalAdminUserRole: boolean = false;
  // constructor with authService and Router injected

  constructor(public auth: AuthService, private router: Router, private store: Store<AppStates>,
    private layoutService: LayoutService, private localStorageService: LocalStorageService,
    private common: CommonService, private logService: ErrorLogService) {

  }
  ngOnInit(): void {
    // on init, subscribed to isAuthenticated
    this.auth.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        // if authenticated, redirect to locations route
        void this.router.navigate(['/', 'dashboard'])
      }
      this.isAuthenticated = !this.isAuthenticated;
    });
    // subscribed to logged inuser object from store
    this.store.select("loggedInUser").subscribe((user: any) => {
      // checking the role of user
      this.hasInternalAdminUserRole = (user.employee.role === RolesFromApi.internalAdmin)
    });
    // subscribe to device type detection observable
    this.layoutService.currentDevice.subscribe(result => this.isDesktop = result);
  }

  login() {
    // Check latest planned maintenance status
    this.common.checkMaintenanceStatus()
      .then((status) => {
        if (status) {
          void this.router.navigate([AppRoutes.plannedMaintenance]);
        } else {
          // Remove saved data from storage
          this.localStorageService.clearData();
          // Auth0 login, with redirect to route path
          this.auth.login("dashboard");
        }
      })
      .catch((error) => {
        this.logService.logError(error);
      });
  }


}
