import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Role, RolesFromApi } from 'src/app/shared/enums/role';
import { ProfileModel } from 'src/app/shared/models/profile.Model';
import { RoleModel } from 'src/app/shared/models/role.Model';
import { AuthService } from '../auth-service/auth0client.service';
import { environment } from 'src/environments/environment';

// available at root level
@Injectable({
  providedIn: 'root'
})
// provides roles related operations for loggedin user
export class RoleService {

  // constructor with AuthService injected
  constructor(private auth: AuthService, @Inject(DOCUMENT) private document: Document) { }

  // loggedin user's role
  roles = new BehaviorSubject<Array<RoleModel>>([]);
  isAuthorized = new BehaviorSubject<boolean>(null);

  // returns the array of roles for current logged in user
  getRoles(): Observable<RoleModel[]> {
    // if role value is empty then make call for roles
    if (this.roles.getValue().length === 0) {
      // read role domain key for specific enviroment
      // subscribe for id token
      this.auth.getIdToken$.subscribe(iTkn => {
        // get domain specific roles and construct an array
        const userToken = iTkn as any;
        // getting current user's profile from iTkn ( from token )
        const profile: Partial<ProfileModel> = { roles: userToken.profile?.data };
        // return the array as observable
        this.roles.next(profile.roles);
      });
    }
    // if already have items in  array then return array as observable
    return this.roles.asObservable();
  }

  // by checking the role, decide if current
  // user is a client admin or not
  isClientAdmin(roles: RoleModel[]): boolean {
    // return true if any of the user role is client admin or organisation admin
    return roles.some(x => x.role === Role.clientAdmin || x.role === Role.organizationUser);
  }

  // by checking the role, decide if current
  // user is a client user or not
  isClientUSer(roles: RoleModel[]): boolean {
    // return true if any of the user role is client user or standard admin
    return roles.some(x => x.role === Role.standardClientUser || x.role === RolesFromApi.standardClientAdmin);
  }

  // check if user token has any of the allowed role in it
  hasValidRole(iTkn: any, allowedRoles: string[]): boolean {
    const isInternalDomain = this.isInternalInstanceDomain()
    // getting current user's profile from iTkn ( from token )
    const roles = localStorage.getItem('role')
    if (roles != null && isInternalDomain) {
      // checking any match exists
      const roleMatches = allowedRoles.findIndex(x => x.indexOf(roles) !== -1)
      // return decision
      return roleMatches > -1;
    }
    else {
      // getting current user's profile from iTkn ( from token )
      const profile: Partial<ProfileModel> = { roles: iTkn.profile?.data };
      // checking any match exists
      const roleMatches = profile.roles?.findIndex(item => allowedRoles.indexOf(item.role) !== -1);
      // return decision
      return roleMatches > -1;
    }

  }


  // method to know the nature of domain
  isInternalInstanceDomain = (): boolean => {
    // getting origin value
    const { origin } = this.document.location;
    // getting all list of internal instances
    const { internalInstances } = environment;
    // check if the origin have internal instance 
    const isInternalDomain = (origin.indexOf(internalInstances[0]) > -1 || origin.indexOf(internalInstances[1]) > -1);
    return isInternalDomain;
  }
}
