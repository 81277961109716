<div style="display: flex; border-bottom: solid #f5f5f5;" *ngIf="!showItemGroupPage">
    <nx-icon
        [ngClass]="itemGroupList && itemGroupAssignedList && itemGroupAssignedList.length > 0 &&
        itemGroupList.length > 1? 'pointer itemGroupIcon nx-margin-top-xs nx-margin-bottom-xs   nx-margin-left-s nx-margin-right-xs' : 'itemGroupIcon  nx-margin-top-xs nx-margin-bottom-xs   nx-margin-left-s nx-margin-right-xs'"
        (click)="itemGroupList && itemGroupList.length > 1 && openModal()" name="filter" size="s">
    </nx-icon>

    <span class=" mobile-label-ItemGroup  nx-margin-top-s nx-margin-right-xs">
        Business Area:

    </span>

    <span *ngIf="popupCancelled" (click)="openModal()"
        class=" mobile-label-ItemGroup  nx-margin-top-s nx-margin-right-xs">
        Business Area:

    </span>
    <p *ngIf=" itemGroupList && itemGroupList.length ===1" class=" nx-margin-top-s nx-margin-right-xs">
        {{itemGroupAssignedList[0]}} </p>
    <a *ngIf="itemGroupList && itemGroupAssignedList && itemGroupAssignedList.length > 0 &&
        itemGroupList.length > 1" class=" nx-margin-top-s  nx-margin-right-xs">
        {{itemGroupAssignedList[0]}} {{itemGroupAssignedList.length > 1? " +": ""}} </a>


</div>



<div class="modal-content-internal desktop-alignment" *ngIf="showItemGroupPage">
    <div class="selectedDivAlignment nx-margin-left-s nx-margin-top-s">
        <h2>Please select a Business Area</h2>
    </div>

    <p class="nx-margin-left-s  nx-margin-top-s">{{dummyText}}</p>
    <hr>
    <div class=" nx-margin-left-s">

        <div class="nx-margin-x-s   nx-margin-top-m " selectable>
            <form>
                <nx-radio-group [(ngModel)]="selectedItemGroupName" name="templateTest">
                    <nx-radio [nxValue]="group.name" *ngFor="let group of itemGroupList" class="nx-margin-bottom-m">
                        {{group.name}}
                    </nx-radio>
                </nx-radio-group>
            </form>
        </div>
    </div>

    <div class="nx-margin-left-s nx-margin-top-m">
        <nx-error *ngIf="showValidationError" appearance="text">
            Please select a Business Area
        </nx-error>
    </div>
    <div class=" nx-margin-top-xl center">
        <button [disabled]="itemGroupAssignedList && itemGroupAssignedList.length===0" class="nx-margin-right-s"
            type="button" id="clear" nxButton="secondary small" type="button" (click)="close()">
            Cancel
        </button>
        <button class="nx-margin-right-s" nxButton="primary small" (click)="save()" type="button">
            Apply
        </button>
        &nbsp;

    </div>


</div>