import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout-service';
import { DashboardViewModel, LocationDetailListSearchModel, LocationDetailSearchModel } from './dashboard.viewModel';
import { shareReplay } from 'rxjs/operators';
import { LocationDueOverDue } from 'src/app/schemas/location-due-overdue';
import { SortEvent } from '@aposin/ng-aquila/table';
import { CommonService } from 'src/app/services/common.service';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FORMFIELD_DEFAULT_OPTIONS } from '@aposin/ng-aquila/formfield';
import { AppRoutes } from 'src/app/shared/appRoutes';
import * as moment from 'moment';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { SearchModel, SearchModels } from 'src/app/schemas/searchModel';
import { LocationItemsViewModel } from '../locations/location-items/location-items.viewModel';
import { DashboardService } from 'src/app/services/graphql/dashboard.service';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ExportService } from 'src/app/services/export.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FiltersService } from 'src/app/services/filters-service';


// dashboard component to display widgets and statistics to the loggedin user
@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: FORMFIELD_DEFAULT_OPTIONS,
      useValue: { appearance: '', nxFloatLabel: 'always' }
    }
  ]
})
export class DashboardComponent implements OnInit {
  // dashboard view model object
  dashboardViewModel: DashboardViewModel = new DashboardViewModel;

  //for toggling checkboxes
  toggle: boolean = false
  // to save checkboxes values and make the button disable or able depending on its length
  checkboxes = [];
  // field to store locations due / over due data
  locationsData: LocationDueOverDue[] = [];
  // field to store current page locations due / over due data
  currentPageLocationsData: LocationDueOverDue[] = [];
  // separate field for map location data which is having only current page data from api
  locationMapData: LocationDueOverDue[] = [];
  //field to store all location data which help to re-populate location list in filter on clear all click
  allLocationsData: LocationDueOverDue[] = [];
  //location item view model to save api response for export
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();
  // viewChild template of filter 
  @ViewChild('filterTemplateModal') filterTemplateRef!: TemplateRef<any>;
  filterTemplateDialogRef!: NxModalRef<any>;
  // start date set to todays date
  startDate = moment()
  // date of past to bring overdue of locations
  locationOverDueStartDate = moment("1970-01-01");
  // locations due items count
  locationDueCount: string = "-"
  // added this to avoid flicker on map when due count is loaded
  maplocationDueCountObj: string = "-"
  // locations over due items count
  locationOverDueCount: string = "-";
  // added this to avoid flicker on map when overdue count is loaded
  maplocationOverDueCountObj: string = "-"
  // search model to store fields of search params
  queryLocationParamList: SearchModels = new SearchModels();
  // end date set to todays Date plus the number of days selected by the user in first dropdown i.e. 14, 30 or 60
  endDate = moment().add(parseInt(this.dashboardViewModel.withinNextDays.slice(0, 2)), 'days')
  // date format for filter modal
  datePickerFormat = 'DD/MM/YYYY';
  //comma separated name array for locations
  locationNamesAndCount: string = ""
  // bit to send to map with reload status
  reloadMap: boolean = true;
  // object of location search model for keeping location ids and names, start date, end date
  locationSearchModel: LocationDetailListSearchModel = new LocationDetailListSearchModel();
  //location detail
  locationDetailList: LocationDetailSearchModel[] = [];
  //locationIds list to use it 
  locationIds: any[] = []
  // search model to store fields of search params
  queryParamList: SearchModels = new SearchModels();
  // search model to store fields of search params
  queryLocationListParamList: SearchModels = new SearchModels();

  //Modal template to location contact
  @ViewChild('itemGroupsTemplate') itemGroupTemplate!: TemplateRef<any>;
  // Modal reference for item groups
  itemGroupTemplateDialogRef!: NxModalRef<any>;
  // boolean to know the first login attempt
  isFirstLogin: boolean = false;
  //Modal template to hold the properties 
  @ViewChild('template') templateRef!: TemplateRef<any>;
  // Modal reference for dialog reference
  templateDialogRef!: NxModalRef<any>;
  // contains the list of all location Ids of current assigned Item Groups
  assignedItemGroupsLocationIds: any[] = []
  // holds the list of assigned item groups
  assignedItemGroupsIds: any[] = []
  // list of pages of location IDs to be used in api calls
  numberOfPages: number[] = []
  // list of all the location Ids which are due
  dueLocationIds: any[] = []
  // list of all the location Ids which are overdue
  overdueLocationIds: any[] = []
  // due over due container heading
  // added this for unit testing purpose
  mapContainerHeading = 'Due and overdue items by location'
  // selected locations from filters
  selectedLocations: any[] = []

  constructor(private layoutService: LayoutService,
    private dashboardService: DashboardService,
    private filterService: FiltersService,
    private common: CommonService, private dialogService: NxDialogService,
    private itemServices: LocationItemService,
    private exportService: ExportService,
    private itemGroupService: ItemGroupService) { }

  ngOnInit(): void {
    // setting the min and max dates possible in start and end dates datepcikers
    this.dashboardViewModel.minStartDate = moment();
    // by default the start date can be selected upto 60 days from todays date
    this.dashboardViewModel.maxStartDate = moment().add(60, 'days');
    this.dashboardViewModel.minEndDate = this.dashboardViewModel.minStartDate
    this.dashboardViewModel.maxEndDate = this.dashboardViewModel.maxStartDate
    //subscribing to device view
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => {
      this.dashboardViewModel.isDesktop = result;


    })
    // finding difference between start and end dates of location due/overdue
    this.dashboardViewModel.dateRangeLocationsDifference = this.endDate.startOf('day').diff(this.startDate.startOf('day'), 'days');
    // subscribe to get updated information of selected Item groups and fetch data accordingly
    this.layoutService.currentAssignedItemGroups.pipe(untilDestroyed(this)).subscribe(itemGroupList => {
      if (itemGroupList && itemGroupList.length > 0) {
        this.dueLocationIds.length = 0
        this.overdueLocationIds.length = 0
        // call dashboard widgets data calls
        this.fetchDashboardWidgetsData();

      }
    })

  }


  fetchDashboardWidgetsData = () => {
    // fetch the assigned Item Group Ids
    this.assignedItemGroupsIds = this.itemGroupService.getSelectedItemGroupsIds();
    if (this.assignedItemGroupsIds && this.assignedItemGroupsIds.length > 0) {
      // fetch and map location due/overdue filters
      this.fetchLocationDueOverDueFilters()
      // fetch user filter vaules from dashboard service
      this.fetchFiltersPersonalisation();
    }

  }

  // fetch and map location due/overdue filters from dashboard service
  fetchLocationDueOverDueFilters = () => {
    //setting filter days
    if (this.filterService.locationDueOverDueFilter.startDate != undefined && this.filterService.locationDueOverDueFilter.endDate != undefined
    ) {
      this.dashboardViewModel.withinNextDays = this.filterService.locationDueOverDueFilter.withinNextDays ?? '30 days';
      this.dashboardViewModel.dateRangeSwitcherModel = this.filterService.locationDueOverDueFilter.switchStatus;
      this.startDate = moment(this.filterService.locationDueOverDueFilter.startDate, 'YYYY-MM-DD')
      //checking if filter has previous date then change it to current date
      if (moment().diff(this.startDate, 'day') > 0) {
        this.startDate = moment()
        this.endDate = moment().add(parseInt(this.dashboardViewModel.withinNextDays.slice(0, 2)), 'days')
      }
      else {
        this.endDate = moment(this.filterService.locationDueOverDueFilter.endDate, 'YYYY-MM-DD')
      }
      // finding difference between start and end dates of location due/overdue
      this.dashboardViewModel.dateRangeLocationsDifference = this.endDate.startOf('day').diff(this.startDate.startOf('day'), 'days');
      if (this.filterService.locationDueOverDueFilter.locationDetailList.length !== undefined)
        this.filterService.locationDueOverDueFilter.locationDetailList.forEach(row => {
         this.selectedLocations.push(row.locationId)
        });
    }
  }

  // contains list of calls to promises in Promise.all
  //-------------Calls Details---------------//
  // 1. need to check for any previous loaded stored data of maps 
  // 2. if itemgroup is changed and stored data is related to old item group, need to reset
  // 3. Send 3 promise calls in Promise All.
  fetchOverDueLocationCount = (locationIds: any, isFilterCall: boolean) => {
    // remove all the previous saved locations details to load fresh data
    if (this.locationMapData && this.locationMapData.length > 0)
      this.locationMapData.length = 0
    if (this.locationsData && this.locationsData.length > 0)
      this.locationsData.length = 0
    const mapStoredData = this.dashboardService.retrieveMapLoadedData();
    if (isFilterCall || this.assignedItemGroupsIds.indexOf(mapStoredData.selectedItemGroupId) < 0)
      this.dashboardService.resetMapStoredData();
    //  1st call to fetch the count of overdue items, 2nd call to fetch the count of due items, 3rd to fetch details of location Ids due locations
    Promise.allSettled([this.fetchDueOverDueCountsFromAPI(this.locationOverDueStartDate, moment(), locationIds, mapStoredData.totalOverDueCountMap, mapStoredData.selectedItemGroupId), this.fetchDueOverDueCountsFromAPI(moment(), this.endDate, locationIds, mapStoredData.totalDueCountMap, mapStoredData.selectedItemGroupId, false), this.getDueLocationsAndCount(moment(), this.endDate, locationIds, mapStoredData.allLocationsDataMap, mapStoredData.selectedItemGroupId)]).then((values) => {
      // call to fetch the locationIds of overdue items
      void this.getOverDueLocationsAndCount(this.locationOverDueStartDate, moment(), locationIds, mapStoredData.allLocationsDataMap, mapStoredData.selectedItemGroupId).then(_ => {
        // fetch the details of all location Ids (overdue/due)
        this.fetchLocationDetailsByPromiseAll()
      })
    })
  }

  // APi call to know the count of locations Due / Overdue count within the specific date range amd locations
  // ------PARAMETERS DETAILS-------//
  //  dueFrom => Starting date, dueTo => endDate
  // locationIDs have all the selected locations from filter model
  // isOverdueCountCall => If true, save the totalCount value to overDue object else save in Due object 
  // aggregation => need to pass to API to fetch details
  fetchDueOverDueCountsFromAPI = (dueFrom: moment.Moment, dueTo: moment.Moment, locationIds: any, dueOverDueCountFromService: string, selectedItemGroup: any[], isOverdueCountCall: boolean = true, aggregationType: string = "itemLocationCode") => {
    return new Promise<string>((resolve, reject) => {
      try {
        if (isOverdueCountCall && dueOverDueCountFromService && selectedItemGroup && selectedItemGroup.length > 0 && this.assignedItemGroupsIds.indexOf(selectedItemGroup) != -1) {
          this.locationOverDueCount = dueOverDueCountFromService
          resolve("Ok")
        }
        else if (!isOverdueCountCall && dueOverDueCountFromService && selectedItemGroup && selectedItemGroup.length > 0 && this.assignedItemGroupsIds.indexOf(selectedItemGroup) != -1) {
          this.locationDueCount = dueOverDueCountFromService
          resolve("Ok")
        }
        else {
          // remove all existing search parameters
          this.queryParamList.searchList.forEach(searchItem => this.queryParamList.remove(searchItem))
          // add the search param with updated values
          this.common.updateSearchParamList(this.queryParamList, "itemNextInspectionDate", `[${dueFrom.format('YYYY-MM-DD')} TO ${dueTo.format('YYYY-MM-DD')}]`);
          this.common.updateSearchParamList(this.queryParamList, "itemLocationCode", locationIds.join('|'));
          this.dashboardService.getLocationDefects(this.queryParamList.searchList, aggregationType, false)
            .pipe(untilDestroyed(this), shareReplay())
            .subscribe(result => {
              // check if we have required objects before accessing
              if (result?.data?.search) {
                if (isOverdueCountCall) {
                  this.locationOverDueCount = result.data.search.totalCount
                }
                else {
                  this.locationDueCount = result.data.search.totalCount
                }
              }
              resolve("Ok")
            });
        }
      }
      catch (exception) {
        reject()
      }
    })
  }

  // apply the default sorting by desc 
  applySorting = () => {
    this.locationsData.sort((a, b) => { return this.common.compare(a.overdueCount ? a.overdueCount : "", b.overdueCount ? b.overdueCount : "", 'desc') });
  }

  // change the check property based on filters data
  // assign updated list to variable to relect it on filters
  checkLocationsBasedOnFiltersForMap = () => {
    // If due location widget have filters
    if (!this.filterService.locationDueOverDueFilter.locationDetailList) {
      // iterate to check all locations by default
      this.allLocationsData.forEach(location => {
      this.selectedLocations.push(location.id)
    })
    }

    //get location name, anc count
    this.getlocationNamesAndCount();
  }

  //fetch user personalisation 
  fetchFiltersPersonalisation() {
    // call to get location details to populate on Due/Overdue widget
    this.getLocationsListForDueOverDueWidget()
    // sort the list w.r.t criteria
    this.applySorting();
  }


  // sort the data
  sortTable(sort: SortEvent) {
    // if returned results are less than 11, we can apply local sorting
    this.locationMapData.sort((a, b) => {
      switch (sort.active) {
        case 'name': return this.common.compare(a.name, b.name, sort.direction);
        case 'address': return this.common.compare(a.postalAddress?.address1, b.postalAddress?.address1, sort.direction);
        case 'overdue': return this.common.compare(a.overdueCount, b.overdueCount, sort.direction);
        case 'due': return this.common.compare(a.dueCount, b.dueCount, sort.direction);
        default: return 0;
      }
    });
  }

  // function to get sum of over due items of a location
  getOverdueItemsTotal = () =>
    this.locationsData.reduce(function (acc, obj) { return acc + (obj.overdueCount ?? 0); }, 0);

  // function to get sum of due items of a location
  getDueItemsTotal = () =>
    this.locationsData.reduce(function (acc, obj) { return acc + (obj.dueCount ?? 0); }, 0);


  // on scroll toggle userHasScrolled variable to true and hide sticky button text in view list mobile grid
  // on scroll stop make icon + text visible
  @HostListener('scroll', ['$event']) scrollHandler(event) {
    // finding the windows height 
    this.dashboardViewModel.getScreenHeight = window.innerHeight;
    // setting the containers height by subtracting 300 i.e. the header, filters height from mobile-view-list-container
    this.dashboardViewModel.mobileViewListHeight = this.dashboardViewModel.getScreenHeight - 300;
    // check the presence, before accessing the property
    if (event?.srcElement) {
      // this if statement is added to only call this function when the height is slightly less than the actual height of the div. This is done to solve the issue of tickling of button at the bottom of the div
      if (event.srcElement.scrollTop < (event.srcElement.scrollHeight * 58 / 100)) {
        this.dashboardViewModel.userHasScrolled = true;
        clearTimeout(this.dashboardViewModel.timeout);
        this.dashboardViewModel.timeout = setTimeout(() => {
          this.dashboardViewModel.userHasScrolled = false
        }, 400)
      }
    }
  }

  //change view from map to list view
  changeView = _ => this.showDiv("list")

  // function for navigation to items screen to see both due / overdue items
  // this is the case when we click on any marker of map, and its info winfow appears
  // then we click on location name, it is redirected to items to display both due / over due items
  navigateForOverdueDueItems(locationId: string, isoverDue: boolean) {
    // extract location name by id
    let locationName;
    if (locationId)
      locationName = this.extractLocationName(locationId)
    //saving filters values for all selected location
    this.saveFilters(this.startDate.format('YYYY-MM-DD'), this.endDate.format('YYYY-MM-DD'));
    //here preparing the list of location which have due/overdue items not all location
    this.prepareLocationFilterSearchModel(locationId, locationName, isoverDue)

    // prepare location search modal to perform filtering according to it
    const startDate = isoverDue || isoverDue.toString() == "" ? this.common.formatDateAndAddDays(new Date("1970-01-01"), 0) : this.startDate.format('YYYY-MM-DD')
    const endDate = isoverDue ? this.common.formatDateAndAddDays(new Date(), 0) : this.endDate.format('YYYY-MM-DD')

    //Creating object to save new values of this object
    let searchObj: LocationDetailListSearchModel = {
      startDate: startDate,
      endDate: endDate,
      locationDetailList: this.locationDetailList,
      withinNextDays: ""
    }
    void this.common.navigate(AppRoutes.clientLocationsItems, { state: searchObj });

  }

  // function to prepare filter search modal 
  prepareLocationFilterSearchModel(locationId, locationName, isoverDue) {
    //in case single location due/overdue
    //list goes on increase when we do multiple 
    //filter change, which cause issue on item list,
    // so need to initialize it for fresh filter data
    this.locationDetailList = [];
    if (locationId && locationName) {
      let locationdetail = {} as LocationDetailSearchModel;
      locationdetail.locationId = locationId;
      locationdetail.locationName = locationName;
      this.locationDetailList.push(locationdetail)
    }
    else {

      // else case if for navigation of multiple locations from footer of table, needs iteration
      this.allLocationsData.filter(x => x.checked === true).forEach(location => {
        let countObj = this.locationsData.find((obj) => {
          return obj.id === location['id'];
        });
        // check if we are preparing search model for due, then append location details where due count != undefined
        if (isoverDue && countObj?.overdueCount) {
          let locationdetail = {} as LocationDetailSearchModel;
          locationdetail.locationId = location['id'];
          locationdetail.locationName = location['name'];
          this.locationDetailList.push(locationdetail)
        }
        if (!isoverDue && countObj?.dueCount) {
          let locationdetail = {} as LocationDetailSearchModel;
          locationdetail.locationId = location['id'];
          locationdetail.locationName = location['name'];
          this.locationDetailList.push(locationdetail)
        }
      })
    }
  }

  // extract location Name from list by locationId
  extractLocationName = (locationId: string) => this.locationsData.find(location => location.id == locationId).name

  // function to show/hide map or list divs on toggle button click
  showDiv = (divName: string) => {
    // check scenarios of show/hide div in mobile view, this is the case of click from mobile tabs
    // 1. if variable have div name
    if (divName == undefined && !this.dashboardViewModel.isDesktop) {
      // if variable is empty and it is mobile view, it means 2nd tab is clicked 
      // hide the location due / overdue widget div
      document.getElementById("location-widget-container").style.display = 'none'
    }
    else {
      // make the location due/ overdure widget div as block
      document.getElementById("location-widget-container").style.display = 'block'
      // get div object by its id
      const el = document.getElementById(`${divName}`);
      // check if div is present in DOM
      if (el != null)
        // mark display property of this div as block
        el.style.display = 'block';
      // check if value passed to function is equal to map
      if (divName == "map")
        // hide the list div
        document.getElementById("list").style.display = 'none'
      else
        // hide the map div in case value passed to function is list
        document.getElementById("map").style.display = 'none'
    }


  }

  // open filter modal and setting its settings
  openFilterModal() {
    this.filterTemplateDialogRef = this.dialogService.open(this.filterTemplateRef, {
      showCloseIcon: true
    })
  }

  // close filter modal upon cross icon click
  closeFilterModal() {
    this.filterTemplateDialogRef.close()
  }

  //  upon change in Within next i.e. 14, 30 or 60 days update the start and end dates accordingly
  changeDayValue(value) {
    this.dashboardViewModel.withinNextDays = value.value;
    // once within next changes then update the start and end dates relative to todays date
    this.endDate = moment().add(parseInt(this.dashboardViewModel.withinNextDays.slice(0, 2)), 'days')
    this.startDate = moment()
  }

  //export data as csv
  exportToCSV = async () => {
    const locationIds = this.selectedLocations;
    let searchModel: SearchModel[] = [
      {
        field: "itemNextInspectionDate",
        value: "[" + new Date("1970-02-25").toISOString().slice(0, 10) + " TO " + new Date(this.endDate.format('YYYY-MM-DD')).toISOString().slice(0, 10) + "]"
      },
      {
        field: "itemLocationCode",
        value: this.toTextMulti(locationIds, "|")
      }
    ]
    const apiResponse = await this.itemServices.getLocationItems(searchModel, false, "ASC", "_id", 0, 100);
    this.locationItemViewModel.itemLocations.items = apiResponse.itemDetails;
    for (let i = 1; i < Math.ceil((apiResponse.totalCount) / 100); i++) {
      // call the promise function to get items from api
      const responseList = await this.itemServices.getItemsByPromise(searchModel, false, "ASC", "_id", 0, 100);
      // check if data is returned from api
      if (responseList?.data?.search?.results) {
        // append the new page data to array to get one final array with pages data
        this.locationItemViewModel.itemLocations.items = [... this.locationItemViewModel.itemLocations.items, ...apiResponse.itemDetails]
      }
    }

    // share the details to export the content to csv
    this.exportService.exportToCsv(this.locationItemViewModel.itemLocations.items, this.dashboardViewModel.exportFileName, this.dashboardViewModel.options, this.locationsData, "MapWidget")

  }
  // toggle expand of map container onclick over Expand/Minimise Icon
  // add classes to pop up the map in a modal when maximize icon is clicked
  // remove classes to bring back the map in div when minimize icon is clicked
  expandMapContainer(expand: boolean) {
    // toggle this bit
    this.dashboardViewModel.isMapContainerExpanded = !this.dashboardViewModel.isMapContainerExpanded
    // get outer div of map / list container
    const mapContainerModalObj = document.getElementById('map-container-modal');
    // get inner div of map / list container
    const mapContainerModalContentObj = document.getElementById('map-container-modal-content');
    // if method is called to expand the div
    if (expand) {
      // add classes to both outer / inner div
      mapContainerModalObj.classList.add('map-container-modal');
      mapContainerModalContentObj.classList.add('map-container-modal-content');
    }
    else {
      // if method is called to minimize the modal
      // remove the modal classes
      mapContainerModalObj.classList.remove('map-container-modal');
      mapContainerModalContentObj.classList.remove('map-container-modal-content');
    }
  }

  // disable Apply button within filter modal under certain conditions
  disableApplyButton() {
    let isDisabled: boolean;
    // Disable button: if startDate is less than min Date i.e. todaysDate or if startDate is greater than max date i.e. 60 days from todays date
    if (this.startDate.isBefore(this.dashboardViewModel.minStartDate, 'day') || this.startDate.isAfter(this.dashboardViewModel.maxStartDate, 'day')) {
      isDisabled = true
    }
    // Disable button: if endDate is smaller than startDate or if endDate is greater than max Date i.e. 60 days from todays date
    else if (this.endDate.isBefore(this.dashboardViewModel.minEndDate, 'days') || this.endDate.isAfter(this.dashboardViewModel.maxEndDate, 'days')) {
      isDisabled = true
    }
    // upon same start and end dates allow apply button
    else if (this.startDate.isSame(this.endDate, 'days')) {
      isDisabled = false;
    }
    // under all other conditions don't make button disabled
    else {
      isDisabled = false
    }
    this.dashboardViewModel.isApplyFilterButtonDisabled = isDisabled
  }
  // concatinates multiple values as comma separated string
  toTextMulti(value: string | string[], separator: string): string {
    // if value exists
    if (value) {
      // if its an array, then join its items as string
      if (Array.isArray(value)) {
        return value.join(separator);
      } else {
        // return string
        return value;
      }
    }
    // default return is empty string
    return '';
  }
  //to get list of selected locationIds
  getSelectedLocationIds(): any[] {
    return this.selectedLocations
  }
  //get locations name and count to display on dashboard
  getlocationNamesAndCount(): void {
    //set this.locationNamesAndCount to empty
    this.locationNamesAndCount = "";
    //if all selected
    if (this.allLocationsData.length === this.selectedLocations.length) {
      this.locationNamesAndCount = "All"
      return;
    }
    const selectedLocationsDetail = this.allLocationsData.filter(location => this.selectedLocations.includes(location.id))
    const selectedLocationsCount = selectedLocationsDetail.length;
    let namesArray: any[] = []
    selectedLocationsDetail.forEach(location => {
      if (namesArray.length < 3)
        namesArray.push(location.name);
    }
    )

    if (selectedLocationsCount < 4) {
      //showing all three locations names
      this.locationNamesAndCount = `${this.toTextMulti(namesArray, ",")}`;
    }
    else {
      //count is based on no of  selected location minus min names showing
      this.locationNamesAndCount = `${this.toTextMulti(namesArray, ",")}` + " +" + `${(selectedLocationsCount - 3)}`;
    }
  }
  // save user filter
  saveFilters(filterStartDate: string, filterEndDate: string) {
    //preparing a fresh list of that are selected to save them in personalization slot
    this.locationDetailList = []
    this.allLocationsData.filter(loc => this.selectedLocations.includes(loc.id)).forEach(location => {
      let locationdetail = {} as LocationDetailSearchModel;
      locationdetail.locationId = location.id;
      locationdetail.locationName = location.name;
      this.locationDetailList.push(locationdetail)
    })
    // save the filters data in dashboard service for later access
    this.filterService.locationDueOverDueFilter.endDate = filterEndDate
    this.filterService.locationDueOverDueFilter.startDate = filterStartDate
    this.filterService.locationDueOverDueFilter.locationDetailList = this.locationDetailList
    this.filterService.locationDueOverDueFilter.withinNextDays = this.dashboardViewModel.withinNextDays
    this.filterService.locationDueOverDueFilter.switchStatus = this.dashboardViewModel.dateRangeSwitcherModel
  }

  isFilterApplied: boolean = false
  // close filter modal upon cross icon click
  applyFilter() {
    // finding difference between start and end dates of location due/overdue
    this.dashboardViewModel.dateRangeLocationsDifference = this.endDate.startOf('day').diff(this.startDate.startOf('day'), 'days');
    const locationIds = this.getSelectedLocationIds()
    this.isFilterApplied = true
    this.dueLocationIds.length = 0
    this.overdueLocationIds.length = 0
    // get location over due counts
    this.fetchOverDueLocationCount(locationIds, this.isFilterApplied);

    //get location name, anc count
    this.getlocationNamesAndCount();

    //close the model after updating the list/map
    this.filterTemplateDialogRef.close()
  }

  // prepare the search param list to be passed to item groups call
  prepareItemGroupSearchParamList = (itemGroupIds: any) => {
    this.common.updateSearchParamList(this.queryLocationParamList, "locationItemGroupId", itemGroupIds.join('|'));
  }
  // prepare the search param list to be passed to locations call
  prepareLocationSearchParamList = (locationIds: any) => {
    this.common.updateSearchParamList(this.queryLocationParamList, "locationId", locationIds.join('|'));
  }


  // method to send multiple calls based on pages of location Ids
  fetchLocationDetailsByPromiseAll = () => {
    // concatenate the Ids of overdue / due Locations
    const locationIds = [...this.overdueLocationIds, ...this.dueLocationIds]
    // calculates the number of pages we have, to send calls accordingly
    this.numberOfPages = Array.from(new Array(Math.ceil(locationIds.length / 100)), (pageNumber) => pageNumber);
    // send calls in promise all to fetch data of each page
    Promise.allSettled(Array.from(this.numberOfPages).map(page => this.getLocationDetails(locationIds, page))).then((_) => {
      this.locationsData = [...new Set(this.locationMapData)]
      this.maplocationDueCountObj = this.locationDueCount;
      this.maplocationOverDueCountObj = this.locationOverDueCount;
      // store data in dashboard service
      this.dashboardService.storeMapLoadedData(this.locationDueCount, this.locationOverDueCount, [...this.locationsData], this.assignedItemGroupsIds.length > 0 ? this.assignedItemGroupsIds[0] : "")
    });
  };


  // fetch the details of location Ids.

  getLocationDetails(locationIds: any, page: number) {
    return new Promise<string>((resolve, reject) => {
      try {
        // clear the previous search params from list
        this.queryLocationParamList.clear(0)
        this.prepareLocationSearchParamList(locationIds);
        this.dashboardService.getLocations(this.queryLocationParamList.searchList, page).subscribe((result) => {
          // check if we have required objects before accessing
          if (result && result.data && result.data.search && result.data.search.results) {
            this.updateDefectsLocatioList(result.data.search.results)
            resolve("Ok")
          }
        });
      }
      catch (exception) {
        reject()
      }
    })
  }



  // function to get the list of all locations with overdue Items from API
  getOverDueLocationsAndCount = (dueFrom: moment.Moment, dueTo: moment.Moment, locationIds: any, mapStoredLocations: LocationDueOverDue[], selectedItemGroup: any[]) => {
    // clean previous data
    return new Promise<string>((resolve, reject) => {
      try {
        if (mapStoredLocations.length > 0 && selectedItemGroup && selectedItemGroup.length > 0 && this.assignedItemGroupsIds.indexOf(selectedItemGroup) != -1) {
          mapStoredLocations.forEach(location => { if (location.overdueCount) { this.addUpdateOverDueLocationMapData(location.id, location.overdueCount) } })
          resolve("Ok")
        }
        else {
          this.queryLocationListParamList.clear(0)
          // add the search param
          this.common.updateSearchParamList(this.queryLocationListParamList, "itemNextInspectionDate", `[${dueFrom.format('YYYY-MM-DD')} TO ${dueTo.format('YYYY-MM-DD')}]`);
          this.common.updateSearchParamList(this.queryLocationListParamList, "itemLocationCode", locationIds.join('|'));
          this.dashboardService.getLocationDefectsList(this.queryLocationListParamList.searchList, "itemLocationCode", false)
            .pipe(untilDestroyed(this), shareReplay())
            .subscribe(result => {
              // check if we have required objects before accessing
              if (result?.data?.search?.buckets) {
                result.data.search.buckets.forEach(result => {
                  result.results.forEach(location => {
                    this.addUpdateOverDueLocationMapData(location.key, location.count)
                  })
                });
                resolve("Ok")
              }
            })
        }
      }
      catch (exception) {
        reject();
      }

    })
  }

  // function to get the list of all locations with due Items from API
  getDueLocationsAndCount = (dueFrom: moment.Moment, dueTo: moment.Moment, locationIds: any, mapStoredLocations: LocationDueOverDue[], selectedItemGroup: any[]) => {
    // clean previous data
    if (mapStoredLocations.length > 0 && selectedItemGroup && selectedItemGroup.length > 0 && this.assignedItemGroupsIds.indexOf(selectedItemGroup) != -1) {
      mapStoredLocations.forEach(location => { if (location.dueCount) { this.addUpdateDueLocationMapData(location.id, location.dueCount) } })
    }
    else {
      this.queryLocationListParamList.clear(0)
      // add the search params
      this.common.updateSearchParamList(this.queryLocationListParamList, "itemNextInspectionDate", `[${dueFrom.format('YYYY-MM-DD')} TO ${dueTo.format('YYYY-MM-DD')}]`);
      this.common.updateSearchParamList(this.queryLocationListParamList, "itemLocationCode", locationIds.join('|'));
      this.dashboardService.getLocationDefectsList(this.queryLocationListParamList.searchList, "itemLocationCode", false)
        .pipe(shareReplay(), untilDestroyed(this))
        .subscribe(result => {
          // check if we have required objects before accessing
          if (result?.data?.search?.buckets) {
            result.data.search.buckets.forEach(result => {
              result.results.forEach(location => {
                this.addUpdateDueLocationMapData(location.key, location.count)
              })
            });
          }
        })
    }
  }

  // method to add or update the counts and due location Ids 
  addUpdateDueLocationMapData = (key: string, count: number) => {
    this.dueLocationIds.push(key)
    // prepare the list of locations
    if (this.locationMapData && this.locationMapData.length > 0) {
      let locationObj = this.locationMapData.find(x => x.id == key)
      // update the count field if location object is already present in list
      if (locationObj) {
        locationObj.dueCount = count
      }
      else {
        // prepare the list of locations
        let locationDueOverDueObj = {} as LocationDueOverDue;
        locationDueOverDueObj.id = key;
        locationDueOverDueObj.dueCount = count;
        this.locationMapData.push(locationDueOverDueObj)
      }
    }
    else {
      // prepare the list of locations
      let locationDueOverDueObj = {} as LocationDueOverDue;
      locationDueOverDueObj.id = key;
      locationDueOverDueObj.dueCount = count;
      this.locationMapData.push(locationDueOverDueObj)
    }

  }

  // method to add or update the counts and overdue location Ids
  addUpdateOverDueLocationMapData = (key: string, count: number) => {
    this.overdueLocationIds.push(key)
    if (this.locationMapData && this.locationMapData.length > 0) {
      let locationObj = this.locationMapData.find(x => x.id == key)
      // update the count field if location object is already present in list
      if (locationObj) {
        locationObj.overdueCount = count
      }
      else {
        // prepare the list of locations
        let locationDueOverDueObj = {} as LocationDueOverDue;
        locationDueOverDueObj.id = key;
        locationDueOverDueObj.overdueCount = count;
        this.locationMapData.push(locationDueOverDueObj)
      }
    }
    else {
      // prepare the list of locations
      let locationDueOverDueObj = {} as LocationDueOverDue;
      locationDueOverDueObj.id = key;
      locationDueOverDueObj.overdueCount = count;
      this.locationMapData.push(locationDueOverDueObj)
    }
  }
  // fetch the list of all locations to show in dropdown of due/over due widget
  getLocationsListForDueOverDueWidget = () => {
    this.allLocationsData.length = 0
    this.getLocationDetailsForDueOverDueWidget(this.assignedItemGroupsIds);

  }
  // fetch the remaining of these locations by passing the Ids to get name and address
  getLocationDetailsForDueOverDueWidget(itemGroupIds: any) {
    this.queryLocationParamList.clear(0)
    this.prepareItemGroupSearchParamList(itemGroupIds);
    this.dashboardService.getLocations(this.queryLocationParamList.searchList).pipe(untilDestroyed(this), shareReplay()).subscribe(result => {
      if (result?.data?.search?.results){
        this.processGetLocationDetailsForDueOverDueWidget(result)
        .then(responseList => {
          // check if data is returned from api
          if (responseList?.data?.search?.results) {
            this.allLocationsData = responseList.data.search.results
          }
        })
        .then(() => {
           // look for any previous filters saved to map against these location
          this.checkLocationsBasedOnFiltersForMap()
          // fetch all the location IDs
          const locationIds = this.getSelectedLocationIds()
          // bit set to false to know its not a filter data call
          this.isFilterApplied = false
          // look for the due/overdue counts of these locations
          this.fetchOverDueLocationCount(locationIds, this.isFilterApplied);
        })
      }
    })

  }

  async processGetLocationDetailsForDueOverDueWidget(result): Promise<any>{
        this.allLocationsData = result.data.search.results
        // if the count of locations is > 100, need to fetch details of later pages
        for (let page = 1; page < Math.ceil((result.data.search.totalCount) / 100); page++) {
          // call the promise function to get locations from api
          await this.dashboardService.getLocationsByPromise(this.queryLocationParamList.searchList, page);
        }
  }

  // update the name property in defect location list to populate on filter dropdown
  updateDefectsLocatioList = (apiLocationList: any) => {
    apiLocationList.map(resultLocation => {
      // if we have due/overdue call, need to look to mapData list and bind the remaining data
      const locationObj = this.locationMapData.find(location => location.id == resultLocation.id)
      locationObj.name = resultLocation.name
      locationObj.postalAddress = resultLocation.postalAddress
    })
  }

  //to enable/disable the apply button based on location selection
  getSelectedlocationsCount(): boolean {
    return this.selectedLocations.length <= 0;
  }
}