import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';

@Component({
    selector: 'record-perpage-toggle',
    templateUrl: './record-per-page-toggle.component.html',
    styleUrls: ['./record-per-page-toggle.component.scss']
})

// Per Page Toggle Component to change number of records we want to fetch per page
export class RecordPerpageToggleComponent implements OnInit, OnChanges {

    // total property
    @Input() total: number = 0;
    // per page count property to set
    @Input() perPage: number;
    // output property to call parent method from this component
    @Output() onPerPageClick: EventEmitter<MouseEvent> =
        new EventEmitter<MouseEvent>();

    ngOnInit(): void {
        // This is intentional
    }

    // per page number options to show
    perPageOptions: PerPageModel[] = [];

    // detect changes in input properties
    ngOnChanges(changes: SimpleChanges) {
        // getting total property passed to per page toggle component
        const { currentValue, previousValue } = changes['total'];
        // if value is changed
        if (currentValue !== previousValue) {
            // condition to check positive values only
            if (currentValue > -1) {
                // prepare list of allowed options as per logic and assign to local property
                this.perPageOptions = PerPageModel.getPageOptions(currentValue);
            }
        }
    }
}