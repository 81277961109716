import { Pipe, PipeTransform } from '@angular/core';
import { ItemDefectsDetails } from 'src/app/schemas/location-items';
import { ItemDefectCodeBadgeColor } from '../enums/item.defect.code.badge.color';
@Pipe({
    name: 'defectBadgeColorPipe'
})
// pipe to assign badge color according to defect code
export class DefectBadgeColorPipe implements PipeTransform {
    transform(value: [ItemDefectsDetails]): [ItemDefectsDetails] {
        value.forEach(itemDefectDetails => {
            if (itemDefectDetails.defectCode && itemDefectDetails.defectCode.code) {
                switch (itemDefectDetails.defectCode.code) {
                    case "AE":
                    case "A":
                        itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.AEDefectCodeColor; break;
                    case "AI": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.AIDefectCodeColor; break;
                    case "AT": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.ATDefectCodeColor; break;
                    case "B": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.BDefectCodeColor; break;
                    case "P": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.PDefectCodeColor; break;
                    case "C": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.CDefectCodeColor; break;
                    case "N": itemDefectDetails.defectCodeBadgeColor = ItemDefectCodeBadgeColor.NDefectCodeColor; break;
                    default: break;
                }
            }
        });

        // ignore all defects with url equals to empty string
        const result = value.filter(defect => defect.url != "");
        // return the results
        return result as [ItemDefectsDetails];
    }
}