import gql from 'graphql-tag';

// graphql query to get list of location site contacts
export const searchLocationSiteContacts = gql`query search($search: [Search]! , $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: Int!){

  search(search: $search, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
          results{
              ... on Location{
                clientContacts{
                    name
                    phoneNumber
                    discipline{
                        description
                    }
                }
              }
          }
      }
  }
`;

// interface containing the details of location site contact
export interface LocationSiteContacts {
    name: string,
    phoneNumber: string,
    discipline: {
        description: string
    }
}