<header nx-header *ngIf="isDesktop">
    <nx-header-row>
        <nx-header-brand class="headerMargin">
            <nx-link>
                <a href="#">
                    <figure nxFigure>
                        <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;"
                            width="122px;">
                    </figure>
                </a>
            </nx-link>
            <nx-header-app-title>Inspection Hub</nx-header-app-title>
        </nx-header-brand>
        <nx-header-actions class="headerMargin">
            <a nxButton="secondary small" type="button" (click)="login()">Login</a>
        </nx-header-actions>
    </nx-header-row>
</header>

<!-- If device is mobile then show this menu  -->
<header nx-header *ngIf="!isDesktop" class="marginMobile">
    <nx-header-brand class="headerMargin">
        <nx-link>
            <a href="#">
                <figure nxFigure>
                    <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;" width="122px;">
                </figure>
            </a>
        </nx-link>
    </nx-header-brand>
    <nav>
        <button nxPlainButton (click)="this.isMenuVisible = !this.isMenuVisible" aria-label="open menu"
            class="expand-btn">
            <nx-icon name="bars" *ngIf="!isMenuVisible; else showCross"></nx-icon>
        </button>
    </nav>
</header>
<!-- Template for showing cross icon in mobile menu  -->
<ng-template #showCross>
    <nx-icon name="close"></nx-icon>
</ng-template>
<!-- Menu -->
<div class="showMenu menuAlignment" [style.height]="isMenuVisible ? this.mobileMenuHeight : '0px'">
    <ul>
        <a (click)="login()">Log in</a>
    </ul>
</div>
<ng-container class="container">
    <div class="background-color  center  nx-margin-y-2m  ">
        <p class="nx-margin-x-l subtitles nx-margin-top-m">Allianz Inspection Hub is currently undergoing planned
            maintenance.
        </p>
        <nx-icon class="nx-margin-x-l icon-color" nxTooltip="maintenance in progress" name="product-repair"
            outline="true" fill="true" size="m"></nx-icon>
        <br>
        <p class="nx-margin-x-l subtitles-text-font-small">Sorry for the inconvenience. We'll be back up and running as
            soon as possible</p>
        <br>
        <p class="nx-margin-x-l subtitles-text-font-small">{{ maintenanceId }}</p>
    </div>
</ng-container>
<div Class="contentAlignment"><app-footer></app-footer></div>