<app-loading></app-loading>

<div class="text-formatting">
    <span *ngIf="!itemGroupViewModel.isDesktop && !itemGroupViewModel.showFullPageDescription"
        id="page-desc">{{itemGroupViewModel.pageDescription
        | slice:0:50}}
        <span (click)="itemGroupViewModel.showFullPageDescription = true">...</span>
    </span>
    <span id="page-desc"
        *ngIf="itemGroupViewModel.isDesktop || itemGroupViewModel.showFullPageDescription">{{itemGroupViewModel.pageDescription}}
    </span>
</div>

<div nxRow class="nx-margin-top-m">

    <nx-checkbox class="nx-margin-left-3m" (checkedChange)="openAssignAllPermissionModel()"
        [checked]="itemGroupViewModel.checked"
        (checkboxChange)="itemGroupViewModel.checked = ! itemGroupViewModel.checked">Assign all
        permissions</nx-checkbox>
    <div>
        <info-icon [infoMessage]="itemGroupViewModel.infoMsgAssignLocations" [height]="itemGroupViewModel.popoverHeight"
            [width]="itemGroupViewModel.popoverWidth" onclick="event.stopPropagation()"></info-icon>
    </div>

</div>

<div class="align-text-add">
    <div class="high-light-count">
        <p class=" caption-formatting" id="table-caption">{{itemGroupViewModel.tableCaption}}</p>

    </div>
    <div class="align-icons flex" (click)="addItemGroup()">
        <nx-icon name="plus" size="xs" fill="true" outline="true"
            class="add-icon nx-margin-top-xs nx-margin-right-xs"></nx-icon>
        <p class="standard-blue">Add Business Area(s) </p>
    </div>
</div>
<div class="wrapper ">

    <div class="tableFixHead" *ngIf="itemGroupViewModel.isDesktop">
        <table nxTable nxSort>
            <caption></caption>
            <thead>
                <tr nxTableRow>
                    <th scope="col" style="width: 60%;" nxHeaderCell nxSortHeaderCell="name">
                        Name
                    </th>
                    <th scope="col" nxHeaderCell nxSortHeaderCell="locations">
                        Locations<span> <info-icon class="nx-margin-left-xs"
                                [infoMessage]="itemGroupViewModel.infoPopupMessage"
                                [height]="itemGroupViewModel.popoverHeight" [width]="itemGroupViewModel.popoverWidth"
                                onclick="event.stopPropagation()"></info-icon></span>
                    </th>
                    <th scope="col" nxHeaderCell>

                    </th>

                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr nxTableRow selectable *ngFor="let itemGroup of itemGroupViewModel.itemGroupList; let i">
                        <td nxTableCell>
                            <span class="nx-margin-left-m">{{itemGroup.name}}</span>
                        </td>
                        <td nxTableCell (click)="newLocationsModel(itemGroup.id,itemGroup.name )"><span
                                class="nx-margin-left-s"
                                nxTooltip="View, add and remove locations for this Business Area"><a
                                    class="standard-blue">{{itemGroup.locationCount}}</a></span></td>
                        <td nxTableCell> <nx-icon name="trash-o" size="s" class="standard-blue"
                                [nxTooltip]="itemGroupViewModel.removeTooltip"
                                (click)=" itemGroupViewModel.itemGroupList.length > 1 ? openRemoveItemGroupModel(itemGroup.id) : null"
                                [ngClass]="{'disable-icon': itemGroupViewModel.itemGroupList.length==1}">
                            </nx-icon></td>
                    </tr>
                    <tr *ngIf="
                    itemGroupViewModel.itemGroupList.length === 0 
                  ">
                        <td nxTableCell>
                            <i class="fas fa-minus nx-margin-left-m " aria-hidden="true"></i>
                        </td>
                        <td nxTableCell>
                            <i class="fas fa-minus nx-margin-left-s" aria-hidden="true"></i>
                        </td>
                        <td nxTableCell>
                            <i class="fas fa-minus nx-margin-left-s" aria-hidden="true"></i>
                        </td>
                    </tr>

                </ng-container>
            </tbody>
        </table>
    </div>

    <div *ngIf="!itemGroupViewModel.isDesktop">
        <hr>
        <div nxLayout="grid">
            <div nxRow *ngFor="let itemGroup of itemGroupViewModel.itemGroupList">
                <div nxCol="10">
                    <div nxRow>
                        <div nxCol="4" class="nx-font-weight-bold font-size">Name</div>
                        <div nxCol="8" class="font-size">{{itemGroup.name}}</div>
                    </div>
                    <div nxRow>
                        <div nxCol="4" class="nx-font-weight-bold font-size">Locations</div>
                        <div nxCol="8" (click)="newLocationsModel(itemGroup.id,itemGroup.name )">
                            <a class="standard-blue underline-text font-size">{{itemGroup.locationCount}}</a>
                        </div>
                    </div>
                </div>
                <div nxCol="2" class="center-align-trash-icon">
                    <nx-icon name="trash-o" size="s" class="standard-blue"
                        [nxTooltip]="itemGroupViewModel.removeTooltip"
                        (click)=" itemGroupViewModel.itemGroupList.length > 1 ? openRemoveItemGroupModel(itemGroup.id) : null"
                        [ngClass]="{'disable-icon': itemGroupViewModel.itemGroupList.length==1}">
                    </nx-icon>
                </div>
                <hr nxCol="12">
            </div>
        </div>
        <div *ngIf="itemGroupViewModel.itemGroupList.length === 0">
            <div class="center increase-font-size nx-margin-bottom-m">There are no Business Areas to display.</div>
        </div>
    </div>

    <div class="nx-margin-top-m center">
        &nbsp;
        <button id="clear" nxButton="secondary small" type="button" (click)="addItemGroup()">
            + Add Business Area(s)
        </button>
    </div>
</div>

<ng-template #addItemGroupTemplate class="root-modal">
    <app-loading></app-loading>
    <div id="step1" *ngIf="showDiv.itemGroupDiv">
        <div>
            <h2 class="center text-alignment">Add Business Area(s)</h2>

        </div>

        <div>
            <p style="color: black; font-weight: 600;">To add new Business Area(s) please use search or select manually
                from
                the grid.
                <br />
                When you select a single Business Area please select 'Save' to save all locations for that Business Area
                or
                select
                'Next' to select locations manually. When you select multiple Business Areas please select 'Save' to
                save
                all
                locations for those Business Areas.
            </p>
        </div>

        <div nxRow class="flex">
            <div nxCol="7">
                <nx-formfield nxLabel="Search Business Area">
                    <input nxInput name="itemSearch" [(ngModel)]="itemGroupViewModel.itemGroupName" />
                </nx-formfield>
            </div>
            <div nxCol="4" class="nx-margin-left-2xs nx-margin-top-2m ">
                <button (click)="search()" [disabled]="itemGroupViewModel.searchDisabled" id="search"
                    nxButton="primary small" type="button">
                    <nx-icon name="search" size="s"></nx-icon>
                    Search</button>
                &nbsp;
                <button type="reset" id="clear" nxButton="secondary small" type="button"
                    (click)="clear()">Clear</button>
            </div>
        </div>
        <div>
            Search Results: <span class="blue-color">{{itemGroupViewModel.itemGroupCount}}
                {{itemGroupViewModel.itemGroupCount <=1 ?'Business Area':'Business Areas'}}</span>
        </div>
        <div class="tableFixHead">
            <table nxTable nxSort (sortChange)="sortAddItemGroupGrid($event)">
                <caption></caption>
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell class="nx-table-cell--checkbox" aria-label="Row selection">
                            <nx-checkbox *ngIf="itemGroupViewModel.adminItemGroupList.length > 0"
                                (checkedChange)="toggleAll()" aria-label="Toggle selection of all rows"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </nx-checkbox>
                        </th>
                        <th nxSortHeaderCell="name" nxHeaderCell>
                            Name
                        </th>


                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr nxTableRow *ngFor="let itemGroup of this.itemGroupViewModel.adminItemGroupList;"
                            [selected]="activateSelected ? selection.isSelected(itemGroup) : itemGroup === activeRow"
                            selectable
                            (select)="activateSelected ? selection.toggle(itemGroup) : activateRow(itemGroup)">
                            <td nxTableCell class="nx-table-cell--checkbox">
                                <nx-checkbox (checkedChange)="selection.toggle(itemGroup)"
                                    aria-label="Toggle row selection"
                                    [checked]="selection.isSelected(itemGroup)"></nx-checkbox>
                            </td>
                            <td nxTableCell>{{itemGroup.name}}</td>

                        </tr>
                        <tr *ngIf="itemGroupViewModel.adminItemGroupList.length === 0">
                            <td nxTableCell class="center" colspan="2">
                                There are no Business Areas to display.
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>

        </div>
        <div class="center pagination-display-inline">
            <record-perpage-toggle class="nx-margin-top-s pagination-record" (onPerPageClick)="togglePerPage($event)"
                [total]="itemGroupViewModel.resultCountAddItemGroup" [perPage]="itemGroupViewModel.perPage">
            </record-perpage-toggle>
            <div class="paginationContainer  nx-margin-left-s nx-margin-top-s">
                <nx-pagination *ngIf="itemGroupViewModel.showPaging"
                    [nxCount]="itemGroupViewModel.resultCountAddItemGroup" [nxPage]="itemGroupViewModel.page"
                    [nxPerPage]="itemGroupViewModel.perPage" nxType="advanced" (nxGoPrev)="prevPage()"
                    (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingClass">
                </nx-pagination>
            </div>
        </div>
        <div class="nx-margin-top-l center">

            &nbsp;
            <button id="cancel" nxButton="secondary small" type="button" (click)="closeAddItemGroupModal()"
                [disabled]="selection.selected.length===0">
                Cancel
            </button>
            <button class="nx-margin-left-s" id="cancel" nxButton="primary small" type="button"
                [disabled]="selection.selected.length===0"
                (click)="selection.selected.length===0 ? null :addItemGroups()">
                Add
            </button>
            <button class="nx-margin-left-s" id="cancel" nxButton="primary small" type="button"
                [disabled]="selection.selected.length!=1"
                (click)="showDiv.itemGroupDiv = !showDiv.itemGroupDiv;showDiv.locationDiv = true;openLocationModelNextStep() ">
                Next
            </button>
        </div>
    </div>

    <div id="step2" *ngIf="showDiv.locationDiv">

        <div *ngIf="itemGroupViewModel.isDesktop">
            <div nxRow>
                <h1 nxHeadline="subsection-medium" class="nx-margin-left-3xl">
                    Select location for {{itemGroupViewModel.groupNameForModel}}
                </h1>
            </div>
            <div class="center">
                <strong>To add locations please use search or select manually from the grid</strong>
            </div>

            <div nxRow class="nx-margin-x-l">

                <div nxCol="7" class="desktopSearchFilter-change-width">
                    <nx-formfield>
                        <input nxInput [(ngModel)]="itemGroupViewModel.locationNameAndAddress"
                            placeholder="Please enter three characters at least" id="name" tabindex="1" />
                    </nx-formfield>
                </div>

                <div class="alignButtons">
                    <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                        [disabled]="itemGroupViewModel.modelSearchDisabled" (click)="searchLocations()">Search</button>
                    <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                        (click)="clearSearch(true)" type="button">Clear</button>
                </div>

            </div>

            <div>
                Search Results: <span class="blue-color">{{locationViewModel.locations.length}}
                    {{locationViewModel.locations.length ===1 ?'location':'locations'}}</span>
            </div>
            <ng-container>
                <div class="tableFixHead">
                    <table id="newAddLocations" nxTable class="nx-margin-top-s" nxSort (sortChange)="sortGrid($event)">
                        <caption></caption>
                        <thead class="sticky-thead">
                            <tr nxTableRow>
                                <th nxHeaderCell class="nx-table-cell--checkbox" aria-label="Row locationSelection">
                                    <nx-checkbox (checkedChange)="toggleAllLocations()"
                                        aria-label="Toggle locationSelection of all rows"
                                        [checked]="locationSelection.hasValue() && isAllLocationSelected()"
                                        [indeterminate]="locationSelection.hasValue() && !isAllLocationSelected()"
                                        *ngIf="locationViewModel.resultCount > 0">
                                    </nx-checkbox>
                                </th>
                                <th scope="col" nxHeaderCell nxSortHeaderCell="name">Location name</th>
                                <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddress">Address</th>
                                <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddressPostCode">Postcode</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr nxTableRow selectable *ngFor="let locations of locationViewModel.locations">
                                    <td nxTableCell class="nx-table-cell--checkbox">
                                        <nx-checkbox (checkedChange)="toggleSelected(locations)"
                                            aria-label="Toggle row selection"
                                            [checked]="locationSelection.isSelected(locations)">
                                        </nx-checkbox>
                                    </td>
                                    <td nxTableCell>{{locations?.name}}</td>
                                    <td nxTableCell>{{locations.completeAddress}}</td>
                                    <td nxTableCell>{{locations?.postalAddress?.postcode}}</td>

                                </tr>

                                <tr *ngIf="locationViewModel.locations.length === 0" nxTableCell>

                                    <td nxTableCell> There are no locations to display.
                                    </td>

                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <!--Action section-->
                <div nxRow class="center nx-margin-top-s">
                    <div class="alignCenter">
                        <button id="previous" nxButton="secondary small" type="button" type="button"
                            (click)="showDiv.locationDiv = !showDiv.locationDiv;showDiv.itemGroupDiv = true;">
                            Previous
                        </button>
                        &nbsp;
                        <button nxButton="secondary small" type="button" class="nx-margin-right-s"
                            (click)="closeAddItemGroupModal()" type="reset">Cancel</button>
                        <button type="submit" nxButton="primary small" id="btnDesktopSave" (click)="addLocations()"
                            [disabled]="locationSelection.selected.length==0">+ Add
                            locations</button>
                        &nbsp;

                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
<ng-template #removeItemGroupTemplate>
    <app-loading></app-loading>
    <div>
        <h2 class="center text-alignment">Remove Business Area</h2>

        <p class="nx-margin-top-m increase-font-size">
            By removing this Business Area, the user will no longer have access to the associated locations.</p>
        <p class="increase-font-size">
            Are you sure
            you wish to remove it?

        </p>
        <div nxCol="12,12" class="nx-margin-top-2m center">
            &nbsp;
            <button class="nx-margin-right-s" type="cancel" id="clear" nxButton="secondary small" type="button"
                (click)="closeItemGroupModal()">
                Cancel
            </button>
            <button class="nx-margin-right-s" id="remove" nxButton="primary small" type="button"
                (click)="removeItemGroup()">
                Remove Business Area
            </button>
        </div>
    </div>

</ng-template>

<ng-template #assignAllPermission>
    <app-loading></app-loading>
    <div>
        <h2 class="center text-alignment">{{itemGroupViewModel.checked ? itemGroupViewModel.modelHeader :
            itemGroupViewModel.unassignModelHeader }}</h2>
        <div class="nx-margin-top-m"
            [innerHTML]="itemGroupViewModel.checked ? itemGroupViewModel.modelMainText :itemGroupViewModel.unassignModelMainText">
        </div>

        <div nxCol="12,12" class="nx-margin-top-2m center">
            &nbsp;
            <button class="nx-margin-right-s" type="cancel" id="clear" nxButton="secondary small" type="button"
                (click)="closeAssignAllPermissionModal()">
                Cancel
            </button>
            <button class="nx-margin-right-s" id="remove" nxButton="primary small" type="button"
                (click)="assignAllLocations()">
                {{itemGroupViewModel.checked ? itemGroupViewModel.modelButtonText :
                itemGroupViewModel.unassignModelButtonText }}
            </button>
        </div>
    </div>

</ng-template>
<!--add locations section-->

<ng-template #newLocationsTemplate class="root-modal">

    <app-loading></app-loading>
    <div *ngIf="itemGroupViewModel.isDesktop">
        <h1 class="center text-alignment">{{this.itemGroupViewModel.groupNameForModel}} locations</h1>
        <div class="increase-font-size">Please use search to find locations the user has assigned. To add new locations
            to their<br />
            permission
            set,
            please select '+ Add locations'.</div>

        <div nxRow class="nx-margin-x-l">

            <div nxCol="7" class="desktopSearchFilter-change-width">
                <nx-formfield>
                    <input nxInput [(ngModel)]="itemGroupViewModel.locationNameAndAddress"
                        placeholder="Please enter three characters at least" id="name" tabindex="1" />
                </nx-formfield>
            </div>

            <div class="alignButtons">
                <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                    [disabled]="itemGroupViewModel.modelSearchDisabled" (click)="searchLocations()">Search</button>
                <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                    (click)="clearSearch(false)" type="button">Clear</button>
            </div>

        </div>

        <div>
            Search Results: <span class="blue-color">{{locationViewModel.locations.length}}
                {{locationViewModel.locations.length ===1 ?'location':'locations'}}</span>
        </div>



        <ng-container>

            <div class="tableFixHead">
                <table id="newLocations" nxTable nxSort (sortChange)="sortGrid($event)">
                    <caption></caption>
                    <thead class="sticky-thead">
                        <tr nxTableRow>

                            <th nxSortHeaderCell="name" scope="col" nxHeaderCell>Location name</th>
                            <th nxHeaderCell scope="col" nxSortHeaderCell="postalAddress">Address</th>
                            <th nxSortHeaderCell="postalAddressPostCode" scope="col" nxHeaderCell>Postcode</th>
                            <th nxHeaderCell scope="col">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr nxTableRow selectable *ngFor="let location of locationViewModel.locations">
                                <td nxTableCell>{{location?.name}}</td>
                                <td nxTableCell>{{location.completeAddress}}</td>
                                <td nxTableCell>{{location?.postalAddress?.postcode}}</td>
                                <td nxTableCell> <nx-icon name="trash-o" size="s" class="standard-blue"
                                        [nxTooltip]="itemGroupViewModel.removeTooltip"
                                        (click)=" locationViewModel.locations.length > 1 ? openRemoveLocationModal(location.id) : null"
                                        [ngClass]="{'disable-icon': locationViewModel.locations.length==1}">
                                    </nx-icon></td>
                            </tr>
                            <div class="infinite-scroller" #theLastItem></div>
                            <tr *ngIf="locationViewModel.locations.length === 0">

                                <td nxTableCell> There are no locations to display.
                                </td>

                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!--Action section-->
            <div nxRow class="actionPanel">

                <div class="alignCenter">
                    <button type="reset" type="button" class="nx-margin-right-s" nxButton="secondary small"
                        (click)="closeLocationModal()">Cancel</button>
                    <button id="btnDesktopSave" type="submit" nxButton="primary small"
                        (click)="openAddLocationsModalFromLocationsPopup()">+ Add
                        locations</button>
                    &nbsp;

                </div>
            </div>
        </ng-container>
    </div>

</ng-template>

<!-- cancel submit and return buttons -->
<div class="return-cancel-submit-buttons">
    <button nxButton="primary small-medium" type="button"
        [disabled]="!itemGroupViewModel.areChangesMadeInUserPermissions" (click)="returnToMyTeams()">
        <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon>
        Return to profile
    </button>
    <button nxButton="secondary  small-medium" type="button"
        [disabled]="itemGroupViewModel.areChangesMadeInUserPermissions" (click)="openCancelModal()">Cancel and return to
        profile</button>
    <button nxButton="primary small-medium" type="button"
        [disabled]="itemGroupViewModel.areChangesMadeInUserPermissions" (click)="openSubmitModal()">Submit and return to
        profile</button>
</div>

<!-- modal for cancel button -->
<ng-template #cancelChangesModal>

    <div>
        <h2 class="center text-alignment">Cancel changes</h2>
        <div class="nx-margin-top-m increase-font-size">
            By cancelling, all changes made to the user's permissions will not be saved and applied to their profile.
        </div>
        <div class="nx-margin-top-m increase-font-size">
            Are you sure you wish to cancel?
        </div>

        <div class="nx-margin-top-2m center">
            <button class="nx-margin-right-s" nxButton="secondary small" type="button"
                (click)="closeCancelSubmitModal()">
                No, don't cancel
            </button>
            <button class="nx-margin-right-s" nxButton="primary small" type="button" (click)="returnToMyTeams(true)">
                Yes, cancel
            </button>
        </div>
    </div>

</ng-template>

<!-- modal for submit button -->
<ng-template #submitChangesModal>

    <div>
        <h2 class="center text-alignment">Submit changes</h2>
        <div class="nx-margin-top-m increase-font-size">
            By submitting, all changes made to the user's permissions will be saved and applied to their profile and
            will update their Inspection Hub experience.
        </div>
        <div class="nx-margin-top-m increase-font-size">
            Are you sure you wish to submit?
        </div>

        <div class="nx-margin-top-2m center">
            <button class="nx-margin-right-s" type="cancel" nxButton="secondary small" type="button"
                (click)="closeCancelSubmitModal()">
                No, don't submit
            </button>
            <button class="nx-margin-right-s" nxButton="primary small" type="button"
                (click)="returnToMyTeams(false, true)">
                Yes, submit
            </button>
        </div>
    </div>

</ng-template>

<!-- modal for submit button -->
<ng-template #removeLocationsModal>

    <div>
        <h2 class="center text-alignment">Remove location</h2>
        <div class="nx-margin-top-m increase-font-size">
            By removing this location, the user will no longer have access to it on Inspection Hub. This will remove
            access to items and Inspection reports for this location.
        </div>
        <div class="nx-margin-top-m increase-font-size">
            Are you sure you wish to remove it?
        </div>

        <div class="nx-margin-top-2m center">
            <button class="nx-margin-right-s" type="cancel" nxButton="secondary small" type="button"
                (click)="closeCancelSubmitModal()">
                Cancel
            </button>
            <button class="nx-margin-right-s" nxButton="primary small" type="button"
                (click)="removeLocationFromLocationsModal()">
                Remove location
            </button>
        </div>
    </div>

</ng-template>

<!-- add locations in locations modal for unassigned locations-->
<ng-template #addLocationsInLocationsModal>
    <app-loading></app-loading>
    <h1 class="center text-alignment">Select locations for {{this.itemGroupViewModel.groupNameForModel}}</h1>
    <div class="increase-font-size">To add locations please use search or select manually from the grid</div>

    <div class="align-search-input-buttons">
        <nx-formfield class="input-field-width">
            <input nxInput [(ngModel)]="itemGroupViewModel.unassignedLocationNameAndAddress"
                placeholder="Please enter three characters at least" id="name" tabindex="1" />
        </nx-formfield>

        <div>
            <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                [disabled]="itemGroupViewModel.unassigneModelSearchDisabled" (click)="searchUnassignedLocations()">
                <nx-icon name="search" class="nx-margin-right-2xs"></nx-icon>
                Search</button>
            <button class="nx-margin-right-s" type="reset" nxButton="secondary small" (click)="clearUnassignedSearch()"
                type="button">Clear</button>
        </div>
    </div>

    <div>
        Search Results:
        <span class="blueColor">{{itemGroupViewModel.unassignedLocations.length}}
            {{itemGroupViewModel.unassignedLocations.length ===1 ?'location':'locations'}}
        </span>
    </div>
    <ng-container>
        <div class="tableFixHead">

            <table id="addLocations" nxTable class="nx-margin-top-s" nxSort
                (sortChange)="sortUnassignedLocationTable($event)">
                <caption></caption>
                <thead class="sticky-thead">
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            <nx-checkbox (checkedChange)="toggleAllUnassignedLocations()"
                                aria-label="Toggle selection of all rows"
                                [checked]="itemGroupViewModel.locationSelection.hasValue() && isAllUnassignedLocationSelected()"
                                [indeterminate]="itemGroupViewModel.locationSelection.hasValue() && !isAllUnassignedLocationSelected()">
                            </nx-checkbox>
                        </th>
                        <th scope="col" nxHeaderCell nxSortHeaderCell="name">Name</th>
                        <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddress">Address</th>
                        <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddressPostCode">Postcode</th>

                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let unassignedLocation of itemGroupViewModel.unassignedLocations">
                        <tr nxTableRow
                            [selected]="itemGroupViewModel.activateLocationSelected ? itemGroupViewModel.locationSelection.isSelected(unassignedLocation) : unassignedLocation === itemGroupViewModel.locationActiveRow"
                            selectable
                            (select)="itemGroupViewModel.activateLocationSelected ? itemGroupViewModel.locationSelection.toggle(unassignedLocation) : LocationActivateRow(unassignedLocation)">
                            <td nxTableCell class="nx-table-cell--checkbox">
                                <nx-checkbox
                                    (checkedChange)="itemGroupViewModel.locationSelection.toggle(unassignedLocation)"
                                    aria-label="Toggle row selection"
                                    [checked]="itemGroupViewModel.locationSelection.isSelected(unassignedLocation)"></nx-checkbox>
                            </td>
                            <td nxTableCell>{{unassignedLocation?.name}}</td>
                            <td nxTableCell>{{unassignedLocation.completeAddress}}</td>
                            <td nxTableCell>{{unassignedLocation?.postalAddress?.postcode}}</td>

                        </tr>

                    </ng-container>
                    <ng-container *ngIf="itemGroupViewModel.unassignedLocations.length === 0">
                        <tr nxTableRow>
                            <td nxTableCell class="center" colspan="12">
                                There are no locations to display.
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <div class="center nx-margin-top-s">
            <div class="alignCenter">
                <button id="previous" nxButton="secondary small" type="button" (click)="navigateToLocationsStepOne()">
                    Previous
                </button>
                &nbsp;
                <button type="reset" nxButton="secondary small" type="button" class="nx-margin-right-s"
                    (click)="closeAllLocationsModals()">Cancel</button>
                <button id="btnDesktopSave" type="submit" nxButton="primary small" (click)="saveLocations()"
                    [disabled]="itemGroupViewModel.locationSelection.selected.length==0">Add</button>
                &nbsp;

            </div>
        </div>
    </ng-container>

</ng-template>

<!--location model for mobile-->

<div class="add-itemgroup-modal-mobile"
    [ngStyle]="{'display': itemGroupViewModel.showAddItemGroupModalMobile ? 'block': 'none'}"
    *ngIf="!itemGroupViewModel.isDesktop">

    <div *ngIf="showDivOnMobile.itemGroupDivMobile">
        <div class="add-itemgroup-modal-mobile-content">
            <div class="blueColor align-back-icon-employee-info" (click)="closeItemGroupMobileModal()">
                <nx-icon name="arrow-left" size="s"></nx-icon>
                <b>{{this.itemGroupViewModel.employeeHeaderInfo}}</b>
            </div>
            <div>
                <h2 class="nx-margin-top-m">Add Business Area(s)</h2>
            </div>
            <div>
                <span
                    *ngIf="!itemGroupViewModel.showAddItemGroupInfoFullContent">{{itemGroupViewModel.addItemGroupModalInfoContent
                    | slice:0:50}}
                    <span (click)="showFullContent()">...</span>
                </span>
                <span
                    *ngIf="itemGroupViewModel.showAddItemGroupInfoFullContent">{{itemGroupViewModel.addItemGroupModalInfoContent
                    }}
                </span>
            </div>

            <div>
                <nx-formfield>
                    <input nxInput name="itemSearch" [(ngModel)]="itemGroupViewModel.itemGroupName"
                        placeholder="Please enter three characters at least" />
                </nx-formfield>
                <div>
                    <button (click)="search()" [disabled]="itemGroupViewModel.searchDisabled" id="search"
                        nxButton="primary small" type="button">
                        <nx-icon name="search" size="s"></nx-icon>
                        Search</button>
                    &nbsp;
                    <button type="reset" id="clear" nxButton="secondary small" type="button"
                        (click)="clear()">Clear</button>
                </div>
            </div>

            <p class="nx-margin-top-m">Search results:
                <strong class="blueColor">
                    {{itemGroupViewModel.itemGroupCount}}
                    {{itemGroupViewModel.itemGroupCount <=1 ?'Business Area':'Business Areas'}} </strong>
            </p>
            <hr *ngIf="itemGroupViewModel.adminItemGroupList.length === 0">
            <div *ngIf="itemGroupViewModel.adminItemGroupList.length === 0" class="center nx-margin-bottom-xl">
                <strong>
                    There are no Business Areas to display.
                </strong>
            </div>
            <div [ngClass]="itemGroupViewModel.showAddItemGroupInfoFullContent ? 'reduce-height': 'height-fix'">
                <div *ngFor="let itemGroup of this.itemGroupViewModel.adminItemGroupList" class="nx-margin-bottom-xs">
                    <nx-selectable-card appearance="default" (checkedChange)="selection.toggle(itemGroup)"
                        aria-label="Toggle row selection" [checked]="selection.isSelected(itemGroup)"
                        *ngIf="itemGroupViewModel.adminItemGroupList.length > 0">
                        <div class="content">
                            {{itemGroup.name}}
                        </div>
                    </nx-selectable-card>
                </div>
            </div>

            <div class="center pagination-display-inline">
                <record-perpage-toggle class="nx-margin-top-s pagination-record"
                    (onPerPageClick)="togglePerPage($event)" [total]="itemGroupViewModel.resultCountAddItemGroup"
                    [perPage]="itemGroupViewModel.perPage">
                </record-perpage-toggle>
                <div class="paginationContainer nx-margin-top-s">
                    <nx-pagination *ngIf="itemGroupViewModel.showPaging"
                        [nxCount]="itemGroupViewModel.resultCountAddItemGroup" [nxPage]="itemGroupViewModel.page"
                        [nxPerPage]="itemGroupViewModel.perPage" nxType="advanced" (nxGoPrev)="prevPage()"
                        (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingClass">
                    </nx-pagination>
                </div>
            </div>


            <div class="center-fixed-position">
                &nbsp;
                <button id="cancel" nxButton="secondary small" type="button" (click)="closeItemGroupMobileModal()"
                    [disabled]="selection.selected.length===0">
                    Cancel
                </button>
                <button class="nx-margin-left-s" id="cancel" nxButton="primary small" type="button"
                    [disabled]="selection.selected.length===0"
                    (click)="selection.selected.length===0 ? null :addItemGroups()">
                    Add
                </button>
                <button class="nx-margin-left-s" id="cancel" nxButton="primary small" type="button"
                    (click)="showDivOnMobile.itemGroupDivMobile = !showDivOnMobile.itemGroupDivMobile; showDivOnMobile.locationDivMobile = true; openLocationModelNextStep()"
                    [disabled]="selection.selected.length!=1">
                    Next
                </button>
            </div>

        </div>
    </div>
    <!-- add new locations mobile view   -->
    <div class="add-itemgroup-modal-mobile" [ngStyle]="{'display': showDivOnMobile.locationDivMobile ? 'block': 'none'}"
        *ngIf="showDivOnMobile.locationDivMobile">
        <div class="add-itemgroup-modal-mobile-content">
            <div class="blueColor align-back-icon-employee-info" (click)="closeItemGroupMobileModal()">
                <nx-icon name="arrow-left" size="s"></nx-icon>
                <b>Add Business Area(s)</b>
            </div>
            <div nxRow>
                <h1 nxHeadline="subsection-medium" class="nx-margin-x-s">
                    Select location for {{itemGroupViewModel.groupNameForModel}}
                </h1>
            </div>
            <div class="nx-margin-x-s">
                <strong>To add locations please use search or select manually from the grid</strong>
            </div>

            <div nxRow class="nx-margin-x-s">

                <div nxCol="12">
                    <nx-formfield>
                        <input nxInput [(ngModel)]="itemGroupViewModel.locationNameAndAddress"
                            placeholder="Please enter three characters at least" id="name" tabindex="1" />
                    </nx-formfield>
                </div>

                <div class="nx-margin-x-s">
                    <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                        [disabled]="itemGroupViewModel.modelSearchDisabled" (click)="searchLocations()">Search</button>
                    <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                        (click)="clearSearch(true)" type="button">Clear</button>
                </div>

            </div>
            <div class="nx-margin-top-s nx-margin-x-s">
                Search Results: <span class="blue-color">{{locationViewModel.locations.length}}
                    {{locationViewModel.locations.length ===1 ?'location':'locations'}}</span>

            </div>

            <div class="grid-container nx-margin-top-s nx-margin-x-s ">
                <div nxLayout="grid nogutters" nxTableRow>
                    <div nxRow>
                        <div nxCol="1">
                            <nx-checkbox (checkedChange)="toggleAllLocations()"
                                aria-label="Toggle locationSelection of all rows"
                                [checked]="locationSelection.hasValue() && isAllLocationSelected()"
                                [indeterminate]="locationSelection.hasValue() && !isAllLocationSelected()"
                                *ngIf="locationViewModel.resultCount > 0">
                            </nx-checkbox>
                        </div>
                        <div nxCol="4">&nbsp;</div>
                        <div nxCol="7">&nbsp;</div>
                    </div>
                    <hr>
                    <div *ngIf="locationViewModel.locations.length === 0" class="center nx-margin-bottom-xl">
                        <strong>
                            There are no locations to display.
                        </strong>
                    </div>
                    <div *ngFor="let location of locationViewModel.locations">

                        <div nxRow>
                            <div nxCol="1">&nbsp;</div>
                            <div class="formatHeading" nxCol="4">Location name &nbsp;</div>
                            <div nxCol="7">{{location?.name}}</div>
                        </div>
                        <div nxRow>
                            <div nxCol="1">
                                <nx-checkbox (checkedChange)="toggleSelected(location)"
                                    aria-label="Toggle row selection"
                                    [checked]="locationSelection.isSelected(location)">
                                </nx-checkbox>
                            </div>
                            <div class="formatHeading" nxCol="4">Address &nbsp;</div>
                            <div nxCol="7">{{location.completeAddress}}</div>
                        </div>
                        <div nxRow>
                            <div nxCol="1">&nbsp;</div>
                            <div class="formatHeading" nxCol="4">Postcode &nbsp;</div>
                            <div nxCol="7">{{location?.postalAddress?.postcode}}</div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>

            <!--Action section-->
            <div nxRow class="center nx-margin-top-s">
                <div class="alignCenter">
                    <button id="previous" nxButton="secondary small" type="button" type="button"
                        (click)="showDivOnMobile.locationDivMobile = !showDivOnMobile.locationDivMobile;showDivOnMobile.itemGroupDivMobile = true;">
                        Previous
                    </button>
                    &nbsp;
                    <button nxButton="secondary small" type="button" class="nx-margin-right-s"
                        (click)="closeItemGroupMobileModal()" type="reset">Cancel</button>
                    <button type="submit" nxButton="primary small" id="btnDesktopSave" (click)="addLocations()"
                        [disabled]="locationSelection.selected.length==0">+ Add
                        locations</button>
                    &nbsp;

                </div>
            </div>
        </div>

    </div>

</div>

<div class="add-itemgroup-modal-mobile"
    [ngStyle]="{'display': itemGroupViewModel.showLocationsInMobile ? 'block': 'none'}"
    *ngIf="!itemGroupViewModel.isDesktop">
    <div class="add-itemgroup-modal-mobile-content">
        <div *ngIf="!itemGroupViewModel.isUnassignedLocationsModalVisibleMobile">
            <div class="blueColor align-back-icon-employee-info" (click)="closeAssignedLocationsModal()">
                <nx-icon name="arrow-left" size="s"></nx-icon>
                <b>{{this.itemGroupViewModel.employeeHeaderInfo}}</b>
            </div>
            <div class="nx-margin-top-s">
                <h1 class="nx-margin-x-3xs">{{this.itemGroupViewModel.groupNameForModel}} locations</h1>
                <div class="nx-margin-x-s">Please use search to find locations the user has assigned. To add new
                    locations
                    to their
                    permission
                    set,
                    please select '+ Add locations'.</div>
            </div>

            <div nxRow>

                <div nxCol="12" class="nx-margin-x-2xs">
                    <nx-formfield>
                        <input nxInput [(ngModel)]="itemGroupViewModel.locationNameAndAddress"
                            placeholder="Please enter three characters at least" id="name" tabindex="1" />
                    </nx-formfield>
                </div>

                <div class="nx-margin-x-s">
                    <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                        [disabled]="itemGroupViewModel.modelSearchDisabled" (click)="searchLocations()">Search</button>
                    <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                        (click)="clearSearch(false)" type="button">Clear</button>
                </div>

            </div>
            <div class="nx-margin-top-s nx-margin-left-2xs">
                Search Results: <span class="blue-color">{{locationViewModel.locations.length}}
                    {{locationViewModel.locations.length ===1 ?'location':'locations'}}</span>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <ng-container>
                        <hr />
                        <div *ngFor="let location of locationViewModel.locations" class="nx-margin-x-s">
                            <div nxRow (click)="navigateToItems(location.id)">
                                <div class="formatHeading align-left" nxCol="4">Location Name</div>
                                <div nxCol="8">
                                    {{ location?.name }}
                                </div>
                            </div>
                            <div nxRow (click)="navigateToItems(location.id)">
                                <div class="formatHeading align-left" nxCol="4">Address</div>

                                <div nxCol="7">
                                    {{
                                    location?.postalAddress?.address1 != "" &&
                                    location?.postalAddress?.address1 != null
                                    ? location?.postalAddress?.address1
                                    : ""
                                    }}
                                    {{
                                    location?.postalAddress?.address2 != "" &&
                                    location?.postalAddress?.address2 != null
                                    ? ", " + location?.postalAddress?.address2
                                    : ""
                                    }}
                                    {{
                                    location?.postalAddress?.address3 != "" &&
                                    location?.postalAddress?.address3 != null
                                    ? ", " + location?.postalAddress?.address3
                                    : ""
                                    }}
                                    {{
                                    location?.postalAddress?.address4 != "" &&
                                    location?.postalAddress?.address4 != null
                                    ? ", " + location?.postalAddress?.address4
                                    : ""
                                    }}
                                    {{
                                    location?.postalAddress?.address5 != "" &&
                                    location?.postalAddress?.address5 != null
                                    ? ", " + location?.postalAddress?.address5
                                    : ""
                                    }}
                                    {{
                                    location?.postalAddress?.address6 != "" &&
                                    location?.postalAddress?.address6 != null
                                    ? ", " + location?.postalAddress?.address6
                                    : ""
                                    }}
                                </div>
                                <div nxCol="1">
                                    <nx-icon name="trash-o" size="s" class="standard-blue"
                                        [nxTooltip]="itemGroupViewModel.removeTooltip"
                                        (click)=" locationViewModel.locations.length > 1 ? openRemoveLocationModal(location.id) : null"
                                        [ngClass]="{'disable-icon': locationViewModel.locations.length==1}">
                                    </nx-icon>
                                </div>
                            </div>
                            <div nxRow (click)="navigateToItems(location.id)">
                                <div class="formatHeading align-left" nxCol="4">Postcode</div>

                                <div nxCol="8">
                                    {{ location?.postalAddress?.postcode }}
                                </div>
                            </div>

                            <hr />
                        </div>
                        <div *ngIf="locationViewModel.locations.length === 0">
                            <div nxRow>
                                <div class="align-left" nxCol="8">There are no locations to display.</div>
                                <div nxCol="4">&nbsp;</div>
                            </div>

                        </div>
                    </ng-container>

                </div>
            </div>
            <div nxRow class="actionPanel nx-margin-top-xs">

                <div class="alignCenter">
                    <button type="reset" type="button" class="nx-margin-right-s" nxButton="secondary small"
                        (click)="closeAssignedLocationsModal()">Cancel</button>
                    <button id="btnDesktopSave" type="submit" nxButton="primary small"
                        (click)="openAddLocationsModalFromLocationsPopupMobile()">+ Add
                        locations</button>
                    &nbsp;

                </div>
            </div>
        </div>

        <div *ngIf="itemGroupViewModel.isUnassignedLocationsModalVisibleMobile">
            <div class="blueColor align-back-icon-employee-info" (click)="closeUnassignedLocationsModal()">
                <nx-icon name="arrow-left" size="s"></nx-icon>
                <b>{{itemGroupViewModel.groupNameForModel}} locations</b>
            </div>
            <h1>Select locations for {{this.itemGroupViewModel.groupNameForModel}}</h1>
            <div class="increase-font-size">To add locations please use search or select manually from the grid.</div>

            <div>
                <nx-formfield>
                    <input nxInput [(ngModel)]="itemGroupViewModel.unassignedLocationNameAndAddress"
                        placeholder="Please enter three characters at least" id="name" tabindex="1" />
                </nx-formfield>

                <div>
                    <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                        [disabled]="itemGroupViewModel.unassigneModelSearchDisabled"
                        (click)="searchUnassignedLocations()">
                        <nx-icon name="search" class="nx-margin-right-2xs"></nx-icon>
                        Search</button>
                    <button class="nx-margin-right-s" type="reset" nxButton="secondary small"
                        (click)="clearUnassignedSearch()" type="button">Clear</button>
                </div>
            </div>

            <div class="nx-margin-top-m">
                Search Results:
                <span class="blueColor">{{itemGroupViewModel.unassignedLocations.length}}
                    {{itemGroupViewModel.unassignedLocations.length ===1 ?'location':'locations'}}
                </span>
            </div>

            <div *ngFor="let unassignedLocation of itemGroupViewModel.unassignedLocations">
                <nx-selectable-card appearance="default"
                    (checkedChange)=" itemGroupViewModel.locationSelection.toggle(unassignedLocation)"
                    aria-label="Toggle row selection"
                    [checked]="itemGroupViewModel.locationSelection.isSelected(unassignedLocation)"
                    class="nx-margin-bottom-xs">
                    <div class="content">
                        <div nxLayout="grid">
                            <div nxRow>
                                <div nxCol="4">
                                    <div nxRow>Name</div>
                                    <div nxRow>Address</div>
                                    <div nxRow>Post code</div>
                                </div>
                                <div nxCol="8">
                                    <div nxRow> {{unassignedLocation?.name}}</div>
                                    <div nxRow> {{unassignedLocation?.completeAddress}}</div>
                                    <div nxRow> {{unassignedLocation?.postalAddress?.postcode}}</div>

                                </div>
                            </div>
                        </div>

                    </div>
                </nx-selectable-card>
            </div>

            <div *ngIf="itemGroupViewModel.unassignedLocations.length === 0">
                <hr>
                <div class="center increase-font-size nx-margin-bottom-m">There are no locations to display.</div>
            </div>

            <div class="center nx-margin-top-s">
                <div class="alignCenter">
                    <button id="previous" nxButton="secondary small" type="button"
                        (click)="closeUnassignedLocationsModal()">
                        Previous
                    </button>
                    &nbsp;
                    <button type="reset" nxButton="secondary small" type="button" class="nx-margin-right-s"
                        (click)="closeAllLocationsModalsInMobile()">Cancel</button>
                    <button id="btnDesktopSave" type="submit" nxButton="primary small" (click)="saveLocations()"
                        [disabled]="itemGroupViewModel.locationSelection.selected.length==0">Add</button>
                    &nbsp;

                </div>
            </div>
        </div>
    </div>

</div>