//to manage roles of user , will use these enum
export enum Role {
    clientAdmin = "CLIENT_ADMIN",
    standardClientUser = "CLIENT_USER",
    internalAdmin = "INTERNAL_ADMIN",
    organizationUser = "ORGANISATION_ADMIN",

}

//to manage roles of user coming from the api
export enum RolesFromApi {
    clientAdmin = "CLIENT_ADMIN",
    standardClientAdmin = "STANDARD_ADMIN",
    standardClientUser = "STANDARD_USER",
    organizationUser = "ORGANISATION_ADMIN",
    internalAdmin = "INTERNAL_ADMIN"
}

//to manage roles of user for display on front end
export enum DisplayRoles {
    clientAdmin = "Client Admin",
    standardClientAdmin = "Standard Client Admin",
    standardClientUser = "Standard Client User",
    organizationUser = "Organization Admin",
    empty = ""
}