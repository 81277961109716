import { Component, Input } from "@angular/core";

// info icon component to display info icon and on click, opens the popover
@Component({
    selector: 'info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.scss'],
})

export class InfoIconComponent {
    //info icon name need to change it for bg color
    iconName: string = "info-circle-o";
    // input to show the message when popup gets open
    @Input() infoMessage: string;
    // input to assign height of popover
    @Input() height: string;
    // input to assign width of popover
    @Input() width: string;
    // change icon color  on hover (in)
    mouseOver() {
        this.iconName = "info-circle";
    }
    // change icon color  on hover (out)
    mouseOut() {
        this.iconName = "info-circle-o";
    }
}
