import { Personalisation } from "src/app/schemas/personalisation";
import { PersonalisationAction, PersonalisationActionTypes } from "../actions/personalisation.action";



const initialState: Personalisation = {
    // initialized class property , so that for user with no intial 
    // personalisation saved , we can update them without any issue
    user: { perPageToggle: '10' },
    item: { perPageToggle: '10' },
    client: { perPageToggle: '10' },
    editUser: { perPageToggle: '10' },
    mainItem: { perPageToggle: '10' }
}
// user reducer to update the state with provided actions
export function Personalisationreducer(state: Personalisation = initialState,
    action: PersonalisationAction = {
        type: PersonalisationActionTypes.GET_PERSONALISATION, payload: undefined
    }) {
    // based on action types return states
    switch (action.type) {
        case PersonalisationActionTypes.GET_PERSONALISATION:
        case PersonalisationActionTypes.UPDATE_PERSONALISATION:
            return {
                ...state,
                Personalisation: action.payload
            }
        default:
            return state;
    }
}