<app-loading></app-loading>
<!--Accordian for search and filter-->
<div class="wrapper nx-margin-top-s">
  <div
    *ngIf="locationItemViewModel.locationViewModel.locationDetails && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 0">
    <div nxRow>
      <div
        *ngIf="locationItemViewModel.isDesktop  && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length !==1">
        <p class="tagsTextFormatting nx-margin-left-m">
          Selected locations</p>
      </div>
      <div nxCol="11">
        <!--For desktop -->
        <div
          *ngIf="locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length !== 1"
          [ngClass]="{'make-fixed-to-three-rows-on-showmore': !locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3}">
          <!-- When locations are more than 3 -->
          <ul class="tagsAlignment"
            [ngClass]="{'center-alignment': locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 1}"
            *ngIf="
        locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3 &&
        locationItemViewModel.locationViewModel.showMore
      ">
            <!-- Show first 3 location -->
            <li tabindex="-1" class="tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-s"
              *ngFor="let location of locationItemViewModel.locationViewModel.locationDetails.locationDetailList.slice(0, 3)"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <li class="disableListItemStyle">
              <!--Show less option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3 &&
            !locationItemViewModel.locationViewModel.showMore
          " (click)="locationItemViewModel.locationViewModel.showMore = true">
                Show less...
              </p>
              <!--Show more option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3 &&
            locationItemViewModel.locationViewModel.showMore &&
            locationItemViewModel.isDesktop
          " (click)="locationItemViewModel.locationViewModel.showMore = false">
                Show more...
              </p>
            </li>
          </ul>
          <!-- When locations are 3 or less than 3 -->
          <ul class="tagsAlignment"
            [ngClass]="{'center-alignment': locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 1}"
            *ngIf="locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length == 3 || locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length &lt; 3 || !locationItemViewModel.locationViewModel.showMore">
            <li tabindex="-1" class="tagWrap nx-taglist__list-item nx-margin-left-s nx-taglistCustom"
              *ngFor="let location of locationItemViewModel.locationViewModel.locationDetails.locationDetailList"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <!-- Show less option -->
            <li class="disableListItemStyle">
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            !locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3
          " (click)="locationItemViewModel.locationViewModel.showMore = true">
                Show less...
              </p>
              <!--Show more option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3
          " (click)="locationItemViewModel.locationViewModel.showTagsModal = true">
                Show more...
              </p>
            </li>
          </ul>
        </div>

        <!-- For Mobile-->
        <div
          *ngIf="!locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length !== 1">
          <!-- When locations are more than 3 -->
          <ul class="tagsAlignment" *ngIf="
        locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length > 3 && !locationItemViewModel.locationViewModel.showTagsModal
      ">
            <!--Show first 3 locations-->
            <li tabindex="-1" class="tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-s"
              *ngFor="let location of locationItemViewModel.locationViewModel.locationDetails.locationDetailList.slice(0, 3)"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <!-- Show more option will open the modal to show all locations -->
            <p class="showMoreMobileAlignment center blueColor nx-margin-left-s" *ngIf="
          !locationItemViewModel.locationViewModel.showTagsModal
       
        " (click)="locationItemViewModel.locationViewModel.showTagsModal = true">
              Show more...
            </p>

          </ul>
          <!-- When locations are 3 or less than 3 -->
          <ul class="tagsAlignment"
            *ngIf="(locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length == 3 || locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length &lt; 3) && !locationItemViewModel.locationViewModel.showTagsModal">
            <li tabindex="-1" class="tagWrap nx-taglist__list-item nx-margin-left-s nx-taglistCustom"
              *ngFor="let location of locationItemViewModel.locationViewModel.locationDetails.locationDetailList"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="locationItemViewModel.locationViewModel.itemDefectDetails && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 0">
    <div nxRow>
      <div
        *ngIf="locationItemViewModel.isDesktop  && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length !==1">
        <p class="tagsTextFormatting nx-margin-left-m">
          Selected locations</p>
      </div>
      <div nxCol="11">
        <!--For desktop -->
        <div
          *ngIf="locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length !== 1"
          [ngClass]="{'make-fixed-to-three-rows-on-showmore': !locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3}">
          <!-- When locations are more than 3 -->
          <ul class="tagsAlignment"
            [ngClass]="{'center-alignment': locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 1}"
            *ngIf="
        locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3 &&
        locationItemViewModel.locationViewModel.showMore
      ">
            <!-- Show first 3 location -->
            <li tabindex="-1" class="tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-s"
              *ngFor="let location of locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.slice(0, 3)"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <li class="disableListItemStyle">
              <!--Show less option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3 &&
            !locationItemViewModel.locationViewModel.showMore
          " (click)="locationItemViewModel.locationViewModel.showMore = true">
                Show less...
              </p>
              <!--Show more option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3 &&
            locationItemViewModel.locationViewModel.showMore &&
            locationItemViewModel.isDesktop
          " (click)="locationItemViewModel.locationViewModel.showMore = false">
                Show more...
              </p>
            </li>
          </ul>
          <!-- When locations are 3 or less than 3 -->
          <ul class="tagsAlignment"
            [ngClass]="{'center-alignment': locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 1}"
            *ngIf="locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length == 3 || locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length &lt; 3 || !locationItemViewModel.locationViewModel.showMore">
            <li tabindex="-1" class="tagWrap nx-taglist__list-item nx-margin-left-s nx-taglistCustom"
              *ngFor="let location of locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <!-- Show less option -->
            <li class="disableListItemStyle">
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            !locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3
          " (click)="locationItemViewModel.locationViewModel.showMore = true">
                Show less...
              </p>
              <!--Show more option-->
              <p class="tagsTextFormatting hand-cursor blueColor nx-margin-left-s" *ngIf="
            locationItemViewModel.locationViewModel.showMore && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3
          " (click)="locationItemViewModel.locationViewModel.showTagsModal = true">
                Show more...
              </p>
            </li>
          </ul>
        </div>

        <!-- For Mobile-->
        <div
          *ngIf="!locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length !== 1">
          <!-- When locations are more than 3 -->
          <ul class="tagsAlignment" *ngIf="
        locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length > 3 && !locationItemViewModel.locationViewModel.showTagsModal
      ">
            <!--Show first 3 locations-->
            <li tabindex="-1" class="tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-s"
              *ngFor="let location of locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.slice(0, 3)"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
            <!-- Show more option will open the modal to show all locations -->
            <p class="showMoreMobileAlignment center blueColor nx-margin-left-s" *ngIf="
          !locationItemViewModel.locationViewModel.showTagsModal
       
        " (click)="locationItemViewModel.locationViewModel.showTagsModal = true">
              Show more...
            </p>

          </ul>
          <!-- When locations are 3 or less than 3 -->
          <ul class="tagsAlignment"
            *ngIf="(locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length == 3 || locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length &lt; 3) && !locationItemViewModel.locationViewModel.showTagsModal">
            <li tabindex="-1" class="tagWrap nx-taglist__list-item nx-margin-left-s nx-taglistCustom"
              *ngFor="let location of locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList"
              (click)="deleteTag(location.locationId)">
              <span class="tagsTextAlignment"> {{ location.locationName }}</span>
              <span role="link" aria-label="close" class="nx-margin-left-s nx-tag__close ng-star-inserted">
                <nx-icon name="close" class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                  (click)="deleteTag(location.locationId)">
                </nx-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Mobile view -->
<div *ngIf="!locationItemViewModel.isDesktop">
  <!--New Search area-->
  <div *ngIf="locationItemViewModel.isSearchShow">
    <div nxRow class="nx-margin-left-2xs">
      <div nxCol="12,12">
        <nx-formfield nxLabel="Search items:">
          <input nxInput placeholder="Please enter at least three characters" id="itemSearch" name="itemSearch"
            [(ngModel)]="locationItemViewModel.itemSearch" />
        </nx-formfield>
      </div>
      <div nxCol="12,12">
        <button (click)="search()" class="nx-margin-right-s" nxButton="primary small"
          [disabled]="locationItemViewModel.searchDisabled" type="button" id="search">Search</button>
        &nbsp;
        <button nxButton="secondary small" type="button" (click)="clearSearch()" class="nx-margin-right-s" type="reset"
          id="clear">Clear</button>
      </div>
    </div>
  </div>

  <div nxRow>
    <div nxCol="9" class="high-light-count">
      Matching items: &nbsp;{{ locationItemViewModel.resultCount}}
    </div>
    <div nxCol="3" class="align-icons nx-margin-top-xs">
      <div class="nx-margin-right-xs">
        <nx-icon name="search" class="blueColor cursor-pointer" size="s"
          (click)="locationItemViewModel.isSearchShow= ! locationItemViewModel.isSearchShow"></nx-icon>

        <nx-icon name="export-and-share" size="s"
          (click)="locationItemViewModel.itemLocations.items == 0 ? null : exportItemsToCSV()" nxTooltip="Export"
          [ngStyle]="locationItemViewModel.itemLocations.items == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
          class="apply-blue-color-onhover blueColor  cursor-pointer"></nx-icon>

      </div>
    </div>
  </div>

  <hr />

  <div class="nx-margin-x-xs">
    <ng-container>
      <div *ngFor="let item of locationItemViewModel.itemLocations.items | badgeColorPipe | latestCurrentReport"
        class="nx-margin-x-s">
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Serial no.
          </div>
          <div nxCol="8">
            <span *ngIf="item.serialNumber else emptyIcon">{{item?.serialNumber}}</span>
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Plant no.
          </div>
          <div nxCol="8">
            <span *ngIf="item.clientPlantNumber else emptyIcon">{{item?.clientPlantNumber}}</span>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetails(item.id)">
          <div class="formatHeading align-left" nxCol="4">
            Plant description
          </div>
          <div nxCol="8">
            <span *ngIf="item.plantType.description else emptyIcon">{{item?.plantType?.description}}</span>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetails(item.id)">
          <div class="formatHeading align-left" nxCol="4">
            Discipline
          </div>
          <div nxCol="8">
            <span *ngIf="item.discipline.description else emptyIcon">{{item?.discipline?.description}}</span>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetails(item.id)">
          <div class="formatHeading align-left" nxCol="4">
            Last inspected
          </div>
          <div nxCol="8">
            <span *ngIf="item.inspections.inspectionDate else emptyIcon">{{item?.inspections?.inspectionDate |
              date:'dd-MMM-YYYY'}}</span>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetails(item.id)">
          <div class="formatHeading align-left" nxCol="4">
            Defect code
          </div>
          <div nxCol="8">
            <span
              *ngIf="item.latestReport && item.latestReport.defectCode && item.latestReport.defectCode.description != '' else emptyIcon">
              <nx-badge
                *ngIf="item.latestReport && item.latestReport.defectCode && item.latestReport.defectCode.description != '' else emptyIcon"
                class="badge-padding" [style.backgroundColor]="item.defectCodeBadgeColor"
                [style.color]="common.defectCodeToColor(item.latestReport.defectCode.code)">
                {{item?.latestReport?.defectCode?.code}} </nx-badge>
            </span>

          </div>
        </div>
        <div nxRow (click)="navigateToItemDetails(item.id)">
          <div class="formatHeading align-left" nxCol="4">
            Next due
          </div>
          <div nxCol="8">
            <span *ngIf="item.inspections.nextInspectionDate else emptyIcon">{{item?.inspections?.nextInspectionDate |
              date:'dd-MMM-YYYY'}}</span>
          </div>
        </div>
        <!--Show/hide area-->
        <div *ngIf="item.showMore" (click)="navigateToItemDetails(item.id)">
          <div nxRow (click)="navigateToItemDetails(item.id)">
            <div class="formatHeading align-left nx-grid__column-4" nxCol="4">
              Location details
            </div>
            <div nxCol="8">
              <div *ngIf="item.location.description == undefined && item.location.subCodeDescription == undefined">
                <span><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></span>
              </div>

              <div *ngIf="item.location.description != undefined || item.location.subCodeDescription != undefined">
                <span *ngIf="item.location && item.location.subCodeDescription">
                  {{item?.location?.subCodeDescription}}</span>
                <span *ngIf="item.location && item.location.description && item.location.subCodeDescription"> /
                </span>
                <span *ngIf="item.location && item.location.description" nxTooltip="{{item.toolTipDetailedLocation}}">
                  {{item?.location?.description}}</span>
              </div>
            </div>
          </div>
          <div nxRow (click)="navigateToItemDetails(item.id)">
            <div class="formatHeading align-left nx-grid__column-4" nxCol="4">
              Current report ID
            </div>
            <div nxCol="8">
              <span
                *ngIf="item.latestReport != null && item.latestReport.url != '' else emptyIcon">{{item?.latestReport?.id}}</span>
            </div>
          </div>
          <div nxRow (click)="navigateToItemDetails(item.id)">
            <div class="formatHeading align-left nx-grid__column-4" nxCol="4">
              Item status
            </div>
            <div nxCol="8">
              <span *ngIf="item.status?.description else emptyIcon">{{item?.status?.description}}</span>
            </div>
          </div>
        </div>
        <div nxRow>
          <a nxCol="10" class="alink align-left" *ngIf="!item.showMore" (click)="item.showMore = true">Show more <em
              class="fa fa-chevron-down"></em> </a>
          <a nxCol="10" class="alink align-left nx-grid__column-4" *ngIf="item.showMore"
            (click)="item.showMore = false">Show
            less <em class="fa fa-chevron-up"></em> </a>
          <span nxCol="2" *ngIf="item.latestReport != null &&  item.latestReport.url != '' else empty">
            <div nxTableCell class="noTopBorder">
              <nx-icon name="pdf" size="s" class="standardBlueColor"
                (click)="itemsService.getItemReport(item.latestReport.url)">
              </nx-icon>
            </div>
          </span>
        </div>
        <hr />
      </div>
      <div *ngIf="locationItemViewModel.itemLocations.items.length === 0 && locationItemViewModel.apiResultReturned">
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Serial no.
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Discipline
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Last inspected
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Next due
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Defect Code
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Plant no.
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Sub location
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Detailed location
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Plant description
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Item status
          </div>
          <div nxCol="8">
            -
          </div>
        </div>
        <div nxRow>
          <div class="formatHeading align-left" nxCol="4">
            Next inspection
            type
          </div>
          <div nxCol="8">
            -
          </div>
        </div>

      </div>
    </ng-container>
    <record-perpage-toggle class="nx-margin-top-s" (onPerPageClick)="togglePerPage($event)"
      [total]="locationItemViewModel.resultCount" [perPage]="locationItemViewModel.perPage">
    </record-perpage-toggle>
    <div class="mobilePaginationAlignmentItem nx-margin-top-s">
      <nx-pagination *ngIf="locationItemViewModel.showPaging" [nxCount]="locationItemViewModel.resultCount"
        [nxPage]="locationItemViewModel.page" [nxPerPage]="locationItemViewModel.perPage" nxType="advanced"
        (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingAlignment">
      </nx-pagination>
    </div>
  </div>
  <ng-template #empty></ng-template>
  <ng-template #emptyIcon>
    <div nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></div>
  </ng-template>
  <ng-template #pdfIcon>
    <div nxTableCell class="noTopBorder">
      <nx-icon name="pdf" size="s" class="standardBlueColor" (click)="itemsService.getItemReport()"></nx-icon>
    </div>
  </ng-template>
</div>

<!-- Desktop view -->
<div *ngIf="locationItemViewModel.isDesktop">
  <!--New Search area-->
  <div *ngIf="locationItemViewModel.isSearchShow">
    <div nxRow>
      <div nxCol="6">
        <nx-formfield nxLabel="Search items:">
          <input [(ngModel)]="locationItemViewModel.itemSearch" nxInput name="itemSearch" id="itemSearch"
            placeholder="Please enter at least three characters " />
        </nx-formfield>
      </div>
      <div class="nx-margin-top-2m" nxCol="6">
        <button class="nx-margin-right-s" id="search" nxButton="primary small"
          [disabled]="locationItemViewModel.searchDisabled" type="button" (click)="search()">Search</button>
        &nbsp;
        <button class="nx-margin-right-s " type="reset" id="clear" nxButton="secondary small" type="button"
          (click)="clearSearch()">Clear</button>
      </div>
    </div>
  </div>
  <div class="nx-margin-top-s">
    <!--Export Row-->
    <div nxRow>
      <div class="high-light-count" nxCol="9">
        Matching items: &nbsp;{{ locationItemViewModel.resultCount}}
      </div>
      <div nxCol="3" class="align-icons nx-margin-top-xs">
        <nx-icon name="product-search" class="nx-margin-right-xs blueColor cursor-pointer" size="s"
          (click)="locationItemViewModel.isSearchShow= ! locationItemViewModel.isSearchShow" nxTooltip="Search">
        </nx-icon>
        <nx-icon [ngStyle]="locationItemViewModel.itemLocations.items == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
          name="export-and-share" size="s"
          (click)="locationItemViewModel.itemLocations.items == 0 ? null : exportItemsToCSV()" nxTooltip="Export"
          class="apply-blue-color-onhover blueColor  cursor-pointer"></nx-icon>
      </div>
    </div>
    <div class="tableFixHead nx-margin-top-s">
      <table nxTable nxSort (sortChange)="sortTable($event)">
        <caption></caption>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell class="heading" id="currentReport" nxSortHeaderCell="currentReportId">Current report</th>
            <th nxHeaderCell class="heading" id="serialNo" nxSortHeaderCell="serialNumber">Serial no.</th>
            <th nxHeaderCell id="clientPlantNo" nxSortHeaderCell="clientPlantNumber">Plant no.</th>
            <th nxHeaderCell id="plantDescription" nxSortHeaderCell="plantTypeDescription">Plant description</th>
            <th nxHeaderCell id="discipline" nxSortHeaderCell="disciplineDescription">Discipline</th>
            <th nxHeaderCell id="lastInspected" nxSortHeaderCell="inspectionsInspectionDate">Last
              inspected</th>
            <th nxHeaderCell id="defectCode" nxSortHeaderCell="currentReportDefectCode">Defect
              code</th>
            <th nxHeaderCell id="nextDue" nxSortHeaderCell="inspectionsNextInspectionDate">Next
              due</th>
            <th nxHeaderCell id="detailedLocation" nxSortHeaderCell="locationDescription">Location
              details</th>
            <th nxHeaderCell id="itemStatus" nxSortHeaderCell="statusDescription">Item
              status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr nxTableRow selectable
              *ngFor="let item of locationItemViewModel.itemLocations.items | latestCurrentReport  | badgeColorPipe | ellipsisPipe"
              class="center">
              <td nxTableCell class="align-content-left"
                *ngIf="item.latestReport != null  && item.latestReport.url != '' else emptyIcon" class="center">
                <span class="flex">
                  <nx-icon name="pdf" size="s" class="standardBlueColor"
                    (click)="itemsService.getItemReport(item.latestReport.url)">
                  </nx-icon>
                  <!-- show report id's last 6 digits starting with dots -->
                  <div nxTooltip="{{item?.toolTipReportId}}" class="nx-margin-left-s">{{item?.latestReport?.id}}</div>
                </span>
              </td>

              <td class="align-content-left" nxTableCell nxTooltip="{{item.toolTipSerialNo}}"
                *ngIf="item.serialNumber else emptyIcon" (click)="navigateToItemDetails(item.id)">
                {{item?.serialNumber}}
              </td>
              <td class="align-content-left" nxTableCell nxTooltip="{{item.toolTipPlantNo}}"
                *ngIf="item.clientPlantNumber else emptyIcon" (click)="navigateToItemDetails(item.id)">
                {{item?.clientPlantNumber}}
              </td>
              <td class="align-content-left" nxTableCell
                *ngIf="item.plantType != null && item.plantType.description != '' else emptyIcon"
                (click)="navigateToItemDetails(item.id)">
                {{item?.plantType?.description}}</td>
              <td class="align-content-left" nxTableCell
                *ngIf="item.discipline != null && item.discipline.description != '' else emptyIcon"
                (click)="navigateToItemDetails(item.id)">
                {{item?.discipline?.description}}
              </td>

              <td class="align-content-left" nxTableCell
                *ngIf="item.inspections && item.inspections.inspectionDate else emptyIcon"
                (click)="navigateToItemDetails(item.id)">
                {{item?.inspections?.inspectionDate
                | date:'dd-MMM-YYYY'}}
              </td>
              <td class="center" nxTableCell (click)="navigateToItemDetails(item.id)">
                <span *ngIf="item.latestReport && item.latestReport.defectCode.description != ''">
                  <nx-badge
                    *ngIf="item.latestReport && item.latestReport.defectCode  && item.latestReport.defectCode.description != '' else emptyIcon"
                    class="badge-padding" [style.backgroundColor]="item.defectCodeBadgeColor"
                    [style.color]="common.defectCodeToColor(item.latestReport.defectCode.code)">
                    {{item?.latestReport?.defectCode?.code}} </nx-badge>
                </span>
              </td>
              <td class="align-content-left wrap-text" nxTableCell (click)="navigateToItemDetails(item.id)"
                *ngIf="item.inspections && item.inspections.nextInspectionDate else emptyIcon">
                {{item?.inspections?.nextInspectionDate
                |
                date:'dd-MMM-YYYY'}}
              </td>


              <td class="align-content-left" nxTableCell (click)="navigateToItemDetails(item.id)">
                <div *ngIf="item.location.description == undefined && item.location.subCodeDescription == undefined">
                  <span><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></span>
                </div>
                <div *ngIf="item.location.description != undefined || item.location.subCodeDescription != undefined">
                  <span *ngIf="item.location && item.location.description" nxTooltip="{{item.toolTipDetailedLocation}}">
                    {{item?.location?.description}}</span>
                  <span *ngIf="item.location && item.location.description && item.location.subCodeDescription"> /
                  </span>
                  <span *ngIf="item.location && item.location.subCodeDescription"
                    nxTooltip="{{item.toolTipSubLocation}}">
                    {{item?.location?.subCodeDescription}}</span>
                </div>


              </td>

              <td class="align-content-left" nxTableCell (click)="navigateToItemDetails(item.id)"><span
                  *ngIf="item.status && item.status.description != '' else emptyIcon">{{item?.status?.description}}</span>
              </td>
            </tr>
            <tr *ngIf="locationItemViewModel.resultCount === 0 && locationItemViewModel.apiResultReturned">
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
              <td nxTableCell class="align-content-left"><i class="fas fa-minus " aria-hidden="true"></i></td>
            </tr>
            <tr class="hr-parent">
              <hr class="horizontal-line-items" />
            </tr>

          </ng-container>

          <ng-template #emptyIcon>
            <td nxTableCell><i class="fas fa-minus center" aria-hidden="true"></i></td>
          </ng-template>
          <ng-template #pdfIcon>
            <td nxTableCell class="noTopBorder">
              <nx-icon name="pdf" size="s" class="standardBlueColor" (click)="itemsService.getItemReport('')">
              </nx-icon>
            </td>
          </ng-template>

        </tbody>
      </table>

    </div>

    <div class="nx-margin-top-m pagination-display-inline">
      <record-perpage-toggle class="pagination-record" (onPerPageClick)="togglePerPage($event)"
        [total]="locationItemViewModel.resultCount" [perPage]="locationItemViewModel.perPage">
      </record-perpage-toggle>

      <div class="paginationContainer">
        <nx-pagination *ngIf="locationItemViewModel.showPaging" [nxCount]="locationItemViewModel.resultCount"
          [nxPage]="locationItemViewModel.page" [nxPerPage]="locationItemViewModel.perPage" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingClass">
        </nx-pagination>
      </div>
    </div>
  </div>
</div>

<!-- Locations Tags list in Model (Mobile View) -->
<div
  *ngIf="locationItemViewModel.locationViewModel.showTagsModal && !locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.locationDetails &&  locationItemViewModel.locationViewModel.locationDetails.locationDetailList">
  <tags-modal [locationTagsData]="locationItemViewModel.locationViewModel.locationDetails.locationDetailList"
    (setTagsModalStatus)="getModalOpenStatus($event)" (filterTagsData)="deleteTagFromModel($event)">
  </tags-modal>
</div>
<!-- Location Tags in Model (Mobile view) Item defects (from a defect widget dashboard)-->
<div
  *ngIf="locationItemViewModel.locationViewModel.showTagsModal && !locationItemViewModel.isDesktop && locationItemViewModel.locationViewModel.itemDefectDetails &&  locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList">
  <tags-modal [locationTagsData]="locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList"
    (setTagsModalStatus)="getModalOpenStatus($event)" (filterTagsData)="deleteTagFromModel($event)">
  </tags-modal>
</div>