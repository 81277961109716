import * as moment from "moment";

export class FilterDashboardViewModel {

  // setting the min and max dates allowed to select from start and end date
  minStartDate: moment.Moment;
  maxStartDate: moment.Moment;
  minEndDate: moment.Moment;
  maxEndDate: moment.Moment;

  // to disable apply button on wrong date entry
  isApplyFilterButtonDisabled: boolean = false;

  // date format for filter modal
  datePickerFormat = 'DD/MM/YYYY';

  // list of all locations
  locationsADefectList: any;
  // switcher model
  aDefectDateRangeSwitcherModel: boolean = false;
}