import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { Observable, Subject } from 'rxjs';
import { ConfirmationComponent } from 'src/app/_layout/confirmation/confirmation.component';
import { BaseComponent } from './base.component';


@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<BaseComponent> {
    //to manage custom popup for confirmation
    confirmDlg!: NxModalRef<ConfirmationComponent>;
    constructor(
        private dialogService: NxDialogService
    ) { }
    // method having the functionality to deactivate the guard
    canDeactivate(
        component: BaseComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const subject = new Subject<boolean>();
        //validating the control here, and if its been changes, need to show confirmation popup
        if (component.isFormValid()) {
            this.confirmDlg = this.dialogService.open(ConfirmationComponent, { disableClose: true, showCloseIcon: true });
            this.confirmDlg.componentInstance.confirmationSubject = subject;
            this.confirmDlg.afterClosed()
                .subscribe(async response => {
                    if (response) {
                        //intentionally 
                    }
                });

            return subject.asObservable();
        }
        return true;
    }

}
