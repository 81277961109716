import gql from "graphql-tag";
// graphql query to decode token and fetch the employee Id
export const decodeToken = gql`query decodeToken($token: String!){
    decodeToken(token: $token){
        key
        val
     }
    }
    
  `;

  // graphql query to decode employee id to fetch token
export const decodeUserId = gql`query generateToken($metadata: String!){

  generateToken(metadata: $metadata){
      key
      val
   }
  }
`;

