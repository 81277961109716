// this component is solely build for filtering purpose
// for now its only used for A-defect count widget filtering
// in future this can be made dynamic for locations filter as well
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { LocationADefectIdName } from 'src/app/schemas/location-a-defects-names';
import { FilterDashboardViewModel } from './filter-dashboard.viewModel';

@Component({
  selector: 'app-filter-dashboard',
  templateUrl: './filter-dashboard.component.html',
  // the 2nd scss file already contains all the styles that were needed here
  styleUrls: ['../dashboard.component.scss'],
})
export class FilterDashboardComponent implements OnInit {
  // bind the checkbox field i.e. two way binding. By default its value is 14 days i.e. coming from Dashboard component
  @Input() showPrevious;
  //  start date of A defect location
  aDefectStartDate;
  //  end date of A defect location
  aDefectEndDate;
  // viewModel where variables are declared
  filterDashboardViewModel: FilterDashboardViewModel = new FilterDashboardViewModel();
  // start date set to todays date
  @Input() startDate;
  // end date set to todays Date
  @Input() endDate;
  // list of all locations
  locationList: LocationADefectIdName[] = [];
  // filter template Dialog to close the popup upon apply button click
  @Input() filterTemplateDialogRef;
  // ndbx switch status 
  @Input() switchStatus;
  // the service that is called once Dashboard loads gets these A defect locations names and Ids that are endered in filter modal
  @Input() locationsADefectList;
  // this input contains properties that were selected while navigation to items page
  @Input() aDefectFilteredLocations;
  // emit difference between start and end dates
  @Output() differenceBetweenDate = new EventEmitter();
  // start date
  @Output() defectStartDate = new EventEmitter();
  // end date
  @Output() defectEndDate = new EventEmitter();
  // call this function on Apply button click
  @Output() getLocationADefects = new EventEmitter();
  // the filtered locations/ selected locations
  @Output() selectedFilteredADefectLocations = new EventEmitter();
  // emit showPrevious value to parent
  @Output() selectedShowPreviousValue = new EventEmitter();
  // emit start and end dates
  @Output() selectedStartDate = new EventEmitter();
  @Output() selectedEndDate = new EventEmitter();
  // output for sending disabled dates information
  @Output() isDateRangeDisabled = new EventEmitter();
  // the difference between start and end date
  diffBtDates;
  // selected locations ids
  selectedLocations: any[] = [];
  // selected locations details
  selectedLocationsDetails: any[] = [];

  ngOnInit(): void {
    if (this.locationsADefectList) {
      // iterate on location A defect list
      this.locationsADefectList.forEach((element) => {
        let object = {} as LocationADefectIdName;
        object.id = element.id;
        object.name = element.name;
        // add-on these changes to location list for further operation
        this.locationList.push(object);
      });
      // check if values are stores in filters
      if (this.aDefectFilteredLocations) {
        this.aDefectFilteredLocations.map((loc) =>
          this.selectedLocations.push(loc.id)
        );
      } 
      // if not values in filters then push all locations in selected locations array
      else {
        this.locationsADefectList.map((loc) =>
          this.selectedLocations.push(loc.id)
        );
      }
    }
    // update the status of switch 
    this.filterDashboardViewModel.aDefectDateRangeSwitcherModel = !this.switchStatus;

    // setting min & max dates for start and end dates
    // minStart date can be upto 1 years in past
    this.filterDashboardViewModel.minStartDate = moment().subtract(1, 'years');
    // the maxStart date can be todays Date
    this.filterDashboardViewModel.maxStartDate = moment();
    // the minEndDate can be todays date
    this.filterDashboardViewModel.minEndDate = this.startDate;
    // the maxEndDate can be upto StartsDate
    this.filterDashboardViewModel.maxEndDate =
      this.filterDashboardViewModel.maxStartDate;
    this.assignCopy();
  }

  // disable/able Apply button within filter modal under certain conditions
  disableApplyButton() {
    let isDisabled: boolean;
    // if switch is on then proceed
    if (this.filterDashboardViewModel.aDefectDateRangeSwitcherModel) {
      // check if entered dates are null or invalid
      if (
        this.getDateValue(this.startDate) === '' ||
        this.getDateValue(this.endDate) === ''
      ) {
        this.filterDashboardViewModel.isApplyFilterButtonDisabled = true;
      }
      // Disable button: if startDate is less than min Date i.e. 1 year in past or if startDate is greater than max date i.e. todays date
      if (
        this.startDate.isBefore(
          this.filterDashboardViewModel.minStartDate,
          'days'
        ) ||
        this.startDate.isAfter(
          this.filterDashboardViewModel.maxStartDate,
          'days'
        )
      ) {
        isDisabled = true;
      }
      // Disable button: if endDate is smaller than startDate or if endDate is greater than max Date i.e. todays date
      else if (
        this.endDate.isBefore(
          this.filterDashboardViewModel.minEndDate,
          'days'
        ) ||
        this.endDate.isAfter(this.filterDashboardViewModel.maxEndDate, 'days')
      ) {
        isDisabled = true;
      }
      // under all other conditions don't make button disabled
      else {
        isDisabled = false;
      }
      this.filterDashboardViewModel.isApplyFilterButtonDisabled = isDisabled;
    }
  }

  // fetch the date and change its format
  getDateValue(value) {
    if (value === undefined || value === null) {
      return '';
    }
    return moment(value).format(this.filterDashboardViewModel.datePickerFormat);
  }

  // create a copy of LocationsData for filtering
  assignCopy() {
    this.locationList = Object.assign([], this.locationList);
  }

  // apply filter values and close filter
  closeFilter() {
    this.getSelectedLocationDetails();
    this.updateDates();
    // emit start date, end date, difference between both, selected locations and call getLocationsAdefect function and then close filter
    this.defectStartDate.emit(this.aDefectStartDate.format('YYYY-MM-DD'));
    this.defectEndDate.emit(this.aDefectEndDate.format('YYYY-MM-DD'));
    this.diffBtDates = this.aDefectEndDate.diff(this.aDefectStartDate, 'days');
    // the difference between the start and end dates or simply the selected dropdown value
    this.differenceBetweenDate.emit(this.diffBtDates);
    this.selectedFilteredADefectLocations.emit(this.selectedLocationsDetails);
    // call the A defect fetch method
    this.getLocationADefects.emit();
    // if dropdown is enabled then emit the showPrevious value and isDateRangeDisabled to be true
    if (!this.filterDashboardViewModel.aDefectDateRangeSwitcherModel) {
      this.selectedShowPreviousValue.emit(this.showPrevious);
      this.isDateRangeDisabled.emit(true);
    }
    // if date range is enabled then emit start and end dates and isDateRangeDisabled to be false
    if (this.filterDashboardViewModel.aDefectDateRangeSwitcherModel) {
      this.selectedStartDate.emit(this.startDate);
      this.selectedEndDate.emit(this.endDate);
      this.isDateRangeDisabled.emit(false);
    }
    // close the modal
    this.filterTemplateDialogRef.close();
  }

  // get the names,ids etc for the selected locations
  getSelectedLocationDetails() {
    this.selectedLocationsDetails = this.locationsADefectList.filter(
      (location) => this.selectedLocations.includes(location.id)
    );
  }

  //  upon change in previous i.e. 14, 30 or 60 days etc
  changeDayValue(value) {
    this.showPrevious = value.value;
  }

  // update start and end date depending on the disabled field
  updateDates() {
    if (this.filterDashboardViewModel.aDefectDateRangeSwitcherModel) {
      this.aDefectStartDate = this.startDate;
      this.aDefectEndDate = this.endDate;
    } else if (!this.filterDashboardViewModel.aDefectDateRangeSwitcherModel) {
      this.aDefectEndDate = moment();
      if (
        this.showPrevious == '14 days' ||
        this.showPrevious === '30 days' ||
        this.showPrevious === '60 days'
      ) {
        this.aDefectStartDate = moment().subtract(
          parseInt(this.showPrevious.slice(0, 2)),
          'days'
        );
      } else if (
        this.showPrevious === '3 months' ||
        this.showPrevious === '6 months'
      ) {
        this.aDefectStartDate = moment().subtract(
          parseInt(this.showPrevious.slice(0, 1)),
          'months'
        );
      } else if (this.showPrevious === '1 year') {
        this.aDefectStartDate = moment().subtract(
          parseInt(this.showPrevious.slice(0, 1)),
          'years'
        );
      }
    }
  }

  //to enable/disable the apply button based on location selection
  getSelectedlocationsCount(): boolean {
    return this.selectedLocations.length <= 0;
  }
}
