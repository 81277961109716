import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "apollo-client";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { searchLocationSurveyorContacts } from "src/app/schemas/location-surveyor-contacts";
import { searchLocationNamesPostcode, searchLocations } from "src/app/schemas/locations";
import { SearchModel } from "src/app/schemas/searchModel";
import { searchLocationSiteContacts } from "src/app/schemas/siteContacts";
import { SortEmployeeField } from "src/app/schemas/users";
import { LocationDetailResponse } from "src/app/shared/models/responses/locationsDetailsResponse.Model";
import { FiltersService } from "../filters-service";
import { GraphQLService } from "./graphql.service";

@Injectable({
    providedIn: 'root'
})
// service for location endpoints
export class LocationService {

    public constructor(private graphQL: GraphQLService, private locationFilterService: FiltersService) { }
    // method to get locations with variables 
    async getLocations(searchList: SearchModel[], order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10, isAdminQuery: boolean = false): Promise<LocationDetailResponse> {
        let sortField = {} as SortEmployeeField;
        sortField.field = sortName
        sortField.order = order
        const variables = {
            "sortBy": sortField,
            "from": from,
            "size": size,
            // making sure to pass search fields with non empty values
            "search": searchList,
            "isAdminQuery": isAdminQuery
        }
        // call to graph QL endpoint, with query and variables.
        const response = await this.graphQL.getList(searchLocations, variables).pipe(take(1)).toPromise();
        // map the data to location details object
        const locationDetailMappedResponse = new LocationDetailResponse(response);
        // check for the presence of location details data object
        if (!locationDetailMappedResponse.hasResultsProperty)
            locationDetailMappedResponse.locationDetails = [];
        // returning the results in observable form 
        return locationDetailMappedResponse;
    }

    // method to get only the required location name and postcode
    getLocationNamesPostCode(searchList: SearchModel[], order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10, isAdminQuery: boolean = false): Observable<ApolloQueryResult<any>> {
        let sortFieldObject = {} as SortEmployeeField;
        sortFieldObject.field = sortName
        sortFieldObject.order = order
        const variables = {
            "sortBy": sortFieldObject,
            "from": from,
            "size": size,
            // making sure to pass search fields with non empty values
            "search": searchList,
            "isAdminQuery": isAdminQuery
        }
        // call to graph QL endpoint, with query and variables.
        return this.graphQL.getList(searchLocationNamesPostcode, variables);
    }
    // get locations by promise way
    async getLocationsByPromise(searchList: SearchModel[], order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10): Promise<LocationDetailResponse> {
        // call above function and add the promise work
        const resultToPromise = await this.getLocations(searchList, order, sortName, from, size);
        return resultToPromise;
    }

    // method to set the filters data of location screen
    setLocationsFilters = (pageNo: number, sortBy: string, sortDirection: string, locationName: string, locationAddress: string, locationPostcode: string): void => {
        this.locationFilterService.locationsFilters.pageNo = pageNo;
        this.locationFilterService.locationsFilters.locationName = locationName;
        this.locationFilterService.locationsFilters.locationPostcode = locationPostcode;
        this.locationFilterService.locationsFilters.locationAddress = locationAddress;
        this.locationFilterService.locationsFilters.sortBy = sortBy;
        this.locationFilterService.locationsFilters.sortDirection = sortDirection;
    }

    // method to send back the location model object 
    fetchLocationsFilters = () => this.locationFilterService.locationsFilters

    // method to get locations surveyor contacts with variables 
    getLocationSurveyorContacts(searchList: SearchModel[], order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10): Observable<ApolloQueryResult<any>> {
        const variables = {
            "sortOrder": order,
            "sortField": sortName,
            "from": from,
            "size": size,
            // making sure to pass search fields with non empty values
            "search": searchList
        }
        // call to graph QL endpoint, with query and variables.
        return this.graphQL.getList(searchLocationSurveyorContacts, variables);
    }

    // method to get locations site contacts with variables 
    getLocationSiteContacts(searchList: SearchModel[], order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10): Observable<ApolloQueryResult<any>> {
        const variables = {
            "sortOrder": order,
            "sortField": sortName,
            "from": from,
            "size": size,
            // making sure to pass search fields with non empty values
            "search": searchList
        }
        // call to graph QL endpoint, with query and variables.
        return this.graphQL.getList(searchLocationSiteContacts, variables);
    }
}