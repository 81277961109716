// enum of defect code badge codes
export enum ItemDefectCodeBadgeColor {
    // AE defect code badge color
    AEDefectCodeColor = "#E4003A",
    // AI defect code badge color
    AIDefectCodeColor = "#F07393",
    // AT defect code badge color
    ATDefectCodeColor = "#F8BFCE",
    // B defect code badge color
    BDefectCodeColor = "#F86200",
    // P defect code badge color
    PDefectCodeColor = "#496EBD",
    // C defect code badge color
    CDefectCodeColor = "#FDD25C",
    // N defect code badge color
    NDefectCodeColor = "#00908D",
    // Unknown case
    NoDefectCodeColor = "#d9d9d9"
}