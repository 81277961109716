<app-loading></app-loading>
<h2 nxHeadline="subsection-medium" class="nx-font-weight-semibold nx-margin-top-2m ">
    My Inspection Hub Preferences
</h2>
<p>
    <span *ngIf="preferencesViewModel.isDesktop">{{preferencesViewModel.para1DummyText}}</span>
    <span *ngIf="!preferencesViewModel.isDesktop">{{(preferencesViewModel.para1DummyText | slice:0:50)}}...</span>
</p>
<div id="firstHR" class="nx-margin-y-m">
    <hr />
</div>

<form [formGroup]="preferencesFormGroup">

    <h2 class="nx-font-weight-light nx-margin-top-2m" nxHeadline="subsection-medium">
        Subscriptions
    </h2>

    <div id="firstsection" class="nx-margin-y-s">
        <hr />
    </div>
    <p id="firstParagraph">
        <span *ngIf="preferencesViewModel.isDesktop">{{preferencesViewModel.para2DummyText }}</span>
        <span *ngIf="!preferencesViewModel.isDesktop">{{(preferencesViewModel.para2DummyText | slice:0:50) }}...</span>
    </p>
    <div nxRow>
        <div class="nx-margin-top-s nx-margin-x-s" >
            eNotifications </div>
        <div >
            <nx-switcher [checked]="preferencesFormGroup.value.eNotification"
                formControlName="eNotification" labelSize="small" labelPosition="left" (change)="saveFlags()"
                class="nx-margin-top-s">
            </nx-switcher>
        </div>

    </div>
    <div class="nx-margin-top-m">
        <hr />
    </div>
    <p>
        <span *ngIf="preferencesViewModel.isDesktop">{{preferencesViewModel.para2DummyText }}</span>
        <span *ngIf="!preferencesViewModel.isDesktop">{{(preferencesViewModel.para2DummyText | slice:0:50) }}...</span>
    </p>
    <div nxRow>
        <div class="nx-margin-top-s nx-margin-left-s custom-margin-right">eReporting</div>
        <div id="eReporting">
            <nx-switcher class="nx-margin-top-s" formControlName="eReporting"
                [checked]="preferencesFormGroup.value.eReporting" labelPosition="left" labelSize="small"
                (change)="saveFlags()">
            </nx-switcher>

        </div>

    </div>
    <div id="finalHR" class="nx-margin-y-m">
        <hr />
    </div>
</form>