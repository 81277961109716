
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SearchModels } from 'src/app/schemas/searchModel';
import { CommonService } from 'src/app/services/common.service';
import { IdleDetectionService } from 'src/app/services/idle-detection.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { RolesFromApi } from 'src/app/shared/enums/role';
import { AppStates } from 'src/app/statemanagement/app.state';

@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss']
})
// Layout container which only authorized users can see on UI
export class AuthorizedComponent implements OnInit {

  // Check for desktop device, by default its true
  isDesktop: boolean = true;
  // Binding of params from query string
  queryItemParamList: SearchModels = new SearchModels();
  // hide / show breadcrumbBg
  showBreadcrumbBg: boolean;
  // show/hide item group modal popup
  showPopup: boolean = false;
  // show/hide organization modal popup
  showOrganizationPopup: boolean = false;
  // list of all item groups
  itemGroupList: any;
  // list of assigned item groups
  itemGroupAssignedList: any;
  // boolean for internal admin role status
  isInternalAdminRole: boolean = false;

  // constructor with required services
  constructor(private store: Store<AppStates>, private layoutService: LayoutService,
    public common: CommonService, private localStorageService: LocalStorageService, private idleDetectionService: IdleDetectionService) { }

  ngOnInit(): void {
    //setting loggedin status to start watch for idle detection
    this.idleDetectionService.setUserLoggedIn(true);
    // check the device type and update the local variable
    this.layoutService.currentDevice.subscribe(result => this.isDesktop = result);
    // check the url , to know banner requirement
    this.layoutService.showBreadcrumbBackground.subscribe(showBreadcrumbBg => this.showBreadcrumbBg = showBreadcrumbBg);
    // subscribed to logged inuser object from store
    this.store.select("loggedInUser").subscribe((user: any) => {

      // preparing the initials
      if (user.employee.role != RolesFromApi.internalAdmin) {
        this.isInternalAdminRole = false;
        // subscribe to know the detail of all item groups 
        this.layoutService.currentItemGroups.subscribe(itemGroupList => {
          if (itemGroupList && itemGroupList.length > 0) {
            // Call the function to know the need to open item group popup
            this.showPopup = this.common.itemGroupStatusCheck();
            this.itemGroupList = [...itemGroupList];
            // get the recently assigned item groups list and store in local variable
            this.itemGroupAssignedList = this.common.getItemGroupAssignedList();
          }
        })
      }
      else if (user.employee.role == RolesFromApi.internalAdmin) {
        this.isInternalAdminRole = true;
        this.layoutService.currentOrganisationObject.subscribe(organizationObject => {
          this.showOrganizationPopup = (organizationObject && organizationObject.length === 0)
          const selectedItemGroup = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups);
          if (selectedItemGroup != null && organizationObject && organizationObject.length > 0) {
            // pass the name to observable to show on header
            this.layoutService.assignItemGroups(selectedItemGroup)
          }
        })
      }
    });
  }

  // method to navigate to items screen
  navigateToItems = () => {
    void this.common.navigate(AppRoutes.mainItems + "?sortDirection=desc&sortBy=inspectionsInspectionDate,currentReportId")
  }

  // method to know the status of modal open/close
  getModalOpenStatus = (status: boolean) => this.showPopup = status;

  // show/hide item goup modal 
  showItemgroupModal = (): boolean => (this.showPopup && this.isDesktop && !this.isInternalAdminRole)

}
