import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SharedMessages } from 'src/app/shared/shared-messages';

@Component({
    selector: 'app-itemGroups',
    templateUrl: './item-group.component.html',
    styleUrls: ['./item-group.component.scss']
})

export class ItemGroupPageComponent implements OnInit, OnChanges {

    // boolean to handle the case of opening of page or showing list of groups name
    showItemGroupPage: boolean = false
    @Input() itemGroupList: any;
    // Input holding the list of all assigned item groups
    @Input() itemGroupAssignedList: any;
    // Variable to handle th validation message on popup
    showValidationError: boolean = false
    // Variable having the data of whether all groups are selected or not
    isSelectAll: boolean = false
    // boolean to know the status of cancel button 
    popupCancelled: boolean = false;
    // property to hold the selected Item group Name
    selectedItemGroupName: string = "";
    // dummy text to local variable
    dummyText = SharedMessages.longDummyText;
    // constructor with banner service injection
    constructor(private layoutService: LayoutService, public router: Router, private common: CommonService,
        private localStorageService: LocalStorageService) { }

    // subscribe to banner text on init
    ngOnInit(): void {
        this.prepareSelectedItemGroupList()
        // check the device type and update the local variable
        this.layoutService.currentDevice.subscribe(result => this.showItemGroupPage = result);
    }
    // sort the item group list by ascending order
    sortItemGroups() {
        this.itemGroupList.sort((a, b) => {
            return this.common.compare(a.name, b.name, "asc");
        })
    }

    // method to close the modal popup
    close = () => {
        // this can be closed 
        if (this.selectedItemGroupName) {
            this.showValidationError = false;
            this.showItemGroupPage = false
        }
        // no item group is selected, need to show the validation 
        else {
            this.showValidationError = true;
        }
    }

    // save the item groups selection preference
    save = () => {
        this.popupCancelled = false;
        //filter the list with only checked status
        if (this.selectedItemGroupName) {
            const checkedItemGroup = this.itemGroupList.filter(x => x.name == this.selectedItemGroupName)
            this.itemGroupAssignedList = []
            // look if we have already object present in local storage
            // need to keep the fresh preference
            this.localStorageService.saveObject(LocalStorageKeys.SelectedItemGroups, checkedItemGroup);
            // Add the list of selected assigned item groups
            this.itemGroupAssignedList = checkedItemGroup.map(((itemGroup: any) => itemGroup.name));
            // Pass the list to observables to show the latest data on header
            this.layoutService.assignItemGroups(this.itemGroupAssignedList)
            this.layoutService.assignAllItemGroups(this.itemGroupList)
            // close the popup
            this.close()
        }
        else {
            this.showValidationError = true;
        }
    }

    // Open the page like modal
    openModal = () =>
        this.showItemGroupPage = true;


    // detect changes in item group lists
    ngOnChanges(): void {
        this.prepareSelectedItemGroupList()
    }

    // method to prepare the list of item groups with checked status
    prepareSelectedItemGroupList = () => {
        this.itemGroupAssignedList = []
        // get the latest info of assigned item groups stored in storage
        this.itemGroupAssignedList = this.common.getItemGroupAssignedList();
        // prepare the list
        this.updateCheckedStatus();
        // look for the presence of assigned groups
        // change the status of showing page accordingly
        this.showItemGroupPage = this.itemGroupAssignedList && this.itemGroupAssignedList.length === 0

    }

    // method to update the checked property of each item group row
    updateCheckedStatus = () => {
        if (this.itemGroupList)
            this.sortItemGroups();
    }

}
