import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SortDirection } from '@aposin/ng-aquila/table';
import { BreadcrumbService } from 'angular-crumbs';
import { DOCUMENT } from '@angular/common';
import { SearchModel, SearchModels } from '../schemas/searchModel';
import { environment } from 'src/environments/environment';
import { LayoutService } from './layout-service';
import { Colors } from '../shared/enums/colors';
import { ErrorLogService } from './errors/errorService';
import { LocalStorageService } from './local-storage/local-storage.service';
import { LocalStorageKeys } from './local-storage/local-storage-keys';


@Injectable()
export class CommonService {
  // variable to show/hide the item group pop up
  showPopup: boolean = false
  // variable holding the list of all the item groups of user
  itemGroupList: any;
  // variable holding the list of all the assigned item groups to user
  itemGroupAssignedList: any[] = [];
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute, private layoutService: LayoutService,
    private breadcrumbService: BreadcrumbService, @Inject(DOCUMENT) private document: Document,
    private logService: ErrorLogService, private localStorageService: LocalStorageService) { }

  // Check for null or undefined before returning its value
  IsNullORUndefined = (
    params: any,
    value: any,
    defaultReturn: any = ''
  ): any => {
    if (!params[value]) return defaultReturn;
    return isNaN(params[value]) ? params[value] : Number(params[value]);
  };

  // Check string for null or undefined before returning its value
  IsNullORUndefString = (
    params: any,
    value: any,
    defaultReturn: any = ''
  ): any => {
    if (!params[value]) return defaultReturn;
    return params[value];
  };

  // Check boolean for null or undefined before returning its value
  IsNullORUndefBoolean = (
    params: any,
    value: any,
    defaultReturn: any = true
  ): any => {
    if (!params[value]) return defaultReturn;
    return params[value] === 'true';
  };

  // compare two values to decide their order
  compare(
    first: number | string | Date,
    second: number | string | Date,
    direction: SortDirection
  ) {
    // if first argument is null or undefined then assign empty string
    first = first ?? "";
    // if second argument is null or undefined then assign empty string
    second = second ?? "";
    // if variables are string then neutralize its case for accurate sorting
    if (typeof first === 'string' && typeof second === 'string') {
      first = first.toLowerCase();
      second = second.toLowerCase();
    }
    return this.compareItems(first, second) * (direction == 'asc' ? 1 : -1);
  }

  // Generic function to update search params list
  updateSearchParamList(
    searchParamList: SearchModels,
    field: string,
    value: string
  ): SearchModels {
    const searchedParam = searchParamList.searchList.filter(
      (x) => x.field == field
    );
    // param not found
    if (searchedParam.length == 0) {
      if (value != '') {
        // add new entry in params list
        let searchItem = {} as SearchModel;
        searchItem.field = field;
        searchItem.value = value.trim();
        searchParamList.searchList.push(searchItem);
      }
    } else {
      // if searched property has some value
      if (searchedParam[0].value != '') {
        // update value of existing entry
        searchedParam[0].value = value.trim();
      }
      // if searched property is empty, lets remove it
      else {
        searchParamList.remove(searchedParam[0]);
      }
    }
    // return updated list
    return searchParamList;
  }

  // safely get empty string in case its
  // undifined or null
  safeString(inputString: string): string {
    return inputString || '';
  }

  // this is for getting html element by className ( first element )
  // and click it
  clickElementByClassName(className: string): void {
    // get html elements
    const elements = document.getElementsByClassName(className);
    // making sure that there were some elements found
    if (elements.length > 0) {
      // get first element from the array
      const firstElement = elements[0] as HTMLElement;
      // click it
      firstElement.click();
    }
  }

  // this is for getting html element by className and selecting the html element based on its position
  // and click it
  clickElementByClassNameAndPosition(className: string, position: number): void {
    // get html elements
    const elements = document.getElementsByClassName(className);
    // making sure that there were some elements found
    if (elements.length > 0) {
      // due to addition of extra elements upon 2nd visit i.e. 4 due to which this condition is written
      // position is added +2 to catch the element
      if (elements.length == 4) {
        position += 2;
      }
      // get first element from the array
      const firstElement = elements[position - 1].parentElement;
      // click it
      firstElement.click();
    }
  }

  // check element exists by id
  elementExist(id: string): boolean {
    return document.getElementById(id) ? true : false;
  }

  // concatinates multiple values as comma separated string
  toTextMulti(value: string | string[]): string {
    // if value exists
    if (value) {
      // if its an array, then join its items as string
      if (Array.isArray(value)) {
        return value.join(' | ');
      } else {
        // return string
        return value;
      }
    }
    // default return is empty string
    return '';
  }

  // compare two values ( for ordering purpose )
  private compareItems(itemA: any, itemB: any): number {
    let retVal: number = 0;
    if (itemA && itemB) {
      if (itemA > itemB) retVal = 1;
      else if (itemA < itemB) retVal = -1;
    } else if (itemA) retVal = 1;
    else if (itemB) retVal = -1;
    return retVal;
  }

  // find and replace in string
  findAndReplace = (text: string, find: string, replace: string) =>
    text.split(find).join(replace);

  // add default search param to be passed to api
  addDefaultSearchParam = (
    searchParamList: SearchModels,
    field: string,
    value: string
  ): SearchModels => this.updateSearchParamList(searchParamList, field, value);


  // Add days to current date
  formatDateAndAddDays = (theDate: Date, days: number = 0) => {
    if (days > 0)
      theDate.setDate(theDate.getDate() + days);
    return theDate.toISOString().slice(0, 10);
  };

  // find the number of digits in number
  // example: 232 returns 3
  numberLength = (number: number) => Math.floor(Math.log10(Math.abs(number))) + 1

  // this is case when we are saving object in json string into perferences and when
  // we fetch it again we want to validate, its a valid json string
  isValidJson = (input: string): boolean => {
    try {
      return (JSON.parse(input) && !!input);
    } catch (e) {
      return false;
    }
  }

  // common function to control navigate between components
  navigate = async (routeValue: string, options: any = null): Promise<void> => {
    try {
      if (options) {
        await this.router.navigate([routeValue], options);
      } else {
        await this.router.navigateByUrl(routeValue);
      }
    } catch (error) {
      this.logService.logError(error);
      throw error;
    }
  };


  // return current navigation object of router 
  getRouterCurrentNavigationObject = () =>
    this.router.getCurrentNavigation()


  // update the breadcrumb node text
  // takes activated route snapshot and text as parameters
  updateBreadcrumbLabel = (routeSnapshot: any, label: string) =>
    this.breadcrumbService.changeBreadcrumb(routeSnapshot, label);

  // get the current route events and send back
  getRouteEvents = () => this.router.events;

  // get client id based on origin
  getAuth0Settings = (): { clientId: string, scope: string } => {
    // getting origin value
    const { origin } = this.document.location;
    // getting all list of internal instances
    const { internalInstances } = environment;
    // default clientId
    let clientId = environment.config.client_id;
    // default scope
    let scope = "openid profile";
    // checking if origin is matching any of those instances
    if (origin.indexOf(internalInstances[0]) > -1 || origin.indexOf(internalInstances[1]) > -1) {
      // use internal instance specific client id
      clientId = environment.config.internal_client_id;
      // use internal instance specific scope
      scope = "openid profile email groups";
    }
    // returning both clientId and scope for Auth0
    return { clientId, scope };
  }

  // PURPOSE: To know the details of item groups and act upon based on scenario
  itemGroupStatusCheck = (): boolean => {
    // 1. Look for Query Param to know whether it is first login or not
    this.activatedRoute.queryParams.subscribe(params => {
      // 2. Fetch list of assigned Item groups from local storage
      const assignedItemGroups = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups);
      if (params?.firstLogin) {
        // 3. Fetch list of Item groups from local storage
        this.itemGroupList = this.localStorageService.getObject(LocalStorageKeys.ItemGroups);
        // IF we have both the list of Item groups and Assigned Item Groups
        if (this.itemGroupList && assignedItemGroups != null) {
          this.handleSingleORMultipleItemGroupCase(assignedItemGroups)
        }
        // IF we have the list of Item groups and not Assigned Item Groups
        else if (this.itemGroupList && assignedItemGroups == null) {
          this.handleSingleORMultipleItemGroupCase(assignedItemGroups)
        }
        else {
          if (assignedItemGroups) {
            this.multipleItemGroupCase(assignedItemGroups)
          }
        }
      }
      // IF we need have the assigned item groups but not the parameter in url
      else {
        if (assignedItemGroups) {
          this.multipleItemGroupCase(assignedItemGroups)
        }
      }
    });
    return this.showPopup;
  }

  // handles the scenarios based on the count of item groups
  handleSingleORMultipleItemGroupCase = (assignedItemGroups: any) => {
    // Single Item group case handling
    if (this.itemGroupList && this.itemGroupList.length === 1) {
      this.singleItemGroupCase()
    }
    // Multiple Item group case handling
    else if (this.itemGroupList && this.itemGroupList.length > 1) {
      if (assignedItemGroups && assignedItemGroups.length > 0) {
        this.multipleItemGroupCase(assignedItemGroups)
      }
      else {
        this.showPopup = true;
      }
    }
    else {
      this.showPopup = true
    }
  }

  // handles the case of single item group of user
  singleItemGroupCase = () => {
    if (!localStorage.getItem("selectedItemGroups"))
      localStorage.setItem('selectedItemGroups', JSON.stringify(this.itemGroupList))
    // add on item group name to retrieve later
    this.itemGroupAssignedList.push(this.itemGroupList[0].name)
    // pass the name to observable to show on header
    this.layoutService.assignItemGroups(this.itemGroupAssignedList)
  }

  // handles the case of multiple item group of user
  // PARAMETER: Takes in the list of all currently assigned groups
  multipleItemGroupCase = (assignedItemGroups: any) => {
    // look for the length to ensure
    if (assignedItemGroups && assignedItemGroups.length > 0) {
      // add on each item groups name to retrieve later
      this.itemGroupAssignedList = assignedItemGroups.map(row => row.name)
      // pass the list to observable to show on header
      this.layoutService.assignItemGroups(this.itemGroupAssignedList)
    }
  }
  // return the list of all assigned item groups
  getItemGroupAssignedList = () => this.itemGroupAssignedList;
  // return the list of all item groups
  getItemGroupList = () => this.itemGroupList
  // get color back from code directly
  defectCodeToColor = (defectCode: string): string => {
    switch (defectCode) {
      case "AE":
      case "AT":
      case "AI":
      case "A":
      case "B":
      case "N":
      case "P":
        return Colors.White;
      case "C": return Colors.Black;
      default:
        return Colors.Black;
    }
  }

  //checking for maintenance file in azure blob storage so to redirect on maintenance page(it will get fresh file content always)
  async checkFileExistsAndReadJSONFile(): Promise<{ data: string }> {
    return new Promise<{ data: string }>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      let fileUrl: string = environment.plannedMaintenanceFileEndPoint;

      // Check if the file is already cached
      const cachedData = this.localStorageService.getObject(LocalStorageKeys.FileData);
      const cachedLastModified = this.localStorageService.getString(LocalStorageKeys.LastModified);

      if (cachedData && cachedLastModified) {
        // Set the If-Modified-Since header with the cached last modified date
        xhr.open('GET', fileUrl);
        xhr.setRequestHeader('If-Modified-Since', cachedLastModified);
      } else {
        xhr.open('GET', fileUrl);
      }
      xhr.send();

      xhr.onload = () => {
        if (xhr.status === 200) {
          const lastModified = xhr.getResponseHeader('Last-Modified');
          const isNewContent = lastModified !== cachedLastModified;

          if (isNewContent) {
            const data = xhr.responseText;

            // Cache the new data and last modified date
            this.localStorageService.saveObject(LocalStorageKeys.FileData, data);
            this.localStorageService.saveString(LocalStorageKeys.LastModified, lastModified);
            resolve({ data });
          } else {
            // The content is not modified, use the cached data
            resolve({ data: cachedData });
          }
        } else if (xhr.status === 304) {
          // The content is not modified, use the cached data
          resolve({ data: cachedData });
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => {
        reject('Network error');
      };
    });
  }



  // check planned maintenance status
  checkMaintenanceStatus(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.checkFileExistsAndReadJSONFile()
        .then((result) => {
          if (result) {
            const maintenance = JSON.parse(result.data);
            const currentDate = new Date();
            const startDate = new Date(maintenance.startDate);
            const endDate = new Date(maintenance.endDate);

            // Set the time component of startDate and endDate to be the same as currentDate
            startDate.setHours(
              currentDate.getHours(),
              currentDate.getMinutes(),
              currentDate.getSeconds(),
              currentDate.getMilliseconds()
            );
            endDate.setHours(
              currentDate.getHours(),
              currentDate.getMinutes(),
              currentDate.getSeconds(),
              currentDate.getMilliseconds()
            );

            resolve(maintenance.show && currentDate >= startDate && currentDate <= endDate);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          this.logService.logError(error);
          resolve(false);
        });
    });
  }
  //method to redirect user from IH to my team (being used in breadcrumb and itemgroup)
  redirectToMyTeam(currentUrl: string, tokenFromEmployeeId: string) {
    const { myTeamManageUserPermissions, myTeamRegisterUser } = environment
    const [myTeamManage, myTeamManageInternal] = myTeamManageUserPermissions;
    const [myTeamCreate, myTeamCreateInternal] = myTeamRegisterUser

    switch (true) {
      // navigate to myTeam/update as internal instance
      case currentUrl.includes('update') && currentUrl.includes('ops'):
        this.document.location.href = `${myTeamManageInternal}?token=${tokenFromEmployeeId}`
        break;
      // navigate to myTeam/update
      case currentUrl.includes('update') && !currentUrl.includes('ops'):
        this.document.location.href = `${myTeamManage}?token=${tokenFromEmployeeId}`
        break
      // navigate to myTeam/add as internal instance
      case currentUrl.includes('add') && currentUrl.includes('ops'):
        this.document.location.href = `${myTeamCreateInternal}?token=${tokenFromEmployeeId}`
        break
      // navigate to myTeam/add
      case currentUrl.includes('add') && !currentUrl.includes('ops'):
        this.document.location.href = `${myTeamCreate}?token=${tokenFromEmployeeId}`
        break

      default:
        break;
    }
  }

}

