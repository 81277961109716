import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';

@Component({
  selector: 'app-unplanned-maintenance',
  templateUrl: './unplanned-maintenance.component.html',
  styleUrls: ['./unplanned-maintenance.component.scss']
})
export class UnplannedMaintenanceComponent {
  //status code from query param
  statusCode: string = "";
  constructor(private route: ActivatedRoute, private common: CommonService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    //to set breadcrums on refresh
    this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.unplannedMaintenance);
    //extracting the optional query param
    this.route.queryParams
      .subscribe(params => {
        //setting the query param values
        this.statusCode = params.status;
      }
      );
  }
}
