import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';

// required component annottation with configs
@Component({
  selector: 'app-management-report',
  templateUrl: './management-report.component.html',
  styleUrls: ['./management-report.component.scss']
})
export class ManagementReportComponent implements OnInit {

  // constructor with layout and router services injected
  constructor(private layoutService: LayoutService, private router: Router) { }

  ngOnInit(): void {
    //Banner text setting
    this.layoutService.setBanner(BannerDetail.managementReports);
    //sub Banner text setting
    this.layoutService.setSubBanner("Create and save management reports based on a wide range of inspection criteria");
  }
  //navigate to plant component
  navigateToPlant = () => {
    void this.router.navigateByUrl("/reports/plant");
  }
}
