<div class="modal-visually-hidden" aria-atomic="true" aria-live="polite"></div>
<div class="modal-container">
    <div class="modal-overlay-backdrop modal-overlay-dark-backdrop modal-overlay-backdrop-showing"></div>
    <div class="modal-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
        <div class="modal-overlay-pane modal-overlay-setting">
            <div tabindex="0" class="modal-visually-hidden modal-focus-trap-anchor" aria-hidden="true">
            </div>
            <div tabindex="-1" aria-modal="true" class="modal-content">
                <div class="modal-content-internal">
                    <!-- Div to show selected clients / locations text and cross button  -->
                    <div class="selectedDivAlignment">
                        <p class="selectedTextMobileAlignment" *ngIf="clientTagsData">Selected clients</p>
                        <p class="selectedTextMobileAlignment" *ngIf="locationTagsData">Selected locations</p>
                        <nx-icon name="close"
                            class="crossButtonMobileAlignmentTags ndbx-icon nx-icon--close nx-icon--auto"
                            (click)="closeModel()">
                        </nx-icon>

                        <hr>
                    </div>
                    <!-- Display all clients / locations -->
                    <div>
                        <div class="tagsMobileContainerAlignment" *ngIf="clientTagsData">
                            <ul class="tagsAlignment">
                                <li tabindex="-1"
                                    class="tagFontMobile tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-xs"
                                    *ngFor="let client of clientTagsData" (click)="deleteTag(client.clientName)">
                                    <span class="tagsTextAlignment"> {{ client.clientName }}</span>
                                    <span role="link" aria-label="close"
                                        class="nx-margin-left-xs nx-tag__close ng-star-inserted">
                                        <nx-icon name="close"
                                            class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                                            (click)="deleteTag(client.clientName,'')">
                                        </nx-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="tagsMobileContainerAlignment" *ngIf="locationTagsData">
                            <ul class="tagsAlignment">
                                <li tabindex="-1"
                                    class="tagFontMobile tagWrap nx-taglistCustom nx-taglist__list-item nx-margin-left-xs"
                                    *ngFor="let location of locationTagsData"
                                    (click)="deleteTag('',location.locationId)">
                                    <span class="tagsTextAlignment"> {{ location.locationName }}</span>
                                    <span role="link" aria-label="close"
                                        class="nx-margin-left-xs nx-tag__close ng-star-inserted">
                                        <nx-icon name="close"
                                            class="nx-tag__close-icon ndbx-icon nx-icon--close nx-icon--auto"
                                            (click)="deleteTag('',location.locationId)">
                                        </nx-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <!-- Show less option -->
                        <p class="showMoreMobileAlignment center blueColor nx-margin-left-s" (click)="closeModel()">
                            Show less...
                        </p>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>