<div *ngIf="bannerText != 'My profile'">
  <div class="bannerPanel" id="bannerPanel" *ngIf="showBanner">
    <!-- when the banner is not dashboard -->
    <div *ngIf="router.url !== '/dashboard'">
      <p>{{ bannerText }}</p>
      <!--Sub banner area-->
      <p *ngIf="subBannerText" class="subtitles" id="subTitle">
        {{ subBannerText }}
      </p>
    </div>
  </div>
</div>