import { NxModalRef } from "@aposin/ng-aquila/modal";
import { AdminItemGroupIdName } from "src/app/schemas/itemsGroup";
import { SearchModels } from "src/app/schemas/searchModel";
import { UserModel, EmployeeLocationList } from "src/app/schemas/users";
import { SharedMessages } from "src/app/shared/shared-messages";

export class EditUserViewModel {
    // modal popup refrence
    dialogRef!: NxModalRef<any, any>;
    // selected user model
    selectedUser = {} as UserModel;
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean = true;
    // employee main locations list for dropdown, 
    // filled by response of logged in user's company call in getUserCompany function
    employeeLocations: EmployeeLocationList = new EmployeeLocationList();
    // search model initilization to have search params
    queryParamList: SearchModels = new SearchModels();
    // updated role
    selectedUserRole: string;
    // enable disable save button
    disableSaveButton: boolean = true;
    // disable role dropdown
    disableRoleDropdown: boolean = false;
    // search model to store fields of search params
    locationQueryParamList: SearchModels = new SearchModels();
    // per page count
    perPage: number = 10;
    // total number of records
    resultCount: number = 0;
    // boolean properties
    _showPaging: boolean = true;
    // list of assigned location Ids
    assignedLocationsIds: any = []
    // current page number
    page: number = 1;
    //for binding this to +Add Location i.e. when clicked then observer is observed and Inntersection observer is called
    observer: IntersectionObserver;
    //one of the arguments for intersection observer for setting threshold, selecting the element from template and defining margin
    options = {
        root: document.querySelector('.root-modal'),
        rootMargin: "0px",
        threshold: 0.85,
    };
    // to check if user has searched or not to adjust functionality of clear accordingly
    isSearchCalled = false;
    // to adjust the page num depending on the modal
    unassignedModalOpened = false

    // variable to store sort direction
    sortDirection: string = "ASC"
    // variable to store sort by
    sortBy: string = "name"
    // variable to store sort direction on add location model popup
    addLocationModelSortDirection: string = "ASC"
    // variable to store sort by  on add location model popup
    addLocationModelSortBy: string = "name"
    // bit to know sorting is applied or not on add location grid
    sortAddLocationGrid: boolean = false;
    // show hide pagination
    get showPaging(): boolean {
        this._showPaging = this.resultCount > 2;
        return this._showPaging;
    }
    set showPaging(value: boolean) {
        this._showPaging = value;
    }
    // height of popover
    popoverHeight: string = "200px !important"
    // width of popover
    popoverWidth: string = "170px !important"
    // property holding the static info text
    infoPopupMessage: string = SharedMessages.shortDummyText;
    // item groups List that has all the itemGroups name and id
    itemGroupsList: AdminItemGroupIdName[] = [];
    // if itemGroup is single then just get name from API and store in this field to display
    singleItemGroupName: string;
    // boolean to check if itemGroups are single or multiple
    isSingleItemGroup: boolean;
    // model attached to multiselect dropdown to store selected items
    itemGroupModel: any[] = [];
    // API result returned from endpoint
    itemGroups: any[];
    // item group item name
    itemGroupIconName = "info-circle-o"
    // list of array list for removing item group having ids and locationIds for standard user 
    removeUserPermissionItemGroups: any[]
    // list of array list for adding item group having ids and locationIds for standard user 
    addUserPermissionItemGroups: any[]
    // list of array list for removing item group having ids for standard admin 
    removeAdminPermissionItemGroups: string[]
    // list of array list for removing item group having ids for standard admin 
    addAdminPermissionItemGroups: string[]
    // assigned item groups that were selected during registration of user
    assignedItemGroups: any[];
    // check if currently selected item groups are different from assinged item groups that were selected during registration
    areItemGroupsAssignedSelectedDifferent: boolean;
    // eReporting enabled or not
    isEReportingEnabled: boolean;
    // if save button clicked then skip confirmation modal
    isSaveButtonClicked: boolean = false
    // eReporting enabled or not
    isENotificationsEnabled: boolean;
    // locationIds relevant to the selected item groups
    assignedItemGroupIds: any = []
    // selected location to be removed from assigned location grid
    locationId: string;
    // dummy text holding static data
    dummyText: string = SharedMessages.userScreenDummyText;

}