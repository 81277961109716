// one place to list all application routes, mainly for navigation purpose
export class AppRoutes {
    public static readonly root: string = "/";
    public static readonly locations: string = "/locations";
    public static readonly locationItems = (locationId: any): string => `/locations/${locationId}/items`;
    public static readonly clients: string = "/clients";
    public static readonly clientLocationsItems: string = "/locations/items";
    public static readonly users: string = "/users";
    public static readonly editUser = (userId: any): string => `/users/edit/${userId}`;
    public static readonly apiError = "error";
    public static readonly itemDetailsByLocationItemId = (locationId: any, itemId: any): string => `/locations/${locationId}/items/details/${itemId}`;
    public static readonly itemDetailsByItemId = (itemId: any): string => `/locations/items/itemDetail/${itemId}`;
    public static readonly itemDetailsFromItems = (itemId: any): string => `/items/details/${itemId}`;
    public static readonly mainItems: string = "/items";
    public static readonly usefulResources = "/help-support";
    public static readonly plannedMaintenance = "/maintenance";
}

