import { NgModule } from '@angular/core';
import { UsersRoutingModule } from './users-routing.module';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { BannerNotificationComponent } from '../Common/banner-notification/banner-notification.component';
import { UserService } from 'src/app/services/graphql/user.Service';
import { UserListComponent } from './user-list/user-list.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { NxSwitcherModule } from '@aposin/ng-aquila/switcher';
import { ConfirmationComponent } from 'src/app/_layout/confirmation/confirmation.component';
import { ReferenceDataService } from 'src/app/services/graphql/reference-data.service';

//Users module
@NgModule({
  declarations: [
    UserRegistrationComponent,
    BannerNotificationComponent,
    UserListComponent,
    EditUserComponent
  ],
  imports: [
    UsersRoutingModule,
    SharedModule,
    FormsModule, NxSwitcherModule

  ],
  exports: [
    NxModalModule
  ],
  entryComponents: [ConfirmationComponent],
  providers: [UserService, ReferenceDataService]
})
export class UsersModule { }
