<app-loading></app-loading>
<!-- Accoridian for search and filter-->

<div class="wrapper nx-margin-top-s">
  <!-- Search and filter accordion-->
  <nx-accordion>
    <nx-expansion-panel nxStyle="light" class="nx-expanded">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title nxStyle="light" id="heading">
          <em class="fas fa-sliders-h" *ngIf="!locationViewModel.isDesktop">&nbsp;</em>
          Search & Filter
        </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <div nxLayout="grid">
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield nxLabel="Location name">
              <input nxInput placeholder="Please enter three characters at least" id="locationName" tabindex="1"
                [(ngModel)]="locationViewModel.locationName" />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield nxLabel="Address">
              <input nxInput placeholder="Please enter three characters at least" id="locationAddress" tabindex="2"
                [(ngModel)]="locationViewModel.locationAddress" />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield nxLabel="Postcode">
              <input nxInput placeholder="Please enter two characters at least" id="locationPostcode" tabindex="3"
                [(ngModel)]="locationViewModel.locationPostcode" />
            </nx-formfield>
          </div>
          <div nxCol="12,12" class="nx-margin-top-2m center" *ngIf="!locationViewModel.isDesktop">
            <button class="nx-margin-right-s" id="search" nxButton="primary small" type="button" (click)="search()"
              [disabled]="locationViewModel.searchDisabled">
              Search
            </button>
            &nbsp;
            <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small" type="button"
              (click)="clearSearch()">
              Clear
            </button>
          </div>
          <div nxCol="12,12,6" class="nx-margin-top-2m" *ngIf="locationViewModel.isDesktop">
            <button class="nx-margin-right-s" id="search" nxButton="primary small" type="button" (click)="search()"
              [disabled]="locationViewModel.searchDisabled">
              Search
            </button>
            &nbsp;
            <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small" type="button"
              (click)="clearSearch()">
              Clear
            </button>
          </div>
        </div>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
</div>
<!--Mobile View-->
<div *ngIf="!locationViewModel.isDesktop">
  <div nxRow>
    <div nxCol="12,12">
      <ng-container>
        <nx-label class="nx-margin-left-s nx-margin-top-s">
          Locations List
        </nx-label>
        <hr />
        <div *ngFor="let location of locationViewModel.locations" class="nx-margin-x-s">
          <div nxRow (click)="navigateToItems(location.id)">
            <div class="formatHeading" nxCol="4">Location Name</div>
            <div nxCol="8">
              {{ location?.name }}
            </div>
          </div>
          <div nxRow (click)="navigateToItems(location.id)">
            <div class="formatHeading" nxCol="4">Address</div>

            <div nxCol="8">
              {{
              location?.postalAddress?.address1 != "" &&
              location?.postalAddress?.address1 != null
              ? location?.postalAddress?.address1
              : ""
              }}
              {{
              location?.postalAddress?.address2 != "" &&
              location?.postalAddress?.address2 != null
              ? ", " + location?.postalAddress?.address2
              : ""
              }}
              {{
              location?.postalAddress?.address3 != "" &&
              location?.postalAddress?.address3 != null
              ? ", " + location?.postalAddress?.address3
              : ""
              }}
              {{
              location?.postalAddress?.address4 != "" &&
              location?.postalAddress?.address4 != null
              ? ", " + location?.postalAddress?.address4
              : ""
              }}
              {{
              location?.postalAddress?.address5 != "" &&
              location?.postalAddress?.address5 != null
              ? ", " + location?.postalAddress?.address5
              : ""
              }}
              {{
              location?.postalAddress?.address6 != "" &&
              location?.postalAddress?.address6 != null
              ? ", " + location?.postalAddress?.address6
              : ""
              }}
            </div>
          </div>
          <div nxRow (click)="navigateToItems(location.id)">
            <div class="formatHeading" nxCol="4">Postcode</div>

            <div nxCol="8">
              {{ location?.postalAddress?.postcode }}
            </div>
          </div>
          <div nxRow class="nx-margin-top-s">
            <div nxCol="2"></div>
            <div nxCol="4" class="display-inline cursor-pointer"
              (click)="openLocationContactModel(location.name, location.id)">
              <nx-icon name="phone" size="s" class="blueColor"></nx-icon>
              <span>Contacts</span>
            </div>
            <div nxCol="4" class="display-inline cursor-pointer"
              (click)="locationViewModel.showAttachmentIconModal = true" (click)="displayLocationInfo(location.name)">
              <nx-icon name="file" size="s" class="blueColor"></nx-icon>
              <span>Attachments</span>
            </div>
            <div nxCol="2"></div>
          </div>
          <hr />
        </div>
        <div *ngIf="
            locationViewModel.locations.length === 0 &&
            locationViewModel.apiResultReturned
          ">
          <div nxRow>
            <div class="formatHeading" nxCol="4">Location Name</div>
            <div nxCol="8">-</div>
          </div>
          <div nxRow>
            <div class="formatHeading" nxCol="4">Address</div>
            <div nxCol="8">-</div>
          </div>
          <div nxRow>
            <div class="formatHeading" nxCol="4">Postcode</div>
            <div nxCol="8">-</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div>
      <record-perpage-toggle class="nx-margin-top-s record-mobile" (onPerPageClick)="togglePerPage($event)"
        [total]="locationViewModel.resultCount" [perPage]="locationViewModel.perPage">
      </record-perpage-toggle>
      <div class="mobilePaginationAlignmentItem nx-margin-top-s">
        <nx-pagination *ngIf="locationViewModel.showPaging" [nxCount]="locationViewModel.resultCount"
          [nxPage]="locationViewModel.page" [nxPerPage]="locationViewModel.perPage" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
        </nx-pagination>
      </div>
    </div>
  </div>
</div>

<!-- Desktop -->
<div *ngIf="locationViewModel.isDesktop">
  <div class="wrapper nx-margin-top-s">
    <div class="tableFixHead">
      <table nxTable nxSort (sortChange)="sortTable($event)">
        <caption></caption>
        <thead>
          <tr nxTableRow>
            <th scope="col" nxHeaderCell nxSortHeaderCell="name">
              Location name
            </th>
            <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddress">
              Address
            </th>
            <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddressPostCode">
              Postcode
            </th>
            <th scope="col" nxHeaderCell class="center">Contacts</th>
            <th scope="col" nxHeaderCell class="center">Attachments</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr nxTableRow selectable *ngFor="let location of locationViewModel.locations">
              <td nxTableCell (click)="navigateToItems(location.id)">
                {{ location?.name }}
              </td>
              <td nxTableCell (click)="navigateToItems(location.id)">
                {{
                location?.postalAddress?.address1 != "" &&
                location?.postalAddress?.address1 != null
                ? location?.postalAddress?.address1
                : ""
                }}
                {{
                location?.postalAddress?.address2 != "" &&
                location?.postalAddress?.address2 != null
                ? ", " + location?.postalAddress?.address2
                : ""
                }}
                {{
                location?.postalAddress?.address3 != "" &&
                location?.postalAddress?.address3 != null
                ? ", " + location?.postalAddress?.address3
                : ""
                }}
                {{
                location?.postalAddress?.address4 != "" &&
                location?.postalAddress?.address4 != null
                ? ", " + location?.postalAddress?.address4
                : ""
                }}
                {{
                location?.postalAddress?.address5 != "" &&
                location?.postalAddress?.address5 != null
                ? ", " + location?.postalAddress?.address5
                : ""
                }}
                {{
                location?.postalAddress?.address6 != "" &&
                location?.postalAddress?.address6 != null
                ? ", " + location?.postalAddress?.address6
                : ""
                }}
              </td>
              <td nxTableCell (click)="navigateToItems(location.id)">
                {{ location?.postalAddress?.postcode }}
              </td>
              <td nxTableCell (click)="openLocationContactModel(location.name, location.id)" class="center">
                <nx-icon name="phone" size="s" class="blueColor"></nx-icon>
              </td>
              <td nxTableCell (click)="locationViewModel.showAttachmentIconModal = true"
                (click)="displayLocationInfo(location.name)" class="center">
                <nx-icon name="file" size="s" class="blueColor"></nx-icon>
              </td>
            </tr>
            <tr *ngIf="
                locationViewModel.locations.length === 0 &&
                locationViewModel.apiResultReturned
              ">
              <td nxTableCell>
                <i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
              </td>
              <td nxTableCell>
                <i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
              </td>
              <td nxTableCell>
                <i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="nx-margin-top-l pagination-display-inline">
      <record-perpage-toggle class="pagination-record" (onPerPageClick)="togglePerPage($event)"
        [total]="locationViewModel.resultCount" [perPage]="locationViewModel.perPage">
      </record-perpage-toggle>
      <div class="paginationContainer">
        <nx-pagination *ngIf="locationViewModel.showPaging" [nxCount]="locationViewModel.resultCount"
          [nxPage]="locationViewModel.page" [nxPerPage]="locationViewModel.perPage" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingClass">
        </nx-pagination>
      </div>
    </div>
  </div>
</div>

<!-- attachment icon modal -->
<div *ngIf="locationViewModel.showAttachmentIconModal">
  <div id="location-modal">
    <div class="location-modal-content attachment-modal-size">
      <nx-icon name="close" class="close" size="s" (click)="locationViewModel.showAttachmentIconModal = false">
      </nx-icon>
      <h3>{{ locationViewModel.selectedLocationName }} - Attachments</h3>
      <div class="attachment-container">
        <!-- desktop -->
        <div class="wrapper nx-margin-top-s attachment-table-wrapper" *ngIf="locationViewModel.isDesktop">
          <table nxTable>
            <caption></caption>
            <thead>
              <tr nxTableRow>
                <th scope="col" nxHeaderCell>Type</th>
                <th scope="col" nxHeaderCell>Name</th>
                <th scope="col" nxHeaderCell>Created date</th>
                <th scope="col" nxHeaderCell>Uploaded by</th>
                <th scope="col" nxHeaderCell></th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr nxTableRow selectable>
                  <td nxTableCell>One off investigation report</td>
                  <td nxTableCell>Report 1</td>
                  <td nxTableCell>01/02/2020</td>
                  <td nxTableCell>Steve Bishop</td>
                  <td nxTableCell>
                    <nx-icon name="pdf" size="s" class="blueColor"></nx-icon>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <!-- mobile -->
        <div *ngIf="!locationViewModel.isDesktop" class="attachment-table-wrapper">
          <hr />
          <div nxRow>
            <div nxCol="12,12">
              <ng-container>
                <div nxRow>
                  <div class="formatHeading" nxCol="4">Type</div>
                  <div nxCol="7">
                    One off investigation report
                  </div>
                </div>
                <div nxRow>
                  <div class="formatHeading" nxCol="4">Name</div>
                  <div nxCol="8">
                    Report 1
                  </div>
                </div>
                <div nxRow>
                  <div class="formatHeading" nxCol="4">Created date</div>
                  <div nxCol="8">
                    01/02/2020
                  </div>
                </div>
                <div nxRow>
                  <div class="formatHeading" nxCol="4">Uploaded by</div>
                  <div nxCol="6">
                    Steve Bishop
                  </div>
                  <div nxCol="2">
                    <nx-icon name="pdf" size="s" class="blueColor"></nx-icon>
                  </div>
                </div>
                <hr />
              </ng-container>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<!-- desktop location contacts -->

<ng-template #locationContactsTemplate>
  <app-loading></app-loading>
  <div *ngIf="locationViewModel.isDesktop">
    <h2>{{ locationViewModel.selectedLocationName }}</h2>
    <h3>Engineer surveyor contacts</h3>
    <table nxTable>
      <caption></caption>
      <thead>
        <tr nxTableRow>
          <th nxHeaderCell> Discipline </th>
          <th nxHeaderCell> Name </th>
          <th nxHeaderCell> Contact number </th>
          <th nxHeaderCell> E-mail </th>
        </tr>
      </thead>
      <tbody>
        <tr nxTableRow *ngFor="let contacts of locationViewModel.locationSurveyorContacts">
          <td nxTableCell>
            <span *ngIf="contacts.discipline.description else emptyIcon">{{contacts.discipline?.description}}</span>
          </td>
          <td nxTableCell>
            <span *ngIf="contacts.firstname || contacts.surname else emptyIcon">{{contacts.firstname}}
              {{contacts.surname}}</span>
          </td>
          <td nxTableCell>
            <span *ngIf="contacts.phoneNumber else emptyIcon">{{contacts.phoneNumber}}</span>
          </td>
          <td nxTableCell>
            <a class="underline-link" href="mailto:{{contacts.email}}"
              *ngIf="contacts.email else emptyIcon">{{contacts.email}}</a>
          </td>
        </tr>
        <tr *ngIf="locationViewModel.locationSurveyorContacts.length == 0">
          <td nxTableCell><i class="fas fa-minus" aria-hidden="true"></i></td>
          <td nxTableCell><i class="fas fa-minus " aria-hidden="true"></i></td>
          <td nxTableCell><i class="fas fa-minus " aria-hidden="true"></i></td>
          <td nxTableCell><i class="fas fa-minus " aria-hidden="true"></i></td>
        </tr>
      </tbody>
    </table>

    <br />
    <h3>Site contacts</h3>
    <table nxTable>
      <caption></caption>
      <thead>
        <tr nxTableRow>
          <th nxHeaderCell> Discipline </th>
          <th nxHeaderCell> Name </th>
          <th nxHeaderCell> Contact number </th>
        </tr>
      </thead>
      <tbody>
        <tr nxTableRow *ngFor="let siteContact of locationViewModel.locationSiteContacts">
          <td nxTableCell>
            <span
              *ngIf="siteContact.discipline.description else emptyIcon">{{siteContact.discipline?.description}}</span>
          </td>
          <td nxTableCell>
            <span *ngIf="siteContact.name  else emptyIcon">{{siteContact.name}}
            </span>
          </td>
          <td nxTableCell>
            <span *ngIf="siteContact.phoneNumber else emptyIcon">{{siteContact.phoneNumber}}</span>
          </td>

        </tr>

        <tr *ngIf="locationViewModel.locationSiteContacts.length == 0">
          <td nxTableCell><i class="fas fa-minus" aria-hidden="true"></i></td>
          <td nxTableCell><i class="fas fa-minus " aria-hidden="true"></i></td>
          <td nxTableCell><i class="fas fa-minus " aria-hidden="true"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- mobile -->
  <div class="margin-bottom" *ngIf="!locationViewModel.isDesktop">
    <h2>{{ locationViewModel.selectedLocationName }}</h2>
    <h3>Engineer surveyor contacts</h3>
    <hr>
    <div nxRow *ngFor="let contacts of locationViewModel.locationSurveyorContacts">
      <div nxCol="12,12">
        <ng-container>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Discipline</div>
            <div nxCol="7" *ngIf="contacts.discipline.description else emptyIcon">
              {{contacts.discipline?.description}}
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Name</div>
            <div nxCol="7" *ngIf="contacts.firstname || contacts.surname else emptyIcon">
              {{contacts.firstname}} {{contacts.surname}}
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Contact number</div>
            <div nxCol="7" *ngIf="contacts.phoneNumber else emptyIcon">
              {{contacts.phoneNumber}}
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">E-mail</div>
            <div nxCol="7" class="underline-link" *ngIf="contacts.email else emptyIcon">
              <a href="mailto:{{contacts.email}}">{{contacts.email}}</a>
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
    </div>
    <div nxRow *ngIf="locationViewModel.locationSurveyorContacts.length == 0">
      <div nxCol="12,12">
        <ng-container>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Discipline</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Name</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Contact number</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">E-mail</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
    </div>

    <h3>Site contacts</h3>
    <hr>
    <div nxRow *ngFor="let siteContact of locationViewModel.locationSiteContacts">
      <div nxCol="12,12">
        <ng-container>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Discipline</div>
            <div nxCol="7" *ngIf="siteContact.discipline.description else emptyIcon">
              {{siteContact.discipline?.description}}
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Name</div>
            <div nxCol="7" *ngIf="siteContact.name else emptyIcon">
              {{siteContact.name}}
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Contact number</div>
            <div nxCol="7" *ngIf="siteContact.phoneNumber else emptyIcon">
              {{siteContact.phoneNumber}}
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
    </div>
    <div nxRow *ngIf="locationViewModel.locationSiteContacts.length == 0">
      <div nxCol="12,12">
        <ng-container>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Discipline</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Name</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <div nxRow>
            <div class="nx-font-weight-semibold text-align-left" nxCol="5">Contact number</div>
            <div nxCol="7">
              -
            </div>
          </div>
          <hr />
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyIcon>
  <i class="fas fa-minus align-dash" aria-hidden="true"></i>
</ng-template>