<app-header></app-header>
<div>
    <nx-header-row nx-header *ngIf="isDesktop">
        <nx-header-navigation class="headerMargin">
            <nx-header-navigation-item>
                <a routerLink="./dashboard" data-cy="dashboardMenuItem"
                    [routerLinkActiveOptions]="{queryParams: 'exact'}" routerLinkActive="is-active"
                    nxHeaderLink>Dashboard</a>
            </nx-header-navigation-item>
            <nx-header-navigation-item>
                <a id="locationMenuItem" data-cy="locationMenuItem" routerLink="./locations"
                    [routerLinkActiveOptions]="{queryParams: 'exact'}" routerLinkActive="is-active"
                    nxHeaderLink>Locations</a>
            </nx-header-navigation-item>
            <nx-header-navigation-item>
                <a routerLink="./items" data-cy="itemsReportsMenuItem" (click)="navigateToItems()"
                    [routerLinkActiveOptions]="{queryParams: 'exact'}" routerLinkActive="is-active" nxHeaderLink>Items &
                    Reports</a>
            </nx-header-navigation-item>
            <nx-header-navigation-item>
                <a id="reportsMenuItem" routerLink="./reports" [routerLinkActiveOptions]="{queryParams: 'exact'}"
                    routerLinkActive="is-active" nxHeaderLink>Management Reports</a>
            </nx-header-navigation-item>
            <nx-header-navigation-item>
                <a routerLink="./calendar" [routerLinkActiveOptions]="{queryParams: 'exact'}"
                    routerLinkActive="is-active" nxHeaderLink>Calendar</a>
            </nx-header-navigation-item>
            <nx-header-navigation-item>
                <a routerLink="./help-support" data-cy="helpSupportMenuItem"
                    [routerLinkActiveOptions]="{queryParams: 'exact'}" routerLinkActive="is-active" nxHeaderLink>Help &
                    Support</a>
            </nx-header-navigation-item>
        </nx-header-navigation>
    </nx-header-row>
</div>
<app-maintenance-banner *ngIf="isDesktop"></app-maintenance-banner>

<!-- Hidding the breadcrumb for non desktop browsers -->
<div class="breadcrumbPadding" *ngIf="isDesktop">
    <app-breadcrumb></app-breadcrumb>
</div>
<app-itemGroups *ngIf="!isDesktop && !isInternalAdminRole" [itemGroupList]="itemGroupList"
    [itemGroupAssignedList]="itemGroupAssignedList">
</app-itemGroups>

<app-organisation *ngIf="!isDesktop && isInternalAdminRole"></app-organisation>
<app-maintenance-banner *ngIf="!isDesktop"></app-maintenance-banner>

<app-banner></app-banner>
<!--Showing APIs error through this component-->
<app-error-banner></app-error-banner>
<div class="contentAlignment">
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>


<item-group-modal (setModalStatus)="getModalOpenStatus($event)" *ngIf="showItemgroupModal()"
    [itemGroupList]="itemGroupList" [showPopup]="showPopup"
    [itemGroupAssignedList]="itemGroupAssignedList"></item-group-modal>

<organization-modal *ngIf="showOrganizationPopup && isDesktop && isInternalAdminRole"></organization-modal>