<ng-container *ngTemplateOutlet="allDefectsTemplate"></ng-container>
<ng-template #allDefectsTemplate>
  <div class="header-container" [ngClass]="{ 'increase-padding': allDefectViewModel.isAllDefectExpanded }">
    <div class="header-title-icons">
      <div class="title">
        All current defects
        <info-icon [infoMessage]="allDefectViewModel.infoPopupMessage" [height]="allDefectViewModel.popoverHeight"
          [width]="allDefectViewModel.popoverWidth" class="cursor-pointer"></info-icon>
      </div>
      <div class="icons">
        <nx-icon name="export-and-share" [ngStyle]="
            !enableExportButton && { cursor: 'not-allowed', opacity: '0.6' }
          " (click)="!enableExportButton ? null : defectCountExportToCSV()" size="s" nxTooltip="Export"
          class="blueColor cursor-pointer"></nx-icon>

        <nx-icon name="launch" size="s" nxTooltip="Expand"
          class="blueColor nx-margin-left-s hide-on-mobile cursor-pointer" (click)="expandCollapseWidget()"
          *ngIf="!allDefectViewModel.isAllDefectExpanded"></nx-icon>
        <nx-icon *ngIf="allDefectViewModel.isAllDefectExpanded" style="transform: rotate(180deg)" name="launch" size="s"
          nxTooltip="Minimise" class="blueColor nx-margin-left-s cursor-pointer" (click)="expandCollapseWidget()">
        </nx-icon>
      </div>
    </div>
    <div class="applied-filters blueColor" nxTooltip="Filter" nxTooltipPosition="right" (click)="openAllFilterModal()">
      <nx-icon name="filter" size="s"></nx-icon>
      <p class="filter-text nx-margin-left-s">Applied filters</p>
    </div>
    <div class="date-range">
      <h4>Date range:</h4>
      <p>
        &nbsp; Within {{ allDefectViewModel.dateRangeLocationsDifference }}
        <span>{{
          allDefectViewModel.dateRangeLocationsDifference == 1 ? "day" : "days"
          }}</span>
      </p>
    </div>
    <div class="locations">
      <h4>Locations:</h4>
      <p class="filtered-locations-name">
        &nbsp;{{ allDefectViewModel.locationNamesAndCount }}
      </p>
    </div>
  </div>

  <div [ngClass]="{
      'widget-cb-button-alignment': allDefectViewModel.isAllDefectExpanded,
      'align-items': locationsAllDefectCountDataLength == 0
    }">
    <div class="widget nx-margin-top-s widget-height" *ngIf="this.locationsAllDefectCountDataLength != 0"
      [ngClass]="{ 'widget-on-expand': allDefectViewModel.isAllDefectExpanded }">
      <ngx-charts-bar-horizontal [scheme]="allDefectViewModel.colorScheme" [results]="allDefectViewModel.allDefectList"
        [yAxis]="allDefectViewModel.showYAxis" [showDataLabel]="allDefectViewModel.showDataLabel"
        [tooltipDisabled]="allDefectViewModel.tooltipDisabled" (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)">
      </ngx-charts-bar-horizontal>
      <div class="lineA"></div>
      <div class="lineB"></div>
      <div class="lineP"></div>
      <div class="lineC"></div>
      <div class="lineN"></div>
    </div>
    <div *ngIf="locationsAllDefectCountDataLength == 0" [ngClass]="
        allDefectViewModel.isAllDefectExpanded
          ? 'no-defect-message-expanded-view'
          : 'no-defect-message nx-margin-top-s widget-height'
      ">
      There are no items to display.
    </div>
    <div>
      <div class="checkboxes-data nx-margin-top-xs defects-height" [ngClass]="{
          'checkboxes-data-modal': allDefectViewModel.isAllDefectExpanded
        }">
        <nx-checkbox-group [ngClass]="{
            'percent-80-width': !allDefectViewModel.isAllDefectExpanded
          }">
          <span class="align-headings">
            <nx-checkbox (change)="toggleAll()" [checked]="isAllSelected" class="nx-margin-bottom-s"
              [disabled]="this.disableSelectAllCheckbox">
              <span class="defect-code-items">Defect Codes</span>
            </nx-checkbox>
            <p class="defect-code-items adjust-alignment-items">Items</p>
          </span>

          <span *ngFor="let aDefects of allDefectCB" class="align-cb-value">
            <nx-checkbox class="nx-margin-bottom-m" (change)="selection.toggle(aDefects)"
              [checked]="selection.isSelected(aDefects)" [disabled]="aDefects.disable">
              <span class="align-dot-text">
                <span class="{{ aDefects.class }}"></span><span class="highlight-text">{{ aDefects.text }}</span>
              </span>
            </nx-checkbox>
            <span class="highlight-text nx-margin-bottom-m nx-margin-left-m">
              <span class="center-align-num">{{ aDefects.value }}</span>
            </span>
          </span>
        </nx-checkbox-group>
      </div>

      <button class="align-button-center nx-margin-top-s" nxButton="primary small" type="button"
        [ngClass]="{'nx-margin-bottom-s': !allDefectViewModel.isDesktop}" [disabled]="!(selection.selected.length > 0)"
        (click)="viewDefectsItems()">
        View Items
      </button>
    </div>
  </div>
</ng-template>

<!-- Filter Modal template start -->
<ng-template #allFilterTemplateModal>
  <h3 nxHeadline="subsection-medium" class="align-filter-heading-with-close-icon">
    All current defects filters
  </h3>
  <div nxLayout="Grid" class="remove-blank-space-top">
    <div nxRow>
      <div nxCol="6">
        <nx-formfield nxLabel="Show">
          <input nxInput [disabled]="allDefectViewModel.allDefectDateRangeSwitcherModel" placeholder="All" readonly />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <hr />
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-switcher labelSize="small" class="nx-margin-bottom-s"
          [(ngModel)]="allDefectViewModel.allDefectDateRangeSwitcherModel"
          name="allDefectViewModel.allDefectDateRangeSwitcherModel">Use Date Range</nx-switcher>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6">
        <nx-formfield nxLabel="Start date">
          <input nxDatefield name="startDate" nxInput [nxDatepicker]="startDatepicker"
            [(ngModel)]="allDefectViewModel.startDate" [nxMin]="allDefectViewModel.minStartDate"
            [nxMax]="allDefectViewModel.maxStartDate" [nxDisplayFormat]="allDefectViewModel.datePickerFormat"
            [nxParseFormat]="allDefectViewModel.datePickerFormat" #formatDatefield="nxDatefield"
            (change)="disableApplyButton()" [disabled]="!allDefectViewModel.allDefectDateRangeSwitcherModel" />
          <span nxFormfieldHint>DD/MM/YYYY</span>
          <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #startDatepicker></nx-datepicker>
        </nx-formfield>
      </div>

      <div nxCol="6">
        <nx-formfield nxLabel="End date">
          <input nxDatefield nxInput name="endDate" [nxDatepicker]="endDatepicker"
            [(ngModel)]="allDefectViewModel.endDate" [nxMin]="allDefectViewModel.minEndDate"
            [nxMax]="allDefectViewModel.maxEndDate" [nxDisplayFormat]="allDefectViewModel.datePickerFormat"
            [nxParseFormat]="allDefectViewModel.datePickerFormat" (change)="disableApplyButton()"
            [disabled]="!allDefectViewModel.allDefectDateRangeSwitcherModel" />
          <span nxFormfieldHint>DD/MM/YYYY</span>
          <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #endDatepicker></nx-datepicker>
        </nx-formfield>
      </div>
    </div>
    <div nxRow class="nx-margin-top-s">
      <div nxCol="12">
        <nx-formfield *ngIf="locationList.length <= 10" appearance="outline" nxFloatLabel="always"
          nxLabel="Filter locations">
          <nx-multi-select placeholder="Choose locations" name="selectedLocationIds" [(ngModel)]="selectedLocations"
            [options]="locationList" selectValue="id" selectLabel="name">
          </nx-multi-select>
        </nx-formfield>
        <nx-formfield *ngIf="locationList.length > 10" appearance="outline" nxFloatLabel="always">
          <nx-multi-select filter placeholder="Choose locations" name="selectedLocationIds"
            [(ngModel)]="selectedLocations" [options]="locationList" selectValue="id" selectLabel="name">
          </nx-multi-select>
        </nx-formfield>
      </div>
    </div>
  </div>
  <button [disabled]="isApplyFilterButtonDisabled || getSelectedlocationsCount()" (click)="applyFilter()"
    nxButton="primary small" class="center-align-filter-modal-button">
    Apply
  </button>
</ng-template>

<!-- expand all-defects container-->
<ng-container *ngIf="allDefectViewModel.isAllDefectExpanded">
  <div ngClass="all-defects-modal">
    <div ngClass="all-defects-modal-content">
      <ng-container *ngTemplateOutlet="allDefectsTemplate"></ng-container>
    </div>
  </div>
</ng-container>