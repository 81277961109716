import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { ItemGroupComponent } from './item-group.component';
import { CanDeactivateGuard } from 'src/app/services/auth-service/can-deactivate.guard';

//Route for item group
const routes: Routes = [{
  path: ':id/permissions/update', component: ItemGroupComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: {
    breadcrumb: 'update'
  }
},

{
  path: ':id/permissions/add', component: ItemGroupComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: {
    breadcrumb: 'add'
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
// exporting item group module
export class ItemGroupRoutingModule { }
