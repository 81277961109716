<div *ngIf="showPopup" class="modal-visually-hidden" aria-atomic="true" aria-live="polite"></div>
<div *ngIf="showPopup" class="modal-container">
    <div class="modal-overlay-backdrop modal-overlay-dark-backdrop modal-overlay-backdrop-showing"></div>
    <div class="modal-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
        <div class="modal-overlay-pane modal-overlay-setting">
            <div tabindex="0" class="modal-visually-hidden modal-focus-trap-anchor" aria-hidden="true">
            </div>
            <div tabindex="-1" aria-modal="true" class="modal-content">
                <div class="modal-content-internal">
                    <!-- Div to show selected Item groups cross button  -->
                    <div class="selectedDivAlignment">
                        <h2>Please select an organisation</h2>
                        <nx-icon name="close" (click)="cancelModal()"
                            class="crossButtonMobileAlignment ndbx-icon nx-icon--close nx-icon--auto">
                        </nx-icon>
                    </div>

                    <p>{{dummyText}}</p>
                    <hr>
                    <div class="nx-margin-top-l flex" *ngIf="isFirstTabSelected">
                        <div class="flex search-div-border percent70-width ">
                            <div class="percent10-width">
                                <nx-icon name="product-search" class="nx-margin-right-xs blueColor" size="s"></nx-icon>
                            </div>
                            <div class="percent100-width">
                                <input id="organizationInput" [(ngModel)]="organisationName" (change)="disableButton()"
                                    placeholder="Enter organisation name or post code" [nxAutocomplete]="auto1"
                                    class="percent100-width" />
                                <nx-autocomplete #auto1="nxAutocomplete" (optionSelected)="selectOrganization()">
                                    <nx-autocomplete-option *ngFor="let option of organisationList"
                                        [value]="option.name">
                                        {{ option.name }}
                                    </nx-autocomplete-option>
                                </nx-autocomplete>
                            </div>
                        </div>

                        <div class="nx-margin-left-l percent20-width ">
                            <button class="nx-margin-right-s" nxButton="primary small"
                                (click)="fetchOrganisationsList()" type="button" id="search">SEARCH</button>
                        </div>
                    </div>

                    <div *ngIf="!isFirstTabSelected && itemGroupList && itemGroupList.length > 0">
                        <div nxRow class="nx-margin-bottom-xs nx-margin-left-m">

                            <div nxCol="2" class="font-size-large nx-margin-top-s nx-margin-bottom-xs">
                                Name
                            </div>
                        </div>
                        <div class="nx-margin-x-s">
                            <div nxRow class="nx-margin-bottom-xs">
                                <form>
                                    <nx-radio-group [(ngModel)]="selectedItemGroupName" name="templateTest">
                                        <nx-radio [nxValue]="group.name" *ngFor="let group of itemGroupList"
                                            class="nx-margin-bottom-m">
                                            {{group.name}}
                                        </nx-radio>
                                    </nx-radio-group>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isFirstTabSelected && itemGroupList && itemGroupList.length===0">
                        <div nxRow class="nx-margin-bottom-xs nx-margin-left-m">

                            <div class="font-size-large nx-margin-top-s nx-margin-bottom-xs">
                                <p class="red-color"> There is no Business Area linked with selected organisation. </p>
                            </div>
                        </div>
                    </div>
                    <div class="nx-margin-top-l">
                        <nx-single-stepper currentStepLabel="Step">
                            <nx-step label="Organisation">
                                <div class="nx-margin-top-s">

                                    <div class="center nx-margin-right-2xs">
                                        <button nxButton="secondary small" (click)="cancelModal()"
                                            class="nx-margin-right-2xs" type="button">
                                            Cancel
                                        </button>
                                        <button nxButton="primary small" type="button" *ngIf="disableNextButton "
                                            [disabled]="disableNextButton">
                                            Next
                                        </button>
                                        <button nxButton="primary small" type="button" nxStepperNext
                                            *ngIf="!disableNextButton" (click)="saveOrganisation()">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </nx-step>
                            <nx-step label="Business Area(s)">
                                <div class="nx-margin-top-s">

                                    <div class="center nx-margin-right-2xs">
                                        <button nxButton="secondary small" nxStepperPrevious class="nx-margin-right-2xs"
                                            (click)="isFirstTabSelected = true" type="button">
                                            Previous
                                        </button>
                                        <button nxButton="primary small" type="button"
                                            [disabled]="selectedItemGroupName == '' || selectedItemGroupName == null"
                                            (click)="proceed()">
                                            Proceed
                                        </button>

                                    </div>
                                </div>
                            </nx-step>
                        </nx-single-stepper>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>