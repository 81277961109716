import { Component, OnInit } from '@angular/core';
import { NxSortHeaderIntl, SortEvent } from '@aposin/ng-aquila/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { LocationItemsViewModel } from './location-items.viewModel';
import { FORMFIELD_DEFAULT_OPTIONS } from '@aposin/ng-aquila/formfield';
import { SortHeaderIntl } from 'src/app/shared/sortHeaderIntl';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';
import { shareReplay } from 'rxjs/operators';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { LayoutService } from 'src/app/services/layout-service';
import { ItemPersonalization } from 'src/app/schemas/personalisation';
import { PersonalisationService } from 'src/app/services/graphql/personalisation.service';
import { DashboardViewModel } from '../../dashboard/dashboard.viewModel';
import { ComponentName } from 'src/app/shared/enums/componentName';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ExportService } from 'src/app/services/export.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorLogService } from 'src/app/services/errors/errorService';

@UntilDestroy()
@Component({
  selector: 'app-items',
  templateUrl: './location-items.component.html',
  styleUrls: ['./location-items.component.scss'],
  providers: [{
    provide: FORMFIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', nxFloatLabel: 'always' },
  }, { provide: NxSortHeaderIntl, useClass: SortHeaderIntl }]
})

export class ItemsComponent implements OnInit {

  // location-item view model instance for model binding
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();
  // dashboard view model to set params of search
  dashboardViewModel: DashboardViewModel = new DashboardViewModel;
  //personlisation object 
  personalisationObj: ItemPersonalization = new ItemPersonalization();

  constructor(
    private common: CommonService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private itemsService: LocationItemService,
    private personalisationService: PersonalisationService,
    private itemGroupService: ItemGroupService,
    private exportService: ExportService,
    private errorLogService: ErrorLogService
  ) {
    // getting current navigation object from router
    this.locationItemViewModel.currentNavigationObject = this.common.getRouterCurrentNavigationObject();

  }

  ngOnInit() {
    // retrieve extras from navigation object 
    this.locationItemViewModel.locationViewModel.stateDetails = this.locationItemViewModel.currentNavigationObject.extras.state;
    // map the model passed in state variable while navigating
    this.setStateVariableToViewModel();
    //  subscribed to route params change
    this.activatedRoute.params.subscribe(params => {
      // get location id from the route and set in viewmodel
      this.locationItemViewModel.locationItemCode = this.common.IsNullORUndefined(params, "id", "");
    });

    // subscribed to query params change
    this.activatedRoute.queryParams.subscribe(params => {
      // get data from query string
      this.getQueryParams(params);
      if (this.locationItemViewModel.itemSearch != "") {
        this.locationItemViewModel.isSearchCall = true;
      } else {
        // resetting params list and viewmodel i.e. item search's binded fields
        this.locationItemViewModel.queryItemParamList.clear(0);
        this.locationItemViewModel.clear();
      }
      // prepare the search param array to be send to api
      this.prepareSearchParamList();
      //fetch user personalisation detail for per page control
      this.fetchPersonalisation();
    });

    // prepare search param list which needs to be send to api
    this.prepareSearchParamList();
    //fetch user personalisation detail for per page control
    this.fetchPersonalisation();
    // set banner text
    this.setBanner();
    // set if its desktop or not
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => this.locationItemViewModel.isDesktop = result);
  }


  fetchItemsFiltersModel = () => {
    // retreive last url from navigation object
    if (this.locationItemViewModel.currentNavigationObject.previousNavigation)
      this.locationItemViewModel.previousUrl = this.locationItemViewModel.currentNavigationObject.previousNavigation.finalUrl.toString();
    if (this.locationItemViewModel.previousUrl != "" && this.locationItemViewModel.previousUrl.indexOf("/items/details/") != -1) {
      this.locationItemViewModel.page = this.itemsService.fetchItemsFilters().pageNo
      this.locationItemViewModel.sortDirection = this.itemsService.fetchItemsFilters().sortDirection
      this.locationItemViewModel.sortBy = this.itemsService.fetchItemsFilters().sortBy
      this.locationItemViewModel.itemSearch = this.itemsService.fetchItemsFilters().itemSearch

    }
  }

  // set banner and fetch items 
  setBannerFetchItems = (apiResult: any): void => {
    // setting viewmodel from response
    this.locationItemViewModel.locationDetails = apiResult;
    //set banner text + postcode for items page
    if (this.locationItemViewModel.locationDetails.length > 0) {
      if (this.locationItemViewModel.locationDetails[0]?.location?.postcode) {
        this.layoutService.setBannerVisibilty(BannerDetail.itemsBannerText, true, false, `${this.locationItemViewModel.locationDetails[0].location?.name} (${this.locationItemViewModel.locationDetails[0].location.postcode})`)
      }
      else {
        this.layoutService.setBannerVisibilty(BannerDetail.itemsBannerText, true, false, this.locationItemViewModel.locationDetails[0]?.location?.name)
      }
    }
    else {
      this.layoutService.setBannerVisibilty(BannerDetail.itemsBannerText, true, false)
    }
    // update the breadcrumb node with location name and postcode
    this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, `${this.locationItemViewModel.locationDetails[0]?.location.name} (${this.locationItemViewModel.locationDetails[0]?.location?.postcode})`);
  }

  // append the location ids fetched from api to make a list
  appendLocationIds = (responseList: any): void => {
    // check if data is returned from api
    if (responseList && responseList.data && responseList.data.search.results) {
      // fetch location ids from data
      const ids = responseList.data.search.results.map(function (value) { return value.id });
      // append the location Ids in Location Ids array
      this.locationItemViewModel.locationViewModel.locationIds = [...this.locationItemViewModel.locationViewModel.locationIds, ...ids]
    }
  }

  // fetch location items call from service
  async fetchItems() {
    // concate the location Ids by pipe symbol
    if (this.locationItemViewModel.locationViewModel.locationIds && this.locationItemViewModel.locationViewModel.locationIds.length > 0)
      this.concatLocationIds();
    // fetch items 
    const apiResponse = await this.itemsService.getLocationItems(this.locationItemViewModel.queryItemParamList.searchList, false,
      this.locationItemViewModel.sortDirection != "" ? this.locationItemViewModel.sortDirection.toUpperCase() : "ASC",
      this.locationItemViewModel.sortBy != "" ? this.locationItemViewModel.sortBy : "_id",
      this.locationItemViewModel.page - 1, this.locationItemViewModel.perPage,
      this.locationItemViewModel.secondarySortingRequested
    );
    // map results to items array
    this.locationItemViewModel.itemLocations.items = apiResponse.itemDetails;
    this.locationItemViewModel.resultCount = apiResponse.totalCount;
    // set the bit to show the em-dash in table
    this.locationItemViewModel.apiResultReturned = true;
    // set banner and then fetch items
    this.setBannerFetchItems(apiResponse?.itemDetails);
  }

  // fetch items filtered by defect codes passed from Dashboard
  fetchItemsFilteredByDefects() {
    const variables = {
      searchList: this.locationItemViewModel.queryItemParamList.searchList,
      order: this.locationItemViewModel.sortDirection != "" ? this.locationItemViewModel.sortDirection.toUpperCase() : "ASC",
      sortName: this.locationItemViewModel.sortBy != "" ? this.locationItemViewModel.sortBy : "_id",
      from: this.locationItemViewModel.page - 1,
      size: this.locationItemViewModel.perPage,
      dueFrom: this.locationItemViewModel.locationViewModel.itemDefectDetails.endDate,
      dueTo: this.locationItemViewModel.locationViewModel.itemDefectDetails.startDate,
      locationIds: this.extractLocationIds(),
      defectTypes: this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDefectTypes,
      itemGroupIds: this.itemGroupService.getSelectedItemGroupsIds()
    }

    // fetch items 
    this.itemsService.getItemsWithDefects(variables).pipe(shareReplay(), untilDestroyed(this)).subscribe(async result => {
      // check if data is present
      if (result && result.data && result.data.itemsWithDefects && result.data.itemsWithDefects.results) {
        // map items rows
        this.locationItemViewModel.itemLocations.items = result.data.itemsWithDefects.results;
        // map the total count of records
        this.locationItemViewModel.resultCount = result.data.itemsWithDefects.totalCount;
        // split the array by comma separator
        const splittedArr = this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDefectTypes.split(',');
        // if defect is of type P, no need to find its subdefects
        // replace the defectcode with P defect
        const subDefects = ["I", "E", "T"];
        if (splittedArr.length == 1 && this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDefectTypes == "P")
          this.updatePDefectCode();
        // extract item level defect code i-e I,E,T for A defect code
        // to show on front-end when call is send from dashboard
        else if (splittedArr.length > 0 && subDefects.some(subDef => this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDefectTypes.includes(subDef)))
          this.extractItemsDefectCode();

      }
    })
  }
  // iterate on all the items and replace the defect code to P
  updatePDefectCode = (defectCode: string = "P") => {
    this.locationItemViewModel.itemLocations.items.forEach(item => {
      // if reports and reports.results object is present
      if (item.reports && item.reports.results) {
        for (let reportObj of item.reports.results) {
          // replace the code with P defect code
          reportObj.defectCode.code = defectCode
        }
      }
    });
  }
  // extract the item sub defects from response and map in Item Sub defects Array
  extractItemsDefectCode = () => {
    // iterate on items array
    this.locationItemViewModel.itemLocations.items.forEach(item => {
      // if reports and reports.results object is present
      if (item.reports && item.reports.results) {
        // initialize the subdefects array in item object
        this.extractSubDefectType(item)
      }
    })
  }

  extractSubDefectType = (item: any): void => {
    // make array of sub defects codes by splitting from comma
    // it is required to show only those subdefects which are required
    const filterSubDefects = this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDefectTypes.split(",")
    // loop on report results object to find the sub defects
    for (let itemObj of item.reports.results) {
      // check if results array have item defects object
      if (itemObj.itemDefects) {
        item.subDefects = []
        // iteration over the item defects object
        itemObj.itemDefects.forEach(itemDefectsList => {
          // if item defect object have defect type
          if (itemDefectsList.defectType) {
            // check if this subdefect is not already present in sub defects array to avoid duplication
            // requirement was to pick top one
            // check if this subdefect is present in filter criteria
            if (!item.subDefects.some(subDefect => subDefect == itemDefectsList.defectType) && filterSubDefects.some(filterSubdefect => filterSubdefect == itemDefectsList.defectType))
              // push this sub defect to item sub defects array
              item.subDefects.push(itemDefectsList.defectType)
          }
        })
      }
    }

  }

  // extract location Ids from item defect object 
  // used in retrieving the items of only returned location Ids
  extractLocationIds = (): string[] => {
    // array to store location id
    let locationIds: string[] = []
    // iteration an get location Id object only
    this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.forEach(row => locationIds.push(row['locationId']));
    // return the prepared list of only location Ids
    return locationIds;
  }

  // clear search form, reset page and reload data from api
  clearSearch() {
    // clear search viewmodel
    this.locationItemViewModel.clear();
    // clear query params from items and locations list
    this.locationItemViewModel.queryItemParamList.clear(0)
    this.locationItemViewModel.queryLocationParamList.clear(0)
    // page to 1
    this.locationItemViewModel.page = 1;
    // update the route
    if (this.locationItemViewModel.locationViewModel.locationDetails != undefined)
      void this.common.navigate(AppRoutes.clientLocationsItems, { state: this.locationItemViewModel.locationViewModel.locationDetails });
    else {
      void this.common.navigate(AppRoutes.locationItems(this.locationItemViewModel.locationItemCode) + "?sortDirection=desc&sortBy=inspectionsInspectionDate")
    }
  }

  // search specific items from API
  search() {

    // updating route
    this.locationItemViewModel.page = 1;
    //call the method to assign value of search
    this.locationItemViewModel.queryItemParamList = this.locationItemViewModel.assignSearchInput(this.locationItemViewModel.itemSearch)
    // prepare parameters list to send to api
    this.prepareSearchParamList();
    //set seearch true to handle search flow
    this.locationItemViewModel.isSearchCall = true;
    // update url with updated parameters
    if (this.locationItemViewModel.locationViewModel.locationDetails != undefined)
      void this.common.navigate(`${AppRoutes.clientLocationsItems}?pageNo=${this.locationItemViewModel.page}&sortDirection=${this.locationItemViewModel.sortDirection}&sortBy=${this.locationItemViewModel.sortBy}&itemSearch=${this.locationItemViewModel.itemSearch}&perPage=${this.locationItemViewModel.perPage}`, { state: this.locationItemViewModel.locationViewModel.locationDetails });
    else if (this.locationItemViewModel.locationViewModel.itemDefectDetails != undefined)
      void this.common.navigate(`${AppRoutes.clientLocationsItems}?pageNo=${this.locationItemViewModel.page}&sortDirection=${this.locationItemViewModel.sortDirection}&sortBy=${this.locationItemViewModel.sortBy}&itemSearch=${this.locationItemViewModel.itemSearch}&perPage=${this.locationItemViewModel.perPage}`, { state: this.locationItemViewModel.locationViewModel.itemDefectDetails });
    else
      void this.common.navigate(`${AppRoutes.locationItems(this.locationItemViewModel.locationItemCode)}?pageNo=${this.locationItemViewModel.page}&sortDirection=${this.locationItemViewModel.sortDirection}&sortBy=${this.locationItemViewModel.sortBy}&itemSearch=${this.locationItemViewModel.itemSearch}&perPage=${this.locationItemViewModel.perPage}`)

  }

  // sort location-items list on client side
  sortLocally(sort: SortEvent): void {
    // getting sortby and direction from sort event
    this.locationItemViewModel.sortBy = sort.active;
    this.locationItemViewModel.sortDirection = sort.direction;
    // apply sorting on location items through a sort function
    this.locationItemViewModel.itemLocations.items.sort((a, b) => {
      switch (sort.active) {
        case 'serialNumber':
          return this.common.compare(this.common.safeString(a.serialNumber), this.common.safeString(b.serialNumber), sort.direction);
        case 'clientPlantNumber':
          return this.common.compare(this.common.safeString(a.clientPlantNumber), this.common.safeString(b.clientPlantNumber), sort.direction);
        case 'plantTypeDescription': return this.common.compare(a.plantType?.description, b.plantType?.description, sort.direction);
        case 'disciplineDescription': return this.common.compare(a.discipline?.description, b.discipline?.description, sort.direction);
        case 'locationDescription': return this.common.compare(a.location?.description, b.location?.description, sort.direction);
        case 'inspectionsInspectionDate': return this.common.compare(a.inspections.inspectionDate ? new Date(a.inspections.inspectionDate) : "", b.inspections.inspectionDate ? new Date(b.inspections.inspectionDate) : "", sort.direction);
        case 'inspectionsNextInspectionDate': return this.common.compare(a.inspections.nextInspectionDate ? new Date(a.inspections.nextInspectionDate) : "", b.inspections.nextInspectionDate ? new Date(b.inspections.nextInspectionDate) : "", sort.direction);
        case 'inspectionsNextInspectionType': return this.common.compare(a.inspections.nextInspectionType?.description, b.inspections.nextInspectionType?.description, sort.direction);
        case 'statusDescription': return this.common.compare(a.status?.description, b.status?.description, sort.direction);
        case 'currentReportDefectCode': return this.common.compare(a.latestReport?.defectCode?.code, b.latestReport?.defectCode?.code, sort.direction);
        case 'subLocationDescription': return this.common.compare(a.location ? a.toolTipSubLocation : "", b.location ? b.toolTipSubLocation : "", sort.direction);
        case 'currentReportId': return this.common.compare(a.reportID, b.reportID, sort.direction)
        default: return 0;
      }
    });
  }

  // sort items by specific column locally
  sortTable(sort: SortEvent) {
    // if records are less than 11 then sort locally
    if (this.locationItemViewModel.resultCount <= 10)
      this.sortLocally(sort);
    else {
      // this is pagination case, we are quering api to sort location items for us from backend
      this.locationItemViewModel.sortBy = sort.active;
      this.locationItemViewModel.sortDirection = sort.direction;
      // if its a search call then incoprating search fields data in the query params
      // else only sort by and direction is required
      if (this.locationItemViewModel.isSearchCall) {
        this.common.navigate(`${AppRoutes.locationItems(this.locationItemViewModel.locationItemCode)}`, {
          queryParams: {
            pageNo: this.locationItemViewModel.page,
            sortDirection: this.locationItemViewModel.sortDirection,
            sortBy: this.locationItemViewModel.sortBy,
            itemSearchInput: this.locationItemViewModel.itemSearch,
            perPage: this.locationItemViewModel.perPage
          }
        }).catch(error => {
          // Handle error here
          console.error(error);
        });
      } else {
        void this.common.navigate(`${AppRoutes.locationItems(this.locationItemViewModel.locationItemCode)}`, {
          queryParams: {
            pageNo: this.locationItemViewModel.page,
            sortDirection: this.locationItemViewModel.sortDirection,
            sortBy: this.locationItemViewModel.sortBy,
            perPage: this.locationItemViewModel.perPage
          }
        });
      }

    }
  }


  // navigate to specific page and load its data
  // configured with pagination control
  goToPage(pageNumber: number) {
    this.locationItemViewModel.page = pageNumber;
    this.loadPage();
  }

  // navigate to previous page
  prevPage() {
    this.locationItemViewModel.page--;
    this.loadPage();
  }

  // navigate to next page
  nextPage() {
    this.locationItemViewModel.page++;
    this.loadPage();
  }

  // used with pagination control on view
  loadPage() {
    // navigate to route with required query params, if it's a search call
    if (this.locationItemViewModel.isSearchCall) {
      this.common.navigate(`${AppRoutes.locationItems((this.locationItemViewModel.locationItemCode))}`, {
        queryParams: {
          pageNo: this.locationItemViewModel.page,
          sortDirection: this.locationItemViewModel.sortDirection,
          sortBy: this.locationItemViewModel.sortBy,
          itemSearchInput: this.locationItemViewModel.itemSearch,
          perPage: this.locationItemViewModel.perPage
        }
      }).catch(error => {
        // Handle error here
        this.errorLogService.logError(error);
      });
    } else {
      // else navigate without search query params
      this.common.navigate(`${AppRoutes.locationItems((this.locationItemViewModel.locationItemCode))}`, {
        queryParams: {
          pageNo: this.locationItemViewModel.page,
          sortDirection: this.locationItemViewModel.sortDirection,
          sortBy: this.locationItemViewModel.sortBy,
          perPage: this.locationItemViewModel.perPage
        }
      }).catch(error => {
        // Handle error here
        this.errorLogService.logError(error);
      });
    }
  }



  // get query params and map to our view model and local variables
  getQueryParams(params: any): void {
    // check if object have any keys
    // if yes, means we have to pick data from query string
    if (params && Object.keys(params).length > 0) {
      this.locationItemViewModel.page = this.common.IsNullORUndefined(params, "pageNo", 1);
      const sortByDetail = this.common.IsNullORUndefString(params, "sortBy");
      this.locationItemViewModel.sortBy = sortByDetail.split(',').length > 1 ? sortByDetail.split(',')[0] : sortByDetail;
      this.locationItemViewModel.secondarySortingRequested = sortByDetail.split(',').length > 1;
      this.locationItemViewModel.sortDirection = this.common.IsNullORUndefString(params, "sortDirection");
      this.locationItemViewModel.perPage = this.common.IsNullORUndefined(params, "perPage", 10);
      this.locationItemViewModel.itemSearch = this.common.IsNullORUndefString(params, "itemSearch", "");
    }
    // if no, lets look for any values in filter service
    else {
      this.fetchItemsFiltersModel();
    }
  }

  // set multi fields search values
  prepareSearchParamList(): void {
    //checking if user moving back from detail page
    if (this.locationItemViewModel.previousUrl != "" && this.locationItemViewModel.previousUrl.indexOf("/items/details/") != -1)
      this.locationItemViewModel.queryItemParamList = this.locationItemViewModel.assignSearchInput(this.locationItemViewModel.itemSearch)

    if (this.locationItemViewModel.locationViewModel.locationDetails && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('startDate') && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('endDate') && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('locationDetailList')) {
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, "itemNextInspectionDate", `[${this.locationItemViewModel.locationViewModel.locationDetails.startDate} TO ${this.locationItemViewModel.locationViewModel.locationDetails.endDate}]`);
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, `${this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.map(location => location.locationId).join("|")}`);
    }
    else {
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, `${this.locationItemViewModel.locationItemCode}`);

    }
  }

  // toggle number of records per page by clicking 
  public togglePerPage(selectedPerPage: PerPageModel): void {
    // clicked option's value is set as per page option
    this.locationItemViewModel.perPage = selectedPerPage.value;
    // page to load is set to 1 i.e. load first page
    this.locationItemViewModel.page = 1;
    //save user personalisation  
    this.personalisationObj.perPageToggle = `${selectedPerPage.value.toString()}`;
    // save the personalisation
    this.savePersonalisation();
    // calling load page
    this.loadPage();
  }
  // delete funtion for location name from modal in modal
  deleteTagFromModel = (dataFromModel: string) => {
    // check if input is type of number, it means that location id is passed
    if (typeof dataFromModel == 'number')
      // call filter function
      this.filterTagsData(dataFromModel)
  }
  // delete location tag by location id
  deleteTag = (locationId: any = "") => this.filterTagsData(locationId)

  // method to filter/remove record from list of location detail , fetch data of left records and show remaining data in tags only.
  filterTagsData(locationId: any) {
    // check if location details array exists with data
    // if yes, we need to remove the id from this list
    if (this.locationItemViewModel.locationViewModel.locationDetails)
      this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList = this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.filter(x => x.locationId != locationId);
    // check if item defects details array exists with data
    // if yes, we need to remove the id from this list
    else if (this.locationItemViewModel.locationViewModel.itemDefectDetails)
      this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList = this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.filter(x => x.locationId != locationId);

    // clear existing search param list
    this.locationItemViewModel.queryItemParamList.clear(0);
    // prepare search param list with new location ids
    this.prepareSearchParamList();
    // set banner accordingly
    this.setBanner();
    // fetch locations and items
    this.clientDetailsMapping();
    // Hide Modal if left with one location
    if ((this.locationItemViewModel.locationViewModel.locationDetails && this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length == 1) || (this.locationItemViewModel.locationViewModel.itemDefectDetails && this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length == 1))
      this.locationItemViewModel.locationViewModel.showTagsModal = false;
  }


  // set banner of items page
  setBanner = () => {
    // set subTitle of the banner
    this.layoutService.setSubBanner(BannerDetail.itemsBannerSubtitleText);
    // need to make location name and postcode as banner text
    if (this.locationItemViewModel.locationViewModel.locationDetails && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('locationDetailList')) {
      // set banner according to location due/overdue details
      this.setBannerWithLocationDueOverdueDetails();
    }
    else if (this.locationItemViewModel.locationViewModel.itemDefectDetails && this.locationItemViewModel.locationViewModel.itemDefectDetails.hasOwnProperty('locationDetailList')) {
      // set banner according to location a widget items details
      this.setBannerWithItemDefectsDetails();
    }
    else {
      // set location field search value
      this.prepareSearchLocationParamList();
      this.layoutService.setBannerVisibilty(BannerDetail.itemsBannerText)
      if (this.locationItemViewModel.locationItemCode != "")
        // fetch location data from api
        this.fetchItems();
    }

  }
  // set banner according to location a widget items details
  setBannerWithItemDefectsDetails = () => {
    if (this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.length == 1) {
      // setting banner title for items page
      this.layoutService.setBanner(`${this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList.find(x => x.locationName != undefined).locationName} ${BannerDetail.itemsBannerText}`);

    }
    else {
      // setting banner title for location page
      this.layoutService.setBanner(BannerDetail.itemsBannerText);
    }
  }
  // set banner according to location due/overdue details
  setBannerWithLocationDueOverdueDetails = () => {
    if (this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.length == 1) {
      // setting banner title for items page

      this.layoutService.setBanner(`${this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.find(x => x.locationName != undefined).locationName} ${BannerDetail.itemsBannerText}`);
      // assign the location Id to be used to navigate to item details
      this.locationItemViewModel.locationItemCode = this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.find(x => x.locationId != undefined).locationId
    }
    else {
      // setting banner title for location page
      this.layoutService.setBanner(BannerDetail.itemsBannerText);
    }
  }

  // set location field search value
  prepareSearchLocationParamList = () => this.common.updateSearchParamList(this.locationItemViewModel.queryLocationParamList, this.locationItemViewModel.locationViewModel.locationIdKey, `${this.locationItemViewModel.locationItemCode}`);

  // concat all locationIds with pipe sign
  concatLocationIds = () => this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, this.locationItemViewModel.locationViewModel.locationIds.join("|"));


  // client details mapping in view model and calling function (fetchLocation / fetchItems) based on data
  clientDetailsMapping() {
    if (this.locationItemViewModel.locationViewModel.itemDefectDetails) {
      // if no  location details, then fetch items filtered by defects
      this.fetchItemsFilteredByDefects();
    }
    else {
      // else directly fetch items
      this.fetchItems();
    }
  }
  //fetch user personalisation 
  fetchPersonalisation() {
    // check if item personalisation object is present
    if (this.personalisationService.personalization.item) {
      this.personalisationObj = this.personalisationService.personalization.item;
    }
    this.locationItemViewModel.perPage = parseInt(this.personalisationObj.perPageToggle);
    // map the client details in view model
    this.clientDetailsMapping();
  }
  //save user personalisation 
  async savePersonalisation(): Promise<void> {
    await this.personalisationService.updateComponentSettings(this.personalisationObj, ComponentName.itemList);
  }

  // function to close the modal
  getModalOpenStatus = (status: boolean) => this.locationItemViewModel.locationViewModel.showTagsModal = status;

  //navigate to item details
  navigateToItemDetails = (itemId: string) => {
    // save filters before navigation
    this.itemsService.setItemsFilters(this.locationItemViewModel.locationViewModel.locationDetails, this.locationItemViewModel.page, this.locationItemViewModel.sortBy, this.locationItemViewModel.sortDirection, this.locationItemViewModel.itemSearch);
    if (this.locationItemViewModel.locationItemCode)
      // navigate to details of a item 
      void this.common.navigate(AppRoutes.itemDetailsByLocationItemId(this.locationItemViewModel.locationItemCode, itemId))
    else
      void this.common.navigate(AppRoutes.itemDetailsByItemId(itemId))


  }
  // map state variables to view model
  // 3 types of state object are passed to this while navigation
  // 1. Object of client details
  // 2. Object of location due/overdue details from dashboard
  // 3. Object of A defect widget details from dashboard
  setStateVariableToViewModel = () => {
    // check if state details object have data, and it is of location details, then map accordingly
    if (this.locationItemViewModel.locationViewModel.stateDetails != undefined && this.locationItemViewModel.locationViewModel.stateDetails.hasOwnProperty("startDate")) {
      // check if it has a property of defect types
      if (this.locationItemViewModel.locationViewModel.stateDetails.hasOwnProperty("locationDefectTypes")) {
        // map the defect details
        this.locationItemViewModel.locationViewModel.itemDefectDetails = this.locationItemViewModel.locationViewModel.stateDetails;
        // sort the array alpha numerically
        this.locationItemViewModel.locationViewModel.alphaNumericSort(this.locationItemViewModel.locationViewModel.itemDefectDetails.locationDetailList)
      }
      else {
        // the state object is having details of location due / over due
        this.locationItemViewModel.locationViewModel.locationDetails = this.locationItemViewModel.locationViewModel.stateDetails;
        // sort the array alpha numerically
        this.locationItemViewModel.locationViewModel.alphaNumericSort(this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList)

      }

    }


  }

  //export data as csv
  exportItemsToCSV = () => {
    //we are using header option.
    const options = {
      headers: ["Current report/ID", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
        "Defect code", "Next due", "Sub location", "Detailed location", "Item status", "Policy number", "ACE plant no", "Manufacture", "Date of manufacture", "Written scheme reference", "Next contractual date"],

    };
    if (this.locationItemViewModel.itemLocations.items) {
      // export the content to csv file

      this.exportService.exportToCsv(this.locationItemViewModel.itemLocations.items, this.locationItemViewModel.exportItemFileName, options, [], null)
    }
  }




}