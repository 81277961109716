<app-loading></app-loading>

<hr class="horizontal-line-below-register-user nx-margin-top-s" />
<div class="wrapper">
    <nx-label class="nx-margin-left-s greyColor margin-top-register-user">
        Registered users
    </nx-label>
    <nx-accordion class="nx-margin-top-s nx-margin-bottom-xs">
        <nx-expansion-panel nxStyle="light">
            <nx-expansion-panel-header>
                <nx-expansion-panel-title nxStyle="light" id="heading">
                    <em class="fas fa-sliders-h" *ngIf="!userViewModel.isDesktop">&nbsp;</em>
                    Search & Filter
                </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div class="desktopSearchFilter">

                <div nxLayout="grid">
                    <div nxRow>

                        <div class="desktopSearchFilter-change-width">
                            <nx-formfield nxLabel="Name">
                                <input nxInput placeholder="Please enter three characters at least" id="name"
                                    [(ngModel)]="userViewModel.name" tabindex="1" />
                            </nx-formfield>
                        </div>

                        <div class="alignButtons">
                            <button id="search" [disabled]="userViewModel.searchDisabled" class="nx-margin-right-s "
                                nxButton="primary small" type="button" (click)="search()">Search</button>
                            <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                                (click)="clearSearch()" type="button">Clear</button>
                        </div>

                    </div>
                </div>
            </div>
        </nx-expansion-panel>
    </nx-accordion>
</div>
<!-- Desktop -->
<div *ngIf="userViewModel.isDesktop">
    <div class="wrapper nx-margin-top-m">
        <table selectable nxTable nxSort (sortChange)="sortTable($event)">
            <caption></caption>
            <thead>
                <tr nxTableRow>
                    <th scope="col" id="uname" nxSortHeaderCell="name" nxHeaderCell>Name</th>
                    <th scope="col" id="role" nxHeaderCell>Role</th>
                    <th scope="col" id="email" nxSortHeaderCell="email" nxHeaderCell>Email</th>
                    <th scope="col" id="manage" nxHeaderCell>Manage</th>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr nxTableRow *ngFor="let user of userViewModel.users">
                        <td nxTableCell>{{user?.name}} </td>
                        <td nxTableCell>{{user?.role}}</td>
                        <td nxTableCell class="wrap-text">{{user?.emailAddress}}</td>
                        <td nxTableCell>
                            <a style="cursor: pointer;">
                                <nx-icon name="chevron-right" class="arrowIcon lightBlueColor"
                                    (click)="navigateToEdit(user)"></nx-icon>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="nx-margin-top-m pagination-display-inline">
        <record-perpage-toggle class="pagination-record" (onPerPageClick)="togglePerPage($event)"
            [total]="userViewModel.resultCount" [perPage]="userViewModel.perPage">
        </record-perpage-toggle>
        <div class="paginationContainer">
            <nx-pagination *ngIf="userViewModel.showPaging" [nxCount]="userViewModel.resultCount"
                [nxPage]="userViewModel.page" [nxPerPage]="userViewModel.perPage" nxType="advanced"
                (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)" class="pagingClass">
            </nx-pagination>
        </div>
    </div>
</div>

<!-- Mobile -->
<div *ngIf="!userViewModel.isDesktop">
    <div nxRow>
        <div nxCol="12,12">
            <ng-container>
                <div *ngFor="let user of userViewModel.users" class="nx-margin-x-s" (click)="navigateToEdit(user)">
                    <div nxRow>
                        <div class="formatHeading" nxCol="4">
                            Name:
                        </div>
                        <div nxCol="8">
                            {{user?.firstname}} {{user?.surname}}
                        </div>
                    </div>
                    <div nxRow>
                        <div class="formatHeading" nxCol="4">

                            Role:

                        </div>

                        <div nxCol="8">
                            {{user?.role}}

                        </div>
                    </div>
                    <div nxRow>
                        <div class="formatHeading" nxCol="4">

                            Email:

                        </div>

                        <div nxCol="8" class="wrap-text">
                            {{user?.emailAddress}}
                        </div>
                    </div>
                    <hr />
                </div>
            </ng-container>


        </div>
        <div class="mobilePaginationAlignment nx-margin-top-s">
            <nx-pagination *ngIf="userViewModel.showPaging" [nxCount]="userViewModel.resultCount"
                [nxPage]="userViewModel.page" [nxPerPage]="userViewModel.perPage" nxType="advanced"
                (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
            </nx-pagination>
        </div>
    </div>
</div>