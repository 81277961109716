import { Component } from '@angular/core';
import { NxModalRef } from '@aposin/ng-aquila/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent {
  //handle button click
  public confirmationSubject: Subject<boolean>;
  //model reference
  dialogRef: NxModalRef<ConfirmationComponent>

  // open/close confirmation modal
  openCloseModal = (openModal: boolean) => {
    if (this.confirmationSubject) {
      this.confirmationSubject.next(openModal);
      this.confirmationSubject.complete();
    }
    this.dialogRef.close(openModal);
  }
}
