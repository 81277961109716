import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { Observable } from 'rxjs';
import { FormValidityService } from 'src/app/services/FormValidityService';
import { CommonService } from 'src/app/services/common.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { CurrentUser } from 'src/app/statemanagement/models/current-user.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

  // control the routing of home click, will be updated once we have dashboard developed
  homeLink: string = '/';
  // boolean to know that device is desktop or mobile
  isDesktop: boolean = true;
  // property to store firstname of clientUser
  firstname: string = ''
  surname: string = ''
  // observable to know details of user
  user$: Observable<CurrentUser>;
  //current url
  currentUrl: string = "";
  // modal template for cancel changes 
  @ViewChild('cancelChangesModal') cancelChangesModal!: TemplateRef<any>;
  // reference to the template model
  templateDialogRef?: NxModalRef<any>;
  ngOnInit(): void {
    this.device.currentDevice.subscribe(result => this.isDesktop = result);
    // if the url have permission/update then fetch details of user name 
    if (this.router.url.indexOf('/permissions') > 1) {
      // subscribe to observable to know about details of user name
      this.device.clientUserNameDetails.subscribe(result => {
        this.firstname = result.firstName;
        this.surname = result.surName;
        this.currentUrl = this.router.url;
      });
    }
  }
  // Constructor with Layout Service injected, utilized in .html file
  constructor(public device: LayoutService, public router: Router, private common: CommonService,
    private localStorageService: LocalStorageService, private formValidityService: FormValidityService, private dialogService: NxDialogService) {

  }
  //navigate back to my team instance, if no change, else show cancel popup
  navigateToProfile = () => {
    this.formValidityService.isFormValid$.subscribe(isValid => {
      isValid ? this.openCancelModal() : this.redirectToMyTeam();
    });
  }
  // open cancel modal
  openCancelModal() {
    this.templateDialogRef = this.dialogService.open(this.cancelChangesModal, { ariaLabel: 'Cancel changes?', showCloseIcon: true });
  }
  // close submit/cancel modals
  closeCancelSubmitModal = () => this.templateDialogRef?.close();

  //redirect to my team
  redirectToMyTeam() {
    // get employeeToken from LS
    const tokenEmployeeID = this.localStorageService.getString(LocalStorageKeys.EmployeeToken);
    // redirect to my team using following method
    this.common.redirectToMyTeam(this.currentUrl, tokenEmployeeID);
  }
}
