<div class="header-container">
  <div class="header-title-icons">
    <div class="title" data-cy="pna-widget-title">
      Plant not available count
      <info-icon [infoMessage]="pnaViewModel.pnaInfoMessage" [height]="pnaViewModel.popoverHeight"
        [width]="pnaViewModel.popoverWidth"></info-icon>
    </div>
    <div class="icons">
      <nx-icon name="export-and-share"
        [ngStyle]="pnaViewModel.pDefectCount === 0 && {cursor: 'not-allowed', opacity: '0.6'}" size="s"
        (click)="pnaViewModel.pDefectCount === 0  ? null : defectCountExportToCSV()" nxTooltip="Export"
        class="blueColor"></nx-icon>
    </div>
  </div>

</div>

<nx-card class="card-color nx-margin-top-l" (click)="viewDefectsItems()">
  <div class="align-center">
    <h1 class="bold-count">{{pnaViewModel.pDefectCount}}</h1>
    <p class="blueColor view-items-style">View Items</p>
  </div>
</nx-card>