<app-loading></app-loading>

<!-- Mobileview -->

<div class="nx-margin-top-s" *ngIf="!editUserViewModel.isDesktop">
    <div class="wrapper">
        <div class="nx-margin-s margin-box">

            <!--Employee section-->
            <div class="left">
                <h3 nxHeadline="subsection-large">Employee details</h3>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Title">
                        <nx-dropdown [(nxValue)]="editUserViewModel.selectedUser.title"
                            [(ngModel)]="editUserViewModel.selectedUser.title" nxDisabled="true"
                            class="disable-bg-color">
                            <nx-dropdown-item nxValue="1">Miss</nx-dropdown-item>
                            <nx-dropdown-item nxValue="2">Mrs</nx-dropdown-item>
                            <nx-dropdown-item nxValue="3">Ms</nx-dropdown-item>
                            <nx-dropdown-item nxValue="4">Mr</nx-dropdown-item>
                            <nx-dropdown-item nxValue="5">Dr</nx-dropdown-item>
                            <nx-dropdown-item nxValue="6">Mx</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError> Your title is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="First name">
                        <input nxInput [(ngModel)]="editUserViewModel.selectedUser.firstname" readonly />
                        <nx-error nxFormfieldError> Your first name is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Last name">
                        <input nxInput [(ngModel)]="editUserViewModel.selectedUser.surname" readonly />
                        <nx-error nxFormfieldError> Your last name is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Email address">
                        <input nxInput [(ngModel)]="editUserViewModel.selectedUser.emailAddress" readonly />
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(1)">
                        </nx-icon>

                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Contact no. (Direct dial only)">
                        <input nxInput [(ngModel)]="editUserViewModel.selectedUser.contactNo" readonly />
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(2)"> </nx-icon>
                        <nx-error nxFormfieldError> Your contact no is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Mobile no.">
                        <input nxInput readonly [(ngModel)]="editUserViewModel.selectedUser.mobileNo" />
                    </nx-formfield>
                </div>
            </div>
            <!--End Employee section-->
            <!--Organization section-->

            <div class="left">
                <h3 nxHeadline="subsection-large">Organisation details</h3>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Company">
                        <input nxInput [(ngModel)]="editUserViewModel.selectedUser.selectedCompany" readonly />
                        <nx-error nxFormfieldError> Your Company is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Main employee location">
                        <nx-dropdown [nxShowFilter]="true" [(ngModel)]="editUserViewModel.selectedUser.branchId"
                            nxDisabled="" class="disable-bg-color">
                            <nx-dropdown-item
                                *ngFor="let location of editUserViewModel.employeeLocations.employeeLocations"
                                [nxValue]="location.id">
                                {{location.name}}
                            </nx-dropdown-item>
                        </nx-dropdown>
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(3)"> </nx-icon>
                        <nx-error nxFormfieldError> Your main employee location is missing. </nx-error>
                    </nx-formfield>
                </div>

            </div>
            <div nxRow>
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Role">
                        <nx-dropdown [(ngModel)]="editUserViewModel.selectedUser.role"
                            [(nxDisabled)]="editUserViewModel.disableRoleDropdown"
                            (ngModelChange)="enableDisableSaveButton(editUserViewModel.selectedUser.role)">
                            <nx-dropdown-item nxValue="Standard Client User">Standard client user</nx-dropdown-item>
                            <nx-dropdown-item nxValue="Standard Client Admin">Standard client admin
                            </nx-dropdown-item>
                        </nx-dropdown>
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(4)">
                        </nx-icon>
                        <nx-error nxFormfieldError> Your role location is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <!--End Organization section-->
            <!--Subscriptions section-->
            <div class="nx-margin-top-s">
                <div class="permissionPanel">
                    <p>Subscriptions</p>
                </div>
                <div class="align-switcher-mobile">
                    <div nxRow class="nx-margin-y-s">
                        <nx-switcher [(ngModel)]="editUserViewModel.selectedUser.eNotificationsEnabled"
                            [checked]="editUserViewModel.selectedUser.eNotificationsEnabled" name="eNotifications"
                            labelPosition="left" labelSize="small">
                            eNotifications
                        </nx-switcher>

                    </div>
                    <div nxRow class="nx-margin-y-s">
                        <nx-switcher [(ngModel)]="editUserViewModel.selectedUser.eReportingEnabled"
                            [checked]="editUserViewModel.selectedUser.eReportingEnabled" name="eReporting"
                            labelPosition="left" labelSize="small">
                            eReporting
                        </nx-switcher>
                        &nbsp;
                        <info-icon class="nx-margin-top-1m" [infoMessage]="editUserViewModel.infoPopupMessage"
                            [height]="editUserViewModel.popoverHeight" [width]="editUserViewModel.popoverWidth">
                        </info-icon>
                    </div>
                </div>
            </div>
            <div class="permissionPanel nx-margin-top-s">
                <p id="permissionPanel">Permissions</p>
            </div>
            <ng-container *ngTemplateOutlet="groupItems"></ng-container>
            <div class="nx-margin-top-s">
                <nx-accordion>
                    <nx-expansion-panel>
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title>
                                <div class="flex">
                                    <nx-icon icon="location-o" class="icon-circle blue-color" name="location-o"
                                        size="s">
                                    </nx-icon>
                                    <p class="nx-margin-left-xs extra-large-font nx-margin-top-xs">Assigned site
                                        locations</p>
                                </div>
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>

                        <div nxRow class="nx-margin-top-xs table-container">
                            <div nxCol="12">
                                <ng-container>
                                    <div nxLayout="grid nogutters" nxTableRow
                                        *ngFor="let location of editUserViewModel.selectedUser.assignedLocations">
                                        <div nxRow>

                                            <div nxCol="12">
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">
                                                        Location name:
                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="8" class="nx-margin-left-xs">

                                                        {{location?.name}}
                                                    </div>
                                                </div>
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">

                                                        Address:

                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="7" class="nx-margin-left-xs">

                                                        {{location?.postalAddress?.address1 !="" &&
                                                        location?.postalAddress?.address1 != null ?
                                                        location?.postalAddress?.address1 : ""}}
                                                        {{location?.postalAddress?.address2 !="" &&
                                                        location?.postalAddress?.address2 != null ? ", " +
                                                        location?.postalAddress?.address2 : ""}}
                                                        {{location?.postalAddress?.address3 !="" &&
                                                        location?.postalAddress?.address3 != null ? ", " +
                                                        location?.postalAddress?.address3 : ""}}
                                                        {{location?.postalAddress?.address4 !="" &&
                                                        location?.postalAddress?.address4 != null ? ", " +
                                                        location?.postalAddress?.address4 : ""}}
                                                        {{location?.postalAddress?.address5 !="" &&
                                                        location?.postalAddress?.address5 != null ? ", " +
                                                        location?.postalAddress?.address5 : ""}}
                                                        {{location?.postalAddress?.address6 !="" &&
                                                        location?.postalAddress?.address6 != null ? ", " +
                                                        location?.postalAddress?.address6 : ""}}
                                                    </div>

                                                    <div nxCol="1">
                                                        <nx-icon name="trash-o" size="s"
                                                            (click)="openRemoveConfirmationModel(location?.id)">
                                                        </nx-icon>

                                                    </div>
                                                </div>
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">

                                                        Postcode:

                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="8" class="nx-margin-left-xs">

                                                        {{location?.postalAddress?.postcode}}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr />
                                    </div>
                                    <div *ngIf="editUserViewModel?.selectedUser?.assignedLocations?.length === 0">
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Location name:
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Address:
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Postcode:
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <nx-pagination *ngIf="editUserViewModel.showPaging"
                                                [nxCount]="editUserViewModel.resultCount"
                                                [nxPage]="editUserViewModel.page"
                                                [nxPerPage]="editUserViewModel.perPage" nxType="advanced"
                                                (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()"
                                                (nxGoPage)="goToPage($event)"
                                                class="pagination-center-alignment nx-margin-top-s">
                                            </nx-pagination>
                                        </div>
                                    </div>


                                </ng-container>

                                <div class="center nx-margin-top-2m">
                                    <a nxButton="secondary small" type="button" (click)="newLocationsModel()">
                                        <nx-icon name="plus" size="s"></nx-icon>Add location
                                    </a>
                                </div>
                            </div>

                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
            </div>

            <!--Action section-->
            <div nxRow class="nx-margin-top-xs">

                <div class="alignCenter">
                    <button id="btnMobileSave" nxButton="primary small" (click)="saveUser()"
                        [disabled]="IsSaveButtonDisabled()" type="submit">Save</button>
                    &nbsp;
                    <button type="reset" id="btnCancel" nxButton="secondary small" type="button"
                        (click)="redirectToUsers()" class="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Desktop -->

<div class="nx-margin-top-s" *ngIf="editUserViewModel.isDesktop">
    <div nxLayout="grid">

        <!--Employee section-->
        <div class="left">
            <h3 nxHeadline="subsection-large">Employee details</h3>
        </div>
        <div nxRow>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Title">
                    <nx-dropdown [(nxValue)]="editUserViewModel.selectedUser.title"
                        [(ngModel)]="editUserViewModel.selectedUser.title" nxDisabled="true" class="disable-bg-color">
                        <nx-dropdown-item nxValue="1">Miss</nx-dropdown-item>
                        <nx-dropdown-item nxValue="2">Mrs</nx-dropdown-item>
                        <nx-dropdown-item nxValue="3">Ms</nx-dropdown-item>
                        <nx-dropdown-item nxValue="4">Mr</nx-dropdown-item>
                        <nx-dropdown-item nxValue="5">Dr</nx-dropdown-item>
                        <nx-dropdown-item nxValue="6">Mx</nx-dropdown-item>
                    </nx-dropdown>
                    <nx-error nxFormfieldError> Your title is missing. </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6">
                <nx-formfield nxLabel="First name">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.firstname" readonly />
                    <nx-error nxFormfieldError> Your first name is missing. </nx-error>
                </nx-formfield>
            </div>

        </div>
        <div nxRow>
            <div nxCol=" 12,6">
                <nx-formfield nxLabel="Last name">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.surname" readonly />
                    <nx-error nxFormfieldError> Your last name is missing. </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Email address">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.emailAddress" readonly />
                    <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                        (click)="displayInfoModal(1)"></nx-icon>
                </nx-formfield>
            </div>
        </div>
        <div nxRow>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Contact no. (Direct dial only)">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.contactNo" readonly />
                    <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                        (click)="displayInfoModal(2)">
                    </nx-icon>
                    <nx-error nxFormfieldError> Your contact no is missing. </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Mobile no.">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.mobileNo" readonly />
                </nx-formfield>
            </div>
        </div>
        <!--End Employee section-->
        <!--Organization section-->

        <div class="left">
            <h3 nxHeadline="subsection-large">Organisation details</h3>
        </div>
        <div nxRow>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Company">
                    <input nxInput [(ngModel)]="editUserViewModel.selectedUser.selectedCompany" readonly />
                    <nx-error nxFormfieldError> Your Company is missing. </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Main employee location">
                    <nx-dropdown [nxShowFilter]="true" [(ngModel)]="editUserViewModel.selectedUser.branchId"
                        nxDisabled="true" class="disable-bg-color">
                        <nx-dropdown-item *ngFor="let location of editUserViewModel.employeeLocations.employeeLocations"
                            [nxValue]="location.id">
                            {{location.name}}
                        </nx-dropdown-item>
                    </nx-dropdown>
                    <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover">
                    </nx-icon>
                    <nx-error nxFormfieldError> Your main employee location is missing. </nx-error>
                </nx-formfield>
            </div>

        </div>
        <div nxRow>
            <div nxCol="12,6">
                <nx-formfield nxLabel="Role">
                    <nx-dropdown [(nxValue)]="editUserViewModel.selectedUser.role"
                        [(nxDisabled)]="editUserViewModel.disableRoleDropdown"
                        [(ngModel)]="editUserViewModel.selectedUser.role"
                        (ngModelChange)="enableDisableSaveButton(editUserViewModel.selectedUser.role)">
                        <nx-dropdown-item nxValue="Standard Client User">Standard client user
                        </nx-dropdown-item>
                        <nx-dropdown-item nxValue="Standard Client Admin">Standard client admin</nx-dropdown-item>
                    </nx-dropdown>
                    <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                        (click)="displayInfoModal(4)">
                    </nx-icon>
                    <nx-error nxFormfieldError> Your role location is missing. </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6">
                &nbsp;
            </div>
        </div>
        <!--Subscriptions section-->
        <div class="nx-margin-top-s">
            <div class="permissionPanel">
                <p>Subscriptions</p>
            </div>
            <div class="switcher-alignment">
                <div nxRow class="nx-margin-x-2m nx-margin-y-s">
                    <nx-switcher [(ngModel)]="editUserViewModel.selectedUser.eNotificationsEnabled"
                        [checked]="editUserViewModel.selectedUser.eNotificationsEnabled" name="eNotifications"
                        labelPosition="left" labelSize="small">
                        eNotifications
                    </nx-switcher>

                </div>
                <div nxRow class="nx-margin-x-2m nx-margin-y-s">
                    <nx-switcher [(ngModel)]="editUserViewModel.selectedUser.eReportingEnabled"
                        [checked]="editUserViewModel.selectedUser.eReportingEnabled" name="eReporting"
                        labelPosition="left" labelSize="small">
                        eReporting
                    </nx-switcher>
                    &nbsp;
                    <info-icon class="nx-margin-top-1m" [infoMessage]="editUserViewModel.infoPopupMessage"
                        [height]="editUserViewModel.popoverHeight" [width]="editUserViewModel.popoverWidth">
                    </info-icon>
                </div>
            </div>
        </div>
        <div>

            <div class="permissionPanel nx-margin-top-s">
                <p id="permissionPanel">Permissions</p>
            </div>
            <ng-container *ngTemplateOutlet="groupItems"></ng-container>
            <div class="nx-margin-top-m nx-margin-bottom-m">
                <nx-accordion>
                    <nx-expansion-panel>
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title>
                                <div class="flex">
                                    <nx-icon icon="location-o" class="icon-circle blue-color" name="location-o"
                                        size="s">
                                    </nx-icon>
                                    <p class="nx-margin-left-xs extra-large-font nx-margin-top-xs">Assigned site
                                        locations</p>
                                </div>
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>

                        <div class="table-container">
                            <table nxTable class="nx-margin-top-xs" nxSort (sortChange)="sortTable($event)">
                                <caption></caption>
                                <thead>
                                    <tr nxTableRow>
                                        <th scope="col" nxHeaderCell nxSortHeaderCell="name">Location name</th>
                                        <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddress">Address</th>
                                        <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddressPostCode">
                                            Postcode</th>
                                        <th scope="col" nxHeaderCell></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container>
                                        <tr nxTableRow
                                            *ngFor="let location of editUserViewModel.selectedUser.assignedLocations">
                                            <td nxTableCell> {{ location?.name }}</td>
                                            <td nxTableCell> {{
                                                location?.postalAddress?.address1 != "" &&
                                                location?.postalAddress?.address1 != null
                                                ? location?.postalAddress?.address1
                                                : ""
                                                }}
                                                {{
                                                location?.postalAddress?.address2 != "" &&
                                                location?.postalAddress?.address2 != null
                                                ? ", " + location?.postalAddress?.address2
                                                : ""
                                                }}
                                                {{
                                                location?.postalAddress?.address3 != "" &&
                                                location?.postalAddress?.address3 != null
                                                ? ", " + location?.postalAddress?.address3
                                                : ""
                                                }}
                                                {{
                                                location?.postalAddress?.address4 != "" &&
                                                location?.postalAddress?.address4 != null
                                                ? ", " + location?.postalAddress?.address4
                                                : ""
                                                }}
                                                {{
                                                location?.postalAddress?.address5 != "" &&
                                                location?.postalAddress?.address5 != null
                                                ? ", " + location?.postalAddress?.address5
                                                : ""
                                                }}
                                                {{
                                                location?.postalAddress?.address6 != "" &&
                                                location?.postalAddress?.address6 != null
                                                ? ", " + location?.postalAddress?.address6
                                                : ""
                                                }}</td>

                                            <td nxTableCell>{{ location?.postalAddress?.postcode }}</td>
                                            <td nxTableCell>
                                                <nx-icon name="trash-o" size="s" nxTooltip="remove location"
                                                    (click)="openRemoveConfirmationModel(location?.id)">
                                                </nx-icon>
                                            </td>
                                        </tr>
                                        <tr *ngIf="editUserViewModel?.selectedUser?.assignedLocations?.length === 0">
                                            <td nxTableCell><i class="fas fa-minus" aria-hidden="true"></i></td>
                                            <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                            </td>
                                            <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                            </td>

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div class="nx-margin-top-m pagination-display-inline">
                                <record-perpage-toggle class="pagination-record"
                                    (onPerPageClick)="togglePerPage($event)" [total]="editUserViewModel.resultCount"
                                    [perPage]="editUserViewModel.perPage">
                                </record-perpage-toggle>
                                <div class="paginationContainer">
                                    <nx-pagination *ngIf="editUserViewModel.showPaging"
                                        [nxCount]="editUserViewModel.resultCount" [nxPage]="editUserViewModel.page"
                                        [nxPerPage]="editUserViewModel.perPage" nxType="advanced"
                                        (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)"
                                        class="pagingClass">
                                    </nx-pagination>
                                </div>
                            </div>
                            <div class="center nx-margin-top-2m">
                                <a nxButton="secondary small" type="button" (click)="newLocationsModel()">
                                    <nx-icon name="plus" size="s"></nx-icon>Add location
                                </a>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
            </div>
        </div>


        <!--End Organization section-->

        <!--Action section-->
        <div nxRow class="actionPanel nx-margin-0 ">

            <div class="alignCenter">
                <button id="btnDesktopSave" type="submit" nxButton="primary small" [disabled]="IsSaveButtonDisabled()"
                    (click)="saveUser()">Save</button>
                &nbsp;
                <button type="reset" id="btnDesktopCancel" nxButton="secondary small" type="button"
                    (click)="redirectToUsers()" class="cancel-button">Cancel</button>
            </div>
        </div>
    </div>

</div>


<ng-template #template let-data>
    <div nxModalContent id="edit-user-info-popup">
        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-3m">Info</h3>
        <p nxCopytext>{{data.body}}</p>
    </div>
</ng-template>

<ng-template #newLocationsTemplate class="root-modal">
    <app-loading></app-loading>
    <div *ngIf="editUserViewModel.isDesktop">
        <div nxRow>
            <div nxCol="3">&nbsp;</div>
            <div nxCol="7">
                <div>
                    <h1 nxHeadline="subsection-medium" class="nx-margin-bottom-s">Search for unassigned locations
                    </h1>

                </div>
            </div>
            <div nxCol="2">&nbsp;</div>
        </div>
        <div class="nx-margin-x-2m">

            Copy text that describes what this overlay is all about. This text provides the user with additional
            information.

        </div>

        <div nxRow class="nx-margin-x-l">

            <div nxCol="7" class="desktopSearchFilter-change-width">
                <nx-formfield>
                    <input nxInput [(ngModel)]="userViewModel.locationNameAndAddress"
                        placeholder="Please enter three characters at least" id="name" tabindex="1" />
                </nx-formfield>
            </div>

            <div class="alignButtons">
                <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                    [disabled]="userViewModel.modelSearchDisabled" (click)="searchLocations()">Search</button>
                <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                    (click)="clearSearch()" type="button">Clear</button>
            </div>

        </div>

        <div>
            Search Results: <span class="blue-color">{{locationViewModel.locations.length}}
                {{locationViewModel.locations.length ===1 ?'location':'locations'}}</span>
        </div>



        <ng-container>

            <div class="tableFixHead">
                <table id="newLocations" nxTable class="nx-margin-top-s" nxSort
                    (sortChange)="sortAddLocationTable($event)">
                    <caption></caption>
                    <thead class="sticky-thead">
                        <tr nxTableRow>
                            <th nxHeaderCell class="nx-table-cell--checkbox" aria-label="Row selection">
                                <nx-checkbox (checkedChange)="toggleAll()" aria-label="Toggle selection of all rows"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    *ngIf="locationViewModel.resultCount > 0">
                                </nx-checkbox>
                            </th>
                            <th scope="col" nxHeaderCell nxSortHeaderCell="name">Location name</th>
                            <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddress">Address</th>
                            <th scope="col" nxHeaderCell nxSortHeaderCell="postalAddressPostCode">Postcode</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr nxTableRow selectable *ngFor="let location of locationViewModel.locations">
                                <td nxTableCell class="nx-table-cell--checkbox">
                                    <nx-checkbox (checkedChange)="toggleSelected(location)"
                                        aria-label="Toggle row selection" [checked]="selection.isSelected(location)">
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell>{{location?.name}}</td>
                                <td nxTableCell>{{location.completeAddress}}</td>
                                <td nxTableCell>{{location?.postalAddress?.postcode}}</td>
                            </tr>
                            <div class="infinite-scroller" #theLastItem></div>
                            <tr *ngIf="locationViewModel.locations.length === 0">
                                <td nxTableCell><i class="fas fa-minus" aria-hidden="true"></i></td>
                                <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                </td>
                                <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                </td>
                                <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!--Action section-->
            <div nxRow class="actionPanel nx-margin-top-s">

                <div class="alignCenter">
                    <button type="reset" nxButton="secondary small" type="button" class="nx-margin-right-s"
                        (click)="closeModel()">Cancel</button>
                    <button id="btnDesktopSave" type="submit" nxButton="primary small" (click)="fetchAdminItemGroup()"
                        [disabled]="selection.selected.length==0">Save
                        locations</button>
                    &nbsp;

                </div>
            </div>
        </ng-container>
    </div>
    <!-- mobile -->
    <div *ngIf="!editUserViewModel.isDesktop">
        <div class="adjust-position-head-text">
            <h2 nxHeadline="subsection-medium" class="nx-margin-bottom-s">Search for unassigned locations</h2>
            <p>Copy text that describes what this overlay is all about. This text provides the user with additional
                information.</p>
        </div>
        <div class="align-input-search-clear">
            <nx-formfield class="reduce-input-size">
                <input nxInput [(ngModel)]="userViewModel.locationNameAndAddress"
                    placeholder="Please enter at least three characters" id="name" tabindex="1" />
            </nx-formfield>

            <div class="alignButtons">
                <button id="search" class="nx-margin-right-s" nxButton="primary small" type="button"
                    [disabled]="userViewModel.modelSearchDisabled" (click)="searchLocations()">
                    <nx-icon name="search" size="m" class="set-search-icon-style"></nx-icon>
                    Search
                </button>
                <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                    (click)="clearSearch()" type="button">Clear</button>
            </div>
        </div>


        <div class="grid-container">
            <div nxLayout="grid nogutters" nxTableRow>
                <div nxRow>
                    <div nxCol="1">
                        <nx-checkbox (checkedChange)="toggleAll()" aria-label="Toggle selection of all rows"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            *ngIf="locationViewModel.resultCount > 0">
                        </nx-checkbox>
                    </div>
                    <div nxCol="4">&nbsp;</div>
                    <div nxCol="7">&nbsp;</div>
                </div>
                <hr>
                <div *ngFor="let location of locationViewModel.locations">

                    <div nxRow>
                        <div nxCol="1">&nbsp;</div>
                        <div class="formatHeading" nxCol="4">Location name: &nbsp;</div>
                        <div nxCol="7">{{location?.name}}</div>
                    </div>
                    <div nxRow>
                        <div nxCol="1">
                            <nx-checkbox (checkedChange)="toggleSelected(location)" aria-label="Toggle row selection"
                                [checked]="selection.isSelected(location)">
                            </nx-checkbox>
                        </div>
                        <div class="formatHeading" nxCol="4">Address: &nbsp;</div>
                        <div nxCol="7">{{location.completeAddress}}</div>
                    </div>
                    <div nxRow>
                        <div nxCol="1">&nbsp;</div>
                        <div class="formatHeading" nxCol="4">Postcode: &nbsp;</div>
                        <div nxCol="7">{{location?.postalAddress?.postcode}}</div>
                    </div>
                    <hr />

                </div>
            </div>

            <div class="infinite-scroller" #theLastItem></div>

            <div *ngIf="locationViewModel.locations.length == 0">
                <div nxRow>
                    <div class="formatHeading" nxCol="4">
                        Location name:
                    </div>
                    <div nxCol="8">
                        -
                    </div>
                </div>
                <div nxRow>
                    <div class="formatHeading" nxCol="4">
                        Address:
                    </div>
                    <div nxCol="8">
                        -
                    </div>
                </div>
                <div nxRow>
                    <div class="formatHeading" nxCol="4">
                        Postcode:
                    </div>
                    <div nxCol="8">
                        -
                    </div>
                </div>
            </div>
        </div>

        <div class="align-action-buttons">
            <button type="reset" nxButton="secondary small" type="button" class="nx-margin-right-s"
                (click)="closeModel()">Cancel</button>
            <button id="btnDesktopSave" type="submit" nxButton="primary small" (click)="fetchAdminItemGroup()"
                [disabled]="selection.selected.length==0">Save
                locations</button>

        </div>




    </div>
</ng-template>

<!-- Model popup to remove location-->
<ng-template #removeLocationsTemplate>
    <div nxModalContent>
        <h1 nxHeadline="subsection-large" class="nx-margin-bottom-3m">Remove assigned location?</h1>
        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">Removing this location means you will no longer
            have access to it.
            Are you sure you wish to proceed?
        </h3>
    </div>
    <div nxModalActions>
        <button nxModalClose class="nx-margin-bottom-0 nx-margin-right-xs " nxButton="small secondary"
            type="button">Cancel</button>
        <button nxModalClose class="nx-margin-bottom-0 " nxButton="small" type="button"
            (click)="removeLocation()">Proceed</button>


    </div>
</ng-template>

<!-- assigned group items -->
<ng-template #groupItems>
    <div class="align-location-icon-text">
        <nx-icon name="location-o" outline="true" size="s" class="blueColor"></nx-icon>
        <h2>Assigned Item group(s)</h2>
    </div>
    <div nxRow>
        <div [nxCol]="editUserViewModel.isDesktop ? '6': '12'"
            [ngClass]="{'item-groups-col-size' : editUserViewModel.isDesktop}">
            <nx-formfield appearance="outline" nxFloatLabel="always" *ngIf="!editUserViewModel.isSingleItemGroup">
                <nx-multi-select placeholder="Choose options" [options]="editUserViewModel.itemGroupsList"
                    selectLabel="name" selectValue="id" [(ngModel)]="editUserViewModel.itemGroupModel"
                    [formControl]="itemGroupsFormcontrol" name="itemGroupsFormcontrol"></nx-multi-select>
                <nx-icon nxFormfieldAppendix name={{editUserViewModel.itemGroupIconName}} size="s" class="blueColor"
                    (mouseover)='mouseOver()' (mouseout)='mouseOut()'
                    [nxPopoverTriggerFor]="itemGroupPopoverBottomAnchor"></nx-icon>
            </nx-formfield>
            <nx-formfield *ngIf="editUserViewModel.isSingleItemGroup">
                <input nxInput type="text" [placeholder]="editUserViewModel.singleItemGroupName" disabled />
                <nx-icon nxFormfieldAppendix name={{editUserViewModel.itemGroupIconName}} size="s"
                    class="blueColor"></nx-icon>
            </nx-formfield>
            <nx-error
                *ngIf="!editUserViewModel.isSingleItemGroup && itemGroupsFormcontrol && itemGroupsFormcontrol.errors && (itemGroupsFormcontrol.dirty || itemGroupsFormcontrol.touched)">Select
                at least one item group</nx-error>
        </div>
    </div>
</ng-template>

<nx-popover #itemGroupPopoverBottomAnchor>
    <div class="width-tooltip">{{editUserViewModel.dummyText}}</div>
</nx-popover>