<ng-contrainer *ngTemplateOutlet="defectInfoIconTemplate"></ng-contrainer>
<!-- heading title and icons -->
<!-- A defect widget icon info -->

<ng-template #defectInfoIconTemplate>
    <div class="header-container" [ngClass]="{ 'increase-padding': aDefectViewModel.isPieChartContainerExpanded }">
        <div class="defect-header">
            <div> A-defects count
                <info-icon [infoMessage]="aDefectViewModel.infoPopupMessage" [height]="aDefectViewModel.popoverHeight"
                    [width]="aDefectViewModel.popoverWidth"></info-icon>
            </div>
            <div class="align-icons">
                <nx-icon
                    [ngStyle]="aDefectViewModel.aDefectDataObj?.locationADefectCountList?.length == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
                    class="nx-margin-left-s" name="export-and-share" size="s"
                    (click)="aDefectViewModel.aDefectDataObj?.locationADefectCountList?.length == 0 ? null : defectCountExportToCSV()"
                    nxTooltip="Export" class="blueColor nx-margin-right-s cursor-pointer">
                </nx-icon>
                <nx-icon *ngIf="aDefectViewModel.isDesktop && !aDefectViewModel.isPieChartContainerExpanded"
                    name="launch" size="s" nxTooltip="Expand" class="blueColor cursor-pointer"
                    (click)="expandCollapsePieChartContainer()">
                </nx-icon>
                <nx-icon *ngIf="aDefectViewModel.isDesktop && aDefectViewModel.isPieChartContainerExpanded"
                    style="transform: rotate(180deg)" name="launch" size="s" nxTooltip="Minimise"
                    class="blueColor cursor-pointer" (click)="expandCollapsePieChartContainer()"></nx-icon>

            </div>

        </div>

        <!-- filter output by default it should be 14 days -->
        <div>
            <div class="applied-filters blueColor" nxTooltip="Filter" nxTooltipPosition="right"
                (click)="openADefectFilter()">
                <nx-icon name="filter" size="s"></nx-icon>
                <p class="filter-text nx-margin-left-s">Applied filters</p>
            </div>
            <div class="date-range">
                <h4>Date range:</h4>
                <p>
                    &nbsp; Previous {{aDefectViewModel.differenceBetweenDates}}
                    <span>{{aDefectViewModel.differenceBetweenDates==1 ? 'day': 'days'}}</span>
                </p>
            </div>
            <div class="locations">
                <h4>Locations:</h4>
                <p class="filtered-locations-name">&nbsp;{{aDefectViewModel.isAllADefectSelected ? 'All' :
                    aDefectViewModel.aDefectLocationNamesAndCount}}</p>
            </div>
        </div>
    </div>
    <div [ngClass]="{
    'widget-cb-button-alignment': aDefectViewModel.isPieChartContainerExpanded
  }">
        <div class="widget-height" [ngClass]="{
            'pie-chart': !aDefectViewModel.isPieChartContainerExpanded
          }">

            <div *ngIf="aDefectViewModel.aDefectDataObj?.locationADefectCountList?.length > 0"
                [ngClass]="{ 'widget-on-expand': aDefectViewModel.isPieChartContainerExpanded }">
                <!-- doughnut pie chart -->
                <ngx-charts-pie-chart [scheme]="colorScheme"
                    [results]="aDefectViewModel.aDefectDataObj?.locationADefectCountList" [labels]="showLabels"
                    [doughnut]="isDoughnut" [tooltipDisabled]="tooltipDisabled" [view]="view" [trimLabels]="trimLabels">
                </ngx-charts-pie-chart>
            </div>
            <div class="no-defect-message"
                [ngClass]="{'no-defect-msg-default-view': !aDefectViewModel.isPieChartContainerExpanded}"
                *ngIf="aDefectViewModel.aDefectDataObj?.locationADefectCountList?.length == 0">
                There are no items to display.
            </div>
        </div>
        <div>
            <div class="defects-height checkboxes-data" [ngClass]="{'checkboxes-data-modal': aDefectViewModel.isPieChartContainerExpanded, 
                'defect-table': !aDefectViewModel.isPieChartContainerExpanded,
                'nx-margin-top-l': !aDefectViewModel.isDesktop 
            
            }">
                <nx-checkbox-group [ngClass]="{
                    'percent-90-width': aDefectViewModel.isPieChartContainerExpanded
                  }" [ngClass]="{
                    'percent-60-width': !aDefectViewModel.isPieChartContainerExpanded
                  }">
                    <nx-checkbox (change)="toggleAllADefectCB()" [checked]="isAllADefectSelected"
                        class="nx-margin-bottom-m"
                        [disabled]="aDefectViewModel.aDefectListObject.AEDefectCount == 0 && aDefectViewModel.aDefectListObject.AIDefectCount == 0 && aDefectViewModel.aDefectListObject.ATDefectCount == 0">
                        <span class="highlight-head">Defect codes</span>
                    </nx-checkbox>
                    <span *ngFor="let aDefects of aDefectCB">
                        <nx-checkbox class="nx-margin-bottom-m d-flex" (change)="aDefectCBSelection.toggle(aDefects)"
                            [checked]="aDefectCBSelection.isSelected(aDefects)" [disabled]="aDefects.disable">
                            <div class="align-center">
                                <span class="{{aDefects.class}}"></span>
                                <span class="highlight-text">{{aDefects.value}}</span>
                            </div>
                        </nx-checkbox>
                    </span>
                </nx-checkbox-group>
                <div class="center-align nx-margin-top-xxs">
                    <div class="nx-margin-top-xxs top-margin-12Percent nx-margin-bottom-m nx-margin-left-m highlight-head"
                        [ngClass]="{'grey-text': aDefectViewModel.aDefectListObject.AEDefectCount == 0 && aDefectViewModel.aDefectListObject.AIDefectCount == 0 && aDefectViewModel.aDefectListObject.ATDefectCount == 0}">
                        Items</div>
                    <div class="top-margin-12Percent nx-margin-bottom-m nx-margin-left-m highlight-text"
                        [ngClass]="{'grey-text': aDefectViewModel.aDefectListObject.AEDefectCount == 0}">
                        {{aDefectViewModel.aDefectListObject.AEDefectCount}}
                    </div>
                    <div class="top-margin-12Percent nx-margin-bottom-m nx-margin-left-m highlight-text"
                        [ngClass]="{'grey-text': aDefectViewModel.aDefectListObject.AIDefectCount == 0}">
                        {{aDefectViewModel.aDefectListObject.AIDefectCount}}
                    </div>
                    <div class="top-margin-12Percent nx-margin-bottom-m nx-margin-left-m highlight-text"
                        [ngClass]="{'grey-text': aDefectViewModel.aDefectListObject.ATDefectCount == 0}">
                        {{aDefectViewModel.aDefectListObject.ATDefectCount}}
                    </div>
                </div>
            </div>


            <!-- button -->


            <button class="nx-margin-top-s" [disabled]="aDefectCBSelection.selected.length <= 0" [ngClass]="{'defect-button-margin':
                 aDefectViewModel.aDefectDataObj?.locationADefectCountList?.length > 0 && !aDefectViewModel.isPieChartContainerExpanded, 'defect-button-margin': aDefectViewModel.aDefectDataObj.locationADefectCountList.length == 0 && !aDefectViewModel.isPieChartContainerExpanded,
                  'align-button-center':
                 !aDefectViewModel.isPieChartContainerExpanded, 
                 'nx-margin-bottom-s': !aDefectViewModel.isDesktop ,
                 'defect-button': aDefectViewModel.isPieChartContainerExpanded}" nxButton="primary small" type="button"
                (click)="viewDefectsItems()">
                View Items
            </button>
        </div>
    </div>

</ng-template>
<!-- A-defect count widget filter Start -->
<ng-template #aDefectfilterTemplateModal>
    <app-filter-dashboard (selectedStartDate)="selectedStartDate($event)" (selectedEndDate)="selectedEndDate($event)"
        (selectedShowPreviousValue)="selectedShowPreviousValue($event)"
        (selectedFilteredADefectLocations)="selectedFilteredADefectLocations($event)"
        (getLocationADefects)="getLocationADefects($event)" (differenceBetweenDate)="differenceBetweenDate($event)"
        (defectStartDate)="defectStartDate($event)" (defectEndDate)="defectEndDate($event)"
        [locationsADefectList]="locationList" [aDefectSelection]="aDefectSelection"
        [filteredADefectLocation]="filteredADefectLocation" [filterTemplateDialogRef]="filterTemplateDialogRef"
        [showPrevious]="aDefectViewModel.showPrevious" [startDate]="aDefectViewModel.aDefectStartDatePie"
        [endDate]="aDefectViewModel.aDefectEndDatePie" (isDateRangeDisabled)="isDateRangeDisabled($event)"
        [aDefectFilteredLocations]="aDefectFilteredLocations"
        [switchStatus]="filterService.aDefectFilter.isDateRangeDisabled">
    </app-filter-dashboard>
</ng-template>

<!-- expand pie-chart container ----- start -->
<ng-container *ngIf="aDefectViewModel.isDesktop && aDefectViewModel.isPieChartContainerExpanded">
    <div ngClass="all-defects-modal">
        <div ngClass="all-defects-modal-content">
            <ng-contrainer *ngTemplateOutlet="defectInfoIconTemplate"></ng-contrainer>
        </div>
    </div>
</ng-container>
<!-- expand pie-chart container ----- end -->