export class HelpSupportViewModel {
    // to check if its desktop
    isDesktop: boolean;
    // ids for documents and return back to top
    inspectionHub: string = "inspection-hub";
    insurance: string = "insurance";
    inspectionServices: string = "inspection-services";
    healthSafetyQuality: string = "health-safety-quality";
    iconsTop: string = "icons-top";
    // contact us email address
    contactUsEmailLink: string;

    // IH resources
    inspectionHubResourcesCol1: Resources[] = []
    inspectionHubResourcesCol2: Resources[] = []

    // insurance resources
    insuranceResourcesCol1: Resources[] = []
    insuranceResourcesCol2: Resources[] = []

    // inspection resources
    inspectionResourcesCol2: Resources[] = []
    inspectionResourcesCol1: Resources[] = []

    // health, safety resources
    healthSafetyResourcesCol1: Resources[] = []
    healthSafetyResourcesCol2: Resources[] = []


}

export interface Resources {
    category: string
    title: string
    type: string
    url: string
}