import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementReportRoutingModule } from './management-report-routing.module';
import { PlantNotAvailableComponent } from './plant-not-available/plant-not-available.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ManagementReportComponent } from './management-report.component';
// Management report module
@NgModule({
  declarations: [
    PlantNotAvailableComponent,
    ManagementReportComponent
  ],
  imports: [
    CommonModule,
    ManagementReportRoutingModule,
    SharedModule,
    FormsModule
  ]
})

// exported Management report module
export class ManagementReportModule { }
