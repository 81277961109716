import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { AdminItemGroupDetails } from "src/app/schemas/itemsGroup";
import { CommonService } from "src/app/services/common.service";
import { TokenDecodeService } from "src/app/services/graphql/decode-token.service";
import { LayoutService } from "src/app/services/layout-service";
import { LocalStorageKeys } from "src/app/services/local-storage/local-storage-keys";
import { LocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { ItemGroupViewModel } from "./item-group.viewModel";
import { shareReplay, take } from "rxjs/operators";
import { UserService } from "src/app/services/graphql/user.Service";
import { ClientUserNameDetails, UserRole } from "src/app/schemas/users";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LocationViewModel } from "../locations/locations.viewModel";
import { LocationService } from "src/app/services/graphql/location.service";
import { DOCUMENT } from "@angular/common";
import { SortEvent } from "@aposin/ng-aquila/table";
import { RolesFromApi } from 'src/app/shared/enums/role';
import { PerPageModel } from "src/app/shared/models/perPage.Model";
import { BaseComponent } from "src/app/services/auth-service/base.component";
import { FormValidityService } from "src/app/services/FormValidityService";
import { OrganisationService } from "src/app/services/graphql/organisation.service";
import { Store } from "@ngrx/store";
import { AppStates } from "src/app/statemanagement/app.state";
import { ErrorLogService } from "src/app/services/errors/errorService";

@UntilDestroy()
@Component({
    selector: 'app-itemGroups',
    templateUrl: './item-group.component.html',
    styleUrls: ['./item-group.component.scss']
})

// item Group component
export class ItemGroupComponent implements OnInit, BaseComponent {

    // item group model instance for model binding
    itemGroupViewModel: ItemGroupViewModel = new ItemGroupViewModel();
    //Model template to location contact
    @ViewChild('removeItemGroupTemplate') itemGroupRemovalTemplate!: TemplateRef<any>;
    // model template to add an item group
    @ViewChild('addItemGroupTemplate') itemGroupAddTemplate!: TemplateRef<any>;
    // reference to the reference
    templateDialogRef?: NxModalRef<any>;
    // for setting the row as highlighted on table when selected
    activeRow?: AdminItemGroupDetails;
    //select itemgroup id to remove it
    itemGroupId: string = ""
    //to store user permission list to remove item group
    userItemGroupPermissions: any[] = []
    // object for selection model
    selection = new SelectionModel<AdminItemGroupDetails>(true, []);
    //Model template to assign all permission
    @ViewChild('assignAllPermission') assignAllPermissionTemplate!: TemplateRef<any>;
    //to keep user assigned location
    userAssignedLocations: any[] = []
    //Model template for assigned location for user
    @ViewChild('newLocationsTemplate') newLocationsTemplate!: TemplateRef<any>;
    // item search model instance for model binding
    locationViewModel: LocationViewModel = new LocationViewModel();
    //Model to open dialog box of assigned location
    newLocationDialogRef!: NxModalRef<any, any>;
    //item group list
    localStorageItemGroup: any[] = []
    // modal template for cancel changes 
    @ViewChild('cancelChangesModal') cancelChangesModal!: TemplateRef<any>;
    // modal template for submit changes 
    @ViewChild('submitChangesModal') submitChangesModal!: TemplateRef<any>;
    // object for location selection model
    locationSelection = new SelectionModel(true, []);
    // modal template for removing location 
    @ViewChild('removeLocationsModal') removeLocationsModal!: TemplateRef<any>;
    // add/assign locations that can be assigned in locations modal
    // all unassigned locations
    @ViewChild('addLocationsInLocationsModal') addLocationsInLocationsModal!: TemplateRef<any>;
    //Model to open add locations from locations modal
    addLocationsInLocationsModalDialogRef!: NxModalRef<any, any>;
    //user role
    userRole: string
    //employee token
    employeeToken: string = "";
    constructor(
        private logService: ErrorLogService, private formValidityService: FormValidityService, private organisationServiceObject: OrganisationService,
        private userService: UserService,
        private store: Store<AppStates>,
        private decodeTokenService: TokenDecodeService, private localStorageService: LocalStorageService, private common: CommonService, private activatedRoute: ActivatedRoute,
        public layoutService: LayoutService,
        private locationService: LocationService, private dialogService: NxDialogService, @Inject(DOCUMENT) private document: Document) {
    }

    async ngOnInit() {

        // subscribe to url for fetching the query params
        this.activatedRoute.queryParams.subscribe(params => {
            // fetch the token from query string
            const token = this.common.IsNullORUndefined(params, "token", '');
            // get the object of token stored in LS 
            const tokenLSObject = this.localStorageService.getObject(LocalStorageKeys.EmployeeTokenWithId);
            // match the token present currently in url with the one present in LS
            // if satisfied, no need to decode to get employee ID
            if (tokenLSObject && tokenLSObject.urlToken == token) {
                // map employee ID with LS employee ID
                this.itemGroupViewModel.employeeId = tokenLSObject.employeeID.val;
                //call api to get new token
                this.decodeUserIdToToken();
                this.updateOrganisationByRole();
            }
            // both token are not similar, need to fetch the employee Id of new token present in url
            else {
                // decode the token to get employee ID
                this.decodeTokenService.fetchEmployeeId(token).subscribe(result => {
                    if (result?.data?.decodeToken) {
                        // prepare the object to be stored in LS
                        const tokenEmployeeIdObj = { urlToken: token, employeeID: result.data.decodeToken }
                        this.localStorageService.removeData(LocalStorageKeys.EmployeeTokenWithId)
                        this.localStorageService.saveObject(LocalStorageKeys.EmployeeTokenWithId, tokenEmployeeIdObj);
                        this.itemGroupViewModel.employeeId = result.data.decodeToken.val;
                        //call api to get new token
                        this.decodeUserIdToToken(); this.updateOrganisationByRole();

                    }
                })
            }


        });

        this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => {
            this.itemGroupViewModel.isDesktop = result
            !this.itemGroupViewModel.isDesktop && this.templateDialogRef?.close();
        });


    }

    // send call to update organisation after checking user role 
    updateOrganisationByRole = () => {
        // fetch all the details of this employee
        this.store.select("user").subscribe((user: any) => {
            // preparing the initials
            user?.roles.some(x => x.role === RolesFromApi.internalAdmin) ? this.updateOrganisation() : void this.employeeById();
        })
    }

    // update the organisation of internal admin
    // in case newly creating user have different organisation
    updateOrganisation = () => {
        // set the employee Id in Organisation Model
        this.itemGroupViewModel.organisationModelObject.enterpriseId = this.itemGroupViewModel.employeeId;
        this.organisationServiceObject.updateOrganisationEmployee(this.itemGroupViewModel.organisationModelObject).subscribe(result => {
            if (result?.data?.updateOrganisationToEmployee?.id) {
                // fetch latest employee details
                void this.employeeById();
            }
        })
    }

    // fetch the name of the organisation to show in header
    getOrganisationName = async (organisationId): Promise<void> => {
        const currentUserObject = await this.organisationServiceObject.getCurrentUserOrganisationName().pipe(take(1)).toPromise();
        if (currentUserObject?.data?.company) {
            // remove previous local storage object
            this.localStorageService.removeData(LocalStorageKeys.SelectedOrganisation);
            const organisationObj: any = [{ "id": organisationId, "name": currentUserObject?.data?.company?.name }]
            // add new local storage object 
            this.localStorageService.saveObject(LocalStorageKeys.SelectedOrganisation, organisationObj)
            this.layoutService.assignOrganisation(organisationObj)

        }
    }

    //method to check if there is any change made on this page
    isFormValid = () => {
        // if there is any change on page, show popup else allow to redirect
        return this.itemGroupViewModel?.manageUserPermissionsLocally[0]?.addPermissions.length > 0;

    }
    // method to fetch the details of employee
    employeeById = async () => {
        // fetch the details of item groups stored in LS
        this.localStorageItemGroup = this.localStorageService.getObject(LocalStorageKeys.ItemGroups)
        const result = await this.userService.getEmployeeById(this.itemGroupViewModel.employeeId).pipe(take(1)).toPromise()
        // map fields with model 
        if (result?.data?.employee) {
            // const itemGroupList = result.data.employee.metadata?.userPermissionItemGroups.map(itemGroup => itemGroup.id)
            this.userAssignedLocations = result.data.employee.metadata?.userPermissionItemGroups
            //get user role 
            this.userRole = result.data.employee.metadata?.role;
            // assign all locationids with itemgroups and names
            const allLocationsWithItemGroupId = this.localStorageItemGroup
            // store user permissions along with all item groups in local storage
            this.localStorageService.saveObject(LocalStorageKeys.UserPermissionsManage, [{ 'addPermissions': this.userAssignedLocations }, { 'allLocationsWithItemGroups': allLocationsWithItemGroupId }])
            // get user permissions object stored locally
            this.itemGroupViewModel.manageUserPermissionsLocally = this.localStorageService.getObject(LocalStorageKeys.UserPermissionsManage)
            // render list of user permissions
            this.refreshList(true)

            this.itemGroupViewModel.removeTooltip = this.itemGroupViewModel.itemGroupList && this.itemGroupViewModel.itemGroupList.length > 1 ? 'Remove Item Group' : ""
            // employee info
            this.itemGroupViewModel.employeeHeaderInfo = `${result.data.employee.firstname} ${result.data.employee.surname}'s permissions for Inspection Hub`
            // update the breadcrumb with employee name details
            this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, this.itemGroupViewModel.employeeHeaderInfo);
            // set banner text with employee name details
            this.layoutService.setBanner(this.itemGroupViewModel.employeeHeaderInfo);
            // update the breadercrumb with name details in header component
            const userNameDetails: ClientUserNameDetails = { firstName: result.data.employee.firstname, surName: result.data.employee.surname }
            this.layoutService.setClientUserName(userNameDetails)
            // fetch all the details of this employee
            this.store.select("user").subscribe((user: any) => {
                // preparing the initials
                if (user?.roles.some(x => x.role === RolesFromApi.internalAdmin))
                    this.getOrganisationName(result?.data?.employee?.branch?.id)
            })


        }
    }

    // open the modal to remove the item group
    openRemoveItemGroupModel = (itemGroupId: string) => {
        this.itemGroupId = itemGroupId
        this.templateDialogRef = this.dialogService.open(this.itemGroupRemovalTemplate, { ariaLabel: 'Remove Item group?', showCloseIcon: true });
    }
    // close the modal

    closeItemGroupModal = () => this.templateDialogRef?.close();

    // open the modal to add the item group
    addItemGroup = () => {
        if (this.itemGroupViewModel.isDesktop) {
            this.templateDialogRef = this.dialogService.open(this.itemGroupAddTemplate, { ariaLabel: 'Add Item group(s)', showCloseIcon: true, height: '650px', width: '700px' });
        }

        this.itemGroupViewModel.showAddItemGroupModalMobile = true;

    }


    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.itemGroupViewModel.adminItemGroupList.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAll() {

        this.isAllSelected()
            ? this.selection.clear()
            : this.itemGroupViewModel.adminItemGroupList.forEach(row => this.selection.select(row));
    }
    /** Marks a row as active without changing this.selection */
    activateRow(row: AdminItemGroupDetails) {
        this.activeRow = row;
    }

    // close the add item group modal
    closeAddItemGroupModal = () => { this.clear(); this.selection.clear(); this.templateDialogRef?.close(); }
    //get count of assigned location to total locations
    countCommonIds(assdignedLocations: any[], totalLocations: any[]): string {
        if (assdignedLocations === undefined || assdignedLocations.length === 0)
            return `${0}/${totalLocations.length}`;
        else {
            const commonLocationIds = totalLocations.filter(ids => assdignedLocations.includes(ids))
            return `${commonLocationIds.length}/${totalLocations.length}`;
        }
    }
    //remove user item group
    removeItemGroup(): void {
        this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions = this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions.filter(item => item.id !== this.itemGroupId)
        this.refreshList()
        this.closeItemGroupModal();

    }

    // on remove/ add render main user permissions list and adjust add permissions modal list accordingly
    refreshList(isPageLoading: boolean = false) {
        const itemGroupList = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions.map(itemGroup => itemGroup.id)
        this.itemGroupViewModel.itemGroupList.length = 0;
        this.itemGroupViewModel.adminItemGroupList.length = 0;
        this.itemGroupViewModel.searchItemGroupList.length = 0;
        // map the item group details

        itemGroupList?.forEach(itemGroupId => {
            const itemGroupObject = {} as AdminItemGroupDetails;
            itemGroupObject.id = itemGroupId
            const itemGroup = this.itemGroupViewModel.manageUserPermissionsLocally[1]?.allLocationsWithItemGroups;
            const filteredItemGroup = itemGroup ? itemGroup.filter(itemGroup => itemGroup.id == itemGroupId) : [];
            const locationIds = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions;
            const filteredLocationIds = locationIds ? locationIds.filter(itemGroup => itemGroup.id == itemGroupId) : [];
            itemGroupObject.name = filteredItemGroup[0]?.name
            itemGroupObject.locationIds = filteredItemGroup[0]?.locationIds
            //calculate assigned/total location count
            itemGroupObject.locationCount = this.countCommonIds(filteredLocationIds[0].locationIds, filteredItemGroup[0].locationIds)
            this.itemGroupViewModel.itemGroupList.push(itemGroupObject)
        })
        this.localStorageItemGroup?.forEach((item) => {
            if (!this.itemGroupViewModel.itemGroupList.some(item1 => item1.id == item.id))
                this.itemGroupViewModel.adminItemGroupList.push(item)
        });
        // list of item groups returned from API
        this.itemGroupViewModel.searchItemGroupList = [...this.itemGroupViewModel.adminItemGroupList]
        this.itemGroupViewModel.resultCountAddItemGroup = this.itemGroupViewModel.adminItemGroupList?.length;
        // count of item groups
        this.itemGroupViewModel.itemGroupCount = this.itemGroupViewModel.searchItemGroupList.length;
        // load page w.r.t pagination 
        this.loadPage()

        // get initial stored user permissions from LS
        const initialManageUserObj = this.localStorageService.getObject(LocalStorageKeys.UserPermissionsManage)
        // compare current changes with initial changes
        // checking changes in locations
        const locationChanges = this.arraysEqual(initialManageUserObj[0]?.addPermissions, this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions, true)
        // checking changes in item groups
        const itemGroupChanges = this.arraysEqual(initialManageUserObj[0]?.addPermissions, this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions)
        // final result of comparison
        this.itemGroupViewModel.areChangesMadeInUserPermissions = locationChanges && itemGroupChanges
        // setting form valid to true here, for case  redirect to other site, base component can not deal with it.
        if (!isPageLoading)
            this.formValidityService.setFormValidity(true);

    }



    //add user item group
    addItemGroups(): void {
        this.selection.selected.forEach(selectedItemGroup => {
            const addObj: { id: string, locationIds: number[] } = { id: selectedItemGroup.id, locationIds: selectedItemGroup.locationIds };
            this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions.push(addObj);
        })
        this.clear();
        // refresh main and add permissions grid list
        this.refreshList()
        // close modals on mobile and desktop
        this.closeAddItemGroupModal()
        this.closeItemGroupMobileModal()

    }

    //assign all location of each item group
    assignAllLocations() {
        //close the pop up in each case
        this.dialogService.closeAll();
        if (this.itemGroupViewModel.checked) {
            //empty the object 
            this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions = []
            //iterate through item group

            this.itemGroupViewModel.itemGroupList.forEach(itemGroup => {
                const addObj: { id: string, locationIds: number[] } = { id: itemGroup.id, locationIds: itemGroup.locationIds };
                this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions.push(addObj);
            })
            // refresh main and add permissions grid list
            this.refreshList()
        } else {
            //restore previous state on uncheck event
            this.itemGroupViewModel.itemGroupList.forEach(itemGroup => {
                const [locationIds] = this.userAssignedLocations.filter(item => item.id == itemGroup.id)
                itemGroup.locationCount = this.countCommonIds(locationIds?.locationIds, itemGroup.locationIds)
            })
        }
    }

    // open the modal to Assign All Permission confirmation
    openAssignAllPermissionModel = () => {

        //model service to open it
        this.templateDialogRef = this.dialogService.open(this.assignAllPermissionTemplate, { ariaLabel: 'Remove Item group?', showCloseIcon: true, height: '270px', width: '500px' });
    }

    // close the assign all permission model
    closeAssignAllPermissionModal = () => {
        //close this model
        this.dialogService.closeAll();
        //reset value of check box
        this.itemGroupViewModel.checked = false;
    };

    // close item group modal for mobile
    closeItemGroupMobileModal() {
        this.itemGroupViewModel.showAddItemGroupModalMobile = false;
    }

    // show full content in add item group modal
    showFullContent() {
        this.itemGroupViewModel.showAddItemGroupInfoFullContent = !this.itemGroupViewModel.showAddItemGroupInfoFullContent
    }
    //to assign new locations to user, this model is help to show unassigned locations
    newLocationsModel(itemGroupId: string, itemGroupName: string): void {
        //assign group name for header
        this.itemGroupViewModel.groupNameForModel = itemGroupName
        // store in global variable the item group selected id
        this.itemGroupViewModel.selectedItemGroup = itemGroupId
        // for rendering locations 
        this.renderNewLocationsModelLocations()
        //open new model for new locations
        if (this.itemGroupViewModel.isDesktop)
            this.newLocationDialogRef = this.dialogService.open(this.newLocationsTemplate, {
                showCloseIcon: true, width: '950px'
            });
        //for mobile view
        this.itemGroupViewModel.showLocationsInMobile = true;
    }

    // fetch all locations depending on the local storage saved item groups locationids
    renderNewLocationsModelLocations() {
        // empty locations array on modal open
        this.locationViewModel.locations = [];
        // clear param list
        this.itemGroupViewModel.queryParamList.clear(0);
        // get locationIds from the selected item group ids
        this.itemGroupViewModel.assignedItemGroupIds = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions.filter(item => item.id == this.itemGroupViewModel.selectedItemGroup).map(addItem => {
            return addItem.locationIds
        })
        // update Search param
        // here .flat() is used to concatenate sub-arrays of an array into single array
        if (this.itemGroupViewModel.assignedItemGroupIds[0]?.length > 0) {
            this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, "locationId", this.itemGroupViewModel.assignedItemGroupIds.flat().join('|'));
            //  call intersection observer for fetching 1st ten locations
            void this.fetchAllLocations();
        }

    }
    //open location model on next step 
    openLocationModelNextStep() {
        let itemgroupId
        this.selection.selected.forEach(selectedItemGroup => {
            const selectedItem: { id: string, name: string } = { id: selectedItemGroup.id, name: selectedItemGroup.name };
            this.itemGroupViewModel.groupNameForModel = selectedItem.name
            itemgroupId = selectedItem.id

        })
        // empty locations array on modal open
        this.locationViewModel.locations = [];
        // clear param list
        this.itemGroupViewModel.queryParamList.clear(0);
        // get locationIds from the selected item group ids
        this.itemGroupViewModel.assignedItemGroupIds = this.localStorageItemGroup.filter(item => item.id == itemgroupId).map(addItem => {
            return addItem.locationIds
        })
        // update Search param
        // here .flat() is used to concatenate sub-arrays of an array into single array
        this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, "locationId", this.itemGroupViewModel.assignedItemGroupIds.flat().join('|'));
        //  call intersection observer for fetching 1st ten locations
        void this.fetchAllLocations();

    }

    // get locations from graphql service
    async fetchAllLocations(isAddLocation: boolean = false, pageSize: number = 10) {
        // in case of unassigned locations modal 
        if (isAddLocation) {
            // prepare the search param list to contain these location ids
            this.prepareSearchParamList(true);
        }
        else {
            // prepare the search param list to contain these location ids
            this.prepareSearchParamList();
        }

        // prepare variables to pass to the api
        const result = await this.locationService.getLocations(this.itemGroupViewModel.queryParamList.searchList, this.itemGroupViewModel.addLocationModelSortDirection.toLocaleUpperCase(), this.itemGroupViewModel.addLocationModelSortBy, this.locationViewModel.page - 1, pageSize, true);
        // after model is once opened then make this variable false so that page num can be incremented by 1
        if (result?.locationDetails) {
            //  mapping of total results count
            this.locationViewModel.resultCount = result.totalCount;
            // check if it is not a scroll call, then clear the existing rows to shows only rows which meets the search criteria
            if (this.locationViewModel.resultCount > 0) {
                result.locationDetails.forEach((location: any) => {

                    // to get complete address here instead of using conditionals in template
                    location.completeAddress = `${location?.postalAddress?.address1} ${location?.postalAddress?.address2} ${location?.postalAddress?.address3} ${location?.postalAddress?.address4} ${location?.postalAddress?.address5} ${location?.postalAddress?.address6}`
                    // in case of unassigned location modal push locations to unassignedLocations list
                    if (!isAddLocation) this.locationViewModel.locations.push(location)
                    else this.itemGroupViewModel.unassignedLocations.push(location)
                })
            }
        }

    }
    // set multi fields search values
    prepareSearchParamList(isAddLocation: boolean = false): void {
        //to handle add new locations/unassigned locations, have added this param 
        if (isAddLocation)
            this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, "locationId", this.itemGroupViewModel.unAssignedItemGroupIds.join('|'));

        else {
            //in case a user already has assigned location
            if (this.itemGroupViewModel.assignedItemGroupIds.length > 0)
                this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, "locationId", this.itemGroupViewModel.assignedItemGroupIds.flat().join('|'));
        }

    }

    //clear the search field
    clearSearch(isStep2Call: boolean) {
        //clear the selection
        if (isStep2Call) {
            this.locationSelection.clear()
            //clear the saerch using userview model
            this.itemGroupViewModel.modelClear();
            this.openLocationModelNextStep()
            return
        }
        else
            this.selection.clear();
        // reset the page num to 0
        this.locationViewModel.page = 1;
        //clear the saerch using userview model
        this.itemGroupViewModel.modelClear();
        // only call this if search is actually done
        if (this.itemGroupViewModel.isSearchCalled) {
            //calling again fetch location for model popup
            this.renderNewLocationsModelLocations();
            // make search variable false
            this.itemGroupViewModel.isSearchCalled = false
        }

    }

    // perform search, display result list and update routes 
    searchLocations() {
        // make isSearchCalled true so that Clear can only be used when searched
        this.itemGroupViewModel.isSearchCalled = true
        // reset the page num to 0
        this.locationViewModel.page = 1;
        //Clearing existing list
        this.locationViewModel.locations = []
        // remove any previous search inputs
        this.itemGroupViewModel.queryParamList.clear(0);
        this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, this.locationViewModel.locationNameAndAddress, this.itemGroupViewModel.locationNameAndAddress);
        void this.fetchAllLocations();

    }

    // search unassigned locations for adding location modal
    searchUnassignedLocations() {
        // reset the page num to 1
        this.locationViewModel.page = 1;
        // clear queryparam list
        this.itemGroupViewModel.queryParamList.clear(0);
        // ampty list
        this.itemGroupViewModel.unassignedLocations = [];
        // search the entered input
        this.common.updateSearchParamList(this.itemGroupViewModel.queryParamList, this.locationViewModel.locationNameAndAddress, this.itemGroupViewModel.unassignedLocationNameAndAddress);
        // fetch locations in case of unassigned modal
        void this.fetchAllLocations(true);
        // set true
        this.itemGroupViewModel.isUnassignedLocationsSearchCalled = true
    }

    //clear the search field
    clearUnassignedSearch() {
        // reset the page num
        this.locationViewModel.page = 1;
        //clear the saerch using userview model
        this.itemGroupViewModel.modelClearUnassigned();
        // only call this if search is actually done
        if (this.itemGroupViewModel.isUnassignedLocationsSearchCalled) {
            //calling again fetch un assigned locations for modal popup
            this.fetchUnassignedLocations();
            // make search variable false
            this.itemGroupViewModel.isUnassignedLocationsSearchCalled = false
        }

    }

    //update first element of array to get unassigned locations
    updateArrayFirstElement(locationArray: any[]): any[] {
        //get first element and append "-" to it, rest of task will do by join function
        if (locationArray.length > 0) {
            let firstElement = locationArray[0];
            if (!String(firstElement).startsWith("-")) {
                firstElement = "-" + firstElement;
            }
            locationArray[0] = Number(firstElement);
        }
        return locationArray;
    }

    // return to myTeam 
    returnToMyTeams(cancelWithoutChanges: boolean = false, submitWithChanges: boolean = false) {
        // if clicked on Save submit button then 
        // call user service and save perferences
        // decode employeeId to get token
        // navigate to UAM
        if (submitWithChanges) {
            this.saveChangesAndSubmit()
        }

        // if clicked on cancel
        // decode employeeId to get token
        // navigate to UAM
        else if (cancelWithoutChanges) {
            this.navigateToMyTeam()
            this.closeCancelSubmitModal()
        }

        // if clicked on return
        // decode employeeId to get token
        // navigate to UAM
        else {
            this.navigateToMyTeam()
        }
    }
    // navigate to my team depending on the url
    navigateToMyTeam() {

        // get current url path
        const currentUrl = this.activatedRoute.snapshot.routeConfig.path;
        //using a common method from common service for redirect to my team
        this.common.redirectToMyTeam(currentUrl, this.employeeToken)
    }
    // get new token using employee id
    decodeUserIdToToken = () => {
        this.decodeTokenService.fetchTokenFromUserId(this.itemGroupViewModel.employeeId)
            .pipe(take(1))
            .subscribe(res => {
                if (res?.data?.generateToken?.val) {
                    this.employeeToken = res?.data?.generateToken?.val;
                    this.localStorageService.saveString(LocalStorageKeys.EmployeeToken, this.employeeToken);
                }
            }, error => {
                this.logService.logError(error);
            });
    }


    // open cancel modal
    openCancelModal() {
        this.templateDialogRef = this.dialogService.open(this.cancelChangesModal, { ariaLabel: 'Cacel changes?', showCloseIcon: true });
    }

    // close submit/cancel modals
    closeCancelSubmitModal = () => this.templateDialogRef?.close()


    // open submit modal
    openSubmitModal() {
        this.templateDialogRef = this.dialogService.open(this.submitChangesModal, { ariaLabel: 'Submit changes?', showCloseIcon: true });
    }


    // save user permissions
    saveChangesAndSubmit() {
        // get initial local storage value
        const initialManageUserObj = this.localStorageService.getObject(LocalStorageKeys.UserPermissionsManage)

        // get the removed item groups whose location ids have been removed
        const removedLocationIdsWithItemGroup = initialManageUserObj[0]?.addPermissions.reduce((acc, obj1) => {
            const obj2 = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions.find(o => o.id === obj1.id);
            if (!obj2) {
                acc.push(obj1);
            } else if (obj1.locationIds.length > 0) {
                const diff = obj1.locationIds.filter(id => !obj2.locationIds.includes(id));
                if (diff.length > 0) {
                    acc.push({ id: obj1.id, locationIds: diff });
                }
            }
            return acc;
        }, []);

        // create array for removing item groups
        let removeItemGroups: any[] = []
        removeItemGroups = this.itemGroupViewModel.adminItemGroupList.map(item => {
            return {
                id: item.id,
                locationIds: []
            }
        })

        // concate both the removed item groups whose locationIds have been removed and those item groups that are removed from user permissions
        const concatenatedRemovedItemGroups = removeItemGroups.concat(removedLocationIdsWithItemGroup)

        // user obj to be passed to api endpoint


        const user: UserRole = {
            id: this.itemGroupViewModel.employeeId,
            role: this.userRole,
            ...(this.userRole === RolesFromApi.standardClientUser
                ? {
                    userPermissions: {
                        addPermissions: this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions,
                        removePermissions: concatenatedRemovedItemGroups
                    }
                }
                : {
                    adminPermissions: {
                        addPermissions: this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions.map(permission => permission.id)
                    },
                    userPermissions: {
                        addPermissions: this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions,
                        removePermissions: concatenatedRemovedItemGroups
                    }
                }
            )
        };



        // close modal
        this.closeCancelSubmitModal()
        // call user service for updating user permissions
        this.userService.updateUserRolePermissions(user).pipe(shareReplay(), untilDestroyed(this)).subscribe(res => {
            if (res?.data?.updateEmployee?.id) {
                // navigate to myTeam
                this.navigateToMyTeam()
            }
        })
    }

    // to compare initial user permission obj with current obj
    arraysEqual(arr1, arr2, compareLocationIds: boolean = false) {
        let sortedIds1;
        let sortedIds2;
        if (arr1.length !== arr2.length) {
            return false;
        }
        // if locations are to be compared then use locationids
        if (compareLocationIds) {
            sortedIds1 = arr1.map(obj => obj.locationIds).sort();
            sortedIds2 = arr2.map(obj => obj.locationIds).sort();
        }
        // if item groups are to be compared then use id
        else {
            sortedIds1 = arr1.map(obj => obj.id).sort();
            sortedIds2 = arr2.map(obj => obj.id).sort();
        }


        if (JSON.stringify(sortedIds1) !== JSON.stringify(sortedIds2)) {
            return false;
        }

        return true;
    }

    //hide show item and locations div on itemgroup model
    showDiv = {
        itemGroupDiv: true,
        locationDiv: false,
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllLocationSelected() {
        const numRows = this.locationViewModel.locations.length;
        const numSelected = this.locationSelection.selected.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllLocations() {

        !this.isAllLocationSelected() ? this.locationViewModel.locations.forEach(row => this.locationSelection.select(row)) : this.locationSelection.clear();


    }

    // Sort grid of add location pip by specific attribute
    // handles both local sorting and 
    // get list storted from api
    sortGrid(sort: SortEvent) {

        // if all results are fetch, need to perform the local sorting
        if (this.locationViewModel.resultCount === this.locationViewModel.locations.length) {
            //sort directions
            this.itemGroupViewModel.addLocationModelSortDirection = sort.direction
            // map the sort by and direction in view model fields
            this.itemGroupViewModel.addLocationModelSortBy = sort.active

            // we are applying local sorting due to infinte scrolling
            this.locationViewModel.locations.sort((first, second) => {
                switch (sort.active) {
                    case 'name': return this.common.compare(first.name, second.name, sort.direction);
                    case 'postalAddressPostCode': return this.common.compare(first.postalAddress?.postcode, second.postalAddress?.postcode, sort.direction);
                    case 'postalAddress': return this.common.compare(first.postalAddress?.address1, second.postalAddress?.address1, sort.direction);
                    default: return 0;
                }
            });
        }
        else {
            // set bit to true to indicate that it was a call to perform sorting
            this.itemGroupViewModel.sortAddLocationGrid = true;
            // make the list empty to load the sorted list
            this.locationViewModel.locations = [];
            // save number of records already loaded to load in future
            // const numberOfRecordsLoaded = this.locationViewModel.locations.length;
            // set page number to 1
            this.locationViewModel.page = 1;
            // call api to get records
            void this.fetchAllLocations();
        }
    }

    // sort assigned locations locally if count is equal/less than 10
    localSorting(sort: SortEvent): void {
        this.locationViewModel.locations.sort((x, y) => {
            switch (sort.active) {
                case 'name': return this.common.compare(x.name, y.name, sort.direction);
                case 'postalAddressPostCode': return this.common.compare(x.postalAddress?.postcode, y.postalAddress?.postcode, sort.direction);
                case 'postalAddress': return this.common.compare(x.postalAddress?.address1, y.postalAddress?.address1, sort.direction);
                default: return 0;
            }
        });
    }
    // Sort grid by specific attribute
    // handles both local sorting and 
    // get list storted from api
    sortTable(sort: SortEvent) {
        // if returned results are less than 11, we can apply local sorting
        if (this.itemGroupViewModel.resultCount > 10) {
            // otherwise will call api with sort params 
            this.itemGroupViewModel.sortBy = sort.active;
            this.itemGroupViewModel.sortDirection = sort.direction;
            void this.fetchAllLocations();

        }
        else {
            this.localSorting(sort);
        }
    }
    // toggle selected checkbox / single location
    toggleSelected(location: LocationViewModel): void {
        this.locationSelection.toggle(location);

    }
    //Add locations against item group on step 2
    addLocations(): void {
        this.selection.selected.forEach(selectedItemGroup => {
            const addObj: { id: string, locationIds: string[] } = { id: selectedItemGroup.id, locationIds: this.locationSelection.selected.map(ids => ids.id) };
            this.itemGroupViewModel.manageUserPermissionsLocally[0].addPermissions.push(addObj);
        })

        // refresh main and add permissions grid list
        this.refreshList();
        // close modals on mobile and desktop
        this.closeAddItemGroupModal()
        this.closeItemGroupMobileModal()
    }

    // close unassigned location modal
    closeLocationModal = () => this.newLocationDialogRef.close();

    // fetch unassigned locations for selected item group
    fetchUnassignedLocations() {
        // clear selection list
        this.itemGroupViewModel.locationSelection.clear()
        // empty list
        this.itemGroupViewModel.unassignedLocations = []
        // clear search params
        this.itemGroupViewModel.queryParamList.clear(0);
        // empty id lists
        this.itemGroupViewModel.unAssignedItemGroupIds = []
        // fetch all locations ids for selected item group
        const totalLocations = this.itemGroupViewModel.manageUserPermissionsLocally[1]?.allLocationsWithItemGroups
            .filter(item => item.id == this.itemGroupViewModel.selectedItemGroup)
            .map(addItem => addItem.locationIds)

        // fetch unassigned locations ids from locally stored variable and all locations
        this.itemGroupViewModel.unAssignedItemGroupIds = totalLocations[0].filter(left => !this.itemGroupViewModel.assignedItemGroupIds[0].includes(left))
        // fetch all unassigned locations
        void this.fetchAllLocations(true)
    }

    // open add locations/unassigned locations modal from locations modal
    openAddLocationsModalFromLocationsPopup() {
        // fetch unassigned locations
        this.fetchUnassignedLocations()
        // open modal

        this.addLocationsInLocationsModalDialogRef = this.dialogService.open(this.addLocationsInLocationsModal, { ariaLabel: 'add locations', showCloseIcon: true })

    }

    // close add locations modal and navigate back to main locations modal
    navigateToLocationsStepOne() {
        this.addLocationsInLocationsModalDialogRef.close()
    }

    // close add location and main locations modals
    closeAllLocationsModals() {
        this.addLocationsInLocationsModalDialogRef.close()
        this.newLocationDialogRef.close();
    }

    // save unassigned locations to be assigned to selected item group
    saveLocations() {
        // get the selected item group
        const itemGroup = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions.filter(item => item.id == this.itemGroupViewModel.selectedItemGroup)
        // push selected location ids to selected item group
        this.itemGroupViewModel.locationSelection.selected.forEach(selectedLocations => {
            itemGroup[0].locationIds.push(selectedLocations.id)
        })

        // refresh main and add permissions grid list
        this.refreshList()
        // close both assigned and unassigned modals
        if (this.itemGroupViewModel.isDesktop) this.closeAllLocationsModals()
        else this.closeAllLocationsModalsInMobile()
    }


    // all selected unassigned locations
    isAllUnassignedLocationSelected() {
        const numSelected = this.itemGroupViewModel.locationSelection.selected.length;
        const numRows = this.itemGroupViewModel.unassignedLocations.length;
        return numSelected === numRows;
    }
    // toggle unassigned locations
    toggleAllUnassignedLocations() {
        this.isAllUnassignedLocationSelected()
            ? this.itemGroupViewModel.locationSelection.clear()
            : this.itemGroupViewModel.unassignedLocations.forEach(row => this.itemGroupViewModel.locationSelection.select(row));
    }
    // activate selected row for unassigned locations modal
    LocationActivateRow(row: any) {
        this.itemGroupViewModel.locationActiveRow = row;
    }

    // Sort grid by specific attribute
    // handles both local sorting and 
    // get list storted from api
    sortUnassignedLocationTable(sort: SortEvent) {
        // if returned results are less than 11, we can apply local sorting
        if (this.itemGroupViewModel.unassignedLocations.length <= 10) {
            this.sortLocally(sort);
        }
        else {
            // clear any previous search list as we prepare this again in onInit
            this.itemGroupViewModel.queryParamList.clear(0);
            // otherwise will call api with sort params 
            this.itemGroupViewModel.addLocationModelSortBy = sort.active;
            this.itemGroupViewModel.addLocationModelSortDirection = sort.direction;
            //   fetch unassigned locations 
            void this.fetchAllLocations(true)
        }
    }

    // sort locations list on client side
    sortLocally(sort: SortEvent): void {
        this.itemGroupViewModel.unassignedLocations.sort((a, b) => {
            switch (sort.active) {
                case 'name': return this.common.compare(a.name, b.name, sort.direction);
                case 'postalAddressPostCode': return this.common.compare(a.postalAddress?.postcode, b.postalAddress?.postcode, sort.direction);
                case 'postalAddress': return this.common.compare(a.postalAddress?.address1, b.postalAddress?.address1, sort.direction);
                default: return 0;
            }
        });
    }

    // open remove location modal
    openRemoveLocationModal(locationId) {
        this.itemGroupViewModel.selectedLocationIdToBeRemoved = locationId;
        this.templateDialogRef = this.dialogService.open(this.removeLocationsModal, { ariaLabel: 'remove location', showCloseIcon: true });
    }

    // remove location from new location modal
    removeLocationFromLocationsModal() {
        // get the item group that was selected
        const itemGroup = this.itemGroupViewModel.manageUserPermissionsLocally[0]?.addPermissions.filter(item => item.id == this.itemGroupViewModel.selectedItemGroup)
        // exclude the location that was removed
        const locationsLeft = itemGroup[0].locationIds.filter(location => location !== this.itemGroupViewModel.selectedLocationIdToBeRemoved)
        // assign remaining locations to locally stored variables locationIds
        itemGroup[0].locationIds = locationsLeft
        // render locations depending on the changes made
        this.renderNewLocationsModelLocations()
        // re-render the itemgroup list in main grid, add item list and update location count accordingly
        this.refreshList()
        this.templateDialogRef?.close()
    }

    // close assigned locations modal
    closeAssignedLocationsModal() {
        this.itemGroupViewModel.showLocationsInMobile = false;
    }
    // open unassigned locations from locations i.e. step 2
    openAddLocationsModalFromLocationsPopupMobile() {
        // fetch unassigned locations
        this.fetchUnassignedLocations()
        // show unassigned locations
        this.itemGroupViewModel.isUnassignedLocationsModalVisibleMobile = true;
    }

    // hide unassigned locations step 2
    closeUnassignedLocationsModal() {
        this.itemGroupViewModel.isUnassignedLocationsModalVisibleMobile = false;
    }

    // close all locations modals in mobile
    closeAllLocationsModalsInMobile() {
        this.itemGroupViewModel.showLocationsInMobile = false;
        this.itemGroupViewModel.isUnassignedLocationsModalVisibleMobile = false;
    }
    //hide show  group and locations div on itemgroup model in mobile
    showDivOnMobile = {
        itemGroupDivMobile: true,
        locationDivMobile: false,
    }

    // searching from the Item group List
    // 1. Performed on main Item group list
    // 2. Update the count of searched results to show above grid (Add Item group Model)
    // 3. Load the data based on pagination
    search = () => {
        const searchStr = this.itemGroupViewModel.itemGroupName;
        this.itemGroupViewModel.adminItemGroupList = this.itemGroupViewModel.searchItemGroupList.filter(function (eachItem) {
            return eachItem.name.toLowerCase().includes(searchStr.toLowerCase());
        });
        // update the count w.r.t records present in main Item group list
        this.itemGroupViewModel.resultCountAddItemGroup = this.itemGroupViewModel.adminItemGroupList?.length
        this.itemGroupViewModel.itemGroupCount = this.itemGroupViewModel.adminItemGroupList.length;
        if (this.itemGroupViewModel.itemGroupCount > 10)
            this.loadPage();
    }

    // sorting the Item group List
    // 1. Performed on main Item group list
    // 2. Load the data based on pagination
    sortAddItemGroupGrid = (sort: SortEvent) => {
        this.itemGroupViewModel.searchItemGroupList.sort((first, second) => {
            if (sort.active == 'name') {
                return this.common.compare(first.name, second.name, sort.direction);
            }
        });
        this.loadPage();
    }

    // toggle number of records per page by clicking 
    public togglePerPage(selectedPerPage: PerPageModel): void {
        // clicked option's value is set as per page option
        this.itemGroupViewModel.perPage = selectedPerPage.value;
        // page to load is set to 1 i.e. load first page
        this.itemGroupViewModel.page = 1;
        this.loadPage()
    }

    // navigate to specific page and load its data
    // configured with pagination control
    goToPage(pageNumber: number) {
        // set the page count and load its data
        this.itemGroupViewModel.page = pageNumber;
        this.loadPage();
    }

    // navigate to previous page
    prevPage() {
        // decrement the page count and load its data
        this.itemGroupViewModel.page--;
        this.loadPage();
    }

    // navigate to next page
    nextPage() {
        // increment the page count and load its data
        this.itemGroupViewModel.page++;
        this.loadPage();
    }

    // load the data of respective page
    loadPage = (): void => {
        const page = this.itemGroupViewModel.page;
        const perPage = this.itemGroupViewModel.perPage;
        // If the page selected from pagination control is 1
        // 1. No need to skip the data
        // 2. Take the chunk of data using perPage from the list
        if (page === 1) {
            this.itemGroupViewModel.adminItemGroupList = this.itemGroupViewModel.searchItemGroupList.slice(0, perPage);
        }
        // If the page selected from pagination control is greater than 1
        // 1. Skip the data of 1st page by using filtering and index
        // 2. Take the chunk of data using perPage from the remaining list
        else if (page > 1) {
            this.itemGroupViewModel.adminItemGroupList = this.itemGroupViewModel.searchItemGroupList.filter((val, index) => {
                return index >= (page - 1) * perPage
            }).slice(0, perPage);
        }
    }

    // clear the search field
    clear(): void {
        // reset the item group search field
        this.itemGroupViewModel.itemGroupName = "";
        // reset the pagination number to 1
        this.itemGroupViewModel.page = 1;
        // update the count w.r.t records present in main Item group list
        this.itemGroupViewModel.resultCountAddItemGroup = this.itemGroupViewModel.searchItemGroupList?.length
        this.itemGroupViewModel.itemGroupCount = this.itemGroupViewModel.searchItemGroupList?.length;
        // load the data based on above criteria
        this.loadPage()
    }


}

