<div *ngIf="showPopup" class="modal-visually-hidden" aria-atomic="true" aria-live="polite"></div>
<div *ngIf="showPopup" class="modal-container">
    <div class="modal-overlay-backdrop modal-overlay-dark-backdrop modal-overlay-backdrop-showing"></div>
    <div class="modal-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
        <div class="modal-overlay-pane modal-overlay-setting">
            <div tabindex="0" class="modal-visually-hidden modal-focus-trap-anchor" aria-hidden="true">
            </div>
            <div tabindex="-1" aria-modal="true" class="modal-content">
                <div class="modal-content-internal">
                    <!-- Div to show selected Item groups cross button  -->
                    <div class="selectedDivAlignment">
                        <h2>Please select a Business Area</h2>
                        <nx-icon name="close"
                            (click)=" itemGroupAssignedList && itemGroupAssignedList.length !=0 ? closeModal(): null"
                            class="crossButtonMobileAlignment ndbx-icon nx-icon--close nx-icon--auto">
                        </nx-icon>
                    </div>

                    <p>{{dummyText}}</p>
                    <hr>
                    <!-- Display all item groups -->
                    <div class="nx-margin-x-s nx-margin-top-m">
                        <div nxRow class="nx-margin-bottom-xs">
                            <form>
                                <nx-radio-group [(ngModel)]="selectedItemGroupName" name="itemgroupList">
                                    <nx-radio [nxValue]="group.name" *ngFor="let group of itemGroupList"
                                        class="nx-margin-bottom-m">
                                        {{group.name}}
                                    </nx-radio>
                                </nx-radio-group>
                            </form>
                        </div>
                    </div>
                    <div>
                        <div style="float: left ; ">
                            <nx-error *ngIf="showValidationError" appearance="text">
                                Please select a Business Area
                            </nx-error>
                        </div>
                        <div class="center">

                            <button [disabled]="itemGroupAssignedList && itemGroupAssignedList.length===0"
                                class="nx-margin-right-s" type="button" id="clear" nxButton="secondary small"
                                type="button" (click)="closeModal()">
                                Cancel
                            </button>
                            <button class="nx-margin-right-s" nxButton="primary small" (click)="saveItemGroups()"
                                type="button">
                                Apply
                            </button>
                            &nbsp;

                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>