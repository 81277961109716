import gql from 'graphql-tag';

// graphql query to get list of organisations
export const organisationList = (organizationName: string) => gql`query{
        organisations(name: "${organizationName}", postCode: "") {
          id
          name
        }
      }
  

`;

// query to update an organization
export const updateOrganisation = gql`
mutation updateOrganisation($organisationId: String!){

  updateOrganisation(organisationId: $organisationId){
      id
   }
  }`


// query to update the organisation in case of internal admin
// when internal admin belongs to different organsiation and client/standard admin belongs to 
// different
export const updateOrganisationEmployee = gql`mutation updateOrganisationToEmployee($enterpriseId: String!){
  updateOrganisationToEmployee(id: $enterpriseId){
      id
   }
  }`;

// inteface containing details of organisation i-e id and name
export interface OrganisationListModel {
  id: string
  name: string
}

// modal containing the id of organization
export class OrganisationModel {
  organisationId: string
}

// model containing the id of user 
export class OrganisationEmployeeModel {
  enterpriseId: string
}