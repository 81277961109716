import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { HelpSupportViewModel } from './help-support.viewModel';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UsefulResourcesService } from 'src/app/services/json-read/useful-resources.service';
import { UseFulResources } from 'src/app/shared/models/useful-resources-static-data-model';
import { environment } from 'src/environments/environment';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

@Component({
  selector: 'app-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.scss'],
})
export class HelpSupportComponent implements OnInit, AfterViewInit {
  @ViewChild('noMailServiceProviderFoundModal')
  noMailServiceProviderFoundModal!: TemplateRef<any>;
  noMailServiceProviderFoundModalDialogRef?: NxModalRef<any>;
  // to get currentTab which by default is selected to useful resources
  helpSupportViewModel: HelpSupportViewModel = new HelpSupportViewModel();

  // devicedimensionservice's subscription object to unsubscribe on destory
  deviceDimensionServiceSubscription: Subscription;

  // object of use ful resources class
  // it contains further lists of all categories
  useFulResourcesList: UseFulResources = new UseFulResources();
  // viewportScroller is added to scroll to specific div on same page
  constructor(
    private layoutService: LayoutService,
    private scroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private usefulResourceService: UsefulResourcesService,
    private dialogService: NxDialogService,
  ) { }

  ngOnInit(): void {
    //set banner text
    this.layoutService.setBanner(BannerDetail.helpSupportText);
    // set subTitle of the banner
    this.layoutService.setSubBanner('');

    // subscribe to device type detection observable
    this.deviceDimensionServiceSubscription =
      this.layoutService.currentDevice.subscribe(
        (result) => (this.helpSupportViewModel.isDesktop = result)
      );
    // get useful resource from json list
    this.useFulResourcesList = this.usefulResourceService.getUsefulResources();

    // for dividing data into 2 columns
    this.divideInspectionHubResources();
    this.divideInsuranceResources();
    this.divideInspectionServicesResources();
    this.dividehealthSafetyResources();

    // get contact us email address
    this.helpSupportViewModel.contactUsEmailLink =
      environment.contactUsEmailAddress;
  }

  // divide inspection hub data into 2 columns
  divideInspectionHubResources() {
    this.useFulResourcesList.inspectionHubResources.forEach((item, index) => {
      // if index is modulus of 2 then assign data to col 1 otherwise col 2
      if (index % 2) {
        this.helpSupportViewModel.inspectionHubResourcesCol1.push(item);
      } else {
        this.helpSupportViewModel.inspectionHubResourcesCol2.push(item);
      }
    });
  }
  // divide Insurance data into 2 columns
  divideInsuranceResources() {
    this.useFulResourcesList.insuranceResources.forEach((item, index) => {
      // if index is modulus of 2 then assign data to col 1 otherwise col 2
      if (index % 2) {
        this.helpSupportViewModel.insuranceResourcesCol1.push(item);
      } else {
        this.helpSupportViewModel.insuranceResourcesCol2.push(item);
      }
    });
  }
  // divide InspectionServices data into 2 columns
  divideInspectionServicesResources() {
    this.useFulResourcesList.inspectionResources.forEach((item, index) => {
      // if index is modulus of 2 then assign data to col 1 otherwise col 2
      if (index % 2) {
        this.helpSupportViewModel.inspectionResourcesCol2.push(item);
      } else {
        this.helpSupportViewModel.inspectionResourcesCol1.push(item);
      }
    });
  }
  // divide healthSafety data into 2 columns
  dividehealthSafetyResources() {
    this.useFulResourcesList.healthSafetyResources.forEach((item, index) => {
      // if index is modulus of 2 then assign data to col 1 otherwise col 2
      if (index % 2) {
        this.helpSupportViewModel.healthSafetyResourcesCol2.push(item);
      } else {
        this.helpSupportViewModel.healthSafetyResourcesCol1.push(item);
      }
    });
  }

  ngAfterViewInit() {
    // when navigated from dashboard then go to specific div depeneding on the fragments passed
    this.activatedRoute.fragment.subscribe((fragment) =>
      this.scroller.scrollToAnchor(fragment)
    );
  }

  // this function scrolls to inspection-hub container
  goToInspectionHub() {
    this.scroller.scrollToAnchor(this.helpSupportViewModel.inspectionHub);
  }

  // this function scrolls to Insurance container
  goToInsurance() {
    this.scroller.scrollToAnchor(this.helpSupportViewModel.insurance);
  }

  // this function scrolls to Inspection-services container
  goToInspectionServices() {
    this.scroller.scrollToAnchor(this.helpSupportViewModel.inspectionServices);
  }

  // this function scrolls to health, safety and quality container
  goToHealthSafetyQuality() {
    this.scroller.scrollToAnchor(this.helpSupportViewModel.healthSafetyQuality);
  }

  // this function scrolls to the top
  goToTop() {
    this.scroller.scrollToAnchor(this.helpSupportViewModel.iconsTop);
  }
  // check and open email application on click
  openEmailClient() {
    const mailtoLink = 'mailto:' + this.helpSupportViewModel.contactUsEmailLink;
    const userAgent = navigator.userAgent.toLowerCase();

    let isSupportedOS = false;
    let isEmailAppInstalled = false;

    if (userAgent.includes('mac')) {
      isSupportedOS = true;
      // Check for email app installed on macOS
      isEmailAppInstalled = userAgent.includes('safari') || userAgent.includes('mail');
    } else if (userAgent.includes('win')) {
      isSupportedOS = true;
      // Check for email app installed on Windows
      isEmailAppInstalled = this.isEmailAppInstalledWindows(mailtoLink);
    }
    //based on above verification , making decision here
    if (!isSupportedOS) {
      console.log('Unsupported OS');
    } else if (!isEmailAppInstalled) {
      this.showPopup();
    } else {
      window.location.href = mailtoLink;
    }
  }
  //for wind OS need to verify installed email application exist or not
  isEmailAppInstalledWindows(mailtoLink: string) {
    const win = window.open(mailtoLink, '_blank');
    if (win) {
      win.close();
      return true;
    }
    return false;
  }

  //to close showpopup manually
  closeNoMailServiceFoundModal() {
    this.noMailServiceProviderFoundModalDialogRef?.close();
  }
  //when no email application found, have to show this popup
  showPopup() {
    this.noMailServiceProviderFoundModalDialogRef = this.dialogService.open(
      this.noMailServiceProviderFoundModal,
      {
        ariaLabel: 'No mail service found on system',
        disableClose: true,
      }
    );
  }


}
