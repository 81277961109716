import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './_layout/public/public.component';
import { AuthorizedComponent } from './_layout/authorized/authorized/authorized.component';
import { LocationModule } from './components/locations/locations.module';
import { CallbackComponent } from './components/callback/callback.component';
import { UsersModule } from './components/users/users.module';
import { Role } from './shared/enums/role';
import { PageNotFoundComponent } from './components/error/404/404.component';
import { ApiErrorComponent } from './components/error/api/api-error.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { MainItemModule } from './components/main-items/main-items.module';
import { HelpSupportModule } from './components/help-support/help-support.module';
import { PreferencesModule } from './components/preferences/preferences.module';
import { BreadcrumbEnums } from './shared/enums/breadcrumbs';
import { ItemGroupModule } from './components/item-group/item-group.module';
import { MaintenanceComponent } from './_layout/maintenance/maintenance.component';
import { UnplannedMaintenanceComponent } from './_layout/unplanned-maintenance/unplanned-maintenance.component';
import { ManagementReportModule } from './components/management-report/management-report.module';
import { CalendarModule } from './components/calendar/calendar.module';

// main app routes
const appRoutes: Routes = [

  // maintenance
  {
    path: 'maintenance', component: MaintenanceComponent, data: {
      breadcrumb: '', expectedRoles: []
    }
  },
  // default
  {
    path: '', component: PublicComponent, data: {
      breadcrumb: '', expectedRoles: []
    }
  },
  // for error page to display api response
  { path: 'error', component: ApiErrorComponent },
  // for logout
  {
    path: 'logged-out', component: PublicComponent, data: {
      breadcrumb: '', expectedRoles: []
    }
  },
  // for locations and items
  {
    path: '', component: AuthorizedComponent, children: [

      //dashboard
      {
        path: 'dashboard', loadChildren: () => DashboardModule, data: {
          breadcrumb: '', expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.organizationUser]
        }
      },
      //Help & support component
      {
        path: 'help-support', loadChildren: () => HelpSupportModule, data: {
          breadcrumb: BreadcrumbEnums.helpAndSupport, expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.organizationUser]
        }
      },
      //calendar
      {
        path: 'calendar', loadChildren: () => CalendarModule, data: {
          breadcrumb: 'Calendar', expectedRoles: [Role.clientAdmin, Role.standardClientUser]
        }
      },
      //items independent component
      {
        path: 'items', loadChildren: () => MainItemModule, data: {
          breadcrumb: BreadcrumbEnums.mainItem, expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.organizationUser]
        }
      },
      {
        path: 'locations', loadChildren: () => LocationModule, data: {
          breadcrumb: BreadcrumbEnums.location, expectedRoles: [Role.clientAdmin, Role.organizationUser]
        }
      },
      // for My Inspection Hub Preferences
      {
        path: 'preferences', loadChildren: () => PreferencesModule, data: {
          breadcrumb: BreadcrumbEnums.preferences, expectedRoles: []
        }
      },
      //New user registration module
      {
        path: 'users', loadChildren: () => UsersModule, data: {
          breadcrumb: BreadcrumbEnums.userManagment
        }
      },
      // user item group permissions
      {
        path: 'user', loadChildren: () => ItemGroupModule, data: {
          expectedRoles: [Role.clientAdmin, Role.organizationUser, Role.internalAdmin]
        }
      },
      // unplanned maintenance
      {
        path: 'unplannedmaintenance', component: UnplannedMaintenanceComponent, data: {
          breadcrumb: BreadcrumbEnums.unplannedMaintenance, expectedRoles: [Role.clientAdmin, Role.organizationUser, Role.internalAdmin]
        }
      },
      //reports
      {
        path: 'reports', loadChildren: () => ManagementReportModule, data: {
          breadcrumb: 'Management Reports', expectedRoles: [Role.clientAdmin, Role.standardClientUser]
        }
      }
    ]
  },
  // for auth0 callback
  { path: 'callback', component: CallbackComponent },
  // 404 error route
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
