// enum with banner setting to set up banner on screen
export enum BannerDetail {
    // default banner height
    bannerHeight = "10px",
    //calendar page banner settings
    calendarBannerText = "Calendar",
    //help & support page banner settings
    helpSupportText = "Help & Support",
    //dashboard page banner setting
    dashboardBannerText = "",
    // location page banner settings
    locationBannerText = "Locations",
    // location subTitle
    locationBannerSubtitleText = "Select a location to view all items linked to that location",
    // main-items and location items page banner text
    itemsBannerText = "Items & Reports",
    // main and location items subTitle
    itemsBannerSubtitleText = "Select an item to view item details and inspection reports",
    // client page banner settings
    clientBannerText = "Clients",
    // user management page banner settings
    userBannerText = "User Management",
    // user management subTitle
    userBannerSubtitle = "Register a new user or select an existing user to view their details",
    // user management registration page banner settings
    userRegBannerText = "New user registration",
    // reduced banner height on location screen mobile
    locationMobileBannerHeight = "70px",
    //management reports
    managementReports = "Management Reports",
    //plant not available with reasons
    plantNotAvailable = "Plant Not Available with Reasons",
    // profile banner text
    profileBannerText = "My profile",
    // no text
    NoText = ""
}