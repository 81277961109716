import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { PreferencesComponent } from './preferences.component';

//Route for preferences component
const routes: Routes = [{
    path: '', component: PreferencesComponent, canActivate: [AuthGuard], data: {
        breadcrumb: '', expectedRoles: []
    }
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PreferencesRoutingModule { }