import gql from 'graphql-tag';
// query to get locations ids and names only of a defect count from api endpoint once Dashboard component is initialized
export const getADefectLocationsList = gql`
  query locationsWithDefects(
    $locationIds: [Long]!
    $dueFrom: Date!
    $dueTo: Date!
    $sortField: SortOrderField!
    $sortOrder: SortOrder
    $from: NonNegativeInt!
    $size: NonNegativeInt!
  ) {
    locationsWithDefects(
      locationIds: $locationIds
      dueFrom: $dueFrom
      dueTo: $dueTo
      sort: { field: $sortField, order: $sortOrder }
      from: $from
      size: $size
    ) {
      totalCount
      results {
        ... on Location {
          id
          name
        }
      }
    }
  }
`;
// query to get items list for 'A' defect location code 
export const getItemsWithDefects = gql`query search( $locationIds: [Long]! , $defectTypes: [String], $dueFrom: Date!, $dueTo: Date! $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: NonNegativeInt!, $itemGroupIds : [String]!){

  itemsWithDefects(locationIds: $locationIds, defectTypes: $defectTypes,  dueFrom: $dueFrom, dueTo: $dueTo, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size, itemGroupIds: $itemGroupIds) {
          totalCount
          results{
              ... on Item{
                   id
          clientPlantNumber
          plantType {
            code
            description
          }
          location {
            code
            description
            subCodeDescription
          }
          status {
            
            description
          }
          reports {
            results{
                ... on InspectionReportDetails{
                  id
                  inspectionDate
                  defectCode{
                      code
                  }
                  url

                    itemDefects{
                        defectType
                    }
                }
            }
        }
          inspections {
            inspectionDate 
          }
          discipline {
      
            description
          } 
          policyNumber    
          serialNumber
        
        }
              }
          }
      }
      
`;
// contains fields for location id, name and checked boolean to know this property is checked or not
export interface LocationADefectIdName {
  id: string;
  name: string;
  checked: boolean;

}

//interface for all defect location filter
export interface AllDefectsLocations {
  id: number;
  name: string;

}
