import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AuthGuard } from "src/app/services/auth-service/auth.guard";
import { SharedModule } from "src/app/shared/shared.module";
import { NxCardModule } from '@aposin/ng-aquila/card';
import { ItemGroupComponent } from "./item-group.component";
import { ItemGroupRoutingModule } from "./item-group-routing.module";


// dashboard modules configured
@NgModule({
    declarations: [ItemGroupComponent],
    imports: [SharedModule, FormsModule, NxCardModule, ItemGroupRoutingModule],
    providers: [AuthGuard]
})
// exported dashboard module
export class ItemGroupModule { }