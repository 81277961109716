import { NgModule } from '@angular/core';
import { LocationsComponent } from './locations.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { LocationRoutingModule } from './location-routing.module';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { DefectBadgeColorPipe } from 'src/app/shared/pipes/defectBadge.color.pipe';

// location module with required imports
@NgModule({
  declarations: [
    LocationsComponent
  ],
  imports: [
    LocationRoutingModule,
    SharedModule,
    FormsModule
  ],
  providers: [AuthGuard, DefectBadgeColorPipe]
})
export class LocationModule { }
