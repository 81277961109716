import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/shared/appRoutes';

@Component({
  selector: 'app-useful-resources',
  templateUrl: './useful-resources.component.html',
  styleUrls: ['./useful-resources.component.scss']
})
export class UsefulResourcesComponent {
  //info message
  infoMessage: string = "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer";
  // height of popover
  popoverHeight: string = "130px"
  // width of popover
  popoverWidth: string = "150px"
  constructor(private router: Router) { }

  // navigate to help&support useful-resources tab and then scroll to specific div i.e. inspection hub, insurance etc
  navigateToUsefulResourcesDiv = async (navigateToHelpSupportResourcesDiv: string) => {
    await this.router.navigate([AppRoutes.usefulResources], { fragment: navigateToHelpSupportResourcesDiv });
  }
}
