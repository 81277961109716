<ng-container class="container">
    <div class="background-color  center nx-margin-top-l">
        <p class="nx-margin-x-l subtitles">Oops!
        </p>
        <p class="nx-margin-x-l  nx-margin-top-m subtitles">Sorry, we can't find that page ({{statusCode}}).
        </p>
        <nx-icon class="nx-margin-x-l icon-color" nxTooltip="unplanned maintenance in progress" name="product-search"
            outline="true" fill="true" size="m"></nx-icon>
        <br>
        <p class="nx-margin-x-l subtitles-text-font-small">Sorry for the inconvenience</p>

        <br>
        <p class="nx-margin-x-l subtitles-text-font-small">The page you are looking for on Allianz Inspection Hub has
            been removed, had its name
            changed or its temporarily unavailable</p>
        <br>
    </div>
</ng-container>