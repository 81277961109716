import { NgModule } from "@angular/core";
import { BadgeColorPipe } from "./badge.color.pipe";
import { DefectTextColorPipe } from "./defects/defect.text.color.pipe";
import { DefectBgPipe } from "./defects/defectBg.color.pipe";
import { EllipsisPipe } from "./ellipsis.pipe";
import { LatestCurrentReportPipe } from "./latest-report.pipe";

@NgModule({
    imports: [],
    declarations: [EllipsisPipe,
        BadgeColorPipe,
        DefectBgPipe,
        LatestCurrentReportPipe,
        DefectTextColorPipe],
    exports: [EllipsisPipe,
        BadgeColorPipe,
        DefectBgPipe,
        LatestCurrentReportPipe,
        DefectTextColorPipe]
})
export class PipesModule { }