import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { InfoModalType } from 'src/app/shared/enums/infoModelType';
import { UserService } from 'src/app/services/graphql/user.Service';
import { UpdateEmployee, UserItemGroupPermissions, UserPermissions, UserRole } from 'src/app/schemas/users';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { CommonService } from 'src/app/services/common.service';
import { shareReplay, take } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout-service';
import { EditUserViewModel } from './edit-user.viewModel';
import { DisplayRolePipe } from 'src/app/shared/pipes/roles/role.pipe';
import { DisplayRoles } from 'src/app/shared/enums/role';
import { ActivatedRoute } from '@angular/router';
import { ErrorModel } from 'src/app/shared/models/error.Model';
import { LocationService } from 'src/app/services/graphql/location.service';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';
import { ComponentName } from 'src/app/shared/enums/componentName';
import { EditUserPersonalization } from 'src/app/schemas/personalisation';
import { PersonalisationService } from 'src/app/services/graphql/personalisation.service';
import { UserViewModel } from '../userViewModel';
import { LocationViewModel } from '../../locations/locations.viewModel';
import { SelectionModel } from '@angular/cdk/collections';
import { SortEvent } from '@aposin/ng-aquila/table';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { FormControl, Validators } from '@angular/forms';
import { RolesFromApi } from '../../../shared/enums/role';
import { BaseComponent } from 'src/app/services/auth-service/base.component';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
// this is edit user component to update user properties
@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, BaseComponent {
    // object of edit user view model
    editUserViewModel: EditUserViewModel = new EditUserViewModel();
    //personlisation object 
    personalisationObj: EditUserPersonalization = new EditUserPersonalization();
    // setup template for info modal popup
    @ViewChild('template') templateRef!: TemplateRef<any>;
    //Model template for assigned location for user
    @ViewChild('newLocationsTemplate') newLocationsTemplate!: TemplateRef<any>;
    //Model to open dialog box of assigned location
    newLocationDialogRef!: NxModalRef<any, any>;
    // user viewmodel
    userViewModel: UserViewModel = new UserViewModel();
    // item search model instance for model binding
    locationViewModel: LocationViewModel = new LocationViewModel();
    // selection for checkboxes
    selection = new SelectionModel(true, []);
    // get theLastItem local ref from template
    @ViewChildren('theLastItem', { read: ElementRef }) theLastItem: QueryList<ElementRef>;
    //Model template to remove location of user
    @ViewChild('removeLocationsTemplate') removeLocationsTemplate!: TemplateRef<any>;
    //location id for remove location
    removeLocationId: any;
    //loggedin user itemGroup
    adminUserItemGroups: string

    //user assigned location and item group
    userItemGroupPermissions: UserItemGroupPermissions[] = []


    constructor(private dialogService: NxDialogService,
        private userService: UserService, private activatedRoute: ActivatedRoute, private commonService: CommonService,
        private layoutService: LayoutService, private locationService: LocationService,
        private personalisationService: PersonalisationService,
        private itemGroupService: ItemGroupService) {
    }

    async ngOnInit(): Promise<void> {
        //to set breadcrums on refresh
        this.commonService.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.editUser);
        // checking if device is desktop
        this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => { this.editUserViewModel.isDesktop = result; });
        //fetch user edit personalisation detail for per page control
        if (this.personalisationService.personalization.editUser) {
            this.personalisationObj = this.personalisationService.personalization.editUser;
        }
        this.editUserViewModel.perPage = parseInt(this.personalisationObj.perPageToggle);

        //  subscribed to route params change
        this.activatedRoute.params.subscribe(params => {
            const userId = this.commonService.IsNullORUndefined(params, "id", "");
            if (userId) {
                this.editUserViewModel.selectedUser.id = this.commonService.findAndReplace(userId, "_", "/");
            }
        });
        const assignedItemGroupObject = this.itemGroupService.getAssignedItemGroups();
        if (assignedItemGroupObject && assignedItemGroupObject.length > 0) {
            this.editUserViewModel.itemGroups = assignedItemGroupObject;
            this.itemGroupMapping();
        }
        else {
            // fetch all item groups list
            await this.fetchItemGroupList()
        }
        // setting user company and location on UI
        this.getUserCompany();
        // get user details from api by id
        await this.fetchUserDetail()

    }

    //method to check control  is dirty or not
    isFormValid = () => {
        // if save button is clicked do not open confirmation popup
        if (this.editUserViewModel.isSaveButtonClicked) {
            return false
        }
        else {
            return !this.areChangesMadeOnForm()
        }
    }

    // check if form inputs are changed and enable disable isFormValid function accordingly
    areChangesMadeOnForm = (): boolean => {
        return JSON.stringify(this.editUserViewModel.assignedItemGroups) === JSON.stringify(this.editUserViewModel.itemGroupModel) && this.editUserViewModel.disableSaveButton && this.editUserViewModel.isEReportingEnabled === this.editUserViewModel.selectedUser.eReportingEnabled && this.editUserViewModel.isENotificationsEnabled === this.editUserViewModel.selectedUser.eNotificationsEnabled
    }

    // check if form inputs are changed. If yes then enable Save button else disable it
    IsSaveButtonDisabled = (): boolean => {
        return this.editUserViewModel.disableSaveButton && this.editUserViewModel.isEReportingEnabled === this.editUserViewModel.selectedUser.eReportingEnabled && this.editUserViewModel.isENotificationsEnabled === this.editUserViewModel.selectedUser.eNotificationsEnabled && !this.itemGroupsFormcontrol.dirty || (this.editUserViewModel.itemGroupModel.length <= 0)
    }

    // redirects user to users page.
    redirectToUsers(): void {
        this.commonService.navigate(AppRoutes.users);
    }

    // private method to get user company and locations for UI controls
    getUserCompany(): void {
        this.userService.getCompanyAndLocations().pipe(untilDestroyed(this)).subscribe(userCompanyObj => {
            if (userCompanyObj) {
                // making sure company name exists in response
                if (userCompanyObj.company) {
                    // check if company has locations
                    if (userCompanyObj.company.branches && userCompanyObj.company.branches.length > 0) {
                        // clearing the employee locations array first
                        this.editUserViewModel.employeeLocations.employeeLocations.length = 0;
                        // pushing office locations to array
                        userCompanyObj.company.branches.forEach((item) => {
                            // pushing company locations
                            this.editUserViewModel.employeeLocations.employeeLocations.push({ id: item.id, name: item.name });
                        });
                    }
                    // double check if company name exists
                    if (userCompanyObj.company.name) {
                        // setting selected user company name 
                        this.editUserViewModel.selectedUser.selectedCompany = userCompanyObj.company.name;
                    }
                }
            }
        });
    }

    // how modal for info icon with appropriate message
    displayInfoModal(modalFor: InfoModalType): void {
        // modal body message
        let modalMessage: string = '';
        // switch to set message body according to specific field
        switch (modalFor) {
            case InfoModalType.Email:
                modalMessage = "Please provide your email in this input field";
                break;
            case InfoModalType.ContactNo:
                modalMessage = "Please provide your contact number in this input field";
                break;
            case InfoModalType.EmployeeLocation:
                modalMessage = "Please select a location for user";
                break;
            case InfoModalType.Role:
                modalMessage = "This is user's role";
                break;
        }
        if (modalMessage) {
            // Setup and open modal with dynamic body
            this.editUserViewModel.dialogRef = this.dialogService.open(this.templateRef, {
                showCloseIcon: true,
                ariaLabel: 'Info',
                width: 'auto',
                data: {
                    body: modalMessage
                }
            });
        }
    }

    // fetch user detail with help of id
    async fetchUserDetail() {
        // call to users service
        const result = await this.userService.getEmployeeById(this.editUserViewModel.selectedUser.id).pipe(take(1)).toPromise()
        // map fields with model 
        if (result?.data?.employee) {

            this.editUserViewModel.selectedUser.firstname = result.data.employee.firstname;
            this.editUserViewModel.selectedUser.surname = result.data.employee.surname;
            this.editUserViewModel.selectedUser.title = this.getTitleId(result.data.employee.title)
            // fetch the already assigned item groups to mark check in dropdown
            // in case of standard admin assign itemGroup to adminPermissionItemGroups
            if (result.data.employee.metadata?.role == RolesFromApi.standardClientAdmin) {
                this.editUserViewModel.itemGroupModel = result.data.employee.metadata.adminPermissionItemGroups;
                this.editUserViewModel.assignedItemGroups = result.data.employee.metadata.adminPermissionItemGroups;
            }
            // // in case of standard user assign itemGroup to userPermissionItemGroups
            else if (result.data.employee.metadata?.role == RolesFromApi.standardClientUser) {
                this.editUserViewModel.itemGroupModel = result.data.employee.metadata?.userPermissionItemGroups.map(itemGroup => itemGroup.id)
                this.editUserViewModel.assignedItemGroups = this.editUserViewModel.itemGroupModel
            }

            // find email in array of email addresses
            this.editUserViewModel.selectedUser.emailAddress = result.data.employee.emailAddresses && result.data.employee.emailAddresses.length > 0 ? result.data.employee.emailAddresses.find(x => x.email != undefined).email : "";
            // loop on phone Numbers array
            this.extractEmployeePhonenumbers(result);
            // hardcoded role 
            this.editUserViewModel.selectedUser.role = new DisplayRolePipe().transform(result.data.employee.metadata.role);
            // map the branch Id
            this.editUserViewModel.selectedUser.branchId = result.data.employee.branch ? result.data.employee.branch.id : "";
            // set roles dropdown's disability, for now if user's current role is standard client admin, we make it disabled
            this.editUserViewModel.disableRoleDropdown = this.editUserViewModel.selectedUser.role === DisplayRoles.standardClientAdmin;
            // initialize the array
            this.editUserViewModel.selectedUser.assignedLocations = []
            //assign the value of eReporting control for this user(will change after getting API field)
            this.editUserViewModel.selectedUser.eReportingEnabled = result.data.employee.metadata?.eReportingEnabled;
            this.editUserViewModel.isEReportingEnabled = result.data.employee.metadata?.eReportingEnabled;
            //assign the value of eNotification control for this user
            this.editUserViewModel.selectedUser.eNotificationsEnabled = result.data.employee.metadata?.eNotificationEnabled;
            // also save value in variable to enable disable save button after comparison with users choice
            this.editUserViewModel.isENotificationsEnabled = result.data.employee.metadata?.eNotificationEnabled;
            // if user is standard client user, then needs to fetch its locations
            // check for metadata object presence in employee object 

            if (result.data.employee.metadata && result.data.employee.metadata.userPermissionItemGroups) {
                this.fetchLocationDetailsOnly(result.data.employee.metadata.userPermissionItemGroups)
            }
            //Banner text setting
            this.layoutService.setBanner(`${this.editUserViewModel.selectedUser.firstname} ${this.editUserViewModel.selectedUser.surname}`);
            //Save the initial value of role in local variable to further reference point of view 
            this.editUserViewModel.selectedUserRole = this.editUserViewModel.selectedUser.role;
        }
    }

    // fetch assigned locations
    fetchLocationDetailsOnly(userPermissionItemGroups: any) {
        if (userPermissionItemGroups) {
            this.extractLocationIds(userPermissionItemGroups);
        }
        else {
            this.userService.getEmployeeById(this.editUserViewModel.selectedUser.id).pipe(untilDestroyed(this)).subscribe(result => {
                if (result?.data?.employee?.metadata && result?.data?.employee?.metadata?.userPermissionItemGroups) {

                    this.extractLocationIds(result?.data?.employee?.metadata?.userPermissionItemGroups)
                }
            })
        }
    }

    // method to extract the location Ids of item group
    extractLocationIds = (userPermissionItemGroups: any) => {
        // fetch location ids from metadata.locations object
        userPermissionItemGroups.forEach(location => {
            this.editUserViewModel.assignedLocationsIds.push(location.locationIds)
        })
        this.editUserViewModel.assignedLocationsIds = this.editUserViewModel.assignedLocationsIds.flat()
        // fetch locations details

        this.fetchLocations();
    }
    // extract employee phone numbers
    extractEmployeePhonenumbers = (result: any): void => {
        let index = 0
        // check if result object have phone numbers object
        if (result.data.employee.phoneNumbers) {
            result.data.employee.phoneNumbers.forEach((userPhoneNumber: any) => {
                // check if it is not a mobile number
                if (index == 0) {
                    this.editUserViewModel.selectedUser.mobileNo = userPhoneNumber.phoneNumber;
                    index += 1;
                }
                // check if it is a mobile number
                else
                    this.editUserViewModel.selectedUser.contactNo = userPhoneNumber.phoneNumber;
            })
        }
    }
    // fetch locations details of standard client user 
    async fetchLocations() {
        // prepare the search param list to contain these location ids
        this.prepareSearchParamList(true);
        // call to locations endpoint to fetch details of these location Ids
        const apiResponse = await this.locationService.getLocations(this.editUserViewModel.locationQueryParamList.searchList, this.editUserViewModel.sortDirection.toUpperCase(), this.editUserViewModel.sortBy, this.editUserViewModel.page - 1, this.editUserViewModel.perPage, true)
        if (apiResponse && apiResponse.locationDetails) {
            // setting viewmodel from response
            this.editUserViewModel.selectedUser.assignedLocations = apiResponse.locationDetails;
            this.editUserViewModel.resultCount = apiResponse.totalCount;
        }

    }
    // set multi fields search values
    prepareSearchParamList(isAssignedCall: boolean = false): void {
        //to handle add new locations, have added this param 
        if (isAssignedCall)
            this.commonService.updateSearchParamList(this.editUserViewModel.locationQueryParamList, "locationId", this.editUserViewModel.assignedLocationsIds.join('|'));

        else {
            //in case a user already has assigned location
            if (this.editUserViewModel.assignedItemGroupIds.length > 0)
                this.commonService.updateSearchParamList(this.userViewModel.queryParamList, "locationId", this.editUserViewModel.assignedItemGroupIds.flat().join('|'));
        }

    }
    // assign title id w.r.t title from api
    getTitleId(title: string) {
        switch (title) {
            case 'Miss': return "1";
            case 'Mrs': return "2";
            case 'Ms': return "3";
            case 'Mr': return "4";
            case 'Doctor': return "5";
            case 'Mx': return "6";
            default: return "0";
        }
    }
    // enable disable save button when value is changed
    enableDisableSaveButton = (updatedRole: string) => {
        this.editUserViewModel.disableSaveButton = updatedRole.toLowerCase() == this.editUserViewModel.selectedUserRole.toLowerCase();
    }

    //get item group id in case of standard user 
    addRemoveUserPermissions() {
        // get the list of arrays to remove access
        this.editUserViewModel.removeUserPermissionItemGroups = this.editUserViewModel.itemGroups.filter(item => !this.editUserViewModel.itemGroupModel.includes(item.id)).map((removeItem) => {
            return {
                id: removeItem.id,
                locationIds: []
            }
        })
        // get the list of arrays to add permissions
        this.editUserViewModel.addUserPermissionItemGroups = this.editUserViewModel.itemGroups.filter(item => this.editUserViewModel.itemGroupModel.includes(item.id)).map(addItem => {
            return {
                id: addItem.id,
                locationIds: []
            }
        })



    }

    //get item group id in case of standard admin
    addRemoveAdminPermissions() {
        // get the list of arrays to remove access
        this.editUserViewModel.removeAdminPermissionItemGroups = this.editUserViewModel.itemGroups.filter(item => !this.editUserViewModel.itemGroupModel.includes(item.id)).map(removeItem => removeItem.id)
        // get the list of arrays to add permissions
        this.editUserViewModel.addAdminPermissionItemGroups = this.editUserViewModel.itemGroups.filter(item => this.editUserViewModel.itemGroupModel.includes(item.id)).map(addItem => addItem.id)
    }

    // save updated role of user 
    saveUser() {
        // if role is Standard Client User then call addRemoveUserPermissions function
        if (this.editUserViewModel.selectedUser.role == "Standard Client User") {
            this.addRemoveUserPermissions()
        }
        // if role is standard client admin call addRemoveAdminPermissions
        else {
            this.addRemoveAdminPermissions()
        }

        this.editUserViewModel.isSaveButtonClicked = true;
        // interface object with user data
        const user: UserRole = {
            id: this.editUserViewModel.selectedUser.id,
            role: this.editUserViewModel.selectedUser.role == "Standard Client Admin" ? RolesFromApi.standardClientAdmin : RolesFromApi.standardClientUser,
            eReportingEnabled: this.editUserViewModel.selectedUser.eReportingEnabled,
            eNotificationEnabled: this.editUserViewModel.selectedUser.eNotificationsEnabled,
            // if role is standard admin then send add and remove arrays else send empty arrays
            adminPermissions: {
                addPermissions: this.editUserViewModel.selectedUser.role == "Standard Client Admin" ? this.editUserViewModel.addAdminPermissionItemGroups : [],
                removePermissions: this.editUserViewModel.selectedUser.role == "Standard Client Admin" ? this.editUserViewModel.removeAdminPermissionItemGroups : []
            },
            // if role is standard user then send add and remove arrays else send empty arrays
            userPermissions: {
                addPermissions: this.editUserViewModel.selectedUser.role == "Standard Client User" ? this.editUserViewModel.addUserPermissionItemGroups : [],
                removePermissions: this.editUserViewModel.selectedUser.role == "Standard Client User" ? this.editUserViewModel.removeUserPermissionItemGroups : []
            }

        }

        // update to api with object
        this.userService.updateUserRolePermissions(user).pipe(shareReplay(), untilDestroyed(this)).subscribe(res => {
            if (res) {
                // if data is has required properties
                if (res?.data?.updateEmployee?.metadata) {
                    // if permissions are successfully updated, navigate to user grid
                    this.redirectToUsers();
                }
                else {
                    // show message in case of failure
                    this.layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Unable to register, please contact admin for support."));

                }
            }
        });

    }

    // navigate to specific page and load its data
    // configured with pagination control
    goToPage(pageNumber: number) {
        this.editUserViewModel.page = pageNumber;
        this.fetchLocations();
    }

    // navigate to previous page
    prevPage() {
        this.editUserViewModel.page--;
        this.fetchLocations();
    }

    // navigate to next page
    nextPage() {
        this.editUserViewModel.page++;
        this.fetchLocations();
    }

    // toggle number of records per page by clicking 
    public async togglePerPage(selectedPerPage: PerPageModel): Promise<void> {
        // clicked option's value is set as per page option
        this.editUserViewModel.perPage = selectedPerPage.value;
        // page to load is set to 1 i.e. load first page
        this.editUserViewModel.page = 1;
        //save user personalisation  
        this.personalisationObj.perPageToggle = `${selectedPerPage.value.toString()}`;
        await this.savePersonalisation();
        // calling load page
        this.fetchLocations();
    }
    //save user personalisation 
    async savePersonalisation(): Promise<void> {
        await this.personalisationService.updateComponentSettings(this.personalisationObj, ComponentName.editUser);
    }

    //to assign new locations to user, this model is help to show unassigned locations
    newLocationsModel(): void {
        // empty locations array on modal open
        this.locationViewModel.locations = [];
        // empty the modal popup locations i.e. Ids assigned by user on item groups dropdown
        this.editUserViewModel.assignedItemGroupIds = []
        // set page to 0
        this.locationViewModel.page = 0;
        //observer to observe the last element and call intersection observer
        this.theLastItem.changes.subscribe((data) => {

            this.editUserViewModel.observer.observe(data.last.nativeElement)
        })
        // clear param list
        this.userViewModel.queryParamList.clear(0);
        // get locationIds from the selected item group ids
        this.editUserViewModel.assignedItemGroupIds = this.editUserViewModel.itemGroups.filter(item => this.editUserViewModel.itemGroupModel.includes(item.id)).map(addItem => {
            return addItem.locationIds
        })
        // update Search param
        // here .flat() is used to concatenate sub-arrays of an array into single array
        this.commonService.updateSearchParamList(this.userViewModel.queryParamList, "locationId", this.editUserViewModel.assignedItemGroupIds.flat().join('|'));
        //  call intersection observer for fetching 1st ten locations
        this.intersectionObserver();

        //open new model for new locations
        this.newLocationDialogRef = this.dialogService.open(this.newLocationsTemplate, {
            showCloseIcon: true
        });
    }

    // get locations from graphql service
    async fetchAllLocations(isSearchCall: boolean = false, pageSize: number = 10) {
        // prepare the search param list to contain these location ids
        if (!isSearchCall) {
            this.prepareSearchParamList();
        }
        // prepare variables to pass to the api
        const result = await this.locationService.getLocations(this.userViewModel.queryParamList.searchList, this.editUserViewModel.addLocationModelSortDirection.toLocaleUpperCase(), this.editUserViewModel.addLocationModelSortBy, this.locationViewModel.page - 1, pageSize, true);
        // after model is once opened then make this variable false so that page num can be incremented by 1
        if (result && result.locationDetails) {
            //  mapping of total results count
            this.locationViewModel.resultCount = result.totalCount;
            // check if it is not a scroll call, then clear the existing rows to shows only rows which meets the search criteria
            result.locationDetails.forEach(location => {
                // to get complete address here instead of using conditionals in template
                location.completeAddress = `${location?.postalAddress?.address1} ${location?.postalAddress?.address2} ${location?.postalAddress?.address3} ${location?.postalAddress?.address4} ${location?.postalAddress?.address5} ${location?.postalAddress?.address6}`
                this.locationViewModel.locations.push(location)
            })
        }

    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.locationViewModel.locations.length;
        return numSelected === numRows;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAll() {

        this.isAllSelected() ?
            this.selection.clear() :
            this.locationViewModel.locations.forEach(row => this.selection.select(row));

    }

    // toggle selected checkbox / single location
    toggleSelected(location: LocationViewModel): void {
        this.selection.toggle(location);

    }
    //close the model 
    closeModel = () => {
        //clear the location object
        this.locationViewModel.locations = []
        //clear the saerch using userview model
        this.userViewModel.modelClear();
        // remove any previous search inputs
        this.userViewModel.queryParamList.clear(0);
        // default sort by
        this.editUserViewModel.addLocationModelSortBy = "name"
        // default sort order
        this.editUserViewModel.addLocationModelSortDirection = "ASC"
        //close the model as well
        this.newLocationDialogRef.close();
    }
    //save newly assigned locations
    updateLocations(userPermission: any) {

        const userlocations: UpdateEmployee = {
            id: this.editUserViewModel.selectedUser.id,
            userPermissions: userPermission
        }
        // need to clear the selection
        this.selection.clear();

        // update new location to user
        this.userService.updateRemoveUserLocation(userlocations).pipe(shareReplay(), untilDestroyed(this)).subscribe(res => {
            if (res) {
                // if data is has required properties
                if (res.data && res.data.updateEmployee && res.data.updateEmployee.id) {
                    // calling load page
                    this.fetchLocationDetailsOnly(null);
                    // if locations are successfully updated,close model, navigate to user grid
                    this.closeModel();
                } else {
                    // show message in case of failure
                    this.layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Unable to add new locations, please contact admin for support."));

                }
            }
        });
    }

    // perform search, display result list and update routes 
    searchLocations() {
        // make isSearchCalled true so that Clear can only be used when searched
        this.editUserViewModel.isSearchCalled = true
        // reset the page num to 0
        this.locationViewModel.page = 1;
        //Clearing existing list
        this.locationViewModel.locations = []
        // remove any previous search inputs
        this.userViewModel.queryParamList.clear(0);
        this.commonService.updateSearchParamList(this.userViewModel.queryParamList, "locationId", this.updateArrayFirstElement(this.editUserViewModel.assignedLocationsIds).join('|-'));
        this.commonService.updateSearchParamList(this.userViewModel.queryParamList, this.locationViewModel.locationNameAndAddress, this.userViewModel.locationNameAndAddress);
        this.fetchAllLocations(true);

    }
    //clear the search field
    clearSearch() {
        //clear the selection
        this.selection.clear();
        // reset the page num to 0
        this.locationViewModel.page = 1;
        //clear the saerch using userview model
        this.userViewModel.modelClear();
        // only call this if search is actually done
        if (this.editUserViewModel.isSearchCalled) {
            this.locationViewModel.locations = []
            // remove any previous search inputs
            this.userViewModel.queryParamList.clear(0);
            //calling again fetch location for model popup
            this.fetchAllLocations(false);
            // make search variable false
            this.editUserViewModel.isSearchCalled = false
        }

    }
    //update first element of array to get unassigned locations
    updateArrayFirstElement(locationArray: any[]): any[] {
        //get first element and append "-" to it, rest of task will do by join function
        if (locationArray.length > 0) {
            let firstElement = locationArray[0];
            if (String(firstElement)[0] !== "-")
                firstElement = "-" + firstElement;
            locationArray[0] = Number(firstElement);
        }
        return locationArray;
    }

    //intersection Observer to callback fetchlocation upon reaching intersection
    intersectionObserver() {
        //main function that takes a callback and options
        this.editUserViewModel.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if ((this.locationViewModel.resultCount > this.locationViewModel.locations.length || this.locationViewModel.resultCount === 0) && !this.editUserViewModel.sortAddLocationGrid) {
                        // increment the page number
                        this.locationViewModel.page += 1;
                        // fetch locations with updated requirements
                        this.fetchAllLocations(true);

                    }
                    // sorting not applied by default
                    this.editUserViewModel.sortAddLocationGrid = false;
                }
            })
        }, this.editUserViewModel.options)
    }

    // Sort grid by specific attribute
    // handles both local sorting and 
    // get list storted from api
    sortTable(sort: SortEvent) {
        // if returned results are less than 11, we can apply local sorting
        if (this.editUserViewModel.resultCount <= 10) {
            this.sortLocally(sort);
        }
        else {
            // otherwise will call api with sort params 
            this.editUserViewModel.sortBy = sort.active;
            this.editUserViewModel.sortDirection = sort.direction;
            this.fetchLocations();
        }
    }
    // Sort grid of add location pip by specific attribute
    // handles both local sorting and 
    // get list storted from api
    sortAddLocationTable(sort: SortEvent) {
        // map the sort by and direction in view model fields
        this.editUserViewModel.addLocationModelSortBy = sort.active
        this.editUserViewModel.addLocationModelSortDirection = sort.direction
        // if all results are fetch, need to perform the local sorting
        if (this.locationViewModel.resultCount === this.locationViewModel.locations.length) {
            // we are applying local sorting due to infinte scrolling
            this.locationViewModel.locations.sort((_firstElement, _secondElement) => {
                switch (sort.active) {
                    case 'name': return this.commonService.compare(_firstElement.name, _secondElement.name, sort.direction);
                    case 'postalAddressPostCode': return this.commonService.compare(_firstElement.postalAddress?.postcode, _secondElement.postalAddress?.postcode, sort.direction);
                    case 'postalAddress': return this.commonService.compare(_firstElement.postalAddress?.address1, _secondElement.postalAddress?.address1, sort.direction);
                    default: return 0;
                }
            });
        }
        else {
            // save number of records already loaded to load in future
            const numberOfRecordsLoaded = this.locationViewModel.locations.length;
            // set bit to true to indicate that it was a call to perform sorting
            this.editUserViewModel.sortAddLocationGrid = true;
            // make the list empty to load the sorted list
            this.locationViewModel.locations = [];
            // set page number to 1
            this.locationViewModel.page = 1;
            // call api to get records
            this.fetchAllLocations(false, numberOfRecordsLoaded < 100 ? numberOfRecordsLoaded : 100)
        }
    }

    // sort assigned locations locally if count is equal/less than 10
    sortLocally(sort: SortEvent): void {
        this.editUserViewModel.selectedUser.assignedLocations.sort((a, b) => {
            switch (sort.active) {
                case 'name': return this.commonService.compare(a.name, b.name, sort.direction);
                case 'postalAddressPostCode': return this.commonService.compare(a.postalAddress?.postcode, b.postalAddress?.postcode, sort.direction);
                case 'postalAddress': return this.commonService.compare(a.postalAddress?.address1, b.postalAddress?.address1, sort.direction);
                default: return 0;
            }
        });
    }

    //open model for delete confirmation message
    openRemoveConfirmationModel(locationId: string) {
        this.editUserViewModel.locationId = locationId
        this.removeLocationId = this.editUserViewModel.itemGroups.filter(item => item.locationIds.includes(locationId))
        this.dialogService.open(this.removeLocationsTemplate, { ariaLabel: 'remove user assigned location', showCloseIcon: true });
    }
    //remove the location
    removeLocation() {
        // call to itemgroup service
        this.itemGroupService.getAdminItemGroup().pipe(untilDestroyed(this)).subscribe(result => {
            // map fields with model 
            if (result && result.data && result.data.itemGroups && result.data.itemGroups[0].id) {
                //assign admin item group id for further use   
                this.adminUserItemGroups = this.removeLocationId[0].id;
                // interface object with user permission
                let userPermission = {} as UserPermissions;
                //initiaze it as each time to get fresh object
                this.userItemGroupPermissions = []

                let userItemGroupPermissionsData = {} as UserItemGroupPermissions
                userItemGroupPermissionsData.locationIds = Number(this.editUserViewModel.locationId)
                userItemGroupPermissionsData.id = this.adminUserItemGroups
                this.userItemGroupPermissions.push(userItemGroupPermissionsData);
                userPermission.removePermissions = this.userItemGroupPermissions
                //object that will be pass to APIs to remove location
                const removeLocation: UpdateEmployee = {
                    id: this.editUserViewModel.selectedUser.id,
                    userPermissions: userPermission
                }
                // update to api with object
                this.userService.updateRemoveUserLocation(removeLocation).pipe(shareReplay(), untilDestroyed(this)).subscribe(res => {
                    // if data  has required properties
                    if (res?.data && res.data?.updateEmployee && res.data.updateEmployee?.id && res.data.updateEmployee?.metadata?.userPermissionItemGroups?.length > 0) {
                        // empty these variables before calling fetchLocations function
                        this.editUserViewModel.selectedUser.assignedLocations = []
                        this.editUserViewModel.assignedLocationsIds = []
                        this.editUserViewModel.locationQueryParamList.clear(0);
                        // calling load page
                        this.fetchLocationDetailsOnly(null);

                    } else {
                        // show message in case of failure
                        this.layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Unable to remove this location, please contact admin for support."));

                    }
                });
            }
        });

    }
    // fetch logged in user item groups 
    fetchAdminItemGroup() {

        this.userItemGroupPermissions = []
        // call to itemgroup service
        this.itemGroupService.getAdminItemGroup().pipe(untilDestroyed(this)).subscribe(result => {
            // map fields with model 
            if (result && result.data && result.data.itemGroups && result.data.itemGroups[0].id) {

                //assign admin item group id for further use   
                let userPermission = {} as UserPermissions;
                for (const itemGroup of result.data.itemGroups) {
                    //have to create separate item group locations ids 
                    const userPermission = this.generateUserItemGroupList(itemGroup)
                    if (userPermission.locationIds.length > 0)
                        this.userItemGroupPermissions.push(userPermission)
                }
                userPermission.addPermissions = this.userItemGroupPermissions
                //call update location API to update the location
                this.updateLocations(userPermission)
            }
        });
    }
    //generate user permission group using multiple item group which will return object of group Ids and its linked location ids bases on 
    //user selection 
    generateUserItemGroupList(adminUserItemGroups: any): UserItemGroupPermissions {
        let userItemGroupPermissionsData = {} as UserItemGroupPermissions;
        userItemGroupPermissionsData.id = adminUserItemGroups.id
        //need to push user selected locationids as list to get common between them
        const itemGroups: number[] = []
        this.selection.selected.forEach(loc => {
            itemGroups.push(loc.id);
        })
        //need to pass linked group location ids as list 
        const locationIds: number[] = []
        adminUserItemGroups.locationIds.forEach(loc => {
            locationIds.push(loc)
        })
        userItemGroupPermissionsData.locationIds = this.getCommon(itemGroups, locationIds)
        // store item group value and location data for each item group
        this.userItemGroupPermissions.push(userItemGroupPermissionsData);
        return userItemGroupPermissionsData
    }
    //we have to look into item group locationiDS and selected location ids to get common 
    //location ids which help us to create item group object {id:groupid, thisgrouplocationidswhich user selected:locationids}
    getCommon(arr1: any[], arr2: any[]): number[] {
        const common: any[] = []
        arr1.forEach(x => {
            if (arr2.includes(x))
                common.push(x)
        })
        return common          // Return the common elements
    }

    // fetch item groups list
    async fetchItemGroupList() {
        const result = await this.itemGroupService.getAdminItemGroup().pipe(take(1)).toPromise()
        if (result && result.data && result.data.itemGroups && result.data.itemGroups.length >= 1) {
            // empty itemGroupList for any previous values
            this.editUserViewModel.itemGroupsList = [];
            // save api result in variable
            this.editUserViewModel.itemGroups = result.data.itemGroups
            this.itemGroupMapping();
        }

    }

    // method which handles the scenarios of item groups (single, multiple)
    itemGroupMapping = () => {
        // in case of single itemGroup get 0th index name and id
        // name is used to directly render it in input field
        // id is used to be passed to userPermissions Variable for creating a user
        if (this.editUserViewModel.itemGroups.length == 1) {
            this.editUserViewModel.isSingleItemGroup = true;
            this.editUserViewModel.singleItemGroupName = this.editUserViewModel.itemGroups[0].name
            this.editUserViewModel.itemGroupModel = this.editUserViewModel.itemGroups.map(id => id.id)
        }
        // if multiple item groups then iterate through each and find id and name
        else {
            this.editUserViewModel.isSingleItemGroup = false;
            this.editUserViewModel.itemGroups.forEach(item => {
                this.editUserViewModel.itemGroupsList.push({
                    id: item.id,
                    name: item.name
                })
            })

        }
    }
    // validation for item group dropdown
    itemGroupsFormcontrol = new FormControl(undefined, {
        validators: [Validators.minLength(1), Validators.required],
    });



    // on mouse over fill the circle icon
    mouseOver() {
        this.editUserViewModel.itemGroupIconName = "info-circle"
    }
    // on mouse out event
    mouseOut() {
        this.editUserViewModel.itemGroupIconName = "info-circle-o"
    }



}