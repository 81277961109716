import { Injectable } from '@angular/core';
import { ItemsFiltersModel } from '../statemanagement/models/filtersModels/items.filters.model';
import { LocationFiltersModel } from '../statemanagement/models/filtersModels/locations-filters.model';
import { MainItemsFiltersModel } from '../statemanagement/models/filtersModels/main-items.filters.model';
import { ADefectWidgetFiltersModel, LocationDueOverdueWidgetFiltersModel, MapsLoadedDataStorage } from '../shared/models/dashboard.filters.model';

// Filters service to have fields of location and items service models
@Injectable()
export class FiltersService {
    // contains filters data of location page
    locationsFilters: LocationFiltersModel = new LocationFiltersModel();
    // contains filters data of items page
    itemsFilter: ItemsFiltersModel = new ItemsFiltersModel();
    // contains filters data for main items page
    mainItemsFilter: MainItemsFiltersModel = new MainItemsFiltersModel();
    // contains filters data of a defect widget
    aDefectFilter: ADefectWidgetFiltersModel = new ADefectWidgetFiltersModel();
    // contains filters data of location due/overdue widget
    locationDueOverDueFilter: LocationDueOverdueWidgetFiltersModel = new LocationDueOverdueWidgetFiltersModel();
    // contains loaded data of maps 
    mapLoadedData: MapsLoadedDataStorage = new MapsLoadedDataStorage();
}