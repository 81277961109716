import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItemsComponent } from './location-items.component';

// setting up routes for location-items module
const appRoutes: Routes = [
    // Route for /Locations-items
    {
      path: '', component: ItemsComponent, canActivate: [AuthGuard], data: {
        breadcrumb: ''
      }
    }
  ];
  
  @NgModule({
    declarations: [],
    imports: [
      CommonModule,
      RouterModule.forChild(appRoutes)
    ],
  })
  
  // Exporting location routing module
  export class LocationItemsRoutingModule { }
  