// class to store the resources lists of different categories
export class UseFulResources {
    // it stores the resources of type inspection hub category only
    inspectionHubResources: ResourceDetails[] = []
    // it stores the resources of type health safety category only
    healthSafetyResources: ResourceDetails[] = []
    // it stores the resources of type inspection category only
    inspectionResources: ResourceDetails[] = []
    // it stores the resources of type insurance category only
    insuranceResources: ResourceDetails[] = []
}
// interface to have information of one particular resource record
export interface ResourceDetails {
    type: any,
    title: any,
    url: any,
    category: any
}
