<app-header></app-header>

<ng-container class="container">
  <div class="banner">
    <img
      src="../../../../assets/404banner.jpeg"
      alt="allianz banner"
      class="img"
      width="100%"
    />
    <h1 class="banner-text blueColor">Allianz Insurance</h1>
  </div>

  <div class="banner-bottom">
    <nx-icon name="exclamation-triangle-o" size="m" class="blueColor"></nx-icon>
    <p class="main-text-size">Something has gone wrong</p>
    <p class="para-text-size">
      If you have an account with us, we may have sent you an email with further
      information.
    </p>
    <p class="para-text-size">
      Otherwise, please contact your Allianz Representative or email
      <span class="blueColor">digitalsupport@allianz.co.uk</span>
    </p>
  </div>
</ng-container>
