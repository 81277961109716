import { AfterViewInit, Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { filter, pairwise, take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FORMFIELD_DEFAULT_OPTIONS } from '@aposin/ng-aquila/formfield';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { LocationDefect } from 'src/app/schemas/location-a-defect';
import { LocationADefectIdName } from 'src/app/schemas/location-a-defects-names';
import { Router, RoutesRecognized } from '@angular/router';
import { DashboardService } from 'src/app/services/graphql/dashboard.service';
import { LocationItemsViewModel } from 'src/app/components/locations/location-items/location-items.viewModel';
import { ADefectDataList, LocationDefectListSearchModel, LocationDetailSearchModel } from '../../dashboard.viewModel';
import { ADefectViewModel } from './a-defect.viewModel';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { SearchModels } from 'src/app/schemas/searchModel';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ExportService } from 'src/app/services/export.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FiltersService } from 'src/app/services/filters-service';
import { from } from 'rxjs';



@UntilDestroy()
// a-defect component component to display widgets and statistics to the loggedin user
@Component({
  selector: 'a-defect-widget',
  templateUrl: './a-defect.component.html',
  styleUrls: ['./a-defect.component.scss'],
  providers: [
    {
      provide: FORMFIELD_DEFAULT_OPTIONS,
      useValue: { appearance: '', nxFloatLabel: 'always' }
    }
  ]
})
export class ADefectWidgetComponent implements OnInit, AfterViewInit {
  @Input() locationList;
  // a-defect view model object
  aDefectViewModel: ADefectViewModel = new ADefectViewModel;
  // to save checkboxes values and make the button disable or able depending on its length
  checkboxes = [];
  // options for pie chart
  tooltipDisabled: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  trimLabels: boolean = false;
  view: any[] = [320, 250];
  // color scheme of pie chart arcs
  colorScheme = {
    domain: ['#F07393', '#F8BFCE', '#E4003A']
  };
  // field to store defects locally, as this is not getting used in view so thats why it is present in component
  locationsADefectCountData: LocationDefect[] = [];

  //location item view model to save api response for export
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();

  // viewchild of filter i.e. A-defect-widget
  @ViewChild('aDefectfilterTemplateModal') aDefectfilterTemplateRef!: TemplateRef<any>;
  // viewChild template of filter 
  @ViewChild('filterTemplateModal') filterTemplateRef!: TemplateRef<any>;
  filterTemplateDialogRef!: NxModalRef<any>;
  // another selection model is used so that it does not disturb locations filter checkboxes states
  aDefectSelection = new SelectionModel<LocationADefectIdName>();
  // dashboard filtered locations
  aDefectFilteredLocations: any;
  // the input entered by user in the search filter of filter modal 
  name: string;
  // create a copy of LocationsData array and store values in this variable for A-defect count widget filter
  filteredADefectLocation: any;
  //location detail
  locationDetailList: LocationDetailSearchModel[] = [];
  //locationIds list to use it 
  locationIds: any[] = []
  // selection model for aDefect checkboxes i.e. AE, AI, AT
  aDefectCBSelection = new SelectionModel(true, [])
  // a variable for a Defect CB
  aDefectCB = [
    {
      value: 'AE (A-Enforcing)',
      class: 'dot bg-ae-dot',
      disable: true
    },
    {
      value: 'AI (A-Immediate)',
      class: 'dot bg-ai-dot',
      disable: true
    },
    {
      value: 'AT (A-Timed)',
      class: 'dot bg-at-dot',
      disable: true
    }
  ]
  //variable to use to check all locations are selected or not
  isSelectAll: boolean = false;
  // search model to store fields of search params
  queryParamList: SearchModels = new SearchModels();
  constructor(private layoutService: LayoutService,
    private router: Router,
    private dashboardService: DashboardService,
    private filterService: FiltersService,
    private common: CommonService,
    private itemGroupService: ItemGroupService,
    private exportService: ExportService,
    private dialogService: NxDialogService, private itemsService: LocationItemService

  ) { }

  ngOnInit(): void {
    // we need to look into previous page for the scenario that when we navigate from aDefect widget to items, on return widget should be expanded
    this.getPreviousUrl();
    // get saved dashboard filters from service
    this.fetchDashboardFilters();
    //set banner text
    this.layoutService.setBanner(BannerDetail.dashboardBannerText);
    //subscribing to device view
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => {
      this.aDefectViewModel.isDesktop = result;
      this.onResize()
    })
    // expand a defect widget when previous url is location items
    this.expandADefectWidget();

  }


  // get previous url from navigation is initiated
  // store value in dashboard service
  getPreviousUrl = () => this.router.events.pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise()).pipe(take(1)).toPromise().then(route => {
    this.dashboardService.previousUrl = route[0].urlAfterRedirects
  })

  // expand a defect widget when
  // 1. Last url is location items with query string
  // 2. Navigation is done from a defect widget
  expandADefectWidget = () => {
    if (this.dashboardService.previousUrl != "" && this.dashboardService.previousUrl == "/locations/items?redirected=dashboard") {
      this.expandCollapsePieChartContainer();
    }

  }

  // fetch saved filters for dashboard
  fetchDashboardFilters = () => {
    // fetch and map location a defect widget filters
    this.fetchDashboardADefectFilter()
  }

  // fetch and map location a defect widget filters
  fetchDashboardADefectFilter = () => {
    // get filters details of a defect widget
    if (this.filterService.aDefectFilter) {
      // fetch the end date
      if (this.filterService.aDefectFilter.endDate) {
        this.aDefectViewModel.aDefectStartDate = this.filterService.aDefectFilter.endDate
        // if date range is not disabled in filter modal, add this value to filter range field
        if (!this.filterService.aDefectFilter.isDateRangeDisabled)
          this.aDefectViewModel.aDefectStartDatePie = moment(this.filterService.aDefectFilter.endDate)
      }
      // fetch the start date
      if (this.filterService.aDefectFilter.startDate) {
        this.aDefectViewModel.aDefectEndDate = this.filterService.aDefectFilter.startDate
        // if date range is not disabled in filter modal, add this value to filter range field
        if (!this.filterService.aDefectFilter.isDateRangeDisabled)
          this.aDefectViewModel.aDefectEndDatePie = moment(this.filterService.aDefectFilter.startDate)
      }
      // map the show previous field
      if (this.filterService.aDefectFilter.showPrevious) {
        this.aDefectViewModel.showPrevious = this.filterService.aDefectFilter.showPrevious
      }
      // map the location details list in filter model
      if (this.filterService.aDefectFilter.locationDetailList) {
        this.aDefectFilteredLocations = this.filterService.aDefectFilter.locationDetailList
        // update the list of locations on widget with selected locations only
        this.updateLocationNamesList();
      }
      // set show previous text
      this.aDefectViewModel.differenceBetweenDates = this.filterService.aDefectFilter.differenceBetweenDates;

    }
  }
  // update location names list on A-defect widget
  // when filters are present, show the selected locations name in widget
  updateLocationNamesList = () => {
    // if locations are more than 3
    if (this.aDefectFilteredLocations.length > 3) {
      // slice the first 3 locations
      this.aDefectViewModel.aDefectLocationNames = this.aDefectFilteredLocations.slice(0, 3)
      // calculate the remaining count of locations
      const remainingCount = this.aDefectFilteredLocations.length - this.aDefectViewModel.aDefectLocationNames.length
      // make array of location names only
      const locationNamesArray = this.aDefectViewModel.aDefectLocationNames.map(locationDetails => { return locationDetails.locationName });
      // concat the location names with comma and add remaining count
      this.aDefectViewModel.aDefectLocationNamesAndCount = `${this.toTextMulti(locationNamesArray, ", ")} + ${remainingCount}`;
    }
    // if selected locations are less than 3 then just show there names
    else {
      const locationNamesArray = this.aDefectFilteredLocations.map(locationDetails => { return locationDetails.locationName });
      this.aDefectViewModel.aDefectLocationNamesAndCount = `${this.toTextMulti(locationNamesArray, ", ")}`;
    }

  }

  // added this life cycle event to access map div 
  ngAfterViewInit(): void {
    this.onResize()
    if (this.aDefectFilteredLocations) {
      // if selected location is not equal to actual locations then show selected locations names
      if (this.locationList.length !== this.aDefectFilteredLocations.length) this.aDefectViewModel.isAllADefectSelected = false;
      // if selected location is equal to actual locations then show All keyword
      if (this.locationList.length == this.aDefectFilteredLocations.length) this.aDefectViewModel.isAllADefectSelected = true;
    }
  }

  ngOnChanges(): void {
    const itemGroupsList = this.itemGroupService.getSelectedItemGroupsIds();
    if (itemGroupsList && itemGroupsList.length > 0) {
      // getting A defect locations name and ids to render on A-defect filter
      this.getLocationADefectList();
      // get A-defect counts of locations
      this.getLocationADefects();

    }
  }

  // Purpose:
  // when filter is applied clear the existing values to show only filter data
  // previous data is removed
  clearADefectModelValues = () => {
    // clear list of a defect which is mapped with widget
    this.aDefectViewModel.aDefectDataObj.locationADefectCountList.length = 0;
    // clear AE defect value
    this.aDefectViewModel.aDefectListObject.AEDefectCount = 0;
    // clear AI defect value
    this.aDefectViewModel.aDefectListObject.AIDefectCount = 0;
    // clear AT defect value
    this.aDefectViewModel.aDefectListObject.ATDefectCount = 0;
  }

  // this service is called to fetch all location names to be displayed on Filter dropdown. It is just called once to get Ids and names of Adefects
  // This service collects data within last 1 year
  getLocationADefectList() {

    // check if filters are present
    // change check property of locations accordingly
    // assign to variable to make it reflected on filters
    this.checkLocationsBasedOnFilters();
  }

  // change the check property based on filters data
  // assign updated list to variable to relect it on filters
  checkLocationsBasedOnFilters = () => {
    this.aDefectViewModel.aDefectSelectedLocations.length = 0;
    // If a defect widget have filters
    if (this.aDefectFilteredLocations) {
      // iterate to check only filtered locations
      this.locationList.forEach(adefectLocation => {
        if (this.aDefectFilteredLocations.findIndex(x => x.locationId == adefectLocation.id) > -1) {
          adefectLocation.checked = true;
          this.aDefectViewModel.aDefectSelectedLocations.push(adefectLocation)
        }
        else {
          adefectLocation.checked = false;
          this.aDefectViewModel.aDefectSelectedLocations.push(adefectLocation)
        }
      })

      // for A-defect count widget filter select all CB by default to show All selected
      this.aDefectSelection = new SelectionModel(false, [...this.locationList])
    }
    else {
      // iterate to check all locations by default
      this.locationList.forEach(adefectLocation => {
        adefectLocation.checked = true;
        this.aDefectViewModel.aDefectSelectedLocations.push(adefectLocation)
      })
      // for A-defect count widget filter select all CB by default to show All selected
      this.aDefectSelection = new SelectionModel(true, [...this.locationList])
    }
    const selectedLocationsCount = this.aDefectViewModel.aDefectSelectedLocations.filter(location => location.checked).length;
    // if selected location is not equal to actual locations then show selected locations names
    if (this.locationList.length !== selectedLocationsCount) this.aDefectViewModel.isAllADefectSelected = false;
    // if selected location is equal to actual locations then show All keyword
    if (this.locationList.length == selectedLocationsCount) this.aDefectViewModel.isAllADefectSelected = true;

  }

  // set multi fields search values
  prepareSearchParamList(): void {
    let locationIds = [];
    if (this.aDefectViewModel.aDefectSelectedLocations && this.aDefectViewModel.aDefectSelectedLocations.length > 0)
      locationIds = this.aDefectViewModel.aDefectSelectedLocations.map(id => id.id)
    else if (this.aDefectFilteredLocations?.length)
      locationIds = this.aDefectFilteredLocations.map(id => id.locationId)
    this.common.updateSearchParamList(this.queryParamList, "defectInspectionDate", `[${this.aDefectViewModel.aDefectStartDate} TO ${this.aDefectViewModel.aDefectEndDate}]`);
    this.common.updateSearchParamList(this.queryParamList, "defectCode", this.aDefectViewModel.defectCode);
    this.common.updateSearchParamList(this.queryParamList, "defectItemLocationCode", locationIds.join("|"));
  }

  // function to get locations a defect count from api
  getLocationADefects = async (): Promise<void> => {
    this.clearADefectModelValues();
    this.prepareSearchParamList();
    // clear the previous selection
    this.aDefectCBSelection.clear();
    try {
      const result = await this.dashboardService.getLocationDefects(this.queryParamList.searchList, "defectType").pipe(take(1)).toPromise();

      // check if we have required objects before accessing
      if (result?.data?.search?.buckets) {
        // store results in local property
        this.locationsADefectCountData = result.data.search.buckets;
        // map the defects types in model
        this.mapLocationsDefectsFromApiResponse();
        // disable the checkbox in case of zero records
        this.disableDefectTypeCheckbox()
        // re calculate values to find less than 10 % values
        this.recalculateADefectValues();

      }
    }
    catch {
      // Handle errors here

    }

  }
  // map the defects types in model
  mapLocationsDefectsFromApiResponse = () => {
    // loop to find a defect in result set
    this.locationsADefectCountData.forEach(defect => {
      // check if defect have results object
      if (defect?.results) {
        // find defect results object in defect object
        const defectCountObj = defect.results;
        // iterate on the array of results
        // outer loop to iterate the defect object based on results property
        for (const defectObj of defectCountObj) {
          // inner loop to iterate the defect object based on buckets property
          for (const defectBucketObject of defectObj.buckets) {
            this.callDefectTypesMappingMethods(defectBucketObject.results, defectObj.key)
          }
        }
      }
    })
  }

  // this method checks which further method is going to be called
  // based on defect types call its respective method
  callDefectTypesMappingMethods = (defectBucketObject: any, locationId: string) => {
    // map defects of type 'I'
    // pass location Id to be stored in AI list
    // check sub defect of type 'I' is present
    for (const defectBucketDetails of defectBucketObject) {
      if (defectBucketDetails.key == 'i')
        this.mapIDefectTypes(defectBucketDetails, locationId)
      // map defects of type 'E'
      // pass location Id and name to be stored in AE list
      // check sub defect of type 'E' is present
      if (defectBucketDetails.key == 'e')
        this.mapEDefectsTypes(defectBucketDetails, locationId);

      // map defects of type 'T'
      // pass location Id and name to be stored in AT list
      if (defectBucketDetails.key == 't')
        this.mapTDefectsTypes(defectBucketDetails, locationId);

    }
  }

  // map defects of type I in model
  mapIDefectTypes = (subDefectCountObj: any, locationId: string) => {
    // if we have already added this type of code object in our list, we need to remove to incorporate the latest data
    const aiIndex = this.aDefectViewModel.aDefectDataObj.locationADefectCountList.findIndex(x => x.name.includes("AI"))
    if (aiIndex != -1)
      this.aDefectViewModel.aDefectDataObj.locationADefectCountList.splice(aiIndex, 1)
    // add count in existing model field
    this.aDefectViewModel.aDefectListObject.AIDefectCount += subDefectCountObj.count;
    // need to have an object of type ADefectDataList to add name and value of this defect code
    let iDefectDataObj = {} as ADefectDataList;
    // add name in this format i-e {totalCountOfAIDefect} AI {percent} e.g 4 AI (50%)
    iDefectDataObj.name = `${this.aDefectViewModel.aDefectListObject.AIDefectCount} AI`;
    // add value of AI defect
    iDefectDataObj.value = this.aDefectViewModel.aDefectListObject.AIDefectCount;
    this.aDefectViewModel.aDefectDataObj.locationADefectCountList.push(iDefectDataObj);
    // add this location to list
    this.addLocationToAIList(locationId);

  }
  // add location in AI list
  addLocationToAIList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.aDefectViewModel.locationsIdsOfAIDefects.push(locationModel)
  }
  // add location in AT list
  addLocationToATList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.aDefectViewModel.locationsIdsOfATDefects.push(locationModel)
  }
  // add location in AE list
  addLocationToAEList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.aDefectViewModel.locationsIdsOfAEDefects.push(locationModel)
  }

  // map defects of type E in model
  mapEDefectsTypes = (subDefectCountObj: any, locationId: string) => {
    // if we have already added the type of code in our model, we need to remove to incorporate the latest data
    const aeIndex = this.aDefectViewModel.aDefectDataObj.locationADefectCountList.findIndex(x => x.name.includes("AE"))
    if (aeIndex != -1)
      this.aDefectViewModel.aDefectDataObj.locationADefectCountList.splice(aeIndex, 1)
    // add count in existing model field
    this.aDefectViewModel.aDefectListObject.AEDefectCount += subDefectCountObj.count;
    // need to have an object of type ADefectDataList to add name and value of this defect code
    let eDefectDataObj = {} as ADefectDataList;
    // add name in this format i-e {totalCountOfAEDefect} AE {percent} e.g 4 AI (50%)
    eDefectDataObj.name = `${this.aDefectViewModel.aDefectListObject.AEDefectCount} AE`;
    // add value of AE defect
    eDefectDataObj.value = this.aDefectViewModel.aDefectListObject.AEDefectCount;
    this.aDefectViewModel.aDefectDataObj.locationADefectCountList.push(eDefectDataObj);
    this.addLocationToAEList(locationId);
  }

  // map defects of type T in model
  mapTDefectsTypes = (subDefectCountObj: any, locationId: string) => {

    // if we have already added the type +of code in our model, we need to remove to incorporate the latest data
    const atIndex = this.aDefectViewModel.aDefectDataObj.locationADefectCountList.findIndex(x => x.name.includes("AT"))
    if (atIndex != -1)
      this.aDefectViewModel.aDefectDataObj.locationADefectCountList.splice(atIndex, 1)
    // add count in existing model field
    this.aDefectViewModel.aDefectListObject.ATDefectCount += subDefectCountObj.count;
    // need to have an object of type ADefectDataList to add name and value of this defect code
    let tDefectDataObj = {} as ADefectDataList;
    // add name in this format i-e {totalCountOfAEDefect} AT {percent} e.g 4 AI (50%)
    tDefectDataObj.name = `${this.aDefectViewModel.aDefectListObject.ATDefectCount} AT`;
    // add value of AT defect
    tDefectDataObj.value = this.aDefectViewModel.aDefectListObject.ATDefectCount;
    this.aDefectViewModel.aDefectDataObj.locationADefectCountList.push(tDefectDataObj);
    this.addLocationToATList(locationId);
  }
  // purpose of the work is to fulfil the requirement to show wheel of 10 %, where values any of the defect is below 10%
  // function to find the values of a defect widget object with less than 10%
  // if any value is less than 10 percent, it finds the difference and add the remaining percentage
  // add percentage value of each sub defect type at the end of name field to display on chart
  recalculateADefectValues() {
    // check object presence before accessing
    if (this.aDefectViewModel.aDefectDataObj && this.aDefectViewModel.aDefectDataObj.locationADefectCountList.length > 0) {
      // calculate the sum of all the values of list
      const valueFieldSum = this.aDefectViewModel.aDefectDataObj.locationADefectCountList.reduce((sum, { value }) => sum + value, 0);
      // calculate the 10% of sum value
      // 0.01 is to find 1% of sum value
      // 10 is to multiple 1% to get 10% value
      const tenPercentOfValue = valueFieldSum * (0.01 * 10);
      // iterate to look for each value field
      this.aDefectViewModel.aDefectDataObj.locationADefectCountList.forEach(items => {
        // add percentage at the end of each name field of chart
        items.name = `${items.name} (${Math.round(this.calculatePercentage(items.value, valueFieldSum))}%)`;
        // check if the current value is less than 10 % value 
        if (items.value < tenPercentOfValue) {
          // need to find the difference between these values
          const findDiffInValues = tenPercentOfValue - items.value;
          // sum the difference to make value equal to 10%
          items.value += findDiffInValues;
        }
      })
    }
  }


  // extract location Name from A defect location list by locationId
  extractAdefectLocationName = (locationId: string) => this.locationList.find(location => location.id === locationId).name

  // open A-defect filter 
  openADefectFilter() {
    this.filterTemplateDialogRef = this.dialogService.open(this.aDefectfilterTemplateRef, {
      showCloseIcon: true
    })
  }
  // create a copy of LocationsData for filtering
  assignCopy() {
    this.filteredADefectLocation = Object.assign([], this.locationList)
  }
  //export a defect widget filtered data to csv 
  defectCountExportToCSV = async (): Promise<void> => {
    const aDefectLocationsIds = this.aDefectViewModel.aDefectSelectedLocations
      ? this.aDefectViewModel.aDefectSelectedLocations.map((id) => id.id) : [];

    const variables = {
      searchList: [],
      order: "ASC",
      sortName: "_id",
      from: 0,
      size: 100,
      dueFrom: this.aDefectViewModel.aDefectStartDate,
      dueTo: this.aDefectViewModel.aDefectEndDate,
      locationIds: aDefectLocationsIds,
      defectTypes: "A",
      itemGroupIds: this.itemGroupService.getSelectedItemGroupsIds()
    }
    try {
      const items = await this.itemsService.getItemsWithDefects(variables, false).pipe(take(1)).toPromise();
      if (items?.data?.itemsWithDefects?.results) {
        this.locationItemViewModel.itemLocations.items = items.data.itemsWithDefects.results;
        for (let i = 1; i < Math.ceil(items.data.itemsWithDefects.totalCount / 100); i++) {
          const responseList = await from(this.itemsService.getItemsWithDefectsByPromise(variables)).toPromise();
          if (responseList?.data?.itemsWithDefects?.results) {
            this.locationItemViewModel.itemLocations.items = [...this.locationItemViewModel.itemLocations.items, ...responseList.data.itemsWithDefects.results];
          }
        }
        this.exportService.exportToCsv(this.locationItemViewModel.itemLocations.items, this.aDefectViewModel.exportFileName, this.aDefectViewModel.options, this.locationList, "ADefect");
      }
    } catch {
      // Handle errors here
    }
  };

  // concatinates multiple values as comma separated string
  toTextMulti(value: string | string[], separator: string): string {
    // if value exists
    if (value) {
      // if its an array, then join its items as string
      if (Array.isArray(value)) {
        return value.join(separator);
      } else {
        // return string
        return value;
      }
    }
    // default return is empty string
    return '';
  }
  // on window width change adjust pie-chart accordingly
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.aDefectViewModel.isDesktop) {
      // under normal conditions i.e. A-defect is collapsed adjust pie-chart width dividing by 3.5 of window width, the height is fixed to 320px
      if (!this.aDefectViewModel.isPieChartContainerExpanded && window.innerWidth > 1100) this.view = [window.innerWidth / 4.1, 250];
      // once modal is expanded use window width for pie-chart. The height is fixed to 320px
      if (this.aDefectViewModel.isPieChartContainerExpanded) this.view = [window.innerWidth, 320];
      // upon map container expand the innerwidth of Locations array is set to 53%
      else this.aDefectViewModel.innerWidthofScreen = window.innerWidth * 0.53;
    }
    else {
      this.view = [window.innerWidth, 320];
      this.aDefectViewModel.innerWidthofScreen = window.innerWidth - 99;
    }
  }
  // calculate percentage function accepts 2 values.
  // numerator => the upper value in percentage formula
  // denominator => the lower value in percentage formula
  calculatePercentage = (numerator: number, denominator: number) => numerator / denominator * 100;
  // on click over expand/collapse icon in A-defect pie-chart container expand modal
  expandCollapsePieChartContainer(): void {
    // toggle this bit
    this.aDefectViewModel.isPieChartContainerExpanded = !this.aDefectViewModel.isPieChartContainerExpanded;
    // call this function so that pie chart gets width according to window size
    this.onResize()
  }
  // start date from filter child that updates local variable upon Apply
  defectStartDate(startDate: string) {
    this.aDefectViewModel.aDefectStartDate = startDate
  }
  // end date from filter child that updates local variable upon Apply
  defectEndDate(endDate: string) {
    this.aDefectViewModel.aDefectEndDate = endDate
  }
  // the difference between the start and end date that gets emitted once Apply is clicked
  differenceBetweenDate(diff) {
    this.aDefectViewModel.differenceBetweenDates = diff;
    this.filterService.aDefectFilter.differenceBetweenDates = diff;
  }
  // the selected locations of A-defect count filter
  selectedFilteredADefectLocations(locations) {
    this.aDefectFilteredLocations = locations;
    // save selected locations
    this.aDefectViewModel.aDefectSelectedLocations = locations.filter(locationObj => locationObj.checked).slice()
    // get names and ids from list of Adefect locations
    this.aDefectViewModel.aDefectSelectedLocationsIds = locations.filter(locationObj => locationObj).slice().map(name => name.id)
    this.aDefectViewModel.aDefectSelectedLocationsNames = locations.filter(locationObj => locationObj).slice().map(name => name.name)
    //  set aDefect filters locations data
    this.setADefectLocationDetailFilters()

    // if selected location is not equal to actual locations then show selected locations names
    if (this.locationList.length !== this.aDefectViewModel.aDefectSelectedLocationsNames.length) this.aDefectViewModel.isAllADefectSelected = false;
    // if selected location is equal to actual locations then show All keyword
    if (this.locationList.length == this.aDefectViewModel.aDefectSelectedLocationsNames.length) this.aDefectViewModel.isAllADefectSelected = true;
    // if selected locations are greater than 3 then show 1st 3 names and add +rest of locations
    if (this.aDefectViewModel.aDefectSelectedLocationsNames.length > 3) {
      this.aDefectViewModel.aDefectLocationNames = this.aDefectViewModel.aDefectSelectedLocationsNames.slice(0, 3)
      const remainingCount = this.aDefectViewModel.aDefectSelectedLocationsNames.length - this.aDefectViewModel.aDefectLocationNames.length
      this.aDefectViewModel.aDefectLocationNamesAndCount = this.toTextMulti(this.aDefectViewModel.aDefectLocationNames, ", ") + ' + ' + remainingCount;
    }
    // if selected locations are less than 3 then just show there names
    else {
      this.aDefectViewModel.aDefectLocationNamesAndCount = this.toTextMulti(this.aDefectViewModel.aDefectSelectedLocationsNames, ", ")
    }
  }
  // aDefectFilter locations details 
  setADefectLocationDetailFilters() {
    this.filterService.aDefectFilter.locationDetailList = { locationId: this.aDefectViewModel.aDefectSelectedLocationsIds, locationName: this.aDefectViewModel.aDefectSelectedLocationsNames }
  }
  // change the dropdown showPrevious binding value to the selected value
  selectedShowPreviousValue = (aDefectDropdownValue) => {
    this.aDefectViewModel.showPrevious = aDefectDropdownValue
    this.filterService.aDefectFilter.showPrevious = aDefectDropdownValue
  }
  // change the start date binding value to the selected value
  selectedStartDate = (startDate) => {
    this.aDefectViewModel.aDefectStartDatePie = startDate
    this.filterService.aDefectFilter.startDate = startDate
  }
  // change the end date binding value to the selected value
  selectedEndDate = (endDate) => {
    this.aDefectViewModel.aDefectEndDatePie = endDate
    this.filterService.aDefectFilter.endDate = endDate
  }
  /** Whether the number of selected elements matches the total number of rows for a Defect checkboxes. */
  get isAllADefectSelected(): boolean {
    // local variable containing num of rows which are not disable
    let numRows;
    // local variable with number of rows selected
    const numSelected = this.aDefectCBSelection.selected.length;
    if (numSelected > 1)
      numRows = this.aDefectCB.filter(x => !x.disable).length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection for a Defect checkboxes. */
  toggleAllADefectCB() {
    this.isAllADefectSelected
      ? this.aDefectCBSelection.clear()
      : this.aDefectCB.map((row) => {
        if (!row.disable)
          this.aDefectCBSelection.select(row)
      }
      );
  }
  /* A Defects navigation to Items work */
  viewDefectsItems = () => {
    // prepare the filter modal, which needs to be sent in navigation 
    // in state variable
    const defectsFilterObj = this.prepareLocationDefectsFilterSearchModel();
    // Creating object to save new values of this object
    this.filterService.aDefectFilter.endDate = defectsFilterObj.endDate
    this.filterService.aDefectFilter.startDate = defectsFilterObj.startDate
    this.filterService.aDefectFilter.locationDetailList = defectsFilterObj.allSelectedLocationsList
    this.filterService.aDefectFilter.showPrevious = this.aDefectViewModel.showPrevious
    this.filterService.aDefectFilter.isDateRangeDisabled = this.aDefectViewModel.isDateRangeDisabled
    this.filterService.aDefectFilter.differenceBetweenDates = this.aDefectViewModel.differenceBetweenDates;
    // navigate with filter object
    this.router.navigateByUrl(`${AppRoutes.clientLocationsItems}?redirected=dashboard`, { state: defectsFilterObj });

  }
  // prepare the model to be used in state variable in navigation
  // When we click on View Items on A-defect widget
  // we need to pass 4 variables to Items page. 1. Start date, 2. End date, 3. List of selected Locations, 4. Selected Defect types
  prepareLocationDefectsFilterSearchModel = (): LocationDefectListSearchModel => {
    let locationsOfSelectedDefects = [];
    // array containing all defect codes selected
    let selectedDefectCodes: string[] = [];
    // model object having all 4 properties
    let defectsFilterModel = {} as LocationDefectListSearchModel;
    // initialize the list having location details to display in tags
    defectsFilterModel.locationDetailList = [];
    // initialize the list having location details of all selected locations
    defectsFilterModel.allSelectedLocationsList = [];
    // add start date in model property
    defectsFilterModel.endDate = this.aDefectViewModel.aDefectStartDate
    // add end date in model property
    defectsFilterModel.startDate = this.aDefectViewModel.aDefectEndDate;
    // add selected defect type from A defect widget
    if (this.aDefectCBSelection) {
      // iterate with all the selected rows of defect types present in selection model
      for (let selectedRow of this.aDefectCBSelection.selected) {
        // add value in local array variable based on defect code variable
        switch (selectedRow.value) {
          case "AE (A-Enforcing)": {
            // if AE is checked, then add particularly its locations only
            this.aDefectViewModel.locationsIdsOfAEDefects.forEach(location => locationsOfSelectedDefects.push(location))
            // push E in array
            selectedDefectCodes.push("E"); break;
          }
          case "AI (A-Immediate)": {
            // if AI is checked, then add particularly its locations only
            this.aDefectViewModel.locationsIdsOfAIDefects.forEach(location => locationsOfSelectedDefects.push(location))
            // push I in array
            selectedDefectCodes.push("I"); break;
          }
          case "AT (A-Timed)": {
            // if AT is checked, then add particularly its locations only
            this.aDefectViewModel.locationsIdsOfATDefects.forEach(location => locationsOfSelectedDefects.push(location))
            // push T in array
            selectedDefectCodes.push("T"); break;
          }
          default: break;
        }
      }
    }
    // concat all the defect codes with comma
    defectsFilterModel.locationDefectTypes = selectedDefectCodes.join(',');
    // fetch properties to be passed to items screen
    defectsFilterModel = this.prepareADefectsLocationList(defectsFilterModel, locationsOfSelectedDefects)
    // return the prepared model
    return defectsFilterModel;
  }
  // make a list of properties which are required to pass to items screen
  prepareADefectsLocationList = (defectsFilterModel: LocationDefectListSearchModel, locationsOfSelectedDefects: any[]): LocationDefectListSearchModel => {

    // if selection model is present
    if (this.aDefectViewModel.aDefectSelectedLocations) {
      // iterate with all the selected rows present in selection model
      for (let selectedRow of this.aDefectViewModel.aDefectSelectedLocations) {
        // check if row is selected
        if (selectedRow.checked) {
          // create object to have properties of location details
          let locationModel = {} as LocationDetailSearchModel;
          locationModel.locationId = selectedRow.id; // id of location
          locationModel.locationName = selectedRow.name; // name of location
          if (locationsOfSelectedDefects.some(row => row.locationId == selectedRow.id)) {
            // add details in list to pass to items to show in tag
            defectsFilterModel.locationDetailList.push(locationModel);
          }
          // add details in list to store for reload filters later
          defectsFilterModel.allSelectedLocationsList.push(locationModel)
        }
      }
    }
    // return the prepare model
    return defectsFilterModel;
  }
  // disable the checkboxes based on data returned from api
  disableDefectTypeCheckbox = () => {
    // fetch record of each category
    const AERecord = this.aDefectCB.find(defect => defect.value == "AE (A-Enforcing)");
    const AIRecord = this.aDefectCB.find(defect => defect.value == "AI (A-Immediate)");
    const ATRecord = this.aDefectCB.find(defect => defect.value == "AT (A-Timed)");
    // if AE count is zero, AE checkbox should be disabled
    if (this.aDefectViewModel.aDefectListObject.AEDefectCount == 0)
      AERecord.disable = true;
    else
      // if AE count is not zero, AE checkbox should be enabled
      AERecord.disable = false;
    // if AI count is zero, AI checkbox should be disabled
    if (this.aDefectViewModel.aDefectListObject.AIDefectCount == 0)
      AIRecord.disable = true;
    else
      // if AI count is not zero, AI checkbox should be enabled
      AIRecord.disable = false;
    // if AT count is zero, AT checkbox should be disabled
    if (this.aDefectViewModel.aDefectListObject.ATDefectCount == 0)
      ATRecord.disable = true;
    else
      // if AT count is not zero, AT checkbox should be enabled
      ATRecord.disable = false;

  }
  // function for mapping of output into view model
  isDateRangeDisabled = (isDateRangeDisabled: boolean) => {
    this.aDefectViewModel.isDateRangeDisabled = isDateRangeDisabled;
    this.filterService.aDefectFilter.isDateRangeDisabled = isDateRangeDisabled
  }

}
