<router-outlet></router-outlet>
<ng-template #template>
    <div nxRow>
        <nx-icon name="product-danger-f" size="s" class="nx-margin-top-xs idle-icon"></nx-icon>
        &nbsp;
        <div class="nx-margin-top-3xs">
            <h2 nxHeadline="subsection-medium">Your session is about to expire.</h2>
        </div>
    </div>
    <div nxRow>
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-2m">You have been inactive in your account for
            29 minutes. For your security we will automatically log out of Inspection Hub in
            <strong class="idle-icon">{{idleStateTimer}}</strong> second(s).
        </h3>
    </div>
    <div nxModalActions>
        <button nxModalClose class="nx-margin-bottom-0 nx-margin-right-xs " (click)="logout()"
            nxButton="small secondary" type="button">Log out</button>
        <button nxModalClose class="nx-margin-bottom-0 " nxButton="small" type="button" (click)="reset()">Stay logged
            in</button>


    </div>
</ng-template>

<ng-template #maxActivityModaltemplate>
    <div nxRow>
        <nx-icon name="product-danger-f" size="s" class="nx-margin-top-xs idle-icon"></nx-icon>
        &nbsp;
        <div>
            <h2 nxHeadline="subsection-medium">Your session is about to expire.</h2>
        </div>
    </div>
    <div nxRow>
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-2m">You have been active in your account for
            7 hours 59 minutes. For your security we will automatically log you out of Inspection Hub in
            <strong class="idle-icon">{{maxActivityCountdown}}</strong> seconds.
        </h3>
    </div>
    <div class="center-align">
        <button nxModalClose class="nx-margin-bottom-0 nx-margin-right-xs " (click)="logout()"
            nxButton="small secondary" type="button">Log out</button>
    </div>
</ng-template>