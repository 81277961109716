import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth0client.service';
import { RoleService } from './role.service';

// availabe for use as injectable anywhere in the solution
@Injectable({
  providedIn: 'root'
})

// Generic role guard to allow access based on list of roles provided in route
export class RoleGuard implements CanActivate {

  // constructor with required services
  constructor(private roleService: RoleService, private auth: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // returns observable boolean
    return this.auth
      .getIdToken$
      .pipe(map(iTkn => {
        // checking if have value in token
        if (iTkn) {
          // getting expected roles value from route data
          const { expectedRoles } = route.data;
          // store access value to return
          let hasAccess = false;
          // if route has expected roles then
          if (expectedRoles) {
            // check if current user's role from iTkn is present in allowed list of roles
            hasAccess = this.roleService.hasValidRole(iTkn, expectedRoles);
          }
          // if role is not listed in route data
          if (!hasAccess) {
            // redirect user to root route
            this.router.navigate(['/']);
          }
          // return access value anyway
          return hasAccess;
        }
      }
      ));
  }
}
