<app-loading></app-loading>

<div class="wrapper">
    <div class="nx-margin-s margin-box">
        <banner-notification messageContext="error" *ngIf="this.userRegistrationViewModel.showTopErrorBanner"
            message="Please note: These are fields that require a value before the new user can be successfully saved.">
        </banner-notification>

        <ng-container *ngTemplateOutlet="formTemplates"></ng-container>
        <div *ngIf="!userRegistrationViewModel.isDesktop">
            <!--Subscriptions section-->
            <div class="nx-margin-top-s">
                <div class="permissionPanel">
                    <p>Subscriptions</p>
                </div>

                <div nxRow class="nx-margin-left-xs nx-margin-y-s">
                    <nx-switcher [checked]="true" labelPosition="left" labelSize="small"
                        [(ngModel)]="userRegistrationViewModel.isEReportingEnabled" name="eReporting">
                        eReporting
                    </nx-switcher>
                    &nbsp;
                    <info-icon class="nx-margin-top-1m" [infoMessage]="userRegistrationViewModel.infoPopupMessage"
                        [height]="userRegistrationViewModel.popoverHeight"
                        [width]="userRegistrationViewModel.popoverWidth">
                    </info-icon>
                </div>
            </div>

            <div class="permissionPanel nx-margin-top-s">
                <p id="permissionPanel">Permissions</p>
            </div>
            <ng-container *ngTemplateOutlet="groupItems"></ng-container>
            <div class="nx-margin-top-s">
                <nx-accordion>
                    <nx-expansion-panel>
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title>Site locations</nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxRow class="nx-margin-left-s">
                            <div nxcol="12,12">
                                <banner-notification messageContext="info"
                                    *ngIf="!this.userRegistrationViewModel.showLocationsErrorBanner"
                                    message="Please select the Site Location(s) that the user should have access to.">
                                </banner-notification>
                            </div>
                        </div>
                        <div nxRow class="nx-margin-left-s">
                            <div nxcol="12,12">
                                <banner-notification id="locationError" messageContext="error"
                                    *ngIf="this.userRegistrationViewModel.showLocationsErrorBanner"
                                    message="Please note: You have to select at least one permission to continue.">
                                </banner-notification>
                            </div>

                        </div>
                        <div nxLayout='grid' class="nx-margin-top-s">
                            <div nxRow>
                                <div nxCol="12,12">
                                    <nx-formfield nxLabel="Name">
                                        <input name="locationName" nxInput
                                            placeholder="Please enter three characters at least" id="locationName"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationName" tabindex="1" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12">
                                    <nx-formfield nxLabel="Address">
                                        <input name="locationAddress" nxInput
                                            placeholder="Please enter three characters at least" id="locationAddress"
                                            tabindex="2" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationAddress" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12">
                                    <nx-formfield nxLabel="Postcode">
                                        <input name="locationPostcode" nxInput
                                            placeholder="Please enter two characters at least" id="locationPostcode"
                                            tabindex="3" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationPostcode" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12" class="center"> <button id="search" nxButton="primary small"
                                        type="button" [disabled]="locationViewModel.searchDisabled"
                                        (click)="search()">Search</button>
                                    &nbsp;
                                    <button id="clear" nxButton="tertiary small-medium" (click)="clearSearch()"
                                        type="button">Clear</button>
                                </div>




                            </div>

                        </div>

                        <div nxRow class="nx-margin-top-xs table-container">
                            <div nxCol="12">
                                <ng-container>
                                    <div nxHeaderCell id="header" class="nx-table-cell--checkbox"
                                        aria-label="Row selection">
                                        <nx-checkbox (checkedChange)="toggleAll()"
                                            aria-label="Toggle selection of all rows"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                                            *ngIf="locationViewModel.resultCount > 0">Locations
                                            List
                                        </nx-checkbox>
                                        <div *ngIf="locationViewModel.resultCount == 0">Locations
                                            List</div>
                                        <hr />
                                    </div>


                                    <div nxLayout="grid nogutters" nxTableRow selectable
                                        *ngFor="let location of locationViewModel.locations"
                                        [selected]="selection.isSelected(location)" selectable
                                        (select)="selection.toggle(location)">
                                        <div nxRow>
                                            <div nxCol="1" class="nx-margin-top-l">
                                                <nx-checkbox (checkedChange)="toggleSelected(location)"
                                                    aria-label="Toggle row selection"
                                                    [checked]="selection.isSelected(location)">
                                                </nx-checkbox>
                                            </div>
                                            <div nxCol="11">
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">
                                                        Name
                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="8">

                                                        {{location?.name}}
                                                    </div>
                                                </div>
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">

                                                        Address

                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="8">

                                                        {{location?.postalAddress?.address1 !="" &&
                                                        location?.postalAddress?.address1 != null ?
                                                        location?.postalAddress?.address1 : ""}}
                                                        {{location?.postalAddress?.address2 !="" &&
                                                        location?.postalAddress?.address2 != null ? ", " +
                                                        location?.postalAddress?.address2 : ""}}
                                                        {{location?.postalAddress?.address3 !="" &&
                                                        location?.postalAddress?.address3 != null ? ", " +
                                                        location?.postalAddress?.address3 : ""}}
                                                        {{location?.postalAddress?.address4 !="" &&
                                                        location?.postalAddress?.address4 != null ? ", " +
                                                        location?.postalAddress?.address4 : ""}}
                                                        {{location?.postalAddress?.address5 !="" &&
                                                        location?.postalAddress?.address5 != null ? ", " +
                                                        location?.postalAddress?.address5 : ""}}
                                                        {{location?.postalAddress?.address6 !="" &&
                                                        location?.postalAddress?.address6 != null ? ", " +
                                                        location?.postalAddress?.address6 : ""}}
                                                    </div>
                                                </div>
                                                <div nxRow>
                                                    <div class="formatHeading" nxCol="3">

                                                        Postcode

                                                    </div>
                                                    &nbsp;
                                                    <div nxCol="8">

                                                        {{location?.postalAddress?.postcode}}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr />
                                    </div>
                                    <div class="infinite-scroller" #theLastItem></div>
                                    <div *ngIf="locationViewModel.locations.length === 0">
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Location Name
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Address
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                        <div nxRow>
                                            <div class="formatHeading" nxCol="4">
                                                Postcode
                                            </div>
                                            <div nxCol="8">
                                                -
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
            </div>
        </div>

        <div *ngIf="userRegistrationViewModel.isDesktop">
            <!--Subscriptions section-->
            <div class="nx-margin-top-s">
                <div class="permissionPanel">
                    <p>Subscriptions</p>
                </div>

                <div nxRow class="nx-margin-left-s nx-margin-y-s">
                    <nx-switcher [checked]="true" labelPosition="left" labelSize="small"
                        [(ngModel)]="userRegistrationViewModel.isEReportingEnabled" name="eReporting">
                        eReporting
                    </nx-switcher>
                    &nbsp;
                    <info-icon class="nx-margin-top-1m" [infoMessage]="userRegistrationViewModel.infoPopupMessage"
                        [height]="userRegistrationViewModel.popoverHeight"
                        [width]="userRegistrationViewModel.popoverWidth">
                    </info-icon>
                </div>
            </div>
            <div class="permissionPanel nx-margin-top-s">
                <p id="permissionPanel">Permissions</p>
            </div>
            <ng-container *ngTemplateOutlet="groupItems"></ng-container>
            <div class="nx-margin-m">
                <nx-accordion>
                    <nx-expansion-panel>
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title>Site locations</nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxRow class="nx-margin-left-2m">
                            <div nxcol="6">
                                <banner-notification messageContext="info" *ngIf="!this.showLocationsErrorBanner"
                                    message="Please select the Site Location(s) that the user should have access to.">
                                </banner-notification>

                                <banner-notification id="locationError" messageContext="error"
                                    *ngIf="this.showLocationsErrorBanner"
                                    message="Please note: You have to select at least one permission to continue.">
                                </banner-notification>
                            </div>
                            <div nxcol="6">&nbsp;</div>
                        </div>
                        <div nxLayout='grid' class="nx-margin-top-s">
                            <div nxRow>
                                <div nxCol="12,12,3">
                                    <nx-formfield nxLabel="Name">
                                        <input name="locationName" nxInput
                                            placeholder="Please enter three characters at least" id="locationName"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationName" tabindex="1" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,3">
                                    <nx-formfield nxLabel="Address">
                                        <input name="locationAddress" nxInput
                                            placeholder="Please enter three characters at least" id="locationAddress"
                                            tabindex="2" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationAddress" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,3">
                                    <nx-formfield nxLabel="Postcode">
                                        <input name="locationPostcode" nxInput
                                            placeholder="Please enter two characters at least" id="locationPostcode"
                                            tabindex="3" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="locationViewModel.locationPostcode" />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,3" nxAlignSelf="start,start,end" class="nx-margin-bottom-m ">
                                    <button id="search" class="alignButtons" nxButton="primary small-medium"
                                        type="button" [disabled]="locationViewModel.searchDisabled"
                                        (click)="search()">Search</button>
                                    &nbsp;
                                    <button type="button" class="alignButtons" nxButton="tertiary small-medium"
                                        id="clear" (click)="clearSearch()" type="button">Clear</button>
                                </div>

                            </div>

                        </div>
                        <div class="table-container">
                            <table nxTable class="nx-margin-top-xs nx-margin-left-2m">
                                <caption></caption>
                                <thead>
                                    <tr nxTableRow>
                                        <th nxHeaderCell id="header" class="nx-table-cell--checkbox"
                                            aria-label="Row selection">
                                            <nx-checkbox (checkedChange)="toggleAll()"
                                                aria-label="Toggle selection of all rows"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                *ngIf="locationViewModel.resultCount > 0">
                                            </nx-checkbox>
                                        </th>
                                        <th scope="col" nxHeaderCell>Location name</th>
                                        <th scope="col" nxHeaderCell>Address</th>
                                        <th scope="col" nxHeaderCell>Postcode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container>
                                        <tr nxTableRow selectable *ngFor="let location of locationViewModel.locations"
                                            [selected]="selection.isSelected(location)" selectable
                                            (select)="selection.toggle(location)">
                                            <td nxTableCell class="nx-table-cell--checkbox">
                                                <nx-checkbox (checkedChange)="toggleSelected(location)"
                                                    aria-label="Toggle row selection"
                                                    [checked]="selection.isSelected(location)">
                                                </nx-checkbox>
                                            </td>
                                            <td nxTableCell>{{location?.name}}</td>
                                            <td nxTableCell>{{location?.postalAddress?.address1 !="" &&
                                                location?.postalAddress?.address1 != null ?
                                                location?.postalAddress?.address1 : ""}}
                                                {{location?.postalAddress?.address2
                                                !="" &&
                                                location?.postalAddress?.address2 != null ? ", " +
                                                location?.postalAddress?.address2 : ""}}
                                                {{location?.postalAddress?.address3 !="" &&
                                                location?.postalAddress?.address3 != null ? ", " +
                                                location?.postalAddress?.address3 : ""}}
                                                {{location?.postalAddress?.address4
                                                !="" &&
                                                location?.postalAddress?.address4 != null ? ", " +
                                                location?.postalAddress?.address4 : ""}}
                                                {{location?.postalAddress?.address5 !="" &&
                                                location?.postalAddress?.address5 != null ? ", " +
                                                location?.postalAddress?.address5 : ""}}
                                                {{location?.postalAddress?.address6
                                                !="" &&
                                                location?.postalAddress?.address6 != null ? ", " +
                                                location?.postalAddress?.address6 : ""}}</td>
                                            <td nxTableCell>{{location?.postalAddress?.postcode}}</td>
                                        </tr>
                                        <div class="infinite-scroller" #theLastItem></div>
                                        <tr *ngIf="locationViewModel.locations.length === 0">
                                            <td nxTableCell><i class="fas fa-minus" aria-hidden="true"></i></td>
                                            <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                            </td>
                                            <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                            </td>
                                            <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
            </div>
        </div>



        <div nxRow class="nx-margin-top-xs">
            <div class="alignCenter">
                <button id="btnSave" nxButton="primary small"
                    [disabled]="!(userRegFormGroup.valid && this.userRegistrationViewModel.itemGroupModel.length > 0)"
                    (click)="submitForm()" type="submit">Save</button>
                &nbsp;
                <button type="reset" id="btnCancel" nxButton="secondary small" type="button"
                    (click)="openCancelTemplate()">Cancel</button>
            </div>
        </div>
    </div>
</div>


<ng-template #template let-data>
    <div nxModalContent id="user-registration-info-popup">
        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-3m">Info</h3>
        <p nxCopytext>{{data.body}}</p>
    </div>
</ng-template>

<ng-template #cancelTemplate>
    <div nxModalContent>

        <div *ngIf="userRegistrationViewModel.isDesktop">
            <h1 nxHeadline="subsection-large" class="nx-margin-bottom-3m">Cancel new user registration</h1>
        </div>
        <div *ngIf="!userRegistrationViewModel.isDesktop">
            <h2 nxHeadline="subsection-large" class="nx-margin-bottom-3m">Cancel new user registration</h2>
        </div>

        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">By cancelling the new user
            registration any unsaved changes
            will be lost.</h3>
        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">Are you sure you wish to cancel?</h3>

    </div>
    <div nxModalActions>
        <button nxModalClose class="nx-margin-bottom-0 nx-margin-right-xs" (click)="redirectToLocations()"
            nxButton="small" type="button">Yes</button>
        <button nxModalClose class="nx-margin-bottom-0 " nxButton="small secondary" type="button">No</button>

    </div>

</ng-template>

<ng-template #formTemplates>
    <form [formGroup]="userRegFormGroup" class="nx-margin-top-s">
        <div class="left">
            <h3 nxHeadline="subsection-large">Employee details</h3>
        </div>
        <div class="align-input-parent">
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Title">
                        <nx-dropdown formControlName="titles">
                            <nx-dropdown-item *ngFor="let titles of userRegistrationViewModel.userTitles | async"
                                [nxValue]="titles.key">{{titles.val}}</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError> Your title is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="First name"> <input name="firstName" nxInput formControlName="firstName" />
                        <nx-error nxFormfieldError> Your first name is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
        </div>
        <div class="align-input-parent">
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Last name"> <input name="lastName" nxInput formControlName="lastName" />
                        <nx-error nxFormfieldError> Your last name is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Email address"> <input name="email" nxInput formControlName="email" />
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(1)">
                        </nx-icon>
                        <nx-error nxFormfieldError *ngIf="hasError('email', 'required')"> Your email is missing.
                        </nx-error>
                        <nx-error nxFormfieldError *ngIf="hasError('email', 'email')">
                            Please enter a valid email address.
                        </nx-error>
                    </nx-formfield>
                </div>
            </div>
        </div>
        <div class="align-input-parent">
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Contact no. (Direct dial only)"> <input name="contactNo" nxInput
                            formControlName="contactNo" placeholder="e.g  01483232222" />
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(2)"> </nx-icon>
                        <nx-error nxFormfieldError> Your contact no is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Mobile no."> <input name="mobileNo" nxInput formControlName="mobileNo"
                            placeholder="e.g  07768222333" />
                    </nx-formfield>
                </div>
            </div>
        </div>

        <div class="left">
            <h3 nxHeadline="subsection-large">Organisation details</h3>
        </div>
        <div class="align-input-parent">
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Company"> <input name="company" nxInput disabled readonly
                            formControlName="company" />
                        <nx-error nxFormfieldError> Your Company is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Main employee location">
                        <nx-dropdown [nxShowFilter]="true" formControlName="mainEmployeeLocation">
                            <nx-dropdown-item *ngFor="let location of employeeLocations.employeeLocations"
                                [nxValue]="location.id">
                                {{location.name}}
                            </nx-dropdown-item>
                        </nx-dropdown>
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(3)"> </nx-icon>
                        <nx-error nxFormfieldError> Your main employee location is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
        </div>
        <div class="align-input-parent">
            <div nxRow class="align-input-child">
                <div nxCol="12,12">
                    <nx-formfield nxLabel="Role">
                        <input name="role" nxInput disabled readonly formControlName="role" />
                        <nx-icon nxFormfieldAppendix name="info-circle-o" nxPopoverTrigger="hover"
                            (click)="displayInfoModal(4)"> </nx-icon>
                        <nx-error nxFormfieldError> Your role location is missing. </nx-error>
                    </nx-formfield>
                </div>
            </div>
        </div>


    </form>
</ng-template>

<!-- assigned group items -->
<ng-template #groupItems>
    <div class="align-location-icon-text">
        <nx-icon name="location-o" outline="true" size="s" class="blueColor"></nx-icon>
        <h2>Assigned Item group(s)</h2>
    </div>
    <div nxRow>
        <div [nxCol]="userRegistrationViewModel.isDesktop ? '6': '12'"
            [ngClass]="{'item-groups-col-size' : userRegistrationViewModel.isDesktop}">
            <nx-formfield appearance="outline" nxFloatLabel="always"
                *ngIf="!userRegistrationViewModel.isSingleItemGroup">
                <nx-multi-select placeholder="Choose options" [options]="userRegistrationViewModel.itemGroupsList"
                    [(ngModel)]="userRegistrationViewModel.itemGroupModel" selectLabel="name" selectValue="id"
                    [formControl]="itemGroupsFormcontrol" name="control"></nx-multi-select>
                <nx-icon nxFormfieldAppendix name={{itemGroupIconName}} size="s" class="blueColor"
                    (mouseover)='mouseOver()' (mouseout)='mouseOut()'
                    [nxPopoverTriggerFor]="itemGroupPopoverBottomAnchor"></nx-icon>
            </nx-formfield>
            <nx-formfield *ngIf="userRegistrationViewModel.isSingleItemGroup">
                <input nxInput type="text" [placeholder]="userRegistrationViewModel.singleItemGroupName" disabled />
                <nx-icon nxFormfieldAppendix name={{itemGroupIconName}} size="s" class="blueColor"></nx-icon>
            </nx-formfield>
            <nx-error
                *ngIf="!userRegistrationViewModel.isSingleItemGroup && itemGroupsFormcontrol && itemGroupsFormcontrol.errors && (itemGroupsFormcontrol.dirty || itemGroupsFormcontrol.touched)">
                Select at least one item group</nx-error>
        </div>
    </div>
</ng-template>

<nx-popover #itemGroupPopoverBottomAnchor>
    <div class="width-tooltip">{{userRegistrationViewModel.dummyText}}</div>
</nx-popover>