import { ApolloQueryResult } from "apollo-client";
import { BaseResponse } from "./BaseResponse.Model";
import { Location } from 'src/app/schemas/locations';
// class to handle location details gql response
export class LocationDetailResponse extends BaseResponse {
    // hold list of location details
    locationDetails: Location[] = [];
    // total count of results
    totalCount: number = 0;
    // to hold the status of data prescence of results
    hasResultsProperty: boolean = true;
    // to hold the status of data prescence of results
    constructor(responseApi: ApolloQueryResult<any>) {
        super(responseApi);
        // setting properties from response
        if (responseApi && responseApi.data && responseApi.data.search) {
            this.locationDetails = this.getResponseProperty("results", responseApi.data.search);
            this.totalCount = this.getResponseProperty("totalCount", responseApi.data.search);
        }
        else
            this.hasResultsProperty = false;
    }
}