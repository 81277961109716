import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
   //Responsive view true for desktop , false for mobile
   isDesktop: boolean;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
     //set banner text
     this.layoutService.setBanner(BannerDetail.calendarBannerText);

     //subscribing to device view
     this.layoutService.currentDevice.subscribe(result => this.isDesktop = result)
  }

}
