import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationViewModel } from '../../locations/locations.viewModel';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { InfoModalType } from 'src/app/shared/enums/infoModelType';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/graphql/user.Service';
import { Employee, UserItemGroupPermissions, EmployeeLocationList } from 'src/app/schemas/users';
import { shareReplay } from 'rxjs/operators';
import { LocationService } from 'src/app/services/graphql/location.service'
import { UserRegistrationViewModel } from './user-registration-viewModel';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { LayoutService } from 'src/app/services/layout-service';
import { ErrorModel } from 'src/app/shared/models/error.Model';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReferenceDataService } from 'src/app/services/graphql/reference-data.service';

@UntilDestroy()
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  // setup template for info modal popup
  @ViewChild('template') templateRef!: TemplateRef<any>;
  // modal popup refrence
  dialogRef!: NxModalRef<any, any>;
  // formGroup for input data/validation
  userRegFormGroup: FormGroup;
  // item search model instance for model binding
  locationViewModel: LocationViewModel = new LocationViewModel();
  // user reg view model
  userRegistrationViewModel: UserRegistrationViewModel = new UserRegistrationViewModel();
  // selection for checkboxes
  selection = new SelectionModel(true, []);
  //Model template for cancel button
  @ViewChild('cancelTemplate') cancelTemplate!: TemplateRef<any>;
  // employee main locations list for dropdown, 
  // filled by response of logged in user's company call in getUserCompany function
  employeeLocations: EmployeeLocationList = new EmployeeLocationList();
  //select lastItem from template
  @ViewChildren('theLastItem', { read: ElementRef }) theLastItem: QueryList<ElementRef>;
  //loggedin user itemGroup
  adminUserItemGroups: string
  //user assigned location and item group
  userItemGroupPermissions: UserItemGroupPermissions[] = []
  // validation for item group dropdown
  itemGroupsFormcontrol = new FormControl(undefined, {
    validators: [Validators.minLength(1), Validators.required],
  });
  // item group item name
  itemGroupIconName = "info-circle-o"
  // on mouse over fill the circle icon
  mouseOver() {
    this.itemGroupIconName = "info-circle"
  }
  // on mouse
  mouseOut() {
    this.itemGroupIconName = "info-circle-o"
  }

  constructor(private layoutService: LayoutService, private referenceDataService: ReferenceDataService,
    private dialogService: NxDialogService,
    private commonService: CommonService,
    private userService: UserService,
    private locationService: LocationService,
    private itemGroupService: ItemGroupService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //to set breadcrums on refresh
    this.commonService.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.userRegistration);
    //Banner text setting
    this.layoutService.setBanner(BannerDetail.userRegBannerText);
    // call Load form method
    this.loadForm();
    // setting user company and location on UI
    this.getUserCompany();
    // fetching permissions for this view
    this.fetchLocations();
    // checking if device is desktop
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => { this.userRegistrationViewModel.isDesktop = result; });
    //intersection-observer
    this.intersectionObserver();
    // fetch user titles to display in Title dropdown
    this.fetchReferenceDataTitles()
    const assignedItemGroupObject = this.itemGroupService.getAssignedItemGroups();
    if (assignedItemGroupObject && assignedItemGroupObject.length > 0) {
      this.userRegistrationViewModel.itemGroups = assignedItemGroupObject;
      this.itemGroupMappingDetails();
    }
    else {
      // fetch all item groups list
      this.fetchItemGroupList()
    }
  }


  //infinite-scroll logic start
  //afterViewInit to view the last item of list
  ngAfterViewInit() {
    //this is called when screen width changes from mobile to desktop and vice versa
    this.theLastItem.changes.subscribe((d) => {
      if (d._changesDetected) this.userRegistrationViewModel.observer.observe(d.last.nativeElement)
    })
    //this is called other than above lines
    this.userRegistrationViewModel.observer.observe(document.querySelector('.infinite-scroller'));
  }

  // fetch user titles to d0.isplay in Title dropdown
  fetchReferenceDataTitles() {
    this.userRegistrationViewModel.userTitles = this.referenceDataService.getUserTitles();
  }
  // get locations from graphql service
  async fetchLocations() {
    // if search list  is empty, add default search param
    if (this.userRegistrationViewModel.queryParamList.searchList.length == 0)
      this.commonService.addDefaultSearchParam(this.userRegistrationViewModel.queryParamList, this.locationViewModel.locationNameKey, "*");

    // prepare variables to pass to the api
    const apiResponce = await this.locationService.getLocations(this.userRegistrationViewModel.queryParamList.searchList, "ASC", "name", this.locationViewModel.page - 1, 10, true)
    if (apiResponce && apiResponce.locationDetails) {
      //  mapping of total results count
      this.locationViewModel.resultCount = apiResponce.totalCount;
      // check if it is not a scroll call, then clear the existing rows to shows only rows which meets the search criteria
      if (!this.userRegistrationViewModel.isLocationScrollCall) {
        this.locationViewModel.locations.length = 0;
        // mapping of results in locations array
        this.locationViewModel.locations = apiResponce.locationDetails
      }
      else {
        apiResponce.locationDetails.forEach(location => { this.locationViewModel.locations.push(location) })
      }
    }

  }

  //intersection Observer to callback fetchlocation upon reaching intersection
  intersectionObserver() {
    //main function that takes a callback and options
    this.userRegistrationViewModel.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // check if the retrieved results are less than total result count
          if (this.locationViewModel.perPage < this.locationViewModel.resultCount) {
            // increment the page number
            this.locationViewModel.page += 1;
            // add 10 plus in previous page count
            this.locationViewModel.perPage += 10;
            // set location scroll call boolean to true
            this.userRegistrationViewModel.isLocationScrollCall = true;
            // fetch locations with updated requirements
            this.fetchLocations();
          }
        }
      })
    }, this.userRegistrationViewModel.options)
  }

  // Load form with default config
  loadForm(): void {
    this.userRegFormGroup = new FormBuilder().group({
      firstName: ['', Validators.required],  // Required as per acceptence creteria
      lastName: ['', Validators.required],
      titles: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email,
      ]],
      contactNo: ['', Validators.required],
      mobileNo: [''],
      company: [''],
      mainEmployeeLocation: ['', Validators.required],
      role: ['Standard client user'],// need to set this as default setting
      eReporting: ['']
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.locationViewModel.locations.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAll() {

    this.isAllSelected() ?
      this.selection.clear() :
      this.locationViewModel.locations.forEach(row => this.selection.select(row));
    this.validateRegistrationForm();
  }

  // toggle selected checkbox / single location
  toggleSelected(location: LocationViewModel): void {
    this.selection.toggle(location);
    this.validateRegistrationForm();
  }

  // how modal for info icon with appropriate message
  displayInfoModal(modalFor: InfoModalType): void {
    // modal body message
    let modalMessage: string = '';
    // switch to set message body according to specific field
    switch (modalFor) {
      case InfoModalType.Email:
        modalMessage = "Please provide the new user email address";
        break;
      case InfoModalType.ContactNo:
        modalMessage = "Please provide the new user contact number in this input field";
        break;
      case InfoModalType.EmployeeLocation:
        modalMessage = "Please select a location for the new user";
        break;
      case InfoModalType.Role:
        modalMessage = "This is new user's role";
        break;
    }
    if (modalMessage) {
      // Setup and open modal with dynamic body
      this.dialogRef = this.dialogService.open(this.templateRef, {
        showCloseIcon: true,
        ariaLabel: 'Info',
        width: 'auto',
        data: {
          body: modalMessage
        }
      });
    }
  }

  // called when save button is clicked
  submitForm(): void {
    // validating the form input against prior rules
    if (this.validateRegistrationForm(true))
      this.createEmployee();
  }

  // Validate  Controls for multiple error

  hasError = (controlName: string, errorName: string) => {
    return this.userRegFormGroup.controls[controlName].hasError(errorName)
  }

  // save data from both forms
  createEmployee = () => {

    // need to validate the form and data before passing to api
    if ((this.userRegFormGroup && this.userRegFormGroup.valid
    ) && this.selection.selected.length > 0) {
      //get admin item group id first
      this.executeEmployeeCreation();
    }
  }

  //store data from each form using employee model
  private executeEmployeeCreation = () => {
    // loop on mulitselect model list
    // find API result 
    //get location IDs
    // empty it for any previous values
    this.userItemGroupPermissions = []
    // iterate thorugh the selected item lists 
    this.userRegistrationViewModel.itemGroupModel.forEach(itemGroup => {
      // iterate through 
      const matchingItemGroups = this.userRegistrationViewModel.itemGroups.find(id => id.id == itemGroup)
      let userItemGroupPermissionsData = {} as UserItemGroupPermissions;
      // push id and locationID in interface
      userItemGroupPermissionsData.id = matchingItemGroups.id
      userItemGroupPermissionsData.locationIds = matchingItemGroups.locationIds
      this.userItemGroupPermissions.push(userItemGroupPermissionsData);
    })
    //constuct employee object
    const employee: Employee = {
      title: this.userRegFormGroup.value.titles,
      firstname: this.userRegFormGroup.value.firstName,
      surname: this.userRegFormGroup.value.lastName,
      emailAddress: this.userRegFormGroup.value.email,
      telephoneNumber: this.userRegFormGroup.value.contactNo,
      mobileNumber: this.userRegFormGroup.value.mobileNo,
      // if all locations are selected, then we send empty array
      // empty array is interpreted like this that all locations are selected on API side
      branchId: this.userRegFormGroup.value.mainEmployeeLocation,
      adminItemGroupPermissions: this.userRegistrationViewModel.itemGroupModel,
      userItemGroupPermissions: this.userItemGroupPermissions,
      eReportingEnabled: this.userRegistrationViewModel.isEReportingEnabled,
      eNotificationEnabled: false,
      role: 'Standard client user'

    }

    // on success process , just redirect user to location view.
    this.userService.saveNewEmployee(employee).pipe(untilDestroyed(this), shareReplay()).subscribe(res => {
      if (res) {
        if (res.data.createEmployee.id) {
          this.redirectToLocations();
        } else {
          this.layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Unable to register, please contact admin for support."));

        }
      }
    });
  }

  // validate required fields on this component
  private validateRegistrationForm(isSaveClicked: boolean = false): boolean {
    // checking the form is valid or invalid
    this.userRegistrationViewModel.showTopErrorBanner = this.userRegFormGroup.invalid
    // set location banner error's condition
    this.userRegistrationViewModel.showLocationsErrorBanner = this.selection.selected.length == 0;
    // in case if no location is selected i.e. show location error banner, then click
    // locations accordian programmatically
    if (this.userRegistrationViewModel.showLocationsErrorBanner && !this.commonService.elementExist("locationError") && isSaveClicked) {
      this.commonService.clickElementByClassName('nx-expansion-panel__header-content');
      return false;
    }
    return true;
  }
  //Open  model for confirmation of actions
  openCancelTemplate(): void {
    //Check if form filled or partial filled then show pop up
    if ((this.userRegFormGroup != undefined && this.userRegFormGroup.dirty))
      this.dialogService.open(this.cancelTemplate, { ariaLabel: 'Confirmation Model', disableClose: true });
  }
  // When user click on yes button on model, will be redirected to location page.
  redirectToLocations(): void {
    void this.commonService.navigate(AppRoutes.users);
  }

  // generate a number list from string list
  convertToNumberList(value: string[]): number[] {
    // if value exists
    if (value && value.length > 0) {
      // if its an array, then join its items as number list
      if (Array.isArray(value)) {
        this.userRegistrationViewModel.employeeSelectedLocations = value.map(Number);
        return this.userRegistrationViewModel.employeeSelectedLocations;
      } else {
        // return single number list
        return this.userRegistrationViewModel.employeeSelectedLocations;
      }
    }
    // default return is empty number list
    return this.userRegistrationViewModel.employeeSelectedLocations;
  }
  // private method to get user company and locations for UI controls
  getUserCompany(): void {
    this.userService.getCompanyAndLocations().subscribe(userCompanyObj => {
      if (userCompanyObj) {
        // making sure company name exists in response
        if (userCompanyObj.company) {
          // check if company has locations
          if (userCompanyObj.company.branches && userCompanyObj.company.branches.length > 0) {
            // clearing the employee locations array first
            this.employeeLocations.employeeLocations.length = 0;
            // pushing office locations to array
            userCompanyObj.company.branches.forEach((item) => {
              this.employeeLocations.employeeLocations.push({ id: item.id, name: item.name });
            });
          }
          if (userCompanyObj.company.name) {
            // checking if its desktop then use different form group
            // setting company name on UI
            this.userRegFormGroup.controls['company'].patchValue(userCompanyObj.company.name);

          }
        }
      }
    });
  }

  // perform search, display result list and update routes 
  search() {
    // remove any previous search inputs
    this.userRegistrationViewModel.queryParamList.clear(0);
    // added to select api call to get location with search params
    this.userRegistrationViewModel.isSearchLocationCall = true;
    // prepare query params to send to api
    this.prepareSearchParamList();
    // set page number to 1
    this.locationViewModel.page = 1;
    // set location scroll boolean to false
    this.userRegistrationViewModel.isLocationScrollCall = false;
    // get locations
    this.fetchLocations();
  }

  // set multi fields search values
  prepareSearchParamList(): void {
    this.commonService.updateSearchParamList(this.userRegistrationViewModel.queryParamList, this.locationViewModel.locationPostcodeKey, `${this.locationViewModel.locationPostcode}`);
    this.commonService.updateSearchParamList(this.userRegistrationViewModel.queryParamList, this.locationViewModel.locationNameKey, `${this.locationViewModel.locationName}`);
    this.commonService.updateSearchParamList(this.userRegistrationViewModel.queryParamList, this.locationViewModel.locationAddressKey, `${this.locationViewModel.locationAddress}`);
  }

  // clear search form, reset page and reload data from api
  clearSearch() {
    // remove any previous search inputs
    this.userRegistrationViewModel.queryParamList.clear(0);
    // reset the fields to be empty
    this.locationViewModel.clear();
    // added to select api call to get location without search params
    this.userRegistrationViewModel.isSearchLocationCall = false;
    // set page number to 1
    this.locationViewModel.page = 1;
    // set location scroll boolean to false
    this.userRegistrationViewModel.isLocationScrollCall = false;
    // get locations
    this.fetchLocations();
  }

  // fetch item groups list
  fetchItemGroupList() {
    this.itemGroupService.getAdminItemGroup().pipe(untilDestroyed(this)).subscribe(result => {
      if (result?.data?.itemGroups?.length >= 1) {
        // empty itemGroupList for any previous values
        this.userRegistrationViewModel.itemGroupsList = [];
        // save api result in variable
        this.userRegistrationViewModel.itemGroups = result.data.itemGroups
        this.itemGroupMappingDetails();
      }
    })
  }

  // method to check the details of item group and act accordingly
  itemGroupMappingDetails = () => {
    // in case of single itemGroup get 0th index name and id
    // name is used to directly render it in input field
    // id is used to be passed to userPermissions Variable for creating a user
    if (this.userRegistrationViewModel.itemGroups.length == 1) {
      this.userRegistrationViewModel.isSingleItemGroup = true;
      this.userRegistrationViewModel.singleItemGroupName = this.userRegistrationViewModel.itemGroups[0].name
      this.userRegistrationViewModel.itemGroupModel = this.userRegistrationViewModel.itemGroups.map(id => id.id)
    }
    // if multiple item groups then iterate through each and find id and name
    else {
      this.userRegistrationViewModel.isSingleItemGroup = false;
      this.userRegistrationViewModel.itemGroups.forEach(item => {
        this.userRegistrationViewModel.itemGroupsList.push({
          id: item.id,
          name: item.name
        })
      })
    }
  }
}