import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from '@aposin/ng-aquila/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserPersonalization } from 'src/app/schemas/personalisation';
import { UserModel, SortEmployeeField } from 'src/app/schemas/users';
import { CommonService } from 'src/app/services/common.service';
import { PersonalisationService } from 'src/app/services/graphql/personalisation.service';
import { UserService } from 'src/app/services/graphql/user.Service';
import { LayoutService } from 'src/app/services/layout-service';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { ComponentName } from 'src/app/shared/enums/componentName';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';
import { DisplayRolePipe } from 'src/app/shared/pipes/roles/role.pipe';
import { UserViewModel } from '../userViewModel';

@UntilDestroy()
// this is user list / grid component
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  // user viewmodel object
  userViewModel: UserViewModel = new UserViewModel();

  //personlisation object 
  personalisationObj: UserPersonalization = new UserPersonalization();

  // constructor with all required services injected
  constructor(public layoutService: LayoutService, private router: Router,
    private userService: UserService, private common: CommonService, private activatedRoute: ActivatedRoute
    , private personalisationService: PersonalisationService) { }

  ngOnInit(): void {
    //to set breadcrums on refresh
    this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.userManagment);
    //fetch user personalisation detail for per page control
    if (this.personalisationService.personalization.user) {
      this.personalisationObj = this.personalisationService.personalization.user;
    }
    this.userViewModel.perPage = parseInt(this.personalisationObj.perPageToggle);
    //Banner text setting
    this.layoutService.setBanner(BannerDetail.userBannerText);
    // set subTitle of the banner
    this.layoutService.setSubBanner(BannerDetail.userBannerSubtitle);
    // subscribe to query params changes
    this.activatedRoute.queryParams.subscribe(params => {
      // get params from url
      this.getQueryParams(params);
      // prepare search list accordingly
      this.prepareSearchParamList();
      //fetch all employees/ users
      this.fetchEmployee();

    });
    // subscribe to device type detectio obserable
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => this.userViewModel.isDesktop = result);

  }
  //redirect user to user registration form 
  navigateToUserRegistration() {
    // navigate to user registration view
    this.router.navigateByUrl("users/register");

  }
  // clear search form, reset page and reload data from api
  clearSearch() {
    //call clear method 
    this.userViewModel.clear();
    // clear query params
    this.userViewModel.queryParamList.clear(0)
    // set page to 1
    this.userViewModel.page = 1;
    // update the route
    this.router.navigate([`${AppRoutes.users}`], {
      queryParams: {
        pageNo: this.userViewModel.page,
        sortDirection: this.userViewModel.order,
        sortBy: this.userViewModel.sortName,
        perPage: this.userViewModel.perPage,
      }
    });
  }
  // perform search, display result list and update routes 
  search() {
    // prepare query params to send to api
    this.prepareSearchParamList();
    // page should be set to first
    this.userViewModel.page = 1;
    // navigate with required query params
    this.router.navigate([`${AppRoutes.users}`], {
      queryParams: {
        pageNo: this.userViewModel.page,
        sortDirection: this.userViewModel.order,
        sortBy: this.userViewModel.sortName,
        perPage: this.userViewModel.perPage,
        userName: this.userViewModel.name
      }
    });
  }
  // set multi fields search values
  prepareSearchParamList(): void {
    // adding name field in search List array to be passed to api
    this.common.updateSearchParamList(this.userViewModel.queryParamList, this.userViewModel.userNameKey, `${this.userViewModel.name}`);
    // we need to add a default search as query is not working in case of empty search list
    if (this.userViewModel.queryParamList.searchList.length == 0)
      this.common.addDefaultSearchParam(this.userViewModel.queryParamList, this.userViewModel.userNameKey, "*");
  }
  // get users from graphql service
  fetchEmployee() {
    let sortField = {} as SortEmployeeField;
    sortField.field = this.userViewModel.sortName
    sortField.order = this.userViewModel.order
    // call to users service
    this.userService.getAllEmployees(this.userViewModel.queryParamList.searchList, sortField, this.userViewModel.page - 1, this.userViewModel.perPage).pipe(untilDestroyed(this)).subscribe(result => {
      if (result && result.data && result.data.search) {
        // clear any already present rows
        this.userViewModel.users.length = 0;
        // setting viewmodel from response
        result.data.search.results.forEach(user => {
          // converting api employee to userviewmodel
          let userModel = {} as UserModel;
          userModel.name = `${user.firstname} ${user.surname}`;
          userModel.emailAddress = user.emailAddresses && user.emailAddresses.length > 0 ? user.emailAddresses.find(x => x.email != undefined).email : "";
          userModel.role = new DisplayRolePipe().transform(user.metadata.role);
          userModel.id = user.id;
          userModel.firstname = user.firstname;
          userModel.surname = user.surname;
          this.userViewModel.users.push(userModel)
        });
        this.userViewModel.resultCount = result.data.search.totalCount;
      }
    });
  }
  // navigate to specific page and load its data
  // configured with pagination control
  goToPage(pageNumber: number) {
    this.userViewModel.page = pageNumber;
    this.loadPage();
  }

  // navigate to previous page
  prevPage() {
    this.userViewModel.page--;
    this.loadPage();
  }

  // navigate to next page
  nextPage() {
    this.userViewModel.page++;
    this.loadPage();
  }
  // used with pagination control on view
  loadPage() {
    // navaigate with query params
    this.router.navigate([`${AppRoutes.users}`], {
      queryParams: {
        pageNo: this.userViewModel.page, sortDirection: this.userViewModel.order,
        sortBy: this.userViewModel.sortName,
        perPage: this.userViewModel.perPage,
        userName: this.userViewModel.name

      }
    })
  }
  // get query params and map to our local variables
  getQueryParams(params: any): void {
    this.userViewModel.perPage = this.common.IsNullORUndefined(params, "perPage", this.userViewModel.perPage);
    this.userViewModel.page = this.common.IsNullORUndefined(params, "pageNo", 1);
    this.userViewModel.sortName = this.common.IsNullORUndefString(params, "sortBy", "name");
    this.userViewModel.order = this.common.IsNullORUndefString(params, "sortDirection", "ASC");
    this.userViewModel.name = this.common.IsNullORUndefString(params, "userName", "");

  }
  // Sort grid by specific attribute
  // handles both local sorting and 
  // get list storted from api
  sortTable(sort: SortEvent) {
    // if returned results are less than 11, we can apply local sorting
    if (this.userViewModel.resultCount <= 10) {
      this.sortLocally(sort);
    }
    else {
      // otherwise will call api with sort params 
      this.router.navigate([`${AppRoutes.users}`], {
        queryParams: {
          pageNo: this.userViewModel.page,
          sortDirection: sort.direction.toUpperCase(),
          sortBy: sort.active,
          perPage: this.userViewModel.perPage,
          userName: this.userViewModel.name

        }
      });
    }
  }
  // sort users list locally
  sortLocally(sort: SortEvent): void {
    this.userViewModel.users.sort((a, b) => {
      switch (sort.active) {
        case 'name': return this.common.compare(a.name, b.name, sort.direction);
        case 'email': return this.common.compare(a.emailAddress, b.emailAddress, sort.direction);
        default: return 0;
      }
    });
  }
  // navigate to user edit screen 
  navigateToEdit(user: UserModel): void {
    // format user id to remove / with _ to pass in url
    const formattedUserId = this.common.findAndReplace(user.id, "/", "_");
    // navigate to edit user screen
    this.router.navigate([`${AppRoutes.editUser(formattedUserId)}`]);
  }

  // toggle number of records per page by clicking 
  public async togglePerPage(selectedPerPage: PerPageModel): Promise<void> {
    // clicked option's value is set as per page option
    this.userViewModel.perPage = selectedPerPage.value;
    // page to load is set to 1 i.e. load first page
    this.userViewModel.page = 1;
    //save user personalisation  
    this.personalisationObj.perPageToggle = `${selectedPerPage.value.toString()}`;
    await this.savePersonalisation();
    // calling load page
    this.loadPage();
  }

  //save user personalisation 
  async savePersonalisation(): Promise<void> {
    await this.personalisationService.updateComponentSettings(this.personalisationObj, ComponentName.userList);
  }
}
