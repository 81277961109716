<div style="display: flex; border-bottom: solid #f5f5f5;" *ngIf="!organisationViewModel.showOrganisationPage"
    (click)="openPage()">
    <nx-icon nxTooltip="change organisation and Business Area" class="itemGroupIcon nx-margin-x-xs nx-margin-top-s"
        name="filter" size="s"></nx-icon>
    <p class=" pointer itemGroupIcon mobile-label-ItemGroup  nx-margin-top-s nx-margin-right-xs">
        <u> Organisation: </u>
    </p>
    <a *ngIf="organisationViewModel.organizationObject && organisationViewModel.organizationObject.length > 0"
        class="pointer itemGroupIcon nx-margin-x-xs nx-margin-top-s">
        <u> {{organisationViewModel.organizationObject[0].name}}</u> </a>

</div>

<div class="modal-content-internal desktop-alignment" *ngIf="organisationViewModel.showOrganisationPage">
    <div class="selectedDivAlignment nx-margin-left-s nx-margin-top-s">
        <h2>Please select an organisation</h2>
    </div>

    <p class="nx-margin-left-s  nx-margin-top-s">{{dummyText}}</p>
    <hr>
    <div class=" nx-margin-left-s nx-margin-top-l flex" *ngIf="organisationViewModel.isFirstTabSelected">
        <div class="flex search-div-border percent70-width ">
            <div class="percent10-width">
                <nx-icon name="product-search" class="nx-margin-right-xs blueColor" size="s"></nx-icon>
            </div>
            <div class="percent100-width">
                <input id="organizationInput" [(ngModel)]="organisationViewModel.organisationNameObject"
                    placeholder="Enter organisation name or post code" [nxAutocomplete]="auto1"
                    class="percent100-width" />
                <nx-autocomplete #auto1="nxAutocomplete" (optionSelected)="organisationSelected()">
                    <nx-autocomplete-option *ngFor="let option of organisationViewModel.organisationListObject"
                        [value]="option.name">
                        {{ option.name }}
                    </nx-autocomplete-option>
                </nx-autocomplete>
            </div>
        </div>

        <div class="nx-margin-left-m percent20-width ">
            <button class="nx-margin-right-s" nxButton="primary small" (click)="fetchOrganisationsList()" type="button"
                id="search">SEARCH</button>
        </div>
    </div>

    <div
        *ngIf="!organisationViewModel.isFirstTabSelected && organisationViewModel.itemGroupListObject && organisationViewModel.itemGroupListObject.length > 0">
        <div nxRow class="nx-margin-bottom-xs nx-margin-left-m">

            <div nxCol="2" class="font-size-large nx-margin-top-s nx-margin-bottom-xs">
                Name
            </div>
        </div>
        <div class="nx-margin-x-s">
            <div nxRow class="nx-margin-bottom-xs nx-margin-left-s">
                <form>
                    <nx-radio-group [(ngModel)]="organisationViewModel.selectedItemGroupName" name="templateTest">
                        <nx-radio [nxValue]="group.name" *ngFor="let group of organisationViewModel.itemGroupListObject"
                            class="nx-margin-bottom-m">
                            {{group.name}}
                        </nx-radio>
                    </nx-radio-group>
                </form>
            </div>
        </div>
    </div>
    <div
        *ngIf="!organisationViewModel.isFirstTabSelected && organisationViewModel.itemGroupListObject && organisationViewModel.itemGroupListObject.length===0">
        <div nxRow class="nx-margin-bottom-xs nx-margin-left-m">

            <div class="font-size-large nx-margin-top-s nx-margin-bottom-xs">
                <p class="red-color"> There is no Business Area linked with selected organisation. </p>
            </div>
        </div>
    </div>
    <div class=" nx-margin-left-s nx-margin-top-l">
        <nx-single-stepper currentStepLabel="Step">
            <nx-step label="Organisation">
                <div class="nx-margin-top-s">

                    <div class="center nx-margin-right-2xs">
                        <button nxButton="secondary small" (click)="cancelOrganisation()" class="nx-margin-right-2xs"
                            type="button">
                            Cancel
                        </button>
                        <button nxButton="primary small" type="button" *ngIf="organisationViewModel.disableNextButton "
                            [disabled]="organisationViewModel.nextDisabled">
                            Next
                        </button>
                        <button nxButton="primary small" type="button" nxStepperNext
                            *ngIf="!organisationViewModel.nextDisabled" (click)="saveOrganisation()">
                            Next
                        </button>
                    </div>
                </div>
            </nx-step>
            <nx-step label="Business Area(s)">
                <div class="nx-margin-top-s">

                    <div class="center nx-margin-right-2xs">
                        <button nxButton="secondary small" nxStepperPrevious class="nx-margin-right-2xs"
                            (click)="organisationViewModel.isFirstTabSelected = true" type="button">
                            Previous
                        </button>
                        <button nxButton="primary small" type="button"
                            [disabled]="organisationViewModel.selectedItemGroupName == '' || organisationViewModel.selectedItemGroupName == null"
                            (click)="proceed()">
                            Proceed
                        </button>

                    </div>
                </div>
            </nx-step>
        </nx-single-stepper>
    </div>

</div>