import { Injectable } from "@angular/core";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { ExportLocationItem } from "../schemas/location-items";
import { ErrorLogService } from "./errors/errorService";

// Service to provide functions to handle export functionality
@Injectable()
export class ExportService {

    constructor(private errorLogService: ErrorLogService) {
    }
    // make the csv file from the shared content
    // takes 5 params
    // 1. items => list of all the items to be present in csv file
    // 2. headers => list of all headers of csv file
    // 3. file name => file name of exported csv
    // 4. list of all locations => it contains data in case of export is related to any widget
    // otherwise its empty in case of location items screen
    // 5. Widget Name => null in case of navigation from items screen
    public exportToCsv = (items: any, fileName: string, headers: any, locationsData: any, widgetName: string, isLocationItems: boolean = true) => {
        try {
            //calling methods which return list of exported items (generateItemsListForCSV)
            //passing to AngularCsv to generate csv
            new AngularCsv(this.generateItemsListForCSV(items, locationsData, widgetName, isLocationItems), this.exportFileName(fileName), headers);
        } catch (exception) {
            this.errorLogService.logError(exception);
            throw exception;
        }
    }

    //export function for item details, splitted this function as per 
    //new requirements, 
    public exportItemDetailToCSV(exportObject: ExportLocationItem[], fileName: string, headers: any) {
        try {
            //calling methods which return list of exported items (generateItemsListForCSV)
            //passing to AngularCsv to generate csv
            new AngularCsv(exportObject, this.exportFileName(fileName), headers);
        } catch (exception) {
            this.errorLogService.logError(exception);
        }
    }

    // generate list of items required to generate item list csv
    private generateItemsListForCSV(items: any, locationsData: any, widgetName: string, isLocationItems: boolean = true): ExportLocationItem[] {
        // initializing the export class object.
        let exportLocationItemArray: ExportLocationItem[] = [];
        if (items) {
            items.forEach(
                item => {
                    // construction the object for export file
                    exportLocationItemArray = this.addItemsInExportFile(exportLocationItemArray, item, locationsData, widgetName, isLocationItems)

                }
            )
        }
        return exportLocationItemArray;
    }

    // add items to have in export file 
    // takes array object and item object as parameters
    // keeps on adding record in array object and returns the updated one
    private addItemsInExportFile = (exportLocationItemArray: ExportLocationItem[], item: any, locationsData: any, widgetName: string, isLocationItems: boolean): ExportLocationItem[] => {
        // for only items associated with any locations
        if (isLocationItems) {
            let exportLocationItemObj: ExportLocationItem = new ExportLocationItem();
            if (widgetName)
                exportLocationItemObj = this.prepareFieldsForWidgetExport(exportLocationItemObj, item, locationsData, widgetName);
            else
                exportLocationItemObj = this.prepareFieldsForItemsExport(exportLocationItemObj, item);

            exportLocationItemObj.nextDue = item?.inspections?.nextInspectionDate ? item?.inspections?.nextInspectionDate : '-'
            exportLocationItemObj.subLocation = item?.location?.subCodeDescription ? item?.location?.subCodeDescription : '-'
            exportLocationItemObj.detailedLocation = item?.location?.description ? item?.location?.description : '-'
            exportLocationItemObj.itemstatus = item?.status?.description ? item?.status?.description : '-'
            exportLocationItemObj = this.appendItemDetailsFieldsForExport(exportLocationItemObj, item);
            exportLocationItemArray.push(exportLocationItemObj);
            return exportLocationItemArray;
        }
        // for items coming from main items page
        else {
            exportLocationItemArray = this.addMainItemsInExportFile(exportLocationItemArray, item);
            return exportLocationItemArray;

        }

    }

    // prepare the fields with respective data when widget needs to be exported
    private prepareFieldsForWidgetExport = (exportLocationItemObj: ExportLocationItem, item: any, locationsData: any, widgetName: string): ExportLocationItem => {
        if (widgetName && widgetName != "AllDefect")
            exportLocationItemObj.location = this.extractLocationName(item.location.code, locationsData)
        exportLocationItemObj.serialNo = item?.serialNumber ? item?.serialNumber : '-'
        exportLocationItemObj.plantNo = item?.clientPlantNumber ? item?.clientPlantNumber : '-'
        exportLocationItemObj.plantDescription = item?.plantType?.description ? item?.plantType?.description : '-'
        exportLocationItemObj.discipline = item?.discipline?.description ? item?.discipline?.description : '-'
        exportLocationItemObj.lastInspected = item?.inspections?.inspectionDate ? item?.inspections?.inspectionDate : null
        if (widgetName == "PDefect")
            exportLocationItemObj.defectCode = "P";
        else
            exportLocationItemObj.defectCode = item?.reports && item?.reports?.results && item?.reports?.results.length > 0 ? item?.reports?.results[0].defectCode?.code : '-'
        return exportLocationItemObj;
    }

    // prepare the fields with respective data when items from items screen needs to be exported
    private prepareFieldsForItemsExport = (exportLocationItemObj: ExportLocationItem, item: any): ExportLocationItem => {
        const plantNo = item?.reportClientPlantNumber;
        exportLocationItemObj.reportId = item?.reportID ? item?.reportID : '-'
        exportLocationItemObj.serialNo = item?.serialNumber ? item?.serialNumber : '-'
        exportLocationItemObj.plantNo = plantNo ? plantNo : '-'
        exportLocationItemObj.plantDescription = item?.plantType?.description ? item?.plantType?.description : '-'
        exportLocationItemObj.discipline = item?.discipline?.description ? item?.discipline?.description : '-'
        exportLocationItemObj.lastInspected = item?.inspections?.inspectionDate ? item?.inspections?.inspectionDate : null
        exportLocationItemObj.defectCode = item?.latestReport?.defectCode?.code ? item?.latestReport?.defectCode?.code : '-'
        return exportLocationItemObj
    }

    // prepare the fields of Item details data when items needs to be exported
    private appendItemDetailsFieldsForExport = (exportLocationItemObj: ExportLocationItem, item: any): ExportLocationItem => {
        exportLocationItemObj.policyNumber = item?.policyNumber ? item?.policyNumber : '-'
        exportLocationItemObj.ACEPlantNo = item?.plantType?.code ? item?.plantType?.code : '-'
        exportLocationItemObj.manufacturer = item?.manufacturer ? item?.manufacturer : '-'
        exportLocationItemObj.manufacturingYear = item?.manufacturingYear ? item?.manufacturingYear : '-'
        exportLocationItemObj.schemeReference = item?.schemeReference?.fileName ? item?.schemeReference?.fileName : '-'
        exportLocationItemObj.nextContractualDate = item?.inspections?.nextInspectionDate ? item?.inspections?.nextInspectionDate : '-'
        return exportLocationItemObj;
    }

    // add all main items to have in export file 
    // takes array object and item object as parameters
    // keeps on adding record in array object and returns the updated one
    private addMainItemsInExportFile = (exportLocationItemArray: ExportLocationItem[], item: any): ExportLocationItem[] => {
        let locationName: string;
        let exportLocationItemObj: ExportLocationItem = new ExportLocationItem();
        exportLocationItemObj = this.prepareFieldsForItemsExport(exportLocationItemObj, item);
        exportLocationItemObj.nextDue = item?.inspections?.nextInspectionDate ? item?.inspections?.nextInspectionDate : '-'
        // make up the location name + postcode string
        if (item.toolTipLocationName)
            locationName = `${item.toolTipLocationName} ${item.locationPostcode ? item.locationPostcode : ""}`
        else if (item.locationName)
            locationName = `${item.locationName} ${item.locationPostcode ? item.locationPostcode : ""}`
        else
            locationName = "-"
        exportLocationItemObj.location = locationName
        exportLocationItemObj.detailedLocation = this.concatSubDetailedLocation(item?.location?.subCodeDescription, item?.location?.description)
        exportLocationItemObj.itemstatus = item?.status?.description ? item?.status?.description : '-'
        exportLocationItemObj = this.appendItemDetailsFieldsForExport(exportLocationItemObj, item)
        exportLocationItemArray.push(exportLocationItemObj);
        return exportLocationItemArray;
    }

    // extract location Name from list by locationId
    private extractLocationName = (locationId: string, locationsData: any) => locationsData.find(location => location.id == locationId).name

    // make up the string by adding sub location and detailed location
    private concatSubDetailedLocation = (subLocation: string, detailedLocation: string): string => {
        let locationDetails: string
        // both fields have data
        if (subLocation && detailedLocation)
            locationDetails = `${subLocation} / ${detailedLocation}`;
        // only data present in sub location
        else if (subLocation)
            locationDetails = subLocation
        // only data present in detailed location
        else if (detailedLocation)
            locationDetails = detailedLocation
        else
            locationDetails = "-"
        // return the concated string back
        return locationDetails
    }

    //method to generate name of export file
    private exportFileName = (fileName: string): string => {
        //passing the array to export utility  
        const currentDate = new Date();
        //generating file name using current datatime
        return `${fileName + "_" + currentDate.toISOString().split('T')[0]}`;
    }
}