<app-loading></app-loading>

<div class="container" [ngClass]="{'display-widgets-vertically': !dashboardViewModel.isDesktop}">
    <div class="map-container" id="location-widget-container">
        <div id="map-container-modal">
            <div id="map-container-modal-content">
                <ng-contrainer *ngTemplateOutlet="infoIconTemplate"></ng-contrainer>
                <ng-container>
                    <google-map (setMapToListView)="changeView($event)" [locationResultset]="locationsData"
                        [locationDueCount]="maplocationDueCountObj" [locationOverDueCount]="maplocationOverDueCountObj"
                        (navigateToOverDueItems)="navigateForOverdueDueItems($event, true)"
                        (navigateToDueItems)="navigateForOverdueDueItems($event, false)"
                        (navigateToDueOverDueItems)="navigateForOverdueDueItems($event, false)" [reloadMap]="reloadMap">
                    </google-map>
                </ng-container>
                <ng-container>
                    <div class="tableFixHead" *ngIf="dashboardViewModel.isDesktop" id="list" style="display: none;">
                        <table class="no-border-bottom" nxTable nxSort (sortChange)="sortTable($event)"
                            aria-labelledby="due-overdue-table" id="due-overdue-table">
                            <caption></caption>
                            <thead>
                                <tr nxTableRow>
                                    <th nxHeaderCell nxSortHeaderCell="name" scope="col">Location</th>
                                    <th nxHeaderCell nxSortHeaderCell="address" scope="col">Address</th>
                                    <th nxHeaderCell nxSortHeaderCell="overdue" scope="col">Overdue</th>
                                    <th nxHeaderCell nxSortHeaderCell="due" scope="col">Due</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr nxTableRow *ngFor="let location of locationMapData">
                                    <td nxTableCell>{{location.name}}</td>
                                    <td nxTableCell>{{location?.postalAddress?.address1}},
                                        {{location?.postalAddress?.address2}},
                                        {{location?.postalAddress?.address3}},
                                        {{location?.postalAddress?.postcode}}</td>

                                    <td nxTableCell class="center cursor-pointer"
                                        *ngIf="location.overdueCount else emptyIcon"
                                        (click)="navigateForOverdueDueItems(location.id,true)">
                                        <nx-badge class="overdue-badge-color">

                                            {{location.overdueCount}} </nx-badge>
                                    </td>
                                    <td nxTableCell class="center cursor-pointer"
                                        *ngIf="location.dueCount else emptyIcon"
                                        (click)="navigateForOverdueDueItems(location.id,false)">
                                        <nx-badge class="due-badge-color"> {{location.dueCount}} </nx-badge>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="table-footer-row" *ngIf="locationMapData.length > 0">
                                    <td colspan="2" class="table-footer-text nx-margin-left-m padding-left-10">Total
                                    </td>
                                    <td class="center table-footer-text cursor-pointer"
                                        *ngIf="getOverdueItemsTotal() >0 else emptyIcon"
                                        (click)="navigateForOverdueDueItems('',true)">
                                        {{getOverdueItemsTotal() }}
                                    </td>
                                    <td class="center table-footer-text cursor-pointer"
                                        *ngIf="getDueItemsTotal() >0 else emptyIcon"
                                        (click)="navigateForOverdueDueItems('',false)">
                                        {{getDueItemsTotal() }}
                                    </td>
                                </tr>
                                <tr *ngIf="locationMapData.length == 0" class="empty-row">
                                    <td colspan="4" class="center">{{dashboardViewModel.noDueOverDueMessage}}</td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                    <!-- mobile grid view list -->
                    <div *ngIf="!dashboardViewModel.isDesktop"
                        [ngStyle]="{'height': dashboardViewModel.mobileViewListHeight+'px'}"
                        class="mobile-view-list-container" (scroll)="scrollHandler($event)" id="list"
                        style="display: none;">
                        <div nxRow>
                            <div nxCol="12, 12" *ngIf="locationsData.length > 0">
                                <ng-container>
                                    <div class="tableFixHead" *ngIf="dashboardViewModel.isDesktop" id="list"
                                        style="display: none;">
                                        <table class="no-border-bottom" nxTable nxSort (sortChange)="sortTable($event)">
                                            <caption></caption>
                                            <thead>
                                                <tr nxTableRow>
                                                    <th nxHeaderCell nxSortHeaderCell="name">Location</th>
                                                    <th nxHeaderCell nxSortHeaderCell="address">Address</th>
                                                    <th nxHeaderCell nxSortHeaderCell="overdue">Overdue</th>
                                                    <th nxHeaderCell nxSortHeaderCell="due">Due</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr nxTableRow *ngFor="let location of locationMapData">
                                                    <td nxTableCell>{{location.name}}</td>
                                                    <td nxTableCell>{{location?.postalAddress?.address1}},
                                                        {{location?.postalAddress?.address2}},
                                                        {{location?.postalAddress?.address3}},
                                                        {{location?.postalAddress?.postcode}}</td>

                                                    <td nxTableCell class="center cursor-pointer"
                                                        *ngIf="location.overdueCount else emptyIcon"
                                                        (click)="navigateForOverdueDueItems(location.id, true)">
                                                        <nx-badge class="overdue-badge-color">

                                                            {{location.overdueCount}} </nx-badge>
                                                    </td>
                                                    <td nxTableCell class="center cursor-pointer"
                                                        *ngIf="location.dueCount else emptyIcon"
                                                        (click)="navigateForOverdueDueItems(location.id, false)">
                                                        <nx-badge class="due-badge-color"> {{location.dueCount}}
                                                        </nx-badge>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr class="table-footer-row" *ngIf="locationMapData.length > 0">
                                                    <td colspan="2"
                                                        class="table-footer-text nx-margin-left-m padding-left-10">Total
                                                    </td>
                                                    <td class="center table-footer-text cursor-pointer"
                                                        (click)="navigateForOverdueDueItems('', true)">
                                                        {{getOverdueItemsTotal() }}
                                                    </td>
                                                    <td class="center table-footer-text cursor-pointer"
                                                        (click)="navigateForOverdueDueItems('', false)">
                                                        {{getDueItemsTotal() }}
                                                    </td>
                                                </tr>
                                                <tr *ngIf="locationMapData.length == 0" class="empty-row">
                                                    <td colspan="4" class="center">Within the specified timeframe there
                                                        are
                                                        no overdue /
                                                        due items to
                                                        display.</td>
                                                </tr>
                                            </tfoot>

                                        </table>
                                    </div>
                                    <!-- mobile grid view list -->
                                    <div *ngIf="!dashboardViewModel.isDesktop"
                                        [ngStyle]="{'height': dashboardViewModel.mobileViewListHeight+'px'}"
                                        class="mobile-view-list-container" (scroll)="scrollHandler($event)">
                                        <div nxRow>
                                            <div nxCol="12, 12" *ngIf="locationMapData.length > 0">
                                                <ng-container>
                                                    <div class="mobile-view-list-header" nxRow>
                                                        <div nxCol="5"
                                                            class="padding-left-20 font-weight-increase-headings">Total:
                                                        </div>
                                                        <div nxCol="4"
                                                            class="center font-weight-increase-headings cursor-pointer"
                                                            (click)="navigateForOverdueDueItems('', true)">
                                                            Overdue: {{getOverdueItemsTotal() }}
                                                        </div>
                                                        <div nxCol="3"
                                                            class="center font-weight-increase-headings  cursor-pointer"
                                                            (click)="navigateForOverdueDueItems('', false)">Due:
                                                            {{getDueItemsTotal() }}</div>
                                                    </div>
                                                    <div class="mobile-view-list-body"
                                                        *ngFor="let location of locationMapData " nxRow>
                                                        <div nxCol="5">
                                                            <div nxRow>
                                                                <div nxCol="4" class="font-weight-increase center">
                                                                    Location
                                                                </div>

                                                                <div nxCol="8">{{location.name}}</div>
                                                            </div>
                                                        </div>
                                                        <div nxCol="4" class="center cursor-pointer"
                                                            *ngIf="location.overdueCount else emptyIconMobile"
                                                            (click)="navigateForOverdueDueItems(location.id, true)">
                                                            <nx-badge class="overdue-badge-color">Overdue
                                                                {{location.overdueCount}}
                                                            </nx-badge>
                                                        </div>
                                                        <div nxCol="3" class="center cursor-pointer"
                                                            *ngIf="location.dueCount else emptyIconMobile"
                                                            (click)="navigateForOverdueDueItems(location.id, true)">
                                                            <nx-badge class="due-badge-color">Due: {{location.dueCount}}
                                                            </nx-badge>
                                                        </div>
                                                    </div>
                                                    <button nxButton="primary small" type="button"
                                                        class="view-map-sticky-button" (click)="showDiv('map')"
                                                        [ngClass]="{'onscroll': dashboardViewModel.userHasScrolled === true}">
                                                        <nx-icon name="location-o" size="s"></nx-icon>
                                                        <p *ngIf="!dashboardViewModel.userHasScrolled">View map</p>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="center empty-row-with-padding" *ngIf="locationMapData.length == 0">
                                            Within the specified timeframe there are no overdue / due items to display.
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="graph-container">
        <ng-contrainer *ngTemplateOutlet="defectInfoIconTemplate"></ng-contrainer>
    </div>
    <div class="all-defect-container">
        <all-defect-widget [locationList]="allLocationsData" [selectedLocations]="dashboardViewModel.selectedLocations">
        </all-defect-widget>
    </div>
</div>

<div class="useful-resources-pna-container">
    <div class="useful-resources-container">
        <app-useful-resources></app-useful-resources>
    </div>
    <div class="pna-count-container">
        <pna-count [locationList]="allLocationsData">
        </pna-count>
    </div>
</div>


<ng-template #emptyIcon>
    <td nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></td>
</ng-template>
<ng-template #emptyIconMobile>
    <i class="fas fa-minus emptyIcon center margin-top-mobile-view-list" aria-hidden="true"></i>
</ng-template>


<ng-template #infoIconTemplate>
    <div class="header-container">
        <div class="defect-header">
            <div> {{mapContainerHeading}} <info-icon [infoMessage]="dashboardViewModel.infoMessage"
                    [height]="dashboardViewModel.popoverHeight" [width]="dashboardViewModel.popoverWidth">
                </info-icon>
            </div>


            <div class="flex">
                <div *ngIf="dashboardViewModel.isDesktop">
                    <form novalidate>
                        <nx-radio-toggle name="isMapView" [(ngModel)]="dashboardViewModel.isMapView" [nxStyle]="'small'"
                            class="nx-margin-right-m">

                            <nx-radio-toggle-button nxValue="true"
                                (click)="dashboardViewModel.isMapView= true;showDiv('map')" nxTooltip="Map View">
                                <nx-icon name="location-o"></nx-icon>
                            </nx-radio-toggle-button>
                            <nx-radio-toggle-button nxValue="false"
                                (click)="dashboardViewModel.isMapView= false;showDiv('list')" nxTooltip="List View">
                                <nx-icon name="list"></nx-icon>
                            </nx-radio-toggle-button>


                        </nx-radio-toggle>
                    </form>

                </div>

                <nx-icon [ngStyle]="locationsData.length == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
                    class="nx-margin-left-s" name="export-and-share" size="s"
                    (click)="locationsData.length == 0 ? null : exportToCSV()" nxTooltip="Export"
                    class="blueColor nx-margin-right-s"></nx-icon>
                <nx-icon *ngIf="dashboardViewModel.isDesktop && !dashboardViewModel.isMapContainerExpanded"
                    class="nx-margin-left-s nx-margin-right-s" name="launch" size="s" nxTooltip="Expand"
                    class="blueColor cursor-pointer" (click)="expandMapContainer(true)"></nx-icon>
                <nx-icon *ngIf="dashboardViewModel.isDesktop && dashboardViewModel.isMapContainerExpanded"
                    style="transform: rotate(180deg)" class="nx-margin-left-s nx-margin-right-s" name="launch" size="s"
                    nxTooltip="Minimise" class="blueColor cursor-pointer" (click)="expandMapContainer(false)"></nx-icon>
            </div>

        </div>
        <div>
            <div class="applied-filters blueColor" nxTooltip="Filter" nxTooltipPosition="right"
                (click)="openFilterModal()">
                <nx-icon name="filter" size="s"></nx-icon>
                <p class="filter-text nx-margin-left-s">Applied filters</p>
            </div>
            <div class="date-range">
                <h4>Date range:</h4>
                <p>
                    &nbsp; Within {{dashboardViewModel.dateRangeLocationsDifference}}
                    <span>{{dashboardViewModel.dateRangeLocationsDifference==1 ? 'day': 'days'}}</span>
                </p>
            </div>
            <div class="locations">
                <h4>Locations:</h4>
                <p>&nbsp;{{locationNamesAndCount}}</p>
            </div>
        </div>
    </div>
</ng-template>

<!-- Filter Modal template start -->
<ng-template #filterTemplateModal>

    <h3 nxHeadline="subsection-medium" class="align-filter-heading-with-close-icon">Due items by location
        filters</h3>
    <div nxLayout="Grid" class="remove-blank-space-top">
        <div nxRow>
            <div nxCol="6">
                <form>
                    <nx-formfield nxLabel="Within the next">
                        <nx-dropdown name="within the next" [(ngModel)]="dashboardViewModel.withinNextDays"
                            (selectionChange)="changeDayValue($event)"
                            [disabled]="dashboardViewModel.dateRangeSwitcherModel">
                            <nx-dropdown-item nxValue='14 days'></nx-dropdown-item>
                            <nx-dropdown-item nxValue='30 days'></nx-dropdown-item>
                            <nx-dropdown-item nxValue='60 days'></nx-dropdown-item>
                        </nx-dropdown>
                    </nx-formfield>
                </form>
            </div>
        </div>
        <div nxRow>
            <div nxCol="12">
                <hr>
            </div>
        </div>
        <div nxRow>
            <div nxCol="12">
                <nx-switcher [checked]="dashboardViewModel.dateRangeSwitcherModel" labelSize="small"
                    class="nx-margin-bottom-s" [(ngModel)]="dashboardViewModel.dateRangeSwitcherModel"
                    name="dashboardViewModel.dateRangeSwitcherModel">Use Date Range</nx-switcher>
            </div>
        </div>
        <div nxRow>
            <div nxCol="6">
                <nx-formfield nxLabel="Start date">
                    <input nxDatefield nxInput [nxDatepicker]="startDatepicker" name="startDate" [(ngModel)]="startDate"
                        [nxMin]="dashboardViewModel.minStartDate" [nxMax]="dashboardViewModel.maxStartDate"
                        [nxDisplayFormat]="datePickerFormat" [nxParseFormat]="datePickerFormat"
                        #formatDatefield="nxDatefield" (change)="disableApplyButton()"
                        [disabled]="!dashboardViewModel.dateRangeSwitcherModel" />
                    <span nxFormfieldHint>DD/MM/YYYY</span>
                    <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #startDatepicker></nx-datepicker>
                </nx-formfield>
            </div>

            <div nxCol="6">
                <nx-formfield nxLabel="End date">
                    <input nxDatefield nxInput [nxDatepicker]="endDatepicker" name="endDate" [(ngModel)]="endDate"
                        [nxMin]="dashboardViewModel.minEndDate" [nxMax]="dashboardViewModel.maxEndDate"
                        [nxDisplayFormat]="datePickerFormat" [nxParseFormat]="datePickerFormat"
                        (change)="disableApplyButton()" (dateChange)="disableApplyButton()"
                        [disabled]="!dashboardViewModel.dateRangeSwitcherModel" />
                    <span nxFormfieldHint>DD/MM/YYYY</span>
                    <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #endDatepicker></nx-datepicker>
                </nx-formfield>
            </div>
        </div>
        <div nxRow class="nx-margin-top-s">
            <div nxCol="12">
                <nx-formfield *ngIf="locationsData.length <= 10" appearance="outline" nxFloatLabel="always"
                    nxLabel="Filter locations">
                    <nx-multi-select placeholder="Choose locations" name="selectedLocationIds"
                        [(ngModel)]="selectedLocations" [options]="allLocationsData" selectValue="id"
                        selectLabel="name">
                    </nx-multi-select>
                </nx-formfield>
                <nx-formfield *ngIf="locationsData.length > 10" appearance="outline" nxFloatLabel="always">
                    <nx-multi-select filter placeholder="Choose locations" name="selectedLocationIds"
                        [(ngModel)]="selectedLocations" [options]="allLocationsData" selectValue="id"
                        selectLabel="name">
                    </nx-multi-select>
                </nx-formfield>
            </div>
        </div>
    </div>

    <button [disabled]="dashboardViewModel.isApplyFilterButtonDisabled || getSelectedlocationsCount()"
        (click)="applyFilter()" nxButton="primary small" class="center-align-filter-modal-button">Apply</button>

</ng-template>
<!-- Filter Modal template end -->

<!-- A defect widget icon info -->
<ng-template #defectInfoIconTemplate>
    <a-defect-widget [locationList]="allLocationsData"></a-defect-widget>
</ng-template>