import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout-service';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { ApiErrorViewModel } from './api-error.viewModel';

@Component({
  selector: 'app-api',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss']
})
export class ApiErrorComponent implements OnInit {

  // component's viewmodel
  apiViewModel: ApiErrorViewModel = new ApiErrorViewModel();

  // constructor with required services
  constructor(private layoutService: LayoutService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.apiViewModel.requestId = this.route.snapshot.queryParams.rid;
    if (!this.apiViewModel.requestId) {
      // navigate to locations with data
      this.router.navigate([`${AppRoutes.root}`]);
    }
    // check the device type and update the local variable
    this.apiViewModel.deviceServiceSubscription = this.layoutService.currentDevice.subscribe(result => this.apiViewModel.isDesktop = result);
    if (!this.apiViewModel.isDesktop)
      this.apiViewModel.fontSize = "30px";
  }

  // unsubscribing layout service
  ngOnDestroy() {
    this.apiViewModel.deviceServiceSubscription.unsubscribe();
  }
}
