import { NxModalRef } from '@aposin/ng-aquila/modal';
import { LocationSurveyorContacts } from 'src/app/schemas/location-surveyor-contacts';
import { Location } from 'src/app/schemas/locations';
import { LocationSiteContacts } from 'src/app/schemas/siteContacts';

//Location search model
export class LocationViewModel {

    // pagination per page property
    perPage: number = 10;
    // total number of results returned from API
    resultCount: number = 0;
    // list of locations
    locations: Location[] = [];
    // list of clients locations Ids
    locationIds: string[] = [];
    // object for location details, i.e start date, end date,location id and name
    locationDetails: any;
    // object for items details, i.e start date, end date,location id and name, defect types
    itemDefectDetails: any;
    // boolean for showing more or less tags => Desktop View
    showMore: boolean = true;
    // boolean for showing modal => Mobile view
    showTagsModal: boolean = false;
    // boolean for showing phone-icon-modal
    showPhoneIconModal: boolean = false;
    // boolean for showing attachment-icon-modal
    showAttachmentIconModal: boolean = false;
    //to save selected map, contact, attachment and show on modal
    selectedLocationName: string;
    // object for state details, that can be coming from client page or dashboard page
    stateDetails: any;
    // Pagination variables 
    page: number = 1;
    // Sorting varaibles 
    sortDirection: string = "";
    sortBy: string = "";

    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // modal popup refrence
    dialogRef!: NxModalRef<any, any>;

    // current navigation object from router
    currentNavigationObject: any;
    // to store value of previous url from router
    previousUrl: string = "";
    // variable for location surveyor contacts 
    locationSurveyorContacts: LocationSurveyorContacts[] = [];
    // variable for location Site contacts 
    locationSiteContacts: LocationSiteContacts[] = [];
    // private properties for getter and setters
    private _locationName: string = "";
    private _locationPostcode: string = "";
    private _locationAddress: string = "";
    private _searchDisabled: boolean = true;
    private _showPaging: boolean = true;

    // bit to know that api returned result or not 
    apiResultReturned: boolean = false;

    // readonly keys
    readonly locationIdKey: string = "locationId";
    readonly locationNameKey: string = "locationName";
    readonly locationAddressKey: string = "locationAddress";
    readonly locationPostcodeKey: string = "locationPostcode";
    readonly clientIdsSearchKey: string = "locationCustomerId";
    readonly locationNameAndAddress: string = "locationNameAndAddress";
    // show hide pagination
    get showPaging(): boolean {
        this._showPaging = this.resultCount > 10;
        return this._showPaging;
    }
    set showPaging(value: boolean) {
        this._showPaging = value;
    }

    // location name
    get locationName(): string {
        return this._locationName;
    }
    set locationName(value: string) {
        this._locationName = value;
    }

    // location postcode
    get locationPostcode(): string {
        return this._locationPostcode;
    }
    set locationPostcode(value: string) {
        this._locationPostcode = value;
    }

    // location address
    get locationAddress(): string {
        return this._locationAddress;
    }
    set locationAddress(value: string) {
        this._locationAddress = value;
    }

    // search location button
    get searchDisabled(): boolean {
        // disable the search button if locationName and 
        // locationAdress field has less than 3 and 
        // locationPostcode has less than 2 characters
        this._searchDisabled = this._locationName.length < 3
            &&
            this._locationPostcode.length < 2
            &&
            this._locationAddress.length < 3;
        return this._searchDisabled;
    }
    set searchDisabled(val: boolean) {
        this._searchDisabled = val;
    }
    // clear the item search fields
    clear(): void {
        this._locationName = "";
        this._locationAddress = "";
        this._locationPostcode = "";
    }
    // alpha numerial sorting
    alphaNumericSort = (arr = []) => {
        arr.sort((a, b) => {

            // check if we need to sort a location detail list, then sort by location Name
            return a.locationName.localeCompare(b.locationName.toLowerCase()) || a.locationName - b.locationName;

        });
    };
}