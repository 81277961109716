import { Item } from "src/app/schemas/location-items";

//Item Details View Model
export class ItemDetailsViewModel {
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean = true;
    //test data for attachments
    testDataForAttachments: Array<any> = [
        { fileName: "File 1", type: "Written scheme", dateModified: "25-Apr-2022" },
    ];
    // test data for notes
    testDataForNotes: Array<any> = [
        { name: "Ally", role: "Supervisor", dateAdded: "26/06/2022", notes: "Item notes shown here." },
        { name: "Rachel Bruce", role: "Supervisor", dateAdded: "07/06/2022", notes: "Item notes shown here." },
    ];
    // object to hold details of all fields of any item
    itemDetail: Item;
    // item id passed in query string
    itemId: string;
    //export to csv file name
    readonly exportItemFileName: string = "ExportItems"
}