// Plant not available view model to bind search field and button
export class PlantNotAvailableViewModel {

    // private properties for getter and setters
    private _reportName: string = "";
    private _searchDisabled: boolean = true;
    //check device type
    isDesktop: boolean = false;
    //  report Name
    get reportName(): string {
        return this._reportName;
    }
    set reportName(value: string) {
        this._reportName = value;
    }
    // search user button
    get searchDisabled(): boolean {
        // disable the search button if Name  less than 3 and 
        this._searchDisabled = this._reportName.length < 3;
        return this._searchDisabled;
    }
    set searchDisabled(val: boolean) {
        this._searchDisabled = val;
    }
    // clear the user search field
    clear(): void {
        this._reportName = "";
    }
}