import { Personalisation } from "src/app/schemas/personalisation";
import { SearchModels } from "src/app/schemas/searchModel";
import { UserModel } from "src/app/schemas/users";

//User view model
export class UserViewModel {
    // list of all users
    users: UserModel[] = [];
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // results per page
    perPage: number = 10;
    // count of data from api
    resultCount: number = 0;
    // current page number
    page: number = 1;
    // sorting order 
    order: string = "ASC";
    // sorting by field
    sortName: string = "name";
    // boolean properties
    _showPaging: boolean = true;
    // get params from url
    queryParamList: SearchModels = new SearchModels();
    // field for searching user by name
    userNameKey: string = "employeeName";

    //user personalisation 
    userPersonalisation: Personalisation = new Personalisation();

    // private properties for getter and setters
    private _name: string = "";
    private _searchDisabled: boolean = true;
    private _locationNameAndAddress: string = "";
    private _modelSearchDisabled: boolean = true;
    // readonly keys
    readonly nameKey: string = "name";
    readonly emailKey: string = "email";
    readonly roleKey: string = "role";
    //  name
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }
    //locationNameAndAddress
    get locationNameAndAddress(): string {
        return this._locationNameAndAddress;
    }
    set locationNameAndAddress(value: string) {
        this._locationNameAndAddress = value;
    }
    // search user button
    get searchDisabled(): boolean {
        // disable the search button if Name  less than 3 and 
        this._searchDisabled = this._name.length < 3;
        return this._searchDisabled;
    }
    set searchDisabled(val: boolean) {
        this._searchDisabled = val;
    }
    //this is for  search of new location model
    get modelSearchDisabled(): boolean {
        // disable the search button if locationNameAndAddress  less than 3 and 
        this._modelSearchDisabled = this._locationNameAndAddress.length < 3;
        return this._modelSearchDisabled;
    }

    // clear the user search field
    clear(): void {
        this._name = "";

    }
    //clear model locationNameAndAddress field
    modelClear(): void {
        this._locationNameAndAddress = "";
    }
    // show hide pagination
    get showPaging(): boolean {
        this._showPaging = this.resultCount > 10;
        return this._showPaging;
    }
    set showPaging(value: boolean) {
        this._showPaging = value;
    }
}
