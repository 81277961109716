import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { adminItemGroup, adminItemGroupIdName } from 'src/app/schemas/itemsGroup';
import { LocalStorageKeys } from '../local-storage/local-storage-keys';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { GraphQLService } from './graphql.service';

@Injectable()
export class ItemGroupService {
  // standard constructor with graphql wrapper injected
  public constructor(private graphQlWrapper: GraphQLService, private localStorageService: LocalStorageService) { }

  //get admin item group and location
  getAdminItemGroup(): Observable<ApolloQueryResult<any>> {
    return this.graphQlWrapper.getList(adminItemGroup, {});
  }

  //get admin item group id and name
  getAdminItemIdNameGroup(): Observable<ApolloQueryResult<any>> {
    return this.graphQlWrapper.getList(adminItemGroupIdName, {});
  }

  // method to provide all the location Ids of only assigned Item Groups
  getLocationIdsAssignedItemGroups = (): any[] => {
    let allLocationIds = []
    // get the object from local storage
    const selectedItemGroupsList = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups);
    if (selectedItemGroupsList && selectedItemGroupsList.length > 0) {
      // iterate and populate the list to send back
      selectedItemGroupsList.forEach(itemGroup => {
        if (itemGroup.locationIds && itemGroup.locationIds.length > 0) {
          allLocationIds = [...allLocationIds, ...itemGroup.locationIds]
        }
      })
    }
    return allLocationIds;
  }

  // method to provide all the names of selected Item Groups Ids
  getSelectedItemGroupsIds = (): any[] => {
    let itemGroupsIds = []
    // get the object from local storage
    const selectedItemGroupsIds = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups);
    if (selectedItemGroupsIds && selectedItemGroupsIds.length > 0) {
      // populate the list to send back
      selectedItemGroupsIds.forEach(itemGroup => {
        if (itemGroup.id) {
          itemGroupsIds.push(itemGroup.id)
        }
      })
    }
    return itemGroupsIds;
  }

  // method to provide all the names of assigned Item Groups
  getAssignedItemGroupsNames = (): any[] => {
    let allItemGroupsNames = []
    // get the object from local storage
    const assignedItemGroupsNames = this.localStorageService.getObject(LocalStorageKeys.ItemGroups);
    if (assignedItemGroupsNames && assignedItemGroupsNames.length > 0) {
      // populate the list to send back
      assignedItemGroupsNames.forEach(itemGroup => {
        if (itemGroup.name) {
          allItemGroupsNames.push(itemGroup.name)
        }
      })
    }
    return allItemGroupsNames;
  }

  // method to provide all the names of assigned Item Groups Ids
  getAssignedItemGroupsIds = (): any[] => {
    let allItemGroupsIds = []
    // get the object from local storage
    const assignedItemGroupsIds = this.localStorageService.getObject(LocalStorageKeys.ItemGroups);
    if (assignedItemGroupsIds && assignedItemGroupsIds.length > 0) {
      // populate the list to send back
      assignedItemGroupsIds.forEach(itemGroup => {
        if (itemGroup.name) {
          allItemGroupsIds.push(itemGroup.id)
        }
      })
    }
    return allItemGroupsIds;
  }

  // method to provide details of all item groups assigned to a user
  getAssignedItemGroups = (): any[] => this.localStorageService.getObject(LocalStorageKeys.ItemGroups);

}
