import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApolloQueryResult } from "apollo-client";
import { Observable } from "rxjs";
import { take } from 'rxjs/operators';
import { getUserPersonalisation, Personalisation, PersonalisationApi, saveUserPersonalisation } from 'src/app/schemas/personalisation';
import { ComponentName } from 'src/app/shared/enums/componentName';
import { PersonalisationSlotType } from 'src/app/shared/enums/personalisation';
import { PersonalisationResponse } from 'src/app/shared/models/responses/personalisationResponse.Model';
import { UPDATEPERSONALISATION } from 'src/app/statemanagement/actions/personalisation.action';
import { AppStates } from 'src/app/statemanagement/app.state';
import { CommonService } from '../common.service';
import { ErrorLogService } from '../errors/errorService';
import { GraphQLService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalisationService {

  // object of personalisation class to be used late in methods
  public personalization: Personalisation = new Personalisation();
  constructor(private graphQlWrapper: GraphQLService, private store: Store<AppStates>, private errorLogService: ErrorLogService, private commonService: CommonService) { }

  //get user Personalisation detail for per page
  getComponentsSettings(): void {
    try {
      this.graphQlWrapper.getList(getUserPersonalisation(PersonalisationSlotType.FirstSlot),
        null).pipe(take(1)).subscribe((response) => {
          const personalisationResponse = new PersonalisationResponse(response);
          //when user already have saved any of his personalisation.
          if (personalisationResponse.personalisation && personalisationResponse.personalisation.data &&
            this.commonService.isValidJson(personalisationResponse.personalisation.data)) {
            this.personalization = JSON.parse(personalisationResponse.personalisation.data);
          }
        });
    } catch (error) { this.errorLogService.logError(error); }
  }

  //get user Personalisation detail for per page
  async getComponentsSettingsAsync(): Promise<void> {
    try {
      const response = await this.graphQlWrapper.getList(getUserPersonalisation(PersonalisationSlotType.FirstSlot),
        null).pipe(take(1)).toPromise();
      const personalisationResponse = new PersonalisationResponse(response);
      //when user already have saved any of his personalisation.
      if (personalisationResponse?.personalisation?.data &&
        this.commonService.isValidJson(personalisationResponse.personalisation.data)) {
        this.personalization = JSON.parse(personalisationResponse.personalisation.data);
      }
    } catch (error) { this.errorLogService.logError(error); }
  }
  //save user Personalisation detail 
  saveUserPersonalisation(personalisationAPI: PersonalisationApi, personalisation: Personalisation, slotType: string) {
    // graphql wrapper call for new user Personalisation detail  mutation
    return this.graphQlWrapper.create(personalisationAPI, saveUserPersonalisation(slotType)).subscribe(
      personalisationObj => {
        //when get ok response from api then will update the store as well
        if (personalisationObj.data.savePersonalisation.ok) {
          this.store.dispatch(new UPDATEPERSONALISATION(personalisation));
        }
      }
    );
  }

  //save your filter preferences
  saveUserFiltersPersonalisation(personalisationAPI: PersonalisationApi, slotType: string) {
    // graphql wrapper call for new user Personalisation detail  mutation
    return this.graphQlWrapper.create(personalisationAPI, saveUserPersonalisation(slotType));
  }

  //get user Personalisation detail for per page
  getUserFiltersPersonalisation(slotType: string): Observable<ApolloQueryResult<any>> {
    const variables = {
      "personalisationSlot": slotType,
      "data": []
    }
    return this.graphQlWrapper.getList(getUserPersonalisation(slotType), variables);
  }

  //save user Personalisation detail 
  async updateComponentSettings(componentSettings: any, component: ComponentName): Promise<void> {
    // refreshing latest copy of components
    await this.getComponentsSettingsAsync();
    // checking for respective settings and updating local presonalisation object
    if (component === ComponentName.clientList) {
      this.personalization.client = componentSettings;
    }
    else if (component === ComponentName.userList) {
      this.personalization.user = componentSettings;
    }
    else if (component === ComponentName.itemList) {
      this.personalization.item = componentSettings;
    }
    else if (component === ComponentName.editUser) {
      this.personalization.editUser = componentSettings;
    }
    else if (component === ComponentName.mainItem) {
      this.personalization.mainItem = componentSettings;
    }
    // converting it into json string
    const personalisationJsonString = JSON.stringify(this.personalization);
    // prepating request body object
    const personalisationObj: PersonalisationApi = {
      personalisationSlot: PersonalisationSlotType.FirstSlot,
      data: personalisationJsonString,
    }
    // graphql wrapper call for new user Personalisation detail  mutation
    await this.graphQlWrapper.create(personalisationObj, saveUserPersonalisation(PersonalisationSlotType.FirstSlot)).pipe(take(1)).toPromise();
  }
}
