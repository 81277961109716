import { Component, ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { OrganisationService } from 'src/app/services/graphql/organisation.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SharedMessages } from 'src/app/shared/shared-messages';
import { environment } from 'src/environments/environment';
import { OrganisationViewModel } from './organisation.component.viewmodel';

@Component({
    selector: 'app-organisation',
    templateUrl: './organisation.component.html',
    styleUrls: ['./organisation.component.scss']
})

export class OrganisationComponent {
    // view model object
    organisationViewModel: OrganisationViewModel = new OrganisationViewModel();
    // dummy text from shared file
    dummyText = SharedMessages.longDummyText;
    // constructor with multiple service injection
    constructor(private layoutServiceObject: LayoutService, private organisationServiceObject: OrganisationService,
        private itemGroupServiceObject: ItemGroupService, private el: ElementRef, private localStorageService: LocalStorageService) { }

    ngOnInit() {
        // fetch the list of item groups from local storage
        const organisationDetailsObj = this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation)
        // assign values in case of item groups object have value
        if (organisationDetailsObj != null) {
            this.organisationViewModel.organizationObject = organisationDetailsObj
            this.organisationViewModel.organisationListObject = organisationDetailsObj
            this.organisationViewModel.organisationNameObject = this.organisationViewModel.organisationListObject[0].name
            this.organisationViewModel.disableNextButton = false
            this.organisationViewModel.showOrganisationPage = false;
        }
        else {
            this.organisationViewModel.showOrganisationPage = true
        }
    }
    // method for saving the selected organisation from page
    saveOrganisation = async (): Promise<void> => {
        // move to second tab
        this.organisationViewModel.isFirstTabSelected = false
        // find the id of selected organisation to pass to API for saving purpose
        const organizationId = this.organisationViewModel.organisationListObject.find(org => org.name == this.organisationViewModel.organisationNameObject).id;
        this.organisationViewModel.organisationModelObject.organisationId = organizationId;

        // call to API to save selected organisation 
        const organizationObject = await this.organisationServiceObject.updateOrganisation(this.organisationViewModel.organisationModelObject).pipe(take(1)).toPromise();
        if (organizationObject) {
            // get item group details from API
            const itemGroupsList = await this.itemGroupServiceObject.getAdminItemGroup().pipe(take(1)).toPromise();
            if (itemGroupsList?.data?.itemGroups) {
                this.organisationViewModel.itemGroupListObject = itemGroupsList.data.itemGroups
                this.preselectItemGroupFromLS(organizationId)
            }
        }

    }


    // Open the page like modal
    openPage = () => {
        this.organisationViewModel.showOrganisationPage = true;
        this.organisationViewModel.isFirstTabSelected = true;
    }


    // set the next button to disable
    organisationSelected = () => this.organisationViewModel.disableNextButton = false

    // pre select the item group, if it is single
    // in case of multiple, if already we have selected item group in local storage
    // select the previously selected item group
    preselectItemGroupFromLS = (organizationId: any) => {
        if (this.organisationViewModel.itemGroupListObject && this.organisationViewModel.itemGroupListObject.length === 1) {
            this.organisationViewModel.selectedItemGroupName = this.organisationViewModel.itemGroupListObject[0].name
        }
        else {
            const selectedOrganizationObject = this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation)
            if (selectedOrganizationObject && selectedOrganizationObject.length > 0 && selectedOrganizationObject[0].id == organizationId) {
                const selectedItemGroupObject = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups)
                if (selectedItemGroupObject && selectedItemGroupObject.length > 0 && this.organisationViewModel.itemGroupListObject.findIndex(itemgroup => itemgroup.id == selectedItemGroupObject[0].id) > -1) {
                    this.organisationViewModel.selectedItemGroupName = selectedItemGroupObject[0].name

                }

            }
        }
    }

    // method to fetch the details of list of organisation
    fetchOrganisationsList = () => {
        this.organisationServiceObject.getOrganisationList(this.organisationViewModel.organisationNameObject).subscribe(result => {
            if (result?.data?.organisations) {
                // map the list to local variable
                this.organisationViewModel.organisationListObject = result.data.organisations
                const input: HTMLInputElement = this.el.nativeElement.querySelector('#organizationInput') as HTMLInputElement;
                input.focus();
            }
        })
    }

    // method to save the selected item groups from page
    proceed = () => {
        this.organisationViewModel.showOrganisationPage = false
        if (this.organisationViewModel.selectedItemGroupName) {
            const checkedItemGroup = this.organisationViewModel.itemGroupListObject.filter(x => x.name == this.organisationViewModel.selectedItemGroupName)
            this.organisationViewModel.organizationObject = this.organisationViewModel.organisationListObject.filter(org => org.name == this.organisationViewModel.organisationNameObject);
            // look if we have already object present in local storage
            // need to remove the previous to save the fresh preference
            if (this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups))
                this.localStorageService.removeData(LocalStorageKeys.SelectedItemGroups)
            this.localStorageService.saveObject(LocalStorageKeys.SelectedItemGroups, checkedItemGroup)
            // selected organization in local storage
            if (this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation))
                this.localStorageService.removeData(LocalStorageKeys.SelectedOrganisation)
            this.localStorageService.saveObject(LocalStorageKeys.SelectedOrganisation, this.organisationViewModel.organizationObject)
            // Pass the list to observables to show the latest data on header
            this.layoutServiceObject.assignItemGroups(checkedItemGroup)
            this.layoutServiceObject.assignAllItemGroups(this.organisationViewModel.itemGroupListObject)
            this.layoutServiceObject.assignOrganisation(this.organisationViewModel.organizationObject)
        }
    }

    // if the user have an organisation, then popup is closed when click on cancel
    // else in fresh case, user will be navigated to main allianz page
    cancelOrganisation(): void {
        // check if we have saved oraganization, close the popup only
        if (this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation) && this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups)) {
            this.organisationViewModel.showOrganisationPage = false
        }
        // else take the user to main my allianz page
        else {
            window.location.href = environment.myAllianzInternal

        }

    }

    // method to set the button disable in case of no organisation selected from page
    disableButton = () => {
        if (this.organisationViewModel.organisationNameObject == "" || this.organisationViewModel.organisationNameObject == null)
            this.organisationViewModel.disableNextButton = true;
    }



}
