import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { AuthService } from '../auth-service/auth0client.service';
import { environment } from 'src/environments/environment';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { EndpointType } from 'src/app/shared/enums/endpointType';
import { LayoutService } from '../layout-service';
import { ErrorModel } from 'src/app/shared/models/error.Model';
import { Router } from '@angular/router';

export function createApollo(httpLink: HttpLink, authService: AuthService, layoutService: LayoutService, route: Router) {

  // accept header config config for graphql api
  const acceptHeaderConfig = setContext(() => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  // authorization header config for graphql
  const authorizationHeaderConfig = setContext(async () => {
    if (authService.aTkn === null) {
      // set the settings and token to auth service
      authService.aTkn = await authService.getUserToken();
    }
    return {
      headers: {
        Authorization: `Bearer ${authService.aTkn}`
      }
    };
  });

  // Generic graphql error handler, both errors returned by api or network errors
  const error = onError(({ graphQLErrors, networkError = {} as any }) => {
    layoutService.toggleLoader(false);
    // in case of graphql api errors
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "UnAuthenticated Access."));
            break;
          case 'INTERNAL_SERVER_ERROR':
            layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "Internal Server Error."));
            break;
          case 'GRAPHQL_VALIDATION_FAILED':
            layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", "GraphQL Validation Failed."));
            break;
          default:
            layoutService.setBannerError(new ErrorModel("Unknown", "Unknown", err.message));
            break;
        }
        if (err.extensions.debugInfo && (err.extensions.debugInfo.code === 'API_ERROR' || err.extensions.debugInfo.code === 'BAD_REQUEST' || err.extensions.debugInfo.code === 'SYSTEM_ERROR')) {
          layoutService.setBannerError(new ErrorModel(err.extensions.debugInfo.code, err.extensions.debugInfo.id));
        }
      }
    }
    // in case of network error
    if (networkError.status) {
      void route.navigate(["/", "unplannedmaintenance"], { queryParams: { status: networkError.status } });
    }
  });

  // constructed apollo link for HUB host
  const hubLink = ApolloLink.from([acceptHeaderConfig, authorizationHeaderConfig, error, httpLink.create(
    {
      uri: environment.qraphql,
    })]);

  // constructed apollo link for BAE host
  const baeLink = ApolloLink.from([acceptHeaderConfig, authorizationHeaderConfig, error, httpLink.create(
    {
      uri: environment.BAE_HOST,


    })]);

  // setting up apollo cache settings
  const cache = new InMemoryCache({ addTypename: false });

  // default options for graphql
  const defaultOptions = {
    watchQuery: {
      errorPolicy: 'all'
    }
  }

  return {
    // apollo link settings for hubhost endpoint
    hubhost: {
      name: EndpointType.hubHost,
      link: hubLink,
      cache,
      defaultOptions

    },
    // apollo link settings for baehost endpoint
    baehost: {
      name: EndpointType.baeHost,
      link: baeLink,
      cache,
      defaultOptions
    }
  }
}

@NgModule({
  exports: [
    HttpClientModule,
  ],
  providers: [{
    provide: APOLLO_NAMED_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink, AuthService, LayoutService]
  }]
})
export class GraphQLModule { }