<!--Desktop view-->
<div nxRow *ngIf="plantNotAvailableVM.isDesktop" class="nx-margin-x-2xl">

    <div nxCol="12,12.6">
        <div nxRow class="nx-margin-top-s nx-margin-bottom-2m">
            <div nxCol="3">&nbsp;</div>
            <div id="heading" nxCol="7">
                Complete each of section below to create your report.
            </div>
            <div nxCol="2">&nbsp;</div>
        </div>
        <nx-single-stepper currentStepLabel="Step">
            <ng-container *ngFor="let step of stepsData">
                <nx-step [label]="step">
                    <div *ngIf=" step === '1' " class="nx-margin-y-m">
                        <div nxLayout="grid">
                            <div nxRow class="nx-margin-x-xl">
                                <div nxCol="12,12,6">
                                    <nx-formfield nxLabel="Search">
                                        <input nxInput placeholder="Please enter three characters at least" id="name"
                                            [(ngModel)]="plantNotAvailableVM.reportName" tabindex="1" />
                                    </nx-formfield>
                                </div>
                                <div nxCol="12,12,6">
                                    <div class="alignButtons">
                                        <button id="search" [disabled]="plantNotAvailableVM.searchDisabled"
                                            class="nx-margin-right-s " nxButton="primary small" type="button"
                                            (click)="search()">Search</button>
                                        <button class="nx-margin-right-s" type="reset" id="clear"
                                            nxButton="secondary small" (click)="clearSearch()"
                                            type="button">Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="step === '2'" class="nx-margin-top-s">
                        <!--Discipline section-->
                        <div class="left">
                            <h3 nxHeadline="subsection-large">Discipline</h3>
                        </div>
                        <div class="nx-margin-x-m">
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Electrical
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Lift & crane
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Local exhaust ventilation
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Power press
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Pressure mechanical
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">

                                    &nbsp;

                                </div>
                            </div>
                        </div>

                        <!--Report frequency and data range section-->
                        <div class="left" class="nx-margin-top-s">
                            <h3 nxHeadline="subsection-large">Report frequency and date range:</h3>
                        </div>
                        <div nxRow class="nx-margin-top-s">
                            <div nxCol="12,6">
                                <nx-radio-group name="myGroup">
                                    <nx-radio nxValue="1" class="nx-margin-bottom-m">One-time report

                                    </nx-radio>
                                    <nx-radio nxValue="2">Auto scheduled report</nx-radio>
                                </nx-radio-group>
                            </div>
                        </div>
                        <div nxRow class="nx-margin-top-s">
                            <div nxCol="12,6">
                                <nx-formfield nxLabel="Inspection date from">
                                    <input nxDatefield nxInput [nxDatepicker]="fromDatepicker" placeholder="Start date"
                                        [(ngModel)]="fromDate" (dateChange)="fromDatepicker()" />
                                    <span class="span-float" nxFormfieldHint>MM/DD/YYYY</span>
                                    <nx-datepicker-toggle [for]="fromDatepicker" nxFormfieldSuffix>
                                    </nx-datepicker-toggle>
                                    <nx-datepicker #fromDatepicker></nx-datepicker>
                                </nx-formfield>
                            </div>
                        </div>
                        <div nxRow class="nx-margin-top-s">
                            <div nxCol="12,6">
                                <nx-formfield nxLabel="Inspection date to">
                                    <input nxDatefield nxInput [nxDatepicker]="toDatepicker" [(ngModel)]="toDate"
                                        placeholder="End date" (dateChange)="toDatepicker()" />
                                    <span class="span-float" nxFormfieldHint>MM/DD/YYYY</span>
                                    <nx-datepicker-toggle [for]="toDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                                    <nx-datepicker #toDatepicker></nx-datepicker>
                                </nx-formfield>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step === '3'" class="nx-margin-top-s">
                        <!--Location section-->
                        <div class="left" class="nx-margin-top-s">
                            <h3 nxHeadline="subsection-large">Location:</h3>
                        </div>
                        <div class="nx-margin-x-m">
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Location name
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Address
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Postcode
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Seen at address
                                    </nx-checkbox>
                                </div>
                            </div>
                        </div>
                        <!--Item section-->
                        <div class="left" class="nx-margin-top-s">
                            <h3 nxHeadline="subsection-large">Item:</h3>
                        </div>
                        <div class="nx-margin-x-m">
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Client plant number
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Sub location
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Detailed location</nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Discipline
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Description
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">

                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Serial number
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Unique item ID
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">

                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Status
                                    </nx-checkbox>
                                </div>
                            </div>
                        </div>
                        <!--Report section-->
                        <div class="left" class="nx-margin-top-s">
                            <h3 nxHeadline="subsection-large">Report:</h3>
                        </div>
                        <div class="nx-margin-x-m">
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Report ID
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Inspection date
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Next Inspection date
                                    </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Inspection type
                                    </nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Defect summary </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s"> Defect code</nx-checkbox>


                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s">
                                        Supplementory tests </nx-checkbox>
                                </div>
                                <div nxCol="12,6">
                                    <nx-checkbox class="nx-margin-bottom-s"> PNA reason</nx-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-centered">

                        <button nxButton="primary  small" nxStepperPrevious class="nx-margin-right-2xs" type="button">
                            <nx-icon name="chevron-left-small"> </nx-icon> Back
                        </button>

                        <!--Step 3 buttons start-->
                        <button id="savereport" *ngIf="step === '3'" nxButton="primary  small"
                            class="nx-margin-right-2xs" (click)="navigateToReport()" type="button">
                            <nx-icon name="save-o"> </nx-icon>&nbsp; Save report
                        </button>
                        <button id="exportreport" *ngIf="step === '3'" nxButton="primary small"
                            class="nx-margin-right-2xs" type="button">
                            <nx-icon name="download"></nx-icon>&nbsp; Export report
                        </button>

                        <button id="viewreport" *ngIf="step === '3'" nxButton="primary small"
                            class="nx-margin-right-2xs" type="button">
                            <nx-icon name="chevron-right-small"></nx-icon> View report
                        </button>
                        <!--Step 3 buttons end-->
                        <button *ngIf="step !== '3'" nxButton="primary small" class="nx-margin-left-m" nxStepperNext
                            type="button">
                            Next <nx-icon name="chevron-right-small"></nx-icon>
                        </button>
                    </div>
                </nx-step>
            </ng-container>
        </nx-single-stepper>
    </div>

</div>

<!--Mobile view-->
<div *ngIf="!plantNotAvailableVM.isDesktop">
    <div id="mobile-heading">
        Complete each of section below to create your report.
    </div>
    <nx-single-stepper currentStepLabel="Step">
        <ng-container *ngFor="let step of stepsData">
            <nx-step [label]="step">
                <div *ngIf=" step === '1' ">
                    <div class="desktopSearchFilter nx-margin-x-s">
                        <div nxLayout="grid">
                            <div nxRow>
                                <div class="desktopSearchFilter-change-width">
                                    <nx-formfield nxLabel="Search">
                                        <input nxInput placeholder="Please enter three characters at least" id="name"
                                            [(ngModel)]="plantNotAvailableVM.reportName" tabindex="1" />
                                    </nx-formfield>
                                </div>
                                <div class="alignButtons">
                                    <button id="search" [disabled]="plantNotAvailableVM.searchDisabled"
                                        class="nx-margin-right-s " nxButton="primary small" type="button"
                                        (click)="search()">Search</button>
                                    <button class="nx-margin-right-s" type="reset" id="clear" nxButton="secondary small"
                                        (click)="clearSearch()" type="button">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="step === '2'" class="nx-margin-top-s nx-margin-x-s">
                    <!--Discipline section-->
                    <div class="left">
                        <h3 nxHeadline="subsection-large">Discipline</h3>
                    </div>
                    <div class="nx-margin-x-s">
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Electrical
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Lift & crane
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Local exhaust ventilation
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Power press
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Pressure mechanical
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">

                                &nbsp;

                            </div>
                        </div>
                    </div>

                    <!--Report frequency and data range section-->
                    <div class="left" class="nx-margin-top-s">
                        <h3 nxHeadline="subsection-large">Report frequency and date range:</h3>
                    </div>
                    <div nxRow class="nx-margin-top-s nx-margin-left-xs">
                        <div nxCol="12,12,6">
                            <nx-radio-group name="myGroup" class="align-radio-buttons">
                                <nx-radio nxValue="1" class="nx-margin-bottom-m">
                                    <span class="fix-font-size">One-time report</span>
                                </nx-radio>
                                <nx-radio nxValue="2" class="fix-font-size">
                                    <span class="fix-font-size">Auto scheduled report</span>
                                </nx-radio>
                            </nx-radio-group>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-top-s ">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Inspection date from">
                                <input nxDatefield nxInput [nxDatepicker]="fromDatepicker" placeholder="Start date"
                                    [(ngModel)]="fromDate" (dateChange)="fromDatepicker()" />
                                <span class="span-float" nxFormfieldHint>MM/DD/YYYY</span>
                                <nx-datepicker-toggle [for]="fromDatepicker" nxFormfieldSuffix>
                                </nx-datepicker-toggle>
                                <nx-datepicker #fromDatepicker></nx-datepicker>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-top-s">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Inspection date to">
                                <input nxDatefield nxInput [nxDatepicker]="toDatepicker" placeholder="End date"
                                    [(ngModel)]="toDate" (dateChange)="toDatepicker()" />
                                <span class="span-float" nxFormfieldHint>MM/DD/YYYY</span>
                                <nx-datepicker-toggle [for]="toDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                                <nx-datepicker #toDatepicker></nx-datepicker>
                            </nx-formfield>
                        </div>
                    </div>
                </div>
                <div *ngIf="step === '3'" class="nx-margin-top-s nx-margin-x-s">
                    <!--Location section-->
                    <div class="left" class="nx-margin-top-s">
                        <h3 nxHeadline="subsection-large">Location:</h3>
                    </div>
                    <div class="nx-margin-x-s">
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Location name
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Address
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Postcode
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Seen at address
                                </nx-checkbox>
                            </div>
                        </div>
                    </div>
                    <!--Item section-->
                    <div class="left" class="nx-margin-top-s">
                        <h3 nxHeadline="subsection-large">Item:</h3>
                    </div>
                    <div class="nx-margin-x-s">
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Client plant number
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Sub location
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Detailed location</nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Discipline
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Description
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">

                                <nx-checkbox class="nx-margin-bottom-s">
                                    Serial number
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Unique item ID
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">

                                <nx-checkbox class="nx-margin-bottom-s">
                                    Status
                                </nx-checkbox>
                            </div>
                        </div>
                    </div>
                    <!--Report section-->
                    <div class="left" class="nx-margin-top-s">
                        <h3 nxHeadline="subsection-large">Report:</h3>
                    </div>
                    <div class="nx-margin-x-s">
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Report ID
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Inspection date
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Next Inspection date
                                </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Inspection type
                                </nx-checkbox>
                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Defect summary </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s"> Defect code</nx-checkbox>


                            </div>
                        </div>
                        <div nxRow>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s">
                                    Supplementory tests </nx-checkbox>
                            </div>
                            <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-s"> PNA reason</nx-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-buttons-centered">
                    <button nxButton="primary  small" *ngIf="step !== '3'" nxStepperPrevious class="nx-margin-left-2xs"
                        type="button">
                        <nx-icon name="chevron-left-small"> </nx-icon> Back
                    </button>

                    <!--Step 3 buttons start-->
                    <div class="button-alignment-step-three" *ngIf="step === '3'">


                        <button id="savereport" *ngIf="step === '3'" nxButton="primary  small"
                            (click)="navigateToReport()" class="nx-margin-left-2xs nx-margin-bottom-s" type="button">
                            <nx-icon name="save-o"> </nx-icon>&nbsp; Save report
                        </button>
                        <button id="exportreport" *ngIf="step === '3'" nxButton="primary small"
                            class="nx-margin-left-2xs nx-margin-bottom-s" type="button">
                            <nx-icon name="download"></nx-icon>&nbsp; Export report
                        </button>

                        <button id="viewreport" *ngIf="step === '3'" nxButton="primary small"
                            class="nx-margin-left-2xs nx-margin-bottom-s" type="button">
                            <nx-icon name="chevron-right-small"></nx-icon> View report
                        </button>

                        <button nxButton="primary  small" nxStepperPrevious
                            class="nx-margin-left-2xs nx-margin-bottom-s" type="button">
                            <nx-icon name="chevron-left-small"> </nx-icon> Back
                        </button>
                    </div>
                    <!--Step 3 buttons end-->
                    <button *ngIf="step !== '3'" nxButton="primary small" class="nx-margin-left-2xs" nxStepperNext
                        type="button">
                        Next <nx-icon name="chevron-right-small"></nx-icon>
                    </button>
                </div>
            </nx-step>
        </ng-container>
    </nx-single-stepper>
</div>