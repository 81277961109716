//get user personalisation 
import gql from "graphql-tag";
export const getUserPersonalisation = (slot: string) => {
  return gql`query personalisation{
   personalisation(personalisationSlot: ${slot} ){
     personalisationSlot
     data
   }
  }`
}
// save user personalisation
export const saveUserPersonalisation = (slot: string) => {
  return gql`
mutation savePersonalisation($data: String!){
    savePersonalisation(input:{
      personalisationSlot: ${slot},
      data: $data
    }){
      ok
    }
  }`
}
// personalisation class 
// to hold personalisation at one place
// to manage it via store object
export class PersonalisationApi {
  personalisationSlot: string;
  data: string;
}

export class Personalisation {
  item: ItemPersonalization = new ItemPersonalization();
  user: UserPersonalization = new UserPersonalization();
  client: ClientPersonalization = new ClientPersonalization();
  editUser: EditUserPersonalization = new EditUserPersonalization();
  mainItem: MainItemPersonalization = new MainItemPersonalization();
}
//item view related personalisation class
export class ItemPersonalization {
  // initializing with default per page value
  perPageToggle: string = "10"
}

//main item view related personalisation class
export class MainItemPersonalization {
  // initializing with default per page value
  perPageToggle: string = "10"
}
//user view related personalisation class
export class UserPersonalization {
  // initializing with default per page value
  perPageToggle: string = "10"
}

//client view related personalisation class
export class ClientPersonalization {
  // initializing with default per page value
  perPageToggle: string = "10"
}

//user edit view related personalisation class
export class EditUserPersonalization {
  // initializing with default per page value
  perPageToggle: string = "10"
}


