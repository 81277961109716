import { Injectable } from "@angular/core";
import { OrganisationEmployeeModel, organisationList, OrganisationModel, updateOrganisation, updateOrganisationEmployee } from "src/app/schemas/organisation";
import { GraphQLService } from "./graphql.service";
import { getCompanyName } from "src/app/schemas/company";

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {

    // standard constructor with graphql wrapper injected
    public constructor(private graphQlWrapper: GraphQLService) { }

    //get organisation list
    getOrganisationList(organizationName: string) {
        return this.graphQlWrapper.getList(organisationList(organizationName), {});
    }

    // update the organisation Id
    updateOrganisation(organisationModel: OrganisationModel) {
        return this.graphQlWrapper.create(organisationModel, updateOrganisation);

    }

    // update organisation of internal Admin
    // when newly created client/standard admin belongs to different organisation
    updateOrganisationEmployee(organisationModel: OrganisationEmployeeModel) {
        return this.graphQlWrapper.create(organisationModel, updateOrganisationEmployee)
    }

    // retrieve the current logged in user organisation name
    getCurrentUserOrganisationName = () => this.graphQlWrapper.getList(getCompanyName, "");

}
