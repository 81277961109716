import { Action } from '@ngrx/store'
import { Employee } from 'src/app/schemas/users';


// set logged-in user action type
export const SET_LOGGEDINUSER = '[LOGGEDINUSER] Set'

// set loggedin user action
export class SetUser implements Action {

    readonly type = SET_LOGGEDINUSER
    constructor(public payload: Employee) {
    }
}
// exported all the logged in user actions
export type LoggedInUserActions = SetUser;