import { Pipe, PipeTransform } from '@angular/core';
import { DisplayRoles, RolesFromApi } from '../../enums/role';
/*
 * Transform the role coming from api into displayable UI specific value
 * Takes a api roles argument.
*/

// pipe name
@Pipe({ name: 'displayRole' })
export class DisplayRolePipe implements PipeTransform {
    // transform takes list of roles comming from api
    transform(roles: string): string {
        // if roles array is undefined or empty
        if (!roles || roles.length == 0) {
            // returns empty string to display on UI
            return DisplayRoles.empty;
        }

        // input the roles first item ( for now we are getting only one item from api )
        switch (roles) {
            // conversion for standard client user
            case RolesFromApi.standardClientUser:
                return DisplayRoles.standardClientUser;
            // conversion for standard client admin
            case RolesFromApi.standardClientAdmin:
                return DisplayRoles.standardClientAdmin;
            case RolesFromApi.clientAdmin:
                return DisplayRoles.standardClientAdmin;
            case RolesFromApi.organizationUser:
                return DisplayRoles.organizationUser;
            default:
                // by default lets return empty string
                return DisplayRoles.empty;
        }
    }
}