import { Injectable } from "@angular/core";

// Service to provide data present in local storage
@Injectable()
export class LocalStorageService {

    // save string to localstorage
    saveString = (key: string, value: string): void => {
        localStorage.setItem(key, value);
    }

    // save object to localstorage
    saveObject = (key: string, value: any): void => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // get data against a key
    getString = (key: string): string => {
        return localStorage.getItem(key);
    }

    // get data against a key
    getObject = (key: string): any => {
        return JSON.parse(localStorage.getItem(key));
    }

    // remove data by its key
    removeData = (key: string): void => {
        localStorage.removeItem(key);
    }

    // flush local storage
    clearData = (): void => {
        localStorage.clear();
    }
    // check if key exists
    exists = (key: string): boolean => {
        return localStorage.hasOwnProperty(key);
    };
}