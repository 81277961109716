import { Component, Input } from '@angular/core';

@Component({
  selector: 'banner-notification',
  templateUrl: './banner-notification.component.html'
})

// common banner component for notifications
export class BannerNotificationComponent {

  // message property to show
  @Input() message: string;
  // context property to set
  @Input() messageContext: string;
  // id property to set
  @Input() id: string;

  constructor() {
    // This is intentional
  }
}
