// Generic object for a search parameter
export interface SearchModel {
    field: string;
    value: string;
}
// List of search params to pass to graphql api
export class SearchModels {
    // initiated list of search model
    searchList: SearchModel[] = [];
    // resets current searchlist 
    clear(length: number): void {
        this.searchList.length = length;
    }
    // remove specific search model from searchList
    remove(model: SearchModel): void {
        this.searchList = this.searchList.filter(obj => obj !== model);
    }
}

// interface containing the details of client i-e client Id and name
export interface ClientDetailSearchModel {
    clientId: string;
    clientName: string;
}
// class containing the list of client details
export class ClientDetailListSearchModel {
    clientIdList: ClientDetailSearchModel[] = [];
}


