import gql from 'graphql-tag';


// query to get required item list with against locations
export const getItemsByLocations = gql`query search($search: [Search]!, $aggregation: [SearchAggregation],  $sortBy: [Sort]!, $from: NonNegativeInt!, $size: Int!, $isAdminQuery: Boolean){

  search(search: $search, aggregation: $aggregation, sort: $sortBy, from: $from, size: $size, isAdminQuery: $isAdminQuery,operator:OR) {
              totalCount
      count
      results {
        ... on Item {
              id
          clientPlantNumber
          plantType {
                  code
            description
              }
          location {
            code
            name
            postcode
            description
            subCodeDescription
              }
       schemeReference{
                fileName
                url
            }
          reports{
            results
            {
                      ... on InspectionReportDetails{
                          id
                          inspectionDate
                          defectCode{
                              code
                          }
                          url
                          itemDefects{
                              defectType
                              description
                              daysToFix
                          }
            }}}
        
          inspections{
            inspectionDate
            nextInspectionDate
            nextInspectionType{
              code
              description
                      }
            previousInspectionType{
              code
              description
                  }
            nextInspectionDateWorking
            nextInspectionDateThorough
            workingPeriod
            previousInspectionDateWorking
            previousInspectionDateThorough
            
            partialExamFlag
              }
          discipline {
            code
            description
          }
          examinationProcedureCode
          status {
            code
            description
      }
          movedAboutPlant
          policyNumber
          policyPrefix
          serialNumber
          manufacturer
          manufacturingYear
         
              }
      }
      }
      
  }
`;

// query to get required item detail with against item Id
export const getItemDetailByLocations = gql`query search($search: [Search]! ,$sortBy: [Sort], $from: NonNegativeInt!, $size: Int!){
  search(search: $search, sort: $sortBy, from: $from, size: $size, operator:OR)
  {
      totalCount
      count
      results {
        ... on Item {
          id
          clientPlantNumber
          plantType {
            code
            description
          }
          schemeReference{
            fileName
            url
        }
          location {
            code
            description
            subCodeDescription
          }
        
          reports (sort: {field: inspectionDate, order:DESC}, size:6 ){
            results{
            ... on InspectionReportDetails{
                id
                previousInspectionDate
                inspectionDate
                defectCode{
                    code
                }
                url
                attachments{                  
                  url                  
                   }
            }
        }}
        
          inspections{
            inspectionDate
            nextInspectionDate
            nextInspectionType{
              code
              description
            }
            previousInspectionType{
              code
              description
            }
            nextInspectionDateWorking
            nextInspectionDateThorough
            workingPeriod
            previousInspectionDateWorking
            previousInspectionDateThorough
            
            partialExamFlag
          }
          discipline {
            code
            description
          }
          examinationProcedureCode
          status {
            code
            description
          }
          movedAboutPlant
          policyNumber
          policyPrefix
          serialNumber
          manufacturer
          manufacturingYear
        }
      }
   }
}
`;


// query to get item detail report URl only
export const getItemDetailReportURL = gql`query search($search: [Search]! ,$sortBy: [Sort], $from: NonNegativeInt!, $size: Int!){
  search(search: $search, sort: $sortBy, from: $from, size: $size, operator:OR)
  {

      results {
        ... on Item {
          reports (sort: {field: inspectionDate, order:DESC}, size:6 ){
            results{
            ... on InspectionReportDetails{
              id
                url
              
            }
        }}
        
        }
      }
   }
}
`;


// query to get required item list with against locations for main item view
export const getItemsByLocationsForMainItemView = gql`query search($search: [Search]!, $aggregation: [SearchAggregation],  $sortBy: [Sort]!, $from: NonNegativeInt!, $size: Int!, $isAdminQuery: Boolean){

  search(search: $search, aggregation: $aggregation, sort: $sortBy, from: $from, size: $size, isAdminQuery: $isAdminQuery,operator:OR) {
              totalCount
      count
      results {
        ... on Item {
              id
          clientPlantNumber
          plantType {
                  code
            description
              }
          location {
            code
            name
            postcode
            description
            subCodeDescription
              }
       schemeReference{
                fileName
                url
            }
          reports{
            results
            {
                      ... on InspectionReportDetails{
                          id
                          inspectionDate
                          defectCode{
                              code
                          }
                          url
                          itemDefects{
                              defectType
                              description
                              daysToFix
                          }
            }}}
        
          inspections{
            inspectionDate
            nextInspectionDate
            nextInspectionType{
              code
              description
                      }
            previousInspectionType{
              code
              description
                  }
            nextInspectionDateWorking
            nextInspectionDateThorough
            workingPeriod
            previousInspectionDateWorking
            previousInspectionDateThorough
            
            partialExamFlag
              }
          discipline {
            code
            description
          }
          examinationProcedureCode
          status {
            code
            description
      }
          movedAboutPlant
          policyNumber
          policyPrefix
          serialNumber
          manufacturer
          manufacturingYear
         
              }
      }
      }
      
  }
`;



// item model returned by items api call, api returns list of Item
export interface Item {
  id: string
  clientPlantNumber: string
  plantType: ItemCodeDescription
  location: ItemLocation
  discipline: ItemDiscipline
  examinationProcedureCode: string
  status: ItemStatus
  inspections: ItemInspections
  reports: ItemReportDetails
  movedAboutPlant: boolean
  policyNumber: string
  policyPrefix: string
  schemeReference: SchemeReferences
  serialNumber: string
  manufacturer: string
  manufacturingYear: string
  // ----------- Item formatted current report object ------//
  latestReport: ItemLatestCurrentReport

  //-----------Item sub defects Array--------//
  subDefects: string[]
  //----------Tooltips Objects --------//
  toolTipPlantNo: string
  toolTipSerialNo: string
  toolTipSubLocation: string
  toolTipDetailedLocation: string
  toolTipReportId: string
  toolTipLocationName: string
  // -------- UI Item expandable property for mobile view ------------ //
  showMore: boolean
  //------ defect code badge color----------//
  defectCodeBadgeColor: string
  //-------------fields used to import data for excel -----
  reportID: string;
  reportClientPlantNumber: string;
  //--------------- location details ------------//
  locationName: string;
  locationPostcode: string;
}

// child object of status an item
export interface ItemStatus {
  code: string
  description: string
}
// child object of plant type an item
export interface ItemCodeDescription {
  code: string
  description: string
}
// child object of location an item
export interface ItemLocation {
  code: string
  name: string
  postcode: string
  description: string
  subCodeDescription: string
}
// child object of report of item
export interface ItemCurrentReport {
  id: string
  url: string
  item: InspectionReportDetailsItem
  defectCode: ItemCodeDescription
  inspectionType: ItemInspectionType
  inspectionDate: Date
  previousInspectionDate: Date
  nextInspectionDateWorking: Date
  nextInspectionDateThorough
  nextInspectionType: ItemCodeDescription
  nextWorkingInspectionDate: Date
  nextThoroughInspectionDate: Date
  overdue: boolean
}
// child object of discipline an item
export interface ItemDiscipline {
  code: string
  description: string
}
// child object of inspection type an item
export interface ItemInspectionType {
  description: string
}
// child object of inspections
export interface ItemInspections {
  inspectionDate: Date
  nextInspectionDate: Date
  nextInspectionType: ItemCodeDescription
  previousInspectionType: ItemCodeDescription
  previousInspectionDateWorking: Date
  previousInspectionDateThorough: Date
  nextInspectionDateWorking: Date
  nextInspectionDateThorough: Date
  workingPeriod: number
  partialExamFlag: boolean
}

// child object of inspection type
export interface InspectionReportDetailsItem {
  id: string
  discipline: ItemCodeDescription
  location: InspectionReportDetailsLocation
}
// child object to have the details of report code
export interface InspectionReportDetailsLocation {
  code: string
}
// child object containing details of item report details
export interface ItemReportDetails {
  results: [ItemDefectsDetails]
}
// child object containing details of only the latest current report 
export interface ItemLatestCurrentReport {
  id: string
  url: string
  inspectionDate: Date
  defectCode: ItemCodeDescription,
  attachments: any,
  previousInspectionDate: Date
}
// child object containing details of item defect details
export interface ItemDefectsDetails {
  id: string
  url: string
  inspectionDate: Date
  defectCode: ItemCodeDescription
  itemDefects: [ItemDefectType],
  defectCodeBadgeColor: string,
  attachments: any,
  previousInspectionDate: Date
}
// child object containing defect type , which is actually the sub defect type 
export interface ItemDefectType {
  defectType: string
}

// model to map items api response
export class ItemResult {
  resultCount: number;
  items: Item[] = [];
}
//For export location item 
export class ExportLocationItem {
  location?: string;
  reportId?: string;
  serialNo: string;
  plantNo: string;
  plantDescription: string;
  discipline: string;
  lastInspected: Date | string;
  defectCode: string;
  nextDue: Date | string;
  subLocation: string;
  detailedLocation: string;
  itemstatus: string;
  policyNumber: string;
  ACEPlantNo: string
  schemeReference: SchemeReferences
  nextContractualDate: Date | string
  manufacturer: string
  manufacturingYear: string

}

//class will be used to store search fields of item
export class SearchItems {
  itemSerialNumber: string = "";
  itemPlantTypeDescription: string = "";
  itemDisciplineCode: string = "";
  itemCurrentReportDefectCode: string = "";
  itemLocationDescription: string = "";
  itemClientPlantNumber: string = "";
  itemStatusDescription: string = "";
  itemDisciplineDescription: string = "";
  itemCurrentReportReportId: string = "";
  itemLocationName: string = "";
  itemLocationPostcode: string = ""
}
//WSE report detailed object
export class SchemeReferences {
  fileName: string = "";
  url: string = ""
}