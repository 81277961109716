import gql from 'graphql-tag';

  // fetch user reference titles
  export const getUserTitles = gql`{
    referenceData(type: TITLE){
        key
        val
    }
}`

// reference user titles interface
export interface UserTitle {
    key: number
    val: string
  }