import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout-service';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
// component to show banner on top with light blue background
export class BannerComponent implements OnInit {
    // text to display inside banner
    bannerText: string;
    // to show banner title above bannerText
    bannerTitle: string
    // text to display inside sub banner
    subBannerText: string;
    // to update ui as soon as we update banner text through banner service
    bannerTextSubscription: Subscription;
    // subscrition for banner height
    bannerHeightSubscription: Subscription;
    //sub banner subcribtion
    subBannerSubscription: Subscription;
    // banner height value
    bannerHeight: string;
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // hide banner on Location Items component
    showBanner: boolean = false;
    // subscription for hiding banner on locations items
    showBannerSubscription: Subscription;
    // bannerTitle subscription
    bannerTitleSubscription: Subscription;
    // current device subscription
    currentDeviceSubscription: Subscription;

    // constructor with banner service injection
    constructor(private layoutService: LayoutService, public router: Router) { }

    // subscribe to banner text on init
    ngOnInit(): void {
        // bannerTitle subscription
        this.bannerTitleSubscription = this.layoutService.currentBannerTitle.subscribe(bannerTitle => this.bannerTitle = bannerTitle)
        this.bannerTextSubscription = this.layoutService.currentBannerText.subscribe((bannerText: string) => this.bannerText = bannerText)
        this.bannerHeightSubscription = this.layoutService.currentBannerHeight.subscribe((height: string) => this.bannerHeight = height)
        //sub banner height
        this.subBannerSubscription = this.layoutService.currentSubBannerText.subscribe((subBannerText: string) => this.subBannerText = subBannerText)
        // subscribe to device type detection observable
        this.currentDeviceSubscription = this.layoutService.currentDevice.subscribe(result => this.isDesktop = result);
        // subscribe to show/hide banner
        this.showBannerSubscription = this.layoutService.showCurrentBanner.subscribe(result => {
            this.showBanner = result;
        })
    }
    // unsubscribe on component destroy 
    ngOnDestroy() {
        this.bannerTextSubscription.unsubscribe();
        this.bannerHeightSubscription.unsubscribe();
        this.subBannerSubscription.unsubscribe();
        this.showBannerSubscription.unsubscribe();
        this.bannerTitleSubscription.unsubscribe();
        this.currentDeviceSubscription.unsubscribe();
    }
}
