import { Pipe, PipeTransform } from "@angular/core";
import { Item } from "src/app/schemas/location-items";
import { Colors } from "../../enums/colors";

@Pipe({
    name: 'defectTextColorPipe'
})
// get color for a defect code
export class DefectTextColorPipe implements PipeTransform {
    // takes whole item 
    transform(item: Item): string {
        // check if defect code is there
        if (item?.reports?.results.length > 0 && item.reports?.results[0]?.defectCode.code) {
            const defectCode = item.reports?.results[0]?.defectCode.code;
            // switch for defect code
            switch (defectCode) {
                case "AE":
                case "AT":
                case "AI":
                case "A":
                case "B":
                case "N":
                case "P":
                    return Colors.White;
                case "C":
                    return Colors.Black;
                default:
                    return Colors.Black;
            }
        }
        // returning default color
        return Colors.Black;
    }
}