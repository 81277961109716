
import * as UserActions from '../actions/user.actions'
import { CurrentUser } from '../models/current-user.model';

const initialState: CurrentUser = {
    // initialized role, first name and last name with empty string
    roles: [],
    firstName: "",
    lastName: "",
    itemGroups: [],
    eNotification: false,
    eReporting: false
}
// user reducer to update the state with provided actions
export function reducer(state: CurrentUser = initialState, action: UserActions.UserActions = {
    type: '[CURRENTUSER] Set',
    payload: undefined
}) {
    // to set current user properties after login
    if (action.type == UserActions.SET_CUERRENTUSER) {
        return { ...state, roles: action.payload.roles, firstName: action.payload.firstName, lastName: action.payload.lastName, eNotification: action.payload.eNotification, eReporting: action.payload.eReporting, itemGroups: action.payload.itemGroups }
    }
    else {
        // return state as it is
        return state;
    }
}

