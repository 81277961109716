import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "apollo-client";
import { BehaviorSubject, Observable } from "rxjs";
import { SearchModel } from "src/app/schemas/searchModel";
import { GraphQLService } from "./graphql.service";
import {
    createEmployee, Employee, getAllemployees, getCompanyAndLocations, getEmployeeById, getCurrentEmployee, UpdateEmployee, UserCompany, UserRole,
    SortEmployeeField,
    updateEmployee,
    updateUserPermissions,
    updatePreferences,
    PreferencesUpdateModel,
    updateAdminPermissions
} from "src/app/schemas/users";
import { RolesFromApi } from "src/app/shared/enums/role";

@Injectable()

// user service to fetch its data
export class UserService {

    // user company object to return as observable
    userCompanyAndLocations = new BehaviorSubject<UserCompany>(null);
    saveNewEmployeeResponse = new BehaviorSubject<boolean>(null);

    // standard constructor with graphql wrapper injected
    public constructor(private graphQlWrapper: GraphQLService) { }
    // fetch user company and location as client admin
    getCompanyAndLocations(): Observable<UserCompany> {
        // graphql wrapper call
        this.graphQlWrapper.getList(getCompanyAndLocations, null, false)
            // get apolloqueryresult and only return required data object
            .subscribe(apolloQueryResult => {
                // get data from apolloqueryresult
                const { data } = apolloQueryResult;
                // pass data back to calling end
                this.userCompanyAndLocations.next(data);
            });
        // return user company and locations as observable
        return this.userCompanyAndLocations.asObservable();
    }
    //save employee
    saveNewEmployee(employeeModel: Employee) {
        // graphql wrapper call for new employee  mutation
        return this.graphQlWrapper.create(employeeModel, createEmployee);
    }
    //get all employees 
    getAllEmployees(searchList: SearchModel[], sortEmployeeField: SortEmployeeField, from: number, size: number): Observable<ApolloQueryResult<any>> {
        const variables = {
            "sortBy": sortEmployeeField,
            "from": from,
            "size": size,
            "search": searchList,
        }
        return this.graphQlWrapper.getList(getAllemployees, variables);
    }
    //get employee by id
    getEmployeeById(employeeId: any) {
        const variables = {
            "employeeId": employeeId,
        }
        return this.graphQlWrapper.getList(getEmployeeById, variables);
    }
    //get few details of current logged in user / employee
    getCurrentEmployee = () => {
        const variables = {
            "employeeId": '',
        }
        return this.graphQlWrapper.getList(getCurrentEmployee, variables);
    }
    // update user/admin  permissions by user id
    updateUserRolePermissions(user: UserRole) {
        if (user.role === RolesFromApi.standardClientUser) {
            // need to remove this role prop
            delete user.role;
            return this.graphQlWrapper.create(user, updateUserPermissions);
        }
        else {
            // need to remove this role prop
            delete user.role;
            return this.graphQlWrapper.create(user, updateAdminPermissions);
        }

    }

    // update or remove  user locations is being used to add or remove assigned location to user
    updateRemoveUserLocation(updateRemoveLocation: UpdateEmployee) {
        return this.graphQlWrapper.create(updateRemoveLocation, updateEmployee)
    }

    // map the data in Employee model and return for storage in store
    employeeFieldsMapping = (employeeObject: any): Employee => {
        let userObject: Employee = {
            id: employeeObject.id ? employeeObject.id : "",
            title: employeeObject.title ? employeeObject.title : "",
            firstname: employeeObject.firstname ? employeeObject.firstname : "",
            surname: employeeObject.surname ? employeeObject.surname : "",
            telephoneNumber: employeeObject.phoneNumbers && employeeObject.phoneNumbers.length > 0 ? employeeObject.phoneNumbers[0].phoneNumber : "",
            mobileNumber: employeeObject.phoneNumbers && employeeObject.phoneNumbers.length > 1 ? employeeObject.phoneNumbers[1].phoneNumber : "",
            emailAddress: employeeObject.emailAddresses && employeeObject.emailAddresses.length > 0 ? employeeObject.emailAddresses[0].email : "",
            branchId: employeeObject.branch?.employeeObject.branch.id ? employeeObject.branch.id : "",
            adminItemGroupPermissions: employeeObject.metadata.adminPermissionItemGroups,
            userItemGroupPermissions: employeeObject.metadata.userPermissionItemGroups,
            eReportingEnabled: employeeObject.metadata.eReportingEnabled,
            eNotificationEnabled: employeeObject.metadata.eNotificationEnabled,
            role: employeeObject.metadata.role
        };
        return userObject;
    }

    // update eNotification and eReporting
    updatePreferences(updatePreferenceFlag: PreferencesUpdateModel) {
        return this.graphQlWrapper.create(updatePreferenceFlag, updatePreferences)
    }
}