import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
   selector: 'tags-modal',
   templateUrl: './tags-modal.component.html',
   styleUrls: ['../common-modal.component.scss']
})

export class TagsModal {
   // setter property for input to get latest updated data of clients
   @Input('clientTagsData')
   set _clientTagsData(clientTagsData: Array<any>) {
      this.clientTagsData = clientTagsData;
   }
   // setter property for input to get latest updated data of locations
   @Input('locationTagsData')
   set _locationTagsData(locationTagsData: Array<any>) {
      this.locationTagsData = locationTagsData;
   }
   // output property to emit the closing requirement of modal
   @Output() setTagsModalStatus: EventEmitter<boolean> = new EventEmitter();
   // output property to emit 2 properties
   // the name of the client which needs to be removed in case of client tags 
   // the id of the location which needs to be removed in case of location tags 
   @Output() filterTagsData: EventEmitter<string> = new EventEmitter();
   // property having the clients tags data
   clientTagsData: any;
   // property having the location tags data
   locationTagsData: any;
   constructor() {
      // This is intentional
   }
   // function to emit the output property of closing requirement of modal
   closeModel = () => this.setTagsModalStatus.emit(false);

   // function to emit the output property of removing  2 cases 
   // 1. client by name in case of clients tags
   // 2. location by id in case of location tags
   deleteTag = (clientName: string, locationId: string) => {
      if (clientName)
         this.filterTagsData.emit(clientName)
      else
         this.filterTagsData.emit(locationId);
   }
} 