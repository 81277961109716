
import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "apollo-client";
import { Observable } from "rxjs";
import { decodeToken, decodeUserId } from "src/app/schemas/token-decode";
import { GraphQLService } from "./graphql.service";

@Injectable()

// token decode service to fetch employee Id based on token
export class TokenDecodeService {
    // standard constructor with graphql wrapper injected
    public constructor(private graphQlWrapper: GraphQLService) { }
    //get employee Id by passing token as a param to endpoint
    fetchEmployeeId(token: string): Observable<ApolloQueryResult<any>> {
        const variables = {
            "token": token
        }
        return this.graphQlWrapper.getList(decodeToken, variables);
    }

     //get token from userId
     fetchTokenFromUserId(userId: string): Observable<ApolloQueryResult<any>> {
        const variables = {
            "metadata": userId
        }
        return this.graphQlWrapper.getList(decodeUserId, variables);
    }
}