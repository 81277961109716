<div class="border-bottom">
  <div class="title" data-cy="usefulRes_title">
    Useful resources
    <info-icon [infoMessage]="infoMessage" [height]="popoverHeight" [width]="popoverWidth"></info-icon>
  </div>
</div>

<div class="nx-margin-top-xl nx-margin-bottom-xl">
  <nx-circle-toggle-group responsive="false" class="align-two-horizontally">
    <div class="align-icon-label" (click)="navigateToUsefulResourcesDiv('inspection-hub')">
      <nx-circle-toggle icon="helmet-construction"></nx-circle-toggle>
      <div class="blueColor align-arrow-label">
        <nx-icon class="hide-on-mobile" name="arrow-right" size="s"></nx-icon>
        <h3 data-cy="usefulRes_cat_1">Inspection Hub</h3>
      </div>
    </div>

    <div class="align-icon-label" (click)="navigateToUsefulResourcesDiv('insurance')">
      <nx-circle-toggle icon="product-care-insurance"></nx-circle-toggle>
      <div class="blueColor align-arrow-label">
        <nx-icon class="hide-on-mobile" name="arrow-right" size="s"></nx-icon>
        <h3 data-cy="usefulRes_cat_2">Insurance</h3>
      </div>
    </div>

    <div class="align-icon-label" (click)="navigateToUsefulResourcesDiv('inspection-services')">
      <nx-circle-toggle icon="product-board-paper-note"></nx-circle-toggle>
      <div class="blueColor align-arrow-label">
        <nx-icon class="hide-on-mobile" name="arrow-right" size="s"></nx-icon>
        <h3 data-cy="usefulRes_cat_3">Inspection Services</h3>
      </div>
    </div>
    
    <div class="align-icon-label nx-margin-right-s" (click)="navigateToUsefulResourcesDiv('health-safety-quality')">
      <nx-circle-toggle icon="product-health-bag-doctor"></nx-circle-toggle>
      <div class="blueColor align-arrow-label">
        <nx-icon class="hide-on-mobile" name="arrow-right" size="s"></nx-icon>
        <h3 data-cy="usefulRes_cat_4">Health, Safety & Quality</h3>
      </div>
    </div>
  </nx-circle-toggle-group>
</div>