/* ----------------------------------------------------------
Model to have values of all filters applied on location screen 
                    before navigation
------------------------------------------------------------*/
export class LocationFiltersModel {
    pageNo: number;
    sortDirection: string;
    sortBy: string;
    locationName: string;
    locationAddress: string;
    locationPostcode: string;
}