import { OrganisationListModel, OrganisationModel } from "src/app/schemas/organisation";

// Organisation view model
export class OrganisationViewModel {

    // boolean to handle the case of opening of page or showing list of organisation name
    showOrganisationPage: boolean = false
    // variable for holding the details of list of organisation
    organisationListObject: OrganisationListModel[] = []
    // variable for holding the details of list of item groups
    itemGroupListObject: any[];
    // boolean to know the current select tab in page
    isFirstTabSelected: boolean = true
    // variable holding the selected organisation name
    organisationNameObject: string = ""
    // variable to hold the selected Item group
    selectedItemGroupName: string = "";
    disableNextButton: boolean = true;
    organizationObject: any;
    // property of organisation model containing organisation detail(id)
    organisationModelObject: OrganisationModel = new OrganisationModel();
    // next button disable property
    get nextDisabled(): boolean {
        // disable the next button if organisation have no data 
        return this.disableNextButton;
    }
    set nextDisabled(val: boolean) {
        this.disableNextButton = val;
    }

}