// abstract base class to handle any gql reqponse 
export abstract class BaseResponse {
    // property to hold api response
    private response: any;
    // constructor to set response
    constructor(response: any) {
        this.response = response;
    }
    // method to get specific property from api response
    protected getResponseProperty(propertyName: string, response: any = null): any {
        // if propety doesn't exists, then we are throwing an error
        if (propertyName == null || propertyName === "") {
            throw new Error("property name must not be null/empty.");
        }
        // checking if response is already set or not
        if (response == null && this.response != null) {
            response = this.response;
        }
        // return null if response is null
        if (response == null) {
            return null;
        }
        // return property from response object
        return response[propertyName];
    }
}