import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { RoleGuard } from 'src/app/services/auth-service/role.guard';
import { Role } from 'src/app/shared/enums/role';
import { DashboardComponent } from './dashboard.component';

//Route for dashboard
const routes: Routes = [{
  // this is default route
  path: '', component: DashboardComponent, canActivate: [AuthGuard, RoleGuard], data: {
    breadcrumb: '', expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.internalAdmin, Role.organizationUser]
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
// exporting dashboard module
export class DashboardRoutingModule { }
