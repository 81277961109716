
import gql from 'graphql-tag';

// graphql query to get list of item groups 
export const adminItemGroup = gql`query{
    itemGroups {
      id
      name
      locationIds
    }
  }

`;
// graphql query to get list of ids and names of item groups
export const adminItemGroupIdName = gql`query{
  itemGroups {
    id
    name
  }
}

`;

//get admin item groups
export interface AdminItemGroupDetails {
  id: string,
  name: string,
  locationIds: number[],
  locationCount: string
}

//get admin item groups Id and name
export interface AdminItemGroupIdName {
  id: string,
  name: string,
}