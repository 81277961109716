// Any local storage key required will be added here then
// its reference will be used , to avoid using direct keys in the code.
export enum LocalStorageKeys {
    // item groups key
    ItemGroups = "ItemGroups",
    // selected item groups key
    SelectedItemGroups = "selectedItemGroups",
    // selected organisation key
    SelectedOrganisation = "selectedOrganisation",
    // selected role key
    Role = "role",
    // employee Id and token Key
    EmployeeTokenWithId = "employeeTokenWithId",
    // maintenance banner Key
    MaintenanceBannerSetting = "showMaintenanceBanner",
    // local user permissions manage/update
    UserPermissionsManage = "userPermissionsManage",
    //employee token
    EmployeeToken = "employeeToken",
    //file date for maintnance page
    FileData = "fileData",
    //last Modified date
    LastModified = "lastModified"
}