import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AuthGuard } from "src/app/services/auth-service/auth.guard";
import { UsefulResourcesService } from "src/app/services/json-read/useful-resources.service";
import { SharedModule } from "src/app/shared/shared.module";
import { HelpSupportRoutingModule } from "./help-support-routing.module";
import { HelpSupportComponent } from "./help-support.component";

@NgModule({
    declarations: [HelpSupportComponent],
    imports: [SharedModule, FormsModule, HelpSupportRoutingModule],
    providers: [AuthGuard, UsefulResourcesService]
})

export class HelpSupportModule { }