import { Subscription } from "rxjs";

// Api Error View Model
export class ApiErrorViewModel {
    // Check for desktop device, by default its true
    isDesktop: boolean = true;
    // setting text font size, by default for desktop
    fontSize: string = '50px';
    // httprequesterror model to display fields on front end
    requestId: string = '';
    // devicedimensionservice's subscription object to unsubscribe on destory
    deviceServiceSubscription: Subscription;
}