import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NxDatepickerComponent } from '@aposin/ng-aquila/datefield';
import { Moment } from 'moment';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { PlantNotAvailableViewModel } from './plantNotAvailableViewModel';

// Plant not available view
@Component({
  selector: 'app-plant-not-available',
  templateUrl: './plant-not-available.component.html',
  styleUrls: ['./plant-not-available.component.scss']
})
export class PlantNotAvailableComponent implements OnInit {
  //step count
  stepsData = ['1', '2', '3'];
  //plantNotAvailableVM obj
  plantNotAvailableVM: PlantNotAvailableViewModel = new PlantNotAvailableViewModel();
  //to datepicket child view
  @ViewChild('toDatepicker', { static: true })
  //to date picket date setting
  toDatepicker!: NxDatepickerComponent<Moment>;
  //intialize both dates
  fromDate: Moment | null = null;
  toDate: Moment | null = null;

  constructor(private layoutService: LayoutService, private router: Router) { }

  ngOnInit(): void {
    //Banner text setting
    this.layoutService.setBanner(BannerDetail.plantNotAvailable);
    // checking if device is desktop
    this.layoutService.currentDevice.subscribe(result => { this.plantNotAvailableVM.isDesktop = result; });

  }
  // clear search form
  clearSearch() {
    //call clear method 
    this.plantNotAvailableVM.clear();
  }
  //navigate to management report page
  navigateToReport = () => {
    void this.router.navigateByUrl("/reports");
  }
}
