import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortEvent } from '@aposin/ng-aquila/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Item } from 'src/app/schemas/location-items';
import { MainItemPersonalization } from 'src/app/schemas/personalisation';
import { CommonService } from 'src/app/services/common.service';
import { ExportService } from 'src/app/services/export.service';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { PersonalisationService } from 'src/app/services/graphql/personalisation.service';
import { LayoutService } from 'src/app/services/layout-service';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { ComponentName } from 'src/app/shared/enums/componentName';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';
import { LocationItemsViewModel } from '../locations/location-items/location-items.viewModel';
import { MainItemsViewModel } from './main-items.viewModel';

@UntilDestroy()
@Component({
  selector: 'app-item',
  templateUrl: './main-items.component.html',
  styleUrls: ['./main-items.component.scss']
})
export class MainItemComponent implements OnInit {
  //Responsive view true for desktop , false for mobile
  isDesktop: boolean;
  //to show more row items on grid in mobile view
  showMore: boolean = false;
  // location-item view model instance for model binding
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();
  //personlisation object 
  personalisationObj: MainItemPersonalization = new MainItemPersonalization();
  // main  items view modal
  mainItemsViewModel: MainItemsViewModel = new MainItemsViewModel();
  // contains the list of all location Ids of current assigned Item Groups
  assignedItemGroupsLocationIds: any[] = []

  constructor(private layoutService: LayoutService,
    private personalisationService: PersonalisationService,
    private activatedRoute: ActivatedRoute, private itemsService: LocationItemService,
    private common: CommonService, private exportService: ExportService,
    private itemGroupService: ItemGroupService) {
    // getting current navigation object from router
    this.mainItemsViewModel.currentNavigationObject = this.common.getRouterCurrentNavigationObject();
  }

  ngOnInit(): void {
    //to set breadcrums on refresh
    this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.mainItem);
    // subscribed to query params change
    this.activatedRoute.queryParams.subscribe(params => {
      // get data from query string
      this.getQueryParamsAndMap(params);
      // fetch items from API
      this.fetchItemsFromAPI()
    });
    // prepare the search param model
    this.prepareSearchParamObject()
    //fetch user personalisation from api
    this.fetchPersonalisation();
    //set banner text for items page
    this.layoutService.setBannerVisibilty(BannerDetail.itemsBannerText);
    // set banner sub title
    this.layoutService.setSubBanner(BannerDetail.itemsBannerSubtitleText);
    //subscribing to device view
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => this.isDesktop = result)
    // fetch items
    this.getlocationForAssignedItemGroup()
  }

  fetchMainItemsFiltersModel = () => {
    // retreive last url from navigation object
    if (this.mainItemsViewModel.currentNavigationObject.previousNavigation) {
      this.mainItemsViewModel.previousUrl = this.mainItemsViewModel.currentNavigationObject.previousNavigation.finalUrl.toString();
    }

    if (this.mainItemsViewModel.previousUrl != "" && this.mainItemsViewModel.previousUrl.indexOf("/items/details/") != -1) {
      this.locationItemViewModel.page = this.itemsService.fetchMainItemsFilters().pageNo
      this.locationItemViewModel.itemSearch = this.itemsService.fetchMainItemsFilters().itemSearch
      this.locationItemViewModel.sortDirection = this.itemsService.fetchMainItemsFilters().sortDirection
      this.locationItemViewModel.sortBy = this.itemsService.fetchMainItemsFilters().sortBy

    }
  }

  //fetch user personalisation 
  fetchPersonalisation() {
    // check if items main page personalisation object is present
    if (this.personalisationService.personalization.mainItem) {
      this.personalisationObj = this.personalisationService.personalization.mainItem;
    }
    this.locationItemViewModel.perPage = parseInt(this.personalisationObj.perPageToggle);

  }

  //save user personalisation to api endpoint slot
  async savePersonalisation(): Promise<void> {
    await this.personalisationService.updateComponentSettings(this.personalisationObj, ComponentName.mainItem);
  }

  // set multi fields search values
  // contains the item code value of *
  prepareSearchParamObject = (): void => {
    this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, '*');
  }

  //get location based on selected item groups
  getlocationForAssignedItemGroup() {
    this.assignedItemGroupsLocationIds = []
    this.layoutService.currentAssignedItemGroups.pipe(untilDestroyed(this)).subscribe(itemGroupList => {
      if (itemGroupList && itemGroupList.length > 0) {
        // get the location Ids list of assigned item groups
        this.assignedItemGroupsLocationIds = this.itemGroupService.getLocationIdsAssignedItemGroups();
        //based on location get items
        this.fetchItemsFromAPI();

      }
    })
  }
  // fetch items call from service to display data on grid
  async fetchItemsFromAPI() {

    const assignedLocationIds = this.itemGroupService.getLocationIdsAssignedItemGroups();
    //call the method to assign value of search
    this.locationItemViewModel.queryItemParamList = this.locationItemViewModel.assignSearchInput(this.locationItemViewModel.itemSearch)
    // prepare param for location list
    this.common.addDefaultSearchParam(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, this.common.toTextMulti(assignedLocationIds));
    // api call with parameters
    const apiResponseObject = await this.itemsService.getLocationItemsForMainItem(this.locationItemViewModel.queryItemParamList.searchList, false,
      this.locationItemViewModel.sortDirection != "" ? this.locationItemViewModel.sortDirection.toUpperCase() : "DESC",
      this.locationItemViewModel.sortBy != "" ? this.locationItemViewModel.sortBy : "inspectionsInspectionDate",
      this.locationItemViewModel.page - 1, this.locationItemViewModel.perPage, this.locationItemViewModel.secondarySortingRequested
    );
    // check if data is present of items 
    if (apiResponseObject && apiResponseObject.itemDetails) {
      // call to get locations data (name,postcode) by passing location ids
      this.locationItemViewModel.itemLocations.items = apiResponseObject.itemDetails;
      this.locationItemViewModel.resultCount = apiResponseObject.totalCount;
      // iterate on items list passed as parameter
      this.locationItemViewModel.itemLocations.items.forEach(item => {
        if (item.location && item.location.code) {
          // find the record by matching the location ids and have object of location with details
          this.populateLocationDetailsObjects(item)
        }
      });

    }
    this.locationItemViewModel.apiResultReturned = true;

  }

  //navigate to item details
  navigateToItemDetailsScreen = (itemId: string) => {
    // save filters before navigation
    this.itemsService.setMainItemsFilters(this.locationItemViewModel.page, this.locationItemViewModel.sortBy, this.locationItemViewModel.sortDirection, this.locationItemViewModel.itemSearch);
    // navigate to item details page
    void this.common.navigate(AppRoutes.itemDetailsFromItems(itemId))
  }
  // populate location name , tooltip and postcode fields
  populateLocationDetailsObjects = (item: Item): Item => {
    // required properties assignments
    item.toolTipLocationName = item.location.name.length > 15 ? item.location.name : null;
    item.locationName = item.location.name.length > 15 ? `${item.location.name.substring(0, 15)}...` : item.location.name;
    if (item.location.postcode)
      item.locationPostcode = item.location.postcode;
    return item;
  }


  // sort location-items list on client side
  sortItemsListLocally(sort: SortEvent): void {
    // getting sortby and direction from sort event
    this.locationItemViewModel.sortBy = sort.active;
    this.locationItemViewModel.sortDirection = sort.direction;
    // apply sorting on location items through a sort function
    this.locationItemViewModel.itemLocations.items.sort((a, b) => {
      switch (sort.active) {
        case 'serialNumber':
          return this.common.compare(this.common.safeString(a.serialNumber), this.common.safeString(b.serialNumber), sort.direction);
        case 'clientPlantNumber':
          return this.common.compare(this.common.safeString(a.clientPlantNumber), this.common.safeString(b.clientPlantNumber), sort.direction);
        case 'plantTypeDescription': return this.common.compare(a.plantType?.description, b.plantType?.description, sort.direction);
        case 'disciplineDescription': return this.common.compare(a.discipline?.description, b.discipline?.description, sort.direction);
        case 'currentReportId': return this.common.compare(a.reportID, b.reportID, sort.direction)
        case 'locationDescription': return this.common.compare(a.location?.description, b.location?.description, sort.direction);
        case 'inspectionsInspectionDate': return this.common.compare(a.inspections.inspectionDate ? new Date(a.inspections.inspectionDate) : "", b.inspections.inspectionDate ? new Date(b.inspections.inspectionDate) : "", sort.direction);
        case 'inspectionsNextInspectionDate': return this.common.compare(a.inspections.nextInspectionDate ? new Date(a.inspections.nextInspectionDate) : "", b.inspections.nextInspectionDate ? new Date(b.inspections.nextInspectionDate) : "", sort.direction);
        case 'inspectionsNextInspectionType': return this.common.compare(a.inspections.nextInspectionType?.description, b.inspections.nextInspectionType?.description, sort.direction);
        case 'statusDescription': return this.common.compare(a.status?.description, b.status?.description, sort.direction);
        case 'currentReportDefectCode': return this.common.compare(a.latestReport?.defectCode?.code, b.latestReport?.defectCode?.code, sort.direction);
        case 'subLocationDescription': return this.common.compare(a.location ? a.toolTipSubLocation : "", b.location ? b.toolTipSubLocation : "", sort.direction);

        default: return 0;
      }
    });
  }

  // sort items by specific column locally
  sortTable(sort: SortEvent) {
    // if records are less than 11 then sort locally
    if (this.locationItemViewModel.resultCount <= 10)
      this.sortItemsListLocally(sort);
    else {
      // this is pagination case, we are quering api to sort location items for us from backend
      this.locationItemViewModel.sortBy = sort.active;
      this.locationItemViewModel.sortDirection = sort.direction;
      //  sort by and direction is required
      // navigate to main items page
      void this.common.navigate(`${AppRoutes.mainItems}`,
        {
          queryParams: {
            pageNo: this.locationItemViewModel.page,
            sortDirection: this.locationItemViewModel.sortDirection,
            sortBy: this.locationItemViewModel.sortBy,
            perPage: this.locationItemViewModel.perPage,
            itemSearch: this.locationItemViewModel.itemSearch
          }
        });
    }
  }

  // fetch data of a particular page number
  // configured with pagination control
  goToPageNumber(pageNumber: number) {
    this.locationItemViewModel.page = pageNumber;
    this.reloadPage();
  }

  // method to decrease the page number value
  prevPageNumber() {
    this.locationItemViewModel.page--;
    this.reloadPage();
  }

  // increment the page number and send call to fetch the data
  nextPageNumber() {
    this.locationItemViewModel.page++;
    this.reloadPage();
  }

  // used with pagination control on main items page
  reloadPage() {
    // navigate to route with required query params
    void this.common.navigate(`${AppRoutes.mainItems}`, {
      queryParams: {
        perPage: this.locationItemViewModel.perPage,
        itemSearch: this.locationItemViewModel.itemSearch,
        pageNo: this.locationItemViewModel.page,
        sortDirection: this.locationItemViewModel.sortDirection,
        sortBy: this.locationItemViewModel.sortBy

      }
    });
  }

  // get query params and map to our view model and local variables
  // used to send to fetch items from api
  getQueryParamsAndMap(params: any): void {
    if (params && Object.keys(params).length > 0) {
      this.locationItemViewModel.page = this.common.IsNullORUndefined(params, "pageNo", 1);
      const sortByElements = this.common.IsNullORUndefString(params, "sortBy");
      this.locationItemViewModel.sortBy = sortByElements.split(',').length > 1 ? sortByElements.split(',')[0] : sortByElements;
      this.locationItemViewModel.secondarySortingRequested = sortByElements.split(',').length > 1;
      this.locationItemViewModel.sortDirection = this.common.IsNullORUndefString(params, "sortDirection");
      this.locationItemViewModel.perPage = this.common.IsNullORUndefined(params, "perPage", 10);
      this.locationItemViewModel.itemSearch = this.common.IsNullORUndefString(params, "itemSearch", "");
    }
    // if no, lets look for any values in filter service
    else {
      this.fetchMainItemsFiltersModel();
    }
  }

  //export data as csv
  exportMainItemsToCSV = () => {
    //we are using header option.
    const options = {
      headers: ["Current report/ID", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
        "Defect code", "Next due", "Location", "Location Details", "Item status", "Policy number", "ACE plant no", "Manufacture", "Date of manufacture", "Written scheme reference", "Next contractual date"

      ],

    };
    if (this.locationItemViewModel.itemLocations.items) {
      // export the content to csv file
      this.exportService.exportToCsv(this.locationItemViewModel.itemLocations.items, this.locationItemViewModel.exportItemFileName, options, [], null, false)
    }
  }
  // toggle number of records per page by clicking the number 
  public togglePerPageMainItem(selectedPerPage: PerPageModel): void {
    // clicked option's value is set as per page option
    this.locationItemViewModel.perPage = selectedPerPage.value;
    // page to load is set to 1 i.e. load first page
    this.locationItemViewModel.page = 1;
    //save user personalisation  setting
    this.personalisationObj.perPageToggle = `${selectedPerPage.value.toString()}`;
    // save the personalisation settings
    this.savePersonalisation();
    // calling reload page
    this.reloadPage();
  }
  // search specific items from API
  search() {
    this.locationItemViewModel.isSearchCall = true;
    //call the method to assign value of search
    this.locationItemViewModel.queryItemParamList = this.locationItemViewModel.assignSearchInput(this.locationItemViewModel.itemSearch)
    // prepare parameters list to send to api
    this.prepareSearchParamList();
    // updating route
    this.locationItemViewModel.page = 1;
    // navigate with required query params
    void this.common.navigate(`${AppRoutes.mainItems}?pageNo=${this.locationItemViewModel.page}&sortDirection=${this.locationItemViewModel.sortDirection}&sortBy=${this.locationItemViewModel.sortBy}&itemSearch=${this.locationItemViewModel.itemSearch}&perPage=${this.locationItemViewModel.perPage}`)
  }

  // set multi fields search values
  prepareSearchParamList(): void {
    if (this.locationItemViewModel.previousUrl != "" && this.locationItemViewModel.previousUrl.indexOf("/items/details/") != -1)
      this.locationItemViewModel.queryItemParamList = this.locationItemViewModel.assignSearchInput(this.locationItemViewModel.itemSearch)

    if (this.locationItemViewModel.locationViewModel.locationDetails && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('startDate') && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('endDate') && this.locationItemViewModel.locationViewModel.locationDetails.hasOwnProperty('locationDetailList')) {
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, "itemNextInspectionDate", `[${this.locationItemViewModel.locationViewModel.locationDetails.startDate} TO ${this.locationItemViewModel.locationViewModel.locationDetails.endDate}]`);
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, `${this.locationItemViewModel.locationViewModel.locationDetails.locationDetailList.map(location => location.locationId).join("|")}`);
    }
    else {
      this.common.updateSearchParamList(this.locationItemViewModel.queryItemParamList, this.locationItemViewModel.itemLocationCode, `${this.locationItemViewModel.locationItemCode}`);

    }
  }
  // clear search form, reset page and reload data from api
  clearSearch() {

    // clear search viewmodel
    this.locationItemViewModel.clear();
    // clear query params from items and locations list
    this.locationItemViewModel.queryItemParamList.clear(0)
    this.locationItemViewModel.queryLocationParamList.clear(0)
    // page to 1
    this.locationItemViewModel.page = 1;
    //navigate to ob fresh params
    void this.common.navigate(AppRoutes.mainItems + "?sortDirection=desc&sortBy=inspectionsInspectionDate")
  }
}
