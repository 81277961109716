import { Injectable } from '@angular/core';

@Injectable()
export class CookieStorageService {
  // domain allianz.co.uk
  domain: string = 'allianz.co.uk'

  // set cookie
  setCookie(name: string, expirationHours: number, countdown: boolean = false, value: string = '') {
    // calculation for expiry date i.e. current date + expiry hours
    const date = new Date(Date.now() + expirationHours * 60 * 60 * 1000);
    // current date for value
    const now = new Date(Date.now())
    // path of cookie
    const path = "path=/";
    // in case of countdown i.e. 60sec counter set value to be date else name and value of cookie will be date
    const cookieValue = countdown ? `${name}=${value}; ${path}; expires=${date.toUTCString()}; domain=${this.domain}` : `${name}=${now.toUTCString()}; ${path}; expires=${date.toUTCString()}; domain=${this.domain}`
    document.cookie = cookieValue;
  }

  // get cookie
  getCookie(name: string): string | undefined {
    // get all cookies
    const value = `; ${document.cookie}`;
    // get specified cookie
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
    }
  }

  // delete cookie
  deleteCookie(name: string) {
    document.cookie = `${name}=; expires=${new Date(0).toUTCString()}; domain=${this.domain}`
  }

  // check if max time has reached
  hasCookieExpired(name: string, maxActivityTime: number): boolean {
    // get cookie
    const cookieValue = this.getCookie(name);
    // if cookie is undefined
    if (!cookieValue) {
      return true; // cookie does not exist, so it has expired
    }
    // get cookies time
    const expires = new Date(cookieValue).getTime();
    // current time
    const now = Date.now()
    // difference between expiry time and current time
    const timeDiff = now - expires
    // if timeDiff is greater than or equal to maxActivity time i.e. time after which modal is shown then return true else false
    return timeDiff >= maxActivityTime;
  }

}