import { ExportLocationItem } from "src/app/schemas/location-items";
import { Location } from "src/app/schemas/locations";
import { Personalisation } from "src/app/schemas/personalisation";
import { SharedMessages } from "src/app/shared/shared-messages";

export class DashboardViewModel {
  // setting the min and max dates allowed to select from start and end date
  minStartDate;
  maxStartDate;
  minEndDate;
  maxEndDate;
  //Responsive view true for desktop , false for mobile
  isDesktop: boolean;
  //personalisation object
  personalisationObj: Personalisation = new Personalisation();
  // get the height of full screen
  getScreenHeight: number;
  // get the height of mobile view list container height  
  mobileViewListHeight: number;

  // to adjust locations in one line, window width is being used i.e. in locations filter
  innerWidthofScreen: number;
  // on scroll hide sticky button's text that is on view list mobile
  userHasScrolled: boolean = false;
  // variable for setting timeout after scroll has stoped
  timeout: any;
  //model to use export to csv
  exportLocationItem: ExportLocationItem[];
  // for selecting map view on desktop  by default
  isMapView: string = "true"

  // bind the checkbox field i.e. two way binding. By default its value is 30 days
  withinNextDays = '30 days';

  // to disable apply button on wrong date entry
  isApplyFilterButtonDisabled: boolean = false;

  // bool to find if map-container is expanded or not. By default its false
  isMapContainerExpanded: boolean = false;
  // export csv file name
  exportFileName: string = "exportLocationItem";
  // difference between end and start date of locations due/overdue filter
  dateRangeLocationsDifference: number;
  //further option to be included in exported file, we are using header option.
  options = {
    headers: ["Location", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
      "Defect code", "Next due", "Sub location", "Detailed location", "Item status"],

  };
  // info message
  infoMessage: string = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
  // height of popover
  popoverHeight: string = "250px !important"
  // width of popover
  popoverWidth: string = "250px !important"
  // message to display in case of no due/over due items
  noDueOverDueMessage = SharedMessages.noDueOverDueItemsMessage

  // array containing the list of locations details fom filters
  locationDetailsForFilters: Location[] = []
  // selected locations for All Defect Types Widget
  selectedLocations: number[] = [];
  // switcher component in filters
  dateRangeSwitcherModel: boolean = false;


}
// interface to have basic information of location
export interface LocationDetailSearchModel {
  locationId: any;
  locationName: string;
}
// class having start,end date and list of location details (id,name)
export class LocationDetailListSearchModel {
  startDate: string;
  endDate: string;
  locationDetailList: LocationDetailSearchModel[] = [];
  withinNextDays: string
}
// interface to have information of a defect i-e name and value
export interface ADefectDataList {
  name: string;
  value: number;
  wheelValue: number;
}
// class having a defect data list
export class ADefectDataObject {
  locationADefectCountList: ADefectDataList[] = [];
}

// class having start,end date, list of location details (id,name) which are having data against defects , and list of all selected locations, and list of defects types
export class LocationDefectListSearchModel {
  startDate: string
  endDate: string
  locationDetailList: LocationDetailSearchModel[] = [];
  allSelectedLocationsList: LocationDetailSearchModel[] = [];
  locationDefectTypes: string;
}



