import { Injectable } from "@angular/core";
import bannerMessage from 'src/assets/json-files/bannerMessage.json';
import { BannerMessageModel } from "src/app/shared/models/banner.message.Model";

@Injectable()
// service to read banner message from json file
export class BannerMessageService {
    // read message from json file
    getMessage = (): BannerMessageModel => {
        // loading banner message model with json from file
        const bannerMessageModel: BannerMessageModel = bannerMessage;
        // return the model
        return bannerMessageModel;
    }
}