import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps'
import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app-routing.module";
import { PublicComponent } from './_layout/public/public.component';
import { AuthorizedComponent } from './_layout/authorized/authorized/authorized.component';
import { HeaderComponent } from './_layout/header/header.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { GraphQLModule } from './services/graphql/graphql.module';
import { CommonService } from './services/common.service';
import { HttpService } from './services/http.service';
import { BreadcrumbComponent } from './_layout/breadcrumb/breadcrumb.component';
import { GlobalErrorHandler } from './services/errors/globalErrorService';
import { ErrorLogService } from './services/errors/errorService';
import { BreadcrumbModule, BreadcrumbService } from 'angular-crumbs';
import { StoreModule } from '@ngrx/store';
import { reducer } from './statemanagement/reducers/user.reducer';
import { PageNotFoundComponent } from './components/error/404/404.component';
import { LayoutService } from './services/layout-service';
import { ApiErrorComponent } from './components/error/api/api-error.component';
import { Personalisationreducer } from './statemanagement/reducers/personalisation.reducer';
import { AppInitService } from './services/app-init.service';
import { DashboardService } from './services/graphql/dashboard.service';
import { LocationItemService } from './services/graphql/location-items.service';
import { FiltersService } from './services/filters-service';
import { UserService } from './services/graphql/user.Service';
import { ItemGroupService } from './services/graphql/item-group.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { loggedInReducer } from './statemanagement/reducers/loggedin-user.reducer';
import { ConfirmationComponent } from './_layout/confirmation/confirmation.component';
import { ExportService } from './services/export.service';
import { OrganisationComponent } from './_layout/organisation/organisation.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleDetectionService } from './services/idle-detection.service';
import { CookieStorageService } from './services/cookie-storage.service';
import { TokenDecodeService } from './services/graphql/decode-token.service';
import { MaintenanceBannerComponent } from './_layout/maintenanceBanner/maintenance-banner.component';
import { NotAvailableComponent } from './not-available/not-available.component';
import { MaintenanceComponent } from './_layout/maintenance/maintenance.component';
import { UnplannedMaintenanceComponent } from './_layout/unplanned-maintenance/unplanned-maintenance.component';
import { BannerMessageService } from './services/json-read/banner.message.service';


@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    AuthorizedComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    PageNotFoundComponent,
    ApiErrorComponent,
    ConfirmationComponent,
    OrganisationComponent,
    MaintenanceBannerComponent,
    NotAvailableComponent,
    MaintenanceComponent,
    UnplannedMaintenanceComponent
  ],
  imports: [
    BrowserModule,
    ApolloModule,
    HttpLinkModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
    SharedModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    GoogleMapsModule,
    StoreModule.forRoot({ user: reducer, personalisation: Personalisationreducer, loggedInUser: loggedInReducer }),
    NgIdleKeepaliveModule.forRoot()
  ],

  providers: [
    LayoutService, CommonService, HttpService, LocalStorageService, AppInitService,
    DashboardService, LocationItemService, BreadcrumbService, UserService, TokenDecodeService, FiltersService, ExportService, IdleDetectionService,
    ItemGroupService, { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: APP_INITIALIZER, useFactory: initializeAppService, deps: [AppInitService], multi: true },
    ErrorLogService, CookieStorageService, BannerMessageService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// // method to call app init service to fetch and store roles and client details
// export function initializeAppService(appInitService: AppInitService) {
//   return async () => {
//     await appInitService.setUserRolesAndClients();
//   }
// }
