import gql from 'graphql-tag';

// graphql query to get list of location surveyor contacts
export const searchLocationSurveyorContacts = gql`query search($search: [Search]! , $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: Int!){

  search(search: $search, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
          results{
              ... on Location{
                surveyorContacts {
                    firstname
                    surname
                    email
                    phoneNumber
                    discipline{
                    description
                    }
                }
              }
          }
      }
  }
`;

// interface containing the details of location surveyor contact
export interface LocationSurveyorContacts {
  firstname: string,
  surname: string,
  email: string,
  phoneNumber: string,
  discipline: {
    description: string
  }
}