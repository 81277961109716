import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AuthGuard } from "src/app/services/auth-service/auth.guard";
import { DefectBadgeColorPipe } from "src/app/shared/pipes/defectBadge.color.pipe";
import { SharedModule } from "src/app/shared/shared.module";
import { ItemRoutingModule } from "./main-items-routing.module";
import { MainItemComponent } from "./main-items.component";

@NgModule({
    declarations: [MainItemComponent],
    imports: [SharedModule, FormsModule, ItemRoutingModule],
    providers: [AuthGuard, DefectBadgeColorPipe]
})

export class MainItemModule { }