
import { AdminItemGroupIdName } from "src/app/schemas/itemsGroup";
import { SearchModels } from "src/app/schemas/searchModel";
import { UserTitle } from "src/app/schemas/reference-data";
import { Observable } from "rxjs";
import { SharedMessages } from "src/app/shared/shared-messages";

// user registration view model for properties and functions
export class UserRegistrationViewModel {
    // search location params
    queryParamList: SearchModels = new SearchModels();
    // Search Location variables
    isSearchLocationCall: boolean = false;
    // control top error banner on UI
    showTopErrorBanner: boolean = false;
    // control permission not selected error
    showLocationsErrorBanner: boolean = false;
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // selected location list
    employeeSelectedLocations = [];
    //for binding this to afterviewInit after getting value stored from Intersection observer
    observer: any;
    //one of the arguments for intersection observer for setting threshold, selecting the element from template and defining margin
    options = {
        root: document.querySelector('.infinite-scroller'),
        rootMargin: "0px",
        threshold: 0.85,
    };
    // boolean to handle the data from api call based on scrolling
    isLocationScrollCall: boolean = false;

    // height of popover
    popoverHeight: string = "200px !important"
    // width of popover
    popoverWidth: string = "170px !important"
    // property holding the static info text
    infoPopupMessage: string = SharedMessages.shortDummyText;

    // item groups List that has all the itemGroups name and id
    itemGroupsList: AdminItemGroupIdName[] = [];
    // if itemGroup is single then just get name from API and store in this field to display
    singleItemGroupName: string;
    // boolean to check if itemGroups are single or multiple
    isSingleItemGroup: boolean;
    // model attached to multiselect dropdown to store selected items
    itemGroupModel: any[] = [];
    // API result returned from endpoint
    itemGroups: any[];
    // eReporting switcher modal
    isEReportingEnabled = true;
    // reference titles from api side
    userTitles: Observable<UserTitle[]>;
    // dummy text holding static data
    dummyText: string = SharedMessages.userScreenDummyText;
}