/* ----------------------------------------------------------
Model to have values of all filters applied on items screen 
                    before navigation
------------------------------------------------------------*/
export class ItemsFiltersModel {
    dashboardFilterDetails: any;
    pageNo: number;
    sortDirection: string;
    sortBy: string;
    itemSearch: string
}