
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { OrganisationListModel, OrganisationModel } from 'src/app/schemas/organisation';
import { ErrorLogService } from 'src/app/services/errors/errorService';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { OrganisationService } from 'src/app/services/graphql/organisation.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SharedMessages } from 'src/app/shared/shared-messages';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'organization-modal',
    templateUrl: './organization-modal.component.html',
    styleUrls: ['../common-modal.component.scss']
})

// Component to handle the organization work
export class OrganizationModal {
    // variable for holding the list of organisation
    organisationList: OrganisationListModel[] = []
    // variable for holding the list of item groups
    itemGroupList: any[];
    // boolean to know the current select tab in modal
    isFirstTabSelected: boolean = true
    // variable holding the selected organisation
    organisationName: string = ""
    // property to hold the selected Item group Name
    selectedItemGroupName: string = "";
    disableNextButton: boolean = true;
    // property of organisation model containing organisation id
    organisationModel: OrganisationModel = new OrganisationModel();
    // boolean to know the status of modal open/close
    showPopup: boolean = true
    // output property to emit the closing requirement of organisation modal
    @Output() setModalStatus: EventEmitter<boolean> = new EventEmitter();
    // organization input element
    @ViewChild('organizationInput') organizationInputElement: ElementRef;
    // dummy text to local variable
    dummyText = SharedMessages.longDummyText;
    constructor(private layoutService: LayoutService, private el: ElementRef,
        private organisationService: OrganisationService,
        private itemGroupService: ItemGroupService, private localStorageService: LocalStorageService,
        private errorLogService: ErrorLogService) { }

    ngOnInit() {
        // fetch the list of item groups from local storage
        const organisationObj = this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation)
        // assign values in case of item groups object have value
        if (organisationObj != null) {
            this.organisationList = organisationObj
            this.organisationName = this.organisationList[0].name
            this.disableNextButton = false
        }
    }
    // method for saving the selected organisation
    saveOrganisation = () => {
        // move to second tab
        this.isFirstTabSelected = false
        // find the id of selected organisation to pass to API for saving purpose
        const organizationId = this.organisationList.find(org => org.name == this.organisationName).id;
        this.organisationModel.organisationId = organizationId;

        // call to API to save selected organisation 
        this.organisationService.updateOrganisation(this.organisationModel).subscribe(result => {
            if (result) {
                // get item group details from API
                this.itemGroupService.getAdminItemGroup().pipe(take(1)).toPromise().then((itemGroups) => {
                    if (itemGroups?.data?.itemGroups) {
                        this.itemGroupList = itemGroups.data.itemGroups
                        this.preselectItemGroup(organizationId)
                    }
                }).catch((eror) => this.errorLogService.logError(eror));
            }
        })
    }
    // set the next button to disable
    selectOrganization = () => this.disableNextButton = false

    // pre select the item group, if it is single
    // in case of multiple, if already we have selected item group in local storage
    // select the previously selected item group
    preselectItemGroup = (organizationId: any) => {
        if (this.itemGroupList && this.itemGroupList.length === 1) {
            this.selectedItemGroupName = this.itemGroupList[0].name
        }
        else {
            const selectedOrganization = this.localStorageService.getObject(LocalStorageKeys.SelectedOrganisation)
            if (selectedOrganization && selectedOrganization.length > 0 && selectedOrganization[0].id == organizationId) {
                const selectedItemGroup = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups)
                if (selectedItemGroup && selectedItemGroup.length > 0 && this.itemGroupList.findIndex(itemgroup => itemgroup.id == selectedItemGroup[0].id) > -1) {
                    this.selectedItemGroupName = selectedItemGroup[0].name
                }
            }
        }
    }

    // method to fetch the list of organisation
    fetchOrganisationsList = () => {
        this.organisationService.getOrganisationList(this.organisationName).subscribe(result => {
            if (result?.data?.organisations) {
                // map the list to local variable
                this.organisationList = result.data.organisations
                const input: HTMLInputElement = this.el.nativeElement.querySelector('#organizationInput') as HTMLInputElement;
                input.focus();
            }
        })
    }

    // method to save the selected item groups 
    proceed = () => {
        this.showPopup = false;
        this.setModalStatus.emit(this.showPopup)
        if (this.selectedItemGroupName) {
            const checkedItemGroup = this.itemGroupList.filter(x => x.name == this.selectedItemGroupName)
            const organizationObject = this.organisationList.filter(org => org.name == this.organisationName);
            // look if we have already object present in local storage
            // need to remove the previous to save the fresh preference
            if (this.localStorageService.getObject("selectedItemGroups"))
                this.localStorageService.removeData("selectedItemGroups")
            this.localStorageService.saveObject('selectedItemGroups', checkedItemGroup)
            // selected organization in local storage
            if (this.localStorageService.getObject("selectedOrganisation"))
                this.localStorageService.removeData("selectedOrganisation")
            this.localStorageService.saveObject('selectedOrganisation', organizationObject)
            // Pass the list to observables to show the latest data on header
            this.layoutService.assignItemGroups(checkedItemGroup)
            this.layoutService.assignAllItemGroups(this.itemGroupList)
            this.layoutService.assignOrganisation(organizationObject)
        }
    }

    // if the user have an organisation, then popup is closed when click on cancel
    // else in fresh case, user will be navigated to main allianz page
    cancelModal(): void {
        // check if we have saved oraganization, close the popup only
        if (this.localStorageService.exists("selectedOrganisation") && this.localStorageService.exists("selectedItemGroups")) {
            this.showPopup = false
            this.setModalStatus.emit(this.showPopup);
        }
        // else navigate user to main myallianz page
        else {
            window.location.href = environment.myAllianzInternal
        }
    }

    // method to set the button disable in case of no organisation selected
    disableButton = () => {
        if (this.organisationName == "" || this.organisationName == null)
            this.disableNextButton = true;
    }
}