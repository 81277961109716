import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AllDefectViewModel } from './all-defect-viewModel';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import moment from 'moment';
import { DashboardService } from 'src/app/services/graphql/dashboard.service';
import { shareReplay } from 'rxjs/operators';
import { LocationDefect } from 'src/app/schemas/location-a-defect';
import { AppRoutes } from 'src/app/shared/appRoutes';
import {
  LocationDefectListSearchModel,
  LocationDetailSearchModel,
} from '../../dashboard.viewModel';
import { CommonService } from 'src/app/services/common.service';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { LocationItemsViewModel } from 'src/app/components/locations/location-items/location-items.viewModel';
import { SearchModels } from 'src/app/schemas/searchModel';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ExportService } from 'src/app/services/export.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from 'src/app/services/layout-service';

@UntilDestroy()
@Component({
  selector: 'all-defect-widget',
  templateUrl: './all-defect.component.html',
  styleUrls: ['./all-defect.component.scss'],
})
export class AllDefectComponent implements OnChanges {
  //initilize the AllDefectViewModel
  allDefectViewModel: AllDefectViewModel = new AllDefectViewModel();
  // selection for checkboxes. The first parameter is true so that multiple values can be selected
  selection = new SelectionModel(true, []);
  // disable the select all checkbox, in case when all checkboxes have no data
  disableSelectAllCheckbox: boolean = true;
  // checkboxes i.e. CB text, classes and values added here so that we can loop through in template without repeating code
  allDefectCB = [
    {
      text: 'All A Defects',
      class: 'dot bg-a-dot',
      value: 0,
      disable: true,
    },
    {
      text: 'B',
      class: 'dot bg-b-dot',
      value: 0,
      disable: true,
    },
    {
      text: 'P (Plant not available)',
      class: 'dot bg-p-dot',
      value: 0,
      disable: true,
    },
    {
      text: 'C',
      class: 'dot bg-c-dot',
      value: 0,
      disable: true,
    },
    {
      text: 'N',
      class: 'dot bg-n-dot',
      value: 0,
      disable: true,
    },
  ];

  public allDefectData = [
    {
      name: 'A',
      value: 0,
    },
    {
      name: 'B',
      value: 0,
    },
    {
      name: 'P',
      value: 0,
    },
    {
      name: 'C',
      value: 0,
    },
    {
      name: 'N',
      value: 0,
    },
  ];

  // viewChild template of filter
  @ViewChild('allFilterTemplateModal') allFilterTemplateRef!: TemplateRef<any>;
  //to do operation on model popup like close etc
  allFilterTemplateDialogRef!: NxModalRef<any>;
  // field to store defects locally, as this is not getting used in view so thats why it is present in component
  locationsAllDefectCountData: LocationDefect[] = [];
  // field to store the length to either show the bar chart widget or not(no data to display)
  locationsAllDefectCountDataLength: number = 0;
  //to store location id of filter
  @Input() selectedLocations: number[] = [];
  // to disable apply button on wrong date entry
  isApplyFilterButtonDisabled: boolean;
  //location item view model to save api response for export
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();
  // boolean to enable / disable the export button
  enableExportButton: boolean = false;
  // search model to store fields of search params
  queryParamList: SearchModels = new SearchModels();
  // conatins the list of all locations to show on filter modal
  @Input() locationList;

  constructor(
    private dialogService: NxDialogService,
    private dashboardService: DashboardService,
    private common: CommonService,
    private exportService: ExportService,
    private itemsService: LocationItemService,
    private itemGroupService: ItemGroupService,
    private layoutService: LayoutService
  ) { }

  /** Whether the number of selected elements matches the total number of rows. */
  get isAllSelected(): boolean {
    return this.selection.selected.length === this.allDefectCB.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */

  toggleAll() {
    this.isAllSelected
      ? this.selection.clear()
      : this.allDefectCB.map((row) => {
        if (!row.disable) {
          this.selection.toggle(row);
        }
      });
  }

  // display block or none depending on mouseover and mouseout over horizontal bar
  display(data: any, displayProperty: string) {
    switch (data.value.name) {
      case 'A': {
        document
          .querySelectorAll<HTMLElement>('.lineA')
          .forEach((dataA) => (dataA.style.display = displayProperty));
        break;
      }
      case 'B': {
        document
          .querySelectorAll<HTMLElement>('.lineB')
          .forEach((dataB) => (dataB.style.display = displayProperty));
        break;
      }
      case 'P': {
        document
          .querySelectorAll<HTMLElement>('.lineP')
          .forEach((dataP) => (dataP.style.display = displayProperty));
        break;
      }
      case 'C': {
        document
          .querySelectorAll<HTMLElement>('.lineC')
          .forEach((dataC) => (dataC.style.display = displayProperty));
        break;
      }
      case 'N': {
        document
          .querySelectorAll<HTMLElement>('.lineN')
          .forEach((dataN) => (dataN.style.display = displayProperty));
        break;
      }
      default: {
        break;
      }
    }
  }

  // on mouseover over any bar a line will be displayed to the left of the bar
  onActivate(data): void {
    this.display(data, 'block');
  }

  // on mouseout the line displayed to the left of the bar will be removed
  onDeactivate(data): void {
    this.display(data, 'none');
  }
  // open filter modal and setting its settings
  openAllFilterModal() {
    this.allFilterTemplateDialogRef = this.dialogService.open(
      this.allFilterTemplateRef,
      {
        showCloseIcon: true,
      }
    );
  }

  // close filter modal upon cross icon click
  closeFilterModal() {
    this.allFilterTemplateDialogRef.close();
  }
  // get all defect location data
  getAllDefectLocationList() {
    //show all location selected in dropdown list
    this.selectAlllocationsForFilter();
    //updateing selected location name
    this.getlocationNamesAndCount();
  }
  //to enable/disable the apply button based on location selection
  getSelectedlocationsCount(): boolean {
    return this.selectedLocations.length > 0 ? false : true;
  }
  //get locations name and count to display on dashboard
  getlocationNamesAndCount(): void {
    //set this.locationNamesAndCount to empty
    this.allDefectViewModel.locationNamesAndCount = '';
    //if all selected
    if (this.isAllLocationSelected()) {
      this.allDefectViewModel.locationNamesAndCount = 'All';
      return;
    }
    const selectedLocationsCount = this.selectedLocations
      ? this.selectedLocations.length
      : 0;
    let namesArray: any[] = [];
    // get selected location details 1for showing name in widget
    let selectedLoc = this.locationList.filter(loc => this.selectedLocations.includes(loc.id))
    // save the name in namesArray var
    selectedLoc.forEach((row) => {
      if (namesArray.length < 1) namesArray.push(row['name']);
    });
    if (selectedLocationsCount < 2) {
      //showing all three locations names
      this.allDefectViewModel.locationNamesAndCount = `${this.toTextMulti(
        namesArray,
        ','
      )}`;
    } else {
      //count is based on no of  selected location minus min names showing
      this.allDefectViewModel.locationNamesAndCount =
        `${this.toTextMulti(namesArray, ',')}` +
        ' +' +
        `${selectedLocationsCount - 1}`;
    }
  }
  // concatinates multiple values as comma separated string
  toTextMulti(value: string | string[], separator: string): string {
    // if value exists
    if (value) {
      // if its an array, then join its items as string
      if (Array.isArray(value)) {
        return value.join(separator);
      } else {
        // return string
        return value;
      }
    }
    // default return is empty string
    return '';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllLocationSelected(): boolean {
    let numSelected = this.selectedLocations.length;
    let numRows = this.locationList ? this.locationList.length : 0;
    return numSelected === numRows;
  }

  ngOnInit(): void {
    // finding difference between start and end dates of all defects, default set it to 1 year
    this.allDefectViewModel.dateRangeLocationsDifference = 365;
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => {
      this.allDefectViewModel.isDesktop = result;

    })
  }

  // close filter modal upon cross icon click
  applyFilter() {
    // finding difference between start and end dates of all defects
    if (!this.allDefectViewModel.allDefectDateRangeSwitcherModel) {
      this.allDefectViewModel.endDate = moment();
      this.allDefectViewModel.startDate = moment().subtract(1, 'years');
    }
    this.allDefectViewModel.dateRangeLocationsDifference =
      this.allDefectViewModel.endDate.diff(
        this.allDefectViewModel.startDate,
        'days'
      );

    //by clicking on apply button will apply filtered data on list and map
    this.getLocationAllDefects();
    //updateing location name
    this.getlocationNamesAndCount();
    //close the model after updating the list/map
    this.allFilterTemplateDialogRef.close();
  }

  //to get list of selected locationIds
  getLocationIds(): any[] {
    //string array to store location id
    let locationIds: string[] = [];
    this.selectedLocations.forEach((row) => {
      locationIds.push(row.toString());
    });
    return locationIds;
  }

  // set multi fields search values
  prepareSearchParamList(): void {
    this.common.updateSearchParamList(
      this.queryParamList,
      'defectInspectionDate',
      `[${this.allDefectViewModel.startDate.format(
        'YYYY-MM-DD'
      )} TO ${this.allDefectViewModel.endDate.format('YYYY-MM-DD')}]`
    );
    this.common.updateSearchParamList(
      this.queryParamList,
      'defectCode',
      this.allDefectViewModel.defectCode
    );
    this.common.updateSearchParamList(
      this.queryParamList,
      'defectItemLocationCode',
      this.selectedLocations.join('|')
    );
  }
  // function to get locations all defect count from api
  getLocationAllDefects(): void {
    this.prepareSearchParamList();
    this.dashboardService
      .getLocationDefects(this.queryParamList.searchList)
      .pipe(untilDestroyed(this), shareReplay())
      .subscribe((result) => {
        // check if we have required objects before accessing
        if (result?.data?.search?.buckets) {
          this.processGetLocationAllDefects(result);
        }
      });
  }

  async processGetLocationAllDefects(result): Promise<any> {
    // store results in local propertyt4
    this.locationsAllDefectCountData = result.data.search.buckets;

    // store the length for setting condition in template either to show bar chart or to show No data to Display
    this.locationsAllDefectCountDataLength =
      result.data.search.buckets[0].results.length;
    // map the defects types in model
    this.assignAllDefectsFromApiResponse();
    // disable the checkbox in case of zero records
    this.disableDefectTypeCheckbox();
  }

  // disable the checkboxes based on data returned from api
  disableDefectTypeCheckbox = () => {
    // fetch record A defects and pass the data to handle its checkbox
    this.enableDisableCheckBox(
      this.allDefectCB.find((defect) => defect.text == 'All A Defects')
    );
    // fetch record B defects and pass the data to handle its checkbox
    this.enableDisableCheckBox(
      this.allDefectCB.find((defect) => defect.text == 'B')
    );
    // fetch record P defects and pass the data to handle its checkbox
    this.enableDisableCheckBox(
      this.allDefectCB.find(
        (defect) => defect.text == 'P (Plant not available)'
      )
    );
    // fetch record C defects and pass the data to handle its checkbox
    this.enableDisableCheckBox(
      this.allDefectCB.find((defect) => defect.text == 'C')
    );
    // fetch record N defects and pass the data to handle its checkbox
    this.enableDisableCheckBox(
      this.allDefectCB.find((defect) => defect.text == 'N')
    );
    // check for select all
    // if all the defects have 0 value, disable the checkbox
    // else enable the checkbox
    if (this.allDefectCB.every((defect) => defect.value == 0))
      this.disableSelectAllCheckbox = true;
    else this.disableSelectAllCheckbox = false;
  };

  // takes the details of defect as parameter
  enableDisableCheckBox = (defectDetails: any): void => {
    // if Defect count is zero, checkbox should be disabled
    if (defectDetails.value == 0) defectDetails.disable = true;
    // if Defect count is not zero, checkbox should be enabled
    else defectDetails.disable = false;
  };
  // map the all defects types in model
  assignAllDefectsFromApiResponse = () => {
    //set the list to default values
    this.allDefectViewModel.allDefectList = [];
    //clear all defect values
    this.clearDefectsData();
    // loop to find a defect in result set
    this.locationsAllDefectCountData.forEach((defect) => {
      // check if location have count object
      if (defect?.results) {
        // find defect results object in defect object
        const defectCountObj = defect.results;
        // iterate on the array of results
        // outer loop to iterate the defect object based on results property
        for (const defectObj of defectCountObj) {
          // inner loop to iterate the defect object based on buckets property
          for (const defectBucketObject of defectObj.buckets) {
            this.generateAllDefectsList(
              defectBucketObject.results,
              defectObj.key
            );
          }
        }
      }
    });
    //assigning values to list with is being used in html
    this.allDefectViewModel.allDefectList = Object.assign(
      [],
      this.allDefectData
    );
    this.enableExportButton =
      this.allDefectData.findIndex((defect) => defect.value > 0) > -1;
    //calling assignAllDefectCodeToCB to assgin values
    this.assignAllDefectCodeToCB();
  };

  //to map the each defect to it linked list
  generateAllDefectsList = (defectBucketObject: any, locationId: string) => {
    this.clearAllLists();
    for (const defectBucketDetails of defectBucketObject) {
      const defectCode: any = defectBucketDetails.key;
      const defectCodeValue: any = defectBucketDetails.count;
      switch (defectCode) {
        case 'a':
          const aDefectType = this.allDefectData.find(
            (aObj) => aObj.name === 'A'
          );
          aDefectType.value = aDefectType.value + defectCodeValue;
          this.addLocationToAList(locationId);
          break;
        case 'p':
          const pDefectType = this.allDefectData.find(
            (pObj) => pObj.name === 'P'
          );
          pDefectType.value = pDefectType.value + defectCodeValue;
          this.addLocationToPList(locationId);
          break;
        case 'n':
          const nDefectType = this.allDefectData.find(
            (nObj) => nObj.name === 'N'
          );
          nDefectType.value = nDefectType.value + defectCodeValue;
          this.addLocationToNList(locationId);
          break;
        case 'b':
          const bDefectType = this.allDefectData.find(
            (bObj) => bObj.name === 'B'
          );
          bDefectType.value = bDefectType.value + defectCodeValue;
          this.addLocationToBList(locationId);
          break;
        case 'c':
          const cDefectType = this.allDefectData.find(
            (cObj) => cObj.name === 'C'
          );
          cDefectType.value = cDefectType.value + defectCodeValue;
          this.addLocationToCList(locationId);
          break;
        default:
          break;
      }
    }
  };

  //clear previous data to load fresh
  clearAllLists() {
    this.allDefectViewModel.locationsIdsOfADefects.length = 0;
    this.allDefectViewModel.locationsIdsOfPDefects.length = 0;
    this.allDefectViewModel.locationsIdsOfCDefects.length = 0;
    this.allDefectViewModel.locationsIdsOfNDefects.length = 0;
    this.allDefectViewModel.locationsIdsOfBDefects.length = 0;
  }

  // add location in A list
  addLocationToAList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.allDefectViewModel.locationsIdsOfADefects.push(locationModel);
  };
  // add location in P list
  addLocationToPList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.allDefectViewModel.locationsIdsOfPDefects.push(locationModel);
  };
  // add location in C list
  addLocationToCList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.allDefectViewModel.locationsIdsOfCDefects.push(locationModel);
  };
  // add location in N list
  addLocationToNList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.allDefectViewModel.locationsIdsOfNDefects.push(locationModel);
  };
  // add location in B list
  addLocationToBList = (locationId: string) => {
    let locationModel = {} as LocationDetailSearchModel;
    locationModel.locationId = locationId; // id of location
    this.allDefectViewModel.locationsIdsOfBDefects.push(locationModel);
  };
  //assign defect count to respective checkboxes
  assignAllDefectCodeToCB() {
    this.allDefectData.forEach((defect) => {
      switch (defect.name) {
        case 'A':
          const aCB = this.allDefectCB.find(
            (aObj) => aObj.text === 'All A Defects'
          );
          aCB.value = defect.value;
          break;
        case 'P':
          const pCB = this.allDefectCB.find(
            (aObj) => aObj.text === 'P (Plant not available)'
          );
          pCB.value = defect.value;
          break;
        case 'B':
          const bCB = this.allDefectCB.find((aObj) => aObj.text === 'B');
          bCB.value = defect.value;
          break;
        case 'C':
          const cCB = this.allDefectCB.find((aObj) => aObj.text === 'C');
          cCB.value = defect.value;
          break;
        case 'N':
          const nCB = this.allDefectCB.find((aObj) => aObj.text === 'N');
          nCB.value = defect.value;
          break;
        default:
          break;
      }
    });
  }
  //clear all value of all defect data
  clearDefectsData() {
    //line structure
    this.allDefectData.forEach((defect) => {
      defect.value = 0;
    });
    //CB list clear
    this.allDefectCB.forEach((cb) => {
      cb.value = 0;
    });
  }

  // disable Apply button within filter modal under certain conditions
  disableApplyButton() {
    if (this.allDefectViewModel.allDefectDateRangeSwitcherModel) {
      // Disable button: if startDate is less than min Date i.e. todaysDate or if startDate is greater than max date i.e. 60 days from todays date
      if (
        this.getDateValue(this.allDefectViewModel.startDate) === '' ||
        this.getDateValue(this.allDefectViewModel.endDate) === ''
      ) {
        this.isApplyFilterButtonDisabled = true;
      } //handle the case start date should not be greate then end date
      else if (
        this.allDefectViewModel.startDate.isBefore(
          this.allDefectViewModel.minStartDate,
          'day'
        ) ||
        this.allDefectViewModel.startDate.isAfter(
          this.allDefectViewModel.maxStartDate,
          'day'
        ) ||
        this.allDefectViewModel.startDate.isAfter(
          this.allDefectViewModel.endDate,
          'days'
        ) ||
        this.allDefectViewModel.endDate.isBefore(
          this.allDefectViewModel.minEndDate,
          'days'
        ) ||
        this.allDefectViewModel.endDate.isAfter(
          this.allDefectViewModel.maxEndDate,
          'days'
        ) ||
        this.allDefectViewModel.endDate.isBefore(
          this.allDefectViewModel.endDate,
          'days'
        )
      ) {
        this.isApplyFilterButtonDisabled = true;
      }
      // upon same start and end dates allow apply button
      else if (
        this.allDefectViewModel.startDate.isSame(
          this.allDefectViewModel.endDate,
          'days'
        )
      ) {
        this.isApplyFilterButtonDisabled = true;
      }
      // under all other conditions don't make button disabled
      else {
        this.isApplyFilterButtonDisabled = false;
      }
    } else {
      this.isApplyFilterButtonDisabled = false;
    }
  }

  // fetch the date and change its format
  getDateValue(value) {
    if (value === undefined || value === null) {
      return '';
    }
    return moment(value).format(this.allDefectViewModel.datePickerFormat);
  }

  ngOnChanges(): void {
    const itemGroupsList = this.itemGroupService.getSelectedItemGroupsIds();
    if (itemGroupsList && itemGroupsList.length > 0) {
      //getting data for locations
      this.getAllDefectLocationList();
      //get all defect counts
      this.getLocationAllDefects();
    }
  }

  //show all locations selected by default in dropdown list
  selectAlllocationsForFilter = () => {
    this.selectedLocations = [];
    if (this.locationList) {
      //iterate through list location we get from API
      this.locationList.forEach((location) => {
        this.selectedLocations.push(location.id);
        this.allDefectViewModel.allDefectSelectedLocations.push(location);
      });
    }
  };

  // to expand/ collapse All cuurent defects widget
  expandCollapseWidget() {
    // toggle variable upon expand/minmize
    this.allDefectViewModel.isAllDefectExpanded =
      !this.allDefectViewModel.isAllDefectExpanded;
  }

  /* All Defects navigation to Items work */
  viewDefectsItems = () => {
    // prepare the filter modal, which needs to be sent in navigation
    // in state variable
    const defectsFilterObj = this.prepareLocationDefectsFilterSearchModel();
    // navigate with filter object
    void this.common.navigate(AppRoutes.clientLocationsItems, {
      state: defectsFilterObj,
    });
  };
  // prepare the model to be used in state variable in navigation
  // When we click on View Items on All-defect widget
  // we need to pass 4 variables to Items page. 1. Start date, 2. End date, 3. List of selected Locations, 4. Selected Defect types
  prepareLocationDefectsFilterSearchModel =
    (): LocationDefectListSearchModel => {
      let locationsOfSelectedDefects = [];
      // array containing all defect codes selected
      let selectedDefectCodes: string[] = [];
      // model object having all 4 properties
      let defectsFilterModel = {} as LocationDefectListSearchModel;
      // initialize the list having location details to display in tags
      defectsFilterModel.locationDetailList = [];
      // initialize the list having location details of all selected locations
      defectsFilterModel.allSelectedLocationsList = [];
      // add start date in model property
      defectsFilterModel.startDate =
        this.allDefectViewModel.endDate.format('YYYY-MM-DD');
      // add end date in model property
      defectsFilterModel.endDate =
        this.allDefectViewModel.startDate.format('YYYY-MM-DD');
      // add selected defect type from All defect widget
      if (this.selection) {
        // iterate with all the selected rows of defect types present in selection model
        for (let selectedRow of this.selection.selected) {
          // add value in local array variable based on defect code variable
          switch (selectedRow.text) {
            case 'All A Defects': {
              // if All A Defects is checked, then add particularly its locations only
              this.allDefectViewModel.locationsIdsOfADefects.forEach(
                (location) => locationsOfSelectedDefects.push(location)
              );
              // push A in array
              selectedDefectCodes.push('A');
              break;
            }
            case 'P (Plant not available)': {
              // if P is checked, then add particularly its locations only
              this.allDefectViewModel.locationsIdsOfPDefects.forEach(
                (location) => locationsOfSelectedDefects.push(location)
              );
              // push P in array
              selectedDefectCodes.push('P');
              break;
            }
            case 'B': {
              // if B is checked, then add particularly its locations only
              this.allDefectViewModel.locationsIdsOfBDefects.forEach(
                (location) => locationsOfSelectedDefects.push(location)
              );
              // push B in array
              selectedDefectCodes.push('B');
              break;
            }
            case 'C': {
              // if C is checked, then add particularly its locations only
              this.allDefectViewModel.locationsIdsOfCDefects.forEach(
                (location) => locationsOfSelectedDefects.push(location)
              );
              // push C in array
              selectedDefectCodes.push('C');
              break;
            }
            case 'N': {
              // if N is checked, then add particularly its locations only
              this.allDefectViewModel.locationsIdsOfNDefects.forEach(
                (location) => locationsOfSelectedDefects.push(location)
              );
              // push N in array
              selectedDefectCodes.push('N');
              break;
            }
            default:
              break;
          }
        }
      }
      // concat all the defect codes with comma
      defectsFilterModel.locationDefectTypes = selectedDefectCodes.join(',');
      // fetch properties to be passed to items screen
      defectsFilterModel = this.prepareAllDefectsLocationList(
        defectsFilterModel,
        locationsOfSelectedDefects
      );
      // return the prepared model
      return defectsFilterModel;
    };
  // make a list of properties which are required to pass to items screen
  prepareAllDefectsLocationList = (
    defectsFilterModel: LocationDefectListSearchModel,
    locationsOfSelectedDefects: any[]
  ): LocationDefectListSearchModel => {
    // if selection model is present
    if (this.selectedLocations) {
      // iterate with all the selected rows present in selection model
      for (let selectedRow of this.selectedLocations) {
        // check if row is selected
        if (selectedRow) {
          // create object to have properties of location details
          let locationModel = {} as LocationDetailSearchModel;
          locationModel.locationId = selectedRow; // id of location
          locationModel.locationName = this.locationList.find(
            (al) => al.id == selectedRow
          ).name; // name of location
          if (
            locationsOfSelectedDefects.some(
              (row) => row.locationId == selectedRow
            )
          ) {
            // add details in list to pass to items to show in tag
            defectsFilterModel.locationDetailList.push(locationModel);
          }
          // add details in list to store for reload filters later
          defectsFilterModel.allSelectedLocationsList.push(locationModel);
        }
      }
    }
    // return the prepare model
    return defectsFilterModel;
  };

  //export all defect widget filtered data to csv
  defectCountExportToCSV = (): void => {
    //Api call to get items detail for All-defect locations
    const allDefectLocationsIds = this.selectedLocations
      ? this.selectedLocations
      : [];
    // list of objects to be passed to service method
    const variables = {
      searchList: [],
      order: 'ASC',
      sortName: '_id',
      from: 0,
      size: 100,
      dueFrom: this.allDefectViewModel.startDate.format('YYYY-MM-DD'),
      dueTo: this.allDefectViewModel.endDate.format('YYYY-MM-DD'),
      locationIds: allDefectLocationsIds,
      defectTypes: 'A,B,P,C,N',
      itemGroupIds: this.itemGroupService.getSelectedItemGroupsIds(),
    };
    this.itemsService
      .getItemsWithDefects(variables, false)
      .pipe(untilDestroyed(this))
      .subscribe((allDefectItems) => {
        if (allDefectItems?.data?.itemsWithDefects?.results) {
          this.processExportToCSV(allDefectItems, variables)
            .then((response) => {
              if (response?.data?.itemsWithDefects?.results) {
                // append the new page data to array to get one final array with pages data
                this.locationItemViewModel.itemLocations.items = [
                  ...this.locationItemViewModel.itemLocations.items,
                  ...allDefectItems.data.itemsWithDefects.results,
                ];
              }
            })
            .then(() => {
              this.exportService.exportToCsv(
                this.locationItemViewModel.itemLocations.items,
                this.allDefectViewModel.exportFileName,
                this.allDefectViewModel.options,
                this.locationList,
                'AllDefect'
              );
            });
        }
      });
  };

  async processExportToCSV(allDefectItems, variables): Promise<any> {
    this.locationItemViewModel.itemLocations.items =
      allDefectItems.data.itemsWithDefects.results;
    for (
      let i = 1;
      i < Math.ceil(allDefectItems.data.itemsWithDefects.totalCount / 100);
      i++
    ) {
      // call the promise function to get items from api
      await this.itemsService.getItemsWithDefectsByPromise(variables);
    }
  }
}
