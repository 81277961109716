import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrganisationListModel } from '../schemas/organisation';
import { ClientUserNameDetails } from '../schemas/users';
import { BannerDetail } from '../shared/enums/banner';
import { ErrorModel } from '../shared/models/error.Model';

// Common service to have device type, banner , loader properties.

@Injectable()
export class LayoutService {

    //#region App Device Detection Work
    private isDesktop = new BehaviorSubject(false);
    currentDevice = this.isDesktop.asObservable();

    //method to set value to observable
    setDevice(appWidth: number) {
        // if app width is less than 900px, then consider it a mobile view
        if (appWidth < 900) {
            this.isDesktop.next(false)
        }
        // if app width is greater than 900, then consider it a desktop view
        else {
            this.isDesktop.next(true)
        }
    }
    //#endregion

    //#region Banner Setting Work
    // creating instance to store banner title
    private bannerTitle$ = new BehaviorSubject('');
    // creating the instance to store banner text
    private bannerText$ = new BehaviorSubject('');
    // creating instance to store visibility of banner
    private showBanner$ = new BehaviorSubject(true);
    // creating instance to set breadcrumb background
    private showBreadcrumbBg$ = new BehaviorSubject(true);
    // creating the instance to store sub banner text
    private subBannerText$ = new BehaviorSubject('');
    // creating the instance to store the names of item Group Assigned
    private itemGroupList$ = new BehaviorSubject([])
    // creating the instance to store the name and id of organisation Assigned
    private organisationObject$ = new BehaviorSubject([])
    // creating the instance to store the names of all item Group 
    public itemGroupListComplete$ = new BehaviorSubject([])
    // creating the instance to store the name of client user later to be used in breadcrumn
    public clientUserNameObject$ = new BehaviorSubject<ClientUserNameDetails>(<ClientUserNameDetails>({ firstName: '', surName: '' }));
    // making banner instance obserable
    currentBannerText = this.bannerText$.asObservable();
    // making bannerTitle instance obserable
    currentBannerTitle = this.bannerTitle$.asObservable();
    // making showbanner instance obserable
    showCurrentBanner = this.showBanner$.asObservable();
    // making banner instance obserable
    currentSubBannerText = this.subBannerText$.asObservable();
    // making assigned item group list instance observable
    currentAssignedItemGroups = this.itemGroupList$.asObservable();
    // making item group list instance observable
    currentItemGroups = this.itemGroupListComplete$.asObservable();
    // making organisation instance observable
    currentOrganisationObject = this.organisationObject$.asObservable();
    // making breadcrumb instance observable
    showBreadcrumbBackground = this.showBreadcrumbBg$.asObservable();
    // creating the instance to store banner height
    private bannerHeight = new BehaviorSubject('');
    // making banner height variable observable
    currentBannerHeight = this.bannerHeight.asObservable();
    // creating banner error subject to make it observabe
    private bannerErrorSubject = new BehaviorSubject<ErrorModel>(new ErrorModel());
    // banner error observable object
    bannerError = this.bannerErrorSubject.asObservable();
    // client user name details observable object
    clientUserNameDetails = this.clientUserNameObject$.asObservable();

    // method to set banner text and height
    setBanner(value: string, height: string = BannerDetail.bannerHeight) {
        // setting banner text
        this.bannerText$.next(value);
        // setting banner height
        this.bannerHeight.next(height);
        // setting showBanner to true 
        this.showBanner$.next(true);
        //  set breadcrumb banner background , hide/show
        this.showBreadcrumbBg$.next(false)
    }
    // method to set banner text and height
    setSubBanner(value: string, height: string = BannerDetail.bannerHeight) {
        // setting banner text
        this.subBannerText$.next(value);
        // setting banner height
        this.bannerHeight.next(height);
    }
    // method to set banner error 
    setBannerError(errorModel: ErrorModel) {
        this.bannerErrorSubject.next(errorModel);
    }

    // method to set banner text, bannerTitle and visibility when background color is removed
    setBannerVisibilty(value: string, bannerVisibility: boolean = true, breadcrumbBg: boolean = true, locationName: string = '') {
        // setting banner text
        this.bannerText$.next(value);
        // setting banner text
        this.showBanner$.next(bannerVisibility);
        //  set breadcrumb banner background , hide/show
        this.showBreadcrumbBg$.next(breadcrumbBg)
        // setting bannerTitle
        this.bannerTitle$.next(locationName)
    }

    // method to set assigned item Group list
    assignItemGroups(itemGroupList: string[]) {
        this.itemGroupList$.next(itemGroupList)
    }
    // method to set all item Group list
    assignAllItemGroups(itemGroupList: any) {
        this.itemGroupListComplete$.next(itemGroupList);
    }
    //#endregion

    // method to set assigned item Group list
    assignOrganisation(organizationObject: OrganisationListModel[]) {
        this.organisationObject$.next(organizationObject)
    }

    // method to set the firstName and surname of client user
    // only useful when updating the permission of client user
    // used to update breadcrumb in header component

    setClientUserName(clientUserNameObj: ClientUserNameDetails) {
        this.clientUserNameObject$.next(clientUserNameObj)
    }
    //#region Loader Setting Work
    private loaderSource = new BehaviorSubject(false);
    currentLoaderStatus = this.loaderSource.asObservable();
    // Function for toggling loader spinner
    toggleLoader(loader: boolean) {
        this.loaderSource.next(loader)
    }
    //#endregion



}