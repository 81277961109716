import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { CanDeactivateGuard } from 'src/app/services/auth-service/can-deactivate.guard';
import { RoleGuard } from 'src/app/services/auth-service/role.guard';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { Role } from 'src/app/shared/enums/role';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';

// Route for clients/userregistraions
const routes: Routes = [
  // Route for /Locations
  {
    path: '', component: UserListComponent, canActivate: [AuthGuard, RoleGuard], data: {
      breadcrumb: BreadcrumbEnums.userManagment, expectedRoles: [Role.clientAdmin, Role.organizationUser]
    }
  },
  {
    path: 'register', component: UserRegistrationComponent, canActivate: [AuthGuard, RoleGuard], data: {
      breadcrumb: BreadcrumbEnums.userRegistration, expectedRoles: [Role.clientAdmin, Role.organizationUser]
    }
  },
  {
    path: 'edit/:id', component: EditUserComponent, canActivate: [AuthGuard, RoleGuard], canDeactivate: [CanDeactivateGuard], data: {
      breadcrumb: BreadcrumbEnums.editUser, expectedRoles: [Role.clientAdmin, Role.organizationUser]
    }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
