import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportLocationItem, Item } from 'src/app/schemas/location-items';
import { SearchModels } from 'src/app/schemas/searchModel';
import { CommonService } from 'src/app/services/common.service';
import { ExportService } from 'src/app/services/export.service';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { LayoutService } from 'src/app/services/layout-service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { DefectBadgeColorPipe } from 'src/app/shared/pipes/defectBadge.color.pipe';
import { ItemDetailsViewModel } from './item-details-viewModel';

@UntilDestroy()
@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {

  // number field to contain the current index number
  currentIndex: number = 0;

  // constructor with required services injected
  constructor(private layoutService: LayoutService, private itemsService: LocationItemService, private common: CommonService,
    private activatedRoute: ActivatedRoute, private defectBadgeColorPipe: DefectBadgeColorPipe,
    private exportService: ExportService) { }

  //item detail view model
  itemDetailViewModel: ItemDetailsViewModel = new ItemDetailsViewModel();
  // boolen to hold status of item detail call
  isItemDetailCall: boolean = true;
  // list of query params to be sent as filters to items api
  queryItemParamList: SearchModels = new SearchModels();

  async ngOnInit() {
    // set subTitle of the banner
    this.layoutService.setSubBanner("");
    // subscribe to device type detection observable
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => this.itemDetailViewModel.isDesktop = result);
    //  subscribed to route params change
    this.activatedRoute.params.subscribe(params => {
      // look for itemId presence in params
      const itemId = this.common.IsNullORUndefined(params, "itemId", "")
      if (itemId)
        // get location id from the route and set in viewmodel
        this.itemDetailViewModel.itemId = itemId;
      else
        // look for id presence in params and assign
        this.itemDetailViewModel.itemId = this.common.IsNullORUndefined(params, "id", "");

    });
    // prepare the search param array to be send to api containing item Id
    this.prepareSearchParamList();
    // fetch details of Item
    await this.getItemDetails();
    // the items section should by default be open when the user views the page
    // time delay is added to firstly let the page load and then expand the accordion
    setTimeout(() => this.common.clickElementByClassNameAndPosition('nx-expansion-panel__header-content', -1), 1000)
  }

  // function to fetch details of Item by using ItemId as search param
  async getItemDetails() {

    const apiResponse = await this.itemsService.getLocationItems(this.queryItemParamList.searchList, this.isItemDetailCall)
    // map the results present at index 0
    if (apiResponse.itemDetails && apiResponse.itemDetails.length >= 1) {
      this.itemDetailViewModel.itemDetail = apiResponse.itemDetails[0];

      // update the item status code
      this.updateItemStatusObject();
      // update the breadcrumb node with item id
      this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, `Item ${this.itemDetailViewModel.itemDetail.id}`);
      //Banner text setting
      this.layoutService.setBannerVisibilty(this.itemDetailViewModel.itemDetail.id, true, false);

      // transform defects for background colors
      this.itemDetailViewModel.itemDetail.reports.results = this.defectBadgeColorPipe.transform(this.itemDetailViewModel.itemDetail.reports.results);
    }
    // set subTitle of the banner
    this.layoutService.setSubBanner(BannerDetail.NoText);
  }

  // PURPOSE: looks for status, if its 'Not available'
  // append the count of P reports in status description
  updateItemStatusObject = () => {
    // looks for status
    if (this.itemDetailViewModel.itemDetail.status.description == "Not Available") {
      if (this.itemDetailViewModel.itemDetail.reports && this.itemDetailViewModel.itemDetail.reports.results) {
        // calculate the count of consective p Defects reports
        const pDefectReportCount = this.pDefectCountCalculation();
        // append the count at the end of status field
        if (pDefectReportCount > 1)
          this.itemDetailViewModel.itemDetail.status.description = `${this.itemDetailViewModel.itemDetail.status.description} (${pDefectReportCount})`
      }
    }
  }
  // count the number of reports, having P defect consectively
  pDefectCountCalculation = (): number => {
    // holds the count of reports
    let pDefectReportCount: number = 0;
    // property to know the previous report it has
    let isPDefect: boolean = false;
    // iterates through reports resultset
    this.itemDetailViewModel.itemDetail.reports.results.forEach((report, index) => {
      if (report.defectCode && report.defectCode.code && report.defectCode.code == "P") {

        // check if previous report was of P defect
        if (isPDefect) {
          // increment the count
          pDefectReportCount += 2;
          // mark this bit as false 
          isPDefect = false
        }
        else {
          // mark this bit as true 
          isPDefect = true;
        }
      }
      else {
        // mark this bit as false 
        isPDefect = false
      }
    })
    return pDefectReportCount;
  }
  // set multi fields search values
  prepareSearchParamList(): void {
    this.common.updateSearchParamList(this.queryItemParamList, "itemId", this.itemDetailViewModel.itemId.toString());
  }
  //export item details as csv
  exportItemsToCSV() {
    //we are using header option.
    const options = {
      headers: ["Current report/ID", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
        "Defect code", "Next due", "Sub location", "Detailed location", "Item status", "Policy number", "ACE plant no",
        "Manufacture", "Date of manufacture", "Written scheme reference", "Next contractual date"],

    };
    if (this.itemDetailViewModel.itemDetail) {
      // export the content to csv file
      this.exportService.exportItemDetailToCSV(this.createObjectforExport(this.itemDetailViewModel.itemDetail), this.itemDetailViewModel.exportItemFileName, options)
    }
  }
  //creating it new object to export as csv, to keep export existing one intact
  createObjectforExport(item: Item): ExportLocationItem[] {
    const { reports, serialNumber, clientPlantNumber, plantType, discipline, inspections,
      policyNumber, manufacturer, manufacturingYear, schemeReference, location, status } = item;

    const exportLocationItemObj: ExportLocationItem = {
      reportId: this.getDefaultValue(reports?.results[0]?.id, '-'),
      serialNo: this.getDefaultValue(serialNumber, '-'),
      plantNo: this.getDefaultValue(clientPlantNumber, '-'),
      plantDescription: this.getDefaultValue(plantType?.description, '-'),
      discipline: this.getDefaultValue(discipline?.description, '-'),
      lastInspected: inspections?.inspectionDate || null,
      defectCode: this.getDefaultValue(reports?.results[0]?.defectCode?.code, '-'),
      nextDue: this.getDefaultValue(inspections?.nextInspectionDate, '-'),
      subLocation: this.getDefaultValue(location?.subCodeDescription, '-'),
      detailedLocation: this.getDefaultValue(location?.description, '-'),
      itemstatus: this.getDefaultValue(status?.description, '-'),
      policyNumber: this.getDefaultValue(policyNumber, '-'),
      ACEPlantNo: this.getDefaultValue(plantType?.code, '-'),
      manufacturer: this.getDefaultValue(manufacturer, '-'),
      manufacturingYear: this.getDefaultValue(manufacturingYear, '-'),
      schemeReference: this.getDefaultValue(schemeReference?.fileName, '-'),
      nextContractualDate: this.getDefaultValue(inspections?.nextInspectionDate, '-'),

    };
    return [exportLocationItemObj];

  }
  //to reduce the complex nature of above method, added this method for default values
  getDefaultValue(value: any, defaultValue: any) {
    return value || defaultValue;
  }


}
