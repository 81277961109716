<!-- contact us start -->
<h1 id="icons-top" *ngIf="helpSupportViewModel.isDesktop">Contact us</h1>
<div class="align-contact-us-info " [ngClass]="{
    'nx-margin-x-m': !helpSupportViewModel.isDesktop
  }">
    <p>If you would like to contact us about anything, we would love to hear from you</p>
    <nx-link class="align-contact-us-email blueColor" (click)="openEmailClient()">
        <nx-icon name="mail-o" size="s" class="nx-margin-right-xs"></nx-icon>
        <a class="underline-contact-us-email">{{helpSupportViewModel.contactUsEmailLink}}</a>
    </nx-link>
</div>

<!-- contact us end -->
<h1 class="nx-margin-top-s" *ngIf="helpSupportViewModel.isDesktop">Useful resources documentation</h1>
<!--Mobile view-->
<div class="nx-margin-top-s" [ngClass]="{
    'nx-margin-x-m': !helpSupportViewModel.isDesktop
  }" *ngIf="!helpSupportViewModel.isDesktop">Useful resources documentation</div>
<!-- Icons -->
<div class="icons-container">
    <div class="center" (click)="goToInspectionHub()">
        <nx-icon name="helmet-construction" size="m"></nx-icon>
        <div class="align-arrow-view-document blueColor">
            <nx-icon name="arrow-down" size="s"></nx-icon>
            <p class="icon-name">Inspection Hub</p>
        </div>
    </div>
    <div class="center" (click)="goToInsurance()">
        <nx-icon name="product-care-insurance" size="m"></nx-icon>
        <div class="blueColor align-arrow-view-document">
            <nx-icon name="arrow-down" size="s"></nx-icon>
            <p class="icon-name">Insurance</p>
        </div>
    </div>
    <div class="center" (click)="goToInspectionServices()">
        <nx-icon name="product-board-paper-note" size="m"></nx-icon>
        <div class=" align-arrow-view-document blueColor">
            <nx-icon name="arrow-down" size="s"></nx-icon>
            <p class="icon-name">Inspection Services</p>
        </div>
    </div>
    <div class="center" (click)="goToHealthSafetyQuality()">
        <nx-icon name="product-health-bag-doctor" size="m"></nx-icon>
        <div class=" align-arrow-view-document blueColor">
            <nx-icon name="arrow-down" size="s"></nx-icon>
            <p class="icon-name">Health, Safety & Quality</p>
        </div>
    </div>
</div>

<div>


    <!-- inspection-hub -->
    <div id="inspection-hub">
        <div class="general-sub-container">
            <span class="align-icon-heading">
                <nx-icon name="helmet-construction" outline="true" size="m"
                    class="blueColor nx-margin-right-s"></nx-icon>
                <h2 class="center" style="padding-top: 20px;">Inspection Hub</h2>
            </span>
            <hr class="nx-margin-bottom-m">
            <div class="file-container-parent">
                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.inspectionHubResourcesCol2">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>

                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.inspectionHubResourcesCol1">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- go to top -->
    <ng-container *ngTemplateOutlet="goToTopTemplate"></ng-container>

    <!-- Insurance -->
    <div id="insurance">
        <div class="general-sub-container">
            <span class="align-icon-heading">
                <nx-icon name="product-care-insurance" outline="true" size="m"
                    class="blueColor nx-margin-right-s"></nx-icon>
                <h2 class="center" style="padding-top: 20px;">Insurance</h2>
            </span>
            <hr class="nx-margin-bottom-m">
            <div class="file-container-parent">
                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.insuranceResourcesCol2">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>

                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.insuranceResourcesCol1">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- go to top -->
    <ng-container *ngTemplateOutlet="goToTopTemplate"></ng-container>

    <!-- Inspection services -->
    <div id="inspection-services">
        <div class="general-sub-container">
            <span class="align-icon-heading">
                <nx-icon name="product-board-paper-note" outline="true" size="m"
                    class="blueColor nx-margin-right-s"></nx-icon>
                <h2 class="center" style="padding-top: 20px;">Inspection Services</h2>
            </span>
            <hr class="nx-margin-bottom-m">
            <div class="file-container-parent">
                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.inspectionResourcesCol1">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>

                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.inspectionResourcesCol2">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- go to top -->
    <ng-container *ngTemplateOutlet="goToTopTemplate"></ng-container>

    <!-- Health, Safety & Quality  -->
    <div id="health-safety-quality">
        <div class="general-sub-container">
            <span class="align-icon-heading">
                <nx-icon name="product-health-bag-doctor" outline="true" size="m"
                    class="blueColor nx-margin-right-s"></nx-icon>
                <h2 class="center" style="padding-top: 20px;">Health, Safety & Quality</h2>
            </span>
            <hr class="nx-margin-bottom-m">
            <div class="file-container-parent">
                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.healthSafetyResourcesCol1">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>

                <div class="files-container-child">
                    <div class="icon-text-alignment"
                        *ngFor="let resource of helpSupportViewModel.healthSafetyResourcesCol2">
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='PDF'" rel=noopener>
                            <ng-container *ngTemplateOutlet="pdfIcon" id="eServicesPdfIcon">
                            </ng-container>
                        </a>
                        <a href="{{resource.url}}" target="_blank" *ngIf="resource.type=='Web Link'" rel=noopener>
                            <ng-container *ngTemplateOutlet="globeIcon" id="eservicesGlobeIcon">
                            </ng-container>
                        </a>

                        <a href="{{resource.url}}" rel="noopener" class="nx-margin-bottom-s" target="_blank"
                            id="eServicesTitle" rel=noopener>{{resource.title}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- go to top -->
    <ng-container *ngTemplateOutlet="goToTopTemplate"></ng-container>
</div>

<ng-template #pdfIcon>
    <nx-icon name="pdf" size="s" class="blueColor nx-margin-right-m nx-margin-bottom-s" aria-label="pdf-icon"></nx-icon>
</ng-template>
<ng-template #globeIcon>
    <nx-icon name="globe" size="s" class="blueColor nx-margin-right-m nx-margin-bottom-s"
        aria-label="globe-icon"></nx-icon>
</ng-template>

<ng-template #goToTopTemplate>
    <div class="blueColor back-to-top nx-margin-top-m nx-margin-bottom-m" (click)="goToTop()">
        <nx-icon name="arrow-up" size="s" class="nx-margin-right-s"></nx-icon>
        <span>BACK TO TOP</span>
    </div>
</ng-template>

<!-- start -- mail provider not founded on system modal -->
<ng-template #noMailServiceProviderFoundModal>
    <div class="centered-content">
        <h2 class="nx-margin-bottom-m">
            Email application cannot be found
        </h2>
        <div class="nx-margin-bottom-s p-font-size-increase">
            <p>
                We were unable to detect an email application on your device.
            </p>
            <p>
                Please make sure you have an email application installed before you attempt to contact us.
            </p>
        </div>
        <div>
            <button nxButton="primary" (click)="closeNoMailServiceFoundModal()">
                Ok
            </button>
        </div>
    </div>
</ng-template>
<!-- end -- mail service provider not found modal -->