<div class="hline"></div>
<footer nx-footer class="footer">

  <div nxLayout="grid nogutters">
    <nx-footer-navigation nxRow class="margin-left">
      <nx-footer-link>
        <a routerLink="./">Privacy & Legal Notices</a>
      </nx-footer-link>
      <nx-footer-link>
        <a routerLink="./">About Cookies</a>
      </nx-footer-link>
      <nx-footer-link>
        <a routerLink="./">Accessibility</a>
      </nx-footer-link>
      <nx-footer-link>
        <a routerLink="./">Terms of use</a>
      </nx-footer-link>
    </nx-footer-navigation>
    <div nxRow class="alignment">
      <div nxCol="8">
        <nx-footer-copyright>
          &copy; Allianz Insurance plc 2023. All Rights Reserved.
        </nx-footer-copyright>
        <br />
        <span>Allianz Insurance plc is authorised by the Prudential Regulation
          Authority and regulated by the Financial Conduct Authority and the
          Prudential Regulation Authority. Financial Services Register number
          121849.</span>
      </div>
      <div nxCol="4">
        <figure nxFigure>
          <img src="assets/logos/allianz-footer-logo.jpg" alt="Allianz Footer Logo" width="260" />
        </figure>
      </div>
    </div>

    <div nxRow class="center-align nx-margin-bottom-m">
      <figure nxFigure>
        <img src="assets/logos/allianz-footer-logo.jpg" alt="Allianz Footer Logo" width="260" />
      </figure>
    </div>
    <div nxRow class="center-align">
      <nx-footer-copyright class="nx-margin-bottom-s">
        &copy; Allianz Insurance plc 2023. All Rights Reserved.
      </nx-footer-copyright>
      <br />
      <span class="align-text-centrally">Allianz Insurance plc is authorised by the Prudential Regulation
        Authority and regulated by the Financial Conduct Authority and the
        Prudential Regulation Authority. Financial Services Register number
        121849.</span>
    </div>
  </div>
</footer>