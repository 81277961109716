import { Pipe, PipeTransform } from '@angular/core';
import { Item } from 'src/app/schemas/location-items';
import { ItemDefectCodeBadgeColor } from '../enums/item.defect.code.badge.color';
@Pipe({
    name: 'badgeColorPipe'
})
// pipe to assign badge color according to defect code
export class BadgeColorPipe implements PipeTransform {
    transform(value: Item[]): Item[] {
        value.forEach(element => {
            if (element.reports && element.reports.results.length > 0 && element.reports.results[0].defectCode && element.reports.results[0].defectCode.code) {
                // checking if there are any subdefects, which we sure that would be against
                // A type defect
                if (element.subDefects && element.subDefects.length > 0) {
                    // updating defect code to include latest subdefect with it.
                    element.reports.results[0].defectCode.code =
                        element.reports.results[0].defectCode.code + element.subDefects[0];
                }
                switch (element.reports.results[0].defectCode.code) {
                    case "A":
                    case "AE": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.AEDefectCodeColor; break;
                    case "AI": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.AIDefectCodeColor; break;
                    case "AT": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.ATDefectCodeColor; break;
                    case "B": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.BDefectCodeColor; break;
                    case "P": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.PDefectCodeColor; break;
                    case "C": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.CDefectCodeColor; break;
                    case "N": element.defectCodeBadgeColor = ItemDefectCodeBadgeColor.NDefectCodeColor; break;
                    default: break;
                }
            }
        });
        return value;
    }
}