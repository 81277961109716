import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { getUserTitles, UserTitle } from "src/app/schemas/reference-data";
import { GraphQLService } from "./graphql.service";

@Injectable()

// user service to fetch its data
export class ReferenceDataService {

    // standard constructor with graphql wrapper injected
    public constructor(private graphQlWrapper: GraphQLService) { }

    // fetch reference titles 
    getUserTitles(): Observable<UserTitle[]> {
        return this.graphQlWrapper.getList(getUserTitles, {}).pipe(map((response) => {
            if (response?.data?.referenceData && response.data.referenceData?.length > 0)
                return response.data.referenceData
        }));
    }
}