import { ApolloQueryResult } from "apollo-client";
import { PersonalisationApi } from "src/app/schemas/personalisation";
import { BaseResponse } from "./BaseResponse.Model";

export class PersonalisationResponse extends BaseResponse {
    // hold personalisation object
    personalisation: PersonalisationApi;

    constructor(response: ApolloQueryResult<any>) {
        super(response);
        // setting up the class properties by getting properties from gql api response
        this.personalisation = this.getResponseProperty("personalisation", response.data);
    }
}