
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SharedMessages } from 'src/app/shared/shared-messages';

@Component({
    selector: 'item-group-modal',
    templateUrl: './item-group-modal.html',
    styleUrls: ['../common-modal.component.scss']
})

// Component to handle the item group model work
export class ItemGroupModal implements OnInit {

    // Input having the list of all item groups
    @Input() itemGroupList: any;
    // Input holding the list of all assigned item groups
    @Input() itemGroupAssignedList: any;
    // Variable to handle th validation message on popup
    showValidationError: boolean = false
    // Variable to know the requirement of opening the model
    @Input() showPopup
    // Variable having the data of whether all groups are selected or not
    isSelectAll: boolean = false
    // property to hold the selected Item group Name
    selectedItemGroupName: string = "";
    // output property to emit the closing requirement of modal
    @Output() setModalStatus: EventEmitter<boolean> = new EventEmitter();
    // dummy text 
    dummyText = SharedMessages.longDummyText;
    // item group modal constructor
    constructor(private layourService: LayoutService, private common: CommonService, private localStorageService: LocalStorageService) {
    }

    ngOnInit() {
        if (this.itemGroupAssignedList && this.itemGroupAssignedList.length > 0) {
            this.selectedItemGroupName = this.itemGroupAssignedList[0].name
        }
        // sort the item group list by ascending order
        if (this.itemGroupList)
            this.sortItemGroupList();
    }


    // sort the item group list by ascending order
    sortItemGroupList() {
        this.itemGroupList.sort((a, b) => {
            return this.common.compare(a.name, b.name, "asc");
        })
    }

    // method to close the modal popup
    closeModal = () => {
        //  this can be closed
        if (this.selectedItemGroupName) {
            this.showValidationError = false;
            this.showPopup = !this.showPopup
            this.setModalStatus.emit(this.showPopup)
        }
        //  no item group is selected, need to show the validation
        else {

            this.showValidationError = true;
        }
    }

    // save the item groups selection preference
    saveItemGroups = () => {
        //filter the list with only checked status
        if (this.selectedItemGroupName) {
            const checkedItemGroup = this.itemGroupList.filter(x => x.name == this.selectedItemGroupName)
            this.itemGroupAssignedList = []
            // need to replace with fresh preference
            this.localStorageService.saveObject(LocalStorageKeys.SelectedItemGroups, checkedItemGroup);
            // Add the list of selected assigned item groups
            checkedItemGroup.forEach((row: any) => {
                this.itemGroupAssignedList.push(row.name)
            })
            // Pass the list to observables to show the latest data on header
            this.layourService.assignItemGroups(this.itemGroupAssignedList)
            this.layourService.assignAllItemGroups(this.itemGroupList)
            // close the popup
            this.closeModal()
        }
        else {
            this.showValidationError = true;
        }
    }

    // send back the status that popup is cancelled
    closeModalStatus = () => {
        this.showPopup = !this.showPopup
        this.setModalStatus.emit(this.showPopup)
    }
}