import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EndpointType } from 'src/app/shared/enums/endpointType';
import { LayoutService } from '../layout-service';

@Injectable({
	providedIn: 'root'
})
export class GraphQLService {

	constructor(private apollo: Apollo, private layoutService: LayoutService) {
	}
	// Generic get list of results
	getList(graphqlQuery, variables, isLoading = true, host = EndpointType.hubHost): Observable<ApolloQueryResult<any>> {
		this.toggleLoader(isLoading);
		return this.apollo.use(host).watchQuery<any>({
			query: graphqlQuery,
			variables: variables,
			errorPolicy: 'all',
			fetchPolicy: 'no-cache'
		}).valueChanges.pipe(tap(_x => {
			this.toggleLoader(false)
		}))
	}

	// Generic get single result
	public getById = (id, graphqlQuery, host = EndpointType.hubHost) => {
		this.apollo.use(host).query({
			query: graphqlQuery,
			variables: { cId: id }
		});
	}

	// Generic mutation function
	create(variables, graphQuery, host = EndpointType.hubHost): Observable<any> {
		this.toggleLoader()
		return this.apollo.use(host).mutate({
			mutation: graphQuery,
			variables: variables
		}).pipe(tap(_x => this.toggleLoader(false)));
	}

	// Generic mutation for deletion
	public delete = (id: number, graphQuery, host = EndpointType.hubHost) => {
		this.apollo.use(host).mutate({
			mutation: graphQuery,
			variables: { cId: id }
		})
	}

	// function to change loading spinner status
	private toggleLoader = (isLoading: boolean = true) => this.layoutService.toggleLoader(isLoading)
}