import moment from "moment";
import { ADefectDataObject, LocationDetailSearchModel } from "../../dashboard.viewModel";

export class ADefectViewModel {
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // start and end dates are defined of A Defect Count
    // start date is equal to -14 days from todays date
    aDefectStartDate = moment().subtract(14, 'days').format('YYYY-MM-DD')
    // end date of A defect that is todays date 
    aDefectEndDate = moment().format('YYYY-MM-DD')
    // the difference between start and end dates of ADefect which by default is 14 days 
    differenceBetweenDates: number = 14
    // The selected locations from filter A-defect filter
    aDefectSelectedLocations: any[] = [];
    // used to display names of selected Adefect locations in template
    aDefectSelectedLocationsNames: any[];
    // if all are selected in filter then show All otherwise show names of selected locations
    isAllADefectSelected: boolean = true
    //  two way binding to retian value in a defect filter
    showPrevious = '14 days'
    //   start and end dates of a defect to be retained in a defect filter
    aDefectStartDatePie = moment().subtract(14, 'days')
    aDefectEndDatePie = moment()
    // location names to be rendered on template
    aDefectLocationNames;
    // to store selected a defect locations and render in template
    aDefectLocationNamesAndCount: string;
    // object to have information of ADefects, being used to draw chart
    aDefectDataObj: ADefectDataObject = new ADefectDataObject()
    // object to have total count of AI, AE and AT defects to show in list below the widget
    aDefectListObject = {
        AIDefectCount: 0,
        AEDefectCount: 0,
        ATDefectCount: 0
    }
    //property to hold previous page url
    previousPageUrl: string = ""
    // property to contains the list of location id and name which is having AI defect code
    locationsIdsOfAIDefects: LocationDetailSearchModel[] = []
    // property to contains the list of location id and name which is having AT defect code
    locationsIdsOfATDefects: LocationDetailSearchModel[] = []
    // property to contains the list of location id and name which is having AE defect code
    locationsIdsOfAEDefects: LocationDetailSearchModel[] = []
    // to adjust locations in one line, window width is being used i.e. in locations filter
    innerWidthofScreen: number;
    // bool to find if pie-chart-container is expanded or not. By default its false
    isPieChartContainerExpanded: boolean = false;
    // property to know the status of disablity of date range fields
    isDateRangeDisabled: boolean = true;
    // export csv file name
    exportFileName: string = "exportAdefectCodeItems";
    //further option to be included in exported file, we are using header option.
    options = {
        headers: ["Location", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
            "Defect code", "Next due", "Sub location", "Detailed location", "Item status"],
    };
    // property holding the static info text
    infoPopupMessage: string = "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    // height of popover
    popoverHeight: string = "250px !important"
    // width of popover
    popoverWidth: string = "150px !important"
    // defect code property
    defectCode: string = "A";
    // id for selected locations in filter
    aDefectSelectedLocationsIds
}