import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SortEvent, NxSortHeaderIntl } from '@aposin/ng-aquila/table';
import { CommonService } from 'src/app/services/common.service';
import { SortHeaderIntl } from 'src/app/shared/sortHeaderIntl';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { LocationViewModel } from './locations.viewModel';
import { SearchModels } from 'src/app/schemas/searchModel';
import { shareReplay } from 'rxjs/operators';
import { LocationService } from 'src/app/services/graphql/location.service';
import { BannerDetail } from 'src/app/shared/enums/banner';
import { PerPageModel } from 'src/app/shared/models/perPage.Model';
import { LayoutService } from 'src/app/services/layout-service';
import { NxDialogService } from '@aposin/ng-aquila/modal';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { BreadcrumbEnums } from 'src/app/shared/enums/breadcrumbs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  providers: [
    { provide: NxSortHeaderIntl, useClass: SortHeaderIntl },
  ]
})

// location component
export class LocationsComponent implements OnInit {
  // search model to store fields of search params
  queryParamList: SearchModels = new SearchModels();
  // search model to store fields of search params
  queryParamContactList: SearchModels = new SearchModels();
  // item search model instance for model binding
  locationViewModel: LocationViewModel = new LocationViewModel();
  //Model template to location contact
  @ViewChild('locationContactsTemplate') locationContactsTemplate!: TemplateRef<any>;
  // contains the list of all location Ids of current assigned Item Groups
  assignedItemGroupsLocationIds: any[] = []

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private common: CommonService, private layoutService: LayoutService,
    private locationService: LocationService,
    private dialogService: NxDialogService,
    private itemGroupService: ItemGroupService) {
    // getting current navigation object from router
    this.locationViewModel.currentNavigationObject = this.common.getRouterCurrentNavigationObject();
  }

  async ngOnInit() {

    //to set breadcrums on refresh
    this.common.updateBreadcrumbLabel(this.activatedRoute.snapshot, BreadcrumbEnums.location);
    // subscribe to query params changes
    this.activatedRoute.queryParams.subscribe(params => {
      // get params from url
      this.getQueryParams(params);
      // prepare search list accordingly
      this.prepareSearchParamList();
      // fetch locations based on assingned item group
      this.getlocationForAssignedItemGroup();
    });
    // double check access token, and set if available
    this.getlocationForAssignedItemGroup();
    // subscribe to device type detection observable
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result => this.locationViewModel.isDesktop = result);
    this.setBannerText();
  }
  //get location based on selected item groups

  getlocationForAssignedItemGroup() {
    this.assignedItemGroupsLocationIds = []
    this.layoutService.currentAssignedItemGroups.pipe(untilDestroyed(this)).subscribe(itemGroupList => {
      if (itemGroupList && itemGroupList.length > 0) {
        // get the location Ids list of assigned item groups
        this.assignedItemGroupsLocationIds = this.itemGroupService.getLocationIdsAssignedItemGroups();
        // double check access token, and set if available
        this.fetchLocations();

      }
    })
  }
  // PURPOSE: retrieve the saved filters from service
  // 1. check if last page was of items, then look for any filters saved in service
  // 2. map each filter value to view model field
  fetchLocationsFiltersModel = () => {
    // retrieve last url from navigation object
    if (this.locationViewModel.currentNavigationObject.previousNavigation)
      this.locationViewModel.previousUrl = this.locationViewModel.currentNavigationObject.previousNavigation.finalUrl.toString();
    if (this.locationViewModel.previousUrl != "" && this.locationViewModel.previousUrl.indexOf("/locations/") != -1 && this.locationViewModel.previousUrl.indexOf("/items") != -1) {
      this.assignFilterValues();
    }
  }
  // assign saved filter values to view model fields
  assignFilterValues = (): void => {
    const locationFilters = this.locationService.fetchLocationsFilters()
    if (locationFilters) {
      this.locationViewModel.page = locationFilters.pageNo ? locationFilters.pageNo : this.locationViewModel.page
      this.locationViewModel.sortDirection = locationFilters.sortDirection ? locationFilters.sortDirection : this.locationViewModel.sortDirection
      this.locationViewModel.sortBy = locationFilters.sortBy ? locationFilters.sortBy : this.locationViewModel.sortBy
      this.locationViewModel.locationName = locationFilters.locationName ? locationFilters.locationName : this.locationViewModel.locationName
      this.locationViewModel.locationAddress = locationFilters.locationAddress ? locationFilters.locationAddress : this.locationViewModel.locationAddress
      this.locationViewModel.locationPostcode = locationFilters.locationPostcode ? locationFilters.locationPostcode : this.locationViewModel.locationPostcode

    }
  }


  // get locations from graphql service
  async fetchLocations() {
    // prepare param for location list
    this.common.addDefaultSearchParam(this.queryParamList, this.locationViewModel.locationIdKey, this.common.toTextMulti(this.assignedItemGroupsLocationIds));
    // call to location service
    const apiResponse = await this.locationService.getLocations(this.queryParamList.searchList, this.locationViewModel.sortDirection ? this.locationViewModel.sortDirection.toUpperCase() : "ASC", this.locationViewModel.sortBy ? this.locationViewModel.sortBy : "_id", this.locationViewModel.page - 1, this.locationViewModel.perPage);
    if (apiResponse && apiResponse.locationDetails) {
      // setting viewmodel from response
      this.locationViewModel.locations = apiResponse.locationDetails;
      this.locationViewModel.resultCount = apiResponse.totalCount;
    }
    ;
  }

  // get location surveyor contacts
  fetchLocationSurveyorContacts(locationId: string) {
    // make the list empty
    this.locationViewModel.locationSurveyorContacts = []
    // prepare search params by passing locationId as value and true only in case of surveyor contacts call
    this.prepareSearchParamList(true, locationId)
    // call location service method
    this.locationService.getLocationSurveyorContacts(this.queryParamContactList.searchList, "ASC", "_id", 0, 100).pipe(shareReplay(), untilDestroyed(this)).subscribe(result => {
      if (result && result.data && result.data.search && result.data.search.results.length > 0) {
        this.locationViewModel.locationSurveyorContacts = result.data.search.results[0].surveyorContacts;
        this.locationViewModel.locationSurveyorContacts.sort((a, b) => {
          return this.common.compare(a.discipline.description, b.discipline.description, "asc");
        });
      }
    })
  }

  // clear search form, reset page and reload data from api
  clearSearch() {
    // clear search viewmodel
    this.locationViewModel.clear();
    // clear query params
    this.queryParamList.clear(0)
    // set page to 1
    this.locationViewModel.page = 1;
    // update the route
    this.router.navigate([`${AppRoutes.locations}`], { queryParams: { pageNo: this.locationViewModel.page, sortDirection: this.locationViewModel.sortDirection, sortBy: this.locationViewModel.sortBy } });
  }

  // used with pagination control on view
  loadPage() {
    this.queryParamList.clear(0);
    // navaigate with query params
    this.router.navigate([`${AppRoutes.locations}`], {
      queryParams: {
        pageNo: this.locationViewModel.page, sortDirection: this.locationViewModel.sortDirection,
        sortBy: this.locationViewModel.sortBy,
        locationName: this.locationViewModel.locationName,
        locationAddress: this.locationViewModel.locationAddress,
        locationPostcode: this.locationViewModel.locationPostcode,
        perPage: this.locationViewModel.perPage
      }
    })
  }

  // perform search, display result list and update routes 
  search() {
    // prepare query params to send to api
    this.prepareSearchParamList();
    // page should be set to first
    this.locationViewModel.page = 1;
    // navigate with required query params
    this.router.navigate([`${AppRoutes.locations}`], {
      queryParams: {
        pageNo: this.locationViewModel.page,
        sortDirection: this.locationViewModel.sortDirection,
        sortBy: this.locationViewModel.sortBy,
        locationName: this.locationViewModel.locationName,
        locationAddress: this.locationViewModel.locationAddress,
        locationPostcode: this.locationViewModel.locationPostcode,
        perPage: this.locationViewModel.perPage
      }
    })
  }

  // Sort grid by specific attribute
  // handles both local sorting and 
  // get list storted from api
  sortTable(sort: SortEvent) {
    // if returned results are less than 11, we can apply local sorting
    if (this.locationViewModel.resultCount <= 10) {
      this.sortLocally(sort);
    }
    else {
      // clear any previous search list as we prepare this again in onInit
      this.queryParamList.clear(0);
      // otherwise will call api with sort params 
      this.locationViewModel.sortBy = sort.active;
      this.locationViewModel.sortDirection = sort.direction;
      this.router.navigate([`${AppRoutes.locations}`], {
        queryParams: {
          pageNo: this.locationViewModel.page, sortDirection: this.locationViewModel.sortDirection, sortBy: this.locationViewModel.sortBy,
          locationName: this.locationViewModel.locationName,
          locationAddress: this.locationViewModel.locationAddress,
          locationPostcode: this.locationViewModel.locationPostcode
        }
      })
    }
  }

  // navigate to a specific location's items
  // by locationId
  navigateToItems(locationId: string) {
    // set values of filters to service for later use before navigation 
    this.locationService.setLocationsFilters(this.locationViewModel.page, this.locationViewModel.sortBy, this.locationViewModel.sortDirection, this.locationViewModel.locationName, this.locationViewModel.locationAddress, this.locationViewModel.locationPostcode);
    // navigate to items of a location and also sory by last inspected date by descending by default
    this.router.navigateByUrl(AppRoutes.locationItems(locationId) + "?sortDirection=desc&sortBy=inspectionsInspectionDate,currentReportId")
  }

  // navigate to specific page and load its data
  // configured with pagination control
  goToPage(pageNumber: number) {
    this.locationViewModel.page = pageNumber;
    this.loadPage();
  }

  // navigate to previous page
  prevPage() {
    this.locationViewModel.page--;
    this.loadPage();
  }

  // navigate to next page
  nextPage() {
    this.locationViewModel.page++;
    this.loadPage();
  }

  // get query params and map to our local variables
  getQueryParams(params: any): void {
    // check if object have any keys
    // if yes, means we have to pick data from query string
    if (params && Object.keys(params).length > 0) {
      this.locationViewModel.page = this.common.IsNullORUndefined(params, "pageNo", 1);
      this.locationViewModel.sortBy = this.common.IsNullORUndefString(params, "sortBy");
      this.locationViewModel.sortDirection = this.common.IsNullORUndefString(params, "sortDirection");
      this.locationViewModel.locationName = this.common.IsNullORUndefString(params, this.locationViewModel.locationNameKey);
      this.locationViewModel.locationAddress = this.common.IsNullORUndefString(params, this.locationViewModel.locationAddressKey);
      this.locationViewModel.locationPostcode = this.common.IsNullORUndefString(params, this.locationViewModel.locationPostcodeKey);
      this.locationViewModel.perPage = this.common.IsNullORUndefined(params, "perPage", 10);
    }
    // if no, lets look for any values in filter service
    else {
      this.fetchLocationsFiltersModel();
    }
  }
  // set multi fields search values
  prepareSearchParamList(isContactCall: boolean = false, locationId: string = ''): void {
    // if isContactCall is true search field will be locationId and value will be the location id 
    if (isContactCall) {
      this.common.updateSearchParamList(this.queryParamContactList, "locationId", `${locationId}`);
    }
    else {
      this.common.updateSearchParamList(this.queryParamList, this.locationViewModel.locationPostcodeKey, `${this.locationViewModel.locationPostcode}`);
      this.common.updateSearchParamList(this.queryParamList, this.locationViewModel.locationNameKey, `${this.locationViewModel.locationName}`);
      this.common.updateSearchParamList(this.queryParamList, this.locationViewModel.locationAddressKey, `${this.locationViewModel.locationAddress}`);
    }
  }

  // sort locations list on client side
  sortLocally(sort: SortEvent): void {
    this.locationViewModel.locations.sort((a, b) => {
      switch (sort.active) {
        case 'name': return this.common.compare(a.name, b.name, sort.direction);
        case 'postalAddressPostCode': return this.common.compare(a.postalAddress?.postcode, b.postalAddress?.postcode, sort.direction);
        case 'postalAddress': return this.common.compare(a.postalAddress?.address1, b.postalAddress?.address1, sort.direction);
        default: return 0;
      }
    });
  }

  // toggle number of records per page by clicking 
  public togglePerPage(selectedPerPage: PerPageModel): void {
    // clicked option's value is set as per page option
    this.locationViewModel.perPage = selectedPerPage.value;
    // page to load is set to 1 i.e. load first page
    this.locationViewModel.page = 1;
    this.loadPage();
  }

  //open model for location contact
  openLocationContactModel(locationName: string, locationId: string) {
    this.displayLocationInfo(locationName)
    this.dialogService.open(this.locationContactsTemplate, { ariaLabel: 'show location contacts', showCloseIcon: true, height: '500px' });
    // fetch surveyor contacts details
    this.fetchLocationSurveyorContacts(locationId)
    // fetch site contacts details
    this.fetchLocationSiteContacts(locationId)
  }
  //fetch selected location name upon click on location icon
  displayLocationInfo = (locationName: string) => this.locationViewModel.selectedLocationName = locationName;

  // set banner text based on conditions
  setBannerText(): void {
    this.layoutService.setBanner(BannerDetail.locationBannerText);
    this.layoutService.setSubBanner(BannerDetail.locationBannerSubtitleText);
  }
  // get location site contacts
  fetchLocationSiteContacts(locationId: string) {

    // prepare search params by passing locationId as value and true only in case of site contacts call
    this.prepareSearchParamList(true, locationId)
    // call location service method
    this.locationService.getLocationSiteContacts(this.queryParamContactList.searchList, "ASC", "_id", 0, 100).pipe(shareReplay(), untilDestroyed(this)).subscribe(result => {
      if (result && result.data && result.data.search && result.data.search.results.length > 0) {
        this.locationViewModel.locationSiteContacts = result.data.search.results[0].clientContacts;
        this.locationViewModel.locationSiteContacts.sort(
          (a, b) => {
            if (a.discipline.description.toLowerCase() === b.discipline.description.toLowerCase()) {
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            } else {
              return a.discipline.description < b.discipline.description ? -1 : 1
            }
          });
      }
    })
  }
}

