import { Pipe, PipeTransform } from '@angular/core';
import { Item } from 'src/app/schemas/location-items';
@Pipe({
    name: 'ellipsisPipe'
})
// pipe to trim and append ... at the start of properties
export class EllipsisPipe implements PipeTransform {
    transform(value: Item[]): Item[] {
        value.forEach(element => {
            if (element.serialNumber?.length > 15) {
                element.toolTipSerialNo = element.serialNumber;
                element.serialNumber = `...${element.serialNumber.slice(length - 15)}`
            }
            //we have this field which is less than 15
            element.reportClientPlantNumber = element.clientPlantNumber;
            if (element.clientPlantNumber && element.clientPlantNumber.length > 15) {
                element.toolTipPlantNo = element.clientPlantNumber;
                element.clientPlantNumber = `...${element.clientPlantNumber.slice(length - 15)}`;

            }
            if (element.location) {
                if (element.location.subCodeDescription && element.location.subCodeDescription.length > 15) {
                    element.toolTipSubLocation = element.location.subCodeDescription;
                    element.location.subCodeDescription = `${element.location.subCodeDescription.slice(0, 15)}...`
                }
                if (element.location.description && element.location.description.length > 15) {
                    element.toolTipDetailedLocation = element.location.description;
                    element.location.description = `${element.location.description.slice(0, 15)}...`
                }
            }
            //report id 
            if (element.latestReport && element.latestReport.id != "") {

                //show tooltip and ellipsis if report id length is greater than 6
                element.reportID = element.latestReport.id;
                if (element.latestReport.id?.length > 6) {
                    element.toolTipReportId = element.latestReport.id;
                    element.latestReport.id = `...${element.latestReport.id.slice(6, 12)}`

                }
            }

        });
        return value;
    }
}