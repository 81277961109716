<header nx-header *ngIf="isDesktop">
  <nx-header-row>
    <nx-header-brand class="headerMargin">
      <nx-link>
        <a href="#">
          <figure nxFigure>
            <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;" width="122px;">
          </figure>
        </a>
      </nx-link>
      <nx-header-app-title>Inspection Hub</nx-header-app-title>
    </nx-header-brand>
    <nx-header-actions class="headerMargin">
      <a nxButton="secondary small" type="button" (click)="login()">Login</a>
    </nx-header-actions>
  </nx-header-row>
</header>

<!-- If device is mobile then show this menu  -->
<header nx-header *ngIf="!isDesktop" class="marginMobile">
  <nx-header-brand class="headerMargin">
    <nx-link>
      <a href="#">
        <figure nxFigure>
          <img src="assets/logos/allianz-logo.svg" alt="Brand Logo" role="none" height="31px;" width="122px;">
        </figure>
      </a>
    </nx-link>
  </nx-header-brand>
  <nav>
    <button nxPlainButton (click)="this.isMenuVisible = !this.isMenuVisible" aria-label="open menu" class="expand-btn">
      <nx-icon name="bars" *ngIf="!isMenuVisible; else showCross"></nx-icon>
    </button>
  </nav>
</header>
<!-- Template for showing cross icon in mobile menu  -->
<ng-template #showCross>
  <nx-icon name="close"></nx-icon>
</ng-template>
<!-- Menu -->
<div class="showMenu menuAlignment" [style.height]="isMenuVisible ? this.mobileMenuHeight : '0px'">
  <ul>
    <a (click)="logout()" *ngIf="this.isAuthenticated && !this.hasInternalAdminUserRole">Log out</a>
    <a (click)="login()" *ngIf="!this.isAuthenticated">Log in</a>
  </ul>
</div>