import { SearchModels } from "src/app/schemas/searchModel";

export class HeaderViewModel {
    // Boolean field for showing menu state
    isMenuVisible = false;
    // Current loggedin user reference
    hasClientAdminRole: boolean = false;
    // contains the status of user role
    hasClientUserRole: boolean = false;
    // contains the status of user role of internal admin
    hasInternalAdminUserRole: boolean = false;
    // Set mobile menue height based on additional links additions
    mobileMenuHeight: string = 'auto'
    // Binding of params from query string
    queryItemParamList: SearchModels = new SearchModels();
    // check user is authenticated or not
    isAuthenticated: boolean = true;
    // Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    // user intitials for display on avatar
    userIntials: string = ""
    // hide show profile drop down menu 
    showSubMenu: boolean = false;
    // variable to hold the list of assigned item groups
    itemGroupList: any[] = []
    // variable to the names of item groups to show on tooltip
    listOfItemGroupNames: string
    // show popup boolean
    showPopup: boolean = false
    // show organisation model boolean
    showOrganisationModel: boolean = false
    // variable to hold the list of all item groups
    itemGroupListComplete: any;
    // assigned item groups list
    itemGroupAssignedList: any
    // organisation object
    organisationObject: any
    // user management/register user cog
    userSubMenu: boolean = false;
    // user management selection icon
    cogWheelIcon: string = 'product-cog-wheel'
    // my team/allianz dropdown variable
    myTeamAllianzSubMenu: boolean = false;

}