import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { RoleGuard } from 'src/app/services/auth-service/role.guard';
import { Role } from 'src/app/shared/enums/role';
import { CalendarComponent } from './calendar.component';

//Route for calendar
const routes: Routes = [{
  path: '', component: CalendarComponent, canActivate: [AuthGuard, RoleGuard], data: {
    breadcrumb: '', expectedRoles: [Role.clientAdmin, Role.standardClientUser]
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarRoutingModule { }
