import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NdbxIconModule } from '@allianz/ngx-ndbx/icon'
import { NxFooterModule } from '@aposin/ng-aquila/footer';
import { RouterModule } from '@angular/router';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxExpertModule } from '@aposin/ng-aquila/config';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxPaginationModule } from '@aposin/ng-aquila/pagination';
import { NxSpinnerModule } from '@aposin/ng-aquila/spinner';
import { AuthGuard } from '../services/auth-service/auth.guard';
import { LoadingComponent } from '../_layout/loading/loading.component';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { BannerComponent } from '../_layout/banner/banner.component';
import { NxBreadcrumbModule } from '@aposin/ng-aquila/breadcrumb';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { RecordPerpageToggleComponent } from '../components/Common/record-per-page-toggle/record-pre-page-toggle.component';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxRadioToggleModule } from '@aposin/ng-aquila/radio-toggle';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { TagsModal } from '../components/Common/tags-modal/tags-modal.component';
import { GoogleMapComponent } from '../components/Common/google-map/google-map-components';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxNotificationPanelModule } from '@aposin/ng-aquila/notification-panel';
import { NxTabsModule } from '@aposin/ng-aquila/tabs';
import { NxProgressStepperModule } from '@aposin/ng-aquila/progress-stepper';
import { NxDatefieldModule, NxNativeDateModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { ErrorBannerComponent } from '../_layout/errorBanner/errorBanner.component';
import { PipesModule } from './pipes/pipes.module';
import { InfoIconComponent } from '../components/Common/info-icon/info-icon.component';
import { ItemDetailsComponent } from '../components/item-details/item-details.component';
import { NxAvatarModule } from '@aposin/ng-aquila/avatar';
import { ItemGroupModal } from '../components/Common/item-group-modal/item-group-modal';
import { NxAutocompleteModule } from '@aposin/ng-aquila/autocomplete';
import { OrganizationModal } from '../components/Common/organisation-modal/organization-modal.component';
import { ItemGroupPageComponent } from '../_layout/itemGroup/item-group.component';
import { NxSwitcherModule } from '@aposin/ng-aquila/switcher';
@NgModule({
  declarations: [
    LoadingComponent,
    BannerComponent,
    RecordPerpageToggleComponent,
    TagsModal,
    ItemGroupModal,
    ItemGroupPageComponent,
    OrganizationModal,
    GoogleMapComponent,
    ErrorBannerComponent,
    InfoIconComponent,
    ItemDetailsComponent],
  imports: [
    CommonModule,
    NxExpertModule,
    NxGridModule,
    NxInputModule,
    NxButtonModule,
    NxHeaderModule,
    NxLinkModule,
    RouterModule,
    NxFooterModule,
    NxTableModule,
    NdbxIconModule,
    NxIconModule,
    NxTooltipModule,
    ReactiveFormsModule,
    NxAccordionModule,
    NxPaginationModule,
    NxSpinnerModule,
    NxMessageModule,
    NxBreadcrumbModule,
    NxDropdownModule,
    NxCheckboxModule,
    NxModalModule,
    NxRadioToggleModule,
    NxRadioModule,
    NgxChartsModule,
    NxBadgeModule,
    NxNotificationPanelModule,
    NxTabsModule,
    NxProgressStepperModule,
    NxDatefieldModule,
    PipesModule,
    NxPopoverModule,
    NxAvatarModule,
    NxAutocompleteModule,
    FormsModule,
    NxSwitcherModule
  ],
  exports: [
    NxExpertModule,
    NxGridModule,
    NxInputModule,
    NxButtonModule,
    NxHeaderModule,
    NxLinkModule,
    NxFooterModule,
    NxTableModule,
    CommonModule,
    LoadingComponent,
    BannerComponent,
    NdbxIconModule,
    NxIconModule,
    NxTooltipModule,
    ReactiveFormsModule,
    NxAccordionModule,
    NxPaginationModule,
    NxSpinnerModule,
    NxMessageModule,
    NxBreadcrumbModule,
    NxDropdownModule,
    NxCheckboxModule,
    RecordPerpageToggleComponent,
    TagsModal,
    ItemGroupModal,
    ItemGroupPageComponent,
    OrganizationModal,
    GoogleMapComponent,
    NxModalModule,
    NxRadioToggleModule,
    NxRadioModule,
    NxPopoverModule,
    NgxChartsModule,
    NxBadgeModule,
    NxNotificationPanelModule,
    NxTabsModule,
    NxProgressStepperModule,
    NxDatefieldModule,
    NxNativeDateModule,
    NxMomentDateModule,
    ErrorBannerComponent,
    PipesModule,
    NxPopoverModule,
    InfoIconComponent,
    ItemDetailsComponent,
    NxAvatarModule,
    NxAutocompleteModule,
    FormsModule,
    NxSwitcherModule
  ],

  providers: [AuthGuard]
})
export class SharedModule { }
