import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NxDialogService } from '@aposin/ng-aquila/modal';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { take } from 'rxjs/operators';
import { AuthService } from './services/auth-service/auth0client.service';
import { CookieStorageService } from './services/cookie-storage.service';
import { ErrorLogService } from './services/errors/errorService';
import { IdleDetectionService } from './services/idle-detection.service';
import { LayoutService } from './services/layout-service';
import { Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { AppRoutes } from './shared/appRoutes';
import { AppInitService } from './services/app-init.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
// main app component
export class AppComponent implements OnInit {
  // default app title
  title = 'Allianz Portal';
  //to set idle time(it will be in second)
  // total time( 29 min) = total min(29) * 60 second=1740
  idleTime: number = 1740
  //timeout(in second) (time span in which we show popup to alert user with (logout or stay logged in) options)
  //if you user would not select either , he will be logout automatically
  timeOut: number = 60
  // to show logout counter 
  idleStateTimer: number = 0;
  //to handle the idle states within interval
  timedOut = false;
  //model to alert user for inactivity
  @ViewChild('template') templateRef!: TemplateRef<any>;
  // modal for max activity 
  @ViewChild('maxActivityModaltemplate') maxActivityModaltemplate!: TemplateRef<any>;
  // check if max active time has reached
  maxActivityModalVisible = false;
  // countdown for max activity modal once it is opened
  maxActivityCountdown: number;
  // max active modal countdown expiry in seconds
  maxActivityCountdownExpiry: number = 1 / 60;
  // timer for checking if max active time has reached
  modalTimer: ReturnType<typeof setTimeout>;
  // cookie value for logSess i.e. max active time
  maxActiveCookieValue: string
  // show max active modal after time has reached
  // 7 hours 59 minutes = 419 minutes
  // in ms 419 * 60 * 1000
  maxActivityTime: number = 419 * 60 * 1000;
  // max active modal expiry
  maxActiveModalExpire: number = 8
  //currentUrl
  currentURL: string = ""
  constructor(private layoutService: LayoutService, private authService: AuthService,
    private logService: ErrorLogService, private idle: Idle,
    private readonly dialogService: NxDialogService, private idleDetectionService: IdleDetectionService,
    private cookieService: CookieStorageService, private router: Router,
    private common: CommonService, private appInitService: AppInitService) {
    // Check if the current URL does not contain '/callback?code='
    if (this.currentURL.indexOf('/callback?code=') < 0) {
      // Check if the authentication single token aTkn is not present
      if (!this.authService.aTkn) {
        // Subscribe to getIdTokens observable
        this.authService.getIdToken$.subscribe((Tkn) => {
          // Subscribe to getAccessTokens observable
          this.authService.getAccessToken$.subscribe((aTkn) => {
            // Set the value of aTkn to the obtained access token (aTkn)
            this.authService.aTkn = aTkn;
            this.appInitService.setUserRolesAndClients();
          });
        })
      }
    }

    // sets an idle timeout , 
    idle.setIdle(this.idleTime);
    // sets a timeout period of in seconds. after (idle time + timeout) seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeOut);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.reset();
    });
    //when default idle time reached, logout automatically.
    idle.onTimeout.subscribe(() => {
      if (this.idleStateTimer == 1) {
        this.timedOut = true;
        // calling auth0's logout
        this.authService.logout();
      }
    });
    //prompt inactivity when min idle time meet
    idle.onIdleStart.subscribe(() => {
      // open model
      // do not open when max activity modal is opened
      !this.maxActivityModalVisible && this.openFromTemplate();
    });
    //show counter of remaining time before auto log out
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleStateTimer = countdown
    });

    //need to verify that user logged in or not, have to show inactivity model when user is 
    //logged in and hide otherwise
    this.idleDetectionService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

    //on click event
    document.addEventListener('click', () => {
      this.common.checkMaintenanceStatus()
        .then((status) => {
          if (status) {
            void this.router.navigate([AppRoutes.plannedMaintenance]);
          }
        })
        .catch((error) => {
          // Handle error if needed
          this.logService.logError(error);
        });
    });
  }
  //to reset idle time,when user clicks on stay logged in button
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  //model to show user inactivity
  openFromTemplate(): void {
    this.dialogService.open(this.templateRef, {
      ariaLabel: 'Inactivity Model',
      disableClose: true,
    });
  }

  logout() {
    //setting logout status to service, to stop watch
    this.idleDetectionService.setUserLoggedIn(false);
    // calling auth0's logout
    this.authService.logout();
  }

  async ngOnInit() {
    // call this function on init to get screen width
    this.resizeWindow();
    try {
      const userToken = await this.authService.getAccessToken$.pipe(take(1)).toPromise();
      if (userToken) {
        this.authService.aTkn = userToken;
      }
    }
    catch (error) {
      this.logService.logError(error);
      throw error;
    }

    // max active modal 
    // timer is set to continuously checking if max time has reached 
    this.modalTimer = setInterval(() => {
      this.OnLogin()
    }, 1000)

  }

  // Host listener added to detect change in app dimensions
  @HostListener('window:resize', ['$event'])
  // method to pass width to the service, to which components are listening
  resizeWindow() {
    this.layoutService.setDevice(window.innerWidth);
  }

  // on logic check wheter logSess cookie is available or not
  OnLogin() {
    // get logSess cookie value
    this.maxActiveCookieValue = this.cookieService.getCookie('logSess');
    if (this.maxActiveCookieValue) {
      const hasMaxTimeReached = this.cookieService.hasCookieExpired('logSess', this.maxActivityTime)
      // if max time has reached then clear timer and open max active modal
      if (hasMaxTimeReached) {
        clearInterval(this.modalTimer)
        this.showModalAndStartCountdown()
      }
    }

    // if cookie is undefined set one
    else {
      this.cookieService.setCookie('logSess', this.maxActiveModalExpire);
    }
  }

  // show max active modal and start 60 sec timer
  showModalAndStartCountdown() {
    // mark this true to disable inactivity modal
    this.maxActivityModalVisible = true;
    // open modal
    this.dialogService.open(this.maxActivityModaltemplate, {
      ariaLabel: 'Activity Model',
      disableClose: true,
    });

    // get countdown cookie
    const existingCountdownCookie = this.cookieService.getCookie('countdown');
    // if countdown exists then parse it
    if (existingCountdownCookie) {
      this.maxActivityCountdown = parseInt(existingCountdownCookie);
    } else { //else set it to 60 seconds
      this.maxActivityCountdown = 60;
      this.cookieService.setCookie('countdown', this.maxActivityCountdownExpiry, true, this.maxActivityCountdown.toString());
    }

    // set interval to show decreasing value from 60 to 0
    setInterval(() => {
      this.maxActivityCountdown--;
      this.cookieService.setCookie('countdown', this.maxActivityCountdownExpiry, true, this.maxActivityCountdown.toString());
      // once timer reaches 0 close modal and logout
      if (this.maxActivityCountdown <= 0) {
        this.dialogService.closeAll()
        this.authService.logout();
      }
    }, 1000);
  }
}
