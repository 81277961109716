import { Pipe, PipeTransform } from "@angular/core";
import { Item } from "src/app/schemas/location-items";
import { ItemDefectCodeBadgeColor } from "../../enums/item.defect.code.badge.color";

@Pipe({
    name: 'defectBgPipe'
})
// Defect background color pipe
export class DefectBgPipe implements PipeTransform {

    transform(item: Item): string {
        if (item?.reports && item.reports.results.length > 0 && item.reports.results[0].defectCode &&
            item.reports.results[0].defectCode.code) {
            // checking if there are any subdefects, which we sure that would be against
            // A type defect
            if (item.subDefects && item.subDefects.length > 0) {
                // updating defect code to include latest sub-defect with it.
                item.reports.results[0].defectCode.code =
                    item.reports.results[0].defectCode.code + item.subDefects[0];
            }
            switch (item.reports.results[0].defectCode.code) {
                case "A":
                case "AE": return ItemDefectCodeBadgeColor.AEDefectCodeColor;
                case "AI": return ItemDefectCodeBadgeColor.AIDefectCodeColor;
                case "AT": return ItemDefectCodeBadgeColor.ATDefectCodeColor;
                case "B": return ItemDefectCodeBadgeColor.BDefectCodeColor;
                case "P": return ItemDefectCodeBadgeColor.PDefectCodeColor;
                case "C": return ItemDefectCodeBadgeColor.CDefectCodeColor;
                case "N": return ItemDefectCodeBadgeColor.NDefectCodeColor;
                default: return ItemDefectCodeBadgeColor.NoDefectCodeColor;
            }
        }
        // returning default color
        return ItemDefectCodeBadgeColor.NoDefectCodeColor;
    }
}