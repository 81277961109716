import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BannerMessageService } from 'src/app/services/json-read/banner.message.service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Component({
    selector: 'app-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss']
})
// component to show banner on top with yellow background
export class MaintenanceBannerComponent implements OnInit {

    // boolean to show banner on portal
    showBanner: boolean = false;
    // property holding the message to be shown in banner
    maintenanceBannerText: string = ""
    // property to close the banner
    warningBanner: boolean = true;

    // constructor with layout service to subscribe for error messages
    constructor(private localStorage: LocalStorageService, private bannerMessageService: BannerMessageService) { }

    // subscribe to banner text on init
    ngOnInit(): void {
        // reading maintenance message from local storage
        const maintenanceMessageStatus = this.localStorage.getObject(LocalStorageKeys.MaintenanceBannerSetting);
        // if its null then check for message
        if (maintenanceMessageStatus === null) {
            // fetch the details of maintenance banner
            this.getMaintenanceMessage()
        }
    }

    // call to the endpoint to know any need to show maintenance banner on portal
    getMaintenanceMessage() {
        const { message, startDate, endDate, show } = this.bannerMessageService.getMessage();
        // check if need to show the message
        if (show) {
            // current date object to be used for matching the start and end date of banner display
            const todaysDate = moment().startOf('day');
            const validatedSartDate = moment(startDate, "D-MM-YYYY");
            const validatedEndDate = moment(endDate, "D-MM-YYYY");
            this.maintenanceBannerText = message;
            // ------ conditions -------//
            // 1. The message should not be null or empty
            // 2. Start Date can be equal or less than todays date
            // 3. End Date can be equal or greater than todays date
            this.showBanner = (message && (validatedSartDate <= todaysDate) && validatedEndDate >= todaysDate);
        }
    }

    // close the maintenance banner and store this setting in LS
    closeBanner = () => {
        this.warningBanner = false;
        this.localStorage.saveString(LocalStorageKeys.MaintenanceBannerSetting, "false");
    }
}
