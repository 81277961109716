import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout-service';
import { ErrorModel } from 'src/app/shared/models/error.Model';

@Component({
    selector: 'app-error-banner',
    templateUrl: './errorBanner.component.html',
    styleUrls: ['./errorBanner.component.scss']
})
// component to show banner on top with light blue background
export class ErrorBannerComponent implements OnInit {

    // title of the error banner
    bannerTitle: string;
    // body of the error banner
    bannerText: string;
    //body of the error message
    bannerMessage: string;
    // banner error messaging subscription to listen for errors
    bannerErrorSubscription: Subscription;
    // boolean to control the visibility for error banner on UI
    errorBanner: boolean = false;

    // constructor with layout service to subscribe for error messages
    constructor(private layoutService: LayoutService) { }

    // subscribe to banner text on init
    ngOnInit(): void {
        // setting up banner error subscription
        this.bannerErrorSubscription = this.layoutService.bannerError.subscribe((error: ErrorModel) => {
            // making sure error id or title is present
            if (error.errorId || error.title) {
                // setting up the properties in the call
                this.bannerTitle = error.title;
                this.bannerText = error.errorId;
                //display error message for generic error (when no code or error Id)
                this.bannerMessage = error.errorMsg
                // displaying banner
                this.errorBanner = true;
            }
        });
    }
    // unsubscribe to all subscriptions on component destroy 
    ngOnDestroy() {
        this.bannerErrorSubscription.unsubscribe();
    }
}
