import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/graphql/dashboard.service';
import { PnaViewModel } from './pna-viewModel';
import { LayoutService } from 'src/app/services/layout-service';
import { LocationDefectListSearchModel, LocationDetailSearchModel } from '../../dashboard.viewModel';
import { AppRoutes } from 'src/app/shared/appRoutes';
import { CommonService } from 'src/app/services/common.service';
import { LocationItemService } from 'src/app/services/graphql/location-items.service';
import { LocationItemsViewModel } from 'src/app/components/locations/location-items/location-items.viewModel';
import { SearchModels } from 'src/app/schemas/searchModel';
import { ItemGroupService } from 'src/app/services/graphql/item-group.service';
import { ExportService } from 'src/app/services/export.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'pna-count',
  templateUrl: './pna-count.component.html',
  styleUrls: ['./pna-count.component.scss'],
})
export class PnaCountComponent implements OnInit {
  pnaViewModel = new PnaViewModel();
  // list of all locations details which is having p defect 
  pDefectLocationDetails: any = [];
  // search model to store fields of search params
  queryParamList: SearchModels = new SearchModels();
  // search model to store fields of search params
  queryLocationParamList: SearchModels = new SearchModels();
  //location item view model to save api response for export
  locationItemViewModel: LocationItemsViewModel = new LocationItemsViewModel();
  // contains the list of all locations
  @Input() locationList;


  constructor(private dashboardService: DashboardService, private itemGroupService: ItemGroupService,
    private common: CommonService,
    private exportService: ExportService,
    private layoutService: LayoutService, private itemsService: LocationItemService) { }

  ngOnInit(): void {
    // subscribe to device detection observable
    this.layoutService.currentDevice.pipe(untilDestroyed(this)).subscribe(result =>
      this.pnaViewModel.isDesktop = result
    )
  }

  ngOnChanges(): void {

    const itemGroupsList = this.itemGroupService.getSelectedItemGroupsIds();
    if (this.locationList && this.locationList.length > 0 && itemGroupsList && itemGroupsList.length > 0) {
      // clear the list and add now ids in selected list
      this.pnaViewModel.selectedLocationIds.length = 0
      this.locationList.forEach(location => this.pnaViewModel.selectedLocationIds.push(location.id))
      // call function to fetch  defects
      this.getLocationPDefects();
    }
  }
  // set multi fields search values
  prepareSearchParamList(): void {
    const locationIds = this.pnaViewModel.selectedLocationIds.length == 1 && this.pnaViewModel.selectedLocationIds[0] == -1 ? [] : this.pnaViewModel.selectedLocationIds;
    this.common.updateSearchParamList(this.queryParamList, "defectInspectionDate", `[${this.pnaViewModel.pDefectStartDate} TO ${this.pnaViewModel.pDefectEndDate}]`);
    this.common.updateSearchParamList(this.queryParamList, "defectCode", this.pnaViewModel.defectCode);
    this.common.updateSearchParamList(this.queryParamList, "defectItemLocationCode", locationIds.join("|"));
  }

  // method to add the location Ids as search params
  prepareLocationSearchParamList = () => this.common.updateSearchParamList(this.queryLocationParamList, "locationId", this.pDefectLocationDetails.map((loc) => loc.locationId).join('|'));

  // function to get locations a defect count from api
  getLocationPDefects = async (): Promise<void> => {
    this.prepareSearchParamList();
    try {
      const result = await this.dashboardService.getLocationDefects(this.queryParamList.searchList).pipe(take(1)).toPromise();
      // check if we have required objects before accessing
      if (result?.data?.search?.buckets) {
        // store results in local property
        let locationsPDefectCountData = result.data.search.buckets;
        this.pnaViewModel.pDefectCount = 0;
        // loop to find a defect in result set
        locationsPDefectCountData.forEach((location: any) => {
          // get defect types having P type key and calculate its count
          this.getPTypeDefects(location)
        });
      }

    }
    catch (error) {
      // Handle errors here
    }
  }

  // get defect types having P type key and calculate its count
  getPTypeDefects(defect) {
    if (defect?.results) {
      // find p defect in defect object
      // find defect results object in defect object
      const defectCountObj = defect.results;
      // iterate on the array of results
      // outer loop to iterate the defect object based on results property
      for (const defectObj of defectCountObj) {
        // inner loop to iterate the defect object based on buckets property
        for (const defectBucketObject of defectObj.buckets) {
          this.fetchPDefectCount(defectBucketObject.results, defectObj.key)
        }
      }
    }
  }

  // function takes the object and location Id
  fetchPDefectCount = (defectBucketObject: any, locationId: any): void => {
    this.pDefectLocationDetails.length = 0;
    this.pnaViewModel.pDefectCount = 0;
    // iteration on the object of defect buckets
    for (const defectBucketDetails of defectBucketObject) {
      // assign the defect code
      const defectCode: any = defectBucketDetails.key
      // assign the defect value
      const defectCodeValue: any = defectBucketDetails.count
      // if value is 'p'
      if (defectCode == 'p') {
        this.pnaViewModel.pDefectCount += defectCodeValue;
        let locationModel = {} as LocationDetailSearchModel;
        locationModel.locationId = locationId; // id of location
        this.pDefectLocationDetails.push(locationModel);
      }

    }
  }

  /* PNA Defects navigation to Items work */
  viewDefectsItems = () => {
    // when count is greater than 0 only
    if (this.pnaViewModel.pDefectCount > 0) {
      // prepare the filter modal, which needs to be sent in navigation 
      // in state variable
      const defectsFilterObj = this.prepareLocationDefectsFilterSearchModel();
      // navigate with filter object
      void this.common.navigate(AppRoutes.clientLocationsItems, { state: defectsFilterObj })
    }
  }

  // prepare the model to be used in state variable in navigation
  // When we click on View Items on PNA widget
  // we need to pass 4 variables to Items page. 1. Start date, 2. End date, 3. List of selected Locations, 4. P Defect types
  prepareLocationDefectsFilterSearchModel = (): LocationDefectListSearchModel => {
    // containing P defect code
    let selectedDefectCodes: string = 'P';
    // model object having all 4 properties
    let defectsFilterModel = {} as LocationDefectListSearchModel;
    // initialize the list having location details to display in tags
    defectsFilterModel.locationDetailList = [];
    // initialize the list having location details of all selected locations
    defectsFilterModel.allSelectedLocationsList = [];
    // add start date in model property
    defectsFilterModel.endDate = this.pnaViewModel.pDefectStartDate
    // add end date in model property
    defectsFilterModel.startDate = this.pnaViewModel.pDefectEndDate;
    // assign codes to variables
    defectsFilterModel.locationDefectTypes = selectedDefectCodes;
    // fetch properties to be passed to items screen
    defectsFilterModel = this.preparePDefectsLocationList(defectsFilterModel)
    // return the prepared model
    return defectsFilterModel;
  }

  // make a list of properties which are required to pass to items screen
  preparePDefectsLocationList = (defectsFilterModel: LocationDefectListSearchModel): LocationDefectListSearchModel => {
    // map the selected location Ids in variable
    // in case of -1 (default case) , map the P defect Ids, 
    const selectedLocationIds = this.pnaViewModel.selectedLocationIds.length > 0 && this.pnaViewModel.selectedLocationIds[0] != -1 ? this.pnaViewModel.selectedLocationIds : this.locationList.map(loc => loc.id)
    // if selection model is present
    if (this.pDefectLocationDetails && selectedLocationIds && selectedLocationIds.length > 0) {
      // iterate with all the selected rows present in selection model
      for (let selectedRow of selectedLocationIds) {
        const locationDetail = this.pDefectLocationDetails.find(location => location.locationId == selectedRow)

        if (locationDetail) {
          // create object to have properties of location details
          let locationModel = {} as LocationDetailSearchModel;
          locationModel.locationId = locationDetail.locationId; // id of location
          locationModel.locationName = this.locationList.find(al => al.id == locationDetail.locationId).name;  // name of location
          // add details in list to pass to items to show in tag
          defectsFilterModel.locationDetailList.push(locationModel);
        }
      }
    }
    else if (this.pDefectLocationDetails) {
      // iterate with all the selected rows present in selection model
      for (let selectedRow of this.pDefectLocationDetails) {
        // create object to have properties of location details
        let locationModel = {} as LocationDetailSearchModel;
        locationModel.locationId = selectedRow.locationId; // id of location
        locationModel.locationName = selectedRow.locationName; // name of location
        // add details in list to pass to items to show in tag
        defectsFilterModel.locationDetailList.push(locationModel);

      }
    }

    // return the prepare model
    return defectsFilterModel;
  }

  //export PNA defect widget filtered data to csv 
  defectCountExportToCSV = async (): Promise<void> => {
    //Api call to get items detail for PNA widget locations
    const pnaDefectLocationsIds = this.pnaViewModel.selectedLocationIds && this.pnaViewModel.selectedLocationIds[0] != -1 ? this.pnaViewModel.selectedLocationIds : this.locationList.map(loc => loc.id);
    // list of objects to be passed to service method
    const variables = {
      searchList: [],
      order: "ASC",
      sortName: "_id",
      from: 0,
      size: 100,
      dueFrom: this.pnaViewModel.pDefectStartDate,
      dueTo: this.pnaViewModel.pDefectEndDate,
      locationIds: pnaDefectLocationsIds,
      defectTypes: "P",
      itemGroupIds: this.itemGroupService.getSelectedItemGroupsIds()

    }

    try {

      const items = await this.itemsService.getItemsWithDefects(variables, false).pipe(take(1)).toPromise();
      if (items?.data?.itemsWithDefects?.results) {
        this.locationItemViewModel.itemLocations.items = items.data.itemsWithDefects.results;
        for (let i = 1; i < Math.ceil((items.data.itemsWithDefects.totalCount) / 100); i++) {
          // call the promise function to get items from api
          const responseListFromAPI = await from(
            this.itemsService.getItemsWithDefectsByPromise(variables)).toPromise();
          // check if data is returned from api
          if (responseListFromAPI?.data?.itemsWithDefects?.results) {
            // append the new page data to array to get one final array with pages data
            this.locationItemViewModel.itemLocations.items = [... this.locationItemViewModel.itemLocations.items, ...items.data.itemsWithDefects.results]
          }
        }
        // export the content to csv file
        this.exportService.exportToCsv(this.locationItemViewModel.itemLocations.items, this.pnaViewModel.exportFileName, this.pnaViewModel.options, this.locationList, "PDefect")
      }
    }
    catch (error) {
      // Handle errors here
    }
  }

}
