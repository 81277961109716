import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth-service/auth0client.service';
import { LayoutService } from './layout-service';

@Injectable()
export class HttpService {

    constructor(private http: HttpClient, private layoutService: LayoutService,
        private authService: AuthService) { }

    // GET HttpClient Call with endpoint, headers and response Type params
    // Returns an observable of type Object
    get(endpoint: string, responseType: any, authorizationRequired = true, isLoading = true): Observable<Object> {
        // To show loader spinner
        if (isLoading) {
            this.layoutService.toggleLoader(isLoading);
        }

        // creating header for http call
        let headers;
        if (authorizationRequired)
            headers = new HttpHeaders().set('authorization', 'Bearer ' + this.authService.aTkn);
        // Http get call
        return this.http.get(endpoint, { headers, responseType: responseType }).pipe(tap(_x => this.layoutService.toggleLoader(false))).pipe(
            catchError((err) => {
                this.layoutService.toggleLoader(false);
                return throwError(err);
            })
        );
    }
}