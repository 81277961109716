// Error model
export class ErrorModel {
    // title for the error
    title: string;
    // error id
    errorId: string;
    //error message
    errorMsg: string;
    // constructor to initialize object with properties
    public constructor(title: string = "", errorId: string = "", errorMsg: string = "") {
        this.title = title;
        this.errorId = errorId;
        this.errorMsg = errorMsg;

    }
}