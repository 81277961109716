import { Action } from '@ngrx/store'
import { CurrentUser } from '../models/current-user.model'

// set user action type
export const SET_CUERRENTUSER = '[CURRENTUSER] Set'

// set loggedin user action
export class SetUser implements Action {
    readonly type = SET_CUERRENTUSER
    constructor(public payload: CurrentUser) { }
}
// exported all the user actions
export type UserActions = SetUser;