import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
//using this service we are making sure,
//app would not watch idle when a user is logout
@Injectable({
  providedIn: 'root'
})
export class IdleDetectionService {
  //to check user logged in status
  private userLoggedIn = new Subject<boolean>();

  constructor() {
    this.userLoggedIn.next(false);
  }
  //to set user as active, to start his idle time
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }
  //get user logged in status
  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
}