// per page option model
export class PerPageModel {
    // actual value in number
    value: number;
    // label to show on UI
    label: string;

    // constructor to initialize object with properties
    public constructor(value: number, label: string = "") {
        this.value = value;
        this.label = label === "" ? value + "" : label;
    }

    // static function to prepare list of PerPageModel based on total records i.e input number
    public static getPageOptions(totalRecords: number): PerPageModel[] {
        const tempList: PerPageModel[] = [];
        // only include 10 page if total records are greater than 10
        if (totalRecords > 10) {
            tempList.push(new PerPageModel(10));
        }
        // only include 25 page if total records are greater than 25
        if (totalRecords > 25) {
            tempList.push(new PerPageModel(25));
        }
        // only include 50 page if total records are greater than 50
        if (totalRecords > 50) {
            tempList.push(new PerPageModel(50));
        }
        // only include All if total records are less than 100 and greater than 10
        if (totalRecords > 10 && totalRecords <= 100) {
            tempList.push(new PerPageModel(totalRecords, 'All'));
        }
        // only include 100 page if total records are greater than 100
        if (totalRecords > 100) {
            tempList.push(new PerPageModel(100));
        }
        return tempList;
    }
}