import gql from 'graphql-tag';
import { Location } from './locations'
// graphql query to get list of locations without search
export const getCompanyAndLocations = gql`query{
    company{
      name
      financialRegulationNumber
      internationalNumber
      registrationNumber
      identificationNumber
      businessType
      businessForm
      status
      foundingDate
      creationDate
      branches{
        id
        name
      }
    }
}
`;

//query to save new employee
export const createEmployee = gql`mutation createEmployee($title: String!, $firstname : String!, $surname : String!, $telephoneNumber : String, $mobileNumber: String, $emailAddress : String!, $adminItemGroupPermissions : [String], $userItemGroupPermissions : [InputItemGroupPermission], $branchId: String!,
  $eReportingEnabled: Boolean!,
  $eNotificationEnabled: Boolean! ){

createEmployee(input:{
  title: $title,
  firstname: $firstname
  surname: $surname
  telephoneNumber: $telephoneNumber
  mobileNumber: $mobileNumber
  emailAddress: $emailAddress
  adminItemGroupPermissions: $adminItemGroupPermissions
  userItemGroupPermissions: $userItemGroupPermissions
  eReportingEnabled: $eReportingEnabled
  eNotificationEnabled: $eNotificationEnabled
  branchId: $branchId
  role: STANDARD_USER
}){
  id
}
}`;

//query to get all employees
export const getAllemployees = gql`query search($search: [Search]!, $sortBy: [Sort], $from: NonNegativeInt!, $size: NonNegativeInt!){

  search(search: $search, sort: $sortBy, from: $from, size: $size) {
          totalCount
          results{
               ... on Employee{
                  id
                  firstname
                  surname
                  emailAddresses {
                      email
                  }
                  metadata {
                      role
                      adminPermissionItemGroups
                      userPermissionItemGroups{
                          id
                          locationIds
                      }
                      eNotificationEnabled
                      eReportingEnabled
                  }
              }  
          }
      }
  }`;


//query to get employee by id
export const getEmployeeById = gql`query employee($employeeId:String)
{
  employee(employeeId: $employeeId) {
    id
    title
    firstname
    surname
	emailAddresses {
      email
    }
    branch {
        id
    }
  	phoneNumbers{
      phoneNumber
      usage
    }
    metadata{
        role
        adminPermissionItemGroups
        userPermissionItemGroups{
            id
            
            locationIds
        }
        eReportingEnabled
        eNotificationEnabled
  }
}
} `;

//query to get employee by id
export const getCurrentEmployee = gql`query employee($employeeId:String)
{
  employee(employeeId: $employeeId) {
    firstname
    surname
    metadata{
        role
    }
  }
} `;

// Update user role and permissions by user id
export const updateUserPermissions = gql`mutation updateEmployee($id: String!, $userPermissions: UpdateUserPermissions){
  updateEmployee(input:{
      id: $id,
      userPermissions: $userPermissions
      removeDisciplines: []
      addDisciplines:[LC,PP,E,LV,PM]
  }){
      id
  }
  }
  
  
  `

// update or remove  user locations
export const updateEmployee = gql`mutation updateEmployee($id: String!, $adminPermissions: UpdateAdminPermissions, $userPermissions: UpdateUserPermissions){
 
  updateEmployee(input:{
   id: $id,
   adminPermissions: $adminPermissions
   userPermissions: $userPermissions
   removeDisciplines: []
   addDisciplines:[LC,PP,E,LV,PM]
  }){
   id
   metadata{
   adminPermissionItemGroups
   userPermissionItemGroups{
   id
   locationIds
   }
   }
   }
  }`



// update prefernces eNotification and eReporting 
export const updatePreferences = gql`mutation updateProfile($eReportingEnabled: Boolean, $eNotificationEnabled: Boolean){

  updateProfile(input:{
      eNotificationEnabled: $eNotificationEnabled
      eReportingEnabled: $eReportingEnabled
  }){
      id
      metadata{
          role
          adminPermissionItemGroups
          userPermissionItemGroups{
              id
              locationIds
          }
          eReportingEnabled
          eNotificationEnabled
      }
   }
  }
  `

// Update admin user permissions by user id
export const updateAdminPermissions = gql`mutation updateEmployee($id: String!, $adminPermissions: UpdateAdminPermissions, $userPermissions: UpdateUserPermissions, $eReportingEnabled: Boolean, $eNotificationEnabled: Boolean){
 
  updateEmployee(input:{
   id: $id,
   adminPermissions: $adminPermissions
   userPermissions: $userPermissions
   eNotificationEnabled: $eNotificationEnabled
   eReportingEnabled: $eReportingEnabled
   removeDisciplines: []
   addDisciplines:[LC,PP,E,LV,PM]
  }){
   id
   metadata{
   role
   adminPermissionItemGroups
   userPermissionItemGroups{
   id
   locationIds
   }
   eReportingEnabled
   eNotificationEnabled
   }
   }
  }
  `
// User Company model for user registration
export interface UserCompany {
  company: Company
}

// Company model for user company property
export interface Company {
  businessForm: string
  financialRegulationNumber: string
  identificationNumber: string
  internationalNumber: string
  branches: [Location]
  name: string
  registrationNumber: string
  status: string
}

//Employee model to create new employe
export interface Employee {
  id?: string
  title: string
  firstname: string
  surname: string
  telephoneNumber: string
  mobileNumber: string
  emailAddress: string
  branchId: string
  adminItemGroupPermissions: any[],
  userItemGroupPermissions: UserItemGroupPermissions[]
  eReportingEnabled: boolean
  eNotificationEnabled: boolean
  role: string

}
//Users interface for user grid
export interface UserModel {
  id: string
  firstname: string
  surname: string
  name: string
  role: string
  emailAddress: string
  contactNo: string
  mobileNo: string
  branchId: string
  assignedLocations: Location[]
  // UI fields
  selectedCompany: string
  title: string,
  eReportingEnabled: boolean,
  eNotificationsEnabled: boolean

}
// model class to test user of api response
export class UserApiResponseData {
  search: UserApiResponseResultDataResult;
}
// model class to test results property of users in api response
export class UserApiResponseResultDataResult {
  results: UserModel[]
}
//Interface for Users role and item groups permission updation 
export interface UserRole {
  id: string
  role?: string
  adminPermissions?: {
    addPermissions: string[],
    removePermissions?: string[]
  }
  userPermissions?: {
    addPermissions?: string[],
    removePermissions?: string[]
  }
  eReportingEnabled?: boolean
  eNotificationEnabled?: boolean
}
//Interface for user location update
export interface UpdateEmployee {
  id: string,
  userPermissions?: UserPermissions
}
//export for userItemGroupPermissions
export class UserItemGroupPermissions {
  id: string;
  locationIds: any;

}
//new sort functionality
export interface SortEmployeeField {
  field: string,
  order: string
}

//Admin permission interface for update employee object
export interface AdminPermissions {
  addPermissions: [],
  removePermissions: string[]
}
//user permission interface for update employee object
export interface UserPermissions {
  addPermissions: UserItemGroupPermissions[]
  removePermissions: UserItemGroupPermissions[]
}

// model with initialzed values
export class EmployeeModel {
  eReporting: boolean = false
  eNotification: boolean = false
  firstName: string = ""
  lastName: string = ""
}

// preferences eNotification and eReporting
export class PreferencesUpdateModel {
  eReportingEnabled: boolean = false
  eNotificationEnabled: boolean = false
}

// Model to have employee location detail
export interface EmployeeLocation {
  id: string
  name: string
}

// Model to have an array of employee location detail
export class EmployeeLocationList {
  employeeLocations: EmployeeLocation[] = [];
}

// Model to have fields of user name only
export interface ClientUserNameDetails {
  firstName: string,
  surName: string
}
//class to remove item group
export class RemoveItemGroup {
  id: string
  removePermissions: UserItemGroupPermissions[]
}