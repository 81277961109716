import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout-service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

// component to show loading icon on UI
export class LoadingComponent implements OnInit {
  // controls visibility of loading icon
  isLoading: boolean;
  // to subscribe to loader service to change state of isLoading
  subscription: Subscription;
  // constructor with layout service injected
  constructor(private layoutService: LayoutService) {
  }

  ngOnInit(): void {
    // setting subscription to currentLoaderStatus, controlled by layout service
    this.subscription = this.layoutService.currentLoaderStatus.subscribe(loader => this.isLoading = loader)
  }

  // component destroy
  ngOnDestroy() {
    // on destroy, unsubscribe for performance and safety
    this.subscription.unsubscribe();
  }
}
