import { Injectable } from "@angular/core";
import usefulResources from 'src/assets/json-files/eServices-usefulResources.json';
import { ResourceDetails, UseFulResources } from "../../shared/models/useful-resources-static-data-model";

@Injectable({ providedIn: 'root' })

// service to read file data stored in project 
// prepare the list of all separate categories data present in json file
export class UsefulResourcesService {
    // object of use ful resources class
    // it contains further lists of all categories
    useFulResourcesList: UseFulResources = null;
    // pass the useful resources data to controller on its call
    getUsefulResources = (): UseFulResources => {
        // greater than 1 because first row is of headers
        if (usefulResources && !this.useFulResourcesList) {
            // initialize the list
            this.useFulResourcesList = new UseFulResources();
            // iterate to file content and map in model list
            for (let row = 1; row < usefulResources.length; row++) {
                // excel file format of header 
                // B => Title, C => Category , D => Types , E => Links
                // pass data to local method for model preparation
                this.updateRespectiveCategoryModel(usefulResources[row].C.trim(), usefulResources[row].B, usefulResources[row].E, usefulResources[row].D)
            }
            // sort list of categories 
            // On top should be Web links , display content in alpha numeric order
            // then Pdfs , display content in alpha numeric order
            this.orderWithSortCategoryList();

        }
        // return the list
        return this.useFulResourcesList;
    }

    // method to prepare the separate list of categories
    // each category list have its own data
    private updateRespectiveCategoryModel = (category: string, title: string, link: string, type: string): void => {
        // set values to single model which will be push in respected list
        let resourceTypeObj = {} as ResourceDetails;
        resourceTypeObj.title = title;
        resourceTypeObj.type = type;
        resourceTypeObj.url = link;
        resourceTypeObj.category = category;
        // switch to check the category by name
        switch (category) {
            // if category is of inspection hub, push the details in inspection hub list object
            case "Inspection hub": {
                this.useFulResourcesList.inspectionHubResources.push(resourceTypeObj);
                break;
            }
            // if category is of Health, Safety & Quality , push the details in Health, Safety & Quality list object
            case "Health, Safety & Quality": {
                this.useFulResourcesList.healthSafetyResources.push(resourceTypeObj);
                break;
            }
            // if category is of Inspection Services , push the details in Inspection Services list object
            case "Inspection Services": {
                this.useFulResourcesList.inspectionResources.push(resourceTypeObj);
                break;
            }
            // if category is of Insurance, push the details in Insurance list object
            case "Insurance": {
                this.useFulResourcesList.insuranceResources.push(resourceTypeObj);
                break;
            }
            default: break;
        }
    }

    // purpose of this function is to separate each category list
    private orderWithSortCategoryList = (): void => {
        // preparing ordered list of inspection hub resources
        this.useFulResourcesList.inspectionHubResources = this.composeResources(this.useFulResourcesList.inspectionHubResources);
        // preparing ordered list of health safety resources
        this.useFulResourcesList.healthSafetyResources = this.composeResources(this.useFulResourcesList.healthSafetyResources);
        // preparing ordered list of inspection resources
        this.useFulResourcesList.inspectionResources = this.composeResources(this.useFulResourcesList.inspectionResources);
        // preparing ordered list of insurance resources
        this.useFulResourcesList.insuranceResources = this.composeResources(this.useFulResourcesList.insuranceResources);
    }

    // function takes input of an array
    // sort the array alpha numercially by its title field
    sortAlphaNumerically = (array: any): ResourceDetails[] => {
        return array.sort((a: any, b: any) => {
            // sort by title field
            return a.title.localeCompare(b.title.toLowerCase()) || a.title - b.title;
        });
    }

    // compose resources for a single category at a time, takes input array and sorts both weblinks
    // and pdf links alphabanumerically
    private composeResources = (resourceDetailList: ResourceDetails[]): ResourceDetails[] => {
        // check if data is present in e services list
        if (resourceDetailList.length > 0) {
            // filter web link records
            let resourcesWithWebLink = resourceDetailList.filter(resource => resource.type == "Web Link");
            // if records, then sort alpha numbercially
            if (resourcesWithWebLink.length > 0)
                resourcesWithWebLink = this.sortAlphaNumerically(resourcesWithWebLink);
            // filter Pdf records
            let resourcesWithPdfLink = resourceDetailList.filter(resource => resource.type == "PDF");
            // if records, then sort alpha numbercially
            if (resourcesWithPdfLink.length > 0)
                resourcesWithPdfLink = this.sortAlphaNumerically(resourcesWithPdfLink);
            // merge both the sorted array in modal object
            return [...resourcesWithWebLink, ...resourcesWithPdfLink]
        }
    }
}