import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NxCircleToggleModule } from "@aposin/ng-aquila/circle-toggle";
import { AuthGuard } from "src/app/services/auth-service/auth.guard";
import { SharedModule } from "src/app/shared/shared.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { FilterDashboardComponent } from './filter-dashboard/filter-dashboard.component';
import { ADefectWidgetComponent } from "./widgets/a-defects/a-defect.component";
import { UsefulResourcesComponent } from "./widgets/useful-resources/useful-resources.component";
import { AllDefectComponent } from './widgets/all-defect/all-defect.component';
import { PnaCountComponent } from './widgets/pna-count/pna-count.component';
import { NxCardModule } from '@aposin/ng-aquila/card';

// dashboard modules configured
@NgModule({
    declarations: [DashboardComponent, FilterDashboardComponent, UsefulResourcesComponent, ADefectWidgetComponent, AllDefectComponent, PnaCountComponent],
    imports: [SharedModule, FormsModule, DashboardRoutingModule, NxCircleToggleModule, NxCardModule],
    providers: [AuthGuard]
})
// exported dashboard module
export class DashboardModule { }