//---- This file is to store dashboard widgets filters -----//

import { LocationDueOverDue } from "src/app/schemas/location-due-overdue";


// Model to have data of a defect widget
export class ADefectWidgetFiltersModel {
    startDate: string;
    endDate: string;
    locationDetailList: any;
    showPrevious: string
    isDateRangeDisabled: boolean = true;
    // By default the difference between start and end date is 14 days 
    differenceBetweenDates: number = 14
}

// Model to have data of location due/overdue widget
export class LocationDueOverdueWidgetFiltersModel {
    startDate: string;
    endDate: string;
    locationDetailList: any;
    withinNextDays: string;
    switchStatus: boolean;
}

// interface to have basic information of location
export interface LocationDetailSearchModel {
    locationId: string;
    locationName: string;
}

//----------------Data stored for quick display of map once loaded ----------------//
export class MapsLoadedDataStorage {
    // contains the overdue count of overlay of maps widget
    totalOverDueCountMap: string = ""
    // contains the due count of overlay of maps widget
    totalDueCountMap: string = ""
    // contains the list of details of all locations to be shown as pins on map
    allLocationsDataMap: LocationDueOverDue[] = [];
    // contains the item group id, selected at the time of map data storage
    selectedItemGroupId: any[] = []
}