<h3 nxHeadline="subsection-medium" class="align-filter-heading-with-close-icon">A-defect count filters</h3>
<div nxLayout="Grid" class="remove-blank-space-top">
    <div nxRow>
        <div nxCol="6">
            <form>
                <nx-formfield nxLabel="Show Previous">
                    <nx-dropdown name="Show previous"
                        [(ngModel)]="showPrevious" [disabled]="filterDashboardViewModel.aDefectDateRangeSwitcherModel"
                        (selectionChange)="changeDayValue($event)">
                        <nx-dropdown-item nxValue='14 days'></nx-dropdown-item>
                        <nx-dropdown-item nxValue='30 days'></nx-dropdown-item>
                        <nx-dropdown-item nxValue='60 days'></nx-dropdown-item>
                        <nx-dropdown-item nxValue='3 months'></nx-dropdown-item>
                        <nx-dropdown-item nxValue='6 months'></nx-dropdown-item>
                        <nx-dropdown-item nxValue='1 year'></nx-dropdown-item>
                    </nx-dropdown>
                </nx-formfield>
            </form>
        </div>
    </div>
    <div nxRow>
        <div nxCol="12">
            <hr>
        </div>
    </div>
    <div nxRow>
        <div nxCol="12">
            <nx-switcher [checked]="filterDashboardViewModel.aDefectDateRangeSwitcherModel" labelSize="small" class="nx-margin-bottom-s" [(ngModel)]="filterDashboardViewModel.aDefectDateRangeSwitcherModel" name="filterDashboardViewModel.dateRangeSwitcherModel" >Use Date Range</nx-switcher>
        </div>
    </div>
    <div nxRow>
        <div nxCol="6">
            <nx-formfield nxLabel="Start date">
                <input [disabled]="!filterDashboardViewModel.aDefectDateRangeSwitcherModel" nxDatefield nxInput
                    [nxDatepicker]="startDatepicker" [(ngModel)]="startDate" name="startDate"
                    [nxMin]="filterDashboardViewModel.minStartDate" [nxMax]="filterDashboardViewModel.maxStartDate"
                    [nxDisplayFormat]="filterDashboardViewModel.datePickerFormat"
                    [nxParseFormat]="filterDashboardViewModel.datePickerFormat" #formatDatefield="nxDatefield"
                    (change)="disableApplyButton()" />
                <span nxFormfieldHint>DD/MM/YYYY</span>
                <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
            </nx-formfield>
        </div>

        <div nxCol="6">
            <nx-formfield nxLabel="End date">
                <input [disabled]="!filterDashboardViewModel.aDefectDateRangeSwitcherModel" nxDatefield nxInput
                    [nxDatepicker]="endDatepicker" name="endDate" [(ngModel)]="endDate"
                    [nxMin]="filterDashboardViewModel.minEndDate" [nxMax]="filterDashboardViewModel.maxEndDate"
                    [nxDisplayFormat]="filterDashboardViewModel.datePickerFormat"
                    [nxParseFormat]="filterDashboardViewModel.datePickerFormat" (change)="disableApplyButton()"
                     />
                <span nxFormfieldHint>DD/MM/YYYY</span>
                <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
            </nx-formfield>
        </div>
    </div>
    <div nxRow class="nx-margin-top-s">
        <div nxCol="12">
            <nx-formfield *ngIf="locationList.length <= 10" appearance="outline" nxFloatLabel="always"
            nxLabel="Filter locations">
            <nx-multi-select placeholder="Choose locations" name="selectedLocationIds" [(ngModel)]="selectedLocations"
              [options]="locationList" selectValue="id" selectLabel="name">
            </nx-multi-select>
          </nx-formfield>
          <nx-formfield *ngIf="locationList.length > 10" appearance="outline" nxFloatLabel="always">
            <nx-multi-select filter placeholder="Choose locations" name="selectedLocationIds"
              [(ngModel)]="selectedLocations" [options]="locationList" selectValue="id" selectLabel="name">
            </nx-multi-select>
          </nx-formfield>
        </div>
    </div>
</div>

<button [disabled]="filterDashboardViewModel.isApplyFilterButtonDisabled || getSelectedlocationsCount()"
    (click)="closeFilter()" nxButton="primary small" class="center-align-filter-modal-button ">Apply</button>