import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AuthGuard } from "src/app/services/auth-service/auth.guard";
import { SharedModule } from "src/app/shared/shared.module";
import { CalendarRoutingModule } from "./calendar-routing.module";
import { CalendarComponent } from "./calendar.component";

@NgModule({
    declarations: [CalendarComponent],
    imports: [SharedModule, FormsModule, CalendarRoutingModule],
    providers: [AuthGuard]
})

export class CalendarModule {}