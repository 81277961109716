
import * as LoggedInUserActions from '../actions/loggedin-user.actions';
import { LoggedInUser } from '../models/logged-in.user.model';

// Logged-in user object
const initialState: LoggedInUser = {
    employee: {
        id: "",
        title: "",
        firstname: "",
        surname: "",
        telephoneNumber: "",
        mobileNumber: "",
        emailAddress: "",
        branchId: "",
        adminItemGroupPermissions: [],
        userItemGroupPermissions: [],
        eReportingEnabled: false,
        eNotificationEnabled: false,
        role: ""
    }
}
// loggedin user reducer to update the state with provided actions
export function loggedInReducer(state: LoggedInUser = initialState, action: LoggedInUserActions.LoggedInUserActions = {
    type: '[LOGGEDINUSER] Set',
    payload: undefined
}) {
    // to set current logged in user properties after login
    if (action.type == LoggedInUserActions.SET_LOGGEDINUSER) {
        return { ...state, employee: action.payload }
    }
    else {
        // return state as it is
        return state;
    }
}

