import gql from "graphql-tag";

// query to fetch the details of location
export const getDueOverDueLocations = gql`query search($locationIds: [Long]! , $dueFrom: Date!, $dueTo: Date!,$sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: NonNegativeInt!){
    locationsDueAndOverdue(locationIds: $locationIds, dueFrom: $dueFrom, dueTo: $dueTo, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
            totalCount
            results{
                ... on Location{
                    id
                    name
                    postalAddress{
                        address1
                        address2
                        address3
                        postcode
                    }
                    counts{
                        type
                        elements{
                            count
                        }
                    }
                }
            }
        }
    }`;

// map location object into this interface
export interface LocationDueOverDue {
    id: string
    name: string
    postalAddress: {
        address1: string
        address2: string
        address3: string
        address4: string
        address5: string
        address6: string
        postcode: string
    }
    counts: CountArray[]
    dueCount: number
    overdueCount: number,
    checked: boolean
}

// this interface contains the counts of overdue / due items
export interface CountArray {
    type: string
    elements: {
        count: number
    }

}
// an interface for having details of marker
export interface GoogleMapMarker {
    Lat: number
    Long: number
    type: string
    dueOverDueTotalCount: number
    locationId: string
    locationName: string;
    address: string;
    postcode: string;
    dueCount: number;
    overDueCount: number;
}
// list of multiple markers details
export class GoogleMapMarkerDetailsList {
    markersList: GoogleMapMarker[] = [];
}