// class to contains common shared messages
export class SharedMessages {
    // in case of no due/ overdue items, show this message in info window on map
    public static readonly noDueOverDueItemsMessage: string = "Within the specified timeframe there are no due or overdue items to display.";
    // dummy text with more words
    public static readonly longDummyText: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac orci in ipsum aliquam semper. Mauris rutrum laoreet sapien, at tempor nisl posuere nec. Mauris porttitor mi quis arci pharetra bubendum. Lorem ipsum"
    // dummy text with few words
    public static readonly shortDummyText: string = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus dolores quo labore temporibus pariatur qui repellat maiores? Inventore perspiciatis quis, ducimus laudantium!"
    // dummy text being used in user registration and edit screen
    public static readonly userScreenDummyText: string = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus dolores quo labore temporibus pariatur qui repellat maiores"

}