import gql from 'graphql-tag';

// graphql query to get list of searched locations
export const searchLocations = gql`query search($search: [Search]! , $sortBy: [Sort]!,  $from: NonNegativeInt!, $size: Int!, $isAdminQuery:Boolean){

  search(search: $search, sort: $sortBy, from: $from, size: $size, isAdminQuery:$isAdminQuery, operator: OR) {
          totalCount
          results{
              ... on Location{
                  id
                  name
                  postalAddress{
                      address1
                      address2
                      address3
                      address4
                      address5
                      address6
                      postcode
                  }
              }
          }
      }
  }
`;

// graphql query to get list of location ids, names and postcode
export const searchLocationNamesPostcode = gql`query search($search: [Search]! , $sortBy: [Sort]!,  $from: NonNegativeInt!, $size: Int!, $isAdminQuery:Boolean){
  search(search: $search, sort: $sortBy, from: $from, size: $size, isAdminQuery:$isAdminQuery) {
          results{
              ... on Location{
                  id
                  name
                  postalAddress{
                      postcode
                  }
              }
          }
      }
  }
`;
// location object returned by location api as list
export interface Location {
  id: string
  name: string
  completeAddress: string
  postalAddress: {
    address1: string
    address2: string
    address3: string
    address4: string
    address5: string
    address6: string
    postcode: string
  },
  geoLocation: {
    longitude: string
    latitude: string
  }
}
// location api response object, for both without and with search
export class LocationResult {
  resultCount: number;
  search: Location[] = [];
}
// model class to test location propery of api response
export class LocationApiResponseData {
  search: LocationApiResponseResultDataResult;
}
// model class to test results property of locations in api response
export class LocationApiResponseResultDataResult {
  results: Location[]
}