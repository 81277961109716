
import { Item, ItemResult, SearchItems } from 'src/app/schemas/location-items';
import { Personalisation } from 'src/app/schemas/personalisation';
import { SearchModel, SearchModels } from 'src/app/schemas/searchModel';
import { LocationViewModel } from '../locations.viewModel';

//Location search model
export class LocationItemsViewModel {

    // default page size of 10
    perPage: number = 10;
    // total results returned from api
    resultCount: number = 0;
    // default page number set to 1
    page: number = 1;
    // sort direction i-e ASC or DESC
    sortDirection: string = "";
    // sort by field name
    sortBy: string = "";
    // boolean to be set to change the api call
    isSearchCall: boolean = false;
    // list of query params to be sent as filters to items api
    queryItemParamList: SearchModels = new SearchModels();
    // list of query params to be sent as filters to location api
    queryLocationParamList: SearchModels = new SearchModels();
    // viewmodel to handle search fields and button model bindings
    locationViewModel: LocationViewModel = new LocationViewModel();
    // model object to hold the items of locations
    itemLocations: ItemResult = new ItemResult();
    //Responsive view true for desktop , false for mobile
    isDesktop: boolean;
    //user personalisation 
    userPersonalisation: Personalisation = new Personalisation();

    // current navigation object from router
    currentNavigationObject: any;
    // to store value of previous url from router
    previousUrl: string = "";
    //hide show search div by clicking on search icon
    isSearchShow: boolean = false;
    // boolean to know secondary sorting requested or not
    secondarySortingRequested: boolean = false;
    locationDetails: Item[] = []
    // readonly keys
    readonly itemLocationCode: string = "itemLocationCode";
    readonly itemSearchInput: string = "itemSearch";
    readonly exportItemFileName: string = "ExportItems"
    // properties for saving the item code , serial number and plant description
    private _locationItemCode: string = "";
    private _itemSearch: string = "";

    // properties to control visibility of paggination and search ability
    private _searchDisabled: boolean = true;
    private _showPaging: boolean = true;

    // bit to know that api returned result or not 
    apiResultReturned: boolean = false;

    // show hide pagination
    get showPaging(): boolean {
        this._showPaging = this.resultCount > 10;
        return this._showPaging;
    }
    set showPaging(value: boolean) {
        this._showPaging = value;
    }

    // location item code
    get locationItemCode(): string {
        return this._locationItemCode;
    }
    set locationItemCode(value: string) {
        this._locationItemCode = value;
    }

    // item serial number
    get itemSearch(): string {
        return this._itemSearch;
    }
    set itemSearch(value: string) {
        this._itemSearch = value;
    }




    // search item Location button
    get searchDisabled(): boolean {
        // disable the search button if all the fields have less than 3 characters
        this._searchDisabled = this._itemSearch.length < 3;
        return this._searchDisabled;
    }
    set searchDisabled(val: boolean) {
        this._searchDisabled = val;
    }
    // clear the item search fields
    clear(): void {
        this._itemSearch = "";
    }
    //assign the search input values to these fields(single field search for item)
    assignSearchInput(searchInput: string = ""): SearchModels {
        let itemSearch: SearchItems = new SearchItems();
        let searchParamList: SearchModels = new SearchModels();
        //if we have value in input search field, will assign it to each prop
        if (searchInput) {
            for (const item in itemSearch) {
                // add new entry in params list
                let searchItem = {} as SearchModel;
                searchItem.field = item;
                searchItem.value = searchInput.trim();
                searchParamList.searchList.push(searchItem)
            }
        }
        else { // else need to assgin default value
            for (const item in itemSearch) {
                // add new entry in params list
                let searchItem = {} as SearchModel;
                searchItem.field = item;
                searchItem.value = "*";
                searchParamList.searchList.push(searchItem)
            }
        }

        return searchParamList;
    }
}
