import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStates } from '../statemanagement/app.state';
import { CurrentUser } from '../statemanagement/models/current-user.model';
import * as UserActions from 'src/app/statemanagement/actions/user.actions';
import { RoleModel } from "src/app/shared/models/role.Model";
import { take } from 'rxjs/operators';
import { AuthService } from './auth-service/auth0client.service';
import { ErrorLogService } from './errors/errorService';
import { PersonalisationService } from './graphql/personalisation.service';
import { ItemGroupService } from './graphql/item-group.service';
import { LayoutService } from './layout-service';
import { UserService } from './graphql/user.Service';
import { RolesFromApi } from '../shared/enums/role';
import { LocalStorageKeys } from './local-storage/local-storage-keys';


@Injectable()
// service for setting roles and client details in store at app initialization level
export class AppInitService {
    // variable holding the list of roles
    rolesList: RoleModel[] = []
    constructor(private store: Store<AppStates>,
        private layoutService: LayoutService, private authService: AuthService,
        private itemGroupService: ItemGroupService,
        private userService: UserService,
        private errorLogService: ErrorLogService, private personalisationService: PersonalisationService,
    ) {
    }
    // method to set user roles and clients information in store and returns promise
    async setUserRolesAndClients() {
        // with try catch, making sure if any of the following call
        // throws error, then we are not stuck here
        try {
            // this.authService.aTkn = await this.authService.getUserToken();
            // check if token exists
            if (this.authService.aTkn) {
                // call to get details of the logged in user
                await this.getLoggedInUser();
                // get user's personalised settings for components
                this.personalisationService.getComponentsSettings()
            }
        }
        catch (error) { this.errorLogService.logError(error); }
    }


    // get user details and keep in store
    async getLoggedInUser() {
        // API call to get the details
        const userDetails = await this.userService.getEmployeeById("").pipe(take(1)).toPromise()
        // if object exists
        if (userDetails?.data?.employee) {
            // keep in store
            this.rolesList.push({ role: userDetails.data.employee.metadata.role, portals: [] })
            const user: CurrentUser = { roles: this.rolesList, firstName: userDetails.data.employee.firstname ? userDetails.data.employee.firstname : "", lastName: userDetails.data.employee.surname ? userDetails.data.employee.surname : "", eNotification: userDetails.data.employee.metadata.eNotificationEnabled, eReporting: userDetails.data.employee.metadata.eReportingEnabled, itemGroups: userDetails.data.employee.metadata.userPermissionItemGroups };
            // store role details in store
            this.store.dispatch(new UserActions.SetUser(user));
            // get user item groups
            this.getUserItemGroups();
            if (userDetails.data.employee.metadata.role == RolesFromApi.internalAdmin) {
                // set role in local storage
                localStorage.setItem(LocalStorageKeys.Role, userDetails.data.employee.metadata.role)
                // organisation object as an observable
                const organisationLocalstorageObj = localStorage.getItem(LocalStorageKeys.SelectedOrganisation);
                if (organisationLocalstorageObj != null) {
                    this.layoutService.assignOrganisation(JSON.parse(organisationLocalstorageObj));
                }
            }
        }
    }
    // method to get item group details
    async getUserItemGroups() {
        // get item group details from API
        const itemGroups = await this.itemGroupService.getAdminItemGroup().pipe(take(1)).toPromise();
        // store in local storage
        localStorage.setItem(LocalStorageKeys.ItemGroups, JSON.stringify(itemGroups.data.itemGroups))
        // pass the values to observable
        this.layoutService.assignAllItemGroups(itemGroups.data.itemGroups)
    }



}
