import { Pipe, PipeTransform } from '@angular/core';
import { Item, ItemCodeDescription, ItemLatestCurrentReport } from 'src/app/schemas/location-items';

@Pipe({
    name: 'latestCurrentReport'
})
// pipe to find and assign latest current report object
// object have lots of reports rows, find the latest one 
export class LatestCurrentReportPipe implements PipeTransform {
    transform(value: Item[]): Item[] {
        value.forEach(element => {
            // check if record is having reports
            if (element.reports && element.reports.results && element.reports.results.length > 0) {
                // initialize the fields
                element.latestReport = {} as ItemLatestCurrentReport;
                element.latestReport.defectCode = {} as ItemCodeDescription;
                // pick the top one from sorted array
                // map all the properties
                element.latestReport = element.reports.results[0]
            }
        });
        // return the array with updated data
        return value;
    }
}