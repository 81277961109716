import gql from "graphql-tag";
// Get company and address query
export const getCompany = gql`query{
    company{
      name
      registeredAddress{
          buildingNumber
          street
          city
          postCode
      }
    }
  }  `

// Get company name only query
export const getCompanyName = gql`query{
    company{
      name
    }
  }  `

// to get company register address
export interface CompanyRegisterAddress {
    company: {
        name: string,
        registeredAddress: {
            buildingNumber: string,
            street: string,
            city: string
            postCode: string
        }
    }
}
