<app-header></app-header>
<ng-container>
    <div class="nx-margin-top-xl">
        <h1 class="textformatting" [style.font-size]="this.apiViewModel.fontSize">
            Something went wrong, please contact your admin </h1>
        <h2 class="nx-margin-top-xl nx-margin-bottom-xl textformatting">
            Request id: {{apiViewModel.requestId}}
        </h2>
        <h2 class="nx-margin-top-xl nx-margin-bottom-xl textformatting">
            <a class="textColor" routerLink="/">Go to home</a>
        </h2>
    </div>
</ng-container>
<app-footer></app-footer>