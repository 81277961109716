import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { RoleGuard } from 'src/app/services/auth-service/role.guard';
import { Role } from 'src/app/shared/enums/role';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { MainItemComponent } from './main-items.component';

//Route for item
const routes: Routes = [
  {
    path: '', component: MainItemComponent, canActivate: [AuthGuard, RoleGuard], data: {
      breadcrumb: '', expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.organizationUser, Role.internalAdmin]
    }
  },
  {
    path: 'details/:id', component: ItemDetailsComponent, canActivate: [AuthGuard], data: {
      expectedRoles: [Role.clientAdmin, Role.standardClientUser, Role.internalAdmin, Role.organizationUser]
    }
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItemRoutingModule { }