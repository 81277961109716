<app-loading></app-loading>
<!-- Desktop view -->
<div *ngIf="isDesktop" class="nx-margin-x-2xs">
  <!--New Search area-->
  <div *ngIf="locationItemViewModel.isSearchShow">
    <div nxRow>
      <div nxCol="6">
        <nx-formfield nxLabel="Search items:">
          <input placeholder="Please enter at least three characters" id="itemSearch" nxInput name="itemSearch"
            [(ngModel)]="locationItemViewModel.itemSearch" />
        </nx-formfield>
      </div>
      <div class="nx-margin-top-2m" nxCol="6">
        <button class="nx-margin-right-s" id="search" [disabled]="locationItemViewModel.searchDisabled"
          nxTooltip="Search" nxButton="primary small" type="button" (click)="search()">Search</button>
        &nbsp;
        <button (click)="clearSearch()" id="clear" nxButton="secondary small" class="nx-margin-right-s" type="reset"
          type="button">Clear</button>
      </div>
    </div>
  </div>
  <div class="nx-margin-top-s">
    <div nxRow>
      <div class="high-light-count" nxCol="9">
        Matching items: &nbsp;{{locationItemViewModel.resultCount}}
      </div>
      <div nxCol="3" class="align-icons nx-margin-top-xs">
        <nx-icon name="product-search" class="nx-margin-right-xs blueColor" size="s"
          (click)="locationItemViewModel.isSearchShow= ! locationItemViewModel.isSearchShow">
        </nx-icon>
        <nx-icon [ngStyle]="locationItemViewModel.itemLocations.items == 0 && {cursor: 'not-allowed', opacity: '0.6'}"
          name="export-and-share" size="s" nxTooltip="Export"
          (click)="locationItemViewModel.itemLocations.items == 0 ? null : exportMainItemsToCSV()"
          class="blueColor  cursor-pointer"></nx-icon>
      </div>
    </div>

    <div class="tableFixHead nx-margin-top-s">
      <table nxTable nxSort (sortChange)="sortTable($event)">
        <caption></caption>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell class="heading" id="currentReport" nxSortHeaderCell="currentReportId">Current report</th>
            <th nxHeaderCell class="heading" id="serialNo" nxSortHeaderCell="serialNumber">Serial no.</th>
            <th nxHeaderCell id="clientPlantNo" nxSortHeaderCell="clientPlantNumber">Plant no.</th>
            <th nxHeaderCell id="plantDescription" nxSortHeaderCell="plantTypeDescription">Plant description</th>
            <th nxHeaderCell id="discipline" nxSortHeaderCell="disciplineDescription">Discipline</th>
            <th nxHeaderCell id="lastInspected" nxSortHeaderCell="inspectionsInspectionDate">Last
              inspected</th>
            <th nxHeaderCell id="defectCode" nxSortHeaderCell="currentReportDefectCode">Defect
              code</th>
            <th nxHeaderCell id="nextDue" nxSortHeaderCell="inspectionsNextInspectionDate">Next
              due</th>
            <th nxHeaderCell id="location" nxSortHeaderCell="itemLocationName">Location
            </th>
            <th nxHeaderCell id="locationDetails" nxSortHeaderCell="locationDescription">Location
              details</th>
            <th nxHeaderCell id="itemStatus" nxSortHeaderCell="statusDescription">Item
              status</th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow selectable
            *ngFor="let item of locationItemViewModel.itemLocations.items | latestCurrentReport  | badgeColorPipe | ellipsisPipe">
            <td nxTableCell class="align-content-left"
              *ngIf="item.latestReport != null  && item.latestReport.url != '' else emptyIcon" class="center">
              <span class="flex">
                <nx-icon name="pdf" size="s" class="standardBlueColor"
                  (click)="itemsService.getItemReport(item.latestReport.url)">
                </nx-icon>
                <!-- show report id's last 6 digits starting with dots -->
                <div nxTooltip="{{item?.toolTipReportId}}" class="nx-margin-left-s">{{item?.latestReport?.id}}</div>
              </span>
            </td>

            <td (click)="navigateToItemDetailsScreen(item.id)" nxTableCell nxTooltip="{{item.toolTipSerialNo}}"
              *ngIf="item.serialNumber else emptyIcon" class="align-content-left">
              {{item?.serialNumber}}
            </td>
            <td nxTableCell nxTooltip="{{item.toolTipPlantNo}}" (click)="navigateToItemDetailsScreen(item.id)"
              class="align-content-left" *ngIf="item.clientPlantNumber else emptyIcon">
              {{item?.clientPlantNumber}}
            </td>
            <td (click)="navigateToItemDetailsScreen(item.id)" class="align-content-left"
              *ngIf="item.plantType != null && item.plantType.description != '' else emptyIcon" nxTableCell>
              {{item?.plantType?.description}}</td>
            <td class="align-content-left" nxTableCell (click)="navigateToItemDetailsScreen(item.id)"
              *ngIf="item.discipline != null && item.discipline.description != '' else emptyIcon">
              {{item?.discipline?.description}}
            </td>

            <td nxTableCell *ngIf="item.inspections && item.inspections.inspectionDate else emptyIcon"
              (click)="navigateToItemDetailsScreen(item.id)" class="align-content-left">
              {{item?.inspections?.inspectionDate
              | date:'dd-MMM-YYYY'}}
            </td>
            <td (click)="navigateToItemDetailsScreen(item.id)" class="center" nxTableCell>

              <nx-badge
                *ngIf="item.latestReport && item.latestReport.defectCode && item.subDefects == undefined && item.latestReport.defectCode.description != '' else emptyIcon"
                class="badge-padding" [style.backgroundColor]="item.defectCodeBadgeColor"
                [style.color]="common.defectCodeToColor(item.latestReport.defectCode.code)">
                {{item?.latestReport?.defectCode?.code}} </nx-badge>

            </td>
            <td nxTableCell (click)="navigateToItemDetailsScreen(item.id)"
              *ngIf="item.inspections && item.inspections.nextInspectionDate else emptyIcon"
              class="align-content-left wrap-text">
              {{item?.inspections?.nextInspectionDate
              |
              date:'dd-MMM-YYYY'}}
            </td>
            <td (click)="navigateToItemDetailsScreen(item.id)" nxTableCell>
              <span nxTooltip="{{item.toolTipLocationName}}" class="align-content-left">{{item?.locationName}}
              </span><br />
              {{item?.locationPostcode}}
            </td>
            <td (click)="navigateToItemDetailsScreen(item.id)" class="align-content-left" nxTableCell>
              <div *ngIf="item.location.description == undefined && item.location.subCodeDescription == undefined">
                <span><i aria-hidden="true" class="fas fa-minus emptyIcon"></i></span>
              </div>
              <div *ngIf="item.location.description != undefined || item.location.subCodeDescription != undefined">
                <span nxTooltip="{{item.toolTipSubLocation}}" *ngIf="item.location && item.location.subCodeDescription">
                  {{item?.location?.subCodeDescription}}</span>
                <span *ngIf="item.location  && item.location.subCodeDescription && item.location.description"> / </span>

                <span *ngIf="item.location && item.location.description" nxTooltip="{{item.toolTipDetailedLocation}}">
                  {{item?.location?.description}}</span>
              </div>
            </td>
            <td class="align-content-left" nxTableCell (click)="navigateToItemDetailsScreen(item.id)"><span
                *ngIf="item.status && item.status.description != '' else emptyIcon">{{item?.status?.description}}</span>
            </td>
          </tr>
          <tr *ngIf="locationItemViewModel.resultCount === 0 && locationItemViewModel.apiResultReturned">
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
            <td nxTableCell class="align-content-left"><i class="fas fa-minus" aria-hidden="true"></i></td>
          </tr>
        </tbody>
        <ng-template #emptyIcon>
          <td nxTableCell><i class="fas fa-minus center" aria-hidden="true"></i></td>
        </ng-template>
        <ng-template #pdfIcon>
          <td nxTableCell class="noTopBorder">
            <nx-icon name="pdf" size="s" class="standardBlueColor" (click)="itemsService.getItemReport('')">
            </nx-icon>
          </td>
        </ng-template>
      </table>
    </div>
    <div class="nx-margin-top-m pagination-display-inline">
      <record-perpage-toggle class="pagination-record" (onPerPageClick)="togglePerPageMainItem($event)"
        [total]="locationItemViewModel.resultCount" [perPage]="locationItemViewModel.perPage">
      </record-perpage-toggle>

      <div class="paginationContainer">
        <nx-pagination *ngIf="locationItemViewModel.showPaging" [nxCount]="locationItemViewModel.resultCount"
          [nxPage]="locationItemViewModel.page" [nxPerPage]="locationItemViewModel.perPage" nxType="advanced"
          (nxGoPrev)="prevPageNumber()" (nxGoNext)="nextPageNumber()" (nxGoPage)="goToPageNumber($event)"
          class="pagingClass">
        </nx-pagination>
      </div>
    </div>
  </div>
</div>
<!-- Mobile view -->
<div *ngIf="!isDesktop" class="footer-gap">
  <!--New Search area-->
  <div *ngIf="locationItemViewModel.isSearchShow">
    <div nxRow class="nx-margin-left-2xs">
      <div nxCol="12,12">
        <nx-formfield nxLabel="Search items:">
          <input nxInput id="itemSearch" name="itemSearch" [(ngModel)]="locationItemViewModel.itemSearch"
            placeholder="Please enter at least three characters" />
        </nx-formfield>
      </div>
      <div nxCol="12.12" class="nx-margin-left-2xs">
        <button [disabled]="locationItemViewModel.searchDisabled" class="nx-margin-right-s" id="search"
          nxButton="primary small" type="button" (click)="search()">Search</button>
        &nbsp;
        <button class="nx-margin-right-s" type="reset" id="clear" (click)="clearSearch()" nxButton="secondary small"
          type="button">Clear</button>
      </div>
    </div>
  </div>
  <div nxRow>
    <div class="high-light-count" nxCol="9">
      Matching items: &nbsp;{{ locationItemViewModel.resultCount}}
    </div>
    <div nxCol="3" class="align-icons nx-margin-top-xs">
      <nx-icon name="product-search" class="nx-margin-right-xs blueColor " size="s"
        (click)="locationItemViewModel.isSearchShow= ! locationItemViewModel.isSearchShow">
      </nx-icon>
      <nx-icon name="export-and-share"
        [ngStyle]="locationItemViewModel.itemLocations.items == 0 && {cursor: 'not-allowed', opacity: '0.6'}" size="s"
        nxTooltip="Export" (click)="locationItemViewModel.itemLocations.items == 0 ? null : exportMainItemsToCSV()"
        class="blueColor  cursor-pointer"></nx-icon>
    </div>
  </div>
  <div>
    <hr />
  </div>
  <ng-container>
    <div *ngFor="let item of locationItemViewModel.itemLocations.items | latestCurrentReport  | badgeColorPipe "
      class="nx-margin-x-s">
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Serial no.
        </div>
        <div nxCol="8">
          <div>
            <span *ngIf="item.serialNumber else emptyIcon"> {{item?.serialNumber }}</span>
          </div>
        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Plant no.
        </div>
        <div nxCol="8">
          <span *ngIf="item?.clientPlantNumber else emptyIcon"> {{item?.clientPlantNumber}}</span>

        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Plant description
        </div>
        <div nxCol="8">
          <span *ngIf="item?.plantType?.description else emptyIcon"> {{item?.plantType?.description}}</span>

        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Discipline
        </div>
        <div nxCol="8">
          <span *ngIf="item?.discipline?.description else emptyIcon"> {{item?.discipline?.description}}</span>

        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Last inspected
        </div>
        <div nxCol="8">
          <span *ngIf="item.inspections.inspectionDate else emptyIcon"> {{item?.inspections?.inspectionDate
            | date:'dd-MMM-YYYY'}}</span>

        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Defect Code
        </div>
        <div nxCol="8">
          <nx-badge
            *ngIf="item.latestReport && item.latestReport.defectCode && item.subDefects == undefined && item.latestReport.defectCode.description != '' else emptyIcon"
            class="badge-padding" [style.backgroundColor]="item.defectCodeBadgeColor"
            [style.color]="common.defectCodeToColor(item.latestReport.defectCode.code)">
            {{item?.latestReport?.defectCode?.code}} </nx-badge>
        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Next due
        </div>
        <div nxCol="8">
          <span *ngIf="item.inspections.nextInspectionDate else emptyIcon"> {{item?.inspections?.nextInspectionDate
            |
            date:'dd-MMM-YYYY'}}</span>

        </div>
      </div>
      <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
        <div class="formatHeading" nxCol="4">
          Location
        </div>
        <div nxCol="8">
          <span *ngIf="item.toolTipLocationName else emptyIcon"> {{item.toolTipLocationName ? item.toolTipLocationName:
            item?.locationName}}</span>

        </div>
      </div>
      <!--Show/hide area-->
      <div *ngIf="item.showMore" (click)="navigateToItemDetailsScreen(item.id)">
        <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
          <div class="formatHeading" nxCol="4">
            Postcode
          </div>
          <div nxCol="8">
            <span *ngIf="item.locationPostcode else emptyIcon"> {{item?.locationPostcode}}</span>

          </div>
        </div>
        <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
          <div class="formatHeading" nxCol="4">
            Location details
          </div>
          <div nxCol="8">
            <div *ngIf="item.location.description == undefined && item.location.subCodeDescription == undefined">
              <span><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></span>
            </div>
            <div nxTooltip="{{item.toolTipSubLocation}}"
              *ngIf="item.location.description != undefined || item.location.subCodeDescription != undefined">
              <span *ngIf="item.location && item.location.subCodeDescription">
                {{item?.location?.subCodeDescription}}</span>
              <span *ngIf="item.location && item.location.description && item.location.subCodeDescription"> / </span>
              <span *ngIf="item.location && item.location.description" nxTooltip="{{item.toolTipDetailedLocation}}">
                {{item?.location?.description}}</span>
            </div>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
          <div class="formatHeading" nxCol="4">
            Current report ID
          </div>
          <div nxCol="8">
            <span *ngIf="item.latestReport != null && item.latestReport.url != '' else emptyIcon">
              {{item?.latestReport?.id}}</span>
          </div>
        </div>
        <div nxRow (click)="navigateToItemDetailsScreen(item.id)">
          <div class="formatHeading" nxCol="4">
            Item status
          </div>
          <div nxCol="8">
            <span *ngIf="item.status.description else emptyIcon"> {{item?.status?.description}}</span>

          </div>
        </div>
      </div>
      <div nxRow>
        <a nxCol="10" class="alink" *ngIf="!item.showMore" (click)="item.showMore = true">Show more <em
            class="fa fa-chevron-down"></em> </a>
        <a nxCol="10" class="alink" *ngIf="item.showMore" (click)="item.showMore = false">Show
          less <em class="fa fa-chevron-up"></em> </a>
        <span nxCol="2" *ngIf="item.latestReport != null &&  item.latestReport.url != '' else empty">
          <div nxTableCell class="noTopBorder">
            <nx-icon name="pdf" size="s" class="standardBlueColor"
              (click)="itemsService.getItemReport(item.latestReport.url)">
            </nx-icon>
          </div>
        </span>
      </div>
      <hr />
    </div>
    <div *ngIf="locationItemViewModel.itemLocations.items.length === 0 && locationItemViewModel.apiResultReturned">
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Serial no.
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Plant no.
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Plant description
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Discipline
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Last inspected
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Defect Code
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Next due
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Location
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Postcode
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Location details
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Current report ID
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
      <div nxRow>
        <div class="formatHeading" nxCol="4">
          Item status
        </div>
        <div nxCol="8">
          -
        </div>
      </div>
    </div>
  </ng-container>
  <div class="mobilePaginationAlignmentItem nx-margin-top-s">
    <nx-pagination *ngIf="locationItemViewModel.showPaging" [nxCount]="locationItemViewModel.resultCount"
      [nxPage]="locationItemViewModel.page" [nxPerPage]="locationItemViewModel.perPage" nxType="advanced"
      (nxGoPrev)="prevPageNumber()" (nxGoNext)="nextPageNumber()" (nxGoPage)="goToPageNumber($event)"
      class="pagingClass">
    </nx-pagination>

  </div>
</div>

<ng-template #empty></ng-template>
<ng-template #emptyIcon>
  <div nxTableCell><i class="fas fa-minus emptyIcon" aria-hidden="true"></i></div>
</ng-template>