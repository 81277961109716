import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormValidityService {
    private isFormValidSubject = new BehaviorSubject<boolean>(false);
    isFormValid$ = this.isFormValidSubject.asObservable();
    //to pass the value of item group component changes to breadcrumb to handle
    //not leave with unsave changes
    setFormValidity(isValid: boolean) {
        this.isFormValidSubject.next(isValid);
    }
}
