import { Action } from "@ngrx/store";
import { Personalisation } from "src/app/schemas/personalisation";

// set personalisation action type

export enum PersonalisationActionTypes {
    //update the existing values of different personalisation attribute
    UPDATE_PERSONALISATION = '[PERSONALISATION] UPDATE',
    // get personalisation value of user
    GET_PERSONALISATION = '[PERSONALISATION] GET'
}

// get user personalisation action
export class GETPERSONALISATION implements Action {
    readonly type = PersonalisationActionTypes.GET_PERSONALISATION
    constructor(public payload: Personalisation) { }
}

export class UPDATEPERSONALISATION implements Action {
    readonly type = PersonalisationActionTypes.UPDATE_PERSONALISATION
    constructor(public payload: Personalisation) { }
}


// exported all the Personalisation actions
export type PersonalisationAction = GETPERSONALISATION | UPDATEPERSONALISATION;