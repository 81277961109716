import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LocationsComponent } from './locations.component';
import { AuthGuard } from 'src/app/services/auth-service/auth.guard';
import { ItemsComponent } from './location-items/location-items.component';
import { ItemDetailsComponent } from '../item-details/item-details.component';
import { ItemsModule } from './location-items/location-items.module';

// setting up routes for location module
const appRoutes: Routes = [
  // Route for /Locations
  {
    path: '', component: LocationsComponent, canActivate: [AuthGuard], data: {
      breadcrumb: ''
    }
  },
  // Route for /Locations/:locationId/Items
  {
    // first root path for LocationID/items route
    path: ':id/items', canActivate: [AuthGuard], data: {
      breadcrumb: 'Items'
    }, children: [
      {
        path: '', component: ItemsComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Items'
        }
      },

      // route to get details of any specific item from item list
      {
        path: 'details/:itemId', component: ItemDetailsComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Item'
        }

      }
    ],

  },
  {
    // root path for /Locations/items
    path: 'items', canActivate: [AuthGuard], data: {
      breadcrumb: 'Items'
    }, children: [
      {
        path: '', loadChildren: () => ItemsModule, canActivate: [AuthGuard], data: {
          breadcrumb: 'Items'
        }
      },

      // Route for /Locations/items/itemDetail/id
      {
        path: 'itemDetail/:id', component: ItemDetailsComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Item Details'
        }

      }
    ],

  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes)
  ],
})

// Exporting location routing module
export class LocationRoutingModule { }
