import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth0client.service';
import { CommonService } from 'src/app/services/common.service';
import { ErrorLogService } from 'src/app/services/errors/errorService';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  //to show any given maintenance id, if provided
  maintenanceId: string = ""
  //Responsive view true for desktop , false for mobile
  isDesktop: boolean;
  // mobile menu
  isMenuVisible = false;
  // Set mobile menue height based on additional links additions
  mobileMenuHeight: string = '46px';
  constructor(private common: CommonService, private logService: ErrorLogService,
    private layoutService: LayoutService, private auth: AuthService, private localStorageService: LocalStorageService) {

  }
  ngOnInit(): void {
    // subscribe to device type detection observable
    this.layoutService.currentDevice.subscribe(result => this.isDesktop = result);
    //check current maintenance status
    this.getMaintenanceId();
  }

  //display maintenance id
  getMaintenanceId() {
    const data = this.localStorageService.getObject(LocalStorageKeys.FileData);
    if (data) {
      const { id } = JSON.parse(data);
      //maintenance id provided by Allianz,have to show it for back tracking the issue
      this.maintenanceId = id
    }
  }

  //check maintenance status
  checkMaintenanceStatus() {
    this.common.checkMaintenanceStatus()
      .then((result) => {
        if (!result) {
          // auth0 login, with redirect to route path
          this.auth.login("dashboard");
        }
      }).catch(error => {
        this.logService.logError(error);
        throw error;
      });
  }
  //when click on login button
  login() {
    //check current maintenance status
    this.checkMaintenanceStatus();
  }
}


