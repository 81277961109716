import gql from "graphql-tag";
// query to get location a defect count from api endpoint

export const getLocationDefects = gql`query search($search: [Search]!, $aggregation: [SearchAggregation],  $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: Int!){
    search(search: $search, aggregation: $aggregation, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
        buckets{
            name
            results{
                key
                count
                buckets{
                    name
                    results{
                        key
                        count
                    }
                }   
            }
        }
    }
}`

// query to get only the location id of defects
export const getLocations = gql`query search($search: [Search]!, $aggregation: [SearchAggregation],  $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: Int!){
    search(search: $search, aggregation: $aggregation, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
        buckets{
            results{
                key 
                count
            }
        }
    }
}`

// query to get location due / over due items count
export const getLocationDueOverDue = gql`query search($search: [Search]!, $aggregation: [SearchAggregation],  $sortField: SortOrderField!, $sortOrder: SortOrder, $from: NonNegativeInt!, $size: Int!){
    search(search: $search, aggregation: $aggregation, sort: {field: $sortField, order:$sortOrder}, from: $from, size: $size) {
              totalCount   
}         
 }      
    `;
// map location a defect object into this interface
export interface LocationDefect {
    name: string
    results: FirstBucketResults[]
}

// this interface contains the array of type element which is having key, count field and list of buckets
export interface FirstBucketResults {
    key: string
    count: number
    buckets: SecondBucketResults[]
}
// this interface is having the object of list of results
export interface SecondBucketResults {
    results: ResultObject[]
}
// this interface possesses the result object details
export interface ResultObject {
    key: string
    name: string
}

