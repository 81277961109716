import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { GoogleMapMarker, GoogleMapMarkerDetailsList } from 'src/app/schemas/location-due-overdue';
import { CommonService } from 'src/app/services/common.service';
import { LabelTextColor } from 'src/app/shared/enums/googleMapMarkerLabel';
import { MapViewModel } from './google-map.viewmodel';
import { PrepareMapRequestModel } from './prepare-map-request.model';
import { ClusterOptions, MarkerClusterer } from "@googlemaps/markerclusterer";
import { SharedMessages } from 'src/app/shared/shared-messages';
import { ErrorLogService } from 'src/app/services/errors/errorService';

@Component({
    selector: 'google-map',
    templateUrl: './google-map-component.html',
    styleUrls: ['./google-map-component.scss']
})

export class GoogleMapComponent implements OnChanges {
    // input to fetch locations from other component and store details in local variable
    @Input('locationResultset')
    set _locationResultset(LocationDueOverDueArray: Array<any>) {
        // assign array to local variable
        this.mapViewModel.locationsData = LocationDueOverDueArray;

    }

    // input to have location due count of all locations
    @Input() locationDueCount: any
    // input to have location over due count of all locations
    @Input() locationOverDueCount: any
    // map property
    map: google.maps.Map;
    // object for google map marker
    marker: google.maps.Marker;
    // object for geo code service
    geocoder: google.maps.Geocoder;
    // calling map view model and storing in mapViewModel variable
    mapViewModel: MapViewModel = new MapViewModel();
    // object to create a cluster of pins on map
    markerClusterer: any;
    // local object to store markers for using in making cluster
    markers: any[] = [];
    // output property to emit the switch between map/list view
    @Output() setMapToListView: EventEmitter<boolean> = new EventEmitter();
    // output property to emit the navigation from dashboard to items page for overdue items with id
    @Output() navigateToOverDueItems: EventEmitter<string> = new EventEmitter();
    // output property to emit the navigation from dashboard to items page for due items with id
    @Output() navigateToDueItems: EventEmitter<string> = new EventEmitter();
    // output property to emit the navigation from dashboard to items page for overdue and due items with id
    @Output() navigateToDueOverDueItems: EventEmitter<string> = new EventEmitter();
    //info window
    @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
    // for device type
    @Input() isDesktop: boolean;
    // input to know map needs to reload or not
    @Input() reloadMap: boolean;


    // object of a class having lat, lng, type and total count of due / over due items to be shown on marker
    markerDetails: GoogleMapMarkerDetailsList = new GoogleMapMarkerDetailsList();
    // get the map div from html 
    @ViewChild('map') mapElement: any;
    constructor(@Inject(DOCUMENT) private document: Document,
        private scriptRenderer: Renderer2, private common: CommonService, private errorService: ErrorLogService) {
        // This is intentional
    }

    // loading map with properties
    initMap(): void {
        // assign geocoder object to object of geocode
        this.geocoder = new google.maps.Geocoder();
        // assign center and zoom
        this.map = new google.maps.Map(this.mapElement.nativeElement, {
            center: new google.maps.LatLng(this.mapViewModel.latCenter, this.mapViewModel.lngCenter),
            zoom: 8,
            fullscreenControl: false,
            minZoom: 3, // maximum zoom out limit is set to 3
            styles: [
                {   // hidding any point of interest lables from map
                    // we can customize it as per google docs as well: https://developers.google.com/maps/documentation/javascript/style-reference#style-features
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    // hidding airports pins from the map
                    "featureType": "transit.station.airport",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        });

        // check if no records, need to show an info window at center of map
        // with message. Info window is default open
        if (this.mapViewModel.locationsData.length === 0) {
            // get center(lat /lng) of map
            const centerPosition = this.map.getCenter();
            const infowindow = new google.maps.InfoWindow({
                content: SharedMessages.noDueOverDueItemsMessage
            });
            // set position of info window
            infowindow.setPosition({ lat: centerPosition.lat(), lng: centerPosition.lng() });
            // open the info window
            infowindow.open(this.map);
        }
        else {
            this.setOverlayDivsOnMap(document);
        }

        // renderer function required to build cluster pin
        // It contains the reference to all pins in cluster
        // calcutates its due/overdue count to show in cluster pin
        // add custom icon of cluster and do some formatting
        const renderer = {
            render({ markers, position }: ClusterOptions) {
                let labelText = "";
                let overdue = 0;
                let due = 0;
                // loop on all creater markers
                markers.forEach((element: google.maps.Marker) => {
                    //add the overdue / due of the each marker 
                    overdue += Number(element.get("overDueCount"))
                    due += Number(element.get("dueCount"))
                });
                // sum count of overdue and due 
                const sum = overdue + due;
                // if count is > 999 show + with 999 text 
                if (sum > 999)
                    labelText = "999+";
                // else show the exact count 
                else
                    labelText = `${sum}`
                // make a new marker pin which acts as a cluster pin
                return new google.maps.Marker({
                    label: { text: labelText, color: LabelTextColor.OverDueLabelColor, fontSize: "12px" },
                    position,
                    icon: "../assets/map-markers/BluePinNew.png",
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + markers.length,
                })
            }
        }
        // object for creating marker cluster
        this.markerClusterer = new MarkerClusterer({ map: this.map, markers: [], renderer: renderer });
    }

    // set the divs to show counts of overdue/due on map
    setOverlayDivsOnMap = (document: any) => {
        // check if overlay element of overdue already present in DOM or not
        // if yes, remove and add with updated data
        // to avoid multiple overlays
        if (document.querySelector("#over-due-div") !== null)
            document.querySelector("#over-due-div").remove()
        // create a div for overdue items count to be added in a container on map 
        let overdueElement = document.createElement("div");
        overdueElement.id = "over-due-div";
        overdueElement.innerHTML = `<p class='oval-shape over-due-items-color'>Overdue: ${this.locationOverDueCount}</p>`;
        // check if overlay element of due already present in DOM or not
        // if yes, remove and add with updated data
        // to avoid multiple overlays
        if (document.querySelector("#due-div") !== null)
            document.querySelector("#due-div").remove()
        // create a div for due items count to be added in a container on map 
        let dueElement = document.createElement("div");
        overdueElement.id = "due-div";
        dueElement.innerHTML = `<p class='oval-shape due-items-color '>Due: ${this.locationDueCount} </p>`;
        // check if overlay element of overdue-due already present in DOM or not
        // if yes, remove and add with updated data
        // to avoid multiple overlays
        if (document.querySelector("#due-over-due-element") !== null)
            document.querySelector("#due-over-due-element").remove()
        // create bottom overlay element to show over due and due count
        let dueOverDueElement = document.createElement("div");
        dueOverDueElement.id = "due-over-due-element";
        dueOverDueElement.classList.add("due-over-due-container");
        // append the over due / due childs in div
        dueOverDueElement.appendChild(overdueElement)
        dueOverDueElement.appendChild(dueElement)
        this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(dueOverDueElement);
        // check if overlay element of view list element already present in DOM or not
        // if yes, remove and add with updated data
        // to avoid multiple overlays
        if (document.querySelector("#view-list-element") == null) {
            // overlay to move from map to list
            let viewListButton = document.createElement("div");
            viewListButton.id = "view-list-element";
            viewListButton.classList.add('view-list-container');
            viewListButton.innerHTML =
                `<p class='oval-shape-view-list view-list-color button-alignment'><i class="fa fa-list" aria-hidden="true"></i> View list</p> `;
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(viewListButton);
            viewListButton.addEventListener("click", () => this.setMapToListView.emit(false))
        }
        // check if overdue count is not equal to - , then add a click event
        if (this.locationOverDueCount != "-")
            overdueElement.addEventListener("click", () => this.navigateToOverDueItems.emit(""));
        // check if due count is not equal to - , then add a click event
        if (this.locationDueCount != "-")
            dueElement.addEventListener("click", () => this.navigateToDueItems.emit(""));
    }
    // method to remove script from DOM
    removeGoogleMapScript() {
        // add google map script text in keywords
        const keywords = ['maps.googleapis'];
        //Remove google from BOM (window object)
        window.google = undefined;
        //Remove google map scripts from DOM
        let scripts = document.head.getElementsByTagName("script");
        for (let i = scripts.length - 1; i >= 0; i--) {
            let scriptSource = scripts[i].getAttribute('src');
            if (scriptSource != null) {
                if (keywords.filter(item => scriptSource.includes(item)).length) {
                    scripts[i].remove();
                }
            }
        }
    }
    // creating script tag at runtime and appending in head of DOM
    loadScript() {
        return new Promise((resolve, reject) => {
            // removing any script already present in DOM
            this.removeGoogleMapScript();
            // commands to add new script at runtime
            const script = this.scriptRenderer.createElement('script');
            script.type = 'text/javascript';
            script.src = this.mapViewModel.googleApiScript;
            script.id = this.mapViewModel.googleApiScriptId
            script.onload = resolve;
            script.onerror = reject;
            this.scriptRenderer.appendChild(this.document.head, script);
        })
    }

    // objectives of prepare marker function are listed below
    // 1. get lat long by address
    // 2. assign the type according to overdue / due or both condition
    // 3. assign the count to be shown on label
    // 4. push marker details in marker details list
    // 5. call add marker function
    // 6. added dynamic info window 
    prepareMarker(requestModel: PrepareMapRequestModel): void {
        this.geocoder
            .geocode(requestModel.request, (result) => {
                // check if result object is not null
                if (result != null) {
                    // create the object of marker with all marker details
                    let markerDetails = {} as GoogleMapMarker
                    if (result.length > 0) {
                        markerDetails.Lat = result[0].geometry.location.lat()
                        markerDetails.Long = result[0].geometry.location.lng()
                    }
                    if (requestModel.overdue && requestModel.due)
                        markerDetails.type = "DueOverdue";
                    else if (requestModel.due)
                        markerDetails.type = "Due"
                    else if (requestModel.overdue)
                        markerDetails.type = "Overdue"
                    markerDetails.dueOverDueTotalCount = requestModel.dueOverDueTotalCount;
                    markerDetails.locationName = requestModel.locationName;
                    markerDetails.locationId = requestModel.locationId;
                    markerDetails.address = requestModel.completeAddress;
                    markerDetails.postcode = requestModel.postCode;
                    markerDetails.dueCount = requestModel.dueCount;
                    markerDetails.overDueCount = requestModel.overDueCount;
                    this.markerDetails.markersList.push(markerDetails)
                    // pass the marker detail object for marker creation
                    this.addMarker(markerDetails)
                }
            })
    }

    // function to add marker on map
    addMarker(markerDetails) {
        // check for empty over due count field in markerDetails
        const overDueCount = markerDetails.overDueCount == "" ? "0" : markerDetails.overDueCount;
        // check for empty due count field in markerDetails
        const dueCount = markerDetails.dueCount == "" ? "0" : markerDetails.dueCount;
        // count the number of digits in total due overdue field
        const numOfDigits = this.common.numberLength(markerDetails.dueOverDueTotalCount)
        // create marker with supplied lat/lng, icon, label details
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(markerDetails.Lat, markerDetails.Long),
            map: this.map,
            icon: {
                url: this.mapViewModel.icons[markerDetails.type].icon,
                scaledSize: this.setScalingSizeMarkerImage(numOfDigits),
                labelOrigin: this.setLabelOriginPosition(numOfDigits)
            },
            label: {
                text: markerDetails.dueOverDueTotalCount > 999 ? "999+" : `${markerDetails.dueOverDueTotalCount}`,
                fontFamily: 'Arial',
                color: markerDetails.type == "Due" ? LabelTextColor.DuePinLabelColor : LabelTextColor.OverDueLabelColor
            }
        });
        // set marker properties
        this.setMarkerProperties(marker, markerDetails.type, markerDetails.locationId, markerDetails.dueCount, markerDetails.overDueCount)

        // look if cluster have existing any markers
        if (this.markerClusterer.markers && this.markerClusterer.markers.length > 0) {
            // check for the presence of marker already with same lat,lng
            if (!this.markerClusterer.markers.some(clusterMarker => clusterMarker.getPosition().lat() == marker.getPosition().lat() && clusterMarker.getPosition().lng() == marker.getPosition().lng()))
                this.markerClusterer.addMarker(marker)
        }
        else
            this.markerClusterer.addMarker(marker)
        // reset the view port according to new markers
        //this.markerClusterer.resetViewport();
        //dynamic creation of info window
        let contentString =
            '<div class="info-window-container">' +
            "<h3 id='navigateForDueOverDue'><u>" + markerDetails.locationName + " </u> </h3>" +
            "<p>Address:   " + markerDetails.address + "</p>" +
            "<p>Postcode:  " + markerDetails.postcode + " </p>" +
            "<div>" +
            "<button class='oval-shape-info-window-over-due-count over-due-items-color' id='navigateForOverDue'>Overdue: " + overDueCount + "</button>" +
            "<button class=' oval-shape-info-window-due-count due-items-color' id='navigateForDue'>Due: " + dueCount + "</button>" +
            "</div>" +
            "</div>";


        // info window object for markers
        let infoWindow = new google.maps.InfoWindow(
            {
                content: contentString,
            }
        );
        // add listeners to info window
        this.infoWindowListeners(marker, infoWindow)
        // set marker on map
        marker.setMap(this.map)

        // push the marker detail object in marker details list for future use
        this.markerDetails.markersList.push(markerDetails)
        // set the bound of map
        this.fitBound()
    }
    // add listener to open/close info window
    infoWindowListeners(marker: google.maps.Marker, infoWindow: google.maps.InfoWindow) {
        // reference to global this
        // need to have this is because in events, we are unable to access global variables
        const globalThis = this;
        //added click event for pin to display info window
        marker.addListener("click", () => {
            // count overdue and due 
            const totalDueOverDue = marker.get("overDueCount") + marker.get("dueCount")
            // if marker type is due it means when update the marker icon of blue
            // We need to make label white to make it visible
            if (marker.get("type") == "Due") {
                const label = {
                    text: totalDueOverDue > 999 ? "999+" : `${totalDueOverDue}`,
                    fontFamily: 'Arial',
                    color: LabelTextColor.OverDueLabelColor
                }
                marker.setLabel(label)
            }
            // count the number of digits in total due overdue field
            const noOfDigits = globalThis.common.numberLength(totalDueOverDue)
            const icon = {
                url: globalThis.mapViewModel.icons["BluePin"].icon,
                scaledSize: globalThis.setScalingSizeMarkerImage(noOfDigits),
                labelOrigin: globalThis.setLabelOriginPosition(noOfDigits),

            };
            marker.setIcon(icon);
            infoWindow.open(this.map, marker);
        });

        // setting tabindex for markers to be 0
        // this is just added for accessibility testing which was giving warning i.e. focusable element is not in keyboard tab order 
        google.maps.event.addListener(this.map, "tilesloaded", function () {
            [].slice.apply(document.querySelectorAll('#map div[role="button"]')).forEach(function (item) {
                item.setAttribute('tabindex', '0');
            });
        })

        // when info window closes, need to revert the marker pin
        google.maps.event.addListener(infoWindow, 'closeclick', function () {
            // get marker type to revert the pin icon
            const markerType = marker.get("type")
            const totalDueOverDue = marker.get("overDueCount") + marker.get("dueCount")
            // if marker type is due it means we are now going back to Yellow pin
            // We need to make label grey again
            if (marker.get("type") == "Due") {
                const label = {
                    text: totalDueOverDue > 999 ? "999+" : `${totalDueOverDue}`,
                    fontFamily: 'Arial',
                    color: LabelTextColor.DuePinLabelColor
                }
                marker.setLabel(label)
            }
            // count the number of digits in total due overdue field
            const noOfDigits = globalThis.common.numberLength(totalDueOverDue)
            // create icon object
            const icon = {
                url: globalThis.mapViewModel.icons[markerType].icon,
                scaledSize: globalThis.setScalingSizeMarkerImage(noOfDigits),
                labelOrigin: globalThis.setLabelOriginPosition(noOfDigits),
            };

            // set icon according to marker type
            switch (markerType) {
                case "Overdue": marker.setIcon(icon); break;
                case "Due": marker.setIcon(icon); break;
                case "DueOverdue": marker.setIcon(icon); break;
                default: return "";
            }
        });

        // listener for info window content navigation to view respective due/overdue items
        google.maps.event.addListener(infoWindow, 'domready', function () {
            document.getElementById("navigateForDueOverDue").addEventListener("click", () => {
                // get location Id of the marker
                const locationId = marker.get("locationId")
                // emit this location id to view only due and overdue items of this location
                globalThis.navigateToDueOverDueItems.emit(locationId);
            });
            document.getElementById("navigateForOverDue").addEventListener("click", () => {
                // get location Id of the marker
                const locationId = marker.get("locationId")
                // check if this location is having overdue count > 0
                const locationObj = globalThis.mapViewModel.locationsData.find(location => location.id == locationId);
                // emit this location id to view only overdue items of this location
                if (locationObj && locationObj.overdueCount > 0)
                    globalThis.navigateToOverDueItems.emit(locationId);
            });
            document.getElementById("navigateForDue").addEventListener("click", () => {
                // get location Id of the marker
                const locationId = marker.get("locationId")
                // check if this location is having due count > 0
                const locationObj = globalThis.mapViewModel.locationsData.find(location => location.id == locationId);
                // emit this location id to view only due items of this location
                if (locationObj && locationObj.dueCount > 0)
                    // emit this location id to view only dueitems of this location
                    globalThis.navigateToDueItems.emit(locationId);
            });
        })
    }

    // set/ attach properties with marker 
    setMarkerProperties(marker: google.maps.Marker, type: string, locationId: string, dueCount: number, overdueCount: number) {
        // adding details of current marker type , location Id, due count, overdue count for later use
        // type , location Id is used in info window work
        marker.set("type", type);
        marker.set("locationId", locationId);
        // due count and over due count is used in showing the count in a cluster of pins
        marker.set("dueCount", dueCount);
        marker.set("overDueCount", overdueCount);
    }

    // this function automatically zoom in/out after addition of new marker on map
    fitBound() {
        // field for setting the map bound according to markers for auto adjustment
        const bounds = new google.maps.LatLngBounds();
        for (const element of this.markerDetails.markersList) {
            // extend the bound to adjust the marker on map
            bounds.extend(new google.maps.LatLng(element.Lat, element.Long));
        }
        // add latest bounds to map
        this.map.fitBounds(bounds);
    }

    // we have to add this to show changes on map when input changes
    ngOnChanges(): void {

        // map initializing conditions called
        this.initializeMapOnNgChanges();

        // check if it is not undefined and have data
        if (this.mapViewModel.locationsData && this.mapViewModel.locationsData.length > 0) {

            // call function for setting up markers
            this.setupMarkerOnMap();

        }
    }

    // local function to initialize map with different conditions
    private initializeMapOnNgChanges(): void {
        if (this.map == undefined)
            // remove/load the google map api script, and then load the map
            this.loadScript().then(() => this.initMap()).catch((eror) => this.errorService.logError(eror));
        // if map is not null and we want to reload the map
        // this is the scenario when we apply filter and we need to show filtered data on map
        else if (this.map != undefined && this.reloadMap) {
            // fetch all previous markers added on map
            this.markers.forEach(marker => {
                // remove marker
                marker.setMap(null)
            })
            // make marker lists empty
            this.mapViewModel.markerDetails.markersList = []
            // reload the map
            this.initMap()
        }
    }


    // loop on locations data to do multiple operations
    // 1. check type of over due / due items for pins
    // 2. count and sum number of due / over due to pass to overlay on map
    // 3. count specific location due / over due to pass to label on marker
    setupMarkerOnMap = () => {
        // make it set to default
        let locationName = "";
        let postalAddress = "";
        let postcode = "";
        for (const index in this.mapViewModel.locationsData) {
            // local function variables to store values
            let dueOverDueTotalCount = 0;
            let singleLocationDueCount = 0;
            let singleLocationOverDueCount = 0;
            locationName = this.mapViewModel.locationsData[index].name;
            postcode = this.mapViewModel.locationsData[index].postalAddress.postcode;
            postalAddress = `${this.mapViewModel.locationsData[index].postalAddress.address1}, ${this.mapViewModel.locationsData[index].postalAddress.address2}, ${this.mapViewModel.locationsData[index].postalAddress.address3}, ${this.mapViewModel.locationsData[index].postalAddress.address4}, ${this.mapViewModel.locationsData[index].postalAddress.address5}, ${this.mapViewModel.locationsData[index].postalAddress.postcode}`
            // assign value to local variable to be passed for marker label purpose
            dueOverDueTotalCount += this.checkOverDueCount(this.mapViewModel.locationsData[index])
            // object to have overdue count of only single location
            singleLocationOverDueCount = this.checkOverDueCount(this.mapViewModel.locationsData[index])
            // assign value to local variable to be passed for marker label purpose
            dueOverDueTotalCount += this.checkDueCount(this.mapViewModel.locationsData[index])
            // object to have due count of only single location
            singleLocationDueCount = this.checkDueCount(this.mapViewModel.locationsData[index])
            // prepare request model
            const prepareRequestModel = new PrepareMapRequestModel();
            // setting request model properties
            prepareRequestModel.request = {
                address: `${this.mapViewModel.locationsData[index].postalAddress.address1}, ${this.mapViewModel.locationsData[index].postalAddress.address3}, ${this.mapViewModel.locationsData[index].postalAddress.postcode} `
            };
            prepareRequestModel.due = this.mapViewModel.locationsData[index].dueCount && this.mapViewModel.locationsData[index].dueCount != "-" ? true : false
            prepareRequestModel.overdue = this.mapViewModel.locationsData[index].overdueCount && this.mapViewModel.locationsData[index].overdueCount != "-" ? true : false;
            prepareRequestModel.dueOverDueTotalCount = dueOverDueTotalCount;
            prepareRequestModel.locationName = locationName;
            prepareRequestModel.completeAddress = postalAddress;
            prepareRequestModel.postCode = postcode;
            prepareRequestModel.dueCount = singleLocationDueCount;
            prepareRequestModel.overDueCount = singleLocationOverDueCount;
            prepareRequestModel.locationId = this.mapViewModel.locationsData[index].id;
            // call prepare marker function with parameters
            // added debounch functionality to schedule calls of geo coding with some interval
            const sendGeocodeCall =
                this.debounce(() =>
                    this.prepareMarker(prepareRequestModel), 1200 * parseInt(index));
            sendGeocodeCall();
        }
    }

    // look for overdue count in object to assign it to marker
    checkOverDueCount = (LocationObj: any) =>
        // assign value to local variable to be passed for marker label purpose
        LocationObj.overdueCount && LocationObj.overdueCount != "-" ? parseInt(LocationObj.overdueCount) : 0

    // look for due count in object to assign it to marker
    checkDueCount = (LocationObj: any) =>
        // assign value to local variable to be passed for marker label purpose
        LocationObj.dueCount && LocationObj.dueCount != "-" ? parseInt(LocationObj.dueCount) : 0

    // method to set the width of the marker icon dynamically based on number digits
    // We are updating the width only for 4 digit max because maximum value we have is 999+
    setScalingSizeMarkerImage = (numOfDigits: number) => {
        let widthValue = 47;
        switch (numOfDigits) {
            case 1: widthValue = 25; break;
            case 2: widthValue = 30; break;
            case 3: widthValue = 35; break;
            case 4: widthValue = 40; break;
            default: break;
        }
        // return the updated object of size with width
        return new google.maps.Size(widthValue, 25)
    }

    // method to set the origin of label in marker based on number digits
    // We are updating the position only for 4 digit max because maximum value we have is 999+
    setLabelOriginPosition = (numOfDigits: number) => {
        let xAxis = 23;
        switch (numOfDigits) {
            case 1: xAxis = 12; break;
            case 2: xAxis = 15; break;
            case 3: xAxis = 18; break;
            case 4: xAxis = 21; break;
            default: break;
        }
        // return the updated object of origin point
        return new google.maps.Point(xAxis, 10)
    }
    // function to call certain function after some interval
    debounce(func: any, timeout: number) {
        let timer;
        return (...args) => {
            // clear the timer
            clearTimeout(timer);
            // set the timer and call the function
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
}