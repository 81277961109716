
import moment from "moment";

export class PnaViewModel {
  // info message
  pnaInfoMessage: string = 'Any applied filters will not be saved and the widget will display all locations with no date range applied to the data.';
  // the num of P defect counts 
  pDefectCount: number = 0;
  // list of all location ids
  locationIds: any[] = []
  // start date is equal to -1 year from todays date
  pDefectStartDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
  // end date of A defect that is todays date 
  pDefectEndDate = moment().format('YYYY-MM-DD')
  // boolean for device 
  isDesktop: boolean = true;
  // boolean to know that date has changed or not
  dateRangeChanged: boolean = false;
  // boolean to know are locations selected or not
  isAllADefectSelected: boolean = true;
  // make location name and count field empty by default
  // later append the details of locations plus show its count
  defectLocationNameCount: string = ""
  // list of all location names selected from filter
  selectedLocationName: string[] = []
  // list of all location ids selected from filter
  selectedLocationIds: number[] = [-1]
  // start date to be send to filters on load
  startDate: moment.Moment;
  // end date to be send to filters on load
  endDate: moment.Moment;
  // export csv file name
  exportFileName: string = "exportPdefectsItems";
  //further option to be included in exported file, we are using header option.
  options = {
    headers: ["Location", "Serial no.", "Plant no.", "Plant description", "Discipline", "Last inspected",
      "Defect code", "Next due", "Sub location", "Detailed location", "Item status"],
  };

  // height of popover
  popoverHeight: string = "18rem"
  // width of popover
  popoverWidth: string = "13rem"

  // defect code property
  defectCode: string = "P"

}

