import { ApolloQueryResult } from "apollo-client";
import { Item } from "src/app/schemas/location-items";
import { BaseResponse } from "./BaseResponse.Model";

// class to handle item details gql response
export class ItemDetailResponse extends BaseResponse {
    // hold list of items details
    itemDetails: Item[] = [];
    // total count of results
    totalCount: number = 0;
    // to hold the status of data prescence of results
    hasResultsProperty: boolean = true;
    // to hold the status of data prescence of results
    constructor(response: ApolloQueryResult<any>) {
        super(response);
        // setting properties from response
        if (response && response.data && response.data.search) {
            this.itemDetails = this.getResponseProperty("results", response.data.search);
            this.totalCount = this.getResponseProperty("totalCount", response.data.search);
        }
        else
            this.hasResultsProperty = false;
    }
}