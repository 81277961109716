import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "apollo-client";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { getItemsWithDefects } from "src/app/schemas/location-a-defects-names";
import { getItemDetailByLocations, getItemsByLocations, getItemsByLocationsForMainItemView } from "src/app/schemas/location-items";
import { SearchModel } from "src/app/schemas/searchModel";
import { SortEmployeeField } from "src/app/schemas/users";
import { ItemDetailResponse } from "src/app/shared/models/responses/itemsDetailsResponse.Model";
import { environment } from "src/environments/environment";
import { FiltersService } from "../filters-service";
import { HttpService } from "../http.service";
import { GraphQLService } from "./graphql.service";

@Injectable()

// Service for getting location items by using graphql
export class LocationItemService {

    // sort field list to send to API
    sortFieldList: SortEmployeeField[] = [];
    // sort field object to append in sort field list 
    sortField = {} as SortEmployeeField;
    // secondary sorting field name
    secondarySortName: string = "currentReportId"

    public constructor(private graphQL: GraphQLService, private httpService: HttpService, private itemFilterService: FiltersService) {
    }
    // method to get items of specific locations
    async getLocationItems(searchList: SearchModel[], isItemDetailCall: boolean = false, order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10, secondarySortingRequest: boolean = false): Promise<ItemDetailResponse> {
        this.sortFieldList.length = 0
        this.prepareSortFields(sortName, order, secondarySortingRequest)
        const variables = {
            "sortBy": this.sortFieldList,
            "from": from,
            "size": size,
            "search": searchList
        }
        const response = await this.graphQL.getList(isItemDetailCall ? getItemDetailByLocations : getItemsByLocations, variables).pipe(take(1)).toPromise();
        // map the data to item details object
        const itemDetailMappedResponse = new ItemDetailResponse(response);
        // check for the presence of item details data object
        if (!itemDetailMappedResponse.hasResultsProperty)
            itemDetailMappedResponse.itemDetails = [];
        // returning the results in observable form 
        return itemDetailMappedResponse;
    }
    // method to get items for main item view
    async getLocationItemsForMainItem(searchList: SearchModel[], isItemDetailCall: boolean = false, order: string = "ASC", sortName: string = "_id", from: number = 0, size: number = 10, secondarySortingRequest: boolean = false): Promise<ItemDetailResponse> {
        this.sortFieldList.length = 0
        this.prepareSortFields(sortName, order, secondarySortingRequest)
        const variables = {
            "sortBy": this.sortFieldList,
            "from": from,
            "size": size,
            "search": searchList
        }

        const response = await this.graphQL.getList(isItemDetailCall ? getItemDetailByLocations : getItemsByLocationsForMainItemView, variables).pipe(take(1)).toPromise();
        // map the data to item details object
        const itemDetailMappedResponse = new ItemDetailResponse(response);
        // check for the presence of item details data object
        if (!itemDetailMappedResponse.hasResultsProperty)
            itemDetailMappedResponse.itemDetails = [];
        // returning the results in observable form 
        return itemDetailMappedResponse;
    }
    // prepare the sort fields list
    prepareSortFields = (sortName: string, order: string, secondarySortingRequested: boolean) => {
        this.sortField.field = sortName
        this.sortField.order = order
        this.sortFieldList.push(this.sortField)
        // add secondary sort fields in case of first page load
        if (secondarySortingRequested) {
            this.sortField = {} as SortEmployeeField;
            this.sortField.field = this.secondarySortName
            this.sortField.order = order
            this.sortFieldList.push(this.sortField)
        }

    }
    // get locations by promise way
    async getItemsByPromise(searchList: SearchModel[], isItemDetailCall: boolean = false, sortOrder: string = "ASC", sortField: string = "name", from: number = 0, size: number = 100): Promise<any> {
        // call above function and add the promise work
        const resultToPromise = await this.getLocationItems(searchList, isItemDetailCall, sortOrder, sortField, from, size);
        return resultToPromise;
    }

    // method to get items of specific 'A' defect locations
    getItemsWithDefects(parameters: any, showLoadingIndicator: boolean = true): Observable<ApolloQueryResult<any>> {
        const variables = {
            "search": parameters.searchList,
            "sortOrder": parameters.order,
            "sortField": parameters.sortName,
            "from": parameters.from,
            "size": parameters.size,
            "dueFrom": parameters.dueFrom,
            "dueTo": parameters.dueTo,
            "locationIds": parameters.locationIds,
            "defectTypes": [parameters.defectTypes],
            "itemGroupIds": parameters.itemGroupIds
        }
        // returning the results in observable form 
        return this.graphQL.getList(getItemsWithDefects, variables, showLoadingIndicator);
    }
    // get 'A' defect locations by promise way
    getItemsWithDefectsByPromise(parameters: any): Promise<any> {
        // call above function and add the promise work
        const resultToPromise = this.getItemsWithDefects(parameters).pipe(take(1)).toPromise();
        return resultToPromise.then(x => { return x; })
    }

    // Function to call endpoint from service for getting pdf report data from http api endpoint 
    // Opens report in new tab.
    getItemReport(urlPart: string): void {
        // checking if urlpart has any value
        if (urlPart) {
            // Setting up headers and response type
            const responseType = 'blob' as 'json';
            this.httpService.get(`${environment.reports}${urlPart}`, responseType).subscribe(
                (response: any) => {
                    // making sure if we have blob returned in response
                    if (response instanceof Blob) {
                        if (navigator.userAgent.match('CriOS')) { //Chrome iOS
                            const reader = new FileReader();
                            reader.onloadend = function () {
                                const iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
                                let winObj = window.open();
                                winObj.document.open();
                                winObj.document.write(iframe);
                                winObj.document.close();
                            };
                            reader.readAsDataURL(response);
                        } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) { //Safari & Opera iOS
                            const url = window.URL.createObjectURL(response);
                            window.location.href = url;
                        }
                        else {
                            // Creating a url 
                            const genericBloburl = window.URL.createObjectURL(response);
                            // Opening this url in new tab, by creating anchor
                            let anchor = document.createElement('a');
                            // setting its properties
                            anchor.href = genericBloburl;
                            anchor.target = '_blank';
                            // click on it to redirect to new tab
                            anchor.click();
                            // remove anchor from body at the end
                            anchor.remove();
                        }
                    }
                }
            );
        }
    }

    // method to set the filters data of items screen
    setItemsFilters = (dashboardFilterDetails: any, pageNo: number, sortBy: string, sortDirection: string, itemSearch: string): void => {
        this.itemFilterService.itemsFilter.dashboardFilterDetails = dashboardFilterDetails
        this.itemFilterService.itemsFilter.pageNo = pageNo;
        this.itemFilterService.itemsFilter.itemSearch = itemSearch;
        this.itemFilterService.itemsFilter.sortBy = sortBy;
        this.itemFilterService.itemsFilter.sortDirection = sortDirection;
    }

    // method to send back the items model object 
    fetchItemsFilters = () => this.itemFilterService.itemsFilter

    // method to set the filters data of main items screen
    setMainItemsFilters = (pageNo: number, sortBy: string, sortDirection: string, itemSearch: string): void => {
        this.itemFilterService.mainItemsFilter.pageNo = pageNo;
        this.itemFilterService.mainItemsFilter.sortBy = sortBy;
        this.itemFilterService.mainItemsFilter.sortDirection = sortDirection;
        this.itemFilterService.mainItemsFilter.itemSearch = itemSearch
    }

    // method to send back the main items model object 
    fetchMainItemsFilters = () => this.itemFilterService.mainItemsFilter


}