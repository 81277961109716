import { NgModule } from '@angular/core';
import { ItemsComponent } from './location-items.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { LocationItemsRoutingModule } from './location-items-routing.module';

// Items component module
@NgModule({
  declarations: [
    ItemsComponent
  ],
  imports: [
    LocationItemsRoutingModule,
    SharedModule,
    FormsModule
  ],
})
export class ItemsModule { }
