// this model is required to prepare params for a method
// because no. of params were exceeded from 7
export class PrepareMapRequestModel {
    request: google.maps.GeocoderRequest;
    overdue: boolean;
    due: boolean;
    dueOverDueTotalCount: number;
    locationName: string;
    completeAddress: string;
    postCode: string;
    dueCount: number;
    overDueCount: number;
    locationId: string;
}