import { GoogleMapMarkerDetailsList } from "src/app/schemas/location-due-overdue";
import { environment } from "src/environments/environment";

export class MapViewModel {

    // google api script to be set in head 
    googleApiScript = `https://maps.googleapis.com/maps/api/js?key=${environment.google_map_api_key}&callback=Function.prototype`;
    // google api script id to be set in head 
    googleApiScriptId = "google-map-script";
    // location object to be loaded on map
    locationsData: any;
    // field to have path of the directory of google map custom marker pins
    iconBase =
        "../assets/map-markers/";
    // option of custom cluster object
    customClusterObj = {
        styles: [{
            height: 47,
            url: this.iconBase + "CustomClusterPin.png",
            width: 30,
            textColor: "white"
        }]
    }
    // an array to have specific pin to a specific status of items (Due / Over due / Due & Over due)
    icons = {
        Overdue: {
            icon: this.iconBase + "RedPinNew.png",
        },
        Due: {
            icon: this.iconBase + "YellowPinNew.png",
        },
        DueOverdue: {
            icon: this.iconBase + "RedPinNew.png",
        },
        BluePin: {
            icon: this.iconBase + "BluePinNew.png",
        }
    };
    // object of a class having lat, lng, type and total count of due / over due items to be shown on marker
    markerDetails: GoogleMapMarkerDetailsList = new GoogleMapMarkerDetailsList();

    // Lat center value for a map
    latCenter: number = 55.953251;
    // Long center value for a map
    lngCenter: number = -3.188267;

}