import { SelectionModel } from "@angular/cdk/collections";
import { AdminItemGroupDetails } from "src/app/schemas/itemsGroup";
import { OrganisationEmployeeModel } from "src/app/schemas/organisation";
import { SearchModels } from "src/app/schemas/searchModel";
import { SharedMessages } from "src/app/shared/shared-messages";

export class ItemGroupViewModel {
    // property holding the static info text
    infoPopupMessage: string = SharedMessages.shortDummyText;
    // property holding the static info text
    infoMsgAssignLocations: string = SharedMessages.shortDummyText;
    // height of popover
    popoverHeight: string = "230px !important"
    // width of popover
    popoverWidth: string = "150px !important"
    // list of item groups
    itemGroupList: AdminItemGroupDetails[] = []
    // list of admin item groups
    adminItemGroupList: AdminItemGroupDetails[] = []
    searchItemGroupList: AdminItemGroupDetails[] = []
    // screen para 1 text 
    pageDescription: string = "This screen displays all of the Business Areas that the user has been assigned permission to. Within each Business Area are a number of locations. To see the list of locations for each Business Area, select the locations count within the 'Locations' column"
    // to show full description of page on main permissions on desktop but on mobile just 50 characters
    showFullPageDescription: boolean = false;
    // table caption text
    tableCaption: string = "Business Area(s)"
    // remove tooltip text
    removeTooltip: string = "Remove location?";
    // employee ID field
    employeeId: string = "";
    //assigned/total location count
    locationDifferenceCount: string = ""
    //assign all permission model text
    modelHeader: string = "Assign all Permissions"
    //assign all permission model main  text
    modelMainText: string = "<p > By assigning all permissions to the user, they will get access to all Business Areas available to them(and all associated locations).</p > <p> Are you sure you wish to assign all permissions? </p>"
    //assign all permission model button text
    modelButtonText: string = "Assign all permissions"
    //unassign all permission model text
    unassignModelHeader: string = "Remove assigned Permissions"
    //assign all permission model main  text
    unassignModelMainText: string = " <p > By de-selecting the 'assign all permissions' checkbox, the user will no longer have access to all permissions.They will have access to only those Business Areas and locations they had access to before the was selected. </p > <p> Are you sure you wish to remove permissions? </p>"
    //assign all permission model button text
    unassignModelButtonText: string = "Remove Permissions"
    //assign all check box state
    checked: boolean = false;
    // get params from url
    queryParamList: SearchModels = new SearchModels();
    // locationIds relevant to the selected item groups
    assignedItemGroupIds: any = []
    //for binding this to +Add Location i.e. when clicked then observer is observed and Inntersection observer is called
    observer: IntersectionObserver;
    //one of the arguments for intersection observer for setting threshold, selecting the element from template and defining margin
    options = {
        root: document.querySelector('.root-modal'),
        rootMargin: "0px",
        threshold: 0.85,
    };
    // bit to know sorting is applied or not on add location grid
    sortAddLocationGrid: boolean = false;
    // search model to store fields of search params
    locationQueryParamList: SearchModels = new SearchModels();
    // list of assigned location Ids
    assignedLocationsIds: Location[] = []
    // variable to store sort by  on add location model popup
    addLocationModelSortBy: string = "name"
    // variable to store sort direction on add location model popup
    addLocationModelSortDirection: string = "ASC"

    // user permissions object from local storage
    manageUserPermissionsLocally: any[] = [];
    // to enable/disable return, cancel and submit buttons
    areChangesMadeInUserPermissions: boolean = false;
    // token from employeeid
    tokenFromEmployeeId: string;
    //item group name to display on location model header
    groupNameForModel: string
    // to check if user has searched or not to adjust functionality of clear accordingly
    isSearchCalled = false;

    // total number of records
    resultCount: number = 0;
    // variable to store sort direction
    sortDirection: string = "ASC"
    // variable to store sort by
    sortBy: string = "name"
    // remove location from new location modal
    selectedLocationIdToBeRemoved: string;
    // the location selected for removals item group
    selectedItemGroup: string;
    // store unassigned locations for selected item group
    unAssignedItemGroupIds: number[];
    // list for unassigned locations
    unassignedLocations: any[] = []
    // search call for unassigned locations
    isUnassignedLocationsSearchCalled: boolean = false
    //show location model in mobile view
    showLocationsInMobile: boolean = false
    // unassigned locations modal in mobile view
    isUnassignedLocationsModalVisibleMobile: boolean = false
    // private properties for getter and setters
    private _itemGroup: string = "";
    private _searchDisabled: boolean = true;
    // search item group button
    get searchDisabled(): boolean {
        // disable the search button if item group has less than 3  
        this._searchDisabled = this._itemGroup.length < 3
        return this._searchDisabled;
    }
    set searchDisabled(val: boolean) {
        this._searchDisabled = val;
    }
    // location name
    get itemGroupName(): string {
        return this._itemGroup;
    }
    set itemGroupName(value: string) {
        this._itemGroup = value;
    }
    // clear the search field
    clear(): void {
        this._itemGroup = "";
        this.adminItemGroupList = [...this.searchItemGroupList];
    }

    // desktop/mobile bit
    isDesktop: boolean = true;
    // show full page modal on mobile for add item groups
    showAddItemGroupModalMobile: boolean = false;
    // add item group modal info content
    addItemGroupModalInfoContent: string = "To add new Business Area(s) please use search or select manually from the grid. When you select a single Business Area please select 'Save' to save all locations for that Business Area or select 'Next' to select locations manually. When you select multiple Business Areas please select 'Save' to save all locations for those Business Areas."
    showAddItemGroupInfoFullContent: boolean = false;
    employeeHeaderInfo: string;

    private _locationNameAndAddress: string = "";
    private _modelSearchDisabled: boolean = true;
    //locationNameAndAddress
    get locationNameAndAddress(): string {
        return this._locationNameAndAddress;
    }
    set locationNameAndAddress(value: string) {
        this._locationNameAndAddress = value;
    }
    //this is for  search of new location model
    get modelSearchDisabled(): boolean {
        // disable the search button if locationNameAndAddress  less than 3 and 
        this._modelSearchDisabled = this._locationNameAndAddress.length < 3;
        return this._modelSearchDisabled;
    }
    //clear model locationNameAndAddress field
    modelClear(): void {
        this._locationNameAndAddress = "";
    }

    // unassigned locations model
    private _unassignedLocationNameAndAddress: string = "";
    private _unassignedModelSearchDisabled: boolean = true;
    //locationNameAndAddress for unassigned locations
    get unassignedLocationNameAndAddress(): string {
        return this._unassignedLocationNameAndAddress;
    }
    set unassignedLocationNameAndAddress(value: string) {
        this._unassignedLocationNameAndAddress = value;
    }
    //this is for  search of unassigned location model
    get unassigneModelSearchDisabled(): boolean {
        // disable the search button if locationNameAndAddress  less than 3 and 
        this._unassignedModelSearchDisabled = this._unassignedLocationNameAndAddress.length < 3;
        return this._unassignedModelSearchDisabled;
    }
    //clear model locationNameAndAddress field
    modelClearUnassigned(): void {
        this._unassignedLocationNameAndAddress = "";
    }

    // for whole row selection
    activateLocationSelected = true;
    // activate the selected row
    locationActiveRow?: any;
    // selection modal for unassigned locations
    locationSelection = new SelectionModel<any>(true, []);

    // default page size of 10
    perPage: number = 10;
    // total results returned from api
    resultCountAddItemGroup: number = 0;
    // default page number set to 1
    page: number = 1;
    private _showPaging: boolean = true;
    // show hide pagination
    get showPaging(): boolean {
        this._showPaging = this.resultCountAddItemGroup > 10;
        return this._showPaging;
    }
    set showPaging(value: boolean) {
        this._showPaging = value;
    }
    // count of item groups to show on Add modal
    itemGroupCount: number = 0;
    // property of organisation model containing employee id
    organisationModelObject: OrganisationEmployeeModel = new OrganisationEmployeeModel();
}