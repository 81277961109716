import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "apollo-client";
import moment from "moment";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { getLocationDefects, getLocationDueOverDue, getLocations } from "src/app/schemas/location-a-defect";
import { getADefectLocationsList } from "src/app/schemas/location-a-defects-names";
import { getDueOverDueLocations, LocationDueOverDue } from "src/app/schemas/location-due-overdue";
import { searchLocations } from "src/app/schemas/locations";
import { SearchModel } from "src/app/schemas/searchModel";
import { SortEmployeeField } from "src/app/schemas/users";
import { GraphQLService } from "./graphql.service";
import { FiltersService } from "../filters-service";

@Injectable()
// service to hold data of dashboard widgets
export class DashboardService {
    // contains the previous url from which it is navigated to dashboard screen
    previousUrl: string = "";

    public constructor(private graphQL: GraphQLService, private filterService: FiltersService) { }
    // method to get locations with variables 
    getDueOverDueItems(sortOrder: string = "ASC", sortField: string = "name", from: number = 0, size: number = 100, dueFrom: moment.Moment = moment(), dueTo: moment.Moment = moment(), locationIds: any[] = []): Observable<ApolloQueryResult<any>> {
        const variables = {
            "dueFrom": dueFrom.format('YYYY-MM-DD'),
            "dueTo": dueTo.format('YYYY-MM-DD'),
            "locationIds": locationIds,
            "from": from,
            "size": size,
            "sortOrder": sortOrder,
            "sortField": sortField,
        }
        // call to graph QL endpoint, with query and variables.
        // disable the loader
        return this.graphQL.getList(getDueOverDueLocations, variables, false);
    }

    // get locations by promise way
    getItemsByPromise(from: number, sortOrder: string = "ASC", sortField: string = "name", size: number = 100, dueFrom: moment.Moment = moment(), dueTo: moment.Moment = moment(), locayionIds: [] = []): Promise<any> {
        // call above function and add the promise work
        const resultToPromise = this.getDueOverDueItems(sortOrder, sortField, from, size, dueFrom, dueTo, locayionIds).pipe(take(1)).toPromise();
        return resultToPromise.then(response => { return response; })
    }

    // this method is called only to get ids and names of Adefect locations only once Dashboard is initialized 
    getLocationADefectsList(from: number, dueFrom: string, dueTo: string, locationIds: any[] = []): Observable<ApolloQueryResult<any>> {
        const variables = {
            "dueFrom": dueFrom,
            "dueTo": dueTo,
            "locationIds": locationIds,
            "from": from,
            "size": 100,
            "sortOrder": 'ASC',
            "sortField": 'name'
        }
        // call to graph QL endpoint, with query and variables.
        // disable the loader for this call

        return this.graphQL.getList(getADefectLocationsList, variables, false);
    }

    // get locations a defects by promise way
    getLocationADefectsByPromise(from: number, dueFrom: string, dueTo: string, locationIds: any[] = []): Promise<any> {
        // call above function and add the promise work
        const resultToPromise = this.getLocationADefectsList(from, dueFrom, dueTo, locationIds).pipe(take(1)).toPromise();
        return resultToPromise.then(response => { return response; })
    }

    // method to get defects of locations with variables 
    getLocationDefects(searchList: SearchModel[] = [], defectType: string = "defectCode", isDefectsCall: boolean = true, sortOrder: string = "ASC", sortField: string = "_id", from: number = 0, size: number = 1): Observable<ApolloQueryResult<any>> {
        const variables = {
            "from": from,
            "size": size,
            "sortOrder": sortOrder,
            "sortField": sortField,
            "search": searchList,
            "aggregation": [
                { "field": defectType, "size": 50000 }
            ]
        }
        // call to graph QL endpoint, with query and variables.
        // disable the loader for this call
        return this.graphQL.getList(isDefectsCall ? getLocationDefects : getLocationDueOverDue, variables, false);
    }

    // method to get defects of locations with variables 
    getLocationDefectsList(searchList: SearchModel[] = [], defectType: string = "defectCode", isDefectsCall: boolean = true, sortOrder: string = "ASC", sortField: string = "_id", from: number = 0, size: number = 1): Observable<ApolloQueryResult<any>> {
        const variables = {
            "from": from,
            "size": size,
            "sortOrder": sortOrder,
            "sortField": sortField,
            "search": searchList,
            "aggregation": [
                { "field": defectType, "size": 50000 }
            ]
        }
        // call to graph QL endpoint, with query and variables.
        // disable the loader for this call
        return this.graphQL.getList(getLocations, variables, false);
    }
    // method to get locations with variables 
    getLocations(searchList: SearchModel[], from: number = 0, order: string = "ASC", sortName: string = "_id", size: number = 100, showLoadingIndicator: boolean = false): Observable<ApolloQueryResult<any>> {
        let sortField = {} as SortEmployeeField;
        sortField.field = sortName
        sortField.order = order
        const variables = {
            "sortBy": sortField,
            "from": from,
            "size": size,
            // making sure to pass search fields with non empty values
            "search": searchList
        }
        // call to graph QL endpoint, with query and variables.
        return this.graphQL.getList(searchLocations, variables, showLoadingIndicator);
    }

    // get locations by promise way
    getLocationsByPromise(searchList: SearchModel[], from: number = 0): Promise<any> {
        // call above function and add the promise work
        const resultToPromise = this.getLocations(searchList, from).pipe(take(1)).toPromise();
        return resultToPromise.then(x => { return x; })
    }

    // method to store loaded values of map locations to modal 
    storeMapLoadedData = (dueCount: string, overdueCount: string, locationsList: LocationDueOverDue[], itemGroupSelected: any[]) => {
        this.filterService.mapLoadedData.totalDueCountMap = dueCount;
        this.filterService.mapLoadedData.totalOverDueCountMap = overdueCount;
        this.filterService.mapLoadedData.allLocationsDataMap = locationsList;
        this.filterService.mapLoadedData.selectedItemGroupId = itemGroupSelected;
    }

    // method to get loaded values of map in modal
    retrieveMapLoadedData = () => this.filterService.mapLoadedData

    // clear all stored data
    resetMapStoredData = () => {
        this.filterService.mapLoadedData.totalDueCountMap = "";
        this.filterService.mapLoadedData.totalOverDueCountMap = "";
        this.filterService.mapLoadedData.allLocationsDataMap = [];
        this.filterService.mapLoadedData.selectedItemGroupId = [];
    }
}