import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout-service';

@Component({
  selector: 'PageNotFound',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})

// page not found component for invalid urls
export class PageNotFoundComponent implements OnInit {

  // Check for desktop device, by default its true
  isDesktop: boolean = true;
  // setting text font size, by default for desktop
  fontSize: string = '180px';

  // constructor with required services
  constructor(private layoutService: LayoutService) { }
  ngOnInit(): void {
    // check the device type and update the local variable
    this.layoutService.currentDevice.subscribe(result => this.isDesktop = result);
    if (!this.isDesktop)
      this.fontSize = "90px";
  }
}
