<div class="icon-centered" (click)="navigateToPlant()">
    <nx-icon name="product-paper-document" outline="true" size="l" class="standardBlueColor">
    </nx-icon>
    <span class="standardBlueColor" id="createnew">Create
        new</span>
</div>
<div>
    <hr />
</div>
<div nxRow class="nx-margin-x-s">

    <div nxCol="12" id="note">

        'Please note: The report contained within this platform are only summaries of your examination report(s).
        They do not constitute a statutory report of Thorough Examination or inspection made by the AE Engineer
        Surveyor
        that informs the duty holder of their responsibilities to attend to defects identified in the reports.'
    </div>

</div>
<div>
    <hr />
</div>