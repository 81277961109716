import moment from 'moment';
import { SharedMessages } from 'src/app/shared/shared-messages';
import { LocationDetailSearchModel } from '../../dashboard.viewModel';

export class AllDefectViewModel {
  // to show y-axis
  showYAxis: boolean = true;
  // to show data on right of bars
  showDataLabel: boolean = true;
  // disable tooltip
  tooltipDisabled: boolean = true;
  // color scheme for defects i.e. A, B, P, C, N respectively
  colorScheme = {
    domain: ['#E4003A', '#F86200', '#496EBD', '#FDD25C', '#00908D'],
  };
  // The selected locations from filter All-defect filter
  allDefectSelectedLocations: any[] = [];
  // property to contains the list of location id and name which is having A defect code
  locationsIdsOfADefects: LocationDetailSearchModel[] = [];
  // property to contain the list of location id and name which is having P defect code
  locationsIdsOfPDefects: LocationDetailSearchModel[] = [];
  // property to contain the list of location id and name which is having C defect code
  locationsIdsOfCDefects: LocationDetailSearchModel[] = [];
  // property to contain the list of location id and name which is having N defect code
  locationsIdsOfNDefects: LocationDetailSearchModel[] = [];
  // property to contain the list of location id and name which is having B defect code
  locationsIdsOfBDefects: LocationDetailSearchModel[] = [];

  // setting the min and max dates allowed to select from start and end date
  minStartDate = moment().subtract(1, 'years');
  maxStartDate = moment();
  minEndDate = moment();
  maxEndDate = moment();
  // start date set to 1 year back from todays date
  startDate = moment().subtract(1, 'years');
  // end date set to todays Date
  endDate = moment();
  // date format for filter modal
  datePickerFormat = 'DD/MM/YYYY';
  //comma separated name array for locations
  locationNamesAndCount: string = '';
  // to expand or collapse the the last checkbox field of filter modal. By default its set to false
  expanded: boolean = false;
  //to store the all the defects for view
  allDefectList: any[] = [];
  // difference between end and start date of locations due/overdue filter
  dateRangeLocationsDifference: number;
  // is all defect widget expanded
  isAllDefectExpanded: boolean = false;
  // export csv file name
  exportFileName: string = 'exportAllCurrentdefectsItems';
  //further option to be included in exported file, we are using header option.
  options = {
    headers: [
      'Serial no.',
      'Plant no.',
      'Plant description',
      'Discipline',
      'Last inspected',
      'Defect code',
      'Next due',
      'Sub location',
      'Detailed location',
      'Item status',
    ],
  };
  // boolean for device type
  isDesktop: boolean = true;
  // property holding the static info text
  infoPopupMessage: string = SharedMessages.shortDummyText;

  // height of popover
  popoverHeight: string = '200px !important';
  // width of popover
  popoverWidth: string = '170px !important';

  // defect code property
  defectCode: string = 'A,B,P,N,C';

  // all defect switcher model
  allDefectDateRangeSwitcherModel: boolean = false;
}
