
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoleService } from 'src/app/services/auth-service/role.service';
import { CommonService } from 'src/app/services/common.service';
import { IdleDetectionService } from 'src/app/services/idle-detection.service';
import { LayoutService } from 'src/app/services/layout-service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RolesFromApi } from 'src/app/shared/enums/role';
import { AppStates } from 'src/app/statemanagement/app.state';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth-service/auth0client.service';
import { HeaderViewModel } from './header.viewModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  //hide show menu and search row
  isSearchClicked: boolean = false;
  // view Model class instance
  headerViewModel: HeaderViewModel = new HeaderViewModel();
  // view user dropdown container
  @ViewChild('userDropdownContainer', { static: false }) userDropdownContainer: ElementRef;
  // view profile dropdown container
  @ViewChild('profileDropdownContainer', { static: false }) profileDropdownContainer: ElementRef;
  // view my team/allianz dropdown container
  @ViewChild('myTeamAllianzDropdownContainer', { static: false }) myTeamAllianzDropdownContainer: ElementRef;


  // constructor with authservice, device and store injection
  constructor(private store: Store<AppStates>, private authService: AuthService,
    public layoutService: LayoutService, private roleService: RoleService,
    private common: CommonService, private localStorageService: LocalStorageService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document
    , private idleDetectionService: IdleDetectionService, private router: Router) {

  }

  ngOnInit() {
    // subscribed to user object from store
    this.store.select('user').subscribe(user => {
      // checking is current user has client admin role
      if (user?.roles) {

        this.headerViewModel.hasClientAdminRole = this.roleService.isClientAdmin(user.roles);
        this.headerViewModel.hasClientUserRole = this.roleService.isClientUSer(user.roles)
      }
      // increase mobile menu height to adjust extra link if has client admin role
      if (this.headerViewModel.hasClientAdminRole) {
        // in case of client admin, we need to update the mobile menue hight to 
        // show Register User option
        this.headerViewModel.mobileMenuHeight = 'auto';
      }

      // preparing the initials
      this.headerViewModel.userIntials = this.getInitials(user.firstName, user.lastName)
      if (user.roles.some(x => x.role === RolesFromApi.internalAdmin)) {
        this.headerViewModel.hasInternalAdminUserRole = true
        this.organisationSubscription();
      }
      else if (this.headerViewModel.hasClientAdminRole || user.roles.some(x => x.role === RolesFromApi.standardClientAdmin)) {
        this.itemGroupsSubscription();
      }
    });
    // subscribe to device type detection observable
    this.layoutService.currentDevice.subscribe(result => this.headerViewModel.isDesktop = result);
    // fetch the list of item groups from local storage
    this.headerViewModel.itemGroupListComplete = this.localStorageService.getObject(LocalStorageKeys.ItemGroups);
  }


  // toggle user sub menu containing user management and register new user
  toggleDropdown(event) {
    // on click on cog wheel toggle userSubMenu variable
    this.headerViewModel.userSubMenu = !this.headerViewModel.userSubMenu;
    // on click on cog wheel make the icon solid blue
    // else back to its original form
    this.headerViewModel.cogWheelIcon = this.headerViewModel.userSubMenu ? 'product-cog-wheel-o' : 'product-cog-wheel';
    // make the cog wheel and applauncher dropdowns closed
    this.headerViewModel.showSubMenu = false;
    this.headerViewModel.myTeamAllianzSubMenu = false;
    event.stopPropagation()
  }

  // profile avatar toggle function
  profileToggleDropdown(event) {
    // on click on profile avatar toggle showsubbanner variable
    this.headerViewModel.showSubMenu = !this.headerViewModel.showSubMenu;
    // make the cog wheel and applauncher dropdowns closed
    this.headerViewModel.userSubMenu = false;
    this.headerViewModel.myTeamAllianzSubMenu = false;
    // change the cog wheel icon to its original form if it was previously showing options
    this.headerViewModel.cogWheelIcon = 'product-cog-wheel'
    event.stopPropagation();
  }

  // my team allianz toggle function
  myTeamAllianzToggleDropdown(event) {
    // on click on applauncher toggle myTeamAllianzSubMenu variable
    this.headerViewModel.myTeamAllianzSubMenu = !this.headerViewModel.myTeamAllianzSubMenu;
    // make the cog wheel and avatar dropdowns closed
    this.headerViewModel.showSubMenu = false;
    this.headerViewModel.userSubMenu = false;
    // change the cog wheel icon to its original form if it was previously showing options
    this.headerViewModel.cogWheelIcon = 'product-cog-wheel';
    event.stopPropagation();
  }

  // to hide dropdown
  ngAfterViewInit() {
    // user dropdown
    // hide dropdown when clicked anywhere on document
    this.renderer.listen('document', 'click', (event) => {
      if (!this.userDropdownContainer.nativeElement.contains(event.target) && this.userDropdownContainer) {
        this.headerViewModel.userSubMenu = false;
        this.headerViewModel.cogWheelIcon = 'product-cog-wheel';
      }
    })

    //  profile avatar dropdown
    // hide dropdown when clicked anywhere on document
    this.renderer.listen('document', 'click', (event) => {
      if (!this.userDropdownContainer.nativeElement.contains(event.target) && this.profileDropdownContainer) {
        this.headerViewModel.showSubMenu = false;
      }
    })
    // }

    //  my Team/allianz dropdown
    // hide dropdown when clicked anywhere on document
    this.renderer.listen('document', 'click', (event) => {
      if (!this.myTeamAllianzDropdownContainer.nativeElement.contains(event.target) && this.myTeamAllianzDropdownContainer) {
        this.headerViewModel.myTeamAllianzSubMenu = false;
      }
    })
  }

  // subscribe to the item group calls if the role is not internal Admin
  itemGroupsSubscription = () => {
    // subscribe to see the assigned item group list
    this.layoutService.currentAssignedItemGroups.subscribe(itemGroupList => {
      this.headerViewModel.listOfItemGroupNames = ""
      // assign the list to local variable
      this.headerViewModel.itemGroupList = itemGroupList
      // sort the list
      this.sortItemGroupList();
      // if the assigned item groups are more than 1, need to add the names with ',' 
      // and show as a tooltip
      if (this.headerViewModel.itemGroupList && this.headerViewModel.itemGroupList.length > 1) {
        this.headerViewModel.listOfItemGroupNames = this.headerViewModel.itemGroupList.map(name => name).join(' , ')
      }
    })

    // subscribe to know the detail of all item groups
    this.layoutService.currentItemGroups.subscribe(itemGroupList => {
      // assign to local variable
      if (itemGroupList) {
        this.headerViewModel.itemGroupListComplete = itemGroupList;
      }
    })
  }
  // subscribe to the organisation calls if the role is internal Admin
  organisationSubscription = () => {
    // subscribe to see the assigned item group list
    this.layoutService.currentOrganisationObject.subscribe(organisationObject => {
      // assign the list to local variable
      this.headerViewModel.organisationObject = organisationObject
    })
  }
  // sort the assigned item group list by name and asc order
  sortItemGroupList = () => {
    this.headerViewModel.itemGroupList.sort((a, b) => {
      return this.common.compare(a, b, "asc");
    })
  }

  // sort the complete item group list by name and asc
  sortItemCompleteGroupList = () => this.headerViewModel.itemGroupListComplete.sort((a, b) => {
    return this.common.compare(a.name, b.name, "asc");
  })

  // method to open the modal
  openModal = () => {
    this.headerViewModel.itemGroupAssignedList = this.localStorageService.getObject(LocalStorageKeys.SelectedItemGroups);
    this.headerViewModel.showPopup = !this.headerViewModel.showPopup
  }

  // method to open the model to show organisation
  openOrganisationModel = () => this.headerViewModel.showOrganisationModel = !this.headerViewModel.showOrganisationModel

  // Logout function to make user unauthorized
  logout(): void {
    //setting logout status to service, to stop watch
    this.idleDetectionService.setUserLoggedIn(false);
    // calling auth0's logout
    this.authService.logout();
  }

  hideMenue(): void {
    this.headerViewModel.isMenuVisible = false;
  }

  //create iniials of loggedin user(replace logout)
  getInitials(firstName: string, lastName: string) {
    //get first and sur name and covert them to uppercase
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }

  // update the show popup property with output status
  getModalOpenStatus = (status: boolean) => this.headerViewModel.showPopup = status;

  // update the show organisation popup property with output status
  getOrganisationModalOpenStatus = (status: boolean) => this.headerViewModel.showOrganisationModel = status;

  // navigate to myTeam register new user page
  navigateToMyTeamRegisterNewUser() {
    const { myTeamRegisterUser } = environment;
    const [myTeam, myTeamInternal] = myTeamRegisterUser
    this.document.location.href = this.headerViewModel.hasInternalAdminUserRole ? myTeamInternal : myTeam;
  }

  // navigate to myTeam manage user page
  navigateToMyTeamManageUser() {
    const { myTeamManageUser } = environment;
    const [myTeam, myTeamInternal] = myTeamManageUser;
    this.document.location.href = this.headerViewModel.hasInternalAdminUserRole ? myTeamInternal : myTeam;
  }

  // navigate to myTeam home page
  navigateToMyTeamHome() {
    const { myTeamHome } = environment;
    const [myTeam, myTeamInternal] = myTeamHome;
    this.document.location.href = this.headerViewModel.hasInternalAdminUserRole ? myTeamInternal : myTeam;
  }

  // navigate to myAllianz home page
  navigateToMyAllianzHome() {
    const { myAllianzHome } = environment;
    const [myAllianz, myAllianzInternal] = myAllianzHome;
    this.document.location.href = this.headerViewModel.hasInternalAdminUserRole ? myAllianzInternal : myAllianz;
  }
  //navigate to my Allianz profile
  navigateToMyAllianzProfile() {
    const { myAllianzProfile } = environment;
    const [myAllianzProfilePage, myAllianzInternalProfilePage] = myAllianzProfile;
    this.document.location.href = this.headerViewModel.hasInternalAdminUserRole ? myAllianzInternalProfilePage : myAllianzProfilePage;

  }
}


